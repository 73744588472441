import { connect } from 'react-redux';
import { RootState } from 'redux/reducers';
import GridOfPodcasts from './GridOfPodcasts';

const mapStateToProps = (state: RootState) => ({
    gridLayout: state.settings.gridLayout,
});

const ConnectedComponent = connect(mapStateToProps)(GridOfPodcasts);
export { ConnectedComponent as GridOfPodcasts };
