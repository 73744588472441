import { PopupMenuWrapped } from 'components/popup/PopupMenu/PopupMenuWrapped';
import styled, { css } from 'styled-components';
import { Typography, black, white } from 'styles';

export const StyledPopupMenu = styled(PopupMenuWrapped)`
    min-width: 345px;
    height: 402px;
    border-radius: 8px;

    .accordion-popup-menu-item {
        padding: 0 12px 12px 12px;
        &:focus,
        &:hover {
            cursor: initial;
            background: initial;
        }
    }

    h3 {
        width: 84%;
    }
`;

export const AccordionContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background-color: transparent;
    padding: 10px 0;
`;

export const CurrentSelection = styled.div<{ isSelectionMade: boolean; isOriginalLength: boolean }>`
    @keyframes fade {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 24px;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    ${props =>
        !props.isOriginalLength &&
        css`
            margin-top: 12px;
        `}

    color: ${white};
    ${props =>
        props.theme.name === 'light' &&
        css`
            color: ${black};
        `}

    @media (prefers-reduced-motion: no-preference) {
        animation: ${props => (props.isSelectionMade ? 'fade 400ms ease-in-out' : 'none')};
    }

    .big-time {
        font-size: 96px;
        font-weight: 500;
        line-height: 108px;
        letter-spacing: -0.04em;
        text-align: center;
    }

    .little-time {
        ${Typography.Desktop['H70-B']};
        line-height: 16.8px;
        text-align: left;
        color: ${props => props.theme.tokens['primary-text-02']};
        position: relative;
        top: -14px;
        left: 0px;

        &.has-hours {
            left: 6px;
        }
    }
`;

export const OriginalLength = styled.span`
    ${Typography.Desktop['H70-B']};
    color: ${props => props.theme.tokens['primary-text-02']};
    line-height: 12px;
`;

export const SetDurationButton = styled.button`
    width: 100%;
    background: ${props => props.theme.legacyScss['theme-text-link']};
    color: ${white};
    ${props =>
        props.theme.name === 'dark' &&
        css`
            color: ${black};
        `}
    ${Typography.Desktop['H70-B']};
    height: 48px;
    padding: 15px 32px;
    border-radius: 8px;
    margin-top: 28px;
    @supports (-moz-appearance: none) {
        margin-top: 28px;
    }
    &:hover {
        opacity: 0.8;
    }
`;

export const PoweredBy = styled.a`
    ${Typography.Desktop['H70-B']};
    color: ${props => props.theme.tokens['primary-text-02']};
    display: flex;
    gap: 8px;
    text-decoration: none;

    margin-top: 24px;
    // We need to use a different margin-top value for Firefox because of
    // the way it renders the slider thumb and range with differnt spacing
    @supports (-moz-appearance: none) {
        margin-top: 16px;
    }

    svg {
        fill: ${props => props.theme.tokens['primary-text-02']};
    }
`;
