import '@babel/polyfill';
import * as Sentry from '@sentry/browser';
import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl-redux';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import {
    acceptAllClicked,
    acceptSelectionClicked,
    analyitcsLoaded,
    cookiesBannerShown,
} from 'redux/actions/analytics.actions';
import { electronMonitorMessages, electronSetLocale } from 'redux/actions/electron.actions';
import { store } from 'store';
import { locale } from 'util/locale';
import App from './App';
import customHistory from './customHistory';
import { getPlatformName } from './helper/Browser';
import { watchForErrors } from './helper/GlobalErrorHelper';
import { beforeBreadcrumbHook, beforeSendHook } from './helper/SentryHelper';
import { GlobalStyles } from './styles';

import {
    DEPLOYMENT_ENV,
    PADDLE_ENVIRONMENT,
    PADDLE_VENDOR_ID,
    RELEASE_ID,
    SENTRY_DSN,
} from './settings';
import './util/a8c-analytics';

import 'scss/style.scss';
import './styles/favicons/favicon-144x144.png';
import './styles/favicons/favicon-16x16.png';
import './styles/favicons/favicon-256x256.png';
import './styles/favicons/favicon-32x32.png';
import './styles/favicons/favicon-512x512.png';
import './styles/favicons/favicon.ico';

async function setup() {
    // While modern browsers support IntersectionObserver, adding the polyfill
    // ensures that unsupported browsers won't crash unexpectedly
    if (typeof window.IntersectionObserver === 'undefined') {
        await import('intersection-observer');
    }

    window.document.addEventListener('a8c-analytics:loaded', event => {
        const { detail } = event;
        store.dispatch(analyitcsLoaded(detail.maybeFireTrackers));
    });

    window.document.addEventListener('a8c-analytics:cookies-banner-shown', () => {
        store.dispatch(cookiesBannerShown());
    });

    window.document.addEventListener('a8c-analytics:accept-all-clicked', () => {
        store.dispatch(acceptAllClicked());
    });

    window.document.addEventListener('a8c-analytics:accept-selection-clicked', event => {
        const { detail } = event;
        store.dispatch(acceptSelectionClicked(detail.buckets.analytics));
    });

    window.addEventListener('DOMContentLoaded', () => {
        store.dispatch(electronMonitorMessages());
        store.dispatch(electronSetLocale(locale));
    });

    watchForErrors();

    Sentry.init({
        dsn: SENTRY_DSN,
        release: RELEASE_ID,
        environment: DEPLOYMENT_ENV,
        initialScope: {
            contexts: { platform: { name: getPlatformName() } },
        },
        debug: false,
        maxBreadcrumbs: 100,
        beforeSend: beforeSendHook,
        beforeBreadcrumb: beforeBreadcrumbHook,
    });

    window.Paddle?.Setup({
        vendor: PADDLE_VENDOR_ID,
        debug: false,
        enableTracking: false,
    });

    window.Paddle?.Environment.set(PADDLE_ENVIRONMENT);

    ReactDOM.render(
        <div>
            <GlobalStyles />
            <Provider store={store}>
                <IntlProvider locale={locale}>
                    <Router history={customHistory}>
                        <App />
                    </Router>
                </IntlProvider>
            </Provider>
        </div>,
        document.getElementById('root'),
    );
}

setup();
