import styled from 'styled-components';
import { gridSize, Typography } from 'styles';

export const FormSection = styled.div`
    ${Typography.Desktop.H65}
    width: 340px;
    margin-bottom: ${2 * gridSize}px;
    display: flex;
    justify-content: center;
`;

export const Footer = styled.div`
    width: 340px;
    margin: ${4 * gridSize}px 0 ${2 * gridSize}px;
    display: flex;
    flex-wrap: wrap;
    button[kind='primary-red'] {
        padding: 0 ${3 * gridSize}px;
        width: 100%;
    }
`;

export const ErrorMessage = styled.div`
    width: 100%;
    flex-shrink: 0;
    margin-bottom: ${2 * gridSize}px;
`;
