import { Folder } from 'model/types';
import { createAction, ActionsUnion } from './action-creators';

export enum ActionTypes {
    FOLDER_ADD = '@folders/FOLDER_ADD',
    FOLDER_REMOVE = '@folders/FOLDER_REMOVE',
    FOLDER_UPDATE = '@folders/FOLDER_UPDATE',
}

export const Actions = {
    addFolder: (folder: Folder, podcastUuids: string[]) =>
        createAction(ActionTypes.FOLDER_ADD, { folder, podcastUuids }),
    removeFolder: (uuid: string) => createAction(ActionTypes.FOLDER_REMOVE, { uuid }),
    updateFolder: (folder: Folder, podcastUuids: string[]) =>
        createAction(ActionTypes.FOLDER_UPDATE, { folder, podcastUuids }),
};

export type Actions = ActionsUnion<typeof Actions>;
