import styled from 'styled-components';
import { B70, gridSize, H60, red10, red60 } from '../../../../../../styles';

export const UploadInformationHeadingWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const MainText = styled(H60)`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;

export const ErrorTagContainer = styled.div`
    display: flex;
    width: 44px;
    height: ${3 * gridSize}px;

    align-items: center;

    background-color: ${red10};
    border-radius: 12px;
    margin-left: ${gridSize}px;
`;

export const ErrorIconWrapper = styled.div`
    display: flex;
    width: ${2 * gridSize}px;
    height: ${2 * gridSize}px;

    align-items: center;

    ${props => props.withMargin && 'margin-left: 4px;'}
`;

export const ErrorNumberWrapper = styled(B70)`
    color: ${red60};

    margin-left: ${props => (props.number < 10 ? 6 : 3)}px;
`;

export const MainTextWithLeftMargin = styled(H60)`
    margin-left: ${gridSize}px;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;
