import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withAnalyticsContext } from 'context/AnalyticsContext';
import { TrackOnMount } from 'components/Tracks';
import { ErrorMessage, Header, LoaderSquare } from '../../components/index';
import { EpisodesTable } from '../../components/EpisodesTable';
import {
    FilterPageWrapper,
    EpisodesWrapper,
    ClearHistory,
    ClearHistoryWrapper,
    ErrorWrapper,
    Separator,
} from './styled';

const TRACKS_MOUNT_EVENT = {
    starred: 'starred_shown',
    history: 'listening_history_shown',
};

const FILTER_SETTINGS = {
    new_releases: {
        title: 'new-releases',
        emptyTitle: 'empty-list',
        emptySummary: 'empty-list-desc',
        hidesArchivedInstantly: true,
    },
    in_progress: {
        title: 'in-progress',
        emptyTitle: 'empty-list',
        emptySummary: 'empty-list-desc',
        hidesArchivedInstantly: true,
    },
    starred: {
        title: 'starred',
        emptyTitle: 'empty-starred',
        emptySummary: 'empty-starred-desc',
        hidesArchivedInstantly: false,
    },
    history: {
        title: 'history',
        emptyTitle: 'empty-history',
        emptySummary: 'empty-history-desc',
        hidesArchivedInstantly: false,
    },
};

class FilterPage extends React.Component {
    componentDidMount() {
        this.props.downloadFilter(this.props.id);
        window.addEventListener('focus', this.windowFocus);
    }

    componentWillUnmount() {
        window.removeEventListener('focus', this.windowFocus);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id) {
            this.props.downloadFilter(this.props.id);
            this.props.filterWebOpenEvent(this.props.id);
        }
    }

    windowFocus = () => {
        this.props.downloadFilter(this.props.id);
    };

    onEpisodeClick = episode => {
        this.props.openEpisode(episode, {
            eventSource: this.props.eventSource,
            autoplayConfig: this.props.autoplay,
        });
    };

    render() {
        const mountEvent = TRACKS_MOUNT_EVENT[this.props.id];
        const settings = FILTER_SETTINGS[this.props.id];
        const { filter, intl } = this.props;

        if (!filter) {
            return null;
        }

        return (
            <FilterPageWrapper>
                {mountEvent && <TrackOnMount event={mountEvent} key={mountEvent} />}
                <Helmet>
                    <title>{intl.formatMessage({ id: settings.title })}</title>
                </Helmet>
                <Header title={<FormattedMessage id={settings.title} />} />
                <EpisodesWrapper>
                    {this.renderEpisodesOptions()}
                    {this.renderEpisodesOrMessage(settings)}
                </EpisodesWrapper>
            </FilterPageWrapper>
        );
    }

    renderEpisodesOrMessage(settings) {
        const { player, id, episodes, filter } = this.props;

        // This breaks abstraction slightly, as it should be based on the filtering logic in EpisodeTable's mergeProps
        // function. However, for the forseeable future, this will be fine.
        const episodesToShow = !settings.hidesArchivedInstantly
            ? episodes
            : episodes?.filter(ep => !ep.isDeleted);

        // Temporary bugfix: When filters are rebuilt, this logic will be removed - For new releases/in
        // progress when an episode finishes or is marked as played, it isn't automatically removed from the list.
        // The server will return the results for new releases/in progress lists,
        // so no filtering is required here.
        const isNewReleasesOrInProgress = ['in_progress', 'new_releases'].includes(id);

        if (episodesToShow?.length > 0) {
            return (
                <EpisodesTable
                    episodes={episodes}
                    uuidToPodcast={this.props.uuidToPodcast}
                    sortEnabled={false}
                    inHistory={this.props.inHistory}
                    upNextEpisodes={this.props.upNext.episodes}
                    playerEpisodeUuid={player && player.episode ? player.episode.uuid : null}
                    isPlaying={player.isPlaying}
                    playerPlayedUpTo={player && player.episode ? player.episode.playedUpTo : 0}
                    onEpisodeClick={this.onEpisodeClick}
                    color="#03A9F4"
                    showsArchived={!settings.hidesArchivedInstantly}
                    showCompleted={!isNewReleasesOrInProgress}
                    eventSource={this.props.eventSource}
                    autoplay={this.props.autoplay}
                />
            );
        }

        // No episodes found
        if (episodesToShow) {
            return (
                <ErrorWrapper>
                    <ErrorMessage
                        title={<FormattedMessage id={settings.emptyTitle} />}
                        description={<FormattedMessage id={settings.emptySummary} />}
                    />
                </ErrorWrapper>
            );
        }

        // Failed to load episodes
        if (filter.loadFailed) {
            return (
                <ErrorWrapper>
                    <ErrorMessage description={<FormattedMessage id="episode-loading-issue" />} />
                </ErrorWrapper>
            );
        }

        // Currently loading the episodes
        return <LoaderSquare />;
    }

    renderEpisodesOptions() {
        const { inHistory, showClearHistoryConfirmation, episodes } = this.props;

        const clearHistoryText = <FormattedMessage id="clear-history" />;

        return inHistory && episodes && episodes.length > 0 ? (
            <div>
                <ClearHistoryWrapper>
                    <ClearHistory onClick={showClearHistoryConfirmation}>
                        {clearHistoryText}
                    </ClearHistory>
                </ClearHistoryWrapper>
                <Separator />
            </div>
        ) : null;
    }
}

FilterPage.propTypes = {
    id: PropTypes.oneOf(['history', 'in_progress', 'new_releases', 'starred']).isRequired,
    settings: PropTypes.object.isRequired,
    filter: PropTypes.object.isRequired,
    uuidToPodcast: PropTypes.object.isRequired,
    upNext: PropTypes.object.isRequired,
    player: PropTypes.object.isRequired,
    inHistory: PropTypes.bool.isRequired,
    downloadFilter: PropTypes.func.isRequired,
    openEpisode: PropTypes.func.isRequired,
    showClearHistoryConfirmation: PropTypes.func.isRequired,
    eventSource: PropTypes.oneOf(['filters', 'starred', 'listening_history']).isRequired,
};

export default withAnalyticsContext(injectIntl(FilterPage));
