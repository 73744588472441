import styled from 'styled-components';
import { gridSize, Typography } from 'styles';

export const Wrapper = styled.div`
    ${Typography.Desktop.H70}
    display: flex;
    svg {
        flex-shrink: 0;
        color: ${props => props.theme.tokens['support-05']};
        margin: -2px ${gridSize}px 0 0;
    }
    button {
        ${Typography.Desktop['H70-B']}
        color: ${props => props.theme.tokens['support-05']};
    }
`;
