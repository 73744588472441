import styled from 'styled-components';
import { THEME } from '../../../../model/theme';
import { H70 } from '../../../../styles';

export const ThemeToggleContainer = styled.div`
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    margin: 2px;
`;

export const ThemeOptionText = styled(H70)``;

const ThemeOption = styled.button<{ selected: boolean }>`
    text-align: center;
    align-items: center;
    flex: 1.5;
    min-width: 25%;

    ${({ selected, theme }) =>
        selected &&
        `
        background-color: ${theme.legacyScss['theme-text-link']};
        border-radius: 8px;
        height: 37px;
    `}

    ${ThemeOptionText} {
        ${({ selected, theme }) =>
            selected === true &&
            `
            color: ${theme.tokens['primary-ui-01']};
            font-weight: 500;
            line-height: 37px;
        `}

        ${({ selected, theme }) =>
            selected === false &&
            `
            &:hover {
                color: ${theme.legacyScss['theme-text-link']};
            }
        `}
    }
`;

export const LightThemeOption = styled(ThemeOption)<{ currentTheme: number }>`
    ${({ currentTheme, theme }) =>
        currentTheme === THEME.system &&
        `border-right: 1px solid ${theme.tokens['primary-ui-05']};`}
`;

export const DarkThemeOption = styled(ThemeOption)<{ currentTheme: number }>`
    ${({ currentTheme, theme }) =>
        currentTheme === THEME.light && `border-right: 1px solid ${theme.tokens['primary-ui-05']};`}
`;

export const SystemThemeOption = styled(ThemeOption)<{ currentTheme: number }>`
    text-align: center;
    flex: 2.5;

    ${ThemeOptionText} {
        max-width: 90%;
        margin: 0 auto;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;

export const ThemeOptionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 41px;
    padding-left: 1px;
    padding-right: 1px;
    border-radius: 10px;
    ${({ theme }) => `border: 1px solid ${theme.tokens['primary-ui-05']};`}
`;
