import { DurationText } from 'components';
import Checkbox from 'components/Checkbox';
import { Icon } from 'components/Icon';
import {
    Row,
    RowCell,
    RowContent,
    RowLink,
    RowLinkPlaceholder,
    RowPlayPauseButton,
    RowTitle,
} from 'components/Row';
import useFormatMessage from 'hooks/useFormatMessage';
import useTracks from 'hooks/useTracks';
import { EpisodeChapter } from 'model/types';
import React, { HTMLAttributes } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as fromPlayerActions from 'redux/actions/player.actions';
import {
    getCurrentPlayingChapterPosition,
    getPlayerEpisode,
    getPlayerIsPlaying,
} from 'redux/reducers/selectors';
import {
    DurationWrapper,
    RowTitleContainer,
    SelectEpisode,
    TitleButton,
} from './ChapterRow.styled';

interface Props extends HTMLAttributes<HTMLDivElement> {
    chapter: EpisodeChapter;
    position: number;
    isSelected: boolean;
    onSelectionChange: (isSelected: boolean) => void;
}

const getChapterDuration = (chapter: EpisodeChapter) => {
    return chapter.endTime ? chapter.endTime - chapter.startTime : null;
};

const getChapterPercentPlayed = (chapter: EpisodeChapter, playedUpTo: number) => {
    const isChapterFinished = chapter.endTime !== undefined && playedUpTo >= chapter.endTime;

    if (isChapterFinished) {
        return 100;
    }

    const isChapterNotStarted = playedUpTo < chapter.startTime;

    if (isChapterNotStarted) {
        return 0;
    }

    const chapterDuration = getChapterDuration(chapter);

    if (!chapterDuration) {
        return null;
    }

    const playedTime = playedUpTo - chapter.startTime;

    return (playedTime / chapterDuration) * 100;
};

const ChapterRow = ({ chapter, position, isSelected, onSelectionChange, ...props }: Props) => {
    const dispatch = useDispatch();

    const playingEpisode = useSelector(getPlayerEpisode);

    const playerIsPlaying = useSelector(getPlayerIsPlaying);

    const currentPlayingChapterPosition = useSelector(getCurrentPlayingChapterPosition);

    const { recordEvent } = useTracks();

    const isChapterPlayed = currentPlayingChapterPosition > position;

    const duration = getChapterDuration(chapter);

    const hasLink = !!chapter.url;

    const formatMessage = useFormatMessage();

    const onPlayClick = () => {
        dispatch(
            fromPlayerActions.Actions.playEpisode(
                playingEpisode?.uuid,
                playingEpisode?.podcastUuid,
                { eventSource: 'chapters' },
                { seekTo: chapter?.startTime },
            ),
        );
    };

    const onPauseClick = () => {
        dispatch(fromPlayerActions.Actions.pause({ eventSource: 'chapters' }));
    };

    const isThisChapterPlaying = playerIsPlaying && currentPlayingChapterPosition === position;

    const chapterPercentPlayed = getChapterPercentPlayed(chapter, playingEpisode?.playedUpTo);

    const onChapterLinkClicked = () => {
        recordEvent('chapter_link_clicked', {
            chapter_title: chapter.title,
            episode_uuid: playingEpisode?.uuid,
            podcast_uuid: playingEpisode?.podcastUuid,
        });
    };

    return (
        <div {...props}>
            <Row sortable={false} disabled={!isSelected}>
                <RowContent>
                    <SelectEpisode>
                        <Checkbox
                            checked={isSelected}
                            onChange={event => onSelectionChange(event.target.checked)}
                        />
                    </SelectEpisode>
                    <RowTitle
                        style={
                            // Workaround to keep it left-aligned because we don't have image chapters yet
                            { marginLeft: '0px' }
                        }
                        inactive={isChapterPlayed || !isSelected}
                    >
                        <RowTitleContainer>
                            <TitleButton type="button" onClick={onPlayClick} disabled={!isSelected}>
                                <h2>
                                    {position + 1} • {chapter.title}
                                </h2>
                            </TitleButton>
                        </RowTitleContainer>
                    </RowTitle>
                    <DurationWrapper>
                        {duration && (
                            <DurationText
                                playedUpToSecs={0}
                                playingStatus={0}
                                durationSecs={duration}
                            />
                        )}
                    </DurationWrapper>
                    {hasLink ? (
                        <RowLink
                            href={chapter.url}
                            onClick={onChapterLinkClicked}
                            target="_blank"
                            alwaysVisible
                            aria-label={formatMessage('chapter-link')}
                        >
                            <Icon id="link" />
                        </RowLink>
                    ) : (
                        <RowLinkPlaceholder />
                    )}
                    <RowCell>
                        <RowPlayPauseButton
                            size={28}
                            color="currentColor"
                            playedColor="currentColor"
                            disabled={!isSelected}
                            isPlaying={isThisChapterPlaying}
                            isPlayed={false}
                            percent={chapterPercentPlayed ?? 0}
                            onPlayClick={onPlayClick}
                            onPauseClick={onPauseClick}
                        />
                    </RowCell>
                </RowContent>
            </Row>
        </div>
    );
};

export default ChapterRow;
