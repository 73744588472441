import { freeze } from 'icepick';
import { EpisodeAndPodcastUuidsArray } from 'model/types';
import * as fromPodcastActions from '../actions/podcast.actions';

type ArchiveAllState = null | {
    episodeUuidAndPodcastUuids: EpisodeAndPodcastUuidsArray;
    playedOnly: boolean;
};

export const INITIAL_STATE = null;

export default (state: ArchiveAllState = INITIAL_STATE, action: fromPodcastActions.Actions) => {
    switch (action.type) {
        case fromPodcastActions.ActionTypes.ARCHIVE_ALL_CONFIRMATION_SHOW:
            return freeze({
                episodeUuidAndPodcastUuids: action.payload.episodeUuidAndPodcastUuids,
                playedOnly: action.payload.playedOnly,
            });
        case fromPodcastActions.ActionTypes.ARCHIVE_ALL_CONFIRMATION_DISMISS:
            return null;
        default:
            return state;
    }
};
