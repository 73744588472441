import React from 'react';
import { LinkProps } from 'react-router-dom';
import { Props as ButtonProps } from 'components/Button/Button';
import { StyledLink } from './ButtonLink.styled';

type Props = Pick<ButtonProps, 'kind' | 'width' | 'disabled'> & LinkProps;

/**
 * <ButtonLink> is a wrapper around React Router's <Link> that looks like one of our standard <Button>s
 */
const ButtonLink = ({ kind = 'primary', disabled = false, ...rest }: Props) => {
    return <StyledLink kind={kind} disabled={disabled} height={48} {...rest} />;
};

export default ButtonLink;
