import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'hooks/react-redux-typed';
import { Button } from 'components/Button';
import { Icon } from 'components/Icon';
import { getQueuedEpisodeUuids } from 'redux/reducers/selectors';
import * as fromUpNextActions from 'redux/actions/up-next.actions';
import { Form } from './ConfirmClearUpNextForm.styled';

type Props = {
    onSuccess: () => void;
};

const ConfirmClearUpNextForm = ({ onSuccess }: Props) => {
    const dispatch = useDispatch();
    const uuids = useSelector(getQueuedEpisodeUuids);

    const handleSubmit = () => {
        dispatch(fromUpNextActions.Actions.clearUpNext(uuids));
        onSuccess && onSuccess();
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Icon id="remove-from-upnext" size={48} />
            <p>
                <FormattedMessage id="up-next-clear-confirm" />
            </p>
            <Button type="submit">
                <FormattedMessage
                    id={uuids.length === 1 ? 'clear-episodes-singular' : 'clear-episodes-plural'}
                    values={{ count: uuids.length }}
                />
            </Button>
        </Form>
    );
};

export default ConfirmClearUpNextForm;
