import styled from 'styled-components';
import { Typography } from 'styles';

export const PlaceholderImage = styled.span<{ isLoading: boolean }>`
    transition: opacity 0.15s ease-in;
    ${props => !props.isLoading && `opacity: 0 !important;`}

    /* The placeholder should wait 100ms for the image to appear and then show itself */
    @keyframes appear {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    animation: 100ms appear ease-in-out;
    animation-fill-mode: both;
    animation-delay: 100ms;
`;

export const Img = styled.img<{ isLoaded: boolean }>`
    opacity: ${props => (props.isLoaded ? 1 : 0)};

    ${PlaceholderImage} + & {
        transition: opacity 0.1s ease-in;
    }
`;

export const MissingArtworkImg = styled.div`
    ${Typography.Desktop['H70-B']}
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    user-select: none;
    color: ${props => props.theme.tokens['contrast-01']};
    background-color: ${props => props.theme.legacyScss['navigation-background-hover']};
`;

export const Wrapper = styled.div<{ borderRadius?: number }>`
    position: relative;
    width: 100%;
    
    &:before {
        // This is a spacer block to make sure the PodcastImageWrapper is square. Since padding percentages
        // are calculated as a % of the parent's width (oddly), padding-bottom: 100% makes this spacer just
        // as tall as the wrapper is wide, forcing it to be square.
        content: '';
        display: block;
        // Trick: Padding is % of width, so this forces it to be square
        padding-bottom: 100%;
    }

    ${PlaceholderImage}, ${Img}, ${MissingArtworkImg} {
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: ${props => props.borderRadius || 3}px;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    }
`;
