import PodcastImageComponent from 'components/PodcastImage';
import styled from 'styled-components';
import { gridSize, H30, Typography } from 'styles';

const BaseWrapper = styled.div`
    min-width: 400px;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    ${H30} {
        margin: ${3 * gridSize}px 0;
    }
`;

export const CannotRateWrapper = styled(BaseWrapper)`
    margin-bottom: ${10 * gridSize}px;
`;

export const Form = styled(BaseWrapper.withComponent('form'))`
    margin-bottom: ${5 * gridSize}px;
    button {
        width: 100%;
        margin-top: ${6 * gridSize}px;
    }

    .loader-square {
        height: 48px;
        margin: 0 auto;
    }

    &.no-submit-button {
        margin-bottom: ${48 + 11 * gridSize}px;
    }
`;

export const PodcastImage = styled(PodcastImageComponent)`
    width: 160px;
    margin-top: ${2 * gridSize}px;
`;

export const Description = styled.p`
    ${Typography.Desktop.H70};
    color: ${props => props.theme.tokens['primary-text-02']};
    width: 460px;
`;
