import { RootState } from '..';
import { getBookmarksForEpisode, getBookmarksForPodcast } from './episode-sync.selectors';

export const getBookmarks = (state: RootState) => state.bookmarks.items;

export const isBookmarksFetching = (state: RootState) => state.bookmarks.isFetching;

export const getBookmarksForPage = (
    state: RootState,
    podcastUuid?: string,
    episodeUuid?: string,
) => {
    if (podcastUuid && episodeUuid) {
        return getBookmarksForEpisode(state, episodeUuid);
    }

    if (podcastUuid) {
        return getBookmarksForPodcast(state, podcastUuid);
    }

    return getBookmarks(state) || [];
};

export const getPodcastUuidsForBookmarksPage = (state: RootState, podcastUuid?: string) => {
    if (podcastUuid) {
        return [podcastUuid];
    }

    const podcastUuids = getBookmarks(state)?.map(b => b.podcastUuid) || [];
    return podcastUuids.filter((uuid, index, self) => self.indexOf(uuid) === index);
};
