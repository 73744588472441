import styled from 'styled-components';
import { gridSize, red50, Typography } from 'styles';
import { ModernStyle } from 'styles/ModernStyle.styled';

export const BackButtonStyled = styled.button`
    display: flex;
    align-items: center;
    cursor: pointer;
    color: ${props => props.color || props.theme.tokens['primary-interactive-01']};
    ${Typography.Desktop.H70};
    line-height: 1 !important;

    @keyframes see-all-arrow {
        0% {
            transform: translate(0px, 0px);
        }
        50% {
            transform: translate(4px, 0);
        }
        100% {
            transform: translate(0px, 0px);
        }
    }

    &:hover {
        > div {
            animation: see-all-arrow 1 400ms ease-in-out;
            animation-direction: alternate;
        }
    }

    ${ModernStyle} & {
        font-size: 16px;
        font-weight: 600;
        color: ${red50};
        text-decoration: none;
        svg {
            transition: transform 200ms ease;
        }
        &:hover,
        &:focus {
            > div {
                animation: none;
            }
            svg {
                transform: translateX(-3px) rotate(180deg);
            }
        }
    }
`;

export const Arrow = styled.div`
    > svg {
        display: block;
        width: 24px;
        height: 24px;
        margin-right: ${gridSize}px;
        transform: rotate(180deg);

        ${ModernStyle} & {
            width: 18px;
            height: 18px;
        }
    }
`;
