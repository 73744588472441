import { isMacApp, isWindowsApp } from 'helper/Browser';

export const VERSION = '4.5.0';

export const WEB_PLAYER_URL = process.env.PUBLIC_URL;

export const API_URL = process.env.API_URL;
export const PODCAST_URL = process.env.PODCAST_URL;
export const PODCAST_CACHE_URL = process.env.PODCAST_CACHE_URL;
export const STATIC_URL = process.env.STATIC_URL;
export const LISTS_URL = process.env.PODCAST_LISTS_URL;

export const MOBILE_CACHE_URL = process.env.MOBILE_CACHE_URL;

export const PADDLE_VENDOR_ID = Number(process.env.PADDLE_VENDOR_ID);
export const PADDLE_SKU_PATRON_MONTHLY = Number(process.env.PADDLE_SKU_PATRON_MONTHLY);
export const PADDLE_SKU_PATRON_YEARLY = Number(process.env.PADDLE_SKU_PATRON_YEARLY);
export const PADDLE_SKU_PLUS_MONTHLY = Number(process.env.PADDLE_SKU_PLUS_MONTHLY);
export const PADDLE_SKU_PLUS_YEARLY = Number(process.env.PADDLE_SKU_PLUS_YEARLY);

/*
This is currently unused, because we don't want to offer trials for monthly subscriptions at the moment.
This might change at any time, so we're keeping this around.

See also https://github.com/Automattic/pocket-casts-webplayer/pull/2734
*/
export const PADDLE_SKU_PLUS_MONTHLY_TRIAL = Number(process.env.PADDLE_SKU_PLUS_MONTHLY_TRIAL);

/*
This will only be used in the case USE_50_PERCENT_BY_DEFAULT is false, as that offer has
preference over the trial.
*/
export const PADDLE_SKU_PLUS_YEARLY_TRIAL = Number(process.env.PADDLE_SKU_PLUS_YEARLY_TRIAL);

export const PADDLE_ENVIRONMENT = process.env.PADDLE_ENVIRONMENT;

export const ALL_PADDLE_SKUS = [
    PADDLE_SKU_PATRON_MONTHLY,
    PADDLE_SKU_PATRON_YEARLY,
    PADDLE_SKU_PLUS_MONTHLY,
    PADDLE_SKU_PLUS_YEARLY,
    PADDLE_SKU_PLUS_MONTHLY_TRIAL,
    PADDLE_SKU_PLUS_YEARLY_TRIAL,
];

export const SENTRY_DSN = process.env.SENTRY_DSN;
export const DEPLOYMENT_ENV = process.env.DEPLOYMENT_ENV;
export const RELEASE_ID = process.env.RELEASE_ID;

export const API_VERSION = 2;
export const API_MODEL = 'webplayer';

export const MAX_PLAYBACK_SPEED = 5;
export const MIN_PLAYBACK_SPEED = 0.5;

// FEATURE FLAGS

export const isNotProduction =
    DEPLOYMENT_ENV && ['development', 'dev_https', 'staging', 'test'].includes(DEPLOYMENT_ENV);

export const USE_PATRON =
    new Date().getTime() >= new Date('2023-10-30').getTime() || isNotProduction;

// Desktop apps require support from the native wrappers for this to work,
// which is not yet implemented.
export const USE_IMPORT_EXPORT = !isMacApp() && !isWindowsApp();

export const USE_GIVE_RATINGS = true;

export const USE_SHARING_CLIP_CREATION = true;

export const USE_ACCORDION_EXPERIMENT = isNotProduction && !isWindowsApp();

export const USE_50_PERCENT_BY_DEFAULT = true;
export const USE_SLUMBER_STUDIOS_PARTNERSHIP = true;

export const USE_EXCLUDE_COUNTRIES_FROM_OFFER_DISCOUNTS = true;
export const excludeCountriesFromInfoOffers = ['IN', 'BR', 'ZA']; // https://wp.me/pdeCcb-4fM#comment-3591
