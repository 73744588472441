import styled from 'styled-components';
import { gridSize, userSelectNoneMixin } from '../../../styles';
import DropZoneIcon from '../../../styles/borders/drop-zone-border-white.svg';

export const FilesDropZoneWrapper = styled.div`
    display: flex;
    position: absolute;
    top: 0;
    left: 0;

    width: 100vw;
    height: 100vh;

    background-color: rgba(3, 169, 244, 0.8); /* blue50 */
    z-index: 2147483647; /* Maximum */

    justify-content: center;
    align-items: center;

    ${userSelectNoneMixin};
`;

export const DropZoneDashedContainer = styled.div`
    display: flex;
    width: calc(100vw - ${6 * gridSize}px);
    height: calc(100vh - ${6 * gridSize}px);

    align-items: center;
    justify-content: center;

    border: 4px dashed rgba(255, 255, 255, 0.5);
    border-image: url(${DropZoneIcon}) 7 round;
    border-radius: ${gridSize}px;
`;
