import 'isomorphic-fetch';
import { PodcastDiscover } from 'model/types';
import { LISTS_URL } from '../settings';
import { signOutIfHttpResponseIs401402403 } from '../helper/SignOutHelper';

const basicGet = <T>(url: string) =>
    fetch(url, { method: 'GET' })
        .then(response => {
            if (response.status !== 200) {
                return Promise.reject(Error(`${response.status}`));
            }
            return response;
        })
        .then(signOutIfHttpResponseIs401402403)
        .then(response => response.json() as T);

export const getPodcastList = (name: string) => basicGet(`${LISTS_URL}/${name}.json`);

export const getCategoryPodcasts = ({
    source,
    regionToken,
    regionCode,
}: {
    source: string;
    regionToken: string;
    regionCode: string;
}) => {
    const url = source.split(regionToken).join(regionCode);
    return basicGet<{ title: string; podcasts: PodcastDiscover[] }>(url);
};
