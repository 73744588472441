import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { LIGHT_THEME } from 'model/theme/theme-light';

const PodcastImagePlaceholder = () => {
    const theme = useContext(ThemeContext);
    return (
        <svg viewBox="0 0 268 268">
            <rect width="268" height="268" fill={theme === LIGHT_THEME ? '#edf2f5' : '#24262e'} />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M-200 268C-200 157.543 -110.457 68 0 68C110.457 68 200 157.543 200 268H150C150 185.157 82.8427 118 0 118C-82.8427 118 -150 185.157 -150 268C-150 350.843 -82.8427 418 0 418V468C-110.457 468 -200 378.457 -200 268ZM0 388C-66.2742 388 -120 334.274 -120 268C-120 201.726 -66.2742 148 0 148C66.2742 148 120 201.726 120 268H76.3636C76.3636 225.826 42.1745 191.636 0 191.636C-42.1745 191.636 -76.3636 225.826 -76.3636 268C-76.3636 310.174 -42.1745 344.364 0 344.364V388Z"
                className="placeholder-image-color-2"
                fill={theme === LIGHT_THEME ? '#e4ebf0' : '#343540'}
            />
        </svg>
    );
};

export default PodcastImagePlaceholder;
