import styled from 'styled-components';
import { blueTextLinkMixin, gridSize } from '../../../../styles';

export const UploadFilesLinkWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    height: ${2 * gridSize + 1}px;
    overflow: visible;
`;

export const UploadFilesInput = styled.input`
    display: none;
`;

export const UploadFilesButton = styled.button`
    display: block;
    width: 100%;

    ${blueTextLinkMixin};

    cursor: pointer;
    text-align: center;
`;
