export function cleanForSort(str: string) {
    return str ? str.replace(/^the /i, '').toLowerCase() : '';
}

export function compare(stringOne: string, stringTwo: string, asc: boolean) {
    return stringOne < stringTwo ? (asc ? -1 : 1) : stringOne > stringTwo ? (asc ? 1 : -1) : 0;
}

export function compareCleaned(stringOne: string, stringTwo: string, asc: boolean) {
    return compare(cleanForSort(stringOne), cleanForSort(stringTwo), asc);
}

export function compareDates(
    dateOne: Date | number | string,
    dateTwo: Date | number | string,
    asc: boolean,
) {
    const cleanedOne = dateOne == null ? '' : dateOne;
    const cleanedTwo = dateTwo == null ? '' : dateTwo;
    return cleanedOne < cleanedTwo ? (asc ? 1 : -1) : cleanedOne > cleanedTwo ? (asc ? -1 : 1) : 0;
}

export function isUrl(url: string) {
    const urlLower = url.toLowerCase();
    return urlLower.startsWith('http://') || urlLower.startsWith('https://');
}

export function isNumber(str: string) {
    return /^-?\d+$/.test(str);
}

export function cleanForFileName(str: string, maxLength = 20) {
    return str
        .replace(/[^a-zA-Z0-9]/g, '_')
        .replace(/_+/g, '_')
        .substring(0, maxLength)
        .toLowerCase();
}
