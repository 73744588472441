import React from 'react';
import { isAppleDevice } from 'helper/Browser';
import KeyboardShortcut from './KeyboardShortcut';

export const NavigateBackShortcut = () => (
    <KeyboardShortcut cmd={isAppleDevice()} alt={!isAppleDevice()} left />
);

export const NavigateForwardShortcut = () => (
    <KeyboardShortcut cmd={isAppleDevice()} alt={!isAppleDevice()} right />
);

export const AddBookmarkShortcut = () => (
    <KeyboardShortcut cmd={isAppleDevice()} ctrl={!isAppleDevice()}>
        B
    </KeyboardShortcut>
);

export { KeyboardShortcut };
