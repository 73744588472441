import { checkIfUserCanRatePodcast } from 'helper/PodcastHelper';
import * as fromPodcastActions from 'redux/actions/podcast.actions';
import {
    fetchPodcastRatingFailed,
    fetchPodcastRatingSucceeded,
    fetchPodcastShowNotesSucceeded,
} from 'redux/actions/podcasts.actions';
import { registerMiddleware } from 'redux/api';
import { getPodcastRating, getSortedEpisodesWithSyncForPodcast } from 'redux/reducers/selectors';
import { api } from 'services/api';
import cacheApi from 'services/cacheApi';

registerMiddleware('PODCAST_FETCH_RATING', async (action, store) => {
    const { uuid, forceRefetch } = action.payload;

    const currentRating = getPodcastRating(store.getState(), uuid);
    if (currentRating !== undefined && !forceRefetch) {
        // This podcast's rating has already been fetched, so don't fetch it again
        return;
    }

    try {
        const rating = await cacheApi.fetchPodcastRating(action.payload.uuid);
        store.dispatch(fetchPodcastRatingSucceeded({ uuid, rating }));
    } catch {
        store.dispatch(fetchPodcastRatingFailed({ uuid }));
    }
});

registerMiddleware([fromPodcastActions.ActionTypes.RATE_PODCAST], async action => {
    const { uuid, userRating } = action.payload;
    await api.ratePodcast(uuid, userRating);
});

registerMiddleware([fromPodcastActions.ActionTypes.FETCH_USER_RATING], async (action, store) => {
    const { uuid } = action.payload;
    const episodes = getSortedEpisodesWithSyncForPodcast(store.getState(), uuid);

    // Don't make a call to fetch a user rating if they can't rate. The endpoint will 404.
    if (episodes && !checkIfUserCanRatePodcast(episodes)) {
        return;
    }

    try {
        const rating = await api.fetchUserPodcastRating(uuid);
        store.dispatch(
            fromPodcastActions.Actions.fetchUserRatingSucceeded(uuid, rating.podcastRating),
        );
    } catch {
        store.dispatch(fromPodcastActions.Actions.fetchUserRatingFailed(uuid));
    }
});

registerMiddleware('PODCAST_FETCH_SHOW_NOTES', async (action, store) => {
    const { uuid } = action.payload;

    const episodeShowNotes = await cacheApi.fetchPodcastShowNotes(action.payload.uuid);
    store.dispatch(fetchPodcastShowNotesSucceeded({ uuid, episodeShowNotes }));
});
