import React, { Component } from 'react';
import {
    FileEditModalTooltipWrapper,
    TooltipTriangle,
    TooltipBodyWrapper,
    TooltipBody,
    TooltipText,
} from './styled';

export class FileEditModalTooltip extends Component {
    render() {
        const { text, top, bottom, left, right } = this.props;

        return (
            <FileEditModalTooltipWrapper top={top} bottom={bottom} left={left} right={right}>
                <TooltipBodyWrapper>
                    <TooltipBody>
                        <TooltipText>{text}</TooltipText>
                    </TooltipBody>
                </TooltipBodyWrapper>
                <TooltipTriangle />
            </FileEditModalTooltipWrapper>
        );
    }
}

export default FileEditModalTooltip;
