import { useMediaQuery } from 'hooks/useMedia';
import { SubscriptionTier } from 'model/types';
import React from 'react';
import Confetti from 'react-confetti';
import Particle from 'react-confetti/dist/types/Particle.d';

export type Props = {
    tier?: SubscriptionTier;
};

function drawPlus(this: Particle, ctx: CanvasRenderingContext2D) {
    const plus = new Path2D('M6 1H10V6H15.5L14.5 10H10V15H6V10H0.5L1.5 6H6V1Z');
    const scale = 1.25 + this.shape * 0.33;
    ctx.scale(scale, scale);
    ctx.translate(-7, -7);

    ctx.globalAlpha = 1 - this.shape * 0.15;
    ctx.fill(plus);
}

function drawPatron(this: Particle, ctx: CanvasRenderingContext2D) {
    const heart = new Path2D(
        'M19.2644 14.5166C20.2614 12.4479 19.3989 9.97394 17.3353 8.97314C16.9657 8.78624 16.5685 8.66744 16.1639 8.60158L15.4454 8.46461L15.5142 7.76037C15.6952 6.44073 15.229 5.11967 14.2681 4.21087C13.3223 3.29069 11.9933 2.88799 10.7011 3.12025C8.97177 3.42153 7.63806 4.81309 7.40641 6.55746L6.10481 14.9321C6.05266 15.2712 6.28649 15.592 6.63437 15.6453C6.6394 15.6416 6.64823 15.6428 6.64823 15.6428L15.0473 16.6972C16.793 16.9516 18.5071 16.0543 19.2763 14.4728L19.2644 14.5166Z',
    );
    const scale = 1 + this.shape * 0.33;
    ctx.scale(scale, scale);
    ctx.translate(-11, -11);

    ctx.globalAlpha = 1 - this.shape * 0.15;
    ctx.fill(heart);
}

const SubscriberConfetti = ({ tier = 'Plus' }: Props) => {
    const shouldReduceMotion = useMediaQuery('(prefers-reduced-motion: reduce)');
    if (shouldReduceMotion) {
        return null;
    }

    const colors = tier === 'Plus' ? ['#ffb626'] : ['#7A64F6'];
    const drawShape = tier === 'Plus' ? drawPlus : drawPatron;

    return (
        <Confetti
            numberOfPieces={250}
            recycle={false}
            gravity={0.4}
            colors={colors}
            drawShape={drawShape}
        />
    );
};

export default SubscriberConfetti;
