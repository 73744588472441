import styled, { css } from 'styled-components';
import { Typography, visuallyHidden } from 'styles';

export const SearchWidgetWrapper = styled.form<{ isExpanded: boolean }>`
    position: absolute;

    top: 0;
    right: 0;

    z-index: 20;

    input {
        ${Typography.Desktop.H70}
        box-sizing: border-box;
        height: 30px;
        margin: 17px 0 8px 0;
        transition: width 0.3s;
        border-radius: 4px;
        outline: 0;
        padding: 0 0 0 37px;
        background-position: 13px center;
        background-color: ${props => props.theme.legacyScss['theme-button-disabled']};
        border: 1px solid ${props => props.theme.legacyScss['theme-button-disabled']};
        color: ${props => props.theme.legacyScss['theme-text-primary']};
        background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%3E%3Cpath%20fill%3D%22%23B8C3C9%22%20d%3D%22M11.757%2013.172c-1.94%201.286-4.582%201.074-6.293-.637-1.952-1.952-1.952-5.118%200-7.07%201.953-1.953%205.119-1.953%207.071%200%201.71%201.71%201.923%204.351.637%206.292l2.192%202.193c.39.39.39%201.023%200%201.414-.39.39-1.024.39-1.414%200l-2.193-2.192zm-.636-2.05c1.172-1.172%201.172-3.072%200-4.244-1.171-1.171-3.071-1.171-4.243%200-1.171%201.172-1.171%203.072%200%204.243%201.172%201.172%203.072%201.172%204.243%200z%22%2F%3E%3C%2Fsvg%3E');
        background-repeat: no-repeat;
        &::placeholder {
            color: ${props => props.theme.tokens['primary-text-02']};
            opacity: 0.6;
        }

        width: 190px;
        @media (min-width: 580px) {
            width: 260px;
        }
        ${props =>
            props.isExpanded &&
            css`
                background-color: transparent;
                &::placeholder {
                    opacity: 1;
                }
                @media (min-width: 800px) {
                    width: 450px;
                }
            `}
    }
`;

export const CancelButton = styled.button`
    transition: opacity 0.3s ease-in-out;
    position: absolute;
    box-sizing: border-box;
    top: 17px;
    right: 0;
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${props => props.theme.tokens['primary-text-02']};
    opacity: 0.55;
    &:hover {
        opacity: 1;
    }
`;

export const AssistiveText = styled.div`
    ${visuallyHidden}
`;

export const SearchResultsWrapper = styled.div`
    position: absolute;
    right: 0;
    top: 100%;
`;
