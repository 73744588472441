import { PaddleDiscountedPlan, PaddlePlan, SubscriptionTier } from 'model/types';
import { TranslatableStringKey } from 'translations/glotpress';

export type PlanVariables = {
    tier: SubscriptionTier;
    perIntervalText: TranslatableStringKey;
    switchIntervalText?: TranslatableStringKey;
    otherIntervalPlan?: PaddlePlan;
    otherTierPlan?: PaddlePlan;
};

export const PLAN_VARIABLES: Record<PaddlePlan | PaddleDiscountedPlan, PlanVariables> = {
    'patron-monthly': {
        tier: 'Patron',
        switchIntervalText: 'switch-to-yearly',
        perIntervalText: 'month',
        otherIntervalPlan: 'patron-yearly',
        otherTierPlan: 'plus-monthly',
    },
    'patron-yearly': {
        tier: 'Patron',
        switchIntervalText: 'switch-to-monthly',
        perIntervalText: 'year',
        otherIntervalPlan: 'patron-monthly',
        otherTierPlan: 'plus-yearly',
    },
    'plus-monthly': {
        tier: 'Plus',
        switchIntervalText: 'switch-to-yearly',
        perIntervalText: 'month',
        otherIntervalPlan: 'plus-yearly',
        otherTierPlan: 'patron-monthly',
    },
    'plus-yearly': {
        tier: 'Plus',
        switchIntervalText: 'switch-to-monthly',
        perIntervalText: 'year',
        otherIntervalPlan: 'plus-monthly',
        otherTierPlan: 'patron-yearly',
    },
    'switch-to-pocket-casts': {
        tier: 'Plus',
        perIntervalText: 'year',
    },
};
