import styled from 'styled-components';
import { gridSize, H40, H60 } from '../../../styles';

export const Wrapper = styled.div`
    margin: ${5 * gridSize}px auto 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Heading = styled(H40)`
    margin: ${gridSize / 2}px 0;
`;

export const Description = styled(H60)`
    width: 480px;

    color: ${props => props.theme.legacyScss['theme-text-secondary']};
    margin-bottom: ${4 * gridSize}px;
`;
