import { UpNextEpisode } from 'model/types';
import { createAction, ActionsUnion } from './action-creators';

export enum ActionTypes {
    HISTORY_ADD = '@player-history/HISTORY_ADD',
    HISTORY_DELETE = '@player-history/HISTORY_DELETE',

    HISTORY_CLEAR_CONFIRMATION_SHOW = '@player-history/HISTORY_CLEAR_CONFIRMATION_SHOW',
    HISTORY_CLEAR_CONFIRMATION_HIDE = '@player-history/HISTORY_CLEAR_CONFIRMATION_HIDE',
    HISTORY_CLEAR = '@player-history/HISTORY_CLEAR',
}

export const Actions = {
    addHistory: (podcastUuid: string, episode: UpNextEpisode) =>
        createAction(ActionTypes.HISTORY_ADD, { podcastUuid, episode }),

    deleteHistory: (episode: UpNextEpisode) =>
        createAction(ActionTypes.HISTORY_DELETE, { episode }),

    clearHistory: () => createAction(ActionTypes.HISTORY_CLEAR),
    showClearHistoryConfirmation: () => createAction(ActionTypes.HISTORY_CLEAR_CONFIRMATION_SHOW),
    dismissClearHistoryConfirmation: () =>
        createAction(ActionTypes.HISTORY_CLEAR_CONFIRMATION_HIDE),
};

export type Actions = ActionsUnion<typeof Actions>;
