import styled from 'styled-components';
import { fontFamilyRetina } from './typography';

export const defaultFontStack =
    '-apple-system,BlinkMacSystemFont,Inter,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,Cantarell,Helvetica Neue,sans-serif';

export const ModernStyle = styled.span`
    /* Force override the Retina font on all children of ModernStyle */
    * {
        font-family: ${defaultFontStack} !important;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: ${fontFamilyRetina} !important;
        font-weight: 700;
    }
    a {
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
`;
