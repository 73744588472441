import { DEPLOYMENT_ENV } from 'settings';
import { analytics } from '../services/firebase';

export enum AnalyticsEvents {
    DiscoverOpen = 'discover_open',
    DiscoverListEpisodeTap = 'discover_list_podcast_episode_tap',
    DiscoverListPodcastTap = 'discover_list_podcast_tap',
    DiscoverPodcastSubscribe = 'discover_list_podcast_subscribe',
    DiscoverEpisodePlay = 'discover_list_episode_play',
    DiscoverShowAll = 'discover_list_show_all',
    DiscoverListImpression = 'discover_list_impression',
    DiscoverCategoryOpen = 'category_open',
    DiscoverCategoryPageOpen = 'category_page_open_',
    PodcastsWebOpen = 'podcasts_web_open',
    DiscoverWebOpen = 'discover_web_open',
    NewReleasesWebOpen = 'new_releases_web_open',
    InProgressWebOpen = 'in_progress_web_open',
    StarredWebOpen = 'starred_web_open',
    FilesWebOpen = 'files_web_open',
    ProfileMenuWebOpen = 'profile_web_open',
    UpNextOpen = 'up_next_open',
    PodcastOpen = 'podcast_open',
    EpisodeOpen = 'episode_open',
}

export const logEvent = (
    eventName: AnalyticsEvents | string,
    eventData?: Record<string, unknown>,
) => {
    if (DEPLOYMENT_ENV !== 'production') {
        // Uncomment next line for logging in Desktop Apps (view in Settings->Help->Send feedback->info icon)
        // Logger.log(`ANALYTICS:  ${eventName} ${JSON.stringify(eventData)}`);
        console.info('ANALYTICS:', eventName, eventData);
        return;
    }

    // The firebase typings are a bit weird for this event, so typecast as any for now
    analytics?.logEvent(eventName as any, eventData);
};
