import { Icon } from 'components/Icon';
import { AddBookmarkShortcut } from 'components/KeyboardShortcut';
import { RowAction, RowCell } from 'components/Row';
import { DeleteBookmarkForm } from 'components/form/bookmarks/DeleteBookmarkForm';
import { EditBookmarkForm } from 'components/form/bookmarks/EditBookmarkForm';
import { TimeText } from 'components/format/TimeText';
import { ImageTextOption } from 'components/popup/ImageTextOption';
import { PopupMenu } from 'components/popup/PopupMenu';
import { useSelector } from 'hooks/react-redux-typed';
import useFormatMessage from 'hooks/useFormatMessage';
import { THEME } from 'model/theme';
import {
    Bookmark,
    BookmarksSortOrder,
    Episode,
    Podcast,
    TracksEventSource,
    UploadedFile,
} from 'model/types';

import {
    UPLOADED_FILES_PODCAST_UUID,
    UPLOADED_FILE_COLORS,
    getUploadedFileIconUrl,
} from 'model/uploaded-files';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FocusOn } from 'react-focus-on';
import { FormattedDate, FormattedTime } from 'react-intl';
import { useDispatch } from 'react-redux';
import * as fromEpisodeActions from 'redux/actions/episode.actions';
import * as fromPlayerActions from 'redux/actions/player.actions';
import * as fromTracksActions from 'redux/actions/tracks.actions';
import { getPodcastsByUuid, getUploadedFilesMap } from 'redux/reducers/selectors';
import {
    getBookmarksForPage,
    getPodcastUuidsForBookmarksPage,
} from 'redux/reducers/selectors/bookmarks.selectors';
import { useTheme } from 'styled-components';
import { H40, H70, coolGrey60, red } from 'styles';
import { LoaderSquare } from '../../components/index';
import * as fromPodcastsActions from '../../redux/actions/podcasts.actions';
import * as fromShareActions from '../../redux/actions/share.actions';
import {
    BookmarkDetails,
    BookmarkTitle,
    EmptyMessage,
    FileImage,
    PlayPauseButton,
    PodcastImage,
    PopupCloseButton,
    PopupForm,
    Row,
    RowContent,
    RowImage,
    RowTitle,
    Wrapper,
} from './BookmarkList.styled';

export type Props = {
    podcastUuid?: string;
    episodeUuid?: string;
    searchTerm?: string;
    sort?: number;
    limit?: number;
    showActions?: boolean;
    inlineActions?: boolean;
    eventSource: TracksEventSource;
};

const BookmarkList = ({
    podcastUuid,
    episodeUuid,
    limit,
    showActions = true,
    inlineActions,
    eventSource,
    sort,
    searchTerm,
}: Props) => {
    const formatMessage = useFormatMessage();
    const dispatch = useDispatch();
    const theme = useTheme();
    const showingAll = !episodeUuid && !podcastUuid;

    const [editingBookmark, setEditingBookmark] = useState<Bookmark>();
    const [deletingBookmark, setDeletingBookmark] = useState<Bookmark>();

    const bookmarks = useSelector(state =>
        getBookmarksForPage(state, podcastUuid, episodeUuid),
    ).slice(0, limit);
    const podcastUuids = useSelector(state => getPodcastUuidsForBookmarksPage(state, podcastUuid));
    const podcasts = useSelector<Podcast[] | undefined>(state =>
        getPodcastsByUuid(state, podcastUuids),
    );
    const files = useSelector(getUploadedFilesMap);
    const getPodcastByUuid = (uuid: string) => podcasts?.find(podcast => podcast.uuid === uuid);

    const dispatchedPodcastUuids = useRef<string[]>([]);
    useEffect(() => {
        if (podcastUuids && bookmarks) {
            const includeEpisodes = showingAll;
            podcastUuids.forEach(uuid => {
                const podcast = getPodcastByUuid(uuid);
                if (
                    uuid !== UPLOADED_FILES_PODCAST_UUID &&
                    (!podcast || !podcast.episodes) && // We want to download episodes if we don't have them
                    !dispatchedPodcastUuids.current.includes(uuid)
                ) {
                    dispatch(fromPodcastsActions.Actions.downloadPodcast(uuid, includeEpisodes));
                    dispatchedPodcastUuids.current.push(uuid);
                }
            });
        }
    }, [dispatch, podcasts, podcastUuids, bookmarks]);

    const onEpisodeClick = useCallback(
        (episodeUuid: string, podcastUuid: string) => {
            dispatch(
                fromEpisodeActions.Actions.openEpisode(
                    {
                        uuid: episodeUuid,
                        podcastUuid,
                    },
                    {
                        eventSource: 'bookmarks',
                    },
                ),
            );
        },
        [dispatch],
    );

    const onFileClick = useCallback(
        (file: UploadedFile) => {
            dispatch(
                fromEpisodeActions.Actions.openEpisode(
                    { ...file, podcastUuid: UPLOADED_FILES_PODCAST_UUID },
                    {
                        eventSource: 'bookmarks',
                    },
                ),
            );
        },
        [dispatch],
    );

    const clearPopupForms = () => {
        setEditingBookmark(undefined);
        setDeletingBookmark(undefined);
    };

    const handlePlayClick = (bookmark: Bookmark) => {
        dispatch(
            fromPlayerActions.Actions.playEpisode(
                bookmark.episodeUuid,
                bookmark.podcastUuid,
                { eventSource },
                { seekTo: bookmark.time },
            ),
        );
        dispatch(
            fromTracksActions.Actions.recordEvent('bookmark_play_tapped', {
                episode_uuid: bookmark.episodeUuid,
                podcast_uuid: bookmark.podcastUuid,
                source: eventSource,
            }),
        );
    };

    const handleShareClick = (
        bookmark: Bookmark,
        episodeTitle: string,
        episodeDuration: number,
        audioUrl: string,
    ) => {
        dispatch(
            fromShareActions.Actions.openEpisodeShare(
                bookmark.episodeUuid,
                bookmark.podcastUuid,
                episodeTitle,
                episodeDuration,
                audioUrl,
                { eventSource: 'bookmarks_page', time: bookmark.time },
            ),
        );
    };

    const renderContent = () => {
        if (
            podcastUuid &&
            podcasts &&
            !podcasts[0] &&
            podcastUuid !== UPLOADED_FILES_PODCAST_UUID
        ) {
            return <LoaderSquare />;
        }

        if (bookmarks.length === 0) {
            return (
                <EmptyMessage>
                    <H40>{formatMessage('no-bookmarks-yet')}</H40>
                    <H70>
                        {formatMessage('add-bookmarks-instructions', {
                            shortcut: (
                                <>
                                    &nbsp;
                                    <AddBookmarkShortcut />
                                </>
                            ),
                        })}
                    </H70>
                </EmptyMessage>
            );
        }

        let bookmarksList = bookmarks;
        if (searchTerm) {
            const term = searchTerm.trim().toLowerCase();
            const filteredBookmarks = bookmarks.filter(bookmark => {
                const podcast = getPodcastByUuid(bookmark.podcastUuid);
                const episode = podcast?.episodes?.find(e => e.uuid === bookmark.episodeUuid);
                return (
                    podcast?.title?.toLowerCase().includes(term) ||
                    episode?.title?.toLowerCase().includes(term) ||
                    bookmark.title.toLowerCase().includes(term)
                );
            });

            if (filteredBookmarks.length === 0) {
                return (
                    <EmptyMessage>
                        <H40>{formatMessage('no-bookmarks-found')}</H40>
                    </EmptyMessage>
                );
            }

            bookmarksList = filteredBookmarks;
        }

        if (sort === BookmarksSortOrder.OLDEST_TO_NEWEST) {
            bookmarksList.sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1));
        } else if (sort === BookmarksSortOrder.PODCAST_AND_EPISODE) {
            bookmarksList.sort((a, b) => {
                if (a.podcastUuid === b.podcastUuid) {
                    if (a.episodeUuid === b.episodeUuid) {
                        return a.time < b.time ? -1 : 1;
                    }
                    return a.episodeUuid > b.episodeUuid ? -1 : 1;
                }
                return a.podcastUuid > b.podcastUuid ? -1 : 1;
            });
        } else {
            bookmarksList.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
        }

        return (
            <>
                {bookmarksList.map(bookmark => {
                    const isUploadedFile = bookmark.podcastUuid === UPLOADED_FILES_PODCAST_UUID;

                    const podcastTitle = showingAll
                        ? isUploadedFile
                            ? formatMessage('uploaded-file')
                            : getPodcastByUuid(bookmark.podcastUuid)?.title
                        : '';

                    const episode =
                        !episodeUuid && podcasts
                            ? isUploadedFile
                                ? files[bookmark.episodeUuid]
                                : getPodcastByUuid(bookmark.podcastUuid)?.episodes?.find(
                                      e => e.uuid === bookmark.episodeUuid,
                                  )
                            : null;

                    const file = isUploadedFile ? files[bookmark.episodeUuid] : null;

                    return (
                        <Row key={bookmark.bookmarkUuid}>
                            <RowContent>
                                {showingAll && (
                                    <RowImage>
                                        {isUploadedFile && episode && file ? (
                                            <button onClick={() => onFileClick(file)}>
                                                {file.imageUrl ? (
                                                    <FileImage
                                                        src={file.imageUrl}
                                                        alt={episode.title}
                                                    />
                                                ) : (
                                                    <FileImage
                                                        src={getUploadedFileIconUrl(
                                                            THEME.dark,
                                                            UPLOADED_FILE_COLORS.grey,
                                                        )}
                                                        alt={episode.title}
                                                    />
                                                )}
                                            </button>
                                        ) : (
                                            <button
                                                onClick={() =>
                                                    onEpisodeClick(
                                                        bookmark.episodeUuid,
                                                        bookmark.podcastUuid,
                                                    )
                                                }
                                            >
                                                <PodcastImage
                                                    className="episode-table-image"
                                                    title={podcastTitle}
                                                    uuid={bookmark.podcastUuid}
                                                />
                                            </button>
                                        )}
                                    </RowImage>
                                )}
                                <RowTitle>
                                    {episode?.title && (
                                        <BookmarkDetails>{episode.title}</BookmarkDetails>
                                    )}
                                    <BookmarkTitle>{bookmark.title}</BookmarkTitle>
                                    {(episodeUuid || showingAll) && (
                                        <BookmarkDetails>
                                            {formatMessage('date-at-time', {
                                                date: (
                                                    <FormattedDate
                                                        value={bookmark.createdAt}
                                                        day="numeric"
                                                        month="long"
                                                        key="date"
                                                    />
                                                ),
                                                time: (
                                                    <FormattedTime
                                                        value={bookmark.createdAt}
                                                        key="time"
                                                    />
                                                ),
                                            })}
                                        </BookmarkDetails>
                                    )}
                                </RowTitle>

                                {showActions && inlineActions && (
                                    <>
                                        {!isUploadedFile && (
                                            <RowAction
                                                onClick={() =>
                                                    handleShareClick(
                                                        bookmark,
                                                        episode?.title || '',
                                                        Number(episode?.duration),
                                                        (episode as Episode).url ?? '',
                                                    )
                                                }
                                                aria-label={formatMessage('share-episode')}
                                            >
                                                <Icon id="share" color={coolGrey60} />
                                            </RowAction>
                                        )}
                                        <RowAction
                                            onClick={() => setEditingBookmark(bookmark)}
                                            aria-label={formatMessage('change-title')}
                                        >
                                            <Icon id="edit" color={coolGrey60} />
                                        </RowAction>
                                        <RowAction
                                            onClick={() => setDeletingBookmark(bookmark)}
                                            aria-label={formatMessage('delete')}
                                        >
                                            <Icon id="delete" color={red} />
                                        </RowAction>
                                    </>
                                )}

                                <RowCell>
                                    <PlayPauseButton
                                        size={34}
                                        filled
                                        onClick={() => handlePlayClick(bookmark)}
                                        text={<TimeText timeSecs={bookmark.time} />}
                                    />
                                </RowCell>
                                {showActions && !inlineActions && (
                                    <RowCell>
                                        <PopupMenu id="bookmark-list-popup">
                                            <ImageTextOption
                                                icon="edit"
                                                color={theme.tokens['primary-icon-01']}
                                                text={formatMessage('change-title')}
                                                onClick={() => setEditingBookmark(bookmark)}
                                            />

                                            <ImageTextOption
                                                icon="delete"
                                                color={theme.tokens['support-05']}
                                                text={formatMessage('delete')}
                                                onClick={() => setDeletingBookmark(bookmark)}
                                            />
                                        </PopupMenu>
                                    </RowCell>
                                )}
                            </RowContent>
                        </Row>
                    );
                })}
            </>
        );
    };

    return (
        <Wrapper>
            {renderContent()}
            {(editingBookmark || deletingBookmark) && (
                <FocusOn
                    scrollLock={false}
                    onEscapeKey={clearPopupForms}
                    onClickOutside={clearPopupForms}
                >
                    <PopupForm>
                        {editingBookmark ? (
                            <EditBookmarkForm
                                bookmark={editingBookmark}
                                onSubmit={clearPopupForms}
                            />
                        ) : deletingBookmark ? (
                            <DeleteBookmarkForm
                                bookmark={deletingBookmark}
                                onSubmit={clearPopupForms}
                            />
                        ) : null}
                        <PopupCloseButton onClick={clearPopupForms}>
                            <Icon id="cancel" />
                        </PopupCloseButton>
                    </PopupForm>
                </FocusOn>
            )}
        </Wrapper>
    );
};

export default BookmarkList;
