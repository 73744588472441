import { connect } from 'react-redux';
import { getFilterEpisodesWithSync } from 'redux/reducers/selectors/filter.selectors';
import * as fromFilterActions from '../../redux/actions/filter.actions';
import * as fromEpisodeActions from '../../redux/actions/episode.actions';
import * as fromHistoryActions from '../../redux/actions/history.actions';
import FilterPage from './view';

const mapStateToProps = (state, ownProps) => ({
    episodes: getFilterEpisodesWithSync(state, ownProps.id),
    settings: state.settings,
    filter: state.filter,
    uuidToPodcast: state.podcasts.uuidToPodcast,
    upNext: state.upNext,
    player: state.player,
    inHistory: ownProps.id === 'history',
});

const mapDispatchToProps = dispatch => ({
    downloadFilter: id => dispatch(fromFilterActions.Actions.downloadFilter(id)),
    openEpisode: (episode, options) =>
        dispatch(fromEpisodeActions.Actions.openEpisode(episode, options)),
    showClearHistoryConfirmation: () =>
        dispatch(fromHistoryActions.Actions.showClearHistoryConfirmation()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterPage);
