import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { gridSize, visuallyHidden } from 'styles';
import { UnplayedBadge } from '../PodcastsPage.styled';

export const AssistiveText = styled.div`
    ${visuallyHidden}
`;

export const Grid = styled.ol<{ isLarge: boolean; isSorting: boolean }>`
    // HACK: There's a bug in react-sortable-hoc with line-wrapping when you move an item to a higher row.
    // Fixing it requires a scrollable parent that's close in dimension to the grid, to ensure that while
    // dragging, items never exceed the bounds of the grid and line-wrap appropriately.
    // See: https://github.com/clauderic/react-sortable-hoc/issues/617
    overflow-y: auto;
    margin: 0 -20px;
    padding: ${props => (props.isLarge ? 3 * gridSize : 2 * gridSize)}px 20px 20px;
    // END HACK

    display: grid;
    gap: ${props => (props.isLarge ? 16 : 10)}px;
    grid-template-columns: repeat(${props => (props.isLarge ? 7 : 10)}, minmax(0, 1fr));
    @media only screen and (min-width: 1360px) {
        grid-template-columns: repeat(${props => (props.isLarge ? 8 : 12)}, minmax(0, 1fr));
    }

    ${props => props.isSorting && `&, ${TileLink} { cursor: grabbing; }`}

    ${props =>
        !props.isSorting &&
        // Only show tile hover effects when we're not in the middle of a sort
        `
            ${TileLink}:hover,
            ${TileLink}:focus {
                transform: scale(1.1);
            }
    `}
`;

export const TileWrapper = styled.li`
    margin: 0;
    padding: 0;
    list-style-type: none;
`;

export const TileLink = styled(Link)`
    display: block;
    position: relative;
    width: 100%;
    transition: transform 100ms ease-out;
    * {
        pointer-events: none; // Prevent dragging conflicts with react-sortable-hoc
    }
    .is-dragging & {
        transform: scale(1.15);
        ${UnplayedBadge} {
            transform-origin: 95% 5%;
            @keyframes hide-to-corner {
                0% {
                    transform: scale(1);
                }
                100% {
                    transform: scale(0);
                }
            }
            animation: 100ms hide-to-corner ease-out;
            animation-fill-mode: both;
        }
        @keyframes wobble {
            0% {
                transform: scale(1.15) rotate(0deg);
                animation-timing-function: ease-out;
            }
            25% {
                transform: scale(1.15) rotate(-1.5deg);
                animation-timing-function: ease-in-out;
            }
            75% {
                transform: scale(1.15) rotate(1.5deg);
                animation-timing-function: ease-in;
            }
        }

        @media (prefers-reduced-motion: no-preference) {
            animation: wobble 600ms infinite;
        }
    }
`;
