import React from 'react';
import useFormatMessage from 'hooks/useFormatMessage';
import { POCKET_CASTS_SUPPORT } from '../../../model/external-links';
import { SubscriptionPlatform } from '../../../model/types';
import { SmallLinksWrapper, AboutLink, AboutText } from './SmallLinks.styled';

type Props = {
    platform: number;
    autoRenewing: boolean;
};

function SmallLinks({ platform, autoRenewing }: Props) {
    const formatMessage = useFormatMessage();
    if (platform === SubscriptionPlatform.gift || !autoRenewing) {
        return (
            <SmallLinksWrapper>
                <AboutLink target="_blank" href={`${POCKET_CASTS_SUPPORT}/article/privacy-policy`}>
                    <AboutText>{formatMessage('about-subscriptions-and-privacy')}</AboutText>
                </AboutLink>
            </SmallLinksWrapper>
        );
    }

    return (
        <SmallLinksWrapper>
            <AboutLink target="_blank" href={`${POCKET_CASTS_SUPPORT}/article/privacy-policy`}>
                <AboutText>{formatMessage('about-subscriptions-and-privacy')}</AboutText>
            </AboutLink>
        </SmallLinksWrapper>
    );
}

export default SmallLinks;
