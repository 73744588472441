import { Icon, IconId } from 'components/Icon';
import React from 'react';
import { ImageTextOptionContainer } from './ImageTextOption.styled';

export type ImageTextOptionProps = {
    icon: IconId;
    text: string;
    color?: string;
    onClick?: (e: React.SyntheticEvent | Event) => void;
    onClose?: (e: React.SyntheticEvent | Event) => void;
};

class ImageTextOption extends React.Component<ImageTextOptionProps> {
    render() {
        const { icon, text, color } = this.props;
        return (
            <ImageTextOptionContainer
                aria-label={text}
                key={icon}
                onClick={this.onOptionClick.bind(this)}
                color={color}
            >
                {this.props.children || <Icon id={icon} color={color} />}
                <div className="option-title">{text}</div>
            </ImageTextOptionContainer>
        );
    }

    onOptionClick(event: React.MouseEvent | Event) {
        if (this.props.onClick) {
            this.props.onClick(event);
        }
        if (this.props.onClose) {
            this.props.onClose(event);
        }
    }
}

export default ImageTextOption;
