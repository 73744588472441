import classNames from 'classnames';
import React from 'react';
import { TranslatableStringKey } from 'translations/glotpress';
import { SeparatorWrapper, Line, Text } from './Separator.styled';

type Props = {
    className?: string;
    message?: TranslatableStringKey;
};

function Separator({ className, message }: Props) {
    // Keep the classnames for now since other components use it still
    const classes = classNames('separator', className);

    return (
        <SeparatorWrapper className={classes}>
            <Line />
            {message && <Text>{message}</Text>}
            <Line />
        </SeparatorWrapper>
    );
}

export default Separator;
