import { EpisodeSyncInfo, PodcastListPodcast } from 'model/types';
import { TranslatableStringKey } from 'translations/glotpress';
import { PodcastGridOrder } from '../pages/PodcastsPage/model';
import * as Settings from '../settings';
import * as StringHelper from './StringHelper';

export const EpisodeSortOrder = {
    NAME_A_TO_Z: 0,
    NAME_Z_TO_A: 1,
    OLDEST_TO_NEWEST: 2,
    NEWEST_TO_OLDEST: 3,
    LENGTH_DESC: 4,
    LENGTH_ASC: 5,
};

export const EpisodeSortOrderMessages: Record<string, TranslatableStringKey> = {
    NAME_A_TO_Z: 'alphabetical',
    NAME_Z_TO_A: 'alphabetical-reversed',
    OLDEST_TO_NEWEST: 'release-date-reversed',
    NEWEST_TO_OLDEST: 'release-date',
    LENGTH_DESC: 'duration-order',
    LENGTH_ASC: 'duration-reversed',
};

// Typing for the sorting function — we don't care what the type is, as long as it has these values
export type SortablePodcastListItem = {
    dateAdded: string;
    lastEpisodePublished: string;
    title: string;
    sortPosition: number;
};

const compareTitle = <T extends SortablePodcastListItem>(a: T, b: T) =>
    StringHelper.compareCleaned(a.title, b.title, true);

const compareSortPosition = <T extends SortablePodcastListItem>(a: T, b: T) =>
    a.sortPosition < b.sortPosition ? -1 : 1;

const compareDateAdded = <T extends SortablePodcastListItem>(a: T, b: T) =>
    StringHelper.compareDates(a.dateAdded ?? '', b.dateAdded ?? '', false) || compareTitle(a, b);

const compareReleaseDate = <T extends SortablePodcastListItem>(a: T, b: T) =>
    StringHelper.compareDates(a.lastEpisodePublished, b.lastEpisodePublished, true) ||
    compareTitle(a, b);

// Note: This function mutates the items list, just like Array.sort
export function sortPodcastListItems<T extends SortablePodcastListItem[]>(
    items: T,
    sortType: PodcastGridOrder,
) {
    switch (sortType) {
        case PodcastGridOrder.RELEASE_DATE:
            return items.sort(compareReleaseDate);
        case PodcastGridOrder.DATE_ADDED:
            return items.sort(compareDateAdded);
        case PodcastGridOrder.USER_SORTED:
            return items.sort(compareSortPosition);
        default:
            return items.sort(compareTitle);
    }
}

export function cleanFields(podcast: PodcastListPodcast) {
    const podcastToClean = podcast;
    if (podcastToClean.autoStartFrom == null) {
        podcastToClean.autoStartFrom = 0;
    }
    if (podcastToClean.episodesSortOrder == null || podcastToClean.episodesSortOrder < 0) {
        podcastToClean.episodesSortOrder = EpisodeSortOrder.NAME_A_TO_Z;
    }
    return podcastToClean;
}

function isRetina() {
    return (window.devicePixelRatio ? window.devicePixelRatio : 1) > 1;
}

export function getDiscoverUrlJpg(size: number, uuid: string) {
    let roundedSize = 680;
    if (size <= 130) {
        roundedSize = 130;
    } else if (size <= 140) {
        roundedSize = 140;
    } else if (size <= 200) {
        roundedSize = 200;
    } else if (size <= 280) {
        roundedSize = 280;
    } else if (size <= 340) {
        roundedSize = 340;
    } else if (size <= 400) {
        roundedSize = 400;
    } else if (size <= 420) {
        roundedSize = 420;
    }

    return `${Settings.STATIC_URL}/discover/images/${roundedSize}/${uuid}.jpg`;
}

export function getDiscoverUrlWebp(size: number, uuid: string) {
    let roundedSize = 960;
    if (size <= 200) {
        roundedSize = 200;
    } else if (size <= 480) {
        roundedSize = 480;
    }

    return `${Settings.STATIC_URL}/discover/images/webp/${roundedSize}/${uuid}.webp`;
}

export function getImageUrl(size: number, uuid: string, webp: boolean) {
    const scaledSize = isRetina() ? size * 2 : size;

    if (webp) {
        return getDiscoverUrlWebp(scaledSize, uuid);
    }

    return getDiscoverUrlJpg(scaledSize, uuid);
}

/*
 * Finding the intersection of the two arrays is potentially very expensive if one list is very long.
 * By mapping over the shorter array first, we short-circuit any redundant searching that would occur
 * if the shorter array had already matched all of its elements.
 */
export const getPodcastUuidIntersection = (uuids1: string[], uuids2: string[]) => {
    const shorterArray = uuids1.length < uuids2.length ? uuids1 : uuids2;
    const longerArray = uuids1.length < uuids2.length ? uuids2 : uuids1;

    return shorterArray.filter(uuid => longerArray.indexOf(uuid) !== -1);
};

/**
 * To rate a podcast, a user must have listened to at least 2 episodes for more than half of the episode duration.
 * See https://github.com/Automattic/pocket-casts-sync-api/pull/987
 */
export const checkIfUserCanRatePodcast = (episodes: EpisodeSyncInfo[]): boolean => {
    const listenedEpisodes = episodes.filter(episode => episode.playedUpTo > episode.duration / 2);
    return listenedEpisodes.length >= 2;
};
