import React from 'react';
import useFormatMessage from 'hooks/useFormatMessage';
import { Icon } from 'components/Icon';
import {
    DropOrBrowseWrapper,
    EmptyStateBox,
    EmptyStateWrapper,
    GiantUploadIconWrapper,
    NoFilesHeadingWrapper,
} from './styled';

const FilesEmptyStateContent = () => {
    const formatMessage = useFormatMessage();

    return (
        <EmptyStateBox>
            <EmptyStateWrapper>
                <GiantUploadIconWrapper>
                    <Icon id="upload" size={48} />
                </GiantUploadIconWrapper>
                <NoFilesHeadingWrapper>{formatMessage('no-files')}</NoFilesHeadingWrapper>
                <DropOrBrowseWrapper>{formatMessage('files-drop-or-browse')}</DropOrBrowseWrapper>
            </EmptyStateWrapper>
        </EmptyStateBox>
    );
};

export default FilesEmptyStateContent;
