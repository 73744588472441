import { IntlShape } from 'react-intl';
import qs from 'query-string';

// Convert seconds to and object containing minutes and seconds { minutes, seconds }
export function secondsToMinsAndSecs(secs: number) {
    const minutes = Math.floor(secs / 60);
    const seconds = Math.floor(secs - minutes * 60);
    return {
        minutes,
        seconds,
    };
}

// Convert an object containing {minutes, seconds } to a number of seconds
export function minsAndSecsToSecs({
    minutes,
    seconds,
}: {
    minutes: number | string;
    seconds: number | string;
}) {
    return Number(minutes) * 60 + Number(seconds);
}

// Returns the date in the format `12 May 2019`
export function daysFromNow(days: number) {
    const daysInMilliseconds = days * 24 * 60 * 60 * 1000;
    const date = new Date(Date.now() + daysInMilliseconds);
    return `${date.getDate()} ${date.toLocaleString(window.navigator.language, {
        month: 'long',
    })} ${date.getFullYear()}`;
}

export function isDateInPast(dateStr: string) {
    const date = new Date(dateStr);
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    if (date < now) {
        return true;
    }
    return false;
}

export function formatTime(seconds: number): string {
    if (!seconds || seconds == null) {
        return '00:00';
    }
    const secs = parseInt(`${seconds % 60}`, 10);
    const mins = parseInt(`${seconds / 60}`, 10) % 60;
    const hours = parseInt(`${seconds / 3600}`, 10) % 24;
    let output = `${(mins > 9 ? '' : '0') + mins}:${secs > 9 ? '' : '0'}${secs}`;
    if (hours > 0) {
        output = `${(hours > 9 ? '' : '0') + hours}:${output}`;
    }
    return output;
}

/**
 * react-intl's <FormattedRelativeTime> / formatRelativeTime() require `value` and `unit` properties
 * to display the relative time. A method, `selectUnit()`, used to be available in the @formatjs/intl-utils
 * package — but that's now deprecated and using it causes TypeScript errors. So this is a copy/paste
 * of that function, so we can easily get the props needed when comparing two dates.
 */
const MS_PER_SECOND = 1e3;
const SECS_PER_MIN = 60;
const SECS_PER_HOUR = SECS_PER_MIN * 60;
const SECS_PER_DAY = SECS_PER_HOUR * 24;
const SECS_PER_WEEK = SECS_PER_DAY * 7;
const DEFAULT_THRESHOLDS = {
    second: 45,
    minute: 45,
    hour: 22,
    day: 5,
};
export const selectUnit = (
    from: Date | number,
    to: Date | number = Date.now(),
    thresholds?: typeof DEFAULT_THRESHOLDS,
): {
    value: Parameters<IntlShape['formatRelativeTime']>[0];
    unit: Parameters<IntlShape['formatRelativeTime']>[1];
} => {
    const resolvedThresholds = { ...DEFAULT_THRESHOLDS, ...thresholds };
    const secs = (+from - +to) / MS_PER_SECOND;
    if (Math.abs(secs) < resolvedThresholds.second) {
        return {
            value: Math.round(secs),
            unit: 'second',
        };
    }
    const mins = secs / SECS_PER_MIN;
    if (Math.abs(mins) < resolvedThresholds.minute) {
        return {
            value: Math.round(mins),
            unit: 'minute',
        };
    }
    const hours = secs / SECS_PER_HOUR;
    if (Math.abs(hours) < resolvedThresholds.hour) {
        return {
            value: Math.round(hours),
            unit: 'hour',
        };
    }
    const days = secs / SECS_PER_DAY;
    if (Math.abs(days) < resolvedThresholds.day) {
        return {
            value: Math.round(days),
            unit: 'day',
        };
    }
    const fromDate = new Date(from);
    const toDate = new Date(to);
    const years = fromDate.getFullYear() - toDate.getFullYear();
    if (Math.round(Math.abs(years)) > 0) {
        return {
            value: Math.round(years),
            unit: 'year',
        };
    }
    const months = years * 12 + fromDate.getMonth() - toDate.getMonth();
    if (Math.round(Math.abs(months)) > 0) {
        return {
            value: Math.round(months),
            unit: 'month',
        };
    }
    const weeks = secs / SECS_PER_WEEK;
    return {
        value: Math.round(weeks),
        unit: 'week',
    };
};

/**
 * Given a relative URL starting with # and containing a 't' param in the format HH:MM:SS,
 * return the total number of seconds represented by the time string in the param.
 */
export const parseTimeStringToSeconds = (timeString: string) =>
    timeString.split(':').reduce((acc, time) => 60 * acc + Number(time), 0);

export const getSeekToSecondsFromHref = (href?: string | null) => {
    if (!href?.startsWith('#')) {
        return null;
    }

    const timeParam = qs.parse(href)?.t;

    if (!timeParam) {
        return null;
    }

    const parsedSecondsValue = parseTimeStringToSeconds(
        Array.isArray(timeParam) ? timeParam[0] : timeParam,
    );

    if (!Number.isInteger(parsedSecondsValue)) {
        return null;
    }

    return parsedSecondsValue;
};
