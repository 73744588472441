import React, { useState } from 'react';
import useFormatMessage from 'hooks/useFormatMessage';
import useTracks from 'hooks/useTracks';
import { useDispatch, useSelector } from 'hooks/react-redux-typed';
import { ModalTypes } from 'helper/UiHelper';
import {
    getFolderColorValues,
    getFolderForPodcast,
    getSubscribedPodcastUuids,
} from 'redux/reducers/selectors';
import * as fromModalActions from 'redux/actions/modal.actions';
import { FolderExpandingIconButton, SubscribeExpandingIconButton } from './PodcastActions.styled';

type Props = {
    onSubscribeClick: () => void;
    podcastUuid: string;
};

const PodcastActions = ({ onSubscribeClick, podcastUuid }: Props) => {
    const formatMessage = useFormatMessage();
    const dispatch = useDispatch();
    const { recordEvent } = useTracks();
    const isSubscribed = useSelector(getSubscribedPodcastUuids).includes(podcastUuid);
    const folder = useSelector(state => getFolderForPodcast(state, podcastUuid));
    const folderColorValues = useSelector(getFolderColorValues);

    // Afer clicking the subscribed button, we want to force the button to collapse to reveal the folder button.
    // Once the mouse leaves or focus is lost, we'll allow the button to be re-expanded.
    const [justSubscribed, setJustSubscribed] = useState<boolean>();
    const handleSubscribeClick = () => {
        recordEvent(
            isSubscribed ? 'podcast_screen_unsubscribe_tapped' : 'podcast_screen_subscribe_tapped',
        );
        setJustSubscribed(!isSubscribed);
        onSubscribeClick();
    };
    let forceIsExpanded;
    if (justSubscribed) {
        forceIsExpanded = false;
    } else if (!isSubscribed) {
        forceIsExpanded = true;
    }

    const handleFolderClick = () => {
        recordEvent('podcast_screen_folder_tapped');
        dispatch(
            fromModalActions.Actions.showModal(ModalTypes.changePodcastFolder, { podcastUuid }),
        );
    };

    return (
        <>
            {isSubscribed && (
                <>
                    <FolderExpandingIconButton
                        icon={folder ? 'folder-check' : 'folder'}
                        alignFlyout="right"
                        folderColor={folderColorValues[folder?.color]}
                        onClick={handleFolderClick}
                        aria-label={
                            folder
                                ? formatMessage('currently-in-folder', { folderName: folder.name })
                                : formatMessage('move-to-folder')
                        }
                    >
                        {folder ? folder.name : formatMessage('move-to-folder')}
                    </FolderExpandingIconButton>
                    &nbsp;&nbsp;&nbsp;
                </>
            )}
            <SubscribeExpandingIconButton
                icon={'tick'}
                expandedIcon={isSubscribed ? 'cancel' : 'plus'}
                alignFlyout={isSubscribed ? 'right' : undefined}
                forceIsExpanded={forceIsExpanded}
                isSubscribed={isSubscribed}
                justSubscribed={justSubscribed}
                onClick={handleSubscribeClick}
                onMouseLeave={() => setJustSubscribed(false)}
                onBlur={() => setJustSubscribed(false)}
            >
                {formatMessage(isSubscribed ? 'unsubscribe' : 'subscribe')}
            </SubscribeExpandingIconButton>
        </>
    );
};

export default PodcastActions;
