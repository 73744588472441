import styled from 'styled-components';
import { Typography } from '../../../styles';

export const SettingsTextContainer = styled.div`
    clear: both;
`;

export const SettingsLabel = styled.div`
    display: inline-block;
`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

export const SettingsEdit = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    input {
        outline: none;
        display: inline-block;
        height: 33px;
        width: 50px;
        border-radius: 4px;
        text-align: center;
        margin-right: 8px;
        color: ${props => props.theme.tokens['primary-text-01']};
        background-color: ${props => props.theme.tokens['primary-field-02']};
        border: 1px solid ${props => props.theme.tokens['primary-field-03']};
        &:focus {
            border: 1px solid ${props => props.theme.tokens['primary-field-03-active']};
            background-color: ${props => props.theme.tokens['primary-field-02-active']};
        }
    }
`;

export const MinutesEdit = styled(SettingsEdit)`
    margin-right: 70px;
`;

export const SettingsUnit = styled.div`
    span: {
        ${Typography.Desktop.H70};
    }
`;
