import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: center;
    line-height: 20px;
`;

export const Label = styled.span`
    margin-right: 12px;
    font-size: 14px;
    line-height: 1;
    padding-top: 0.14em; // Vertically center uppercase letters by nudging this down a bit
`;
