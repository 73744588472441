import React, { useCallback, useEffect, useRef } from 'react';

type Props = {
    children: React.ReactNode;
    focus: boolean;
    index: number;
    setFocus: (val: number) => void;
};

export default function ListItem({ children, focus, index, setFocus, ...rest }: Props) {
    const ref = useRef() as React.MutableRefObject<HTMLLIElement>;

    useEffect(() => {
        if (focus) {
            ref.current.focus();
        }
    }, [focus]);

    const handleSelect = useCallback(() => {
        setFocus(index);
    }, [index, setFocus]);

    return (
        <li
            tabIndex={focus ? 0 : -1}
            ref={ref}
            onClick={handleSelect}
            onKeyPress={handleSelect}
            role="option"
            aria-selected={focus}
            {...rest}
        >
            {children}
        </li>
    );
}
