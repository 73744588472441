import { connect } from 'react-redux';
import { RootState } from 'redux/reducers';
import { Dispatch } from 'redux';
import * as fromUserActions from '../../redux/actions/user.actions';
import StatsPage from './StatsPage';

const mapStateToProps = (state: RootState) => {
    const { stats } = state.user;

    return {
        timeListened: stats.timeListened != null ? Number(stats.timeListened) : 0,
        timeSkipping: stats.timeSkipping != null ? Number(stats.timeSkipping) : 0,
        timeVariableSpeed: stats.timeVariableSpeed != null ? Number(stats.timeVariableSpeed) : 0,
        timeSilenceRemoval: stats.timeSilenceRemoval != null ? Number(stats.timeSilenceRemoval) : 0,
        timeIntroSkipping: stats.timeIntroSkipping != null ? Number(stats.timeIntroSkipping) : 0,
        timesStartedAt: stats.timesStartedAt ?? null,

        locale: state.settings.language ?? undefined,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    downloadStats: () => dispatch(fromUserActions.Actions.downloadStats()),
});

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(StatsPage);
export { ConnectedComponent as StatsPage };
