import { useState, useEffect, useCallback } from 'react';

export default function useResize(myRef?: React.RefObject<HTMLElement>) {
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    const handleResize = useCallback(() => {
        if (myRef?.current) {
            setWidth(myRef.current.offsetWidth);
            setHeight(myRef.current.offsetHeight);
        }
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
    }, [myRef, myRef?.current]);

    useEffect(handleResize, [myRef?.current]);

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return { width, height, windowWidth, windowHeight };
}
