import classNames from 'classnames';
import { ModalMonitor } from 'components/ModalMonitor';
import useFormatMessage from 'hooks/useFormatMessage';
import { TracksProperties } from 'model/types';
import React, { useCallback, useState } from 'react';
import { USE_SHARING_CLIP_CREATION } from 'settings';
import PodcastImage from '../../PodcastImage';
import { CloseButton } from '../../buttons/CloseButton';
import { ClipEditor } from './ClipEditor';
import { ClipView } from './ClipView';
import { ShareOptions } from './ShareOptions';
import {
    CloseButtonWrapper,
    CreateClipOptions,
    ModalTitle,
    PrimaryButton,
    SecondaryButton,
    ShareInfo,
    SharePopupWrapper,
    ShareText,
} from './SharePopup.styled';

export type ShareProps = {
    url: string;
    audioUrl: string;
    color: string;
    podcastUuid: string;
    episodeUuid: string;
    duration: number;
    podcastTitle: string;
    episodeTitle: string;
    podcastAuthor: string;
    eventSource: string;
    isPlaying: boolean;
    playedUpTo: number;
    loading?: boolean;
    shareTime?: number;
    closeShare: () => void;
    recordEvent: (event: string, properties: TracksProperties) => void;
    addFlag: (message: string) => void;
};

function SharePopup(props: ShareProps) {
    const {
        podcastUuid,
        episodeUuid,
        episodeTitle,
        podcastTitle,
        podcastAuthor,
        closeShare,
        shareTime,
        isPlaying,
        addFlag,
    } = props;
    const formatMessage = useFormatMessage();

    const [isClipCreationOpen, setIsClipCreationOpen] = useState(false);
    const [isClipViewOpen, setIsClipViewOpen] = useState(false);
    const [clipStartTime, setClipStartTime] = useState(0);
    const [clipEndTime, setClipEndTime] = useState(0);

    const [initialStartTime, setInitialStartTime] = useState<number | null>(null);
    const [initialEndTime, setInitialEndTime] = useState<number | null>(null);

    const handleUpdateStartTime = useCallback((startTime: number) => {
        setClipStartTime(Math.floor(startTime));
    }, []);

    const handleUpdateEndTime = useCallback((endTime: number) => {
        setClipEndTime(Math.floor(endTime));
    }, []);

    const openClipCreation = useCallback(() => {
        setIsClipCreationOpen(true);
    }, []);

    const cancelClipCreation = useCallback(() => {
        setIsClipCreationOpen(false);
    }, []);

    const openClipEditor = useCallback(() => {
        setIsClipViewOpen(false);
        setIsClipCreationOpen(true);
        setInitialStartTime(clipStartTime);
        setInitialEndTime(clipEndTime);
    }, [clipStartTime, clipEndTime]);

    const createClip = useCallback(() => {
        setIsClipCreationOpen(false);
        setIsClipViewOpen(true);

        addFlag(formatMessage('clip-created'));
    }, []);

    const isPodcastShare = !episodeUuid;
    const title = isPodcastShare ? podcastTitle : episodeTitle;
    const desc = isPodcastShare ? podcastAuthor : podcastTitle;

    // The popup height is used to calculated modal position, so we need to adjust it based on the content we plan on showing
    const isClipCreation = USE_SHARING_CLIP_CREATION && isClipCreationOpen;
    const isClipView = USE_SHARING_CLIP_CREATION && isClipViewOpen;
    const isShareOptions = !isClipCreation && !isClipView;
    const showCurrentTime = isPlaying && !shareTime;

    let popupHeight = 256;
    if (isClipCreation) {
        popupHeight = 430;
    } else if (isClipView) {
        popupHeight = 402;
    } else if (showCurrentTime) {
        popupHeight = USE_SHARING_CLIP_CREATION ? 355 : 286;
    } else if (USE_SHARING_CLIP_CREATION && !isPodcastShare) {
        popupHeight = 324;
    }

    const hasLongTitle = title?.length >= 40;
    const titleClasses = classNames('title', {
        long: hasLongTitle,
    });
    if (hasLongTitle) {
        popupHeight += 25;
    }

    let modalTitle;
    if (isClipCreationOpen || isClipViewOpen) {
        modalTitle = formatMessage('share-clip');
    } else if (isPodcastShare) {
        modalTitle = formatMessage('share-podcast');
    } else {
        modalTitle = formatMessage('share-episode');
    }

    return (
        <SharePopupWrapper isOpen={true} onClosed={closeShare} width={776} height={popupHeight}>
            <ModalMonitor onCloseModals={closeShare} />

            <CloseButtonWrapper>
                <CloseButton onClick={closeShare} />
            </CloseButtonWrapper>

            <ShareInfo>
                <PodcastImage className="share-popup-image" uuid={podcastUuid} />
                <ShareText>
                    <ModalTitle>{modalTitle}</ModalTitle>
                    <div className={titleClasses}>{title}</div>
                    <div className="desc">{desc}</div>
                </ShareText>
            </ShareInfo>

            {isClipCreation && (
                <ClipEditor
                    {...props}
                    initialStartTime={initialStartTime}
                    initialEndTime={initialEndTime}
                    onUpdateStartTime={handleUpdateStartTime}
                    onUpdateEndTime={handleUpdateEndTime}
                />
            )}

            {isClipView && (
                <ClipView
                    {...props}
                    url={`${props.url}?t=${clipStartTime},${clipEndTime}`}
                    audioUrl={`${props.audioUrl}?t=${clipStartTime},${clipEndTime}`}
                    startTime={clipStartTime}
                    endTime={clipEndTime}
                    isPlaying={false}
                />
            )}

            {isShareOptions && <ShareOptions {...props} />}

            {USE_SHARING_CLIP_CREATION && !isPodcastShare && (
                <CreateClipOptions>
                    {isClipCreation && (
                        <>
                            <PrimaryButton onClick={createClip}>
                                {formatMessage('create-clip')}
                            </PrimaryButton>
                            <SecondaryButton onClick={cancelClipCreation}>
                                {formatMessage('cancel')}
                            </SecondaryButton>
                        </>
                    )}

                    {isClipViewOpen && (
                        <SecondaryButton onClick={openClipEditor}>
                            {formatMessage('edit-clip')}
                        </SecondaryButton>
                    )}

                    {isShareOptions && (
                        <SecondaryButton onClick={openClipCreation}>
                            {formatMessage('create-clip')}
                        </SecondaryButton>
                    )}
                </CreateClipOptions>
            )}
        </SharePopupWrapper>
    );
}

export default SharePopup;
