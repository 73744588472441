import { Logo } from 'components/Logo';
import Beta from 'components/Logo/Beta';
import { BetaLogoWrapper } from 'components/Logo/Beta.styled';
import { NavigationItem, NavigationItems } from 'helper/NavigationHelper';
import { useSelector } from 'hooks/react-redux-typed';
import useFormatMessage from 'hooks/useFormatMessage';
import useIsBeta from 'hooks/useIsBeta';
import key from 'keymaster';
import { DARK_THEME } from 'model/theme/theme-dark';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { getChaptersOpen } from 'redux/reducers/selectors';
import {
    LinkIcon,
    LinkNotch,
    LinkText,
    LogoLink,
    NavigationLink,
    NavigationWrapper,
    ScrollingCover,
    Spaces,
} from './Navigation.styled';

const Link = ({ navigationItem }: { navigationItem: NavigationItem }) => {
    const location = useLocation();
    const formatMessage = useFormatMessage();
    const { icon, path, text } = navigationItem;
    const selected = location.pathname.startsWith(path);

    return (
        <NavigationLink to={path} selected={selected} aria-label={formatMessage(text)}>
            <LinkNotch
                selected={selected}
                width="8"
                height="46"
                viewBox="1 0 8 54"
                aria-hidden={true}
            >
                <path d="M0,0 C0,0 4.14113188e-13,2 3,2 C4.75181535,2 5,2 5,2 L5.00860596,2 C6.66070727,2 8,3.34205759 8,4.99139404 L8,5 L8,49 L8,49.008606 C8,50.6607073 6.65794241,52 5.00860596,52 L5,52 C5,52 5,52 3,52 C1,52 0,54 0,54 L0,0 Z" />
            </LinkNotch>

            {icon && <LinkIcon id={icon} size={32} aria-hidden={true} />}

            <LinkText>{formatMessage(text)}</LinkText>
        </NavigationLink>
    );
};

const Navigation = () => {
    const history = useHistory();
    const isUpNextOpen = useSelector(state => state.upNext.open);
    const isChaptersOpen = useSelector(getChaptersOpen);

    const shouldShowFilesItem = useSelector(
        state => state.uploadedFiles?.data?.account?.totalFiles > 0,
    );

    useEffect(() => {
        const SHORTCUT_ITEMS = [
            NavigationItems.PODCASTS,
            NavigationItems.DISCOVER,
            NavigationItems.NEW_RELEASES,
            NavigationItems.IN_PROGRESS,
            NavigationItems.STARRED,
            NavigationItems.BOOKMARKS,
        ];

        SHORTCUT_ITEMS.forEach(({ id, path }) => key(id.toString(), () => history.push(path)));

        return () => {
            SHORTCUT_ITEMS.forEach(({ id }) => key.unbind(id.toString()));
        };
    }, [history]);

    const isInactive = isUpNextOpen || isChaptersOpen;

    const [betaFeaturesEnabled] = useIsBeta();

    return (
        <NavigationWrapper aria-label="Navigation Side Menu">
            <LogoLink to={NavigationItems.PODCASTS.path} aria-hidden="true">
                <BetaLogoWrapper>
                    <Logo
                        className="logo"
                        color={isInactive ? '#2d2e2f' : undefined}
                        lineColor={isInactive ? DARK_THEME.tokens['contrast-03'] : undefined}
                    />
                    {betaFeaturesEnabled && <Beta />}
                </BetaLogoWrapper>
            </LogoLink>

            <ScrollingCover />

            <Spaces />

            <Link navigationItem={NavigationItems.PODCASTS} />
            <Link navigationItem={NavigationItems.DISCOVER} />

            <Spaces n={4} />

            <Link navigationItem={NavigationItems.NEW_RELEASES} />
            <Link navigationItem={NavigationItems.IN_PROGRESS} />
            <Link navigationItem={NavigationItems.STARRED} />
            <Link navigationItem={NavigationItems.BOOKMARKS} />
            <Link navigationItem={NavigationItems.HISTORY} />

            {shouldShowFilesItem && (
                <>
                    <Spaces n={4} />
                    <Link navigationItem={NavigationItems.UPLOADED_FILES} />
                </>
            )}
        </NavigationWrapper>
    );
};

export default Navigation;
