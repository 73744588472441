import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useFormatMessage from 'hooks/useFormatMessage';
import { Icon } from 'components/Icon';
import useTracks from 'hooks/useTracks';
import * as fromModalActions from '../../../../redux/actions/modal.actions';
import {
    ModalTitle,
    ModalWrapper,
    CountryList,
    CountryListItem,
    CountryFlag,
    CountryTitle,
    CountryListItemDetails,
    CountryListItemTickWrapper,
} from './RegionSelectModal.styled';
import { LoaderSquare } from '../../../../components';
import { getDiscoverRegion, getRegionsArray } from '../../../../redux/reducers/selectors';
import * as fromSettingsActions from '../../../../redux/actions/settings.actions';
import { useListFocus } from '../../../../hooks/useListFocus';

function RegionSelectModal() {
    const dispatch = useDispatch();
    const formatMessage = useFormatMessage();
    const { recordEvent } = useTracks();

    const { regions, loading, selected } = useSelector(state => {
        const selected = getDiscoverRegion(state);
        const regions = getRegionsArray(state);
        return {
            loading: regions.length === 0,
            regions,
            selected,
        };
    });

    const [focus, setFocus] = useListFocus(regions.length);

    const onCloseClicked = useCallback(() => {
        dispatch(fromModalActions.Actions.hideModal());
    }, [dispatch]);

    const onSelectRegion = useCallback(
        (index: number) => {
            const region = regions[index];
            setFocus(index);
            recordEvent('discover_region_changed', { region: region.code });
            dispatch(fromSettingsActions.Actions.saveRegion(region.code));
            dispatch(fromModalActions.Actions.hideModal());
        },
        [regions, dispatch, recordEvent, setFocus],
    );

    return (
        <ModalWrapper horizontalInsetPx={32} onClose={onCloseClicked}>
            <ModalTitle id="select-modal-title">
                {formatMessage('discover-select-region')}
            </ModalTitle>
            {loading ? (
                <LoaderSquare />
            ) : (
                <CountryList aria-labelledby="select-modal-title" tabIndex={0} role="listbox">
                    {regions.map((region, index) => (
                        <CountryListItem
                            key={region.code}
                            setFocus={onSelectRegion}
                            index={index}
                            focus={focus === index}
                            aria-selected={focus === index}
                        >
                            <CountryListItemDetails>
                                <CountryFlag alt="" src={region.flag} />
                                <CountryTitle>{region.name}</CountryTitle>
                            </CountryListItemDetails>
                            {region && selected && region.code === selected.code && (
                                <CountryListItemTickWrapper>
                                    <Icon id="tick" />
                                </CountryListItemTickWrapper>
                            )}
                        </CountryListItem>
                    ))}
                </CountryList>
            )}
        </ModalWrapper>
    );
}

export default RegionSelectModal;
