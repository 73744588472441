import React from 'react';
import ReactTooltip from 'react-tooltip';
import { TooltipWrapper } from './Tooltip.styled';

const Tooltip = ({ ...props }) => (
    <TooltipWrapper>
        <ReactTooltip className="tooltip" {...props} />
    </TooltipWrapper>
);

export default Tooltip;
