import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CancelProgressIndicator from './view';

CancelProgressIndicator.propTypes = {
    percent: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    theme: state.settings.theme,
});

export default connect(mapStateToProps)(CancelProgressIndicator);
