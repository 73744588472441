import styled from 'styled-components';
import { gridSize } from '../../../../../styles/index';

export const ReplaceImageButtonWrapper = styled.div`
    width: ${5 * gridSize}px;
    height: ${5 * gridSize}px;
    background-color: ${props => props.theme.legacyScss['theme-background']};
    border-radius: ${2.5 * gridSize}px;
`;

export const ReplaceImageLabel = styled.label`
    display: flex;
    height: 100%;
    color: ${props => props.theme.tokens['primary-icon-01']};

    align-items: center;
    justify-content: center;

    cursor: pointer;
`;

export const ReplaceImageInput = styled.input`
    opacity: 0;
    height: 1px;
    width: 1px;
`;
