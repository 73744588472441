import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FileColorPicker from './view';

FileColorPicker.propTypes = {
    fileUuid: PropTypes.string.isRequired,
    selectedColor: PropTypes.number,
    image: PropTypes.string,
    onColorSelect: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
    theme: state.settings.theme,
    imageUploadData: state.uploadManager.images[ownProps.fileUuid],
});

export default connect(mapStateToProps)(FileColorPicker);
