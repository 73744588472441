import { SLIDING_MODAL_ANIMATION_TIME_IN_MS } from 'components/SlidingModal/SlidingModal.styled';
import styled from 'styled-components';

export const SlideUpButtonWrapper = styled.button<{ isOpen: boolean }>`
    height: 48px;
    overflow: hidden;
    color: white;

    & > span {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        transform: translateY(${props => (props.isOpen ? '-100%' : '0')});
        @media (prefers-reduced-motion: no-preference) {
            transition: transform ${SLIDING_MODAL_ANIMATION_TIME_IN_MS}ms
                cubic-bezier(0.25, 0.09, 0.3, 1);
        }
        svg {
            margin: 0 auto;
        }
    }

    &:hover svg * {
        fill-opacity: 1;
    }
`;
