import { connect } from 'react-redux';
import { RootState } from 'redux/reducers';
import { Dispatch } from 'redux';
import ChangeBillingDetailsModal from './ChangeBillingDetailsModal';
import * as fromSubscriptionActions from '../../../../redux/actions/subscription.actions';

const mapStateToProps = (state: RootState) => {
    const subscriptionData = state.subscription.modalData.showChangeBillingDetailsModal;
    return {
        platform: subscriptionData?.platform,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    onClose: () =>
        dispatch(fromSubscriptionActions.Actions.hideModalGeneric('showChangeBillingDetailsModal')),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeBillingDetailsModal);
