import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon } from 'components/Icon';
import Modal from '../../../../components/Modal';
import { Button } from '../../../../components/Button';

import {
    DeleteIconWrapper,
    Heading,
    SubtitleWrapper,
    WarningWrapper,
    Separator,
    ButtonsWrapper,
    VerticalSpace,
} from './styled';

const FileDeleteModal = ({ theme, title, uuid, onClose, onConfirm }) => {
    return (
        <Modal theme={theme} horizontalInsetPx={32} onClose={onClose}>
            <DeleteIconWrapper>
                <Icon id="delete" size={30} />
            </DeleteIconWrapper>
            <Heading>
                <FormattedMessage id="delete-file" />
            </Heading>
            <SubtitleWrapper>
                <FormattedMessage
                    id="file-delete-confirm"
                    values={{
                        fileName: `"${title}"`,
                    }}
                />
            </SubtitleWrapper>
            <WarningWrapper>
                <FormattedMessage id="file-delete-from-devices" />
            </WarningWrapper>
            <Separator />
            <ButtonsWrapper>
                <Button kind="secondary-muted" width={120} onClick={onClose}>
                    <FormattedMessage id="cancel" />
                </Button>
                <VerticalSpace />
                <Button kind="primary-red" width={120} onClick={() => onConfirm(uuid)}>
                    <FormattedMessage id="delete" />
                </Button>
            </ButtonsWrapper>
        </Modal>
    );
};

export default FileDeleteModal;
