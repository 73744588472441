import styled from 'styled-components';
import { gridSize } from 'styles';

export const DurationWrapper = styled.div`
    margin-right: 88px;
`;

export const RowTitleContainer = styled.div`
    display: flex;
`;

export const SelectEpisode = styled.label`
    cursor: pointer;
    padding: 4px;
    margin-right: ${gridSize}px;
`;

export const TitleButton = styled.button`
    &:disabled {
        cursor: default;
    }
`;
