import { TodoFixmeMigrationType, TracksProperties } from 'model/types';
import { UPLOADED_FILES_PODCAST_UUID } from 'model/uploaded-files';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import * as fromPodcastActions from 'redux/actions/podcast.actions';
import * as fromShareActions from 'redux/actions/share.actions';
import * as fromTracksActions from 'redux/actions/tracks.actions';
import * as fromUploadedFilesActions from 'redux/actions/uploaded-files.actions';
import { RootState } from 'redux/reducers';

import MoreActionsMenu from './MoreActionsMenu';

const mapStateToProps = (state: RootState) => {
    const { episode } = state.player;
    const { podcastUuid } = episode;
    const isEpisodeStarred = episode.starred;
    const isUploadedFile = podcastUuid === UPLOADED_FILES_PODCAST_UUID;

    return {
        episodeTitle: episode.title,
        episodeUuid: episode.uuid,
        podcastUuid: episode.podcastUuid,
        episodeDuration: episode.duration,
        audioUrl: episode.url,
        isEpisodeStarred,
        isUploadedFile,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    markAsPlayed: (episodeUuid: string, podcastUuid: string) => {
        dispatch(
            fromPodcastActions.Actions.markAsPlayed(episodeUuid, podcastUuid, {
                eventSource: 'player_more_actions',
            }),
        );
    },
    requestUpdateFile: (episodeUuid: string, data: TodoFixmeMigrationType) => {
        dispatch(fromUploadedFilesActions.Actions.requestUpdateFile(episodeUuid, data));
    },
    starEpisode: (episodeUuid: string, podcastUuid: string) => {
        dispatch(
            fromPodcastActions.Actions.starEpisode(episodeUuid, podcastUuid, true, {
                eventSource: 'player_more_actions',
            }),
        );
    },
    unstarEpisode: (episodeUuid: string, podcastUuid: string) => {
        dispatch(
            fromPodcastActions.Actions.starEpisode(episodeUuid, podcastUuid, false, {
                eventSource: 'player_more_actions',
            }),
        );
    },
    archive: (episodeUuid: string, podcastUuid: string) =>
        dispatch(
            fromPodcastActions.Actions.archive(episodeUuid, podcastUuid, {
                eventSource: 'player_more_actions',
            }),
        ),
    recordEvent: (name: string, properties?: TracksProperties) =>
        dispatch(fromTracksActions.Actions.recordEvent(name, properties)),
    openShare: (
        episodeUuid: string,
        podcastUuid: string,
        title: string,
        duration: number,
        audioUrl: string,
    ) =>
        dispatch(
            fromShareActions.Actions.openEpisodeShare(
                episodeUuid,
                podcastUuid,
                title,
                duration,
                audioUrl,
                {
                    eventSource: 'player_more_actions',
                },
            ),
        ),
});

const MoreActionsMenuConnected = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(MoreActionsMenu),
);

export { MoreActionsMenuConnected as MoreActionsMenu };
