import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import * as Sentry from '@sentry/browser';
import isEqual from 'lodash/isEqual';
import { getPlayerEpisode } from 'redux/reducers/selectors';

// Hooks to update Sentry data when Redux state changes
const useSentry = () => {
    const playerEpisode = useSelector(getPlayerEpisode);
    const lastPlayerEpisode = useRef(null);

    useEffect(() => {
        // When information in the player state changes, update the Sentry context, so that at the moment
        // an error happens Sentry will send the current player state in the log.
        if (isEqual(playerEpisode, lastPlayerEpisode.current)) {
            return;
        }
        Sentry.setContext('playerEpisode', playerEpisode);
        lastPlayerEpisode.current = playerEpisode;
    }, [playerEpisode]);

    return null;
};

export default useSentry;
