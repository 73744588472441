import { PodcastDiscover, ContentSpec, ListData } from 'model/types';
import { createAction, ActionsUnion } from './action-creators';

export enum ActionTypes {
    GET_DISCOVER_CONTENT = '@discover/GET_DISCOVER_CONTENT',
    SET_DISCOVER_CONTENT = '@discover/SET_DISCOVER_CONTENT',
    OPEN_DISCOVER_LIST = '@discover/OPEN_DISCOVER_LIST',
    OPEN_CATEGORY_LIST = '@discover/OPEN_CATEGORY_LIST',
    CLEAR_CATEGORY = '@discover/CLEAR_CATEGORY',
    OPEN_DISCOVER_LIST_SUCCESS = '@discover/OPEN_DISCOVER_LIST_SUCCESS',
    OPEN_CATEGORY_LIST_SUCCESS = '@discover/OPEN_CATEGORY_LIST_SUCCESS',
}

export const Actions = {
    getDiscoverContent: () => createAction(ActionTypes.GET_DISCOVER_CONTENT),

    setDiscoverContent: (content: ContentSpec) =>
        createAction(ActionTypes.SET_DISCOVER_CONTENT, { ...content }),

    openDiscoverList: (name: string) => createAction(ActionTypes.OPEN_DISCOVER_LIST, { name }),

    openCategoryList: (id: string) => createAction(ActionTypes.OPEN_CATEGORY_LIST, { id }),

    clearCategory: (id: number) => createAction(ActionTypes.CLEAR_CATEGORY, { id }),

    openDiscoverListSuccess: (name: string, list: ListData) =>
        createAction(ActionTypes.OPEN_DISCOVER_LIST_SUCCESS, { name, list }),

    openCategoryListSuccess: ({
        name,
        title,
        list,
        region,
    }: {
        name: number;
        title: string;
        list: PodcastDiscover[];
        region: string;
    }) =>
        createAction(ActionTypes.OPEN_CATEGORY_LIST_SUCCESS, {
            name,
            title,
            list,
            region,
        }),
};

export type Actions = ActionsUnion<typeof Actions>;
