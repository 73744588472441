import { Icon } from 'components/Icon';
import { TextWithIcon } from 'components/TextWithIcon';
import { cleanForFileName } from 'helper/StringHelper';
import { ModalTypes } from 'helper/UiHelper';
import { useSelector } from 'hooks/react-redux-typed';
import useFormatMessage from 'hooks/useFormatMessage';
import { FullEpisode } from 'model/types';
import qs from 'query-string';
import React, { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { getPlayingEpisode, getPodcastByUuid } from 'redux/reducers/selectors';
import { red } from 'styles';
import { useAnalyticsContext } from '../../../../context/AnalyticsContext';
import { PlayingStatus } from '../../../../helper/PlayingStatus';
import { UPLOADED_FILES_PODCAST_UUID } from '../../../../model/uploaded-files';
import * as fromModalActions from '../../../../redux/actions/modal.actions';
import * as fromPlayerActions from '../../../../redux/actions/player.actions';
import * as fromPodcastActions from '../../../../redux/actions/podcast.actions';
import * as fromShareActions from '../../../../redux/actions/share.actions';
import * as fromTracksActions from '../../../../redux/actions/tracks.actions';
import * as fromUpNextActions from '../../../../redux/actions/up-next.actions';
import * as fromUploadedFilesActions from '../../../../redux/actions/uploaded-files.actions';
import DateText from '../../../format/DateText';
import DurationText from '../../../format/DurationText';
import { CalendarIcon, HourGlassIcon } from '../icons';
import {
    BookmarksButton,
    EpisodeActions,
    EpisodePopupToolbarContainer,
    PlayButtonContainer,
    PopupMenuBarContent,
} from './EpisodePopupToolbar.styled';
import { SvgImageButton } from './SvgImageButton';

type Props = {
    episode: FullEpisode;
    onClose: () => void;
    isUploadedFile: boolean;
};

function EpisodePopupToolbar({ episode, isUploadedFile, onClose }: Props) {
    const formatMessage = useFormatMessage();
    const dispatch = useDispatch();
    const { discoverEpisodePlayEvent } = useAnalyticsContext();
    const location = useLocation();
    const bookmarks = episode.bookmarks || [];
    const isPlaying = useSelector(state => getPlayingEpisode(state)?.uuid === episode.uuid);
    const autoplayConfig = useSelector(state => state.episode.autoplayConfig);
    const podcast = useSelector(state => getPodcastByUuid(state, episode.podcastUuid));
    const didShareSync = useRef(false);

    const inUpNext = useSelector(
        state =>
            episode &&
            state.upNext &&
            state.upNext.episodes &&
            !!state.upNext.episodes[episode.uuid],
    );

    const isEpisodeStarred = episode.starred;
    const markedAsPlayed = episode.playingStatus === PlayingStatus.COMPLETED;
    const onShareClick = useCallback(() => {
        dispatch(
            fromShareActions.Actions.openEpisodeShare(
                episode.uuid,
                episode.podcastUuid,
                episode.title,
                episode.duration,
                episode.url,
                { eventSource: 'episode_detail' },
            ),
        );
        onClose();
    }, [
        dispatch,
        episode.uuid,
        episode.podcastUuid,
        episode.title,
        episode.duration,
        episode.url,
        onClose,
    ]);

    const onBookmarksClick = useCallback(() => {
        dispatch(
            fromModalActions.Actions.showModal(ModalTypes.listBookmarks, {
                podcastUuid: episode.podcastUuid,
                episodeUuid: episode.uuid,
            }),
        );
        onClose();
    }, [episode.podcastUuid, episode.uuid, dispatch, onClose]);

    const onStarEpisode = useCallback(() => {
        dispatch(
            fromPodcastActions.Actions.starEpisode(episode.uuid, episode.podcastUuid, true, {
                eventSource: 'episode_detail',
            }),
        );
    }, [episode.uuid, episode.podcastUuid, dispatch]);

    const onUnstarEpisode = useCallback(() => {
        dispatch(
            fromPodcastActions.Actions.starEpisode(episode.uuid, episode.podcastUuid, false, {
                eventSource: 'episode_detail',
            }),
        );
    }, [episode.uuid, episode.podcastUuid, dispatch]);

    const onPlayClick = useCallback(() => {
        if (isPlaying) {
            dispatch(fromPlayerActions.Actions.pause({ eventSource: 'episode_detail' }));
            if (isUploadedFile) {
                dispatch(
                    fromTracksActions.Actions.recordEvent('user_file_play_pause_button_tapped', {
                        option: 'pause',
                    }),
                );
            }
        } else {
            const queryParts = qs.parse(location.search);
            const seekTo = queryParts.t
                ? Array.isArray(queryParts.t)
                    ? parseInt(queryParts.t[0], 10)
                    : parseInt(queryParts.t, 10)
                : undefined;
            // Prevent seeking past the last 5 seconds of the episode
            const maxSeekTo = episode.duration - 5;

            const { podcastUuid } = episode;
            discoverEpisodePlayEvent(podcastUuid);
            dispatch(
                fromPlayerActions.Actions.playEpisode(
                    episode.uuid,
                    podcastUuid,
                    {
                        eventSource: 'episode_detail',
                    },
                    {
                        autoplay: autoplayConfig,
                        seekTo:
                            seekTo !== null && seekTo !== undefined && !didShareSync.current
                                ? Math.min(seekTo, maxSeekTo)
                                : undefined,
                    },
                ),
            );

            // Don't seek more then once if the user pauses and then plays again
            if (seekTo && !didShareSync.current) {
                didShareSync.current = true;
            }

            if (isUploadedFile) {
                dispatch(
                    fromTracksActions.Actions.recordEvent('user_file_play_pause_button_tapped', {
                        option: 'play',
                    }),
                );
            }
        }
    }, [isPlaying, dispatch, isUploadedFile, episode, discoverEpisodePlayEvent]);

    const onMarkAsPlayedClick = useCallback(() => {
        if (episode.podcastUuid === UPLOADED_FILES_PODCAST_UUID) {
            dispatch(
                fromUploadedFilesActions.Actions.requestUpdateFile(episode.uuid, {
                    playingStatus: PlayingStatus.COMPLETED,
                }),
            );
        } else {
            dispatch(
                fromPodcastActions.Actions.markAsPlayed(episode.uuid, episode.podcastUuid, {
                    eventSource: 'episode_detail',
                }),
            );
        }
        if (isUploadedFile) {
            dispatch(
                fromTracksActions.Actions.recordEvent('user_file_option_tapped', {
                    option: 'mark_played',
                }),
            );
        }
    }, [episode.podcastUuid, episode.uuid, isUploadedFile, dispatch]);

    const onMarkAsUnplayedClick = useCallback(() => {
        if (episode.podcastUuid === UPLOADED_FILES_PODCAST_UUID) {
            dispatch(
                fromUploadedFilesActions.Actions.requestUpdateFile(episode.uuid, {
                    playingStatus: PlayingStatus.NOT_PLAYED,
                    playedUpTo: 0,
                }),
            );
        } else {
            dispatch(
                fromPodcastActions.Actions.markAsUnplayed(episode.uuid, episode.podcastUuid, {
                    eventSource: 'episode_detail',
                }),
            );
        }
        if (isUploadedFile) {
            dispatch(
                fromTracksActions.Actions.recordEvent('user_file_option_tapped', {
                    option: 'mark_unplayed',
                }),
            );
        }
    }, [episode.podcastUuid, episode.uuid, isUploadedFile, dispatch]);

    const downloadUploadedFile = useCallback(() => {
        dispatch(fromUploadedFilesActions.Actions.downloadFile(episode.uuid, episode.title));
        if (isUploadedFile) {
            dispatch(
                fromTracksActions.Actions.recordEvent('user_file_option_tapped', {
                    option: 'download',
                }),
            );
        }
    }, [dispatch, episode.uuid, episode.title, isUploadedFile]);

    const onDeleteClick = useCallback(() => {
        onClose();
        dispatch(
            fromUploadedFilesActions.Actions.showDeleteFileConfirmation(
                episode.uuid,
                episode.title,
            ),
        );
        if (isUploadedFile) {
            dispatch(
                fromTracksActions.Actions.recordEvent('user_file_option_tapped', {
                    option: 'delete',
                }),
            );
        }
    }, [onClose, dispatch, episode.uuid, episode.title, isUploadedFile]);

    const onEditClick = useCallback(() => {
        dispatch(fromUploadedFilesActions.Actions.editFile(episode.uuid));
        if (isUploadedFile) {
            dispatch(
                fromTracksActions.Actions.recordEvent('user_file_option_tapped', {
                    option: 'edit',
                }),
            );
        }
        onClose();
    }, [dispatch, episode.uuid, isUploadedFile, onClose]);

    const onRemoveFromUpNextClick = useCallback(() => {
        dispatch(
            fromUpNextActions.Actions.removeFromUpNext(episode.uuid, {
                eventSource: 'episode_detail',
            }),
        );
        if (isUploadedFile) {
            dispatch(
                fromTracksActions.Actions.recordEvent('user_file_option_tapped', {
                    option: 'up_next_remove',
                }),
            );
        }
    }, [dispatch, episode.uuid, isUploadedFile]);

    const onPlayNextClick = useCallback(() => {
        dispatch(
            fromUpNextActions.Actions.upNextPlayNext(episode.podcastUuid, episode, {
                eventSource: 'episode_detail',
            }),
        );
        if (isUploadedFile) {
            dispatch(
                fromTracksActions.Actions.recordEvent('user_file_option_tapped', {
                    option: 'up_next_add_top',
                }),
            );
        }
    }, [dispatch, episode, isUploadedFile]);

    const onPlayLastClick = useCallback(() => {
        dispatch(
            fromUpNextActions.Actions.upNextPlayLast(episode.podcastUuid, episode, {
                eventSource: 'episode_detail',
            }),
        );
        if (isUploadedFile) {
            dispatch(
                fromTracksActions.Actions.recordEvent('user_file_option_tapped', {
                    option: 'up_next_add_bottom',
                }),
            );
        }
    }, [dispatch, episode, isUploadedFile]);

    const PlayButton = React.useMemo(() => {
        return (
            <PlayButtonContainer
                aria-label={isPlaying ? 'Pause' : 'Play'}
                aria-pressed={isPlaying}
                onClick={onPlayClick}
                className={isPlaying ? 'play' : 'pause'}
                onKeyUp={e => {
                    e.preventDefault();
                }}
            >
                <svg width="46" height="46" viewBox="0 0 46 46">
                    <use xlinkHref={isPlaying ? '#pause' : '#play'} />
                </svg>
            </PlayButtonContainer>
        );
    }, [isPlaying, onPlayClick]);

    const downloadFileName =
        episode.podcastUuid === UPLOADED_FILES_PODCAST_UUID || !episode.title
            ? null // File name is instead set by downloadUploadedFile() or we depend on basic one set by the app
            : `${podcast ? `${cleanForFileName(podcast.title)}_` : ''}${cleanForFileName(
                  episode.title,
              )}`;
    return (
        <EpisodePopupToolbarContainer>
            {PlayButton}
            <PopupMenuBarContent>
                <TextWithIcon
                    icon={<CalendarIcon />}
                    text={<DateText date={episode.published} showToday={false} />}
                />

                {episode.duration > 0 && (
                    <TextWithIcon
                        icon={<HourGlassIcon />}
                        text={
                            <DurationText
                                durationSecs={Number(episode.duration)}
                                playedUpToSecs={episode.playedUpTo}
                                playingStatus={episode.playingStatus}
                            />
                        }
                    />
                )}

                <BookmarksButton kind="text" onClick={onBookmarksClick}>
                    <TextWithIcon
                        icon={<Icon id="bookmark" size={20} />}
                        text={
                            bookmarks.length > 1
                                ? formatMessage('bookmark-count-plural', {
                                      count: bookmarks.length,
                                  })
                                : bookmarks.length === 1
                                ? formatMessage('bookmark-count-singular')
                                : formatMessage('bookmarks')
                        }
                    />
                </BookmarksButton>

                <EpisodeActions>
                    {!isUploadedFile && (
                        <SvgImageButton
                            svg={isEpisodeStarred ? 'star-full' : 'star-empty'}
                            title={
                                isEpisodeStarred ? formatMessage('unstar') : formatMessage('star')
                            }
                            onClick={isEpisodeStarred ? onUnstarEpisode : onStarEpisode}
                            ariaAttributes={{
                                'aria-pressed': isEpisodeStarred,
                                'aria-label': 'Star Episode',
                            }}
                        />
                    )}

                    {markedAsPlayed ? (
                        <SvgImageButton
                            svg="mark-as-unplayed"
                            title={formatMessage('mark-as-unplayed')}
                            onClick={onMarkAsUnplayedClick}
                            ariaAttributes={{
                                'aria-label': 'Mark as unplayed',
                            }}
                        />
                    ) : (
                        <SvgImageButton
                            svg="mark-as-played"
                            title={formatMessage('mark-as-played')}
                            onClick={onMarkAsPlayedClick}
                            ariaAttributes={{
                                'aria-label': 'Mark as played',
                            }}
                        />
                    )}

                    {inUpNext ? (
                        <SvgImageButton
                            svg="remove-from-upnext"
                            title={formatMessage('remove-up-next')}
                            onClick={onRemoveFromUpNextClick}
                            ariaAttributes={{
                                'aria-pressed': inUpNext,
                                'aria-label': 'Episode in up next',
                            }}
                        />
                    ) : (
                        <>
                            <SvgImageButton
                                svg="play-next"
                                title={formatMessage('play-next')}
                                onClick={onPlayNextClick}
                                ariaAttributes={{
                                    'aria-label': 'Play Next',
                                }}
                            />
                            <SvgImageButton
                                svg="play-last"
                                title={formatMessage('play-last')}
                                onClick={onPlayLastClick}
                                ariaAttributes={{
                                    'aria-label': 'Play Last',
                                }}
                            />
                        </>
                    )}

                    {isUploadedFile ? (
                        <>
                            <SvgImageButton
                                svg="download-file"
                                title={formatMessage('download-file')}
                                onClick={() => downloadUploadedFile()}
                                ariaAttributes={{
                                    'aria-label': 'Download Episode',
                                }}
                            />{' '}
                            <SvgImageButton
                                svg="edit"
                                title={formatMessage('edit')}
                                onClick={onEditClick}
                                ariaAttributes={{
                                    'aria-label': 'Edit',
                                }}
                            />
                            <SvgImageButton
                                svg="delete"
                                color={red}
                                title={formatMessage('delete')}
                                onClick={onDeleteClick}
                                ariaAttributes={{
                                    'aria-label': 'Delete',
                                }}
                            />
                        </>
                    ) : (
                        <>
                            <a
                                href={episode.url}
                                download
                                target="_blank"
                                rel="noopener noreferrer"
                                tabIndex={0}
                                data-filename={downloadFileName}
                            >
                                <SvgImageButton
                                    svg="download-file"
                                    buttonRole={false}
                                    title={formatMessage('download-file')}
                                    ariaAttributes={{
                                        'aria-label': 'Download Episode',
                                    }}
                                />
                            </a>
                            <SvgImageButton
                                svg="share"
                                title={formatMessage('share')}
                                onClick={onShareClick}
                                ariaAttributes={{
                                    'aria-label': 'Share',
                                }}
                            />
                        </>
                    )}
                </EpisodeActions>
            </PopupMenuBarContent>
        </EpisodePopupToolbarContainer>
    );
}

export default EpisodePopupToolbar;
