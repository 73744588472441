import styled from 'styled-components';
import { ModernStyle } from 'styles/ModernStyle.styled';
import { green20, green70, green30, gridSize } from '../../../styles';

export const CredentialNotificationWrapper = styled.div<{
    height: number;
    variant: 'success' | 'warning';
}>`
    display: flex;
    height: ${props => props.height}px;
    width: 100%;

    background-color: #ffefc4;
    border: 1px solid #ffe08a;
    border-radius: 10px;

    color: #b35900;
    text-align: center;
    font-size: 14.4px;
    line-height: 21px;

    align-items: center;
    justify-content: center;

    box-shadow: 0px 1px 2.5px 0px rgba(0, 0, 0, 0.25);

    ${props =>
        props.variant === 'success' &&
        `
        background: ${green20};
        border: 1px solid ${green30};
        color: ${green70};
    `}

    ${ModernStyle} && {
        border: none;
        height: auto;
        padding: ${4 * gridSize}px;
        text-align: left;
        a {
            color: inherit;
            font-weight: 800;
        }
    }
`;

export const ContentWrapper = styled.div`
    width: 80%;

    ${ModernStyle} & {
        width: 100%;

        > *:first-child {
            margin-top: 0;
        }
        > *:last-child {
            margin-bottom: 0;
        }
    }
`;
