import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import * as TimeHelper from '../../../helper/TimeHelper';
import {
    InputContainer,
    MinutesEdit,
    SettingsEdit,
    SettingsTextContainer,
    SettingsUnit,
} from './MinutesSecondsInputField.styled';

type Props = {
    initialValueInSecs: number;
    onSave: (num: number) => void;
    maximumValue: number;
    onExceededMaxValue: () => void;
};

function MinutesSecondsInputField({
    initialValueInSecs,
    onSave,
    maximumValue,
    onExceededMaxValue,
}: Props) {
    const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;
    const [minutes, setMinutes] = useState<number | ''>(
        TimeHelper.secondsToMinsAndSecs(initialValueInSecs).minutes,
    );
    const [seconds, setSeconds] = useState<number | ''>(
        TimeHelper.secondsToMinsAndSecs(initialValueInSecs).seconds,
    );

    useEffect(() => {
        const { minutes, seconds } = TimeHelper.secondsToMinsAndSecs(initialValueInSecs);
        setMinutes(minutes);
        setSeconds(seconds);
    }, [initialValueInSecs]);

    useEffect(() => {
        // When either value changes, check whether we've exceeded the maximums
        const max = TimeHelper.secondsToMinsAndSecs(maximumValue);
        if (TimeHelper.minsAndSecsToSecs({ minutes, seconds }) > maximumValue) {
            setMinutes(max.minutes);
            setSeconds(max.seconds);
            onExceededMaxValue();
        }
    }, [minutes, seconds, onExceededMaxValue, maximumValue]);

    const parseEventValue = (event: React.ChangeEvent<HTMLInputElement>): number | '' => {
        let eventValue = event.target.value;
        if (eventValue.length > 2) {
            eventValue = eventValue.slice(0, 2);
        }
        if (eventValue.length === 0) {
            return '';
        }
        return Math.abs(parseInt(eventValue, 10) || 0);
    };

    const handleSecsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const seconds = parseEventValue(event);

        // If seconds is numeric, don't let it exceed 59 seconds
        setSeconds(seconds === '' ? '' : Math.min(seconds, 59));
    };

    const handleMinsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMinutes(parseEventValue(event));
    };

    const handleBlur = () => {
        // On blur, if either field is blank set it to 0
        if (minutes === '') {
            setMinutes(0);
        }

        if (seconds === '') {
            setSeconds(0);
        }

        const newValueInSecs = TimeHelper.minsAndSecsToSecs({ minutes, seconds });
        if (initialValueInSecs !== newValueInSecs) {
            // Only trigger onSave if the value has changed
            onSave(newValueInSecs);
        }
    };

    return (
        <SettingsTextContainer className="setting">
            <InputContainer>
                <MinutesEdit>
                    <input
                        data-testid="minutes-input-field"
                        onChange={handleMinsChange}
                        onBlur={handleBlur}
                        type="text"
                        value={minutes}
                        ref={inputRef}
                    />
                    <SettingsUnit>
                        <FormattedMessage id="minutes" />
                    </SettingsUnit>
                </MinutesEdit>

                <SettingsEdit>
                    <input
                        data-testid="seconds-input-field"
                        onChange={handleSecsChange}
                        onBlur={handleBlur}
                        type="text"
                        value={seconds}
                        ref={inputRef}
                    />
                    <SettingsUnit>
                        <FormattedMessage id="seconds" />
                    </SettingsUnit>
                </SettingsEdit>
            </InputContainer>
        </SettingsTextContainer>
    );
}

MinutesSecondsInputField.propTypes = {
    initialValueInSecs: PropTypes.number.isRequired,
    maximumValue: PropTypes.number,
    onExceededMaxValue: PropTypes.func,
    onSave: PropTypes.func,
};

export default MinutesSecondsInputField;
