import styled from 'styled-components';
import { ModernStyle } from 'styles/ModernStyle.styled';
import { gridSize, Typography } from '../../styles';

const CLOSE_BUTTON_SIZE = 72;

export const ModalDialog = styled.div<{ blurred?: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.24);
    border-radius: 6px;
    z-index: 3002;
    background-color: ${props => props.theme.legacyScss['theme-popup-background']};

    ${props => props.blurred && `filter: blur(5px);`}

    ${ModernStyle} & {
        margin: ${2 * gridSize}px;
        border-radius: ${2 * gridSize}px;
    }
`;

export const Header = styled.header`
    min-height: ${CLOSE_BUTTON_SIZE * (2 / 3)}px;
    ${ModernStyle} && {
        min-height: 0;
    }
`;

export const Title = styled.h1<{ horizontalInset: number }>`
    ${Typography.Desktop['H50-MN']};
    line-height: ${CLOSE_BUTTON_SIZE}px;
    padding-right: ${CLOSE_BUTTON_SIZE}px;
    padding-left: ${props => props.horizontalInset}px;
    border-bottom: 1px solid ${props => props.theme.tokens['primary-ui-05']};
    margin-bottom: ${props => props.horizontalInset - gridSize}px;

    ${ModernStyle} & {
        font-size: 24px;
        font-weight: 700;
        border: none;
        text-align: center;
        padding: ${props => props.horizontalInset}px;
        margin-bottom: 0;
        line-height: 1;
    }
`;

export const ModalCoverBackground = styled.div`
    background: ${props => props.theme.legacyScss['fixme-popup-background']};
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;

    z-index: -101;

    ${ModernStyle} & {
        background: rgba(0, 0, 0, 0.8);
    }
`;

export const Cross = styled.button`
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    width: ${CLOSE_BUTTON_SIZE}px;
    height: ${CLOSE_BUTTON_SIZE}px;

    transition: color 0.1s ease;
    color: ${props => (props.theme.name === 'dark' ? 'rgba(255, 255, 255, 0.5)' : '#B8C3C9')};
    &:hover {
        color: ${props => (props.theme.name === 'dark' ? 'rgba(255, 255, 255, 1.0)' : '#8F97A4')};
    }

    svg {
        display: block;
        margin: auto;
    }

    ${ModernStyle} & {
        position: fixed;
        color: #fff;
        &:hover {
            opacity: 0.8;
        }
    }
`;

export const ChildrenWrapper = styled.div<{ horizontalInset: number }>`
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-left: ${props => props.horizontalInset}px;
    margin-right: ${props => props.horizontalInset}px;
    ${ModernStyle} & {
        margin-bottom: ${props => props.horizontalInset}px;
    }
`;

export const ModalWrapper = styled.section`
    position: fixed !important;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: 1000;
    max-width: none !important;
    color: ${props => props.theme.tokens['primary-text-01']};

    display: flex;
    overflow: auto;
    justify-content: center;
    > div {
        display: flex;
        justify-content: center;
        margin: auto;
    }

    @keyframes fade-in {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    @keyframes fade-and-slide-in {
        from {
            opacity: 0;
            transform: translateY(10px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @media (prefers-reduced-motion: no-preference) {
        ${ModalCoverBackground} {
            animation: fade-in 200ms ease;
        }
        ${ModalDialog} {
            animation: fade-and-slide-in 250ms ease;
            animation-delay: 100ms;
            animation-fill-mode: backwards;
        }
    }
`;
