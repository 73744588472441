import { AutoplayEpisode } from 'model/types';
import { RootState } from 'redux/reducers';
import { PlayingStatus } from 'helper/PlayingStatus';
import { getUploadedFilesInOrder } from './uploaded-files.selectors';
import { getSortedEpisodesWithSyncForPodcast } from './podcasts.selectors';
import { getFilterEpisodesWithSync } from './filter.selectors';

export const isAutoplayEnabled = (state: RootState) => state.autoplay.isEnabled;

export const getAutoplayConfig = (state: RootState) => state.autoplay.config;

export const getAutoplayEpisodes = (state: RootState): AutoplayEpisode[] | null => {
    const config = getAutoplayConfig(state);

    if (config === null) {
        return null;
    }

    switch (config.source) {
        case 'files':
            return getUploadedFilesInOrder(state);

        case 'podcast':
            return getSortedEpisodesWithSyncForPodcast(state, config.uuid);

        case 'in_progress':
        case 'new_releases':
        case 'starred':
            return getFilterEpisodesWithSync(state, config.source) ?? null;

        default:
            return null;
    }
};

export const getNextAutoplayEpisode = (state: RootState) => {
    const episodes = getAutoplayEpisodes(state);

    if (episodes === null) {
        return null;
    }

    const playingEpisode = state.player.episode;

    if (!playingEpisode) {
        // Nothing is currently playing, so there shouldn't be any next autoplayed episode
        return null;
    }

    const currentIndex = episodes.findIndex(episode => episode.uuid === playingEpisode.uuid);

    if (currentIndex === -1) {
        // The current episode is not in the autoplay list, so we shouldn't autoplay anything
        return null;
    }

    const nextEpisode = episodes.slice(currentIndex + 1).find(episode => {
        if (episode.playingStatus === PlayingStatus.COMPLETED) {
            return false;
        }
        if ('isDeleted' in episode && episode.isDeleted) {
            return false;
        }
        return true;
    });

    return nextEpisode || null;
};
