import { chain, freeze, setIn } from 'icepick';
import { ActionTypes, Actions } from '../actions/stats.actions';

const INITIAL_STATE = freeze({
    timeSavedSkippingMs: 0,
    timeSavedSkippingIntroMs: 0,
    timeSavedVariableSpeedMs: 0,
    totalListeningTimeMs: 0,

    // This is unused by the web player, but we still want to store it
    timeSilenceRemoval: 0,
});

export type StatsState = typeof INITIAL_STATE;

export default (state: StatsState = INITIAL_STATE, action: Actions): StatsState => {
    switch (action.type) {
        case ActionTypes.ADD_TIME_SAVED_SKIPPING:
            return setIn(
                state,
                ['timeSavedSkippingMs'],
                state.timeSavedSkippingMs + action.payload.timeMs,
            );
        case ActionTypes.ADD_TIME_SAVED_AUTO_SKIPPING:
            return setIn(
                state,
                ['timeSavedSkippingIntroMs'],
                state.timeSavedSkippingIntroMs + action.payload.timeMs,
            );
        case ActionTypes.ADD_TIME_SAVED_VARIABLE_SPEED:
            return setIn(
                state,
                ['timeSavedVariableSpeedMs'],
                state.timeSavedVariableSpeedMs + action.payload.timeMs,
            );
        case ActionTypes.ADD_TOTAL_LISTENING_TIME:
            return setIn(
                state,
                ['totalListeningTimeMs'],
                state.totalListeningTimeMs + action.payload.timeMs,
            );
        case ActionTypes.UPDATE_STATS:
            return chain(state)
                .setIn(['timeSavedSkippingMs'], action.payload.skippingStatMs)
                .setIn(['timeSavedSkippingIntroMs'], action.payload.skippingIntroMs)
                .setIn(['timeSavedVariableSpeedMs'], action.payload.variableSpeedMs)
                .setIn(['totalListeningTimeMs'], action.payload.totalListeningMs)
                .setIn(['timeSilenceRemoval'], state.timeSilenceRemoval)
                .value();
        default:
            return state;
    }
};
