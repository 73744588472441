import styled from 'styled-components';

import { PageTitle } from 'components/PageHeader/PageHeader.styled';
import { gridSize } from 'styles';

export const Title = styled(PageTitle)`
    margin-right: auto;
    padding-right: ${gridSize}px;
`;

export const SearchBox = styled.div`
    display: flex;
    height: 57px;
    align-items: center;

    border-top: 1px solid ${props => props.theme.legacyScss['theme-line']};
    border-bottom: 1px solid ${props => props.theme.legacyScss['theme-line']};

    color: ${props => props.theme.tokens['primary-text-02']};

    .icon-search-bookmarks {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: ${props => props.theme.legacyScss['theme-text-secondary']};
    }

    input {
        font-size: 16px;
        border: 0;
        outline: none;
        width: 100%;
        margin-left: 5px;
        margin-right: 30px;
        background-color: ${props => props.theme.legacyScss['theme-background']};
        color: ${props => props.theme.legacyScss['theme-text-primary']};
    }

    #clear-search-bar {
        width: 30px;
        height: 30px;
        margin-right: -4px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .loading-icon-wrapper {
        display: flex;
        align-items: center;
        width: 30px;
        height: 30px;
        margin-right: -4px;
        padding-left: 5px;
    }
`;
