import { Icon, IconId } from 'components/Icon';
import React from 'react';
import { Switch } from '../../form/Switch';
import { ImageTextSwitchOptionContainer } from './ImageTextSwitchOption.styled';

type Props = {
    on: boolean;
    onSvg: IconId;
    onText: string;
    offText: string;
    offSvg: IconId;
    color?: string;
    onClick?: (e: React.SyntheticEvent | Event) => void;
    onClose?: (e: React.SyntheticEvent | Event) => void;
};

/* This class is an adaptation of ImageTextOption that includes a Switch component */
class ImageTextSwitchOption extends React.Component<Props> {
    onClick = (e: Event | React.SyntheticEvent) => {
        if (this.props.onClick) {
            this.props.onClick(e);
        }
    };

    render() {
        const { on, onSvg, offSvg, onText, offText, color } = this.props;
        return (
            <ImageTextSwitchOptionContainer onClick={this.onClick}>
                {this.props.children || <Icon id={on ? onSvg : offSvg} color={color} />}
                <div className="option-title">{on ? onText : offText}</div>
                <div className="option-switch">
                    <Switch on={on} color={color} onChange={() => ({})} />
                </div>
            </ImageTextSwitchOptionContainer>
        );
    }
}

export default ImageTextSwitchOption;
