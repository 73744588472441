import { RefObject, useEffect } from 'react';
import { DefaultTheme } from 'styled-components';
import {
    LINE_HEIGHT,
    LINE_SPACING,
    LINE_WIDTH,
    MAX_DISTANCE,
    MIN_DISTANCE,
    NUMBER_OF_LINES,
} from '../constants';
import { createSvgElement, lcg } from '../util';

interface Props {
    ref: RefObject<SVGSVGElement>;
    url: string;
    duration: number;
    theme: DefaultTheme;
}

/*
 * Generates a waveform graphic for the clipping UI
 *
 * This is a fake waveform since we cannot generate a waveform from a URL thanks to CORS restrictions.
 * However the randomness is seeded by the URL so the waveform will be consistent for the same URL.
 *
 * TODO: In the future, we could generate waveform info on a meta server and cache it for a short time.
 * See https://a8c.slack.com/archives/C028JBJJ1N3/p1710743408328169?thread_ts=1710440022.969279&cid=C028JBJJ1N3
 */
export const useWaveform = ({ ref, url, theme, duration }: Props) => {
    useEffect(() => {
        const svg = ref.current;
        if (!svg) {
            return;
        }
        svg.innerHTML = '';

        const items = Array(MAX_DISTANCE - MIN_DISTANCE + 1)
            .fill(null)
            .map((_, idx) => MIN_DISTANCE + idx);

        const rect = createSvgElement('rect', {
            width: '100%',
            height: '100%',
            fill: theme.tokens['primary-ui-04'],
        });
        svg.appendChild(rect);

        const rng = lcg(url);
        for (let i = 1; i < NUMBER_OF_LINES; i += 1) {
            const y1 = items[Math.floor(rng() * items.length)];
            const y2 = LINE_HEIGHT - y1;
            const x = i * LINE_SPACING;

            const line = createSvgElement('line', {
                x1: x,
                y1,
                x2: x,
                y2,
                'stroke-width': LINE_WIDTH,
                stroke: theme.tokens['primary-text-02'],
            });

            svg.appendChild(line);
        }

        svg.setAttribute('width', `${NUMBER_OF_LINES * LINE_SPACING}`);
    }, [ref, url, theme, duration]);
};
