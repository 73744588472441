import styled from 'styled-components';

export const PopupMenuWrapper = styled.div`
    cursor: pointer;
    height: 30px;
    width: 30px;
    .symbol {
        height: 30px;
        width: 30px;
    }
    .popup {
        .separator {
            position: relative;
            width: 100%;
            top: 0;
        }
    }
    button {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;
