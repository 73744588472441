import { isBetaEnabled, persistBetaEnabled } from 'helper/BetaHelper';
import { useCallback, useState } from 'react';
import useTracks from './useTracks';

const useIsBeta = () => {
    const [isBeta, setIsBeta] = useState(isBetaEnabled);

    const { recordEvent } = useTracks();

    const toggleBeta = useCallback(() => {
        const updatedIsBeta = !isBeta;

        recordEvent('settings_beta_toggled', {
            enabled: updatedIsBeta,
        });

        setIsBeta(updatedIsBeta);
        persistBetaEnabled(updatedIsBeta);
    }, [isBeta, recordEvent]);

    return [isBeta, toggleBeta] as const;
};

export default useIsBeta;
