import { FileUploadRecord, TodoFixmeMigrationType, UploadedFile } from 'model/types';
import { UPLOADED_FILES_PODCAST_UUID } from 'model/uploaded-files';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Channel, END, eventChannel } from 'redux-saga';
import { ActionChannelEffect, call, delay, put, select, take, takeEvery } from 'redux-saga/effects';
import { isEdge } from '../../helper/Browser';
import * as filesApi from '../../services/filesApi';
import * as fromFlagsActions from '../actions/flags.actions';
import * as fromTracksActions from '../actions/tracks.actions';
import * as fromUpNextActions from '../actions/up-next.actions';
import * as fromUploadManagerActions from '../actions/upload-manager.actions';
import * as fromUploadedFilesActions from '../actions/uploaded-files.actions';
import {
    getSettings,
    getUploadManagerFiles,
    getUploadManagerFilesOrder,
    getUploadManagerImages,
    getUploadedFileCurried,
    getUploadedFileLastModified,
    getUploadedFilesMap,
} from '../reducers/selectors';
import { getUploadedFileFetchingIfNecessary } from './uploaded-files.saga';

/*
 * When we're uploading files to the Files API, there are a lot of instances where it needs
 * some additional processing time once the requests themselves have completed. As there is
 * no way to determine that time exactly, there are a number of delay calls in these Sagas.
 */

const createUploadFileChannel = (file: TodoFixmeMigrationType, uuid: string, url: string) =>
    eventChannel(emitter => {
        const xhr = new XMLHttpRequest();

        let lastProgress = 0;
        let lastTimeout: ReturnType<typeof setTimeout> | null = null;

        const updateProgress = (e: ProgressEvent) => {
            const thisLastProgress = e.loaded;
            lastProgress = e.loaded;

            // If we see no progress for 90 seconds, abort this file's upload as it's likely
            // the user's Internet (or our servers) are having problems. In each callback we
            // clear the previous timer because it is no longer needed.
            if (lastTimeout) {
                clearTimeout(lastTimeout);
            }

            lastTimeout = setTimeout(() => {
                if (lastProgress === thisLastProgress) {
                    xhr?.abort?.();
                }
            }, 90000);

            emitter({
                progress: e.loaded,
                total: e.total,
                error: null,
                success: false,
                xhr,
            });
        };

        const transferComplete = (e: ProgressEvent) => {
            if (lastTimeout) {
                clearTimeout(lastTimeout);
            }

            emitter({
                progress: e.loaded,
                total: e.total,
                error: null,
                success: true,
                xhr,
            });
            emitter(END);
        };

        const transferFailed = () => {
            if (lastTimeout) {
                clearTimeout(lastTimeout);
            }

            emitter({
                progress: 0,
                total: 1,
                error: 'File upload error. Please try again later.',
                success: false,
                xhr,
            });
            emitter(END);
        };

        const transferAborted = () => {
            if (lastTimeout) {
                clearTimeout(lastTimeout);
            }

            emitter({
                progress: 0,
                total: 1,
                error: 'File upload error. Please try again later.',
                aborted: true,
                success: false,
                xhr,
            });
            emitter(END);
        };

        xhr.upload.addEventListener('progress', updateProgress);
        xhr.upload.addEventListener('error', transferFailed);
        xhr.upload.addEventListener('abort', transferAborted);

        xhr.onload = () => {
            transferComplete(xhr.response);
        };

        xhr.open('PUT', url);
        xhr.overrideMimeType('text/plain; charset=x-user-defined-binary');

        xhr.send(file);

        return () => {
            xhr.upload.removeEventListener('progress', updateProgress);
            xhr.upload.removeEventListener('error', transferFailed);
            xhr.upload.removeEventListener('abort', transferAborted);

            if (isEdge()) {
                // Edge has a bug where sometimes these PUT requests will report completion but
                // actually remain pending in the network layer. Prodding the xhr state like this
                // triggers Edge to close the connection successfully if it hasn't already.
                xhr.getAllResponseHeaders();
            }
        };
    });

function* doFileUpload(
    action: ReturnType<typeof fromUploadManagerActions.Actions.addFileToUploadManager>,
) {
    const { file, uuid, url, errorIfPresent } = action.payload;
    yield put(
        fromTracksActions.Actions.recordEvent('episode_upload_queued', {
            episode_uuid: uuid,
            source: 'files',
        }),
    );

    // We know from the preflight request that this won't work
    if (errorIfPresent || !url || !uuid) {
        yield put(fromUploadManagerActions.Actions.failFileInUploadManager(uuid));
        return;
    }

    const channel: Channel<ActionChannelEffect> = yield call(
        createUploadFileChannel,
        file,
        uuid,
        url,
    );

    while (true) {
        const { progress, total, error, success, xhr, aborted } = yield take(channel);

        if (error) {
            yield put(
                fromTracksActions.Actions.recordEvent(
                    aborted ? 'episode_upload_cancelled' : 'episode_upload_failed',
                    {
                        episode_uuid: uuid,
                        source: 'files',
                        error: error?.message,
                    },
                ),
            );
            yield put(fromUploadManagerActions.Actions.failFileInUploadManager(uuid, error));
            return;
        }

        if (success) {
            const settings: ReturnType<typeof getSettings> = yield select(getSettings);

            yield put(
                fromTracksActions.Actions.recordEvent('episode_upload_finished', {
                    episode_uuid: uuid,
                    source: 'files',
                }),
            );
            yield delay(1000);
            yield put(fromUploadManagerActions.Actions.completeFileInUploadManager(uuid));
            yield delay(2000);
            yield put(fromUploadedFilesActions.Actions.fetchUploadedFilesData());

            if (settings.filesAutoUpNext) {
                const file: UploadedFile = yield getUploadedFileFetchingIfNecessary(uuid);
                const fileAsEpisode = {
                    ...file,
                    url: '',
                };
                yield put(
                    fromUpNextActions.Actions.upNextPlayLast(
                        UPLOADED_FILES_PODCAST_UUID,
                        fileAsEpisode,
                        {
                            eventSource: null,
                        },
                    ),
                );
            }

            return;
        }

        yield put(
            fromUploadManagerActions.Actions.updateFileInUploadManager(
                uuid,
                progress,
                total,
                error,
                xhr,
            ),
        );
    }
}

function* fileCompletedOrFailedInUploadManager() {
    const uploadFilesOrder: ReturnType<typeof getUploadManagerFilesOrder> = yield select(
        getUploadManagerFilesOrder,
    );
    const files: ReturnType<typeof getUploadManagerFiles> = yield select(getUploadManagerFiles);

    // Completed => .complete
    // Failed => .failed
    // Finished => .complete || .failed

    let allComplete = true;
    let allFinished = true;
    let completeCount = 0;

    uploadFilesOrder.forEach((uuid: string) => {
        if (files[uuid].complete) {
            completeCount += 1;
        } else {
            allComplete = false;
            if (!files[uuid].failed) {
                allFinished = false;
            }
        }
    });

    if (allFinished) {
        if (completeCount === 1) {
            yield put(
                fromFlagsActions.Actions.addFlag(<FormattedMessage id="one-file-uploaded" />),
            );
        } else if (completeCount > 1) {
            yield put(
                fromFlagsActions.Actions.addFlag(
                    <FormattedMessage
                        id="n-files-uploaded"
                        values={{
                            number: completeCount,
                        }}
                    />,
                ),
            );
        }

        if (allComplete) {
            yield put(fromUploadManagerActions.Actions.clearFilesFromUploadManager());
        }
    }
}

// This can't just be done in a reducer because the abort() call will make it
// appear to Redux that an action is being dispatched from a reducer.
function* fileAbortInUploadManager(
    action: ReturnType<typeof fromUploadManagerActions.Actions.abortImageInUploadManager>,
) {
    const { uuid } = action.payload;
    const files: ReturnType<typeof getUploadManagerFiles> = yield select(getUploadManagerFiles);
    yield files[uuid] && files[uuid].xhr && files[uuid].xhr?.abort && files[uuid].xhr?.abort();
    yield put(fromUploadManagerActions.Actions.removeFileFromUploadManager(uuid));
}

function* doImageUpload(
    action: ReturnType<typeof fromUploadManagerActions.Actions.addImageToUploadManager>,
) {
    const { file, uuid, url } = action.payload;

    if (!file || !uuid || !url) {
        yield put(fromUploadManagerActions.Actions.failImageInUploadManager(uuid));
        yield delay(2000);
        yield put(fromUploadManagerActions.Actions.abortImageInUploadManager(uuid));
        return;
    }

    const channel: Channel<ActionChannelEffect> = yield call(
        createUploadFileChannel,
        file,
        uuid,
        url,
    );

    while (true) {
        const { progress, total, error, success, xhr } = yield take(channel);

        if (error) {
            yield put(fromUploadManagerActions.Actions.failImageInUploadManager(uuid));
            yield delay(2000);
            yield put(fromUploadManagerActions.Actions.abortImageInUploadManager(uuid));
            return;
        }

        if (success) {
            yield put(fromUploadManagerActions.Actions.completeImageInUploadManager(uuid));
            return;
        }

        yield put(
            fromUploadManagerActions.Actions.updateImageInUploadManager(
                uuid,
                progress,
                total,
                error,
                xhr,
            ),
        );
    }
}

function* imageCompleteInUploadManager(
    action: ReturnType<typeof fromUploadManagerActions.Actions.completeImageInUploadManager>,
) {
    const { uuid } = action.payload;

    // Poll until we have confirmed the image has changed
    const currentFiles: Record<string, FileUploadRecord> = yield select(getUploadedFilesMap);
    const thisFile = currentFiles[uuid];
    const currentArtworkImageUrl = thisFile.artworkImageUrl;

    let counter = 0;
    let foundNewImage = false;
    let mostRecentFileFetch: FileUploadRecord | null = null;
    let mostRecentIfModifiedSince: string = yield select(getUploadedFileLastModified, uuid);

    while (counter < 10 && !foundNewImage) {
        yield delay(1000);

        mostRecentFileFetch = yield call(
            filesApi.getUploadedFile,
            uuid,
            0, // We don't care about the theme here
            mostRecentIfModifiedSince,
            getUploadedFileCurried(yield select()),
        );

        const newArtworkImageUrl = mostRecentFileFetch?.artworkImageUrl;

        if (newArtworkImageUrl !== currentArtworkImageUrl) {
            foundNewImage = true;
        }

        if (mostRecentFileFetch?.lastModified && mostRecentFileFetch) {
            const lastIfModifiedSinceDate = new Date(mostRecentIfModifiedSince).getTime();
            const thisIfModifiedSinceDate = new Date(mostRecentFileFetch.lastModified).getTime();

            if (thisIfModifiedSinceDate > lastIfModifiedSinceDate) {
                mostRecentIfModifiedSince = mostRecentFileFetch.lastModified;
            }
        }

        counter += 1;
    }

    // Make sure the color is set to 0 to render the image. As a side-effect, this will
    // force a refresh of the file in the File Edit Modal.
    yield put(fromUploadedFilesActions.Actions.requestUpdateFile(uuid, { color: 0 }));

    // Remove the image from the upload manager which will prompt a re-render of the Edit modal
    yield put(fromUploadManagerActions.Actions.removeImageFromUploadManager(uuid));
}

// This can't just be done in a reducer because the abort() call will make it
// appear to Redux that an action is being dispatched from a reducer.
function* imageAbortInUploadManager(
    action: ReturnType<typeof fromUploadManagerActions.Actions.abortImageInUploadManager>,
) {
    const { uuid } = action.payload;
    const images: ReturnType<typeof getUploadManagerImages> = yield select(getUploadManagerImages);
    yield images[uuid] && images[uuid].xhr && images[uuid].xhr?.abort && images[uuid].xhr?.abort();
    yield put(fromUploadManagerActions.Actions.removeImageFromUploadManager(uuid));
}

export function* watchAddFileToUploadManager() {
    yield takeEvery(fromUploadManagerActions.ActionTypes.FILE_ADD_TO_UPLOAD_MANAGER, doFileUpload);
}

export function* watchCompleteFileInUploadManager() {
    yield takeEvery(
        fromUploadManagerActions.ActionTypes.FILE_COMPLETE_IN_UPLOAD_MANAGER,
        fileCompletedOrFailedInUploadManager,
    );
}

export function* watchFailedFileInUploadManager() {
    yield takeEvery(
        fromUploadManagerActions.ActionTypes.FILE_FAIL_IN_UPLOAD_MANAGER,
        fileCompletedOrFailedInUploadManager,
    );
}

export function* watchAbortFileInUploadManager() {
    yield takeEvery(
        fromUploadManagerActions.ActionTypes.FILE_ABORT_IN_UPLOAD_MANAGER,
        fileAbortInUploadManager,
    );
}

export function* watchAddImageToUploadManager() {
    yield takeEvery(
        fromUploadManagerActions.ActionTypes.IMAGE_ADD_TO_UPLOAD_MANAGER,
        doImageUpload,
    );
}

export function* watchCompleteImageInUploadManager() {
    yield takeEvery(
        fromUploadManagerActions.ActionTypes.IMAGE_COMPLETE_IN_UPLOAD_MANAGER,
        imageCompleteInUploadManager,
    );
}

export function* watchAbortImageInUploadManager() {
    yield takeEvery(
        fromUploadManagerActions.ActionTypes.IMAGE_ABORT_IN_UPLOAD_MANAGER,
        imageAbortInUploadManager,
    );
}
