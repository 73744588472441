// Forked from https://github.com/Automattic/a8c-analytics
function a8cAnalyticsSetup() {
    // --------- Mozilla Cookie Library ----------
    // https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie/Simple_document.cookie_framework

    const docCookies = {
        getItem(sKey) {
            if (!sKey) {
                return null;
            }
            return (
                decodeURIComponent(
                    document.cookie.replace(
                        new RegExp(
                            `(?:(?:^|.*;)\\s*${encodeURIComponent(sKey).replace(
                                /[-.+*]/g,
                                '\\$&',
                            )}\\s*\\=\\s*([^;]*).*$)|^.*$`,
                        ),
                        '$1',
                    ),
                ) || null
            );
        },
        setItem(sKey, sValue, vEnd, sPath, sDomain, bSecure) {
            if (!sKey || /^(?:expires|max-age|path|domain|secure)$/i.test(sKey)) {
                return false;
            }
            let sExpires = '';
            if (vEnd) {
                switch (vEnd.constructor) {
                    case Number:
                        sExpires =
                            vEnd === Infinity
                                ? '; expires=Fri, 31 Dec 9999 23:59:59 GMT'
                                : `; max-age=${vEnd}`;
                        break;
                    case String:
                        sExpires = `; expires=${vEnd}`;
                        break;
                    case Date:
                        sExpires = `; expires=${vEnd.toUTCString()}`;
                        break;
                    default:
                }
            }
            document.cookie = `${encodeURIComponent(sKey)}=${encodeURIComponent(
                sValue,
            )}${sExpires}${sDomain ? `; domain=${sDomain}` : ''}${sPath ? `; path=${sPath}` : ''}${
                bSecure ? '; secure' : ''
            }`;
            return true;
        },
        removeItem(sKey, sPath, sDomain) {
            if (!this.hasItem(sKey)) {
                return false;
            }
            document.cookie = `${encodeURIComponent(sKey)}=; expires=Thu, 01 Jan 1970 00:00:00 GMT${
                sDomain ? `; domain=${sDomain}` : ''
            }${sPath ? `; path=${sPath}` : ''}`;
            return true;
        },
        hasItem(sKey) {
            if (!sKey || /^(?:expires|max-age|path|domain|secure)$/i.test(sKey)) {
                return false;
            }
            return new RegExp(
                `(?:^|;\\s*)${encodeURIComponent(sKey).replace(/[-.+*]/g, '\\$&')}\\s*\\=`,
            ).test(document.cookie);
        },
        keys() {
            const aKeys = document.cookie
                // eslint-disable-next-line no-useless-backreference
                .replace(/((?:^|\s*;)[^=]+)(?=;|)|^\s*|\s*(?: =[^;]*)?(?:\1|$)/g, '')
                .split(/\s*(?:=[^;]*)?;\s*/);
            for (let nLen = aKeys.length, nIdx = 0; nIdx < nLen; nIdx += 1) {
                aKeys[nIdx] = decodeURIComponent(aKeys[nIdx]);
            }
            return aKeys;
        },
    };

    // --------- Cookie Banner Logic ----------

    // Init Tracks temp object if not present already
    window.tkq = window.tkq || [];

    window.cbq = window.cbq || [];

    // Injected CSS

    const DEFAULT_CSS = `
    .a8c-cookie-banner {
		display: block;
		z-index: 50001;
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		margin: 0;
		padding: 18px;
		background-color: #f0f0f0;
		border-top: 1px solid rgba(0, 0, 0, 0.05);
		transform: translateY(+100%);
		transition: transform 600ms ease-out;
	}
	.a8c-cookie-banner p {
		display: inline-block;
		color: black;
		margin: 0 240px 0 0 ;
		padding: 0;
		font-family: sans-serif;
		font-size: 15px;
		font-weight: normal;
		line-height: 1.25;
	}
	.a8c-cookie-banner-custom-options {
		display: none;
	}
	.a8c-cookie-banner-custom-options p {
		margin: 18px 0 0;
	}
	.a8c-cookie-banner a {
		color: #0087BE;
		font-family: sans-serif;
		font-size: 15px;
		font-weight: normal;
		line-height: 1.25;
	}
	a.a8c-cookie-banner-customize-button,
	a.a8c-cookie-banner-accept-all-button,
	a.a8c-cookie-banner-accept-selection-button {
		box-sizing: border-box;
		display: inline-block;
		position: absolute;
		top: 50%;
		width: 110px;
		transform: translateY(-50%);
		border-radius: 4px;
		border: 1px solid #e0e0e0;
		padding: 10px;
		text-align: center;
		font-family: sans-serif;
		text-decoration: none;
	}
	a.a8c-cookie-banner-customize-button {
		background-color: white;
		color: #333;
		right: 130px;
	}
	a.a8c-cookie-banner-accept-all-button {
		background-color: #333;
		color: white;
		right: 10px;
	}
	a.a8c-cookie-banner-accept-selection-button {
		position: static;
		transform: none;
		display: block;
		width: auto;
		min-width: 150px;
		max-width: 500px;
		margin: 18px auto 0 auto;
		background-color: #333;
		color: white;
	}
	@media only screen and (max-width : 600px) {
		.a8c-cookie-banner {
			padding: 10px 10px 10px 10px;
		}
		.a8c-cookie-banner p {
			margin: 0 0 10px 0;
			text-align: justify;
		}
		.a8c-cookie-banner a.a8c-cookie-banner-accept-all-button {
			display: block;
			position: static;
			top: auto;
			right: auto;
			transform: none;
			min-width: 89px;
			width: 50%;
			margin: 0 auto;
		}
    }`;

    // see gdpr.php
    const GDPR_COUNTRIES = [
        // European Member countries
        'AT', // Austria
        'BE', // Belgium
        'BG', // Bulgaria
        'CY', // Cyprus
        'CZ', // Czech Republic
        'DE', // Germany
        'DK', // Denmark
        'EE', // Estonia
        'ES', // Spain
        'FI', // Finland
        'FR', // France
        'GR', // Greece
        'HR', // Croatia
        'HU', // Hungary
        'IE', // Ireland
        'IT', // Italy
        'LT', // Lithuania
        'LU', // Luxembourg
        'LV', // Latvia
        'MT', // Malta
        'NL', // Netherlands
        'PL', // Poland
        'PT', // Portugal
        'RO', // Romania
        'SE', // Sweden
        'SI', // Slovenia
        'SK', // Slovakia
        'GB', // United Kingdom
        // Single Market Countries that GDPR applies to
        'CH', // Switzerland
        'IS', // Iceland
        'LI', // Liechtenstein
        'NO', // Norway
    ];

    // Best practice is to show the cookie banner every 6 months or less
    const SIX_MONTHS_MAX = 6 * 30 * 24 * 60 * 60;

    const COOKIE_NAME = 'sensitive_pixel_option';

    window.a8cAnalytics = {
        firedSensitivePixelsEvent: false,

        allBucketsNull: {
            essential: null,
            analytics: null,
            advertising: null,
        },

        allBucketsTrue: {
            essential: true,
            analytics: true,
            advertising: true,
        },

        getOptions() {
            const options = {
                version: 20220901,
                ok: null,
                buckets: cb.allBucketsNull,
            };
            let userOptions = docCookies.getItem(COOKIE_NAME);
            try {
                userOptions = userOptions ? JSON.parse(userOptions) : cb.options;
            } catch (e) {
                console.error(e);
            }
            userOptions = userOptions && typeof userOptions === 'object' ? userOptions : {};
            options.ok = userOptions.ok;
            options.buckets = { ...options.buckets, ...userOptions.buckets };

            return options;
        },

        updateOptions(config, ok, buckets) {
            const options = cb.getOptions(config);

            // Update options.
            options.ok = !!ok;
            options.buckets = { ...options.buckets, ...(buckets || {}) };

            // Best practice is to show the cookie banner every 6 months or less.
            docCookies.setItem(COOKIE_NAME, JSON.stringify(options), SIX_MONTHS_MAX, '/');

            cb.maybeFireTrackers(config);
        },

        maybeShowCookieBanner(config, countryCode, options) {
            config.isGdprCountry = GDPR_COUNTRIES.indexOf(countryCode) !== -1;

            if (!config.isGdprCountry) {
                return;
            }
            if (options.ok === true || options.ok === false) {
                return;
            }

            cb.showCookieBanner(config, options);
        },

        showCookieBanner(config, options) {
            // Inject CSS
            const bannerCss = document.createElement('style');
            bannerCss.setAttribute('type', 'text/css');
            bannerCss.innerHTML = DEFAULT_CSS;
            document.head.appendChild(bannerCss);

            const banner = document.createElement('form');
            banner.setAttribute('method', 'post');
            banner.setAttribute('action', '#');
            banner.setAttribute('novalidate', 'novalidate');
            banner.classList.add('a8c-cookie-banner', 'custom-cookie-banner');

            const bannerSimpleOptions = document.createElement('div');
            bannerSimpleOptions.classList.add('a8c-cookie-banner-simple-options');
            banner.appendChild(bannerSimpleOptions);

            const simpleOptionsText = document.createElement('p');
            simpleOptionsText.innerHTML =
                'As an open source company, we take your privacy seriously and want to be as transparent as possible. So: We use cookies to collect some personal data from you (like your browsing data, IP addresses, and other unique identifiers). Some of these cookies we absolutely need in order to make things work, and others you can choose in order to optimize your experience while using our site and services.';

            const customizeButton = document.createElement('a');
            customizeButton.setAttribute('href', '#');
            customizeButton.innerText = 'Customize';
            customizeButton.classList.add('a8c-cookie-banner-customize-button');

            const acceptAllButton = document.createElement('a');
            acceptAllButton.setAttribute('href', '#');
            acceptAllButton.innerText = 'Accept All';
            acceptAllButton.classList.add('a8c-cookie-banner-accept-all-button');

            bannerSimpleOptions.appendChild(simpleOptionsText);
            bannerSimpleOptions.appendChild(customizeButton);
            bannerSimpleOptions.appendChild(acceptAllButton);

            const bannerCustomOptions = document.createElement('div');
            bannerCustomOptions.classList.add('a8c-cookie-banner-custom-options');

            const customOptionsText1 = document.createElement('p');
            customOptionsText1.innerHTML =
                'Your privacy is critically important to us. We and our partners use, store, and process your personal data to optimize: our <strong>website</strong> such as by improving security or conducting analytics, <strong>marketing activities</strong> to help deliver relevant marketing or content, and your <strong>user experience</strong> such as by remembering your account name, language settings, or cart information, where applicable. You can customize your cookie settings below. Learn more in our <a href="https://support.pocketcasts.com/article/privacy-policy/" target="_blank">Privacy Policy</a>.';
            bannerCustomOptions.appendChild(customOptionsText1);

            const customOptionsText2 = document.createElement('p');
            customOptionsText2.innerHTML =
                '<input type="checkbox" class="a8c-cookie-banner-option--essential-bucket" checked disabled />  <strong>Required:</strong> These cookies are essential for our websites and services to perform basic functions and are necessary for us to operate certain features, like allowing registered users to authenticate and perform account-related functions, storing preferences set by users (like account name, language, and location), and ensuring our services operate properly.';
            bannerCustomOptions.appendChild(customOptionsText2);

            const customOptionsText3 = document.createElement('p');
            customOptionsText3.innerHTML = `<input type="checkbox" class="a8c-cookie-banner-option--analytics-bucket"${
                options.buckets.analytics === false ? '' : ' checked'
            } />  <strong>Analytics:</strong> These cookies allow us to optimize performance by collecting information on how users interact with our websites.`;
            bannerCustomOptions.appendChild(customOptionsText3);

            const acceptSelectionButton = document.createElement('a');
            acceptSelectionButton.innerText = 'Accept Selection';
            acceptSelectionButton.classList.add('a8c-cookie-banner-accept-selection-button');
            bannerCustomOptions.appendChild(acceptSelectionButton);

            banner.appendChild(bannerCustomOptions);
            document.body.appendChild(banner);

            // Ease-in cookie banner
            banner.style.transform = 'none';
            function onBannerSubmit(event) {
                event.preventDefault();
            }
            banner.addEventListener('submit', onBannerSubmit);

            function onCustomizeClick(event) {
                event.preventDefault();
                bannerSimpleOptions.style.display = 'none';
                bannerCustomOptions.style.display = 'block';
            }
            customizeButton.addEventListener('click', onCustomizeClick);

            // Fire `a8c-cookie-banner:fire-pixels` event
            function onAcceptAllClick(event) {
                event.preventDefault();

                banner.style.display = 'none';

                cb.updateOptions(config, true, cb.allBucketsTrue);

                // Record event in Tracks (if available)
                window.tkq.push([
                    'recordEvent',
                    'a8c_cookie_banner_ok',
                    {
                        site: document.location.host,
                        path: document.location.pathname,
                    },
                ]);

                window.document.dispatchEvent(
                    new CustomEvent('a8c-analytics:accept-all-clicked', {
                        detail: cb.getOptions(),
                    }),
                );

                cb.maybeFireTrackers(config);
            }
            acceptAllButton.addEventListener('click', onAcceptAllClick);

            function onAcceptSelectionClick(event) {
                event.preventDefault();

                // Record event in Tracks (if available)
                window.tkq.push([
                    'recordEvent',
                    'a8c_cookie_banner_ok',
                    {
                        site: document.location.host,
                        path: document.location.pathname,
                    },
                ]);

                banner.style.display = 'none';

                cb.updateOptions(config, true, {
                    ...cb.allBucketsTrue,
                    essential: true,
                    analytics: customOptionsText3.querySelectorAll('input')[0].checked,
                    advertising: false,
                });

                window.document.dispatchEvent(
                    new CustomEvent('a8c-analytics:accept-selection-clicked', {
                        detail: cb.getOptions(),
                    }),
                );

                cb.maybeFireTrackers(config);
            }
            acceptSelectionButton.addEventListener('click', onAcceptSelectionClick);

            // Count number of cookie banner view events
            const sanitized_host = document.location.host.replace(/[^a-zA-Z0-9]/g, '-');
            window.stq = window.stq || [];
            window.stq.push([
                'extra',
                {
                    'x_cookie-banner-view': `total,${sanitized_host}`,
                },
            ]);
            window.document.dispatchEvent(new CustomEvent('a8c-analytics:cookies-banner-shown'));
        },

        maybeFireTrackers(config) {
            const options = cb.getOptions(config);

            function processTracker(item) {
                // Only essential trackers can fire when a user has denied consent.
                if (options.ok === false && item.bucket !== 'essential') {
                    return; // Do not fire.
                }

                // Trackers requiring GDPR consent must not fire until we have consent.
                if (!options.ok && item.requiresConsentInGDPRCountries && config.isGdprCountry) {
                    return; // Do not fire.
                }

                // Every tracker must be in a bucket that's not disabled, except for essential trackers, which are always on.
                if (
                    (!item.bucket || options.buckets[item.bucket] === false) &&
                    item.bucket !== 'essential'
                ) {
                    return; // Do not fire.
                }

                // OK, let's fire the tracker if it hasn't fired already.
                if (typeof item.callback === 'function' && !item.callbackFired) {
                    item.callbackFired = true;
                    item.callback();
                }
            }
            window.cbq.forEach(processTracker);

            // Back compatibility for trackers attached to `a8c-analytics:fire-sensitive-pixels`.
            // Important that we only check the `ok` option here, because back compat. doesn't support bucketed trackers.
            if ((!config.isGdprCountry || options.ok === true) && !cb.firedSensitivePixelsEvent) {
                cb.firedSensitivePixelsEvent = true;
                window.document.dispatchEvent(new Event('a8c-analytics:fire-sensitive-pixels'));
            }
        },

        initCookieBanner() {
            // passed by `wp_localize_script()`
            const config = {};
            const options = cb.getOptions(config);

            window.document.dispatchEvent(
                new CustomEvent('a8c-analytics:loaded', {
                    detail: {
                        maybeFireTrackers: () => cb.maybeFireTrackers(config),
                    },
                }),
            );

            let countryCode = docCookies.getItem('country_code');
            if (countryCode === null) {
                fetch('https://public-api.wordpress.com/geo/')
                    .then(response => {
                        response.json().then(data => {
                            countryCode = data.country_short;
                            docCookies.setItem('country_code', countryCode, 6 * 60 * 60, '/');
                            cb.maybeShowCookieBanner(config, countryCode, options);
                            cb.maybeFireTrackers(config);
                        });
                    })
                    .catch(() => {
                        countryCode = 'unknown';
                        docCookies.setItem('country_code', countryCode, 6 * 60 * 60, '/');
                        cb.maybeShowCookieBanner(config, countryCode, options);
                        cb.maybeFireTrackers(config);
                    });
            } else {
                cb.maybeShowCookieBanner(config, countryCode, options);
                cb.maybeFireTrackers(config);
            }
        },
    };
    const cb = window.a8cAnalytics;

    // --- A8C Cookie Banner Kit ---

    window.a8cAnalytics.kit = {
        docCookies,

        doNotTrack() {
            // Internet Explorer 11 uses window.doNotTrack rather than navigator.doNotTrack.
            // Safari 7.1.3+ uses window.doNotTrack rather than navigator.doNotTrack.
            // MDN ref: https://developer.mozilla.org/en-US/docs/Web/API/navigator/doNotTrack#Browser_compatibility
            return (
                window.doNotTrack === '1' ||
                (window.navigator && window.navigator.doNotTrack === '1')
            );
        },
        createScriptElement(url, attrs, onLoad, onError) {
            const script = document.createElement('script');
            script.src = url;
            script.type = 'text/javascript';
            script.async = true;
            script.onload = onLoad;
            script.onerror = onError;
            if (attrs) {
                for (const key in attrs) {
                    if (Object.prototype.hasOwnProperty.call(attrs, key)) {
                        script.setAttribute(key, attrs[key]);
                    }
                }
            }
            return script;
        },
        attachToBody(element) {
            document.body.appendChild(element);
            return element;
        },
        attachScriptElement(url, attrs, onLoad, onError) {
            return cb.kit.attachToBody(cb.kit.createScriptElement(url, attrs, onLoad, onError));
        },
        loadPixelImage(url) {
            const img = document.createElement('img');
            img.setAttribute('src', url);
            img.setAttribute('width', 1);
            img.setAttribute('height', 1);
            img.setAttribute('border', 0);
            return document.createElement(img);
        },
        appendIFrame(url) {
            const iframe = document.createElement('iframe');
            iframe.setAttribute('src', url);
            iframe.setAttribute('width', '1');
            iframe.setAttribute('height', '1');
            iframe.setAttribute('frameborder', '0');
            iframe.style.display = 'none';
            document.getElementById('wpcom-ad-tracking').appendChild(iframe);
            return iframe;
        },
        loadGoogleAnalytics() {
            /* eslint-disable */
            (function (i, s, o, g, r, a, m) {
                i.GoogleAnalyticsObject = r;
                (i[r] =
                    i[r] ||
                    function () {
                        (i[r].q = i[r].q || []).push(arguments);
                    }),
                    (i[r].l = 1 * new Date());
                (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
                a.async = 1;
                a.src = g;
                m.parentNode.insertBefore(a, m);
            })(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');
            /* eslint-enable */
        },
        loadGtag(trackingId, options) {
            /* eslint-disable */
            options = typeof options !== 'undefined' ? options : {};

            // The first time we load the GTag script.
            // The subsequent times we only configure the given id.

            if ('gtag' in window === false) {
                cb.kit.attachScriptElement(
                    `https://www.googletagmanager.com/gtag/js?id=${trackingId}`,
                );
                window.dataLayer = window.dataLayer || [];
                window.gtag = function () {
                    dataLayer.push(arguments);
                };
                window.gtag('js', new Date());
                window.gtag('config', trackingId, options);
            } else {
                window.gtag('config', trackingId, options);
            }
            /* eslint-enable */
        },
        loadFacebook() {
            /* eslint-disable */
            !(function (f, b, e, v, n, t, s) {
                if (f.fbq) return;
                n = f.fbq = function () {
                    n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
                };
                if (!f._fbq) f._fbq = n;
                n.push = n;
                n.loaded = !0;
                n.version = '2.0';
                n.queue = [];
                t = b.createElement(e);
                t.async = !0;
                t.src = v;
                s = b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t, s);
            })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
            /* eslint-enable */
        },
        loadTwitter() {
            /* eslint-disable */
            !(function (e, t, n, s, u, a) {
                e.twq ||
                    ((s = e.twq =
                        function () {
                            s.exe ? s.exe.apply(s, arguments) : s.queue.push(arguments);
                        }),
                    (s.version = '1.1'),
                    (s.queue = []),
                    (u = t.createElement(n)),
                    (u.async = !0),
                    (u.src = '//static.ads-twitter.com/uwt.js'),
                    (a = t.getElementsByTagName(n)[0]),
                    a.parentNode.insertBefore(u, a));
            })(window, document, 'script');
            /* eslint-enable */
        },
        fireHotjar(hjid) {
            (function (h, o, t, j, a, r) {
                h.hj =
                    h.hj ||
                    function () {
                        (h.hj.q = h.hj.q || []).push(arguments);
                    };
                h._hjSettings = { hjid, hjsv: 5 };
                a = o.getElementsByTagName('head')[0];
                r = o.createElement('script');
                r.async = 1;
                r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
                a.appendChild(r);
            })(window, document, '//static.hotjar.com/c/hotjar-', '.js?sv=');
        },
        fireAdWords(data) {
            cb.kit.attachScriptElement(
                'https://www.googleadservices.com/pagead/conversion_async.js',
                {},
                function () {
                    window.google_trackConversion(data);
                },
            );
        },
        // Polyfill of Object.assign()
        // See:https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/assign
        /* eslint-disable */
        objectAssign(target, varArgs) {
            // .length of function is 2

            if (target == null) {
                // TypeError if undefined or null
                throw new TypeError('Cannot convert undefined or null to object');
            }

            const to = Object(target);

            for (let index = 1; index < arguments.length; index++) {
                const nextSource = arguments[index];

                if (nextSource != null) {
                    // Skip over if undefined or null
                    for (const nextKey in nextSource) {
                        // Avoid bugs when hasOwnProperty is shadowed
                        if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                            to[nextKey] = nextSource[nextKey];
                        }
                    }
                }
            }
            return to;
        },
        /* eslint-enable */

        // Adds a new tracker to test against the privacy buckets (assential, analytics, advertising)
        addTracker(bucket, requiresConsentInGDPRCountries, callback) {
            window.cbq.push({
                bucket,
                requiresConsentInGDPRCountries,
                callback,
                callbackFired: false,
            });
        },
    };

    window.a8cAnalytics.initCookieBanner();
}
document.addEventListener('DOMContentLoaded', a8cAnalyticsSetup);
