import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const PodcastLink = styled(Link)`
    text-decoration: none;
    color: var(--tint-color);
    &:hover {
        text-decoration: underline;
    }
`;
