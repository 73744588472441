import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { DynamicPie } from '../../../../../components/DynamicPie';
import UploadInformationHeading from './UploadInformationHeading';
import GoldCloudIcon from './gold-cloud.svg';
import {
    DismissText,
    HeaderContentWrapper,
    InformationWrapper,
    ShowHideFilesText,
    StatusIconWrapper,
    StatusWrapper,
    UploadManagerHeaderWrapper,
    UploadingIconWrapper,
} from './styled';
import UploadErrorsIcon from './upload-errors.svg';
import UploadingIcon from './uploading.gif';

export class UploadManagerHeader extends Component {
    render() {
        const {
            collapsed,
            numberOfUploads,
            errorCount,
            completeCount,
            totalProgress,
            allFinished,
        } = this.props;

        return (
            <UploadManagerHeaderWrapper collapsed={collapsed}>
                <HeaderContentWrapper>
                    <UploadingIconWrapper>
                        {allFinished && errorCount > 0 ? (
                            <img width={56} height={56} src={UploadErrorsIcon} alt="" />
                        ) : (
                            <img width={56} height={56} src={UploadingIcon} alt="" />
                        )}
                    </UploadingIconWrapper>
                    <InformationWrapper>
                        <UploadInformationHeading
                            numberOfUploads={numberOfUploads - completeCount}
                            allFinished={allFinished}
                            errorCount={errorCount}
                        />
                        <ShowHideFilesText
                            onClick={collapsed ? this.props.onExpand : this.props.onCollapse}
                        >
                            <FormattedMessage
                                id={collapsed ? 'uploaded-files-show' : 'uploaded-files-hide'}
                            />
                        </ShowHideFilesText>
                    </InformationWrapper>
                    <StatusWrapper>
                        {!allFinished ? (
                            <StatusIconWrapper>
                                <DynamicPie size={22} percent={totalProgress} />
                            </StatusIconWrapper>
                        ) : errorCount > 0 ? (
                            <button onClick={this.props.clearFilesFromUploadManager}>
                                <DismissText>
                                    <FormattedMessage id="dismiss" />
                                </DismissText>
                            </button>
                        ) : (
                            <StatusIconWrapper>
                                <img src={GoldCloudIcon} alt="" />
                            </StatusIconWrapper>
                        )}
                    </StatusWrapper>
                </HeaderContentWrapper>
            </UploadManagerHeaderWrapper>
        );
    }
}

export default UploadManagerHeader;
