import styled from 'styled-components';
import { blueTextLinkMixin, gridSize, popoverMixin, Typography } from 'styles';
import { WrapperLink as ResultItemWrapper } from 'components/SearchResultItem/SearchResultItem.styled';

export const SEARCH_RESULT_ITEM_HEIGHT = 60;

export const Wrapper = styled.section`
    ${popoverMixin}

    width: 450px;
    overflow: hidden;

    ${ResultItemWrapper} {
        position: relative;
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: ${2 * gridSize}px;
            right: ${2 * gridSize}px;
            border-bottom: 1px solid ${props => props.theme.tokens['primary-ui-05']};
        }
        &:last-child:after {
            display: none;
        }
    }
`;

export const Header = styled.header`
    ${Typography.Desktop['H70-B']}
    padding: ${2 * gridSize}px ${2 * gridSize}px ${gridSize}px;
    display: flex;
    justify-content: space-between;
    h1 {
        ${Typography.Desktop['H70-B']}
    }
    button, a {
        ${blueTextLinkMixin}
        svg {
            display: inline;
            vertical-align: baseline;
            margin: 0 0 -2px 2px;
        }
    }
`;

export const NoResults = styled.section`
    margin: ${7 * gridSize}px ${8 * gridSize}px;
    text-align: center;

    svg {
        color: ${props => props.theme.tokens['primary-icon-01']};
        margin: 0 auto;
    }
    h1 {
        ${Typography.Desktop['H60-B']}
        margin: ${1.5 * gridSize}px 0 ${0.5 * gridSize}px 0;
    }
    p {
        ${Typography.Desktop.H70}
        line-height: 120%;
        color: ${props => props.theme.tokens['primary-text-02']};
        margin: 0;
    }
`;

export const ScrollingHistory = styled.section<{ maxResults: number }>`
    max-height: ${props => SEARCH_RESULT_ITEM_HEIGHT * props.maxResults}px;
    overflow-y: auto;
`;
