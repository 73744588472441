import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as fromUploadedFilesActions from '../../../../../../redux/actions/uploaded-files.actions';
import UploadImageButton from './view';

UploadImageButton.propTypes = {
    onMouseEnter: PropTypes.func.isRequired,
    onMouseLeave: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    uuid: state.uploadedFiles.fileToEdit.uuid,
});

const mapDispatchToProps = dispatch => ({
    requestImageUploadPreflight: (fileUuid, imageFile) =>
        dispatch(fromUploadedFilesActions.Actions.requestImageUploadPreflight(fileUuid, imageFile)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadImageButton);
