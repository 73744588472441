import styled from 'styled-components';

export const PlayPauseButton = styled.button`
    display: block;
`;

export const ScalableSvg = styled.svg<{ $isHovering: boolean; disabled?: boolean }>`
    display: block;
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    transform: ${props => (props.$isHovering && !props.disabled ? 'scale(1.2)' : 'scale(1)')};
    transition: transform ease-in-out 100ms;
`;
