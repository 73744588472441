import { EpisodeWithImage } from 'model/types';
import { action } from 'redux/api';

export const electronMonitorMessages = () => action('ELECTRON_MONITOR_MESSAGES');

export const electronSetLocale = (locale: string) => action('ELECTRON_SET_LOCALE', { locale });

export const electronMiniPlayerLoaded = () => action('ELECTRON_MINI_PLAYER_LOADED');

export const electronMiniPlayerOpened = (isOpened: boolean) =>
    action('ELECTRON_MINI_PLAYER_OPENED', { isOpened });

export const electronSyncingState = () => action('ELECTRON_SYNCING_STATE');

export const electronSyncingStateDone = () => action('ELECTRON_SYNCING_STATE_DONE');

export const electronMiniPlayerSeekTo = (playedUpTo: number) =>
    action('ELECTRON_MINI_PLAYER_SEEK_TO', { playedUpTo });

export const electronMiniPlayerLoadEpisode = (episode: EpisodeWithImage) =>
    action('ELECTRON_MINI_PLAYER_LOAD_EPISODE', { episode });

export const electronMiniPlayerPlay = () => action('ELECTRON_MINI_PLAYER_PLAY');
export const electronMiniPlayerPause = () => action('ELECTRON_MINI_PLAYER_PAUSE');
export const electronMiniPlayerSkipBack = () => action('ELECTRON_MINI_PLAYER_SKIP_BACK');
export const electronMiniPlayerSkipForward = () => action('ELECTRON_MINI_PLAYER_SKIP_FORWARD');
