import styled from 'styled-components';
import { Typography } from '../../../styles/typography';
import { PAGE_MAX_WIDTH } from '../../../model/page';
import { TextWithReadMore } from '../../../components/TextWithReadMore';

const Subtitle = styled.div`
    ${Typography.Desktop['H70-TBC']};

    display: flex;
    align-items: center;
    padding-bottom: 3px;
`;

export const AccentSubtitle = styled(Subtitle)`
    ${props => props.color != null && `color: ${props.color}`};
`;

export const TokenSubtitle = styled(Subtitle)<{ token: string }>`
    ${props => props.token != null && `color: ${props.token}`};
`;

export const ListLogoImage = styled.div`
    width: 176px;
    margin-right: 32px;
    flex-shrink: 0;
`;

export const PodcastDetails = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const TitleAndActions = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0 0 6px 0;
`;

export const ListTitle = styled.h1`
    display: flex;
    align-items: center;
    ${Typography.Desktop.H10};
    margin-right: 40px;
    word-break: break-word;
    color: ${props => props.theme.tokens['primary-text-01']};
`;

export const PodcastUrl = styled.div`
    display: inline-flex;
    height: 20px;
    background-position: 0 center;
    padding-right: 22px;

    svg {
        margin-right: 0.25em;
        color: ${props => props.theme.tokens['primary-icon-02']};
    }

    a {
        ${Typography.Desktop['H70-B']};
        text-decoration: none;
        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }
`;

export const StyledHeaderDescription = styled.div`
    display: flex;
    flex-direction: row;
    padding: 32px 0 39px 0;
    min-height: 200px;
    width: 100%;
    flex-shrink: 0;
`;

export const ReadMoreText = styled(TextWithReadMore)`
    padding-top: 10px;
    margin-right: 206px;
    @media only screen and (max-width: ${PAGE_MAX_WIDTH}px) {
        margin-right: 106px;
    }
`;

export const CategoryCircleWrapper = styled.div``;

export const CategoryCircle = styled.div<{ token: string | null }>`
    width: 176px;
    height: 176px;
    ${props => props.token != null && `background: ${props.token}`};
    -moz-border-radius: 88px;
    -webkit-border-radius: 88px;
    border-radius: 88px;

    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        color: #fff;
        width: 100px;
        height: 100px;
    }
`;
