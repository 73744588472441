import useFormatMessage from 'hooks/useFormatMessage';
import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import * as fromTracksActions from '../../../../redux/actions/tracks.actions';
import { H80 } from '../../../../styles';
import { UploadFilesButton, UploadFilesInput, UploadFilesLinkWrapper } from './styled';

const FileUploadButton = ({ requestFileUploadPreflight }) => {
    const formatMessage = useFormatMessage();
    const dispatch = useDispatch();
    const fileUploadInputRef = useRef(null);

    const fileUploadInputChanged = event => {
        const { files } = event.target;

        for (const file of files) {
            requestFileUploadPreflight(file);
        }
    };

    const handleFileUploadButtonClick = () => {
        dispatch(fromTracksActions.Actions.recordEvent('uploaded_files_add_file_tapped'));
        fileUploadInputRef.current?.click();
    };

    return (
        <UploadFilesLinkWrapper>
            <UploadFilesButton onClick={handleFileUploadButtonClick}>
                <H80>{formatMessage('upload-cta')}</H80>
            </UploadFilesButton>
            <UploadFilesInput
                type="file"
                aria-hidden="true"
                ref={fileUploadInputRef}
                onChange={fileUploadInputChanged}
                multiple
            />
        </UploadFilesLinkWrapper>
    );
};

export default FileUploadButton;
