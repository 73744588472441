import React, { useCallback, useEffect, useState } from 'react';
import { AutoFocusInside, FocusOn } from 'react-focus-on';
import key, { KeyHandler } from 'keymaster';
import { ESCAPE_KEY_PRESSED_EVENT } from 'model/page';
import { isAppleDevice } from 'helper/Browser';
import { ModalTypes } from 'helper/UiHelper';
import { useDispatch, useSelector } from 'hooks/react-redux-typed';
import useFormatMessage from 'hooks/useFormatMessage';
import { addBookmark } from 'redux/actions/episode-sync.actions';
import {
    getBookmarksForEpisode,
    getDefaultBookmarkTitleForEpisode,
} from 'redux/reducers/selectors/episode-sync.selectors';
import * as fromModalActions from 'redux/actions/modal.actions';
import { BookmarkCountIcon } from 'components/BookmarkCountIcon';
import { BookmarkList } from 'components/BookmarkList';
import { Button } from 'components/Button';
import { AddBookmarkForm } from 'components/form/bookmarks/AddBookmarkForm';
import { ModalMonitor } from 'components/ModalMonitor';
import {
    IconButton,
    Popover,
    RecentBookmarks,
    RecentBookmarksHeading,
    Wrapper,
} from './AddBookmarkButton.styled';

export type Props = {
    time: number;
    episodeUuid: string;
    podcastUuid: string;
};

const AddBookmarkButton = ({ time, episodeUuid, podcastUuid }: Props) => {
    const dispatch = useDispatch();
    const formatMessage = useFormatMessage();
    const bookmarks = useSelector(state => getBookmarksForEpisode(state, episodeUuid));
    const defaultBookmarkTitle = useSelector(state =>
        getDefaultBookmarkTitleForEpisode(state, episodeUuid, formatMessage('bookmark')),
    );
    const [addAtTimestamp, setAddAtTimestamp] = useState<number>();

    const closePopover = useCallback(() => setAddAtTimestamp(undefined), []);

    const handleAddBookmarkShortcut = useCallback<KeyHandler>(
        evt => {
            evt.preventDefault(); // CMD+B is used by some browsers to open the browser bookmarks
            dispatch(
                addBookmark(
                    { episodeUuid, podcastUuid, time, title: defaultBookmarkTitle },
                    { eventSource: 'keyboard_shortcut', showConfirmation: true },
                ),
            );
        },
        [episodeUuid, podcastUuid, time, defaultBookmarkTitle, dispatch],
    );

    useEffect(() => {
        const shortcut = isAppleDevice() ? '⌘+b' : 'ctrl+b';
        window.addEventListener(ESCAPE_KEY_PRESSED_EVENT, closePopover);
        key(shortcut, handleAddBookmarkShortcut);
        return () => {
            window.removeEventListener(ESCAPE_KEY_PRESSED_EVENT, closePopover);
            key.unbind(shortcut);
        };
    }, [closePopover, handleAddBookmarkShortcut]);

    const handleClick = () => {
        if (addAtTimestamp === undefined) {
            setAddAtTimestamp(time);
        } else {
            closePopover();
        }
    };

    const handleManageBookmarksClick = () => {
        openBookmarksModal();
        closePopover();
    };

    const openBookmarksModal = () => {
        dispatch(
            fromModalActions.Actions.showModal(ModalTypes.listBookmarks, {
                podcastUuid,
                episodeUuid,
            }),
        );
    };

    return (
        <Wrapper>
            <ModalMonitor onCloseModals={closePopover} />
            <IconButton onClick={handleClick} aria-label={formatMessage('add-bookmark')}>
                <BookmarkCountIcon count={bookmarks.length} />
            </IconButton>
            {addAtTimestamp !== undefined && (
                <FocusOn onClickOutside={closePopover}>
                    <Popover>
                        {bookmarks.length > 0 && (
                            <RecentBookmarks>
                                <RecentBookmarksHeading>
                                    <h1>{formatMessage('recent-bookmarks')}</h1>
                                    <Button kind="text" onClick={handleManageBookmarksClick}>
                                        {formatMessage('manage-all-bookmarks')}
                                    </Button>
                                </RecentBookmarksHeading>
                                <BookmarkList
                                    podcastUuid={podcastUuid}
                                    episodeUuid={episodeUuid}
                                    showActions={false}
                                    limit={3}
                                    eventSource="add_bookmark_popup"
                                />
                            </RecentBookmarks>
                        )}
                        <AutoFocusInside>
                            <AddBookmarkForm
                                time={addAtTimestamp}
                                episodeUuid={episodeUuid}
                                podcastUuid={podcastUuid}
                                onSubmit={closePopover}
                            />
                        </AutoFocusInside>
                    </Popover>
                </FocusOn>
            )}
        </Wrapper>
    );
};

export default AddBookmarkButton;
