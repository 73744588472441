import classNames from 'classnames';
import { ModalMonitor } from 'components/ModalMonitor';
import { Logger } from 'helper/Logger';
import key from 'keymaster';
import React from 'react';
import ReactDOM from 'react-dom';
import { ESCAPE_KEY_PRESSED_EVENT } from '../../../../../model/page';
import AudioVideo from '../AudioVideo';
import { VideoControls } from '../VideoControls';
import { GlobalVideoStyles } from './Video.styled';

class Video extends AudioVideo {
    componentDidMount() {
        super.componentDidMount();
        window.addEventListener(ESCAPE_KEY_PRESSED_EVENT, this.shortcutEsc);
        key('f', this.shortcutFullScreen);
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener(ESCAPE_KEY_PRESSED_EVENT, this.shortcutEsc);
        const portal = document.getElementById('video-portal');
        portal.className = '';
        key.unbind('f');
    }

    render() {
        const classes = classNames('video-player', this.getVideoModeCss());
        const portal = document.getElementById('video-portal');
        portal.className = classes;
        return ReactDOM.createPortal(this.renderVideo(), portal);
    }

    renderVideo() {
        const { url, playing, videoMode, onVideoModeChanged } = this.props;

        const isFullscreen = document.fullscreenElement === this.player;
        return (
            <>
                {videoMode === 2 && <ModalMonitor onCloseModals={() => onVideoModeChanged(1)} />}
                <GlobalVideoStyles />
                <button className="background" onClick={this.onCollapseClick.bind(this)}>
                    <div className="video-layer">
                        <video
                            id="video"
                            className="video"
                            src={url || ''}
                            autoPlay={playing}
                            preload="auto"
                            controls={isFullscreen}
                            ref={ref => {
                                this.player = ref;
                            }}
                        />
                    </div>
                    <div className="video-layer">
                        <VideoControls
                            videoWidth={this.props.videoWidth}
                            videoHeight={this.props.videoHeight}
                            onExpandClick={this.onExpandClick.bind(this)}
                            onFullScreenClick={this.onFullScreenClick.bind(this)}
                        />
                    </div>
                </button>
            </>
        );
    }

    shortcutEsc = event => {
        this.onCollapseClick(event);
    };

    shortcutFullScreen = event => {
        const { videoMode } = this.props;

        if (videoMode === 1) {
            this.onExpandClick(event);
        } else if (videoMode === 2 && !this.isFullScreen()) {
            this.onFullScreenClick(event);
        } else if (this.isFullScreen()) {
            document.exitFullscreen();
            this.onCollapseClick(event);
        }
    };

    isFullScreen() {
        return document.fullscreenElement !== null;
    }

    getVideoModeCss() {
        if (this.props.videoMode === 2) {
            return 'large';
        }

        return 'small';
    }

    isVideo() {
        return true;
    }

    onCollapseClick(event) {
        event.stopPropagation();
        this.props.onVideoModeChanged(1);
    }

    onExpandClick(event) {
        event.stopPropagation();
        this.props.onVideoModeChanged(2);
    }

    onVideoClick(event) {
        event.stopPropagation();
        const { videoMode } = this.props;
        if (videoMode === 2) {
            this.props.onVideoModeChanged(1);
        }
    }

    onFullScreenClick(event) {
        event.stopPropagation();
        const video = document.getElementById('video');
        if (video.requestFullscreen) {
            video.requestFullscreen();
        } else if (video.mozRequestFullScreen) {
            video.mozRequestFullScreen();
        } else if (video.webkitRequestFullscreen) {
            video.webkitRequestFullscreen();
        }
    }

    onVideoLoad() {
        const video = document.getElementById('video');
        const { videoHeight, videoWidth } = video;
        Logger.log(`onVideoLoad ${videoWidth} ${videoHeight}`);
        if (!videoWidth || !videoHeight) {
            return;
        }
        this.props.onVideoSizeChanged(videoWidth, videoHeight);
    }
}

export default Video;
