import { addHours } from 'date-fns';
import { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export function useGeolocation() {
    const [fetchedLocation, setFetchedLocation] = useState(false);
    const [countryCode, setCountryCode] = useState<string>(cookies.get('country_code'));

    const setCookie = (countryCode: string) => {
        cookies.set('country_code', countryCode, {
            path: '/',
            expires: addHours(new Date(), 6),
        });
    };

    useEffect(() => {
        if (countryCode) {
            setFetchedLocation(true);
            return;
        }

        fetch('https://public-api.wordpress.com/geo/')
            .then(response => {
                response.json().then(data => {
                    setCountryCode(data.country_short);
                    setCookie(data.country_short);
                    setFetchedLocation(true);
                });
            })
            .catch(() => {
                setCountryCode('unknown');
                setCookie('unknown');
                setFetchedLocation(true);
            });
    }, [countryCode]);

    return {
        countryCode,
        hasFetchedLocation: fetchedLocation,
    };
}
