/**
 * This file is for common types.
 *
 * Eventually it might be worth splitting them out into separate files, but the goal
 * is to make typing as easy as possible.
 *
 */
import { PodcastGridOrder } from 'pages/PodcastsPage/model';
import { Episode, EpisodeChapter, EpisodeSyncInfo, RecommendedEpisode } from './episode.types';
import { ListColors } from './list.types';

export * from './episode.types';
export * from './list.types';
export * from './subscription.types';

 
export type TodoFixmeMigrationType = any;

// The Windows App exposes window.external.notify — but we can't include this in global.d.ts
// because a deprecated window.external defition already exists. So when used, you'll need
// to explicitly cast it as: (<WindowFromWindowsApp>window).external.notify(...)
export type WindowFromWindowsApp = Window &
    typeof globalThis & {
        external: {
            notify: (payload: string) => void;
        };
    };

// The previous form of auth didn't send refresh tokens, so we need to track
// that type until users have had time to migrate to the new type
export type LegacyAuthTokens = {
    accessToken: string;
};

export type SSOAuthTokens = {
    accessToken: string;
    tokenType: 'Bearer';
    expiresIn: number;
    refreshToken: string;
};

export type DecodedAuthToken = {
    exp: number;
    iat: number;
    iss: string;
    jti: string;
    'pc:provider': 'POCKET_CASTS' | 'GOOGLE' | 'APPLE';
    'pc:tokenType': 'ACCESS' | 'REFRESH';
    'pc:uuid': string;
    scopes: string[];
    sub: string;
};

// The data for current tokens and refreshing tokens. Could be the new style
// with refresh tokens, or the legacy style without.
export type AuthTokens = LegacyAuthTokens | SSOAuthTokens;

// The response from login calls, which includes tokens and user-specific data
export type APILoginResponse = SSOAuthTokens & {
    email: string;
    uuid: string;
    isNew: boolean;
};

export type OAuthProvider = 'apple' | 'google';

export type OAuthLoginConfig = {
    provider: OAuthProvider;
    nonce: string;
    source: string;
    redirect?: string;
};

export type OAuthTokenJWT = {
    nonce: string;
};

export interface RequestPurchaseActionPayload {
    plan: PaddlePlan | PaddleDiscountedPlan;
    trialDays: number;
    transactionId: string;
    email: string;
    productId: PaddleProductId;
    subscriptionId: number;
    offerType: string;
    isRenewing?: boolean;
    inAppPurchase?: boolean;
}

export type PurchaseWebPlayerFailureActionPayload =
    | {
          plan: PaddlePlan | PaddleDiscountedPlan;
          trialDays: number;
          offerType: string;
          error: string;
      }
    | { error: string };

export interface PaddleCheckoutResponse {
    checkout: {
        id: string; // e.g. "265127-chre704c0229910-fe9cacfae7"
        prices: {
            customer: {
                items: {
                    [index: number]: {
                        recurring: {
                            trial_days: number;
                        };
                    };
                };
            };
        };
    };
    user: {
        email: string; // e.g. "shiftyjelly@gmail.com"
        id: string; // e.g. "26044"
    };
    product: {
        id: string; // e.g. 7004
    };
}

export interface PaddleOrderResponse {
    success: boolean | null;
    state: string | null; // e.g. "processed"
    order: {
        order_id: number; // e.g. 158542
        product_id: number; // e.g. 7004
        subscription_id: number; // e.g. 135989
        subscription_order_id: string; // e.g. "158542-775354"
    };
}

export interface PaddleResponse {
    checkoutResponse: PaddleCheckoutResponse;
    orderResponse: PaddleOrderResponse;
}

export interface PaddleProduct {
    currency: string;
    product_id: number;
    product_title: string;
    applied_coupon?: {
        code: string;
        discount: number;
    };
    price: {
        gross: number;
        net: number;
        tax: number;
    };
    subscription: {
        trial_days: number;
        interval: 'month' | 'year';
        frequency: number;
        price: {
            gross: number;
            net: number;
            tax: number;
        };
    };
    // The response includes other properties, but we probably won't use them. See more:
    // https://developer.paddle.com/classic/api-reference/e268a91845971-get-prices
}

// https://app.swaggerhub.com/apis/pocketcasts/pocketcasts-podcast-api/1.0.1#/mobile/get_mobile_podcast_full
export interface Podcast {
    uuid: string;
    url: string;
    title: string;
    audio: boolean;
    author: string;
    show_type: string;
    season_count: number;
    category: string;
    description: string;
    episodes: Episode[];
    episodesSortOrder?: number;
}

export interface PodcastTintColors {
    lightTint: string;
    darkTint: string;
    darkTintUnsafe: string;
    background: string;
}

export type AnyTintColors = Partial<ListColors> & Partial<PodcastTintColors>;

// Discover

export interface FileUploadRecord {
    file: UploadedFile;
    name?: string;
    size: number;
    type?: string;
    url?: string | null;
    progress: number;
    uploadUrl?: string;
    xhr?: XMLHttpRequest;
    error?: string;
    complete?: boolean;
    failed?: boolean;
    artworkImageUrl?: string;
    lastModified?: string;
}

export interface UploadedFile {
    uuid: string;
    title: string;
    size: string;
    contentType: string;
    playedUpTo: number;
    playedUpToModified: string;
    playingStatus: number;
    playingStatusModified: string;
    duration: string;
    published: string;
    imageUrl: string;
    hasCustomImage: boolean;
    modifiedAt: string;
    imageStatus: number;
    artworkImageUrl: string;
    color: number;
    lastModified: string;
    bookmarks: Bookmark[];
}

export interface UploadedFilesAccount {
    totalSize: string;
    usedSize: string;
    totalFiles: string;
}

export interface UpNextEpisode {
    title: string;
    url: string;
    podcast?: string;
    uuid: string;
    published: string;
    podcastUuid?: string;
    imageUrl?: string;
    exists?: boolean;
}

export interface ChangedSettings {
    gridLayout: boolean;
    gridOrder: boolean;
    showPlayed: boolean;
    theme: boolean;
    skipForward: boolean;
    skipBack: boolean;
    webVersion: boolean;
    language: boolean;
    recommendationsOn: boolean;
    streamByDefault: boolean;
    useEmbeddedArtwork: boolean;
    playbackSpeed: boolean;
    silenceRemoval: boolean;
    volumeBoost: boolean;
    badges: boolean;
    freeGiftAcknowledgement: boolean;
    marketingOptIn: boolean;
    autoArchivePlayed: boolean;
    autoArchiveIncludesStarred: boolean;
    region: boolean;
}

export interface WebPlayerSettings {
    gridLayout: number;
    gridOrder: number;
    badges: number;
    showArchived: boolean;
    theme: number;
    preferredColorScheme: number;
    skipForward: number;
    skipBack: number;
    webpSupported: boolean;
    recommendationsOn: boolean;
    showRecommendations: boolean;
    isCastConnected: boolean;
    language: string;
    region: string;
    keyboardShortcutsOpen: boolean;
    marketingOptIn: boolean;
    changed: ChangedSettings;
    showPlayed: number;
    webVersion: number;
    streamByDefault: boolean;
    useEmbeddedArtwork: boolean;
    playbackSpeed: number;
    silenceRemoval: boolean;
    volumeBoost: boolean;
    freeGiftAcknowledgement: boolean;
    autoArchivePlayed: boolean;
    autoArchiveIncludesStarred: boolean;
}

// https://pocketcastsp2.wordpress.com/2024/03/07/changing-the-scope-of-named-settings
export const globalSettingsToMigrate = [
    'autoArchiveIncludesStarred',
    'autoArchivePlayedEpisodes',
    'filesAfterPlayingDeleteCloud',
    'filesAfterPlayingDeleteLocal',
    'filesAutoUpNext',
    'gridLayout',
    'playerShelf',
    'recommendationsOn',
    'rowAction',
    'volumeBoost',
    'useEmbeddedArtwork',
    'badges',
];

export interface UserStats {
    timeListened?: string | null;
    timeSkipping?: string | null;
    timeVariableSpeed?: string | null;
    timeSilenceRemoval?: string | null;
    timeIntroSkipping?: string | null;
    timesStartedAt?: string | null;
}

export interface RecommendedEpisodeResponse {
    totalEpisodes: number;
    episodes: RecommendedEpisode[];
}

export interface PodcastCacheEpisode {
    uuid: string;
    title: string;
    url: string;
    file_type: string;
    file_size: number;
    duration: number | null;
    published: string;
    type: string | null;
}

export interface PodcastCacheJson {
    episode_frequency: string;
    estimated_next_episode_at: string;
    has_seasons: boolean;
    season_count: number;
    episode_count: number;
    has_more_episodes: boolean;
    podcast: Podcast;
}

export interface PodcastCacheParsed extends Omit<Podcast, 'episodes' | 'season_count'> {
    episodeFrequency: string;
    estimatedNextEpisodeAt: string;
    hasSeasons: boolean;
    seasonCount: number;
    episodeCount: number;
    hasMoreEpisodes?: boolean;
    // The shape of episodes from cache is different from what the Podcast type declares, so we override it here
    episodes: PodcastCacheEpisode[];
    showArchived?: boolean;
}

export interface PodcastSyncInfo {
    autoStartFrom: number;
    autoSkipLast: number;
    episodesSortOrder: number;
    episodes: EpisodeSyncInfo[];
}

export interface PodcastListPodcast {
    uuid: string;
    episodesSortOrder: number;
    autoStartFrom: number;
    title: string;
    author: string;
    description: string;
    url: string;
    lastEpisodePublished: string;
    unplayed: boolean;
    lastEpisodeUuid: string;
    lastEpisodePlayingStatus: number;
    lastEpisodeArchived: boolean;
    autoSkipLast: number;
    sortPosition: number;
    dateAdded: string;
    folderUuid: string;
}

/**
 * Our api returns it as a string, Paddle returns it as a number.
 * This isn't a war I want to fight in. I don't care what it is - just please don't break my typescript
 */
export type PaddleProductId = string | number;

export type PaddlePlan = 'patron-yearly' | 'patron-monthly' | 'plus-yearly' | 'plus-monthly';
export type PaddleDiscountedPlan = 'switch-to-pocket-casts'; // Add string union when there are discounted plans available

export interface PodcastSearchResult {
    uuid: string;
    title: string;
    author: string;
}

export interface EpisodeSearchResult {
    uuid: string;
    url: string;
    published: string;
    title: string;
    podcast_uuid: string;
    podcast_title: string;
    duration: number;
}

export interface FolderSearchResult {
    uuid: string;
    name: string;
    color: number;
    podcastUuids: string[];
    sortType: PodcastGridOrder;
}

export type SearchResponse = {
    podcasts?: PodcastSearchResult[];
    episodes?: EpisodeSearchResult[];
};

export interface BookmarksListResult {
    bookmarkUuid: string;
    createdAt: string;
    episodeUuid: string;
    podcastUuid: string;
    time: number;
    title: string;
}

export type BookmarksListResponse = {
    bookmarks?: BookmarksListResult[];
};

export enum BookmarksSortOrder {
    NEWEST_TO_OLDEST = 0,
    OLDEST_TO_NEWEST = 1,
    PODCAST_AND_EPISODE = 2,
}

export type SearchHistoryItem = { date?: number; subscribed?: boolean } & (
    | { podcast: PodcastSearchResult }
    | { episode: EpisodeSearchResult }
    | { folder: FolderSearchResult }
    | { term: string }
);

export type EpisodeAndPodcastUuidsArray = {
    uuid: string;
    podcast: string;
}[];

export interface PlayerState {
    episode: TodoFixmeMigrationType;
    buffering: boolean;
    isPlaying: boolean;
    lastSeekTo: null | number;
    speed: number;
    lastSentPlayedUpTo: number;
    lastSentPlayingStatus: null | number;
    errorMessage: string | JSX.Element;
    volume: number;
    muted: boolean;
    videoMode: number;
    recommendedEpisodes: RecommendedEpisode[];
    loadingRecommendedEpisodes: boolean;
    videoWidth: number;
    videoHeight: number;
    chapters: EpisodeChapter[];
    chaptersOpen: boolean;
    deselectedChapters: number[];
    unplayableFile?: boolean;
}

export type UpNextState = {
    order: string[];
    episodes: Record<string, UpNextEpisode>;
    serverModified: number | undefined;
    loadFailed: boolean;
    isLoading: boolean;
    isLoaded: boolean;
    open: boolean;
};

export enum CheckoutModalType {
    TYPE_SETUP = 'setup',
    TYPE_WELCOME_BACK = 'welcome-back',
}

export type ExternalFeedAppType = { name: string; icon: string; url: string };

export type ExternalFeed = {
    url: string;
    apps: ExternalFeedAppType[];
};

export type Folder = {
    uuid: string;
    name: string;
    color: number;
    dateAdded: string;
    // podcasts: string[];
    sortPosition: number;
    sortType: PodcastGridOrder;
};

export type UnsavedFolder = Omit<Folder, 'uuid' | 'dateAdded'>;

export enum FormStatus {
    READY = 'READY',
    SUBMITTING = 'SUBMITTING',
    ERROR = 'ERROR',
}

export type PodcastListPositions = {
    podcasts: Record<string, number>;
    folders: Record<string, number>;
};

export type TracksProperties = Record<string, string | number | boolean>;

// Many Tracks events require a `source` property to determine what area of the application
// triggered the event. These are the valid sources:
export type TracksEventSource =
    | 'add_bookmark_popup'
    | 'bookmarks_modal'
    | 'desktop_media_controls' // The media controls in the desktop app
    | 'choose_folder' // FolderSelectForm
    | 'discover' // Anything in the Discover section
    | 'episode_detail' // Episode Popups
    | 'files'
    | 'filters' // Filter pages like In Progress and New Releases
    | 'keyboard_shortcut' // Keyboard shortcuts in the main Player widget
    | 'listening_history'
    | 'media_session' // Browser's Media Session API
    | 'podcast_screen' // Full Podcast pages
    | 'podcasts_list' // The main Podcasts page
    | 'recommendations' // Onboarding flow that recommends podcasts
    | 'recommended_episodes' // Episode recommendations that appear when nothing is playing
    | 'search' // The full search page
    | 'search_list' // The popup search UI
    | 'starred'
    | 'up_next'
    | 'chapters'
    | 'bookmarks_page'
    | 'player_more_actions';

export type Bookmark = {
    bookmarkUuid: string;
    createdAt: string;
    episodeUuid: string;
    podcastUuid: string;
    time: number;
    title: string;
};

// AutoplayConfig describes where the next autoplayed episode should come from. In most
// cases we just need a named source ('files', 'starred', etc). But some sources also require
// an extra key (e.g. to know what 'podcast' we're pulling from, we need the UUID).
export type AutoplayConfig =
    | { source: 'files' | 'starred' | 'in_progress' | 'new_releases' }
    | { source: 'podcast'; uuid: string };

export type FilterId = 'history' | 'in_progress' | 'new_releases' | 'starred';

export type PodcastRating = {
    average: number; // The average rating from 0.0–5.0
    total: number; // The number of times the podcast was rated
};
