import styled from 'styled-components';
import { blueTextLinkMixin, gridSize, H40, H50, H70 } from '../../../../styles/index';

export const ImageWrapper = styled.div`
    display: flex;
    width: ${14 * gridSize}px;
    height: ${14 * gridSize}px;

    align-items: center;
`;

export const HeaderWrapper = styled(H40)`
    text-align: center;
    width: 440px;
`;

export const SubtitleWrapper = styled(H70)`
    text-align: center;
    width: 368px;
`;

export const Spaces = styled.div<{ n: number }>`
    height: ${props => gridSize * (props.n || 1)}px;
`;

export const Separator = styled.div`
    width: 100%;
    border-bottom: 1px solid ${props => props.theme.legacyScss['theme-line']};
`;

export const DetailsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 436px;
`;

export const DetailWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const DetailTextWrapper = styled(H70)`
    width: 426px;
    padding-left: ${gridSize * 1.5}px;
`;

export const HopeIsNotLostWrapper = styled(H50)`
    text-align: center;
    width: 368px;
`;

export const HelpLink = styled.a`
    ${blueTextLinkMixin};
`;

export const HelpWrapper = styled(H70)`
    text-align: center;
    width: 420px;
`;

export const WhenYouCancelSubheading = styled(H50)`
    text-align: center;
    width: 440px;
`;
