import { ModalTypes } from 'helper/UiHelper';
import { ActionTypes, Actions } from '../actions/modal.actions';

interface ModalState {
    show: boolean;
    type: ModalTypes | undefined;
    data?: Record<string, unknown>;
}

export const INITIAL_STATE: ModalState = {
    show: false,
    type: undefined,
    data: {},
};

export default (state = INITIAL_STATE, action: Actions): ModalState => {
    switch (action.type) {
        case ActionTypes.SHOW_MODAL: {
            return {
                ...state,
                show: true,
                type: action.payload.type,
                data: action.payload.data,
            };
        }

        case ActionTypes.HIDE_MODAL: {
            return {
                ...state,
                show: false,
                type: undefined,
                data: {},
            };
        }

        default:
            return state;
    }
};
