import { BackButton } from 'components/BackButton';
import { Button } from 'components/Button';
import { CredentialNotification } from 'components/form/CredentialNotification';
import { StyledForm, StyledFormError } from 'components/form/form.styled';
import Input from 'components/Input';
import { TrackOnMount, TrackOnUnmount } from 'components/Tracks';
import useFormatMessage from 'hooks/useFormatMessage';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { emailFieldSchema, useForm } from '../../hooks/useForm';

type Props = {
    clearUserError: () => void;
    isLoading: boolean;
    isSent: boolean;
    error?: string;
    forgottenPassword: (email: string) => void;
};

function PasswordForgottenPage({
    clearUserError,
    isLoading,
    isSent,
    error,
    forgottenPassword,
}: Props) {
    const formatMessage = useFormatMessage();
    const {
        form,
        form: { fields, isValid, onChange, onFocusChange },
    } = useForm({
        emailField: emailFieldSchema(formatMessage),
    });

    // Clear errors on unmount
    useEffect(
        () => () => {
            clearUserError?.();
        },
        [],
    );

    const onSendClick = (event: React.MouseEvent) => {
        event.preventDefault();
        const { emailField } = form.getValues();
        forgottenPassword(emailField);
    };

    return (
        <StyledForm>
            <TrackOnMount event="forgot_password_shown" />
            <TrackOnUnmount event="forgot_password_dismissed" />
            <Helmet>
                <title>{formatMessage('forgot-your-password')}</title>
            </Helmet>

            <BackButton buttonText={formatMessage('back')} />

            <h1>{formatMessage('forgot-password')}</h1>

            <p>
                {formatMessage('forgotten-password-subtitle-1')}{' '}
                {formatMessage('forgotten-password-subtitle-2')}
            </p>

            {isSent && (
                <CredentialNotification variant="success">
                    {formatMessage('forgotten-password-confirmation')}
                </CredentialNotification>
            )}

            {!isSent && error && <CredentialNotification>{error}</CredentialNotification>}

            <label htmlFor="PasswordForgottenPage__email">{fields.emailField.placeholder}</label>
            <Input
                name="emailField"
                aria-label="Email"
                type={fields.emailField.type}
                value={fields.emailField.value}
                status={fields.emailField.state}
                onChange={onChange}
                onFocus={e => onFocusChange(e, true)}
                onBlur={e => onFocusChange(e, false)}
            />
            {!fields.emailField.focus && fields.emailField.error && (
                <StyledFormError>{fields.emailField.error}</StyledFormError>
            )}

            <Button
                type="submit"
                kind="primary"
                onClick={onSendClick}
                loading={isLoading}
                disabled={isValid === false}
            >
                {formatMessage('send')}
            </Button>
        </StyledForm>
    );
}

export default PasswordForgottenPage;
