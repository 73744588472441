import styled from 'styled-components';
import { coolGrey40, coolGrey60, coolGrey70, gridSize, H70 } from '../../../../styles/index';

export const ImageAndOverlayWrapper = styled.div`
    position: relative;
    width: ${25 * gridSize}px;
    height: ${25 * gridSize}px;

    background-color: ${props => props.theme.legacyScss['theme-button-disabled']};
    border-radius: 4px;
`;

export const ImageWrapper = styled.div`
    display: flex;
    width: ${25 * gridSize}px;
    height: ${25 * gridSize}px;

    align-items: center;
    border-radius: 4px;
    overflow: hidden;
`;

export const ImageOverlay = styled.div`
    display: flex;
    width: ${25 * gridSize}px;
    height: ${25 * gridSize}px;

    justify-content: flex-end;

    position: absolute;
    top: 0;
    left: 0;
`;

export const ReplaceImageButtonWrapper = styled.div`
    display: flex;
    width: ${5 * gridSize}px;
    height: ${5 * gridSize}px;

    align-items: center;

    margin-top: ${gridSize}px;
    margin-right: ${gridSize}px;

    position: relative;
`;

export const Separator = styled.div`
    width: 496px;
    border-bottom: 1px solid ${props => (props.theme.name === 'dark' ? coolGrey70 : coolGrey40)};
    margin-bottom: ${3 * gridSize}px;
`;

export const TitleEditFieldWrapper = styled.div`
    padding-bottom: ${2 * gridSize}px;
`;

export const FileInfoWrapper = styled.div`
    display: flex;
    flex-direction: row-reverse;
    width: 496px;
    padding-bottom: ${2 * gridSize}px;
`;

export const FileInfoField = styled(H70)`
    color: ${coolGrey60};
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    width: 496px;

    flex-direction: row-reverse;
    justify-content: flex-start;
    padding-bottom: ${4 * gridSize}px;
`;

export const ButtonSpacer = styled.div`
    width: ${2 * gridSize}px;
`;
