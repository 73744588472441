import styled from 'styled-components';
import { gridSize } from 'styles';

export const UploadManagerWrapper = styled.div`
    background-color: ${props => props.theme.legacyScss['theme-background']};
    border: 1px solid ${props => props.theme.legacyScss['theme-line']};
    border-radius: 8px;
    box-shadow:
        0px 6px 9px rgba(48, 59, 64, 0.07),
        0px 3px 6px rgba(26, 4, 0, 0.12),
        0px 1px 2px rgba(0, 0, 0, 0.05);
    margin-top: ${gridSize}px;
`;
