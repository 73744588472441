import { Bookmark, TracksEventSource } from 'model/types';
import { action } from 'redux/api';

export const addBookmark = (
    bookmark: Pick<Bookmark, 'episodeUuid' | 'podcastUuid' | 'time' | 'title'>,
    options: { eventSource: TracksEventSource; showConfirmation?: boolean },
) =>
    action('BOOKMARK_ADD', { bookmark: { ...bookmark, time: Math.floor(bookmark.time) }, options });

export const addBookmarkSucceeded = (bookmark: Bookmark) =>
    action('BOOKMARK_ADD_SUCCEEDED', { bookmark });

export const addBookmarkFailed = () => action('BOOKMARK_ADD_FAILED');

export const deleteBookmark = (bookmark: Bookmark) => action('BOOKMARK_DELETE', { bookmark });

export const deleteBookmarkSucceeded = (bookmark: Bookmark) =>
    action('BOOKMARK_DELETE_SUCCEEDED', { bookmark });

export const deleteBookmarkFailed = () => action('BOOKMARK_DELETE_FAILED');

export const editBookmark = (bookmark: Bookmark) => action('BOOKMARK_EDIT', bookmark);

export const editBookmarkSucceeded = (bookmark: Bookmark) =>
    action('BOOKMARK_EDIT_SUCCEEDED', { bookmark });

export const editBookmarkFailed = () => action('BOOKMARK_EDIT_FAILED');
