import styled from 'styled-components';

export const Wrapper = styled.span`
    display: inline-flex;
    align-items: center;
    font-weight: 600;
    font-size: inherit;
    color: ${props => props.theme.legacyScss['theme-background']};
    background: ${props => props.theme.legacyScss['theme-text-primary']};
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.24);
    border-radius: 100px;
    padding: 0 0.66em;
    line-height: 1.75;
    svg {
        flex-shrink: 0;
        margin-right: 0.25em;
        color: var(--plan-color);
        width: 0.9em;
        height: 0.9em;
    }
`;
