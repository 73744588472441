import { Button } from 'components/Button';
import styled from 'styled-components';
import { gridSize, preventFlexboxOverflow, Typography, visuallyHidden } from 'styles';

const HORIZONTAL_PADDING = 3 * gridSize;

export const Fieldset = styled.fieldset`
    ${preventFlexboxOverflow}
    display: flex;
    flex-direction: column;
    height: 100%;
    legend {
        ${visuallyHidden}
    }

    /*
        The ideal layout with nested scrollable flex containers doesn't seem possible in Legacy Edge (e.g. the
        Windows App). This is a hack that just targets Legacy Edge to add a scrollbar to the entire modal contents,
        instead of just to the podcast list. It's not a perfect experience, but it's the best solution I can find
        without even hackier absolute positioning. —@mattwondra
    */
    @supports (-ms-ime-align: auto) {
        overflow-y: scroll;
    }
`;

export const FilterOptions = styled.div`
    display: flex;
    margin: 0 ${HORIZONTAL_PADDING}px ${2 * gridSize}px;
    position: relative;
`;

export const PodcastsWrapper = styled.div`
    overflow-y: scroll;
    border: 1px solid ${props => props.theme.tokens['primary-ui-05']};
    border-width: 1px 0;
    position: relative;

    /*
        The ideal layout with nested scrollable flex containers doesn't seem possible in Legacy Edge (e.g. the
        Windows App). This is a hack that just targets Legacy Edge to add a scrollbar to the entire modal contents,
        instead of just to the podcast list. It's not a perfect experience, but it's the best solution I can find
        without even hackier absolute positioning. —@mattwondra
    */
    @supports (-ms-ime-align: auto) {
        overflow-y: hidden;
    }
`;

export const PodcastText = styled.div`
    flex: 1;
    margin: 0 ${2 * gridSize}px;
    ${preventFlexboxOverflow}
`;

export const Image = styled.div`
    width: 60px;
`;

export const PodcastFolder = styled.div<{ color: string }>`
    color: ${props => props.color};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    svg {
        display: inline-block;
        position: relative;
        top: 2px;
        margin-right: 3px;
    }
`;
export const PodcastTitle = styled.div`
    ${Typography.Desktop.H65}
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;
export const PodcastAuthor = styled.div`
    color: ${props => props.theme.tokens['primary-text-02']};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;
export const EmptyMessage = styled.div`
    color: ${props => props.theme.tokens['primary-text-02']};
    padding: ${2 * gridSize}px;
    margin: ${HORIZONTAL_PADDING}px;
    text-align: center;
`;

export const SortButton = styled(Button)`
    height: 100%;
    color: ${props => props.theme.tokens['primary-icon-02']};

    // Make the clickable area larger, while keeping the icon visually right-aligned
    padding: 0 10px;
    margin-right: -10px;

    &:hover {
        color: ${props => props.theme.tokens['primary-icon-02-active']};
    }
`;

export const SortOptions = styled.fieldset`
    position: absolute;
    z-index: 1;
    top: 100%;
    margin-top: -8px;
    right: -8px;
    background: ${props => props.theme.tokens['primary-ui-06']};
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
    border-radius: ${gridSize}px;
    overflow: hidden;

    legend,
    input {
        ${visuallyHidden}
    }
`;

export const SortOption = styled.div`
    ${Typography.Desktop.H70}
    display: block;
    padding: ${gridSize}px ${6 * gridSize}px ${gridSize}px ${2 * gridSize}px;
    cursor: pointer;
    color: ${props => props.theme.tokens['primary-text-02']};
    position: relative;

    label:first-of-type & {
        padding-top: ${2 * gridSize}px;
    }

    label:last-of-type & {
        padding-bottom: ${2 * gridSize}px;
    }

    svg {
        vertical-align: bottom;
        margin-left: 15px;
        opacity: 0;
        position: absolute;
        right: ${gridSize}px;
        transform: translateY(-100%);
    }

    &:hover,
    input:focus + & {
        svg {
            opacity: 50%;
        }
    }
    input:checked + & {
        color: ${props => props.theme.tokens['primary-text-01']};
        svg {
            opacity: 100%;
        }
    }
`;

export const LabelContent = styled.label`
    ${Typography.Desktop.H70}
    display: flex;
    align-items: center;
    padding: ${gridSize}px ${HORIZONTAL_PADDING / 2}px ${gridSize}px ${HORIZONTAL_PADDING}px;
    cursor: pointer;

    &:hover,
    input:focus + & {
        background: ${props => props.theme.tokens['primary-ui-01-active']};
    }
`;
