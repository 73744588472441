import { PlanBadge } from 'components/PlanBadge';
import { TermsAndConditions } from 'components/TermsAndConditions';
import { getCorrectFreeTrialMessage } from 'helper/GiftDaysHelper';
import { PaddleDiscountedPlan, PaddlePlan, SubscriptionTier } from 'model/types';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { USE_PATRON, USE_SLUMBER_STUDIOS_PARTNERSHIP } from 'settings';
import { TranslatableStringKey } from 'translations/glotpress';
import {
    FeatureList,
    FlexLineBreak,
    FreeTrialBadge,
    FullPriceStrikethrough,
    PlanSelectorItemsRow,
    PlanSelectorTermsWrapper,
    Price,
    TextButton,
    Wrapper,
} from './PlanSelector.styled';
import { PLAN_VARIABLES } from './plans';

export type Props = {
    plan: PaddlePlan;
    price: ReactNode;
    trialDays: number;
    variant?: 'mini' | 'regular';
    showDisclaimers?: boolean;
    onChange: (newPlan: PaddlePlan) => void;
};

const SmallIcon = ({ icon }: { icon: string }) => (
    <svg width={16} height={16} viewBox="0 0 16 16">
        <use xlinkHref={`#${icon}`} />
    </svg>
);

export const PlanPrice = ({
    price,
    fullPrice,
    interval,
    fullInterval,
    variant,
}: {
    price: ReactNode;
    fullPrice?: ReactNode;
    interval: TranslatableStringKey;
    fullInterval?: TranslatableStringKey;
    variant?: 'mini' | 'regular';
}) => (
    <Price variant={variant ?? 'regular'} data-testid="plan-selector-price">
        <strong>{price}</strong>
        <span>
            /<FormattedMessage id={interval} />
        </span>
        {fullPrice && (
            <FullPriceStrikethrough>
                {fullPrice}/<FormattedMessage id={fullInterval ?? interval} />
            </FullPriceStrikethrough>
        )}
    </Price>
);

export const TrialBadge = ({ trialDays }: { trialDays: number }) =>
    trialDays > 0 ? <FreeTrialBadge>{getCorrectFreeTrialMessage(trialDays)}</FreeTrialBadge> : null;

export const PlanFeatures = ({
    tier,
    plan,
}: {
    tier: SubscriptionTier;
    plan: PaddlePlan | PaddleDiscountedPlan;
}) => {
    const slumberStudiosLink = 'https://slumberstudios.com';
    const frequency = ['plus-yearly', 'patron-yearly'].includes(plan) ? 'yearly' : 'monthly';

    if (tier === 'Patron') {
        return (
            <FeatureList>
                <li>
                    <SmallIcon icon="tick-sm" />
                    <FormattedMessage id="everything-in-plus" />
                </li>
                <li>
                    <SmallIcon icon="sparkle-sm" />
                    <FormattedMessage id="early-access-to-features" />
                </li>
                <li>
                    <SmallIcon icon="cloud-drive-sm" />
                    <FormattedMessage id="patron-cloud-storage" />
                </li>
                <li>
                    <SmallIcon icon="user-sm" />
                    <FormattedMessage id="supporters-profile-badge" />
                </li>
                <li>
                    <SmallIcon icon="mobile-device-sm" />
                    <FormattedMessage id="special-pocket-casts-app-icons" />
                </li>
                {USE_SLUMBER_STUDIOS_PARTNERSHIP && frequency === 'yearly' ? (
                    <li>
                        <SmallIcon icon="slumber-sm" />
                        <p>
                            <FormattedMessage
                                id="slumber-studios-benefit"
                                values={{
                                    slumberStudiosLink: (text: ReactNode) => (
                                        <a
                                            href={slumberStudiosLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {text}
                                        </a>
                                    ),
                                }}
                            />
                        </p>
                    </li>
                ) : (
                    <li>
                        <SmallIcon icon="heart-outline-sm" />
                        <FormattedMessage id="our-undying-gratitude" />
                    </li>
                )}
            </FeatureList>
        );
    }
    return (
        <FeatureList>
            <li>
                <SmallIcon icon="desktop-sm" />
                <FormattedMessage id="desktop-apps" />
            </li>
            <li>
                <SmallIcon icon="folder-sm" />
                <FormattedMessage id="folders" />
            </li>
            <li>
                <SmallIcon icon="bookmark-sm" />
                <FormattedMessage id="bookmarks" />
            </li>
            <li>
                <SmallIcon icon="cloud-drive-sm" />
                <FormattedMessage id="plus-cloud-storage" />
            </li>
            <li>
                <SmallIcon icon="watch-sm" />
                <FormattedMessage id="watch-apps" />
            </li>
            <li>
                <SmallIcon icon="palette-sm" />
                <FormattedMessage id="extra-themes-and-icons" />
            </li>
            {USE_SLUMBER_STUDIOS_PARTNERSHIP && frequency === 'yearly' ? (
                <li>
                    <SmallIcon icon="slumber-sm" />
                    <p>
                        <FormattedMessage
                            id="slumber-studios-benefit"
                            values={{
                                slumberStudiosLink: (text: ReactNode) => (
                                    <a
                                        href={slumberStudiosLink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {text}
                                    </a>
                                ),
                            }}
                        />
                    </p>
                </li>
            ) : (
                <li>
                    <SmallIcon icon="heart-outline-sm" />
                    <FormattedMessage id="our-undying-gratitude" />
                </li>
            )}
        </FeatureList>
    );
};

const PlanSelector = ({
    plan,
    onChange,
    price,
    trialDays,
    showDisclaimers,
    variant = 'regular',
}: Props) => {
    const {
        otherIntervalPlan,
        otherTierPlan,
        perIntervalText,
        switchIntervalText,
        tier,
    } = PLAN_VARIABLES[plan];
    const isPatron = ['patron-monthly', 'patron-yearly'].includes(plan);

    return (
        <Wrapper variant={variant} isPatron={isPatron}>
            <PlanSelectorItemsRow>
                <div>
                    <PlanBadge tier={tier} />
                </div>
                <div>
                    <TrialBadge trialDays={trialDays} />
                </div>
                <div>
                    {USE_PATRON && otherTierPlan && (
                        <TextButton kind="text" onClick={() => onChange(otherTierPlan)}>
                            <FormattedMessage id="change-plan" />
                        </TextButton>
                    )}
                </div>
            </PlanSelectorItemsRow>

            <FlexLineBreak />

            <PlanSelectorItemsRow>
                <PlanPrice price={price} interval={perIntervalText} variant={variant} />

                {otherIntervalPlan && switchIntervalText && (
                    <TextButton
                        kind="text"
                        onClick={() => onChange(otherIntervalPlan)}
                        data-testid="plan-selector-switch-plan-button"
                    >
                        <FormattedMessage id={switchIntervalText} />
                    </TextButton>
                )}
            </PlanSelectorItemsRow>
            <FlexLineBreak />

            {variant !== 'mini' && <PlanFeatures tier={tier} plan={plan} />}

            {showDisclaimers && (
                <PlanSelectorTermsWrapper>
                    <TermsAndConditions />
                </PlanSelectorTermsWrapper>
            )}
        </Wrapper>
    );
};

export default PlanSelector;
