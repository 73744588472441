import React from 'react';
import { Svg } from './BookmarkCountIcon.styled';

export type Props = {
    count: number;
};

const BookmarkCountIcon = ({ count }: Props) => (
    <Svg width={44} height={32} viewBox="0 0 44 32">
        {/* Mask to cut away the bookmark icon when the counter is present */}
        <mask id="bookmark-count-icon-mask-cutaway">
            <rect x="0" y="0" width="100%" height="100%" fill="white" />
            <circle cx="28" cy="10" r="12" fill="black" />
        </mask>

        {/* Bookmark icon - mask is applied to a wrapping <g> so that it's applied _after_ the bookmark's transform */}
        <g mask={count > 0 ? 'url("#bookmark-count-icon-mask-cutaway")' : ''}>
            <path
                d="M5 6C5 4.89543 5.89543 4 7 4H17C18.1046 4 19 4.89543 19 6V18.3636C19 20.2042 16.7238 21.0676 15.5031 19.69L12 15.7366L8.49689 19.69C7.27623 21.0676 5 20.2042 5 18.3636V6ZM17 6H7V18.3636L10.5031 14.4102C11.299 13.5121 12.701 13.5121 13.4969 14.4102L17 18.3636V6Z"
                fillOpacity="0.5"
                transform={'translate(4, 4)'}
            />
        </g>

        {/* Mask to show the circle or pill-shaped counter, with the count cut out */}
        <mask id={`bookmark-count-icon-mask-${count}`}>
            {count < 10 ? (
                <circle cx="28" cy="10" r="10" fill="white" />
            ) : (
                <rect x="18" y="0" width="26" height="20" rx="10" fill="white" />
            )}
            <text textAnchor="middle" x={count < 10 ? 28 : 31} y="14" fill="black">
                {count < 100 ? count : '99+'}
            </text>
        </mask>

        {/* Full-size rectangle that gets the counter mask applied, so it looks the circle or pill count */}
        {count > 0 && (
            <rect
                x="0"
                y="0"
                width="100%"
                height="100%"
                rx="10"
                mask={`url(#bookmark-count-icon-mask-${count})`}
            />
        )}
    </Svg>
);

export default BookmarkCountIcon;
