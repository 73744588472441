import Link from 'components/StyledLink';
import styled from 'styled-components';
import { Typography } from '../../styles/typography';

export const SettingsSideNavigation = styled.div`
    width: 200px;
    min-height: 400px;
    flex: 0 0 200px;
`;

export const SettingsPageWrapper = styled.div``;

export const SettingsPageContentWrapper = styled.div`
    display: flex;
    padding-top: 40px;
    color: ${props => props.theme.tokens['primary-text-01']};
    h1 {
        ${Typography.Desktop['H50-B']}
        margin-top: 0;
        margin-bottom: 20px;
    }
`;

export const SettingsRouteContainer = styled.div`
    ${Typography.Desktop.H70};
    padding-left: 20px;
    flex: 1 1 auto;
`;

// These styles can be reused by sub components

export const SettingsOption = styled.div`
    display: flex;
    align-items: center;
    min-height: 48px;
    padding: 8px;
    border-bottom: 1px solid ${props => props.theme.tokens['primary-ui-05']};
`;

export const SwitchWrapper = styled.div`
    width: 50px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
`;

export const SettingsTitle = styled.div`
    width: 165px;
    flex-shrink: 0;
`;

export const SettingsDescription = styled.span`
    color: ${props => props.theme.tokens['primary-text-02']};
    line-height: 16px;
    max-width: 450px;
`;

export const SettingsLink = styled(Link)`
    ${Typography.Desktop.H70};
    transition: all 0.1s ease;
    line-height: 48px;
    vertical-align: middle;
    margin-right: 8px;
`;
