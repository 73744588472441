// Creates a pre-typed Redux hooks, so components don't need to import their own typing
// See: https://redux.js.org/usage/usage-with-typescript#define-typed-hooks
import {
    TypedUseSelectorHook,
    useSelector as reduxUseSelector,
    useDispatch as reduxUseDispatch,
} from 'react-redux';
import { RootState } from 'redux/reducers';

export const useDispatch = reduxUseDispatch; // TODO: Add typing
export const useSelector: TypedUseSelectorHook<RootState> = reduxUseSelector;
