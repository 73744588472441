import React, { Component } from 'react';
import { isEdge, isFirefox } from '../../../../../../../helper/Browser';
import {
    UploadErrorTooltipWrapper,
    TooltipTriangle,
    TooltipBodyWrapper,
    FileName,
    ErrorMessage,
} from './styled';

export class UploadErrorTooltip extends Component {
    componentDidMount() {
        this.mountTime = new Date().getTime();
    }

    calculateTooltipParams = () => {
        const { fileName } = this.props;
        const fileNameCharacterCount = fileName.length;

        const initialWidth = 200;
        const initialOffset = -130;

        if (fileNameCharacterCount <= 22) {
            return {
                tooltipWidth: initialWidth,
                triangleOffset: initialOffset,
            };
        }

        const extraChars = fileNameCharacterCount - 22;

        const tooltipWidth = initialWidth + extraChars * 4;
        const triangleOffset = 70 - tooltipWidth;

        return {
            tooltipWidth,
            triangleOffset,
        };
    };

    mouseEnteredTooltipBody = () => {
        const currentTime = new Date().getTime();

        // It's possible for the first mouse enter to be on a spot where the tooltip would be.
        // In this case we don't want to hide it immediately, so this effectively "waits" until
        // the second mouse enter is detected.
        if (currentTime - this.mountTime > 200) {
            this.props.dismissNowCallback();
        }
    };

    render() {
        const { fileName, message } = this.props;
        const { tooltipWidth, triangleOffset } = this.calculateTooltipParams();

        return (
            <UploadErrorTooltipWrapper isFirefox={isFirefox()} isEdge={isEdge()}>
                <TooltipBodyWrapper
                    onMouseEnter={this.mouseEnteredTooltipBody}
                    onMouseLeave={this.mouseLeftTooltipBody}
                    width={tooltipWidth}
                >
                    <FileName maxWidth={tooltipWidth - 16}>{fileName}</FileName>
                    <ErrorMessage>{message}</ErrorMessage>
                </TooltipBodyWrapper>
                <TooltipTriangle triangleOffset={triangleOffset} />
            </UploadErrorTooltipWrapper>
        );
    }
}

export default UploadErrorTooltip;
