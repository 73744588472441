import React, { Component } from 'react';
import UploadingFileItem from './UploadingFileItem';
import { UploadManagerDetailWrapper, DetailShadow, DetailShadowWrapper } from './styled';

export class UploadManagerDetail extends Component {
    render() {
        const { collapsed, fileUuids, files } = this.props;

        const incompleteFiles = fileUuids.filter(uuid => !files[uuid].complete);

        return (
            <UploadManagerDetailWrapper collapsed={collapsed}>
                <DetailShadowWrapper>
                    <DetailShadow />
                </DetailShadowWrapper>
                {incompleteFiles.map((uuid, index) => (
                    <UploadingFileItem
                        key={uuid}
                        file={files[uuid]}
                        uuid={uuid}
                        hideBorder={index === fileUuids.length - 1 && index > 3}
                    />
                ))}
            </UploadManagerDetailWrapper>
        );
    }
}

export default UploadManagerDetail;
