import styled from 'styled-components';
import { PLAYER_HEIGHT } from 'model/page';

export const DiscoverPlayerWrapper = styled.div``;
export const Recommendations = styled.div`
    text-align: center;
    height: ${PLAYER_HEIGHT}px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
`;
export const RecommendTitle = styled.div`
    margin-right: 55px;
    font-size: 16px;
    color: #ffffff;
    letter-spacing: -0.21px;
    @media screen and (max-width: 1200px) {
        font-size: 14px;
        margin-right: 12px;
    }
`;
export const RecommendEpisode = styled.div`
    margin-right: 12px;
    height: 77px;
    width: 280px;
    @media screen and (max-width: 1200px) {
        width: 230px;
    }
`;
export const LoaderSquareStyled = styled.div`
    margin: 0;
`;
