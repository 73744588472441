/* eslint-disable */
// ^ FIXME

import { isUrl } from 'helper/StringHelper';
import {
    Episode,
    EpisodeAndPodcastUuidsArray,
    EpisodeSearchResult,
    EpisodeShowNotes,
    FilterEpisode,
    FilterId,
    Podcast,
    PodcastCacheParsed,
    PodcastListPodcast,
    PodcastListPositions,
    PodcastSearchResult,
    PodcastSyncInfo,
    PodcastTintColors,
} from 'model/types';
import { Channel, SagaIterator, buffers } from 'redux-saga';
import {
    ActionChannelEffect,
    SagaReturnType,
    actionChannel,
    all,
    call,
    debounce,
    delay,
    fork,
    put,
    select,
    take,
    takeEvery,
    takeLatest,
    throttle,
} from 'redux-saga/effects';
import { ActionWithPayload } from 'redux/actions/action-creators';
import { getEpisodeShowNotes } from 'redux/reducers/selectors/episode-show-notes.selector';
import { getEpisodeSyncByUuid } from 'redux/reducers/selectors/episode-sync.selectors';
import { getPodcastListFolders } from 'redux/reducers/selectors/podcasts.selectors';
import { userIsLoggedIn } from 'redux/reducers/selectors/user.selectors';
import { NavigationItems } from '../../helper/NavigationHelper';
import { PlayingStatus } from '../../helper/PlayingStatus';
import * as PodcastHelper from '../../helper/PodcastHelper';
import * as StatsHelper from '../../helper/StatsHelper';
import { UPLOADED_FILES_PODCAST_UUID } from '../../model/uploaded-files';
import { api } from '../../services/api';
import cacheApi from '../../services/cacheApi';
import staticApi from '../../services/staticApi';
import * as fromEpisodeActions from '../actions/episode.actions';
import * as fromFilterActions from '../actions/filter.actions';
import * as fromPlayerActions from '../actions/player.actions';
import * as fromPodcastActions from '../actions/podcast.actions';
import * as fromPodcastsActions from '../actions/podcasts.actions';
import { fetchPodcastShowNotes } from '../actions/podcasts.actions';
import * as fromSearchActions from '../actions/search.actions';
import * as fromShareActions from '../actions/share.actions';
import * as fromStatsActions from '../actions/stats.actions';
import * as fromTracksActions from '../actions/tracks.actions';
import * as fromUpNextActions from '../actions/up-next.actions';
import * as fromUploadedFilesActions from '../actions/uploaded-files.actions';
import {
    getAutoArchivePlayed,
    getColorsByUuid,
    getOpenPodcastData,
    getPodcastByUuid,
    getSettings,
    getStats,
    getUpNext,
    getUuidToColors,
    isEpisodeInUpNext,
    isSubscribedPodcastsLoaded,
} from '../reducers/selectors';
import { logSagaError } from './saga-helper';

function* downloadPodcastsColor(podcastUuids: string[]) {
    try {
        const uuidToColors: ReturnType<typeof getUuidToColors> = yield select(getUuidToColors);
        const uuidsMissingColor = podcastUuids.filter(uuid => !uuidToColors[uuid]);
        if (uuidsMissingColor.length === 0) {
            return;
        }
        const colors: PodcastTintColors[] = yield all(
            uuidsMissingColor.map(uuid => call(staticApi.getPodcastColors, uuid)),
        );
        const newUuidToColors = {} as Record<string, PodcastTintColors>;
        for (let i = 0; i < uuidsMissingColor.length; i += 1) {
            newUuidToColors[uuidsMissingColor[i]] = colors[i];
        }
        yield put(fromPodcastsActions.Actions.updatePodcastsColors(newUuidToColors));
    } catch (error) {
        logSagaError('Failed in Download Podcast Color', error);
    }
}

function* downloadPodcastColor(
    action: ReturnType<typeof fromPodcastActions.Actions.downloadPodcastColor>,
) {
    const { uuid } = action.payload;
    try {
        const existingColors: ReturnType<typeof getColorsByUuid> = yield select(
            getColorsByUuid,
            uuid,
        );
        if (existingColors) {
            return;
        }
        const colors: SagaReturnType<typeof staticApi.getPodcastColors> = yield call(
            staticApi.getPodcastColors,
            uuid,
        );
        yield put(fromPodcastsActions.Actions.updatePodcastColors(uuid, colors));
    } catch (error) {
        logSagaError('Failed downloading podcast color', error);
    }
}

export function* downloadPodcasts(): SagaIterator {
    try {
        const { podcasts, folders }: SagaReturnType<typeof api.fetchPodcasts> = yield call(
            api.fetchPodcasts,
        );
        const cleanedPodcasts = podcasts.map(PodcastHelper.cleanFields);

        yield put(
            fromPodcastsActions.Actions.downloadSubscribedPodcastsSuccess(cleanedPodcasts, folders),
        );
        yield fork(
            downloadPodcastsColor,
            cleanedPodcasts.map(podcast => podcast.uuid),
        );
    } catch (error) {
        logSagaError('Download podcasts failed', error);
        yield put(fromPodcastsActions.Actions.downloadSubscribedPodcastsFailed());
    }
}

export function* downloadPodcast(
    action: ReturnType<typeof fromPodcastsActions.Actions.downloadPodcast>,
) {
    const { podcastUuid, includeEpisodes } = action.payload;
    let podcast: PodcastListPodcast & Podcast = yield select(getPodcastByUuid, podcastUuid);
    if (!includeEpisodes && podcast && !podcast.episodes) {
        return;
    }

    if (includeEpisodes) {
        yield put(fetchPodcastShowNotes(podcastUuid));
    }
    yield put(fromPodcastActions.Actions.downloadPodcastColor(podcastUuid));
    try {
        const isLoggedIn: ReturnType<typeof userIsLoggedIn> = yield select(userIsLoggedIn);

        const [podcastCache, podcastSync]: [
            PodcastCacheParsed,
            PodcastSyncInfo | undefined,
        ] = yield all([
            call(cacheApi.getPodcast, podcastUuid),
            isLoggedIn ? call(api.fetchUserPodcastEpisodes, podcastUuid) : undefined,
        ]);

        if (podcastCache) {
            yield put(fromPodcastsActions.Actions.addPodcast(podcastCache, podcastSync));
        }
    } catch (error) {
        logSagaError('Download podcast failed', error);
        yield put(fromPodcastActions.Actions.openPodcastFailed());
    }
}

function* searchRemotePodcasts(term: string) {
    const { podcasts }: { podcasts: PodcastSearchResult[] } = yield call(
        api.fetchSearchResults,
        term,
    );
    yield put(fromSearchActions.Actions.fetchPodcastsSearchResultsSuccess(term, podcasts));
    return podcasts;
}

function* searchRemoteEpisodes(term: string) {
    const episodes: EpisodeSearchResult[] = yield call(cacheApi.fetchEpisodeSearchResults, term);
    yield put(fromSearchActions.Actions.fetchEpisodesSearchResultsSuccess(term, episodes));
    return episodes;
}

function* searchRemote(action: ReturnType<typeof fromSearchActions.Actions.fetchSearchResults>) {
    try {
        const { term } = action.payload;
        if (term.length === 0) {
            return;
        }

        yield put(fromTracksActions.Actions.recordEvent('search_performed'));

        // Fetch Podcasts and Episodes in parallel, since Episode search is slower. Each function
        // pushes results to Redux as they come in so users can see some results as quick as possible.
        const [podcasts, episodes]: [PodcastSearchResult[], EpisodeSearchResult[]] = yield all([
            searchRemotePodcasts(term),
            isUrl(term) ? [] : searchRemoteEpisodes(term),
        ]);

        // Once both results are in, confirm that search is done and confirm that the canonical
        // podcasts and episodes are attributed.
        yield put(
            fromSearchActions.Actions.fetchSearchResultsSuccess(term, { podcasts, episodes }),
        );
    } catch (error) {
        logSagaError('Search remote podcasts', error);
        yield put(fromSearchActions.Actions.fetchSearchResultsFailed());
        yield put(fromTracksActions.Actions.recordEvent('search_failed'));
    }
}

function* searchHistoryClear(action: ReturnType<typeof fromSearchActions.Actions.clearHistory>) {
    yield put(fromTracksActions.Actions.recordEvent('search_history_cleared'));
}

function* pollAddPodcastByUrl(url: string) {
    let times = 0;
    let pollUuid: string | null = null;
    do {
        times += 1;
        const json: Record<string, string> = yield call(api.addFeedByUrl, url, pollUuid);
        if (json.status === 'ok') {
            return json;
        } else if (json.status === 'poll') {
            yield delay(2000 * times);
            pollUuid = json.poll_uuid;
        }
    } while (times < 10);
    throw new Error('Unable to add feed url.');
}

function* addPodcastByUrl(action: ReturnType<typeof fromSearchActions.Actions.addPodcastByUrl>) {
    const { url, history } = action.payload;
    try {
        yield put(fromSearchActions.Actions.addPodcastByUrlLoading());

        const response: { result: { podcast: { uuid: string } } } = yield call(
            pollAddPodcastByUrl,
            url,
        );
        const uuid = response.result.podcast.uuid;

        yield put(fromSearchActions.Actions.addPodcastByUrlSuccess(uuid));

        history.push(`${NavigationItems.PODCASTS.path}/${uuid}`);
    } catch (error) {
        yield put(
            fromSearchActions.Actions.addPodcastByUrlFailed('Unable to find a podcast at this URL'),
        );
        console.error('Add by feed url error', error);
    }
}

function* searchPodcastEpisodes(
    action: ReturnType<typeof fromPodcastActions.Actions.searchEpisodes>,
) {
    try {
        yield delay(600);

        const { term, podcastUuid } = action.payload;
        if (term.length < 2) {
            yield put(fromPodcastActions.Actions.clearSearchEpisodes());
            return;
        }

        const episodes: Episode[] = yield call(cacheApi.searchPodcastEpisodes, term, podcastUuid);
        yield put(fromPodcastActions.Actions.searchEpisodesSuccess(episodes || [], term));
    } catch (error) {
        logSagaError('Search podcast episodes failed', error);
        yield put(fromPodcastActions.Actions.searchEpisodesFailed());
    }
}

function* openPodcast(action: ReturnType<typeof fromPodcastActions.Actions.openPodcast>): unknown {
    const podcastUuid = action.payload.uuid;

    try {
        yield put(fromPodcastsActions.Actions.downloadPodcast(podcastUuid, true));
    } catch (error) {
        logSagaError('Open podcast failed', error);
        yield put(fromPodcastActions.Actions.openPodcastFailed());
    }
}

function* openEpisode(action: ReturnType<typeof fromEpisodeActions.Actions.openEpisode>) {
    try {
        const {
            episode: { uuid, podcastUuid },
        } = action.payload;

        if (podcastUuid !== UPLOADED_FILES_PODCAST_UUID) {
            const podcast: Podcast = yield select(getPodcastByUuid, podcastUuid);
            const episodeShowNotes: EpisodeShowNotes = yield select(state =>
                getEpisodeShowNotes(state, uuid),
            );
            if (!podcast || !episodeShowNotes) {
                yield put(fromPodcastsActions.Actions.downloadPodcast(podcastUuid, true));
            }
            yield call(downloadPodcastColor, {
                type: fromPodcastActions.ActionTypes.DOWNLOAD_PODCAST_COLOR,
                payload: {
                    uuid: podcastUuid,
                },
            } as ActionWithPayload<fromPodcastActions.ActionTypes.DOWNLOAD_PODCAST_COLOR, { uuid: string }>);

            const episodeSync: FilterEpisode = yield call(api.fetchUserEpisode, uuid);
            yield put(fromEpisodeActions.Actions.receiveEpisodeSync(episodeSync));
        }
    } catch (error) {
        logSagaError('Failed opening episode', error);
    }
}

function* saveProgress(episodeUuid: string, podcastUuid: string, playedUpTo: number) {
    if (playedUpTo < 0) {
        return;
    }
    const stats: ReturnType<typeof getStats> = yield select(getStats);

    // sync stats in seconds, keep the remaining milliseconds for the next sync
    const skippingStat = StatsHelper.calcSecsWithRemainingMs(stats.timeSavedSkippingMs);
    const skippingIntro = StatsHelper.calcSecsWithRemainingMs(stats.timeSavedSkippingIntroMs);
    const variableSpeed = StatsHelper.calcSecsWithRemainingMs(stats.timeSavedVariableSpeedMs);
    const totalListening = StatsHelper.calcSecsWithRemainingMs(stats.totalListeningTimeMs);

    yield put(
        fromStatsActions.Actions.updateStats({
            totalListeningMs: totalListening.remainingMs,
            skippingStatMs: skippingStat.remainingMs,
            skippingIntroMs: skippingIntro.remainingMs,
            variableSpeedMs: variableSpeed.remainingMs,
        }),
    );

    try {
        if (podcastUuid === UPLOADED_FILES_PODCAST_UUID) {
            yield put(
                fromUploadedFilesActions.Actions.requestUpdateFile(episodeUuid, {
                    playedUpTo: Math.floor(playedUpTo),
                    playingStatus: PlayingStatus.IN_PROGRESS,
                }),
            );
        } else {
            yield call(api.saveProgress, episodeUuid, podcastUuid, playedUpTo);
        }

        yield call(
            api.addStats,
            0,
            skippingStat.timeSecs,
            skippingIntro.timeSecs,
            variableSpeed.timeSecs,
            totalListening.timeSecs,
        );

        yield put(fromPlayerActions.Actions.saveProgressSuccess(episodeUuid, playedUpTo));
    } catch (error) {
        logSagaError(
            `Save progress failed episodeUuid: ${episodeUuid} podcastUuid: ${podcastUuid} playedUpTo: ${playedUpTo}`,
            error,
        );
    }
}

function* savePlayingStatus(episodeUuid: string, podcastUuid: string, playingStatus: number) {
    try {
        yield call(api.savePlayingStatus, episodeUuid, podcastUuid, playingStatus);
    } catch (error) {
        logSagaError('Save playing status failed', error);
    }
}

function* saveArchive(
    episodeUuidAndPodcastUuids: EpisodeAndPodcastUuidsArray,
    isArchived: boolean,
) {
    try {
        yield call(api.saveArchive, episodeUuidAndPodcastUuids, isArchived);
    } catch (error) {
        logSagaError('Save archive failed', error);
    }
}

function* savePlayingStatusAndProgress(
    episodeUuid: string,
    podcastUuid: string,
    playingStatus: number,
    playedUpTo: number,
) {
    try {
        yield call(
            api.savePlayingStatusAndProgress,
            episodeUuid,
            podcastUuid,
            playingStatus,
            playedUpTo,
        );
    } catch (error) {
        logSagaError('Save playing status failed', error);
    }
}

function* saveStarred(
    episodeUuid: string,
    podcastUuid: string,
    starred: boolean,
    tracksProperties: fromPodcastActions.EpisodeTracksProperties,
) {
    try {
        yield call(api.saveEpisodeStar, episodeUuid, podcastUuid, starred);
        if (tracksProperties.eventSource) {
            yield put(
                fromTracksActions.Actions.recordEvent(
                    starred ? 'episode_starred' : 'episode_unstarred',
                    { episode_uuid: episodeUuid, source: tracksProperties.eventSource },
                ),
            );
        }
    } catch (error) {
        logSagaError('Save starred failed', error);
    }
}

function* subscribeToPodcast(
    action: ReturnType<typeof fromPodcastsActions.Actions.subscribeToPodcast>,
) {
    const { podcast, tracksProperties } = action.payload;
    const { uuid } = podcast;
    try {
        yield call(api.subscribeToPodcast, uuid);
        yield put(fromPodcastsActions.Actions.downloadPodcast(uuid));

        // After subscribing, make sure the podcast is positioned at the end of the home folder
        yield put(fromPodcastsActions.Actions.rearrangePodcastList([['PUSH', uuid, 'home']]));

        yield put(
            fromTracksActions.Actions.recordEvent('podcast_subscribed', {
                uuid,
                source: tracksProperties.eventSource,
            }),
        );
    } catch (error) {
        logSagaError('Subscribe to podcast failed', error);
    }
}

function* unsubscribeFromPodcast(
    action: ReturnType<typeof fromPodcastsActions.Actions.unsubscribeFromPodcast>,
) {
    const { podcastUuid, tracksProperties } = action.payload;
    try {
        yield call(api.unsubscribeFromPodcast, podcastUuid);

        yield put(fromPodcastsActions.Actions.rearrangePodcastList([['REMOVE', podcastUuid]]));

        yield put(
            fromTracksActions.Actions.recordEvent('podcast_unsubscribed', {
                uuid: podcastUuid,
                source: tracksProperties.eventSource,
            }),
        );
    } catch (error) {
        logSagaError('Unsubscribe from podcast failed', error);
    }
}

export function* downloadFilter(
    action: ReturnType<typeof fromFilterActions.Actions.downloadFilter>,
) {
    const { id } = action.payload;

    const apiPaths: Record<FilterId, string> = {
        new_releases: '/user/new_releases',
        in_progress: '/user/in_progress',
        starred: '/user/starred',
        history: '/user/history',
    };

    try {
        let episodes: FilterEpisode[] = yield call(api.fetchFilter, apiPaths[id]);

        // Check subscribed podcasts are loaded for the downloadPodcast actions below
        let loaded: ReturnType<typeof isSubscribedPodcastsLoaded> = yield select(
            isSubscribedPodcastsLoaded,
        );
        let i = 0;
        while (!loaded && i < 10) {
            yield delay(200);
            loaded = yield select(isSubscribedPodcastsLoaded);
            i += 1;
        }

        if (loaded) {
            // limit history to 200 items here until we can pass this to the server
            if (id === 'history') {
                episodes = episodes.slice(0, 200);
            }
        }

        yield put(fromFilterActions.Actions.downloadFilterSuccess(id, episodes));
    } catch (error) {
        logSagaError('Open filter failed', error);
        yield put(fromFilterActions.Actions.downloadFilterFailed());
    }
}

function* updateAutoStartFrom(
    action: ReturnType<typeof fromPodcastActions.Actions.updateAutoStartFrom>,
) {
    try {
        const { podcastUuid, autoStartFrom } = action.payload;
        const podcast: ReturnType<typeof getOpenPodcastData> = yield select(getOpenPodcastData);
        if (podcast.uuid === podcastUuid) {
            yield call(api.updatePodcast, { podcastUuid, autoStartFrom });
        }
    } catch (error) {
        logSagaError('Update Auto Start From failed', error);
    }
}

function* updateAutoSkipLast(
    action: ReturnType<typeof fromPodcastActions.Actions.updateAutoSkipLast>,
) {
    try {
        const { podcastUuid, autoSkipLast } = action.payload;
        const podcast: ReturnType<typeof getOpenPodcastData> = yield select(getOpenPodcastData);
        if (podcast.uuid === podcastUuid) {
            yield call(api.updatePodcast, { podcastUuid, autoSkipLast });
        }
    } catch (error) {
        logSagaError('Update Auto Skip Last failed', error);
    }
}

function* updatePlaybackEffects(
    action: ReturnType<typeof fromPodcastActions.Actions.updatePlaybackEffects>,
) {
    try {
        const { podcastUuid, playbackEffects } = action.payload;
        yield call(api.updatePodcast, { podcastUuid, playbackEffects });
    } catch (error) {
        logSagaError('Update Playback Effects failed', error);
    }
}

function* updatePlaybackSpeed(
    action: ReturnType<typeof fromPodcastActions.Actions.updatePlaybackSpeed>,
) {
    try {
        const { podcastUuid, playbackSpeed } = action.payload;
        yield call(api.updatePodcast, { podcastUuid, playbackSpeed });
    } catch (error) {
        logSagaError('Update Playback Speed failed', error);
    }
}

function* updateShowArchived(action: ReturnType<typeof fromPodcastActions.Actions.showArchived>) {
    try {
        const { podcastUuid, showArchived } = action.payload;
        const podcast: ReturnType<typeof getOpenPodcastData> = yield select(getOpenPodcastData);
        if (podcast.uuid === podcastUuid) {
            yield call(api.updatePodcast, { podcastUuid, showArchived });
        }
    } catch (error) {
        logSagaError('Update show archived failed', error);
    }
}

function* updateAutoArchive(
    action: ReturnType<typeof fromPodcastActions.Actions.updateAutoArchive>,
) {
    try {
        const { podcastUuid, autoArchive } = action.payload;
        const podcast: ReturnType<typeof getOpenPodcastData> = yield select(getOpenPodcastData);
        if (podcast.uuid === podcastUuid) {
            yield call(api.updatePodcast, { podcastUuid, autoArchive });
        }
    } catch (error) {
        logSagaError('Update Auto Archive failed', error);
    }
}

function* updateAutoArchivePlayed(
    action: ReturnType<typeof fromPodcastActions.Actions.updateAutoArchivePlayed>,
) {
    try {
        const { podcastUuid } = action.payload;
        const podcast: ReturnType<typeof getOpenPodcastData> = yield select(getOpenPodcastData);
        const autoArchivePlayed = action.payload.autoArchivePlayed ? '1' : '0';

        if (podcast.uuid === podcastUuid) {
            yield call(api.updatePodcast, { podcastUuid, autoArchivePlayed });
        }
    } catch (error) {
        logSagaError('Update Auto Archive Played failed', error);
    }
}

function* updateEpisodeOrder(
    action: ReturnType<typeof fromPodcastActions.Actions.updateEpisodeOrder>,
) {
    try {
        const { podcastUuid, episodesSortOrder } = action.payload;
        yield call(api.updatePodcast, { podcastUuid, episodesSortOrder });
    } catch (error) {
        logSagaError('Update episode order failed', error);
    }
}

function* openShare(
    action:
        | ReturnType<typeof fromShareActions.Actions.openPodcastShare>
        | ReturnType<typeof fromShareActions.Actions.openEpisodeShare>,
) {
    try {
        let podcastUuid;
        let episodeUuid = '';
        let time;
        if (action.type === fromShareActions.ActionTypes.OPEN_EPISODE_SHARE) {
            ({
                podcastUuid,
                episodeUuid,
                options: { time },
            } = action.payload);
        } else {
            ({ podcastUuid } = action.payload);
        }
        const url: string = yield call(api.fetchShareLink, episodeUuid, podcastUuid);
        yield put(fromShareActions.Actions.openShareSuccess(url, time));
    } catch (error) {
        logSagaError('Open share failed', error);
        yield put(fromShareActions.Actions.openShareFailed());
    }
}

/**
 * The payload for this action is an array of specific ways to rearrange the Podcast List:
 *
 * [
 *     [ 'MOVE', 'uuid1', 'home', 8],  // Moves an podcast/folder from where it is, to the position in the given folder
 *     [ 'PUSH', 'uuid2', 'folder3' ], // Pushes a podcast/folder to the end of the given folder
 *     [ 'REMOVE', 'folder2' ],        // Removes a podcast/folder from the positioning list
 * ]
 *
 * The saga gathers the current ordering based on each folder's sortType, applies the given
 * actions to rearrange the items, and then calls PODCAST_LIST_UPDATE_POSITIONS to save the
 * new positions both in local Redux and the Sync API.
 */
function* rearrangePodcastList(
    action: ReturnType<typeof fromPodcastsActions.Actions.rearrangePodcastList>,
) {
    const { actions } = action.payload;

    // First turn the sorted podcast list that powers the Podcasts page into arrays of UUIDs in folders:
    //
    // const positions = {
    //     'home': [ 'uuid1', 'uuid2', ... ],
    //     'folder1': [ 'uuid3', 'uuid4', ... ],
    //     ...
    // };
    //
    // These lists will be mutated below as each action is processed.
    const podcastListFolders: ReturnType<typeof getPodcastListFolders> = yield select(
        getPodcastListFolders,
    );
    const positions: Record<string, string[]> = {};
    Object.keys(podcastListFolders).forEach(folderUuid => {
        positions[folderUuid] = podcastListFolders[folderUuid].map(item => item.uuid);
    });

    const findAndRemove = (uuid: string) =>
        Object.keys(positions).forEach(folderUuid => {
            positions[folderUuid] = positions[folderUuid].filter(itemUuid => itemUuid !== uuid);
        });

    // Process each action and mutate `positions` accordingly.
    actions.forEach(([type, itemUuid, toFolderUuid = '', toPosition = 0]) => {
        switch (type) {
            case 'MOVE':
                findAndRemove(itemUuid);
                positions[toFolderUuid].splice(toPosition, 0, itemUuid);
                break;
            case 'PUSH':
                findAndRemove(itemUuid);
                positions[toFolderUuid].push(itemUuid);
                break;
            case 'REMOVE':
                findAndRemove(itemUuid);
                // If the item was a folder, move all its contents to the end of the home folder
                positions[itemUuid]?.forEach(uuid => {
                    positions['home'].push(uuid);
                });
                delete positions[itemUuid];
                break;
        }
    });

    // Now that everything has been rearranged, create the new positions object to persist it all
    const positionsList: PodcastListPositions = {
        podcasts: {},
        folders: {},
    };
    Object.values(positions).forEach(items =>
        items.forEach((uuid, index) => {
            if (uuid in podcastListFolders) {
                positionsList.folders[uuid] = index;
            } else {
                positionsList.podcasts[uuid] = index;
            }
        }),
    );

    // ...and fire an action to persist those new positions.
    yield put(fromPodcastsActions.Actions.updatePodcastListPositions(positionsList));
}

function* updatePodcastListPositions(
    action: ReturnType<typeof fromPodcastsActions.Actions.updatePodcastListPositions>,
) {
    try {
        yield call(api.updatePodcastListPositions, action.payload.positions);
    } catch (error) {
        logSagaError('Failed in Update Podcast List Positions', error);
    }
}

export function* watchDownloadSubscribedPodcasts() {
    yield throttle(
        5000,
        fromPodcastsActions.ActionTypes.DOWNLOAD_SUBSCRIBED_PODCASTS,
        downloadPodcasts,
    );
}

export function* watchOpenPodcast() {
    yield takeLatest(fromPodcastActions.ActionTypes.OPEN_PODCAST, openPodcast);
}

export function* watchOpenEpisode() {
    yield takeLatest(fromEpisodeActions.ActionTypes.OPEN_EPISODE, openEpisode);
}

export function* watchDownloadPodcast() {
    // If a bunch of DOWNLOAD_PODCAST actions fire off all at once, the app can just fall over.
    // This is because it results in stacked network requests that block JS from continuing execution,
    // so none of the podcasts update until all the network activity has finished.
    //
    // It's actually faster in these situations to make the requests consecutively instead of trying to
    // handle them in parallel. So this Saga uses a channel to accomplish this — it's always listening and
    // `take`ing new actions, but it waits for the previous `yield` to complete before processing the next one.
    const channel: Channel<ActionChannelEffect> = yield actionChannel(
        fromPodcastsActions.ActionTypes.DOWNLOAD_PODCAST,
    );
    while (true) {
        const action: ReturnType<typeof fromPodcastsActions.Actions.downloadPodcast> = yield take(
            channel,
        );
        yield call(downloadPodcast, action);
    }
}

export function* watchSetSearchTerm() {
    // Fetch search results once the search term has stopped changing for 600ms
    yield debounce(600, fromSearchActions.ActionTypes.SET_SEARCH_TERM, searchRemote);
}

export function* watchSearchRemote() {
    yield takeLatest(fromSearchActions.ActionTypes.FETCH_SEARCH_RESULTS, searchRemote);
}

export function* watchSearchHistoryClear() {
    yield takeLatest(fromSearchActions.ActionTypes.SEARCH_HISTORY_CLEAR, searchHistoryClear);
}

export function* watchAddPodcastByUrl() {
    yield takeEvery(fromSearchActions.ActionTypes.ADD_PODCAST_BY_URL, addPodcastByUrl);
}

export function* watchSearchPodcastEpisodes() {
    yield takeLatest(fromPodcastActions.ActionTypes.SEARCH_EPISODES, searchPodcastEpisodes);
}

export function* watchEpisodeProgress() {
    const progressChannel: Channel<ActionChannelEffect> = yield actionChannel(
        fromPlayerActions.ActionTypes.SAVE_PROGRESS,
        buffers.sliding(20),
    );
    while (true) {
        const action: ReturnType<typeof fromPlayerActions.Actions.saveProgress> = yield take(
            progressChannel,
        );
        const { episodeUuid, podcastUuid, playedUpTo } = action.payload;
        yield saveProgress(episodeUuid, podcastUuid, playedUpTo);
    }
}

export function* watchSubscribeToPodcast() {
    yield takeEvery(fromPodcastsActions.ActionTypes.SUBSCRIBE_TO_PODCAST, subscribeToPodcast);
}

export function* watchUnsubscribeToPodcast() {
    yield takeEvery(
        fromPodcastsActions.ActionTypes.UNSUBSCRIBE_FROM_PODCAST,
        unsubscribeFromPodcast,
    );
}

export function* watchOpenFilter() {
    yield takeEvery(fromFilterActions.ActionTypes.DOWNLOAD_FILTER, downloadFilter);
}

export function* watchDownloadPodcastColor() {
    yield takeEvery(fromPodcastActions.ActionTypes.DOWNLOAD_PODCAST_COLOR, downloadPodcastColor);
}

export function* watchUpdateEpisodeOrder() {
    yield takeEvery(fromPodcastActions.ActionTypes.UPDATE_EPISODE_ORDER, updateEpisodeOrder);
}

export function* watchUpdateAutoStartFrom() {
    yield takeLatest(fromPodcastActions.ActionTypes.UPDATE_AUTO_START_FROM, updateAutoStartFrom);
}

export function* watchUpdateAutoSkipLast() {
    yield takeLatest(fromPodcastActions.ActionTypes.UPDATE_AUTO_SKIP_LAST, updateAutoSkipLast);
}

export function* watchUpdatePlaybackEffects() {
    yield takeLatest(fromPodcastActions.ActionTypes.UPDATE_PLAYBACK_EFFECTS, updatePlaybackEffects);
}

export function* watchUpdatePlaybackSpeed() {
    yield takeLatest(fromPodcastActions.ActionTypes.UPDATE_PLAYBACK_SPEED, updatePlaybackSpeed);
}

export function* watchShowArchived() {
    yield takeLatest(fromPodcastActions.ActionTypes.SHOW_ARCHIVED, updateShowArchived);
}

export function* watchUpdateAutoArchive() {
    yield takeLatest(fromPodcastActions.ActionTypes.UPDATE_AUTO_ARCHIVE, updateAutoArchive);
}

export function* watchUpdateAutoArchivePlayed() {
    yield takeLatest(
        fromPodcastActions.ActionTypes.UPDATE_AUTO_ARCHIVE_PLAYED,
        updateAutoArchivePlayed,
    );
}

export function* watchOpenShare() {
    yield takeLatest(
        [
            fromShareActions.ActionTypes.OPEN_EPISODE_SHARE,
            fromShareActions.ActionTypes.OPEN_PODCAST_SHARE,
        ],
        openShare,
    );
}

export function* watchArchive() {
    const changesChannel: Channel<ActionChannelEffect> = yield actionChannel(
        [
            fromPodcastActions.ActionTypes.ARCHIVE,
            fromPodcastActions.ActionTypes.UNARCHIVE,
            fromPodcastActions.ActionTypes.ARCHIVE_ALL,
            fromPodcastActions.ActionTypes.UNARCHIVE_ALL,
        ],
        buffers.sliding(50),
    );
    while (true) {
        const action: ReturnType<
            | typeof fromPodcastActions.Actions.archive
            | typeof fromPodcastActions.Actions.unarchive
            | typeof fromPodcastActions.Actions.unarchiveAll
            | typeof fromPodcastActions.Actions.archiveAll
        > = yield take(changesChannel);
        if (action.type === fromPodcastActions.ActionTypes.ARCHIVE) {
            const payload = action.payload;
            yield saveArchive([{ uuid: payload.episodeUuid, podcast: payload.podcastUuid }], true);

            const isInUpNext: ReturnType<typeof isEpisodeInUpNext> = yield select(
                isEpisodeInUpNext,
                payload.episodeUuid,
            );
            if (isInUpNext) {
                yield put(
                    fromUpNextActions.Actions.removeFromUpNext(payload.episodeUuid, {
                        eventSource: null,
                    }),
                );
            }
            if (payload.tracksProperties.eventSource) {
                yield put(
                    fromTracksActions.Actions.recordEvent('episode_archived', {
                        episode_uuid: payload.episodeUuid,
                        source: payload.tracksProperties.eventSource,
                    }),
                );
            }
        } else if (action.type === fromPodcastActions.ActionTypes.UNARCHIVE) {
            const payload = action.payload;
            yield saveArchive([{ uuid: payload.episodeUuid, podcast: payload.podcastUuid }], false);
            if (payload.tracksProperties.eventSource) {
                yield put(
                    fromTracksActions.Actions.recordEvent('episode_unarchived', {
                        episode_uuid: payload.episodeUuid,
                        source: payload.tracksProperties.eventSource,
                    }),
                );
            }
        } else if (action.type === fromPodcastActions.ActionTypes.ARCHIVE_ALL) {
            const payload = action.payload;
            yield saveArchive(payload.episodeUuidAndPodcastUuids, true);

            const uuidsArchived = payload.episodeUuidAndPodcastUuids.map(
                ({ uuid }: { uuid: string }) => uuid,
            );
            const { order }: ReturnType<typeof getUpNext> = yield select(getUpNext);

            const uuidsToRemoveFromUpNext = PodcastHelper.getPodcastUuidIntersection(
                order,
                uuidsArchived,
            );

            for (const uuid of uuidsToRemoveFromUpNext) {
                yield put(fromUpNextActions.Actions.removeFromUpNext(uuid, { eventSource: null }));
            }
        } else if (action.type === fromPodcastActions.ActionTypes.UNARCHIVE_ALL) {
            const payload = action.payload;
            yield saveArchive(payload.episodeUuidAndPodcastUuids, false);
        }
    }
}

export function* watchMarkAsPlayedChanges() {
    const changesChannel: Channel<ActionChannelEffect> = yield actionChannel(
        [
            fromPodcastActions.ActionTypes.MARK_AS_PLAYED,
            fromPodcastActions.ActionTypes.MARK_AS_UNPLAYED,
            fromPodcastActions.ActionTypes.MARK_AS_IN_PROGRESS,
        ],
        buffers.sliding(50),
    );
    while (true) {
        const action: ReturnType<
            | typeof fromPodcastActions.Actions.markAsPlayed
            | typeof fromPodcastActions.Actions.markAsUnplayed
            | typeof fromPodcastActions.Actions.markAsInProgress
        > = yield take(changesChannel);
        const episode: ReturnType<typeof getEpisodeSyncByUuid> = yield select(
            getEpisodeSyncByUuid,
            action.payload.episodeUuid,
        );

        if (action.type === fromPodcastActions.ActionTypes.MARK_AS_PLAYED) {
            const settings: ReturnType<typeof getSettings> = yield select(getSettings);
            const { episodeUuid, tracksProperties } = action.payload;
            const autoArchivePlayed: ReturnType<typeof getAutoArchivePlayed> = yield select(
                getAutoArchivePlayed,
                action.payload.podcastUuid,
            );

            yield savePlayingStatus(
                action.payload.episodeUuid,
                action.payload.podcastUuid,
                PlayingStatus.COMPLETED,
            );

            const isInUpNext: ReturnType<typeof isEpisodeInUpNext> = yield select(
                isEpisodeInUpNext,
                action.payload.episodeUuid,
            );
            if (isInUpNext) {
                yield put(
                    fromUpNextActions.Actions.removeFromUpNext(action.payload.episodeUuid, {
                        eventSource: null,
                    }),
                );
            }

            if (autoArchivePlayed) {
                if (!episode.starred || settings.autoArchiveIncludesStarred) {
                    yield put(
                        fromPodcastActions.Actions.archive(
                            action.payload.episodeUuid,
                            action.payload.podcastUuid,
                            { eventSource: null },
                        ),
                    );
                }
            }
            if (tracksProperties.eventSource) {
                yield put(
                    fromTracksActions.Actions.recordEvent('episode_marked_as_played', {
                        episode_uuid: episodeUuid,
                        source: tracksProperties.eventSource,
                    }),
                );
            }
        } else if (action.type === fromPodcastActions.ActionTypes.MARK_AS_UNPLAYED) {
            const { episodeUuid, tracksProperties } = action.payload;
            yield savePlayingStatusAndProgress(
                action.payload.episodeUuid,
                action.payload.podcastUuid,
                PlayingStatus.NOT_PLAYED,
                0,
            );
            if (episode.isDeleted) {
                yield put(
                    fromPodcastActions.Actions.unarchive(
                        action.payload.episodeUuid,
                        action.payload.podcastUuid,
                        { eventSource: null },
                    ),
                );
            }
            if (tracksProperties.eventSource) {
                yield put(
                    fromTracksActions.Actions.recordEvent('episode_marked_as_unplayed', {
                        episode_uuid: episodeUuid,
                        source: tracksProperties.eventSource,
                    }),
                );
            }
        } else if (action.type === fromPodcastActions.ActionTypes.MARK_AS_IN_PROGRESS) {
            yield savePlayingStatusAndProgress(
                action.payload.episodeUuid,
                action.payload.podcastUuid,
                PlayingStatus.IN_PROGRESS,
                action.payload.playedUpTo,
            );
        }
    }
}

export function* watchStarredChanges() {
    const starChannel: Channel<ActionChannelEffect> = yield actionChannel(
        fromPodcastActions.ActionTypes.STAR_EPISODE,
        buffers.sliding(50),
    );
    while (true) {
        const action: ReturnType<typeof fromPodcastActions.Actions.starEpisode> = yield take(
            starChannel,
        );
        const { episodeUuid, podcastUuid, starred, tracksProperties } = action.payload;
        yield saveStarred(episodeUuid, podcastUuid, starred, tracksProperties);
    }
}

export function* watchPodcastListRearrange() {
    yield takeEvery(fromPodcastsActions.ActionTypes.PODCAST_LIST_REARRANGE, rearrangePodcastList);
}

export function* watchPodcastListUpdatePositions() {
    yield takeEvery(
        fromPodcastsActions.ActionTypes.PODCAST_LIST_UPDATE_POSITIONS,
        updatePodcastListPositions,
    );
}
