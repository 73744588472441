import React from 'react';
import { EpisodeCardLarge } from 'components/EpisodeCard';
import { EpisodeDiscover } from 'model/types';
import { useDispatch, useSelector } from 'react-redux';
import { getPlayingEpisode } from 'redux/reducers/selectors';
import { RootState } from 'redux/reducers';
import * as fromEpisodeActions from 'redux/actions/episode.actions';
import * as fromPlayerActions from 'redux/actions/player.actions';

type Props = {
    episode: EpisodeDiscover;
    color?: string;
    onEpisodeClick?: (podcastUuid: string, episodeUuid: string) => void;
    onEpisodePlay?: (podcastUuid: string, episodeUuid: string) => void;
    onPodcastClick: (uuid: string) => void;
};

const DiscoverEpisodeLarge = ({
    episode,
    color,
    onEpisodeClick,
    onEpisodePlay,
    onPodcastClick,
}: Props) => {
    const isPlaying = useSelector(
        (state: RootState) => getPlayingEpisode(state)?.uuid === episode.uuid,
    );
    const dispatch = useDispatch();

    const openEpisode = () => {
        onEpisodeClick && onEpisodeClick(episode.podcast_uuid, episode.uuid);
        dispatch(
            fromEpisodeActions.Actions.openEpisode(
                {
                    // Episode data from Discover lists have a couple params different from
                    // what the action expects, so we'll add them in here:
                    ...episode,
                    file_size: episode.size,
                    podcastUuid: episode.podcast_uuid,
                },
                { eventSource: 'discover' },
            ),
        );
    };

    const playOrPause = () => {
        if (isPlaying) {
            dispatch(fromPlayerActions.Actions.pause({ eventSource: 'discover' }));
            return;
        }
        onEpisodePlay && onEpisodePlay(episode.podcast_uuid, episode.uuid);
        dispatch(
            fromPlayerActions.Actions.playEpisode(episode.uuid, episode.podcast_uuid, {
                eventSource: 'discover',
            }),
        );
    };

    return (
        <EpisodeCardLarge
            podcastUuid={episode.podcast_uuid}
            podcastTitle={episode.podcast_title}
            title={episode.title}
            published={episode.published}
            duration={episode.duration}
            type={episode.type}
            color={color}
            isPlaying={isPlaying}
            onClick={openEpisode}
            onPlayPauseClick={playOrPause}
            onPodcastClick={() => onPodcastClick(episode.podcast_uuid)}
        />
    );
};

export default DiscoverEpisodeLarge;
