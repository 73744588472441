import classNames from 'classnames';
import { Icon } from 'components/Icon';
import { TracksEventSource } from 'model/types';
import React, { MouseEventHandler } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as fromPodcastsActions from 'redux/actions/podcasts.actions';
import { getSubscribedPodcastUuids } from 'redux/reducers/selectors';
import { SubscribeButtonWrapper } from './SubscribeButton.styled';

type Props = {
    podcastUuid: string;
    circled?: boolean;
    className?: string;
    eventSource: TracksEventSource;
    onSubscribe?: () => void;
};

const SubscribeButton = ({
    podcastUuid,
    onSubscribe,
    circled = false,
    eventSource,
    className,
}: Props) => {
    const dispatch = useDispatch();
    const subscribedUuids = useSelector(getSubscribedPodcastUuids);
    const isSubscribed = subscribedUuids.includes(podcastUuid);

    const styleClasses = classNames('sub5cribe-button', className, {
        sub5cribed: isSubscribed,
    });

    const handleClick: MouseEventHandler = event => {
        event.stopPropagation();
        event.preventDefault();
        if (isSubscribed) {
            dispatch(
                fromPodcastsActions.Actions.unsubscribeFromPodcast(podcastUuid, { eventSource }),
            );
        } else {
            dispatch(
                fromPodcastsActions.Actions.subscribeToPodcast(
                    { uuid: podcastUuid },
                    { eventSource },
                ),
            );
            onSubscribe?.();
        }
    };

    return (
        <SubscribeButtonWrapper
            data-testid={`subscribe-button-${podcastUuid}`}
            className={styleClasses}
            onClick={handleClick}
            circled={circled}
        >
            <Icon id={isSubscribed ? 'tick' : 'plus'} size={20} />
        </SubscribeButtonWrapper>
    );
};
export default SubscribeButton;
