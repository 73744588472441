import { getImageUrl } from 'helper/PodcastHelper';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import {
    electronMiniPlayerOpened,
    electronMiniPlayerPause,
    electronMiniPlayerPlay,
    electronMiniPlayerSeekTo,
    electronMiniPlayerSkipBack,
    electronMiniPlayerSkipForward,
} from 'redux/actions/electron.actions';
import { RootState } from 'redux/reducers';
import MiniPlayerWindow from './MiniPlayerWindow';

const mapStateToProps = (state: RootState) => {
    const { episode } = state.electron.miniPlayer;
    const podcastUuid = episode ? episode.podcastUuid : null;
    const podcastColors = podcastUuid && state.podcasts.uuidToColors[podcastUuid];
    const colorTint = podcastColors ? podcastColors.darkTintUnsafe : '#78A8A8';
    const colorBackground = podcastColors ? podcastColors.background : '#78A8A8';

    const imageUrl =
        episode?.imageUrl || (podcastUuid && getImageUrl(40, podcastUuid, false)) || '';

    return {
        playedUpTo: state.electron.miniPlayer.playedUpTo,
        playing: state.electron.miniPlayer.isPlaying,
        episode,
        imageUrl,
        colorTint,
        colorBackground,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    seekTo: (uuid: string, time: number) => dispatch(electronMiniPlayerSeekTo(time)),
    play: () => dispatch(electronMiniPlayerPlay()),
    pause: () => dispatch(electronMiniPlayerPause()),
    skipBack: () => dispatch(electronMiniPlayerSkipBack()),
    skipForward: () => dispatch(electronMiniPlayerSkipForward()),
    closePlayer: () => dispatch(electronMiniPlayerOpened(false)),
});

const MiniPlayerWindowConnected = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(MiniPlayerWindow),
);

export { MiniPlayerWindowConnected as MiniPlayerWindow };
