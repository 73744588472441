import styled from 'styled-components';
import { blueTextLinkMixin, H70 } from '../../../styles';

export const SmallLinksWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const AboutLink = styled.a`
    ${blueTextLinkMixin};
`;

export const AboutText = styled(H70)`
    text-align: left;
`;
