import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import * as fromSettingsActions from '../../../redux/actions/settings.actions';
import { RootState } from '../../../redux/reducers';
import SettingsPrivacyComponent from './SettingsPrivacy';

const mapStateToProps = (state: RootState) => ({
    privacyAnalytics: !!state.settings.privacyAnalytics,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    savePrivacyAnalytics: (enabled: boolean) =>
        dispatch(fromSettingsActions.Actions.savePrivacyAnalytics(enabled)),
});

const SettingsPrivacy = connect(mapStateToProps, mapDispatchToProps)(SettingsPrivacyComponent);
export { SettingsPrivacy };
