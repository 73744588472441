import { TrackOnMount } from 'components/Tracks';
import { NavigationItems } from 'helper/NavigationHelper';
import { ModalTypes } from 'helper/UiHelper';
import { Folder, TracksProperties } from 'model/types';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { Redirect, RouteComponentProps } from 'react-router';
import { api } from 'services/api';
import { ErrorMessage, LoaderSquare } from '../../components';
import { withAnalyticsContext } from '../../context/AnalyticsContext';
import { FolderEmptyState } from './FolderEmptyState';
import { GridOfPodcasts } from './GridOfPodcasts';
import { ListOfPodcasts } from './ListOfPodcasts';
import { OptionsHeader } from './OptionsHeader';
import { PodcastsEmptyState } from './PodcastsEmptyState';
import { PodcastsContentWrapper, PodcastsPageWrapper } from './PodcastsPage.styled';
import {
    PodcastBadges,
    PodcastGridLayouts,
    PodcastGridOrder,
    PodcastListItem,
    PodcastListRearrangeAction,
} from './model';

const TRACKS_SORT_ORDER = {
    [PodcastGridOrder.DATE_ADDED]: 'date_added',
    [PodcastGridOrder.TITLE]: 'name',
    [PodcastGridOrder.RELEASE_DATE]: 'episode_release_date',
    [PodcastGridOrder.USER_SORTED]: 'drag_and_drop',
};

const TRACKS_BADGE_TYPES = {
    [PodcastBadges.OFF]: 'off',
    [PodcastBadges.NEWEST_EPISODE]: 'only_latest_episode',
};

export type Props = {
    downloadSubscribedPodcasts: () => void;
    rearrangePodcastList: (actions: PodcastListRearrangeAction[]) => void;
    recordEvent: (event: string, properties?: TracksProperties) => void;
    savePodcastGridScrollPosition: (position: number) => void;
    savePodcastGridLayout: (layout: PodcastGridLayouts) => void;
    savePodcastGridBadgesOn: (badges: PodcastBadges) => void;
    savePodcastGridOrder: (order: PodcastGridOrder) => void;
    showModal: (modalType: ModalTypes, data?: Record<string, unknown>) => void;
    updateFolder: (folder: Folder, podcastUuids: string[]) => void;
    scrollPosition: number;
    loadFailed: boolean;
    isLoading: boolean;
    gridLayout: PodcastGridLayouts;
    gridOrder: PodcastGridOrder;
    badges: PodcastBadges;
    items: PodcastListItem[];
    podcastsWebOpenEvent: () => void;
    folder?: Folder;
    podcastsInFolder: string[];
} & RouteComponentProps<{ folderUuid: string }> &
    WrappedComponentProps;

export class PodcastsPage extends Component<Props> {
    pageRef: React.MutableRefObject<HTMLDivElement>;

    constructor(props: Props) {
        super(props);
        this.pageRef = React.createRef() as React.MutableRefObject<HTMLDivElement>;
    }

    componentDidMount() {
        this.props.downloadSubscribedPodcasts();
        this.props.podcastsWebOpenEvent();

        // Restore the scroll position
        if (this.pageRef?.current?.parentElement) {
            this.pageRef.current.parentElement.scrollTop = this.props.scrollPosition;
        }
        this.props.savePodcastGridScrollPosition(0);
    }

    handleItemClick = (item: PodcastListItem) => {
        this.props.recordEvent(
            'podcast' in item ? 'podcasts_list_podcast_tapped' : 'podcasts_list_folder_tapped',
        );
        const scrollPosition = this.pageRef?.current?.parentElement?.scrollTop;
        this.props.savePodcastGridScrollPosition(scrollPosition ?? 0);
    };

    gridLayoutChanged = (layout: PodcastGridLayouts) => {
        this.props.savePodcastGridLayout(layout);
        this.props.recordEvent('podcasts_list_layout_changed', {
            layout: PodcastGridLayouts[layout].toLowerCase(),
        });
    };

    showBadgesChanged = (badges: PodcastBadges) => {
        this.props.savePodcastGridBadgesOn(badges);
        this.props.recordEvent('podcasts_list_badges_changed', {
            type: TRACKS_BADGE_TYPES[badges],
        });
    };

    onOrderChanged = (newOrder: PodcastGridOrder) => {
        const {
            folder,
            podcastsInFolder,
            updateFolder,
            recordEvent,
            savePodcastGridOrder,
        } = this.props;

        if (folder) {
            const updatedFolder = { ...folder, sortType: newOrder };
            api.updateFolder(updatedFolder, podcastsInFolder);
            updateFolder(updatedFolder, podcastsInFolder);
            recordEvent('folder_sort_by_changed', {
                sort_by: TRACKS_SORT_ORDER[newOrder],
            });
        } else {
            savePodcastGridOrder(newOrder);
            recordEvent('podcasts_list_sort_order_changed', {
                sort_by: TRACKS_SORT_ORDER[newOrder],
            });
        }
    };

    handleSortEnd = (oldPosition: number, newPosition: number) => {
        const {
            folder,
            podcastsInFolder,
            items,
            updateFolder,
            rearrangePodcastList,
            recordEvent,
            savePodcastGridOrder,
        } = this.props;

        if (oldPosition === newPosition) {
            return;
        }

        const item = items[oldPosition];

        rearrangePodcastList([['MOVE', item.uuid, folder?.uuid || 'home', newPosition]]);

        if (folder && folder.sortType !== PodcastGridOrder.USER_SORTED) {
            const updatedFolder = {
                ...folder,
                sortType: PodcastGridOrder.USER_SORTED,
            };
            api.updateFolder(updatedFolder, podcastsInFolder);
            updateFolder(updatedFolder, podcastsInFolder);
        }
        if (!folder && this.props.gridOrder !== PodcastGridOrder.USER_SORTED) {
            savePodcastGridOrder(PodcastGridOrder.USER_SORTED);
        }

        recordEvent('podcasts_list_reordered');
    };

    renderContent = () => {
        const { isLoading, loadFailed, items, gridLayout } = this.props;

        if (isLoading && items.length === 0) {
            return this.renderLoading();
        }

        if (loadFailed) {
            return this.renderError();
        }

        if (items.length === 0) {
            return this.renderEmptyState();
        }

        const LayoutComponent =
            gridLayout === PodcastGridLayouts.LIST ? ListOfPodcasts : GridOfPodcasts;

        return (
            <>
                {this.renderOptionsHeader()}
                <LayoutComponent
                    items={items}
                    onItemClick={this.handleItemClick}
                    onSortEnd={this.handleSortEnd}
                />
            </>
        );
    };

    renderLoading() {
        return <LoaderSquare />;
    }

    renderError() {
        const { intl } = this.props;
        return <ErrorMessage description={intl.formatMessage({ id: 'podcasts-loading-error' })} />;
    }

    renderEmptyState() {
        if (this.props.folder) {
            return (
                <>
                    {this.renderOptionsHeader()}
                    <FolderEmptyState folderUuid={this.props.folder.uuid} />
                </>
            );
        }
        return <PodcastsEmptyState />;
    }

    getOptionsHeaderTitle() {
        const { folder, intl } = this.props;

        if (folder) {
            return folder.name;
        }

        return intl.formatMessage({ id: 'podcasts' });
    }

    renderOptionsHeader() {
        const { folder, gridOrder, badges, gridLayout, recordEvent, showModal } = this.props;

        return (
            <OptionsHeader
                folder={folder}
                title={this.getOptionsHeaderTitle()}
                layout={gridLayout}
                badges={badges}
                sort={folder ? folder.sortType : gridOrder}
                onOrderChanged={this.onOrderChanged}
                onLayoutChanged={this.gridLayoutChanged}
                onBadgesChanged={this.showBadgesChanged}
                onCreateFolderClick={() => {
                    recordEvent('podcasts_list_folder_button_tapped');
                    showModal(ModalTypes.createFolder, { eventSource: 'podcasts_list' });
                }}
                onEditFolderDetailsClick={() =>
                    showModal(ModalTypes.editFolderDetails, { folderUuid: folder?.uuid })
                }
                onEditFolderPodcastsClick={() =>
                    showModal(ModalTypes.editFolderPodcasts, { folderUuid: folder?.uuid })
                }
            />
        );
    }

    render() {
        const {
            intl,
            folder,
            podcastsInFolder,
            isLoading,
            match: {
                params: { folderUuid },
            },
            badges,
            gridLayout,
            gridOrder,
            items,
        } = this.props;

        if (!isLoading && folderUuid && !folder) {
            // All data has loaded, but the requested folder doesn't exist — redirect to Podcasts home
            return <Redirect to={NavigationItems.PODCASTS.path} />;
        }

        return (
            <PodcastsPageWrapper ref={this.pageRef}>
                {!isLoading &&
                    (folder ? (
                        <TrackOnMount
                            event="folder_shown"
                            properties={{
                                sort_order: TRACKS_SORT_ORDER[folder.sortType],
                                number_of_podcasts: podcastsInFolder.length,
                            }}
                            key="folder_shown"
                        />
                    ) : (
                        <TrackOnMount
                            event="podcasts_list_shown"
                            properties={{
                                sort_order: TRACKS_SORT_ORDER[gridOrder],
                                badge_type: TRACKS_BADGE_TYPES[badges],
                                layout: PodcastGridLayouts[gridLayout].toLowerCase(),
                                number_of_podcasts: items.filter(item => 'podcast' in item).length,
                                number_of_folders: items.filter(item => 'folder' in item).length,
                            }}
                            key="podcasts_list_shown"
                        />
                    ))}
                <Helmet>
                    <title>
                        {folder
                            ? `${folder.name} ${intl.formatMessage({ id: 'folder' })}`
                            : intl.formatMessage({ id: 'podcasts' })}
                    </title>
                </Helmet>
                <PodcastsContentWrapper>{this.renderContent()}</PodcastsContentWrapper>
            </PodcastsPageWrapper>
        );
    }
}

export default injectIntl(withAnalyticsContext(PodcastsPage));
