import React, { useState } from 'react';
import useFormatMessage from 'hooks/useFormatMessage';
import { getThemeColorsForUploadedFileFromColorId } from '../../../../../helper/ColorHelper';
import { isDarkTheme, getThemeFromId } from '../../../../../model/theme';
import { DynamicPie } from '../../../../../components/DynamicPie';
import { blue, red } from '../../../../../styles';
import FileEditModalTooltip from '../FileEditModalTooltip';
import { DynamicLoadingCircle } from '../../../../../components/DynamicLoadingCircle';
import UploadImageButton from './UploadImageButton';
import {
    FileColorPickerWrapper,
    Swatch,
    WhiteDot,
    ProgressIndicatorWrapper,
    UploadImageButtonWrapper,
} from './styled';

const FileColorPicker = ({ theme, selectedColor, image, imageUploadData, onColorSelect }) => {
    const formatMessage = useFormatMessage();
    const [hoveringUploadButton, setHoveringUploadButton] = useState(false);

    const uploadButtonEnter = () => {
        setHoveringUploadButton(true);
    };

    const uploadButtonLeave = () => {
        setHoveringUploadButton(false);
    };

    const colorSelected = id => () => {
        if (!imageUploadData) {
            onColorSelect(id);
        }
    };

    const colorIds = [1, 2, 3, 4, 5, 6, 7, 8];

    return (
        <FileColorPickerWrapper>
            {imageUploadData && imageUploadData.complete ? (
                <ProgressIndicatorWrapper>
                    <DynamicLoadingCircle size={40} color={blue} />
                </ProgressIndicatorWrapper>
            ) : imageUploadData ? (
                <ProgressIndicatorWrapper>
                    <DynamicPie
                        size={40}
                        color={imageUploadData.failed ? red : blue}
                        percent={(100 * imageUploadData.progress) / imageUploadData.size}
                        ringStrokeWidth={3}
                    />
                </ProgressIndicatorWrapper>
            ) : image ? (
                <Swatch
                    color={getThemeFromId(theme).legacyScss['theme-button-disabled']}
                    selected={selectedColor === 0}
                    image={image}
                    onClick={colorSelected(0)}
                >
                    {selectedColor === 0 && <WhiteDot />}
                </Swatch>
            ) : (
                <UploadImageButtonWrapper>
                    <UploadImageButton
                        onMouseEnter={uploadButtonEnter}
                        onMouseLeave={uploadButtonLeave}
                    />
                    {hoveringUploadButton && (
                        <FileEditModalTooltip
                            top={-58}
                            left={-57}
                            text={formatMessage('upload-custom-image')}
                        />
                    )}
                </UploadImageButtonWrapper>
            )}
            {colorIds.map(id => {
                const colorObj = getThemeColorsForUploadedFileFromColorId(id);
                const color = isDarkTheme(theme) ? colorObj.darkTint : colorObj.lightTint;
                const selected = id === selectedColor && !imageUploadData;

                return (
                    <Swatch
                        key={`col${id}`}
                        color={color}
                        selected={selected}
                        onClick={colorSelected(id)}
                    >
                        {selected && <WhiteDot />}
                    </Swatch>
                );
            })}
        </FileColorPickerWrapper>
    );
};

export default FileColorPicker;
