import useFormatMessage from 'hooks/useFormatMessage';
import React from 'react';
import { NavigationItems } from '../../../helper/NavigationHelper';
import {
    DiscoverLink,
    GoToDiscoverWrapper,
    NoPodcastsDescription,
    NoPodcastsHeading,
    PodcastsEmptyStateWrapper,
} from './PodcastsEmptyState.styled';

const PodcastsEmptyState = () => {
    const formatMessage = useFormatMessage();

    const heading = formatMessage('no-podcasts-heading');
    const description = formatMessage('no-podcasts-description');
    const buttonText = formatMessage('go-to-discover');

    return (
        <PodcastsEmptyStateWrapper>
            <NoPodcastsHeading>{heading}</NoPodcastsHeading>
            <NoPodcastsDescription>{description}</NoPodcastsDescription>
            <GoToDiscoverWrapper>
                <DiscoverLink to={NavigationItems.DISCOVER.path}>{buttonText}</DiscoverLink>
            </GoToDiscoverWrapper>
        </PodcastsEmptyStateWrapper>
    );
};

export default PodcastsEmptyState;
