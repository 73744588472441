import React, { FormEvent, useState } from 'react';
import { useDispatch, useSelector } from 'hooks/react-redux-typed';
import useTracks from 'hooks/useTracks';
import uniq from 'lodash/uniq';
import { getFolder, getPodcastUuidsForFolder } from 'redux/reducers/selectors';
import { FormattedMessage } from 'react-intl';
import { Folder, FormStatus, PodcastListPodcast } from 'model/types';
import { PodcastListRearrangeAction } from 'pages/PodcastsPage/model';
import { api } from 'services/api';
import { Button } from 'components/Button';
import { GenericErrorMessage } from 'components/GenericErrorMessage';
import { TrackOnMount } from 'components/Tracks';
import * as fromFoldersActions from 'redux/actions/folders.actions';
import * as fromPodcastsActions from 'redux/actions/podcasts.actions';
import { FolderPodcastsSelector } from '../FolderPodcastsSelector';
import { ErrorMessage, Footer, Form, FormSection } from './FolderEditPodcastsForm.styled';

export type Props = {
    folderUuid: string;
    onSuccess: () => void;
};

const FolderEditPodcastsForm = ({ folderUuid, onSuccess }: Props) => {
    const dispatch = useDispatch();
    const { recordEvent } = useTracks();
    const { folder } = useSelector(state => ({
        folder: getFolder(state, folderUuid) as Folder,
    }));
    const originalPodcasts = useSelector(state =>
        getPodcastUuidsForFolder(state, folderUuid),
    ) as string[];

    const [formStatus, setFormStatus] = useState<FormStatus>(FormStatus.READY);
    const [podcasts, setPodcasts] = useState(originalPodcasts);
    const [filteredPodcasts, setFilteredPodcasts] = useState<PodcastListPodcast[]>([]);

    const submitForm = (e: FormEvent) => {
        e.preventDefault();
        setFormStatus(FormStatus.SUBMITTING);

        // TODO: Add podcasts back
        api.updateFolder(folder, podcasts).then(
            updatedFolder => {
                // Modify Redux state to update the folder
                dispatch(fromFoldersActions.Actions.updateFolder(updatedFolder, podcasts));

                // Then ensure that the all the added and removed podcasts wind up at the _end_ of their
                // new folder — either in this folder or the home folder.
                const addedPodcasts = podcasts.filter(uuid => !originalPodcasts.includes(uuid));
                const removedPodcasts = originalPodcasts.filter(uuid => !podcasts.includes(uuid));
                dispatch(
                    fromPodcastsActions.Actions.rearrangePodcastList([
                        ...addedPodcasts.map(
                            uuid => ['PUSH', uuid, folder.uuid] as PodcastListRearrangeAction,
                        ),
                        ...removedPodcasts.map(
                            uuid => ['PUSH', uuid, 'home'] as PodcastListRearrangeAction,
                        ),
                    ]),
                );

                setFormStatus(FormStatus.READY);
                recordEvent('folder_choose_podcasts_dismissed', {
                    changed_podcasts: addedPodcasts.length - removedPodcasts.length,
                });
                onSuccess();
            },
            () => {
                setFormStatus(FormStatus.ERROR);
            },
        );
    };

    const hasSelectedAllFilteredPodcasts = () =>
        !filteredPodcasts.some(podcast => podcasts.indexOf(podcast.uuid) === -1);

    const handleSelectAllClick = () =>
        hasSelectedAllFilteredPodcasts()
            ? setPodcasts(
                  podcasts.filter(uuid => !filteredPodcasts.some(podcast => uuid === podcast.uuid)),
              )
            : setPodcasts(uniq([...filteredPodcasts.map(p => p.uuid), ...podcasts]));

    return (
        <Form onSubmit={submitForm}>
            <TrackOnMount event="folder_choose_podcasts_shown" />
            <FormSection fullWidth={true}>
                <FolderPodcastsSelector
                    currentFolderUuid={folderUuid}
                    selected={podcasts}
                    onChange={setPodcasts}
                    onFilteredPodcastsChange={setFilteredPodcasts}
                />
            </FormSection>
            <Footer>
                {formStatus === FormStatus.ERROR && (
                    <ErrorMessage>
                        <GenericErrorMessage />
                    </ErrorMessage>
                )}
                <Button kind="text" type="button" onClick={handleSelectAllClick}>
                    <FormattedMessage
                        id={hasSelectedAllFilteredPodcasts() ? 'unselect-all' : 'select-all'}
                    />
                </Button>
                <Button loading={formStatus === FormStatus.SUBMITTING} type="submit">
                    {podcasts.length === 0 ? (
                        <FormattedMessage id="done" />
                    ) : podcasts.length === 1 ? (
                        <FormattedMessage id="choose-podcasts-singular" />
                    ) : (
                        <FormattedMessage
                            id="choose-podcasts-plural"
                            values={{ count: podcasts.length }}
                        />
                    )}
                </Button>
            </Footer>
        </Form>
    );
};

export default FolderEditPodcastsForm;
