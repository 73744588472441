import styled, { css } from 'styled-components';
import { ModernStyle } from 'styles/ModernStyle.styled';
import { fontFamilyRetina, gridSize } from '../../styles';

const lightBackground = '#FFFFFF';
const darkBackground = '#272727';
const lightBackgroundBlurred = '#FBFCFC';
const darkBackgroundBlurred = '#1C1C1C';

export const Wrapper = styled.label<{
    focused: boolean;
    status?: string;
    width?: number;
}>`
    display: flex;
    align-items: center;
    width: ${props => (props.width ? `${props.width}px` : '100%')};
    border-radius: 10px;
    box-shadow: inset 0 0 0 1px
        ${props => {
            if (props.focused) {
                return props.theme.legacyScss['theme-interactive'];
            }
            if (props.status === 'error') {
                return props.theme.tokens['support-05'];
            }
            return props.theme.legacyScss['theme-interactive-secondary'];
        }};

    background-color: ${props => {
        if (props.focused) {
            return props.theme.name === 'dark' ? darkBackground : lightBackground;
        }
        return props.theme.name === 'dark' ? darkBackgroundBlurred : lightBackgroundBlurred;
    }};

    ${props => {
        let iconColor =
            props.theme.name === 'dark' ? '#AFB4BD' : props.theme.tokens['primary-text-02'];
        let auxIconColor = iconColor;

        if (props.focused) {
            iconColor = props.theme.tokens['primary-interactive-01'];
            auxIconColor = iconColor;
        } else if (props.status === 'ok') {
            auxIconColor = props.theme.tokens['support-02'];
        } else if (props.status === 'error') {
            iconColor = props.theme.tokens['support-05'];
            auxIconColor = iconColor;
        }

        return `
            ${PrefixIcon} {
                color: ${iconColor};
            }
            ${AuxIconWrapper} {
                color: ${auxIconColor};
            }
        `;
    }};

    ${ModernStyle} && {
        border-radius: 8px;
        border: 1px solid
            ${props =>
                props.status === 'error'
                    ? props.theme.tokens['support-05']
                    : props.theme.tokens['primary-text-02']};

        box-shadow: none;
        ${props =>
            props.focused &&
            css`
                border-color: ${props.theme.tokens['primary-text-01']};
                ${AuxIconWrapper} {
                    color: inherit;
                }
                ${PrefixIcon} {
                    color: inherit;
                }
            `}
        overflow: hidden;
    }
`;

export const PrefixIcon = styled.div<{ hasExplicitIcon: boolean }>`
    min-width: ${2 * gridSize}px;
    margin: 0 12px 2px 12px;
    user-select: none;

    &:empty {
        margin: 0;
    }

    ${ModernStyle} && {
        margin: 1px 0 0 ${2 * gridSize}px;
        color: ${props => props.theme.tokens['primary-text-02']};
        width: ${2.5 * gridSize}px;

        /* In ModernStyle, don't show icons by default, only if they've been explicitly selected */
        ${props => !props.hasExplicitIcon && 'display: none'}
    }
`;

export const StyledInput = styled.input`
    flex-grow: 1;
    color: ${props => (props.theme.name === 'dark' ? 'white' : 'black')};
    border: none;
    background: transparent;

    appearance: none;
    font-family: ${fontFamilyRetina};
    font-size: 14px;
    height: 48px;
    margin-left: -4px;
    outline: none;

    &:required,
    &:invalid {
        box-shadow: none;
    }

    ${ModernStyle} && {
        font-size: 16px;
        font-weight: 500;
        color: ${props => props.theme.tokens['primary-text-01']};
        padding-left: 16px;
    }
`;

export const AuxIconWrapper = styled.div`
    width: 48px;
    align-self: stretch;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;

    button {
        width: 100%;
        height: 100%;
    }
    svg {
        margin: auto;
    }
`;
