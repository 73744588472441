import { ErrorBoundary } from 'components/ErrorBoundary';
import { LegacyNotificationBanner } from 'components/LegacyNotificationBanner';
import Modal, { ConnectedModal } from 'components/Modal';
import { RouteMatchMonitor } from 'components/RouteMatchMonitor';
import { ScrollbarPadding } from 'components/ScrollbarPadding';
import { SecondaryNav } from 'components/SecondaryNav';
import { SentryMonitor } from 'components/SentryMonitor';
import { Tooltip } from 'components/Tooltip';
import { ConfirmClearUpNextForm } from 'components/form/ConfirmClearUpNextForm';
import { RatePodcastForm } from 'components/form/RatePodcastForm';
import { FolderCreateForm } from 'components/form/folders/FolderCreateForm';
import { FolderDeleteForm } from 'components/form/folders/FolderDeleteForm';
import { FolderEditDetailsForm } from 'components/form/folders/FolderEditDetailsForm';
import { FolderEditPodcastsForm } from 'components/form/folders/FolderEditPodcastsForm';
import { FolderSelectForm } from 'components/form/folders/FolderSelectForm';
import 'focus-visible';
import * as key from 'keymaster';
import { DARK_THEME } from 'model/theme/theme-dark';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';
import { ThemeProvider } from 'styled-components';
import { EpisodePopup, ErrorMessage, SharePopup } from '../../components';
import { ArchiveAllPopup } from '../../components/popup/ArchiveAllPopup';
import { ClearHistoryPopup } from '../../components/popup/ClearHistoryPopup';
import { KeyboardShortcuts } from '../../components/popup/KeyboardShortcuts';
import { SupportPopup } from '../../components/popup/SupportPopup';
import { isLegacyApp, isWindowsApp } from '../../helper/Browser';
import { NavigationItems } from '../../helper/NavigationHelper';
import { ModalTypes } from '../../helper/UiHelper';
import { CONTENT_DIV_ID } from '../../model/page';
import { getPreferredWindowsTheme, getThemeFromId } from '../../model/theme';
import { Chapters } from './Chapters';
import FilesDropZone, { FILES_DROP_ZONE_ID_PREFIX } from './FilesDropZone';
import ImageDropZone, { IMAGE_DROP_ZONE_ID_PREFIX } from './ImageDropZone';
import { AlreadyPlusSubscriberModal } from './Modals/AlreadyPlusSubscriberModal';
import { BookmarksModal } from './Modals/BookmarksModal';
import CancelSubscriptionModal from './Modals/CancelSubscriptionModal';
import ChangeBillingDetailsModal from './Modals/ChangeBillingDetailsModal';
import { ChangeEmailModal } from './Modals/ChangeEmailModal';
import { ChangePasswordModal } from './Modals/ChangePasswordModal';
import { CheckoutModal } from './Modals/CheckoutModal';
import { DeleteAccountModal } from './Modals/DeleteAccountModal';
import { FarewellModal } from './Modals/FarewellModal';
import FileDeleteModal from './Modals/FileDeleteModal';
import FileEditModal from './Modals/FileEditModal';
import { RegionSelectModal } from './Modals/RegionSelectModal';
import { ThankYouModal } from './Modals/ThankYouModal';
import { Navigation } from './Navigation';
import NotificationContainer from './NotificationContainer';
import { PlayerControls } from './PlayerControls';
import { UpNext } from './UpNext';
import {
    Content,
    ContentWrapper,
    Layout,
    LayoutFooter,
    LayoutWrapper,
    SecondaryNavWrapper,
} from './styled';

// We have to fire the userReturned action on mount, but there are certain situations
// (e.g. changing language) where the Redux stack will re-mount the whole DOM, rather
// than just issue an update. When this happens, we don't want to fire it again.
let userReturnedOnMount = false;
class LoggedInPageChrome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shouldShowFileUploadOverlay: false,
            shouldShowImageUploadOverlay: false,
            ratingModalType: null,
        };

        if (!userReturnedOnMount) {
            this.props.userReturned();
            userReturnedOnMount = true;
        }
    }

    componentDidMount() {
        key('t', this.shortcutT);
        key('/', this.shortcutQuestionMark);
        key('shift+/', this.shortcutQuestionMark);
        key('h', this.shortcutH);

        this.updateDocumentTagLanguage();

        document.addEventListener('navigate-to-settings', this.navigateToSettings);
        document.addEventListener('update-theme', this.updateTheme);
        window.addEventListener('focus', this.windowFocus);
        document.addEventListener('system-theme-changed', this.systemThemeChanged);
    }

    componentWillUnmount() {
        key.unbind('t');
        key.unbind('/');
        key.unbind('shift+/');
        key.unbind('h');

        document.removeEventListener('navigate-to-settings', this.navigateToSettings);
        document.removeEventListener('update-theme', this.updateTheme);
        window.removeEventListener('focus', this.windowFocus);
        document.removeEventListener('system-theme-changed', this.systemThemeChanged);
    }

    systemThemeChanged = () => {
        if (isWindowsApp()) {
            const preferredTheme = getPreferredWindowsTheme();
            this.props.updatePreferredColorScheme(preferredTheme);
        }
    };

    componentDidUpdate() {
        this.updateDocumentTagLanguage();
    }

    updateDocumentTagLanguage = () => {
        document.documentElement.lang = this.props.settings.language || 'en-US';
    };

    windowFocus = () => {
        this.props.userReturned();
    };

    navigateToSettings = () => {
        this.props.history.push(`${NavigationItems.SETTINGS.path}/about`);
    };

    updateTheme = event => {
        const { theme } = event.detail;
        this.props.updateTheme(theme);
    };

    // Shortcuts

    shortcutH = () => {
        this.props.history.push(NavigationItems.HISTORY.path);
    };

    shortcutT = () => {
        this.props.toggleTheme();
    };

    shortcutQuestionMark = () => {
        this.props.openCloseKeyboardShortcuts();
    };

    // Drag and drop

    onDragEnter = event => {
        event.stopPropagation();
        event.preventDefault();

        const { editFile } = this.props;

        if (event.dataTransfer.types.includes('Files')) {
            // These must be mutually exclusive
            this.setState({
                shouldShowFileUploadOverlay: !editFile,
                shouldShowImageUploadOverlay: !!editFile,
            });
        }
    };

    onDragOver = event => {
        event.stopPropagation();
        event.preventDefault();
    };

    onDragLeave = event => {
        event.stopPropagation();
        event.preventDefault();

        if (event.target.id.startsWith(FILES_DROP_ZONE_ID_PREFIX)) {
            this.setState({
                shouldShowFileUploadOverlay: false,
            });
        } else if (event.target.id.startsWith(IMAGE_DROP_ZONE_ID_PREFIX)) {
            this.setState({
                shouldShowImageUploadOverlay: false,
            });
        }
    };

    onDragEnd = event => {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }

        this.setState({
            shouldShowFileUploadOverlay: false,
            shouldShowImageUploadOverlay: false,
        });
    };

    onDrop = event => {
        event.stopPropagation();
        event.preventDefault();

        const { editFile } = this.props;
        const { shouldShowFileUploadOverlay, shouldShowImageUploadOverlay } = this.state;

        const { files } = event.dataTransfer;

        if (shouldShowFileUploadOverlay) {
            for (const file of files) {
                this.props.requestFileUploadPreflight(file);
            }
        } else if (shouldShowImageUploadOverlay) {
            if (event.target.id.startsWith(IMAGE_DROP_ZONE_ID_PREFIX)) {
                for (const file of files) {
                    this.props.requestImageUploadPreflight(editFile.uuid, file);
                }
            }
        }

        this.setState({
            shouldShowFileUploadOverlay: false,
            shouldShowImageUploadOverlay: false,
        });
    };

    // Rendering

    renderFooter() {
        const { shouldShowFileUploadOverlay } = this.state;

        return (
            <LayoutFooter blurred={shouldShowFileUploadOverlay}>
                <PlayerControls />
            </LayoutFooter>
        );
    }

    setRatingModalType = type => {
        this.setState({ ratingModalType: type });
    };

    renderModal(modalState) {
        const { hideRatingModal } = this.props;
        const { type, show, data } = modalState;
        if (!show) return null;
        switch (type) {
            case ModalTypes.contentRegionSelection:
                return <RegionSelectModal />;

            case ModalTypes.createFolder:
                return (
                    <ConnectedModal
                        title={<FormattedMessage id="new-folder" />}
                        horizontalInsetPx={24}
                        key={ModalTypes.createFolder}
                    >
                        {({ closeModal }) => (
                            <FolderCreateForm
                                defaultPodcasts={data?.defaultPodcasts}
                                eventSource={data?.eventSource}
                                onSuccess={folder => {
                                    closeModal();
                                    this.props.history.push(
                                        `${NavigationItems.PODCASTS.path}/folders/${folder.uuid}`,
                                    );
                                }}
                            />
                        )}
                    </ConnectedModal>
                );

            case ModalTypes.editFolderDetails:
                return (
                    <ConnectedModal
                        title={<FormattedMessage id="edit-folder" />}
                        horizontalInsetPx={24}
                        key={ModalTypes.editFolderDetails}
                    >
                        {({ closeModal }) => (
                            <FolderEditDetailsForm
                                folderUuid={data?.folderUuid}
                                onSuccess={closeModal}
                            />
                        )}
                    </ConnectedModal>
                );

            case ModalTypes.editFolderPodcasts:
                return (
                    <ConnectedModal
                        title={<FormattedMessage id="add-or-remove-podcasts" />}
                        horizontalInsetPx={24}
                        key={ModalTypes.editFolderPodcasts}
                    >
                        {({ closeModal }) => (
                            <FolderEditPodcastsForm
                                folderUuid={data?.folderUuid}
                                onSuccess={closeModal}
                            />
                        )}
                    </ConnectedModal>
                );

            case ModalTypes.deleteFolder:
                return (
                    <ConnectedModal horizontalInsetPx={24} key={ModalTypes.deleteFolder}>
                        {({ closeModal }) => (
                            <FolderDeleteForm
                                folderUuid={data?.folderUuid}
                                onSuccess={() => {
                                    closeModal();
                                    this.props.history.push(NavigationItems.PODCASTS.path);
                                }}
                            />
                        )}
                    </ConnectedModal>
                );

            case ModalTypes.changePodcastFolder:
                return (
                    <ConnectedModal
                        title={<FormattedMessage id="move-to-folder" />}
                        horizontalInsetPx={24}
                        key={ModalTypes.changePodcastFolder}
                    >
                        {({ closeModal }) => (
                            <FolderSelectForm
                                podcastUuid={data?.podcastUuid}
                                onSuccess={closeModal}
                            />
                        )}
                    </ConnectedModal>
                );

            case ModalTypes.confirmClearUpNext:
                return (
                    <ConnectedModal horizontalInsetPx={32} key={ModalTypes.confirmClearUpNext}>
                        {({ closeModal }) => <ConfirmClearUpNextForm onSuccess={closeModal} />}
                    </ConnectedModal>
                );

            case ModalTypes.listBookmarks:
                return (
                    <BookmarksModal podcastUuid={data.podcastUuid} episodeUuid={data.episodeUuid} />
                );

            case ModalTypes.ratePodcast:
                return (
                    <Modal
                        horizontalInsetPx={40}
                        key={ModalTypes.ratePodcast}
                        onClose={() => hideRatingModal(`${this.state.ratingModalType}_dismissed`)}
                    >
                        <RatePodcastForm
                            podcastUuid={data.podcastUuid}
                            onFormLoad={this.setRatingModalType}
                            onSubmit={hideRatingModal}
                        />
                    </Modal>
                );

            // TODO: Add more modals here

            default:
                return null;
        }
    }

    render() {
        const {
            playerEpisode,
            episode,
            shareOpen,
            archiveAll,
            deleteFile,
            editFile,
            showClearHistoryConfirmation,
            showRecommendations,
            shouldShowEmailChangeModal,
            shouldShowPasswordChangeModal,
            shouldShowAccountDeleteModal,
            showCancelSubscriptionModal,
            shouldShowFarewellModal,
            showBillingDetailsModal,
            shouldShowCheckoutModal,
            theme,

            shouldShowThankYouModal,
            shouldShowAlreadyPlusSubscriberModal,
            modals,
            shouldShowKeyboardShortcuts,
        } = this.props;

        const { shouldShowFileUploadOverlay, shouldShowImageUploadOverlay } = this.state;

        const playerOpen = showRecommendations || !!playerEpisode;

        // When Up Next is open, popups should default to the dark theme
        const popupTheme = this.props.upNextOpen ? DARK_THEME : getThemeFromId(theme);

        return (
            <LayoutWrapper
                onDragEnter={this.onDragEnter}
                onDragOver={this.onDragOver}
                onDragLeave={this.onDragLeave}
                onDragEnd={this.onDragEnd}
                onDrop={this.onDrop}
            >
                {isLegacyApp() && <LegacyNotificationBanner />}
                <RouteMatchMonitor />
                <Layout blurred={shouldShowFileUploadOverlay}>
                    <Navigation />
                    <ContentWrapper>
                        <ScrollbarPadding>
                            <SecondaryNavWrapper>
                                <SecondaryNav />
                            </SecondaryNavWrapper>
                        </ScrollbarPadding>
                        <Content
                            id={CONTENT_DIV_ID}
                            ref={el => {
                                window.contentElement = el;
                            }}
                        >
                            <ErrorBoundary
                                key={window.location.pathname}
                                logError
                                fallback={<ErrorMessage />}
                            >
                                {this.props.children}
                            </ErrorBoundary>
                        </Content>
                    </ContentWrapper>
                </Layout>
                {playerOpen && this.renderFooter()}
                <ThemeProvider theme={DARK_THEME}>
                    <Chapters isOpen={this.props.chaptersOpen} />
                    <UpNext isOpen={this.props.upNextOpen} />
                </ThemeProvider>

                <ThemeProvider theme={popupTheme}>
                    {this.renderModal(modals)}
                    {shouldShowThankYouModal && <ThankYouModal />}
                    {shouldShowAlreadyPlusSubscriberModal && <AlreadyPlusSubscriberModal />}
                    {shouldShowEmailChangeModal && <ChangeEmailModal />}
                    {shouldShowPasswordChangeModal && <ChangePasswordModal />}
                    {shouldShowAccountDeleteModal && <DeleteAccountModal />}
                    {showCancelSubscriptionModal && <CancelSubscriptionModal />}
                    {shouldShowFarewellModal && (
                        <FarewellModal onClose={this.props.hideFarewellModal} />
                    )}
                    {showBillingDetailsModal && <ChangeBillingDetailsModal />}
                    {shouldShowCheckoutModal && (
                        <CheckoutModal
                            onClose={this.props.hideCheckoutModal}
                            onPurchaseSuccess={this.props.showThankYouModal}
                        />
                    )}
                    {deleteFile && <FileDeleteModal />}
                    {editFile && (
                        <FileEditModal imageDropZoneIsShowing={shouldShowImageUploadOverlay} />
                    )}
                    {episode && episode.uuid && <EpisodePopup />}
                    {shareOpen && <SharePopup />}
                    {archiveAll && <ArchiveAllPopup />}
                    {showClearHistoryConfirmation && <ClearHistoryPopup />}

                    <SupportPopup />
                    <Tooltip />
                    {shouldShowKeyboardShortcuts && <KeyboardShortcuts />}
                </ThemeProvider>

                <NotificationContainer playerOpen={playerOpen} />
                {shouldShowFileUploadOverlay && <FilesDropZone forceClose={this.onDragEnd} />}
                {/*
                    This is quite painful because ImageDropZone has to be exactly the size
                    and shape of the FileEditModal, but it can't be a child because blurring
                    the modal will blur the overlay. Better future solutions welcome!
                */}
                {shouldShowImageUploadOverlay && <ImageDropZone forceClose={this.onDragEnd} />}
                <SentryMonitor />
            </LayoutWrapper>
        );
    }
}

export default withRouter(LoggedInPageChrome);
