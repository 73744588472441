import PropTypes from 'prop-types';
import FilesDropZone from './view';

export { filesDropZoneIdPrefix as FILES_DROP_ZONE_ID_PREFIX } from './view';

FilesDropZone.propTypes = {
    forceClose: PropTypes.func.isRequired,
};

export default FilesDropZone;
