import styled from 'styled-components';

export const SvgImageButtonWrapper = styled.div<{ iconColour?: string }>`
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: inline-block;

    height: 32px;
    width: 32px;
    margin: 6px 1px;
    padding: 4px 0 0 4px;
    ${props => props.iconColour && `color: ${props.iconColour}`};
`;
