import { TrackOnMount } from 'components/Tracks';
import useFormatMessage from 'hooks/useFormatMessage';
import useTracks from 'hooks/useTracks';
import React from 'react';
import { Switch } from '../../../components/form/Switch';
import {
    ArchivingSettings,
    Description,
    Setting,
    SettingSwitchWrapper,
    SettingTitle,
    SettingsArchivingTitle,
    SettingsArchivingWrapper,
} from './SettingsArchiving.styled';

type Props = {
    saveAutoArchivePlayed: (enabled: boolean) => void;
    saveAutoArchiveIncludesStarred: (enabled: boolean) => void;
    autoArchivePlayed: boolean;
    autoArchiveIncludesStarred: boolean;
};

const SettingsArchiving = ({
    autoArchivePlayed,
    autoArchiveIncludesStarred,
    saveAutoArchiveIncludesStarred,
    saveAutoArchivePlayed,
}: Props) => {
    const formatMessage = useFormatMessage();
    const { recordEvent } = useTracks();
    const toggleAutoArchive = () => {
        saveAutoArchivePlayed(!autoArchivePlayed);
        recordEvent('settings_auto_archive_played_changed', {
            value: autoArchivePlayed ? 'never' : 'after_playing',
        });
    };

    const toggleIncludeStarred = () => {
        saveAutoArchiveIncludesStarred(!autoArchiveIncludesStarred);
        recordEvent('settings_auto_archive_include_starred_toggled', {
            enabled: !autoArchiveIncludesStarred,
        });
    };

    return (
        <SettingsArchivingWrapper>
            <TrackOnMount event="settings_auto_archive_shown" />
            <SettingsArchivingTitle>{formatMessage('settings-archiving')}</SettingsArchivingTitle>
            <ArchivingSettings>
                <Setting>
                    <SettingTitle>
                        {formatMessage('settings-archiving-played-episodes')}
                    </SettingTitle>
                    <SettingSwitchWrapper>
                        <Switch on={autoArchivePlayed} onChange={toggleAutoArchive} />
                    </SettingSwitchWrapper>
                </Setting>
                {autoArchivePlayed && (
                    <Setting>
                        <SettingTitle>
                            {formatMessage('settings-archiving-include-starred-episodes')}
                        </SettingTitle>
                        <SettingSwitchWrapper>
                            <Switch
                                on={autoArchiveIncludesStarred}
                                onChange={toggleIncludeStarred}
                            />
                        </SettingSwitchWrapper>
                        <Description>
                            {formatMessage(
                                autoArchiveIncludesStarred
                                    ? 'settings-archiving-include-starred'
                                    : 'settings-archiving-exclude-starred',
                            )}
                        </Description>
                    </Setting>
                )}
            </ArchivingSettings>
        </SettingsArchivingWrapper>
    );
};

export default SettingsArchiving;
