import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { gridSize } from 'styles';

export const TWO_COLUMNS_BREAKPOINT = 900;

export const Wrapper = styled.div`
    width: 100%;
    max-width: 920px;
    min-height: 100vh;
    position: relative;

    padding: 0 ${3 * gridSize}px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
`;

export const Text = styled.div`
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    margin: ${5 * gridSize}px auto;
    h1 {
        max-width: 460px;
        margin: 0;
    }
    a,
    button {
        font-weight: 600;
        color: inherit;
    }
`;

export const CloseButton = styled(Link)`
    color: ${props => props.theme.tokens['primary-text-01']};
    position: absolute;
    top: ${2 * gridSize}px;
    right: ${3 * gridSize}px;
    opacity: 0.3;
    &:hover {
        opacity: 0.8;
    }
`;
