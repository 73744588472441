/**
 * Default tooltip configuration properties for ReactTooltip.
 * @param text the text to display in the tooltip and for the aria-label attribute.
 */
export const tooltipAndLabel = (text: string) => ({
    'aria-label': text,
    'data-tip': text,
    'data-effect': 'solid',
    'data-delay-show': 500,
    'data-place': 'bottom',
    // By default the tooltip shows on focus, but this causes a bug — if you click a button and a modal displays,
    // when the modal closes focus is returned to the button (good!) triggering the tooltip to display (bad!).
    // We can fix this by using custom mouse and keyboard show/hide events that don't rely on focus.
    'data-event': 'mouseenter keyup',
    'data-event-off': 'mouseleave blur',
});
