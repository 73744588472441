import styled from 'styled-components';

export const LoaderRoundWrapper = styled.div<{ $size: number }>`
    width: ${props => props.$size}px;
    height: ${props => props.$size}px;
    display: none;
    color: ${props => props.theme.tokens['primary-icon-02']};
    svg {
        width: ${props => props.$size}px;
        height: ${props => props.$size}px;
    }
    &.visible {
        display: inline-block;
        svg {
            @keyframes rotate {
                to {
                    transform: rotate(360deg);
                }
            }
            animation: rotate 0.6s linear infinite;
        }
    }
`;
