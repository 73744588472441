import { Logger } from 'helper/Logger';
import React from 'react';
import { connect } from 'react-redux';
import * as PodcastHelper from '../../../../helper/PodcastHelper';
import { THEME } from '../../../../model/theme';
import {
    getUploadedFileIconUrl,
    UPLOADED_FILE_COLORS,
    UPLOADED_FILES_PODCAST_UUID,
} from '../../../../model/uploaded-files';
import * as fromPlayerActions from '../../../../redux/actions/player.actions';
import * as fromSettingsActions from '../../../../redux/actions/settings.actions';
import * as fromTracksActions from '../../../../redux/actions/tracks.actions';
import Base from './Base';

const PLAYER_STATE = {
    IDLE: 'IDLE',
    LOADING: 'LOADING',
    LOADED: 'LOADED',
    PLAYING: 'PLAYING',
    PAUSED: 'PAUSED',
    STOPPED: 'STOPPED',
    ERROR: 'ERROR',
};

class ChromeCast extends Base {
    isAvailable = false;

    // We need to manually check if available here because the custom event will
    // usually have fired well before this component exists in the DOM. We keep
    // the listener though for correctness.
    componentDidMount() {
        this.checkAvailable();
        document.addEventListener('cast-available', this.checkAvailable);
    }

    componentWillUnmount() {
        document.removeEventListener('cast-available', this.checkAvailable);
        if (this.remotePlayerController) {
            this.remotePlayerController.removeEventListener(
                window.cast.framework.RemotePlayerEventType.IS_CONNECTED_CHANGED,
                this.remoteIsConnectedChanged,
            );
            this.remotePlayerController.removeEventListener(
                window.cast.framework.RemotePlayerEventType.IS_PAUSED_CHANGED,
                this.remoteIsPausedChanged,
            );
            this.remotePlayerController.removeEventListener(
                window.cast.framework.RemotePlayerEventType.IS_MUTED_CHANGED,
                this.remoteIsMutedChanged,
            );
            this.remotePlayerController.removeEventListener(
                window.cast.framework.RemotePlayerEventType.VOLUME_LEVEL_CHANGED,
                this.remoteVolumeLevelChanged,
            );
            this.remotePlayerController.removeEventListener(
                window.cast.framework.RemotePlayerEventType.PLAYER_STATE_CHANGED,
                this.remotePlayerStateChanged,
            );
        }
    }

    componentDidUpdate(prevProps) {
        super.componentDidUpdate(prevProps);
        const { isCastConnected, recordEvent } = this.props;
        if (prevProps.isCastConnected !== isCastConnected) {
            recordEvent(
                isCastConnected ? 'chromecast_started_casting' : 'chromecast_stopped_casting',
            );
        }
    }

    checkAvailable = () => {
        if (this.isAvailable) {
            return;
        }

        // This check matches the one in the __onGCastApiAvailable callback. We need to check
        // the media field as well the RemotePlayer() constructor depends on it.
        if (
            window.chrome &&
            window.cast &&
            window.chrome.cast &&
            window.chrome.cast.media &&
            window.cast.framework
        ) {
            this.isAvailable = true;
            this.playerState = PLAYER_STATE.IDLE;
            this.remotePlayer = new window.cast.framework.RemotePlayer();
            this.remotePlayerController = new window.cast.framework.RemotePlayerController(
                this.remotePlayer,
            );

            this.remotePlayerController.addEventListener(
                window.cast.framework.RemotePlayerEventType.IS_CONNECTED_CHANGED,
                this.remoteIsConnectedChanged,
            );
            this.remotePlayerController.addEventListener(
                window.cast.framework.RemotePlayerEventType.IS_PAUSED_CHANGED,
                this.remoteIsPausedChanged,
            );
            this.remotePlayerController.addEventListener(
                window.cast.framework.RemotePlayerEventType.IS_MUTED_CHANGED,
                this.remoteIsMutedChanged,
            );
            this.remotePlayerController.addEventListener(
                window.cast.framework.RemotePlayerEventType.VOLUME_LEVEL_CHANGED,
                this.remoteVolumeLevelChanged,
            );
            this.remotePlayerController.addEventListener(
                window.cast.framework.RemotePlayerEventType.PLAYER_STATE_CHANGED,
                this.remotePlayerStateChanged,
            );

            this.remoteIsConnectedChanged();

            document.removeEventListener('cast-available', this.checkAvailable);
        }
    };

    remoteIsConnectedChanged = () => {
        this.stop();
        this.playerState = PLAYER_STATE.IDLE;
        if (this.remotePlayer.isConnected) {
            Logger.log('Chrome cast connected');
            this.castSession = window.cast.framework.CastContext.getInstance().getCurrentSession();
            this.onReady();
            this.connected();
        } else {
            Logger.log('Chrome cast disconnected');
            this.disconnected();
        }
        this.resetPlayer();
    };

    remoteIsPausedChanged = () => {
        if (this.remotePlayer.isPaused) {
            this.props.onPause();
        } else {
            this.props.onPlay();
        }
    };

    remoteIsMutedChanged = () => {
        this.props.updateMuted(this.remotePlayer.isMuted);
    };

    remoteVolumeLevelChanged = () => {
        this.props.updateVolume(this.remotePlayer.volumeLevel);
    };

    remotePlayerStateChanged = () => {
        const { upNext } = this.props;
        const { playerState } = this.remotePlayer;

        // Chromecast doesn't have a method/status for when media has finished playing...
        if (
            playerState === window.chrome.cast.media.PlayerState.IDLE &&
            this.props.playing &&
            this.remotePlayer.statusText === 'Pocket Casts'
        ) {
            this.stopTimer();
            this.props.onEnded();

            // If there are no episodes up next, then end the session since
            // the next episode that is played won't play.
            if (!(upNext.isLoaded && upNext.order.length > 1)) {
                this.castSession.endSession(true);
            }
        } else {
            const BUFFERED_STATES = [
                window.chrome.cast.media.PlayerState.BUFFERING,
                window.chrome.cast.media.PlayerState.IDLE,
            ];
            this.props.onBuffering(BUFFERED_STATES.includes(playerState));
        }
    };

    connected = () => {
        this.props.chromeCastConnected();
    };

    disconnected = () => {
        this.props.chromeCastDisconnected();
    };

    load() {
        const { episode, podcast } = this.props;
        const { url } = episode;

        this.isLoading = true;

        if (this.isUrlLoaded(url)) {
            this.loaded();
            return;
        }

        // If an episode is playing while a new episode is loading, the progress timer is still running.
        // This means progress checks will keep happening, causing bugs like setting currentTime to 0.
        // In this transitional loading state we want the Chromecast to stay in "play" mode but we want to
        // prevent the timed events from continuing, so we stop the timer.
        //
        // A longer-term solution to this would be to remove the timer behavior from the Chromecast component
        // and send progress updates when the RemotePlayerController fires CURRENT_TIME_CHANGED events:
        //
        // https://developers.google.com/cast/docs/reference/web_sender/cast.framework#.RemotePlayerEventType
        this.stopTimer();

        const fileType =
            episode.fileType || episode.contentType || episode.file_type || episode.content_type;

        let subtitle = '';
        if (episode.podcastUuid === UPLOADED_FILES_PODCAST_UUID) {
            subtitle = 'Uploaded File';
        } else if (podcast && podcast.title) {
            subtitle = podcast.title;
        }

        let images = [];
        if (episode.podcastUuid === UPLOADED_FILES_PODCAST_UUID) {
            if (episode.imageUrl) {
                images = [{ url: episode.imageUrl }];
            } else {
                images = [{ url: getUploadedFileIconUrl(THEME.dark, UPLOADED_FILE_COLORS.grey) }];
            }
        } else {
            images = [{ url: PodcastHelper.getDiscoverUrlWebp(960, podcast.uuid) }];
        }

        const mediaInfo = new window.chrome.cast.media.MediaInfo(url, fileType);
        mediaInfo.metadata = new window.chrome.cast.media.GenericMediaMetadata();
        mediaInfo.metadata.metadataType = window.chrome.cast.media.MetadataType.GENERIC;
        mediaInfo.metadata.title = episode.title;
        mediaInfo.metadata.subtitle = subtitle;
        mediaInfo.metadata.images = images;

        const request = new window.chrome.cast.media.LoadRequest(mediaInfo);
        request.autoplay = this.props.playing;
        request.currentTime = this.props.playedUpTo;

        this.castSession.loadMedia(request).then(this.loaded, errorCode => {
            this.playerState = PLAYER_STATE.ERROR;
            Logger.log(`Remote media load error: ${errorCode}`);
        });
    }

    sendMessage = ({ type, ...args }) => {
        const media = this.castSession?.getMediaSession();
        if (!media) {
            return;
        }
        // h/t https://stackoverflow.com/questions/70205119/setting-playbackrate-from-a-chromecast-websender
        this.castSession.sendMessage('urn:x-cast:com.google.cast.media', {
            ...args,
            type,
            mediaSessionId: media.mediaSessionId,
            // requestId is used to identify return calls, which we currently don't handle, so hard-coding
            // it to 1 works just fine.
            requestId: 1,
        });
    };

    isUrlLoaded = url =>
        this.castSession.getMediaSession() &&
        this.castSession.getMediaSession().media &&
        this.castSession.getMediaSession().media.contentId === url;

    loaded = () => {
        this.isLoading = false;
        const duration = this.getDuration();
        if (duration > 0) {
            this.props.onDurationChanged?.(duration);
        }
        this.playerState = PLAYER_STATE.LOADED;
        if (this.props.playing) {
            this.play();
        } else {
            this.pause();
        }
        this.setSpeed(this.props.speed);
    };

    resetPlayer() {
        super.resetPlayer();
        if (!this.props.isCastConnected) {
            this.stop();
            return;
        }
        if (this.props.url) {
            this.load();
        } else {
            this.stop();
        }
    }

    play() {
        if (!this.props.isCastConnected) {
            return;
        }
        if (this.isLoading) {
            // If the media is played while its still loading, weird bugs occur within
            // the Chromecast Remote Player / Controller. The currentTime stops updating,
            // and most of the time we lose our ability to further control the playing media
            // (play/pause/seek/etc).
            //
            // Unfortunately the parent Base class calls play() at times when the media can
            // still be loading. So we'll force it to never do so here. Longer-term, we should
            // reconsider this inheritance model, and how much it helps vs obscures and forces
            // us into this kind of workaround.
            return;
        }
        if (
            this.playerState !== PLAYER_STATE.PLAYING &&
            this.playerState !== PLAYER_STATE.PAUSED &&
            this.playerState !== PLAYER_STATE.LOADED
        ) {
            this.load();
            return;
        }

        this.playerState = PLAYER_STATE.PLAYING;
        if (this.remotePlayer.isPaused) {
            this.remotePlayerController.playOrPause();
        }
        this.startTimer();
    }

    pause() {
        if (!this.props.isCastConnected) {
            return;
        }
        this.saveProgress();
        this.stopTimer();
        if (!this.remotePlayer.isPaused) {
            this.remotePlayerController.playOrPause();
        }
    }

    stop() {
        this.stopTimer();
        this.pause();
        this.playerState = PLAYER_STATE.STOPPED;
    }

    setVolume(volume) {
        if (!this.props.isCastConnected) {
            return;
        }
        this.remotePlayer.volumeLevel = volume;
        this.remotePlayerController.setVolumeLevel();
    }

    setSpeed(speed) {
        this.sendMessage({ type: 'SET_PLAYBACK_RATE', playbackRate: speed });
    }

    // eslint-disable-next-line
    setTheme(theme) {}

    // eslint-disable-next-line
    setUpdatedArtwork(imageUrl) {}

    getSpeed() {
        const media = this.castSession?.getMediaSession();
        if (!media) {
            return 1;
        }
        return media.playbackRate || 1;
    }

    setMuted(muted) {
        if (!this.props.isCastConnected) {
            return;
        }
        if (muted !== this.remotePlayer.isMuted) {
            this.remotePlayerController.muteOrUnmute();
        }
    }

    seekTo(timeSecs) {
        if (!this.props.isCastConnected) {
            return;
        }
        this.remotePlayer.currentTime = timeSecs;
        this.remotePlayerController.seek();
    }

    getDuration = () => {
        if (!this.props.isCastConnected) {
            return null;
        }
        const { duration } = this.remotePlayer;
        return duration <= 0 ? null : duration;
    };

    getCurrentTime() {
        if (!this.props.isCastConnected) {
            return null;
        }
        return this.remotePlayer.currentTime;
    }

    // eslint-disable-next-line
    setSkipTimes(skipForward, skipBack) {}

    render() {
        return <div className="chrome-cast" />;
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
    chromeCastConnected: () => dispatch(fromSettingsActions.Actions.chromeCastConnected()),
    chromeCastDisconnected: () => dispatch(fromSettingsActions.Actions.chromeCastDisconnected()),
    recordEvent: name => dispatch(fromTracksActions.Actions.recordEvent(name)),
    updateMuted: muted => dispatch(fromPlayerActions.Actions.updateMuted(muted)),
    updateVolume: volume => dispatch(fromPlayerActions.Actions.updateVolume(volume)),
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ChromeCast);
