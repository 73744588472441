import { useMediaQuery } from 'hooks/useMedia';
import React, { useEffect, useRef } from 'react';
import { ButtonWrapper } from './AnimatedPlayButton.styled';
import { PauseButton } from './PauseButton';
import { PlayButton } from './PlayButton';
import playAnimation from './pauseplaypause.json';

// eslint-disable-next-line
const bodymovin = require('bodymovin/build/player/bodymovin_light.min');
// https://github.com/airbnb/lottie-web#usage
interface bodymovinType {
    goToAndStop: (value: number, isFrame: boolean) => void;
    playSegments: (segments: number[], forceFlag: boolean) => void;
    setSpeed: (speed: number) => void;
    destroy: () => void;
}

interface Props {
    playing: boolean;
    onPlayClicked: () => void;
    onPauseClicked: () => void;
}

const AnimatedPlayButton = ({ playing, onPlayClicked, onPauseClicked }: Props) => {
    const wrapper = useRef(null);
    const animation = useRef<bodymovinType | null>(null);
    const isFirstRender = useRef(true);

    // Storing this as an unchanging ref to avoid an edge case where switching back to motion
    // without reloading the page causes the animated playbutton to not re-render
    const allowMotion = useRef(useMediaQuery('(prefers-reduced-motion: no-preference)'));

    useEffect(() => {
        if (allowMotion.current) {
            const options = {
                wrapper: wrapper.current,
                renderer: 'svg',
                loop: false,
                autoplay: false,
                prerender: true,
                animationData: playAnimation,
            };

            animation.current = bodymovin.loadAnimation(options);

            if (animation.current) {
                animation.current.goToAndStop(playing ? 0 : 11, true);
                animation.current.setSpeed(0.8);
            }
        }

        return () => {
            if (animation.current) {
                animation.current.destroy();
            }
        };
    }, []);

    useEffect(() => {
        // Don't animate on first render, equivalent to componentDidUpdate
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }

        if (animation.current) {
            if (playing) {
                animation.current.playSegments([11, 22], true);
            } else {
                animation.current.playSegments([0, 11], true);
            }
        }
    }, [playing]);

    if (allowMotion.current) {
        const handleClick = () => {
            if (playing) {
                onPauseClicked();
            } else {
                onPlayClicked();
            }
        };

        return (
            <ButtonWrapper
                ref={wrapper}
                onClick={handleClick}
                aria-pressed={playing}
                aria-label={playing ? 'Pause' : 'Play'}
                className="play_pause_button" // For streamkeys
            />
        );
    }

    if (playing) {
        return <PauseButton onClick={onPauseClicked} aria-label="Play" />;
    }
    return <PlayButton onClick={onPlayClicked} aria-label="Pause" />;
};

export default AnimatedPlayButton;
