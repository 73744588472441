import React from 'react';
import { FormattedMessage } from 'react-intl';
import { MIN_GIFT_DAYS_FOR_LIFETIME_MEMBERSHIP } from '../model/subscription';

export const getCorrectFreeGiftMessage = (days = 0) => {
    if (days >= MIN_GIFT_DAYS_FOR_LIFETIME_MEMBERSHIP) {
        return <FormattedMessage id="pocket-casts-champion" />;
    }

    if (days === 0) {
        return '';
    }

    if (days === 1) {
        return <FormattedMessage id="one-day-free" />;
    }

    if (days < 30) {
        return (
            <FormattedMessage
                id="n-days-free"
                values={{
                    days,
                }}
            />
        );
    }

    if (days < 45) {
        return <FormattedMessage id="one-month-free" />;
    }

    if (days / 365 < 1) {
        return (
            <FormattedMessage
                id="n-months-free"
                values={{
                    months: Math.floor(days / 30),
                }}
            />
        );
    }

    if (days === 365 || days === 366 || days < 548) {
        return <FormattedMessage id="one-year-free" />;
    }

    const years = Math.floor(days / 365);
    const extraDays = days % 365;
    const yearsToReport = extraDays > 365 / 2 ? years + 1 : years;

    return (
        <FormattedMessage
            id="n-years-free"
            values={{
                years: yearsToReport,
            }}
        />
    );
};

export const getCorrectFreeTrialMessage = (days: number) => {
    if (days === 0) {
        return '';
    }

    if (days < 30) {
        return (
            <FormattedMessage
                id="n-days-free-trial"
                values={{
                    days,
                }}
            />
        );
    }

    if (days / 365 < 1) {
        let monthDuration = Math.floor(days / 30);
        if (days < 45) {
            monthDuration = 1;
        }
        return (
            <FormattedMessage
                id="n-months-free-trial"
                values={{
                    months: monthDuration,
                }}
            />
        );
    }

    const years = Math.floor(days / 365);
    const extraDays = days % 365;
    const yearsToReport = extraDays > 365 / 2 ? years + 1 : years;

    return (
        <FormattedMessage
            id="n-years-free-trial"
            values={{
                years: yearsToReport,
            }}
        />
    );
};
