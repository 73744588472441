import { useIntl } from 'react-intl';
import { ReactNode } from 'react';
import { FormatXMLElementFn, PrimitiveType } from 'intl-messageformat';
import { TranslatableStringKey } from 'translations/glotpress';

const useFormatMessage = () => {
    const intl = useIntl();

    // Overload the function signature with both options from react-intl's formatMessage signature
    function formatMessage(
        id: TranslatableStringKey,
        values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>,
    ): string;
    function formatMessage(
        id: TranslatableStringKey,
        values?: Record<string, PrimitiveType | ReactNode>,
    ): ReactNode;
    function formatMessage(
        id: TranslatableStringKey,
        values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string> | ReactNode>,
    ) {
        return intl.formatMessage({ id }, values);
    }

    return formatMessage;
};

export default useFormatMessage;
