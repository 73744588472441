import styled from 'styled-components';
import { gridSize, H40, H60, red } from '../../../../styles/index';

const dialogWidth = 480;

export const DeleteIconWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${3 * gridSize}px;
    color: ${props => props.theme.tokens['support-05']};
`;

export const Heading = styled(H40)`
    margin-bottom: ${3 * gridSize}px;
`;

export const SubtitleWrapper = styled(H60)`
    margin-bottom: ${3 * gridSize}px;
    max-width: ${dialogWidth}px;
    text-align: center;
    word-break: break-word;
`;

export const WarningWrapper = styled(H60)`
    color: ${red};
    margin-bottom: ${4 * gridSize}px;
    max-width: ${dialogWidth}px;
    text-align: center;
`;

export const Separator = styled.div`
    width: ${dialogWidth}px;
    border-bottom: 1px solid ${props => props.theme.legacyScss['theme-line']};
    margin-bottom: ${3 * gridSize}px;
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    width: ${dialogWidth}px;

    justify-content: flex-end;
    margin-bottom: ${4 * gridSize}px;
`;

export const VerticalSpace = styled.div`
    width: ${2 * gridSize}px;
`;
