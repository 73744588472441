import { SubscriptionTier } from 'model/types';
import styled from 'styled-components';
import { patronColor, plusColor } from 'styles/colors';
import { Typography, coolGrey60, gridSize, orange } from '../../../styles';

export const ProfileInformationWrapper = styled.div`
    display: flex;
    width: 320px;

    flex-direction: column;
    align-items: center;

    padding-top: ${gridSize}px;
`;

export const ProfileInformationBox = styled.div`
    display: flex;

    flex-direction: column;
    align-items: center;

    border-radius: 4px;
    box-shadow: rgba(26, 0, 0, 0.08) 0px 2px 3px, rgba(0, 0, 0, 0.05) 0px 1px 0px;
`;

export const PictureBox = styled.div<{ tier?: SubscriptionTier }>`
    height: ${20 * gridSize}px;
    width: 320px;

    display: flex;
    align-items: center;
    justify-content: center;
    background: ${props => (props.tier === 'Patron' ? patronColor : plusColor)};
    border-radius: 4px 4px 0px 0px;
`;

export const GravatarWrapper = styled.div<{ tier?: SubscriptionTier }>`
    img {
        background: ${props => (props.tier === 'Patron' ? patronColor : plusColor)};
        border-radius: 50%;
    }
`;

export const EmailBox = styled.div`
    display: flex;
    max-width: 320px;
    width: 100%;
    height: ${8 * gridSize}px;

    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-left: 1px solid ${props => props.theme.tokens['primary-ui-05']};
    border-right: 1px solid ${props => props.theme.tokens['primary-ui-05']};
    border-bottom: 1px solid ${props => props.theme.tokens['primary-ui-05']};

    padding-left: ${2 * gridSize}px;
    padding-right: ${2 * gridSize}px;
`;

export const EmailWrapper = styled.p`
    ${Typography.Desktop.H70};
    width: 100%;
    text-align: center;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

export const DetailsBox = styled.div`
    width: 320px;
    padding-left: ${2 * gridSize}px;
    padding-right: ${2 * gridSize}px;

    border-left: 1px solid ${props => props.theme.tokens['primary-ui-05']};
    border-right: 1px solid ${props => props.theme.tokens['primary-ui-05']};
    border-bottom: 1px solid ${props => props.theme.tokens['primary-ui-05']};
    border-radius: 0px 0px 4px 4px;
`;

export const LogoWrapper = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: ${gridSize}px;
    padding-top: ${2 * gridSize}px;
    padding-bottom: ${2 * gridSize}px;
    transition: transform 75ms ease-out;
    &:hover {
        transform: scale(1.03);
    }
`;

export const FreeWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const BulletWrapper = styled.span`
    ${Typography.Desktop.H70};
    color: ${coolGrey60};
`;

export const CombinedSubscriptionWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: ${2 * gridSize}px;
`;

export const PriceWrapper = styled.p`
    ${Typography.Desktop['H70-B']};
    color: ${coolGrey60};
`;

export const PlatformWrapper = styled.p`
    ${Typography.Desktop.H70};
    color: ${coolGrey60};
`;

export const CombinedDateWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: ${2 * gridSize}px;
    flex-wrap: wrap;
`;

export const NextBillingDateWrapper = styled.p`
    ${Typography.Desktop.H70};
    color: ${orange};
`;

export const DateWrapper = styled.p`
    ${Typography.Desktop['H70-B']};
    color: ${orange};
`;

export const FirstPaymentDateWrapper = styled.p`
    ${Typography.Desktop.H70};
    color: ${coolGrey60};
`;

export const PlanBadge = styled.div<{ tier?: SubscriptionTier }>`
    background: ${props => (props.tier === 'Patron' ? patronColor : 'transparent')};
    color: ${props => (props.tier === 'Patron' ? '#fff' : props.theme.tokens['primary-text-01'])};
    font-weight: 600;
    padding: ${1 * gridSize}px ${1.75 * gridSize}px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    svg {
        margin-right: ${0.75 * gridSize}px;
        color: ${props => (props.tier === 'Patron' ? '#fff' : plusColor)};
    }
`;
