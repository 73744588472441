import en from '../en.json';
import da from './pocket-casts-webplayer-da.ngx.json';
import de from './pocket-casts-webplayer-de.ngx.json';
import es from './pocket-casts-webplayer-es.ngx.json';
import fr from './pocket-casts-webplayer-fr.ngx.json';
import it from './pocket-casts-webplayer-it.ngx.json';
import ja from './pocket-casts-webplayer-ja.ngx.json';
import nl from './pocket-casts-webplayer-nl.ngx.json';
import nb from './pocket-casts-webplayer-nb.ngx.json';
import pl from './pocket-casts-webplayer-pl.ngx.json';
import ptBr from './pocket-casts-webplayer-pt-br.ngx.json';
import ru from './pocket-casts-webplayer-ru.ngx.json';
import sv from './pocket-casts-webplayer-sv.ngx.json';
import zhCn from './pocket-casts-webplayer-zh-cn.ngx.json';
import zhTw from './pocket-casts-webplayer-zh-tw.ngx.json';

export type TranslatableStringKey = keyof typeof en;
type TranslatedMessages = Record<string, string | null>;
type TranslatedMessagesMap = Record<string, TranslatedMessages>;

const translations: TranslatedMessagesMap = {
    da,
    de,
    en,
    es,
    fr,
    it,
    ja,
    nl,
    nb,
    pl,
    'pt-br': ptBr,
    ru,
    sv,
    'zh-cn': zhCn,
    'zh-tw': zhTw,
};

// GlotPress returns `null` for untranslated strings, leading to the string ID being used instead.
// This function deletes `null` and blank entries, so that untranslated strings properly fall back
// to the English translation.
const removeNulls = (translations: TranslatedMessagesMap) => {
    const trimmedTranslations = { ...translations };
    Object.keys(translations).forEach(locale => {
        Object.keys(translations[locale]).forEach(id => {
            if (!translations[locale][id]) {
                delete trimmedTranslations[locale][id];
            }
        });
    });
    return trimmedTranslations;
};

export default removeNulls(translations);
