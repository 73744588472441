import React from 'react';
import { ImageTextOptionContainer } from './SelectOption.styled';

type Props = {
    onClose?: (optionId: number | string) => void;
    onClick: (e: React.MouseEvent) => void;
    optionId: number | string;
    text: string;
    isSelected: boolean;
    color?: string;
    children?: React.ReactNode;
};

const SelectOption = (props: Props) => {
    const onOptionClick = (e: React.MouseEvent, optionId: number | string) => {
        e.stopPropagation();
        props.onClick(e);

        if (props.onClose) {
            props.onClose(optionId);
        }
    };

    const { optionId, text, isSelected, color } = props;

    const IsSelectedTextDiv = (props: Partial<Props>) => (
        <div className="option-title-no-svg" style={{ color, fontWeight: 'bold' }} {...props} />
    );

    const NotSelectedTextDiv = (props: Partial<Props>) => (
        <div className="option-title-no-svg" {...props} />
    );

    const TextDiv = isSelected ? IsSelectedTextDiv : NotSelectedTextDiv;

    return (
        <ImageTextOptionContainer key={`${optionId}`} onClick={e => onOptionClick(e, optionId)}>
            <TextDiv key={`${optionId}-${text}`}>{text}</TextDiv>
        </ImageTextOptionContainer>
    );
};

export default SelectOption;
