import React from 'react';
import { FormattedMessage } from 'react-intl';
import { USE_PATRON } from 'settings';
import { MAIL_TO_POCKET_CASTS_SUPPORT, POCKET_CASTS_PLUS } from 'model/external-links';
import { choosePlanPath, registerPath } from 'App';
import { ButtonLink } from 'components/ButtonLink';
import { Logo } from 'components/PocketcastsLogo';
import { Modal } from './LoginPage.styled';

type Props = {
    onClose: () => void;
};

const FreeAccountModal = ({ onClose }: Props) => {
    return (
        <Modal
            data-testid="freeAccountModal"
            horizontalInsetPx={42}
            onClose={onClose}
            useModernStyle
        >
            <Logo size="small" />

            <h1>
                <FormattedMessage id="modal-free-account" />
            </h1>

            <p>
                <FormattedMessage id="web-player-availability" />
                <br />
                <strong>
                    <FormattedMessage id="subscribe-now-to-get-access" />
                </strong>
            </p>

            <ButtonLink kind="plus" to={USE_PATRON ? choosePlanPath : registerPath}>
                <FormattedMessage id="subscribe-to-pocket-casts" />
            </ButtonLink>

            <ButtonLink kind="secondary" to={{ pathname: POCKET_CASTS_PLUS }} target="_blank">
                <FormattedMessage id="modal-learn-more-pcp" />
            </ButtonLink>

            <small>
                <FormattedMessage id="modal-trouble-signing-in" />
                <br />
                <a href={`${MAIL_TO_POCKET_CASTS_SUPPORT}?subject=Account%20Enquiry`}>
                    <FormattedMessage id="contact-us" />
                </a>
            </small>
        </Modal>
    );
};

export default FreeAccountModal;
