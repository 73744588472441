import React, { useEffect, useState } from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import useFormatMessage from 'hooks/useFormatMessage';
import { NavigationItems } from 'helper/NavigationHelper';
import { pauseKeyboardShortcuts, resumeKeyboardShortcuts } from 'helper/UiHelper';
import { FolderImage } from 'components/FolderImage';
import { PodcastImage } from '../../../components';
import { PodcastGridLayouts, PodcastListItem } from '../model';
import { UnplayedBadge } from '../PodcastsPage.styled';
import { AssistiveText, Grid, TileLink, TileWrapper } from './GridOfPodcasts.styled';

const SortableGrid = SortableContainer(Grid);
const DraggableTileLink = SortableHandle(TileLink);

type TileProps = {
    item: PodcastListItem;
    onItemClick: (item: PodcastListItem) => void;
};

const SortableTile = SortableElement(({ item, onItemClick }: TileProps) => {
    let title;
    let image;
    let href = '';

    if ('podcast' in item) {
        title = item.podcast.title;
        image = <PodcastImage uuid={item.podcast.uuid} title={title} />;
        href = `${NavigationItems.PODCASTS.path}/${item.podcast.uuid}`;
    } else if ('folder' in item) {
        title = item.folder.name;
        image = (
            <FolderImage
                color={item.folder.color}
                name={item.folder.name}
                podcastUuids={item.podcastUuids}
                sortType={item.folder.sortType}
            />
        );
        href = `${NavigationItems.PODCASTS.path}/folders/${item.folder.uuid}`;
    }

    return (
        <TileWrapper>
            <DraggableTileLink
                to={href}
                onClick={() => onItemClick(item)}
                aria-label={title}
                aria-describedby="list-of-podcasts-dnd-instructions"
                draggable="false" // Prevent link-dragging conflicts with react-sortable-hoc
            >
                {image}
                {item.showUnplayedBadge && <UnplayedBadge />}
            </DraggableTileLink>
        </TileWrapper>
    );
});

type Props = {
    gridLayout: PodcastGridLayouts;
    items: PodcastListItem[];
    onItemClick: (item: PodcastListItem) => void;
    onSortEnd: (oldPosition: number, newPosition: number) => void;
};

const GridOfPodcasts = ({ gridLayout, items, onItemClick, onSortEnd }: Props) => {
    const formatMessage = useFormatMessage();
    const [isSorting, setIsSorting] = useState(false);
    const [liveText, setLiveText] = useState('');

    // Make sure on unmount, we always resume keyboard shortcuts
    useEffect(() => resumeKeyboardShortcuts, []);

    return (
        <>
            <AssistiveText>
                <p id="list-of-podcasts-dnd-instructions">
                    {formatMessage('reorder-start-instructions')}
                </p>
                <p aria-live="assertive">
                    <span key={liveText}>{liveText}</span>
                </p>
            </AssistiveText>
            <SortableGrid
                axis="xy"
                pressDelay={200}
                pressThreshold={1000}
                useDragHandle={true}
                helperClass="is-dragging"
                onSortStart={({ index }) => {
                    pauseKeyboardShortcuts();
                    setIsSorting(true);
                    setLiveText(
                        `${items[index].title}, ${formatMessage('grabbed')}. ${formatMessage(
                            'current-position',

                            { position: index + 1, count: items.length },
                        )}. ${formatMessage('reorder-move-instructions')}`,
                    );
                }}
                onSortOver={({ newIndex }) => {
                    setLiveText(
                        `${formatMessage('moved')}. ${formatMessage('current-position', {
                            position: newIndex + 1,
                            count: items.length,
                        })}.`,
                    );
                }}
                onSortEnd={({ oldIndex, newIndex }) => {
                    setIsSorting(false);
                    onSortEnd(oldIndex, newIndex);
                    resumeKeyboardShortcuts();
                    if (oldIndex === newIndex) {
                        setLiveText(
                            `${items[oldIndex].title}, ${formatMessage(
                                'dropped-in-original-position',
                            )}.`,
                        );
                    } else {
                        setLiveText(
                            `${items[oldIndex].title}, ${formatMessage('dropped')}. ${formatMessage(
                                'final-position',
                                {
                                    position: newIndex + 1,
                                    count: items.length,
                                },
                            )}.`,
                        );
                    }
                }}
                isLarge={gridLayout === PodcastGridLayouts.GRID_LARGE}
                isSorting={isSorting}
            >
                {items.map((item, index) => (
                    <SortableTile
                        key={'podcast' in item ? item.podcast.uuid : item.folder.uuid}
                        index={index}
                        item={item}
                        onItemClick={onItemClick}
                    />
                ))}
            </SortableGrid>
        </>
    );
};

export default GridOfPodcasts;
