import { FilterEpisode, FilterId } from 'model/types';
import * as fromFilterActions from '../actions/filter.actions';
import * as fromPodcastActions from '../actions/podcast.actions';
import * as fromHistoryActions from '../actions/history.actions';

interface FilterState {
    loadFailed: boolean;
    showClearHistoryConfirmation: boolean;
    episodes: Partial<Record<FilterId, FilterEpisode[] | undefined>>;
}

export const INITIAL_STATE: FilterState = {
    loadFailed: false,
    episodes: {},
    showClearHistoryConfirmation: false,
};

export default (
    state: FilterState = INITIAL_STATE,
    action: fromFilterActions.Actions | fromPodcastActions.Actions | fromHistoryActions.Actions,
): FilterState => {
    switch (action.type) {
        case fromFilterActions.ActionTypes.DOWNLOAD_FILTER:
            return { ...state, loadFailed: false };
        case fromFilterActions.ActionTypes.DOWNLOAD_FILTER_FAILED:
            return { ...state, loadFailed: true };
        case fromFilterActions.ActionTypes.DOWNLOAD_FILTER_SUCCESS:
            return {
                ...state,
                episodes: { ...state.episodes, [action.payload.id]: action.payload.episodes },
            };
        case fromHistoryActions.ActionTypes.HISTORY_DELETE:
            if (state.episodes.history) {
                const history = state.episodes.history.filter(
                    episode => episode.uuid !== action.payload.episode.uuid,
                );
                return {
                    ...state,
                    episodes: {
                        ...state.episodes,
                        history,
                    },
                };
            }

            return state;
        case fromHistoryActions.ActionTypes.HISTORY_CLEAR_CONFIRMATION_SHOW:
            return { ...state, showClearHistoryConfirmation: true };
        case fromHistoryActions.ActionTypes.HISTORY_CLEAR_CONFIRMATION_HIDE:
            return { ...state, showClearHistoryConfirmation: false };
        case fromHistoryActions.ActionTypes.HISTORY_CLEAR:
            return { ...state, episodes: { ...state.episodes, history: [] } };
        case fromPodcastActions.ActionTypes.STAR_EPISODE:
            if (!action.payload.starred && state.episodes.starred) {
                // remove the episode
                const starred = state.episodes.starred.filter(
                    episode => episode.uuid !== action.payload.episodeUuid,
                );
                return {
                    ...state,
                    episodes: {
                        ...state.episodes,
                        starred,
                    },
                };
            }
            return state;
        case fromFilterActions.ActionTypes.CLEAR_FILTER:
            return { ...INITIAL_STATE };
        default:
            return state;
    }
};
