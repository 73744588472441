import { rgba } from 'polished';
import styled, { css } from 'styled-components';
import { coolGrey80, plusColor } from 'styles/colors';
import { ModernStyle } from 'styles/ModernStyle.styled';
import {
    blue,
    blue20,
    blue30,
    blue40,
    coolGrey70,
    red,
    red20,
    red30,
    red40,
    white,
} from '../../styles';

export const Content = styled.span``;

export const LoadingIcon = styled.div`
    width: 24px;
    height: 24px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    svg {
        @keyframes rotate {
            to {
                transform: rotate(360deg);
            }
        }
        animation: rotate 0.6s linear infinite;
    }
`;

const primaryHover = blue40;
const primaryActive = blue30;

const plusMixin = css<{ disabled: boolean }>`
    background: ${plusColor};
    color: ${coolGrey80};
    font-weight: 500;

    &:disabled {
        opacity: 0.5;
    }
    &:not([disabled]) {
        &:hover,
        &:focus {
            box-shadow: 0px 1px 2.5px rgba(0, 0, 0, 0.25);
        }
        &:active {
            box-shadow: inset 0 0 10px #feb525;
        }
    }
`;

const primaryMixin = css<{ disabled: boolean }>`
    color: ${props => props.theme.tokens['primary-ui-01']};
    font-weight: 500;

    background-color: ${props =>
        props.disabled
            ? rgba(props.theme.tokens['primary-ui-02-selected'], 0.24)
            : props.theme.tokens['primary-ui-02-selected']};

    color: ${props =>
        props.disabled
            ? rgba(props.theme.tokens['primary-ui-01'], 0.7)
            : props.theme.tokens['primary-ui-01']};

    border: 1px solid
        ${props => (props.disabled ? '' : props.theme.tokens['primary-ui-02-selected'])};

    &:hover {
        ${props => (props.disabled ? '' : `background-color: ${primaryHover}`)};
        ${props => (props.disabled ? '' : `border: 1px solid ${primaryHover}`)};
        ${props => (props.disabled ? '' : 'box-shadow: 0px 1px 2.5px rgba(0, 0, 0, 0.25)')};
    }

    &:active {
        ${props => (props.disabled ? '' : `background-color: ${primaryActive}`)};
        ${props => (props.disabled ? '' : `border: 1px solid ${primaryActive}`)};
        ${props => (props.disabled ? '' : 'box-shadow: none')};
    }

    ${ModernStyle} && {
        background: ${props => props.theme.tokens['primary-text-01']};
        border: none;
        &:disabled {
            background: ${props => rgba(props.theme.tokens['primary-text-01'], 0.3)};
        }
        &:not([disabled]) {
            &:hover,
            &:focus {
                box-shadow: 0px 1px 2.5px rgba(0, 0, 0, 0.25);
                background: ${props => rgba(props.theme.tokens['primary-text-01'], 0.9)};
            }
            &:active {
                box-shadow: none;
            }
        }
    }
`;

const primaryRedStandard = red;
const primaryRedHover = red40;
const primaryRedActive = red30;
const primaryRedDisabled = red20;

const primaryRedMixin = css<{ disabled: boolean }>`
    color: ${white};
    font-weight: 500;

    background-color: ${props => (props.disabled ? primaryRedDisabled : primaryRedStandard)};
    border: 1px solid ${props => (props.disabled ? primaryRedDisabled : primaryRedStandard)};

    &:hover {
        ${props => (props.disabled ? '' : `background-color: ${primaryRedHover}`)};
        ${props => (props.disabled ? '' : `border: 1px solid ${primaryRedHover}`)};
        ${props => (props.disabled ? '' : 'box-shadow: 0px 1px 2.5px rgba(0, 0, 0, 0.25)')};
    }

    &:active {
        ${props => (props.disabled ? '' : `background-color: ${primaryRedActive}`)};
        ${props => (props.disabled ? '' : `border: 1px solid ${primaryRedActive}`)};
        ${props => (props.disabled ? '' : 'box-shadow: none')};
    }
`;

const secondaryLightActive = '#d2f1f9';
const secondaryLightDisabled = blue20;
const secondaryLightDisabledText = '#b3e5fa';
const secondaryLightDisabledBorder = '#bfe7f9';

const secondaryMixinLight = css<{ disabled: boolean; kind: string }>`
    color: ${props =>
        props.disabled
            ? secondaryLightDisabledText
            : props.kind === 'secondary-muted'
            ? coolGrey70
            : blue};

    font-weight: 400;

    border: 1px solid
        ${props =>
            props.disabled
                ? secondaryLightDisabledBorder
                : props.kind === 'secondary-muted'
                ? coolGrey70
                : blue};

    background-color: ${props => (props.disabled ? secondaryLightDisabled : 'transparent')};

    &:hover {
        ${props => (props.disabled ? '' : `color: ${blue40}`)};
        ${props => (props.disabled ? '' : `border: 1px solid ${blue40}`)};
        ${props => (props.disabled ? '' : 'box-shadow: 0px 1px 2.5px rgba(0, 0, 0, 0.25)')};
    }

    &:active {
        ${props => (props.disabled ? '' : `background-color: ${secondaryLightActive}`)};
        ${props => (props.disabled ? '' : `border: 1px solid ${blue40}`)};
        ${props => (props.disabled ? '' : 'box-shadow: none')};
    }
`;

const secondaryDarkActive = 'rgba(80, 208, 241, 0.24)';
const secondaryDarkDisabled = 'rgba(0, 45, 89, 0.24)';
const secondaryDarkDisabledText = 'rgba(3, 169, 244, 0.25)';
const secondaryDarkDisabledBorder = 'rgba(3, 169, 244, 0.24)';

const secondaryMixinDark = css<{ disabled: boolean; kind: string }>`
    color: ${props =>
        props.disabled
            ? secondaryDarkDisabledText
            : props.kind === 'secondary-muted'
            ? coolGrey70
            : blue};

    font-weight: 400;

    border: 1px solid
        ${props =>
            props.disabled
                ? secondaryDarkDisabledBorder
                : props.kind === 'secondary-muted'
                ? coolGrey70
                : blue};

    background-color: ${props => (props.disabled ? secondaryDarkDisabled : 'transparent')};

    &:hover {
        ${props => (props.disabled ? '' : `color: ${blue40}`)};
        ${props => (props.disabled ? '' : `border: 1px solid ${blue40}`)};
        ${props => (props.disabled ? '' : 'box-shadow: 0px 1px 2.5px rgba(0, 0, 0, 0.25)')};
    }

    &:active {
        ${props => (props.disabled ? '' : `color: ${blue40}`)};
        ${props => (props.disabled ? '' : `background-color: ${secondaryDarkActive}`)};
        ${props => (props.disabled ? '' : `border: 1px solid ${blue40}`)};
        ${props => (props.disabled ? '' : 'box-shadow: none')};
    }
`;

const secondaryMixin = css<{ kind: string; disabled: boolean }>`
    ${props => (props.theme.name === 'dark' ? secondaryMixinDark : secondaryMixinLight)}
    ${ModernStyle} && {
        border: 1px solid ${props => props.theme.modernStyle.border};
        color: ${props => props.theme.tokens['primary-text-01']};

        &:disabled {
            background: inherit;
            opacity: 0.3;
        }
        &:active {
            background: inherit;
        }
    }
`;

export const buttonMixin = css<{ disabled: boolean; kind: string; width?: number; height: number }>`
    border-radius: 10px;
    transition: all 0.1s ease;

    ${props => (props.height ? `height: ${props.height}px` : '')};
    ${props => (props.height ? `line-height: ${props.height}px` : '')};

    text-align: center;
    font-size: 14.4px;
    font-weight: 500;

    cursor: ${props => (props.disabled ? 'initial' : 'pointer')};

    ${props => (props.width ? `width: ${props.width}px` : '')};
    ${props => (props.width ? `min-width: ${props.width}px` : '')};

    ${props => (props.kind === 'plus' ? plusMixin : '')};
    ${props => (props.kind === 'primary' ? primaryMixin : '')};
    ${props => (props.kind === 'primary-red' ? primaryRedMixin : '')};
    ${props => (props.kind === 'secondary' ? secondaryMixin : '')};
    ${props => (props.kind === 'secondary-muted' ? secondaryMixin : '')}

    ${ModernStyle} && {
        font-weight: 600;
        font-size: 16px;
        width: ${props => (props.width ? `${props.width}px` : '100%')};
        line-height: 1em;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

export const ButtonWrapper = styled.button<{
    disabled: boolean;
    kind: string;
    isLoading: boolean;
    width?: number;
    height: number;
}>`
    ${buttonMixin}

    position: relative;
    ${props =>
        props.isLoading
            ? css`
                  ${Content} {
                      visibility: hidden;
                  }
              `
            : css`
                  ${LoadingIcon} {
                      visibility: hidden;
                  }
              `}
`;

export const TextButton = styled.button`
    ${ModernStyle} && {
        text-decoration: underline;
        &:hover,
        &:focus {
            text-decoration: none;
        }
    }
`;
