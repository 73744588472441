import styled from 'styled-components';
import { gridSize } from '../../styles';

export const NavBarWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    position: relative;

    height: ${8 * gridSize}px;
    width: 100%;

    min-height: ${8 * gridSize}px;
    max-height: ${8 * gridSize}px;

    padding-bottom: 8px;
    padding-top: 8px;
    border-bottom: 1px solid ${props => props.theme.legacyScss['theme-line']};
`;

export const SearchWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    position: relative;
    height: ${8 * gridSize}px;

    margin-right: ${2 * gridSize}px;

    flex-grow: 1;
`;

export const ProfileWrapper = styled.div`
    display: flex;
    flex-direction: column;

    justify-content: flex-start;
    flex-grow: 0;

    cursor: pointer;
`;

export const ProfileIconWrapper = styled.button`
    display: flex;
    width: 30px;
    height: 30px;

    align-items: center;
`;

export const RoundedGravatar = styled.svg`
    image {
        clip-path: inset(0% round 50%);
        border: 1px solid red;
    }
`;
