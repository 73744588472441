import { EpisodeShowNotes } from 'model/types';
import { Action } from 'redux/actions';

type EpisodeShowNotesState = Record<string, EpisodeShowNotes>;

export const INITIAL_STATE: EpisodeShowNotesState = {};

export default (
    state: EpisodeShowNotesState = INITIAL_STATE,
    action: Action,
): EpisodeShowNotesState => {
    switch (action.type) {
        case 'PODCAST_FETCH_SHOW_NOTES_SUCCEEDED':
            return action.payload.episodeShowNotes.reduce(
                (newState, episode) => ({ ...newState, [episode.uuid]: episode }),
                state,
            );
        default:
            return state;
    }
};
