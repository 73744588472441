import { SubscriptionPlatform } from './types';

// These strings are shown in the UI, so they must be human-readable.
export const platformsById = (platform: SubscriptionPlatform) => {
    switch (platform) {
        case SubscriptionPlatform.none:
            return 'none';
        case SubscriptionPlatform.iOS:
            return 'iOS';
        case SubscriptionPlatform.gift:
            return 'gift';
        case SubscriptionPlatform.Android:
            return 'Android';
        case SubscriptionPlatform.Paddle:
            return 'Paddle';
        default:
            return 'none';
    }
};

export const MAX_DAYS_FOR_EXPIRY_COUNTDOWN = 30;

export const MIN_GIFT_DAYS_FOR_LIFETIME_MEMBERSHIP = 3650;

export const DELAY_MS_FOR_CANCEL_SUBSCRIPTION = 1800;
