import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useFormatMessage from 'hooks/useFormatMessage';
import {
    ContentRegionSelectorWrapper,
    ContentRegionHeading,
    SelectInputCard,
    CountryFlag,
    CountryTitle,
} from './ContentRegionSelector.styled';
import { getDiscoverRegion } from '../../../redux/reducers/selectors';
import * as fromModalActions from '../../../redux/actions/modal.actions';
import { ModalTypes } from '../../../helper/UiHelper';

function ContentRegionSelector() {
    const formatMessage = useFormatMessage();
    const dispatch = useDispatch();
    const { name, flag } = useSelector(state => {
        const region = getDiscoverRegion(state) || {};
        return {
            name: region.name || '',
            flag: region.flag || 'https://static.pocketcasts.net/discover/flags/global.png',
        };
    });

    const onChangeRegionClicked = () => {
        dispatch(fromModalActions.Actions.showModal(ModalTypes.contentRegionSelection));
    };

    return (
        <ContentRegionSelectorWrapper>
            <ContentRegionHeading>{formatMessage('discover-select-region')}</ContentRegionHeading>
            <SelectInputCard aria-haspopup="true" onClick={onChangeRegionClicked}>
                <CountryFlag alt="" src={flag} />
                <CountryTitle>{name}</CountryTitle>
            </SelectInputCard>
        </ContentRegionSelectorWrapper>
    );
}

export default ContentRegionSelector;
