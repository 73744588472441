import { isInfoOfferAvailableForCountry } from 'helper/PlansHelper';
import { useSelector } from 'hooks/react-redux-typed';
import {
    PaddleCheckoutResponse,
    PaddleDiscountedPlan,
    PaddlePlan,
    PaddleResponse,
    PurchaseWebPlayerFailureActionPayload,
    RequestPurchaseActionPayload,
} from 'model/types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getEmail, getPaddleSKUs } from 'redux/reducers/selectors';
import { getAccessToken } from 'services/auth';
import { openPaddleCheckout } from '../helper/PaddleCheckoutHelper';
import * as fromTracksActions from '../redux/actions/tracks.actions';
import * as fromUserActions from '../redux/actions/user.actions';
import { USE_50_PERCENT_BY_DEFAULT } from '../settings';
import { useGeolocation } from './useGeolocation';
import { usePaddleProducts } from './usePaddleProducts';

type Props = {
    plan: PaddlePlan | PaddleDiscountedPlan;
    code?: string;
    onPaymentSuccess?: () => void;
    onPaymentFailure?: () => void;
    onPaymentCancel?: () => void;
};

const couponsByPlan: Partial<Record<PaddlePlan | PaddleDiscountedPlan, string>> = {
    'switch-to-pocket-casts': 'SWITCHTOPC',
};

/**
 * @param plan - The plan to sign up for
 */
export const usePaddle = ({
    plan,
    code,
    onPaymentSuccess,
    onPaymentFailure,
    onPaymentCancel,
}: Props) => {
    const dispatch = useDispatch();
    const accountEmail = useSelector(getEmail);
    const paddleSkus = useSelector(getPaddleSKUs);
    const paddleProducts = usePaddleProducts({ code });
    const { countryCode } = useGeolocation();
    const [offerIntroDiscount, setOfferIntroDiscount] = useState(false);
    const product = paddleProducts[plan];

    useEffect(() => {
        const offerAvailable = isInfoOfferAvailableForCountry(countryCode);
        setOfferIntroDiscount(offerAvailable);
        if (offerAvailable && USE_50_PERCENT_BY_DEFAULT) {
            couponsByPlan['plus-yearly'] = '50OFFINTRO';
        }
    }, [countryCode]);

    // Paddle adds their own viewport meta tag when the Overlay Checkout opens on some mobile browsers,
    // which breaks the layout. This hook watches for it to be added and removes it.
    // Ref: https://stackoverflow.com/questions/76280117/prevent-safari-from-zoom-after-interact-with-iframe/76305022#76305022
    useEffect(() => {
        const observer = new MutationObserver(mutationsList => {
            for (const mutation of mutationsList) {
                if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
                    document.querySelector('[id="__mobileViewportControl_hook__"]')?.remove();
                }
            }
        });
        const head = document.querySelector('head');
        if (head) {
            observer.observe(head, { childList: true });
        }
        return () => {
            observer.disconnect();
        };
    }, []);

    const getOfferType = (plan: string, trialDays: number, coupon?: string): string => {
        if (!offerIntroDiscount) {
            return '';
        }

        if (USE_50_PERCENT_BY_DEFAULT && plan === 'plus-yearly' && coupon === '50OFFINTRO') {
            return 'intro_offer';
        }

        if (trialDays > 0) {
            return 'free_trial';
        }

        return '';
    };

    const paymentSuccess = (payload: RequestPurchaseActionPayload) => {
        dispatch(fromUserActions.Actions.requestPurchaseWebPlayer(payload));
        onPaymentSuccess?.();
    };

    const paymentFailure = (payload: PurchaseWebPlayerFailureActionPayload) => {
        dispatch(fromUserActions.Actions.purchaseWebPlayerFailure(payload));
        onPaymentFailure?.();
    };

    const paymentCancelled = () => {
        const trialDays = product?.subscription.trial_days ?? 0;

        dispatch(
            fromTracksActions.Actions.recordEvent('purchase_cancelled', {
                product: plan,
                offer_type: getOfferType(plan, trialDays, couponsByPlan[plan]),
                // TODO [https://github.com/Automattic/pocket-casts-webplayer/issues/2466]: remove this once we've updated our reports to only rely on offer_type
                is_free_trial: trialDays > 0,
            }),
        );
        onPaymentCancel?.();
    };

    const doSubscribeFlow = async (additionalFields = {}) => {
        const accountToken = await getAccessToken();
        openPaddleCheckout({
            accountEmail,
            accountToken,
            paddleSku: paddleSkus[plan],
            coupon: code ?? couponsByPlan[plan],
            closeCallback: paymentCancelled,
            successCallback: (data: PaddleResponse) => {
                const { checkoutResponse, orderResponse } = data;
                paymentSuccess({
                    plan,
                    offerType: getOfferType(
                        plan,
                        checkoutResponse.checkout.prices.customer.items?.[0].recurring.trial_days,
                        couponsByPlan[plan],
                    ),
                    trialDays:
                        checkoutResponse.checkout.prices.customer.items?.[0].recurring.trial_days,
                    transactionId: checkoutResponse.checkout.id,
                    email: checkoutResponse.user.email,
                    productId: checkoutResponse.product.id,
                    subscriptionId: orderResponse.order.subscription_id,
                    ...additionalFields,
                });
            },
            paymentFailedCallback: (checkoutResponse: PaddleCheckoutResponse) => {
                paymentFailure({
                    plan,
                    offerType: getOfferType(
                        plan,
                        checkoutResponse.checkout.prices.customer.items?.[0].recurring.trial_days,
                        couponsByPlan[plan],
                    ),
                    trialDays:
                        checkoutResponse.checkout.prices.customer.items?.[0].recurring.trial_days,
                    error: 'paddle-failure',
                });
            },
        });
    };

    return {
        product,
        doSubscribeFlow,
    };
};
