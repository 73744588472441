import styled from 'styled-components';
import { fontFamilyRetinaNarrow } from '../../../styles/typography';

export const StyledArrowButton = styled.button<{
    accent: string;
    fontSize: number;
    height: number;
    width: number;
}>`
    cursor: pointer;
    letter-spacing: -0.17px;
    vertical-align: middle;
    user-select: none;
    display: flex;
    color: ${props => props.accent};
    span {
        font-size: ${props => props.fontSize}px;
        font-weight: 500;
        font-family: ${fontFamilyRetinaNarrow};
        display: inline-block;
        line-height: ${props => props.height}px;
        text-align: right;
    }
    .arrow {
        display: inline-block;
        margin-left: 5px;
    }
    &:hover {
        .arrow {
            animation: see-all-arrow 1 400ms ease-in-out;
            animation-direction: alternate;
        }
    }
    @keyframes see-all-arrow {
        0% {
            transform: translate(0px, 0px);
        }
        50% {
            transform: translate(4px, 0);
        }
        100% {
            transform: translate(0px, 0px);
        }
    }
`;
