import styled from 'styled-components';
import { H40, H70 } from '../../../styles';

export const DialogIcon = styled.img`
    width: 112px;
    height: 112px;
    margin-top: 32px;
    margin-bottom: 11px;
`;

/* TODO: Design/typography needs to be updated to match style guide made by ux across the board */
export const DialogTitle = styled(H40)`
    font-family: Retina;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
`;

export const DialogDescription = styled(H70)`
    padding-top: 20px;
    padding-left: 40px;
    padding-right: 40px;

    width: 450px;
`;

export const DialogContent = styled.div`
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
`;

export const DialogText = styled.div`
    margin: 10px 50px 40px 50px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const ModalDialog = styled.div`
    display: flex;
    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 515px;
    background-color: white;

    padding: 30px;
    border-radius: 8px;

    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1002;
`;

export const GetPlusButton = styled.div``;

export const ReturnToPromoButton = styled.div`
    margin-top: 24px;
    margin-bottom: 32px;
`;
