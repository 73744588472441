import React from 'react';
import { DurationStringFromSeconds } from '../../helper/DurationHelper';

type Props = {
    durationSecs: number | null;
    playedUpToSecs: number | null;
    playingStatus: number;
    format?: 'short' | 'long';
};

function DurationText({
    durationSecs = null,
    playedUpToSecs = null,
    playingStatus = 1,
    format = 'short',
}: Props) {
    const duration = formatDuration(durationSecs, playedUpToSecs as number, playingStatus, format);
    return <span className="duration-text">{duration}</span>;
}

function formatDuration(
    duration: number | null,
    playedUpTo: number,
    playingStatus: number,
    format: 'short' | 'long',
) {
    if (!duration) {
        return '';
    }

    if (playedUpTo && playedUpTo > 0 && playingStatus !== 3) {
        const timeLeft = Math.round(duration - playedUpTo);
        if (timeLeft > 0) {
            if (format === 'short') {
                return convertTimeShort(timeLeft);
            }

            return (
                <DurationStringFromSeconds
                    durationSecsStrOrNum={timeLeft}
                    remaining={true}
                    short={false}
                />
            );
        }
    }

    if (format === 'short') {
        return convertTimeShort(duration);
    }

    return convertTimeLong(duration);
}

function convertTimeShort(time: number) {
    return <DurationStringFromSeconds durationSecsStrOrNum={time} remaining={false} short={true} />;
}

function convertTimeLong(time: number) {
    return (
        <DurationStringFromSeconds durationSecsStrOrNum={time} remaining={false} short={false} />
    );
}

export default DurationText;
