import React, { useState } from 'react';
import {
    TextWithReadMoreContainer,
    TextContentWrapper,
    TextContent,
    MoreButton,
} from './TextWithReadMore.styled';
import { blue50 } from '../../styles';

type Props = {
    text: string;
    lines: number;
    className?: string;
    color?: string;
    onExpand?: () => void;
};

export function TextWithReadMore({ text, lines, className, color = blue50, onExpand }: Props) {
    const [maxLines, setMaxLines] = useState(lines);

    const onReadMoreClicked = () => {
        setMaxLines(12);
        onExpand?.();
    };

    return (
        <TextWithReadMoreContainer className={className}>
            <TextContentWrapper>
                <TextContent
                    overflowNode={
                        <MoreButton color={color} onClick={onReadMoreClicked}>
                            ...<span>Read More</span>
                        </MoreButton>
                    }
                    lines={maxLines}
                >
                    {text}
                </TextContent>
            </TextContentWrapper>
        </TextWithReadMoreContainer>
    );
}

export default TextWithReadMore;
