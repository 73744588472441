import styled from 'styled-components';

export const DynamicLoadingCircleWrapper = styled.div<{ $size: number }>`
    @keyframes rotation {
        from {
            rransform: rotate(0deg);
        }
        to {
            transform: rotate(359deg);
        }
    }

    display: flex;
    align-items: center;

    width: ${props => props.$size};
    height: ${props => props.$size};

    animation: rotation 2s infinite linear;
`;
