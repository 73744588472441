import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import UploadManagerDetail from './view';

UploadManagerDetail.propTypes = {
    collapsed: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    fileUuids: state.uploadManager.filesOrder,
    files: state.uploadManager.files,
});

export default connect(mapStateToProps)(UploadManagerDetail);
