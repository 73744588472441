import { connect, RootStateOrAny } from 'react-redux';
import { Dispatch } from 'redux';
import * as fromUserActions from '../../redux/actions/user.actions';
import PasswordResetPage from './PasswordResetPage';

const mapStateToProps = (state: RootStateOrAny) => ({
    requestInProgress: state.user.passwordResetResult.inProgress,
    requestSucceeded: state.user.passwordResetResult.success,
    responseMessage: state.user.passwordResetResult.message,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    requestResetPassword: (newPassword: string, token: string) =>
        dispatch(fromUserActions.Actions.requestResetPassword(newPassword, token)),
});

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(PasswordResetPage);
export { ConnectedComponent as PasswordResetPage };
