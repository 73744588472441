import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { H40, H60, blue, blue40, gridSize } from '../../../styles';

export const PodcastsEmptyStateWrapper = styled.div`
    margin: ${5 * gridSize}px auto 0 auto;
`;

export const NoPodcastsHeading = styled(H40)`
    text-align: center;
    margin-bottom: ${gridSize}px;
`;

export const NoPodcastsDescription = styled(H60)`
    width: 480px;

    color: ${props => props.theme.legacyScss['theme-text-secondary']};
    margin: 0 auto;
    margin-bottom: ${3 * gridSize}px;
    text-align: center;
`;

export const GoToDiscoverWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const DiscoverLink = styled(Link)`
    border-radius: 10px;
    text-align: center;
    font-size: 14.4px;
    font-weight: 500;
    text-decoration: none;
    height: 48px;
    line-height: 48px;
    min-width: 200px;

    background-color: ${blue};
    border: 1px solid ${blue};
    color: ${props => props.theme.tokens['primary-ui-01']};

    &:hover {
        background-color: ${blue40};
        border: 1px solid ${blue40};
        box-shadow: 0px 1px 2.5px rgba(0, 0, 0, 0.25);
    }
`;
