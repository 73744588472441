import React, { ChangeEventHandler } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { gridSize } from 'styles';
import { Bookmark } from 'model/types';
import useFormatMessage from 'hooks/useFormatMessage';
import { editBookmark } from 'redux/actions/episode-sync.actions';
import { Button } from 'components/Button';
import Input from 'components/Input';

export type Props = {
    bookmark: Bookmark;
    onSubmit?: () => void;
};

const Form = styled.form`
    button {
        width: 100%;
        margin-top: ${gridSize}px;
    }
`;

const EditBookmarkForm = ({ bookmark, onSubmit }: Props) => {
    const dispatch = useDispatch();
    const formatMessage = useFormatMessage();
    const [title, setTitle] = React.useState(bookmark.title);

    const handleTitleChange: ChangeEventHandler<HTMLInputElement> = e => {
        setTitle(e.target.value);
    };

    const handleSubmit = () => {
        dispatch(editBookmark({ ...bookmark, title }));
        onSubmit?.();
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Input type="text" value={title} onChange={handleTitleChange} />
            <Button kind="primary" type="submit">
                {formatMessage('change-title')}
            </Button>
        </Form>
    );
};

export default EditBookmarkForm;
