import { useCallback, useEffect, useRef, useState } from 'react';

interface Props {
    audioUrl: string;
    startTime: number;
    endTime: number;
    duration: number;
    type?: 'view' | 'edit';
}

export const useAudioControl = (props: Props) => {
    const { audioUrl, startTime, endTime, duration, type = 'view' } = props;
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const [isClipPlaying, setIsClipPlaying] = useState(false);

    const calculateCurrentPlayPosition = useCallback(
        (currentTime: number) => {
            if (type === 'view') {
                return ((currentTime - startTime) / (endTime - startTime)) * 100;
            }
            return (currentTime / duration) * 100;
        },
        [type, startTime, endTime, duration],
    );

    const [playPosition, setPlayPosition] = useState(calculateCurrentPlayPosition(startTime));

    useEffect(() => {
        audioRef.current = new Audio();
        return () => {
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current = null;
            }
        };
    }, []);

    const handlePlayClicked = useCallback(() => {
        if (audioRef.current) {
            audioRef.current.src = `${audioUrl}#t=${startTime},${endTime}`;
            audioRef.current.play();
            audioRef.current.ontimeupdate = () => {
                if (!audioRef.current) return;
                const { currentTime } = audioRef.current;
                if (currentTime >= endTime) {
                    setIsClipPlaying(false);
                    setPlayPosition(calculateCurrentPlayPosition(startTime));
                    audioRef.current.pause();
                } else if (currentTime < startTime) {
                    setPlayPosition(calculateCurrentPlayPosition(startTime));
                } else {
                    setPlayPosition(calculateCurrentPlayPosition(currentTime));
                }
            };
        }
        setIsClipPlaying(true);
    }, [audioUrl, startTime, endTime, calculateCurrentPlayPosition]);

    const handlePauseClicked = useCallback(() => {
        if (audioRef.current) {
            audioRef.current.pause();
        }
        setIsClipPlaying(false);
        setPlayPosition(calculateCurrentPlayPosition(startTime));
    }, [startTime, calculateCurrentPlayPosition]);

    return { isClipPlaying, handlePlayClicked, handlePauseClicked, playPosition };
};
