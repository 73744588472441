import styled from 'styled-components';
import { gridSize } from 'styles';
import { ModernStyle, defaultFontStack } from 'styles/ModernStyle.styled';
import { SubscribeButton as SubscribeButtonComponent } from 'components/buttons/SubscribeButton';

const maxWidth = 560;

export const Wrapper = styled(ModernStyle)`
    display: flex;
    flex-direction: column;
    height: 100vh;
    // Fix for mobile Safari: https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/
    height: -webkit-fill-available;
    width: 100vw;
    color: ${props => props.theme.tokens['primary-text-01']};
`;

export const ContentWrapper = styled.div`
    overflow-y: scroll;
    flex: 1;
    width: 100%;
    display: flex;
    padding: 0 ${3 * gridSize}px;
`;

export const Content = styled.div`
    width: 100%;
    max-width: ${maxWidth}px;
    margin: auto;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    line-height: 1.33;
`;

export const Footer = styled.div`
    display: flex;
    width: 100%;
    border-top: 1px solid ${props => props.theme.legacyScss['theme-line']};
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.16);
    padding: ${2 * gridSize}px ${3 * gridSize}px;
`;

export const FooterButton = styled.div`
    width: 100%;
    max-width: ${maxWidth}px;
    margin: auto;
`;

export const ThankYouWrapper = styled.div`
    flex: 1;
    margin: auto;
    display: flex;
    flex-direction: column;
    padding-bottom: ${2 * gridSize}px;
    > :last-child {
        margin-top: auto;
    }
    @media (min-width: 600px) {
        flex: 0;
        padding-bottom: ${6 * gridSize}px;
    }
`;
export const ThankYouHeading = styled.div`
    text-align: center;
    margin: ${3 * gridSize}px auto;
    max-width: 25em;
    h1 {
        font-size: 24px;
        font-weight: 700;
        margin: ${2 * gridSize}px 0 ${gridSize}px;
    }
`;

export const Bubble = styled.section`
    border: 1px solid ${props => props.theme.legacyScss['theme-line']};
    border-radius: 12px;
    padding: ${2 * gridSize}px ${3 * gridSize}px;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: ${3 * gridSize}px;
    h2 {
        font-size: 18px;
        font-weight: 600;
        margin: 0 0 ${0.5 * gridSize}px;
    }
`;

export const BubbleLink = styled.a`
    display: block;
    margin: ${2 * gridSize}px ${-3 * gridSize}px ${-2 * gridSize}px;
    padding: ${2 * gridSize}px ${3 * gridSize}px;
    border-top: 1px solid ${props => props.theme.legacyScss['theme-line']};
    text-decoration: none;
    color: ${props => props.theme.tokens['primary-text-01']};
    font-weight: 500;
    display: flex;
    text-decoration: none !important;
    &:hover,
    &:focus {
        background: ${props => props.theme.legacyScss['theme-background-hover']};
    }
`;

export const QRCodeWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
        width: 120px;
        margin-top: ${2 * gridSize}px;
    }
    @media (min-width: 500px) {
        flex-direction: row;
        img {
            margin: 0 0 0 ${2 * gridSize}px;
        }
    }
`;

export const NewsletterText = styled.div`
    h2 {
        font-size: 16px;
        font-weight: 500;
        margin: 0 0 ${0.5 * gridSize}px;
    }
    font-size: 14px;
`;

export const DiscoverHeading = styled(ThankYouHeading)`
    margin-top: ${5 * gridSize}px;
    p {
        margin: 0;
    }
`;

export const DiscoverLayoutWrapper = styled.section`
    margin: ${2 * gridSize}px 0;
    > h2 {
        font-size: 24px;
        font-weight: 700;
        line-height: 1.2;
        margin: 0 0 ${3 * gridSize}px;
    }

    .slick-list {
        margin: 0 -${gridSize / 2}px ${2 * gridSize}px;
    }
`;

export const SubscribeButton = styled(SubscribeButtonComponent)``;

export const DiscoverPodcast = styled.div`
    padding: 0 ${gridSize / 2}px;
    position: relative;
    button {
        width: 100%;
        text-align: left;
    }
    h3 {
        margin: ${gridSize}px 0 0;
        font-family: ${defaultFontStack} !important;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.2;
        -webkit-line-clamp: 2;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
    }
    ${SubscribeButton} {
        position: absolute;
        right: 0;
        transform: translate(-${gridSize}px, calc(-100% - ${gridSize / 2}px));
    }
`;

export const PodcastModal = styled.div`
    width: 92vw;
    max-width: 480px;
    padding: ${3 * gridSize}px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4;
    p {
        margin: ${2 * gridSize}px 0 0;
    }
`;

export const PodcastModalHeader = styled.div`
    display: grid;
    align-items: center;
    grid-template-columns: 100px auto;
    gap: ${2 * gridSize}px;
    margin-bottom: ${2 * gridSize}px;

    font-weight: 600;
    color: ${props => props.theme.tokens['primary-text-02']};

    h1 {
        color: ${props => props.theme.tokens['primary-text-01']};
        margin: 0 0 ${0.5 * gridSize}px;
        font-size: 20px;
        line-height: 1.2;
        font-weight: 700;
        -webkit-line-clamp: 3;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
    }
`;

export const SearchInput = styled.div`
    margin: 0 ${-1 * gridSize}px;
    padding: ${2 * gridSize}px ${gridSize}px;
    position: sticky;
    top: 0;
    z-index: 100;
    background: linear-gradient(
        0,
        transparent,
        ${props => props.theme.legacyScss['theme-background']} ${1.5 * gridSize}px
    );
`;

export const SearchNoResults = styled.section`
    text-align: center;
    margin: ${5 * gridSize}px 0;
    h1 {
        font-size: 20px;
        margin: 0 0 ${gridSize}px;
    }
`;

export const SearchResult = styled.button`
    display: grid;
    grid-template-columns: 56px minmax(0, 1fr) auto;
    gap: ${2 * gridSize}px;
    align-items: center;
    text-align: left;
    padding: ${2 * gridSize}px 0;
    border-bottom: 1px solid ${props => props.theme.legacyScss['theme-line']};
`;

export const SearchResultTitle = styled.div`
    font-weight: 500;
    font-size: 14px;
    color: ${props => props.theme.tokens['primary-text-02']};
    h1 {
        font-family: ${defaultFontStack} !important;
        font-size: 16px;
        font-weight: inherit;
        line-height: 1.2;
        color: ${props => props.theme.tokens['primary-text-01']};
        margin: 0;
        -webkit-line-clamp: 2;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        margin-bottom: ${gridSize / 2}px;
    }
    p {
        opacity: 0.9;
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;
