import React, { Component } from 'react';
import { ESCAPE_KEY_PRESSED_EVENT } from '../../../model/page';
import DropZoneInformation from './DropZoneInformation';
import { FilesDropZoneWrapper, DropZoneDashedContainer } from './styled';

export const filesDropZoneIdPrefix = 'files-drop-zone';

// To simplify the dragEnter and dragLeave logic in the parent, every component and child
// from here has to have an id that begins with filesDropZoneIdPrefix (see also ImageDropZone).
export class FilesDropZone extends Component {
    componentDidMount() {
        window.addEventListener(ESCAPE_KEY_PRESSED_EVENT, this.onEsc);
    }

    componentWillUnmount() {
        window.removeEventListener(ESCAPE_KEY_PRESSED_EVENT, this.onEsc);
    }

    onEsc = () => {
        this.props.forceClose();
    };

    render() {
        return (
            <FilesDropZoneWrapper
                id={`${filesDropZoneIdPrefix}-wrapper`}
                onClick={this.props.forceClose}
            >
                <DropZoneDashedContainer id={`${filesDropZoneIdPrefix}-container`}>
                    <DropZoneInformation id={`${filesDropZoneIdPrefix}-info`} />
                </DropZoneDashedContainer>
            </FilesDropZoneWrapper>
        );
    }
}

export default FilesDropZone;
