import React from 'react';
import { VideoExpandButton } from '../../../../../components';
import { VideoControlsWrapper } from './VideoControls.styled';

type Props = {
    videoWidth: number;
    videoHeight: number;
    onFullScreenClick: (event: React.SyntheticEvent) => void;
};

class VideoControls extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
        this.state = {
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight,
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.onResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    onResize = () => {
        this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
    };

    render() {
        const { onFullScreenClick } = this.props;
        const size = this.getSize();
        const attrs: { width?: number; height?: number } = {};
        if (size && size.width && size.height) {
            attrs.width = size.width;
            attrs.height = size.height;
        }
        return (
            <VideoControlsWrapper className="video-controls" style={attrs}>
                <div className="controls-bar" />
                <button className="video-fullscreen" onClick={onFullScreenClick}>
                    <div className="frame">
                        <div className="topleft corner" />
                        <div className="topright corner" />
                        <div className="bottomleft corner" />
                        <div className="bottomright corner" />
                    </div>
                </button>
                <VideoExpandButton />
            </VideoControlsWrapper>
        );
    }

    getSize() {
        const video = document.getElementById('video');
        if (!video) {
            return {
                width: 0,
                height: 0,
            };
        }
        const { videoWidth, videoHeight } = this.props;
        const videoRatio = videoWidth / videoHeight;
        // The width and height of the video element
        let actualWidth = video.offsetWidth;
        let actualHeight = video.offsetHeight;
        // The ratio of the element's width to its height
        const elementRatio = actualWidth / actualHeight;
        // If the video element is short and wide
        if (elementRatio > videoRatio) {
            actualWidth = actualHeight * videoRatio;
        }
        // It must be tall and thin, or exactly equal to the original ratio
        else {
            actualHeight = actualWidth / videoRatio;
        }

        return {
            width: actualWidth,
            height: actualHeight,
        };
    }
}

export default VideoControls;
