import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { getSubscribedPodcasts } from 'redux/reducers/selectors';
import escape from 'lodash/escape';
import { api } from 'services/api';
import useTracks from './useTracks';

type ExportPodcastInfo = {
    title: string;
    feedUrl: string;
};

/**
 * This is a helper so that the below html template literal is
 * syntax highlighted and automatically formatted by prettier
 */
const html = String.raw;

/**
 * Given a list of podcasts, build an OPML export file as a string
 */
const buildOPMLExport = (podcasts: ExportPodcastInfo[]) =>
    html`
        <?xml version="1.0" encoding="utf-8" standalone="no"?>
        <opml version="1.0">
            <head>
                <title>Pocket Casts Feeds</title>
            </head>
            <body>
                <outline text="feeds">
                    ${podcasts
                        .map(
                            podcast =>
                                html`
                                    <outline
                                        xmlUrl="${podcast.feedUrl}"
                                        type="rss"
                                        text="${escape(podcast.title)}"
                                    />
                                `,
                        )
                        .join('\n')}
                </outline>
            </body>
        </opml>
    `.trim();

/**
 * Hook to export the user's subscribed podcasts as an OPML file
 *
 * @returns A tuple containing the data url for the OPML export
 * file and a function to trigger the export
 */
const useExportPodcasts = () => {
    const subscribedPodcasts = useSelector(getSubscribedPodcasts);

    const [dataUrl, setDataUrl] = useState<string | null>(null);

    const { recordEvent } = useTracks();

    const exportPodcasts = useCallback(async () => {
        if (!subscribedPodcasts.length) {
            return;
        }

        const subscribedPodcastsIds = subscribedPodcasts.map(podcast => podcast.uuid);

        const feedUrlsMap = await api.getPodcastFeed(subscribedPodcastsIds);

        const subscribedPodcastsWithFeedUrls = subscribedPodcasts.map(podcast => ({
            ...podcast,
            feedUrl: feedUrlsMap[podcast.uuid],
        }));

        // Build the OPML export file as a string
        const data = buildOPMLExport(subscribedPodcastsWithFeedUrls);

        // Save the OPML export file as a data url to be used on an anchor for download purposes
        setDataUrl(URL.createObjectURL(new Blob([data], { type: 'text/xml' })));
    }, [subscribedPodcasts]);

    const trackedExportPodcasts = useCallback(async () => {
        recordEvent('settings_import_export_started');

        try {
            await exportPodcasts();

            recordEvent('settings_import_export_finished');
        } catch (e) {
            const reason = e instanceof Error ? e.message : 'Unknown error';

            recordEvent('settings_import_export_failed', { reason });

            throw e;
        }
    }, [exportPodcasts, recordEvent]);

    return [dataUrl, trackedExportPodcasts] as const;
};

export default useExportPodcasts;
