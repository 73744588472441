import React, { ForwardedRef } from 'react';

import { PopupMenu as PopupMenuComponent } from 'components/popup/PopupMenu';
import { Props as PopupMenuProps } from 'components/popup/PopupMenu/PopupMenu';

const PopupMenuWrapped = React.forwardRef(
    (
        { className, children, ...rest }: PopupMenuProps & { className?: string },
        ref: ForwardedRef<PopupMenuComponent>,
    ) => {
        // This allows us to wrap the PopupMenu in a styled component and pass the class name all the way down.
        // Otherwise there is no way to target the inner portal class name.
        return (
            <PopupMenuComponent {...rest} popupClassName={className} ref={ref}>
                {children}
            </PopupMenuComponent>
        );
    },
);

export default PopupMenuWrapped;
