import classNames from 'classnames';
import React from 'react';
import { MAX_PLAYBACK_SPEED, MIN_PLAYBACK_SPEED } from 'settings';
import { MinusButton, NumberButton, PlusButton, SpeedControlContainer } from './styled';

class SpeedControl extends React.Component {
    render() {
        const { speed, variant = 'mini' } = this.props;

        return (
            <SpeedControlContainer
                className={classNames('speed-control', variant)}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
                onKeyDown={this.onKeyDown}
                tabIndex="0"
                role="spinbutton" // Apple needs to fix this - https://stackoverflow.com/questions/59177465/incrementing-a-role-spinbutton-with-voiceover/59204326#59204326
                aria-label="Playback Speed"
                aria-valuemax={MAX_PLAYBACK_SPEED}
                aria-valuemin={MIN_PLAYBACK_SPEED}
                aria-valuenow={speed}
                value={speed}
            >
                <div>
                    <PlusButton aria-hidden="true" tabIndex="-1" onClick={this.onPlusClicked} />
                    <NumberButton
                        isChromium={window.chrome}
                        active={speed !== 1}
                        tabIndex="-1"
                        onClick={this.onNumberClicked}
                    >
                        <span className="speed-number">{speed}</span>
                        <span className="speed-x">×</span>
                    </NumberButton>
                    <MinusButton aria-hidden="true" tabIndex="-1" onClick={this.onMinusClicked} />
                </div>
            </SpeedControlContainer>
        );
    }

    onKeyDown = e => {
        switch (e.key) {
            case 'ArrowUp':
                this.onPlusClicked();
                break;
            case 'ArrowDown':
                this.onMinusClicked();
                break;
            case 'Home':
                this.setToMin();
                break;
            case 'End':
                this.setToMax();
                break;
            default:
                return;
        }
        e.preventDefault();
        e.stopPropagation();
    };

    setToMax = () => {
        const { speed, onSpeedChanged } = this.props;

        const maxSpeed = MAX_PLAYBACK_SPEED;
        if (onSpeedChanged && speed !== maxSpeed) {
            onSpeedChanged(maxSpeed);
        }
    };

    setToMin = () => {
        const { speed, onSpeedChanged } = this.props;

        const minSpeed = MIN_PLAYBACK_SPEED;
        if (onSpeedChanged && speed !== minSpeed) {
            onSpeedChanged(minSpeed);
        }
    };

    onNumberClicked = () => {
        const { speed, onSpeedChanged } = this.props;

        let rate = speed;

        if (rate < 1.0) {
            rate = 1.0;
        } else if (rate < 1.5) {
            rate = 1.5;
        } else if (rate < 2.0) {
            rate = 2.0;
        } else {
            rate = 1.0;
        }

        if (onSpeedChanged) {
            onSpeedChanged(rate);
        }
    };

    onPlusClicked = () => {
        const { speed, onSpeedChanged } = this.props;

        let rate = this.props.speed;

        if (rate < MAX_PLAYBACK_SPEED) {
            rate += 0.1;
            rate = Math.round(rate * 10) / 10;
        }

        if (onSpeedChanged && speed !== rate) {
            onSpeedChanged(rate);
        }
    };

    onMinusClicked = () => {
        const { speed, onSpeedChanged } = this.props;

        let rate = speed;

        if (rate > MIN_PLAYBACK_SPEED) {
            rate -= 0.1;
            rate = Math.round(rate * 10) / 10;
        }

        if (onSpeedChanged && speed !== rate) {
            onSpeedChanged(rate);
        }
    };
}

export default SpeedControl;
