import { connect } from 'react-redux';
import { RootState } from 'redux/reducers';
import * as fromSubscriptionActions from '../../../../redux/actions/subscription.actions';
import ThankYouModal from './ThankYouModal';

const mapStateToProps = (state: RootState) => {
    return {
        isWelcomeBack:
            state.subscription.showThankYouModalWelcomeBack && !state.subscription.data.paid,
    };
};

const mapDispatchToProps = {
    hideThankYouModal: fromSubscriptionActions.Actions.hideThankYouModal,
};

const ThankYouModalC = connect(mapStateToProps, mapDispatchToProps)(ThankYouModal);

export { ThankYouModalC as ThankYouModal };
