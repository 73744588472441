import React from 'react';
import {
    ScrollbarContentWrapper,
    ScrollbarPaddingSpacer,
    ScrollbarPaddingWrapper,
} from './ScrollbarPadding.styled';

interface Props {
    children: React.ReactNode;
}

export const ScrollbarPadding = ({ children }: Props) => (
    <ScrollbarPaddingWrapper>
        <ScrollbarContentWrapper>{children}</ScrollbarContentWrapper>
        <ScrollbarPaddingSpacer />
    </ScrollbarPaddingWrapper>
);
