import { Icon } from 'components/Icon';
import useFormatMessage from 'hooks/useFormatMessage';
import React, { MouseEvent } from 'react';
import { useTheme } from 'styled-components';
import { Button, Text } from './PlayPauseButton.styled';

export type Props = {
    className?: string;
    color?: string;
    filled?: boolean;
    isPlaying?: boolean;
    size?: number;
    text?: string | JSX.Element;
    onClick: (evt: MouseEvent) => void;
};

export type InternalProps = Props & { buttonRef: React.ForwardedRef<HTMLButtonElement> };

const PlayPauseButton = ({
    buttonRef,
    className,
    color,
    filled = false,
    isPlaying,
    size = 28,
    text,
    onClick,
}: InternalProps) => {
    const theme = useTheme();
    const formatMessage = useFormatMessage();

    return (
        <Button
            color={color || theme.tokens['primary-interactive-01']}
            onClick={onClick}
            hasText={!!text}
            size={size}
            filled={filled}
            ref={buttonRef}
            aria-label={!text ? formatMessage(isPlaying ? 'pause' : 'play') : undefined}
            className={className}
        >
            {text && <Text>{text}</Text>}
            <Icon id={isPlaying ? 'pause-icon' : 'play-icon'} size={16} />
        </Button>
    );
};

export default React.forwardRef((props: Props, ref: React.Ref<HTMLButtonElement>) => (
    <PlayPauseButton {...props} buttonRef={ref} />
));
