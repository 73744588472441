import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { NavigationItems } from 'helper/NavigationHelper';
import { RootState } from 'redux/reducers';
import {
    getSubscribedPodcastUuids,
    getSubscriptionTier,
    isSubscribedPodcastsLoaded,
} from 'redux/reducers/selectors';
import * as fromDiscoverActions from 'redux/actions/discover.actions';
import * as fromUserActions from 'redux/actions/user.actions';
import { LoaderSquare } from 'components';
import { Button } from 'components/Button';
import { ButtonLink } from 'components/ButtonLink';
import { SubscriberConfetti } from 'components/SubscriberConfetti';
import { TrackOnMount, TrackOnUnmount } from 'components/Tracks';
import { ScrollbarPadding } from 'components/ScrollbarPadding';
import { Content, ContentWrapper, Footer, FooterButton, Wrapper } from './WelcomePage.styled';
import DiscoverScreen from './DiscoverScreen';
import ThankYouScreen from './ThankYouScreen';

type Props = { redirect?: string };

/**
 * This hook gathers all the data needed to decide what onboarding steps to show, and
 * returns the steps. Once steps are chosen, they will not be changed again.
 *
 * @returns steps — null if loading, or array of components
 */
const useOnboardingSteps = () => {
    const dispatch = useDispatch();
    const isLoading = useSelector((state: RootState) => state.podcasts.isLoading);
    const isLoaded = useSelector(isSubscribedPodcastsLoaded);
    const subscribedPodcastUuids = useSelector(getSubscribedPodcastUuids);

    const [steps, setSteps] = useState<FunctionComponent[] | null>(null);

    // If Podcast data has not been loaded and steps have not been chosen, refresh all the user's info
    useEffect(() => {
        if (!isLoading && !isLoaded && !steps) {
            dispatch(fromUserActions.Actions.userReturned());
        }
    }, [dispatch, isLoading, isLoaded, steps]);

    // Once the user's podcasts have loaded, decide which steps to show and fetch related data
    useEffect(() => {
        if (!isLoaded) {
            return;
        }

        // We don't want the current screen to change unexpectedly, so let's bail if steps are already chosen
        if (steps) {
            return;
        }

        if (subscribedPodcastUuids.length === 0) {
            // This user has no podcasts — let's show them the DiscoverScreen and fetch Discover data
            setSteps([ThankYouScreen, DiscoverScreen]);
            dispatch(fromDiscoverActions.Actions.getDiscoverContent());
        } else {
            // This user already has podcasts so they're an upgrading existing user and probably
            // don't need to see the DiscoverScreen
            setSteps([ThankYouScreen]);
        }
    }, [dispatch, isLoaded, subscribedPodcastUuids, steps]);

    return steps;
};

const WelcomePage = ({ redirect }: Props) => {
    const subscribedPodcastUuids = useSelector(getSubscribedPodcastUuids);
    const tier = useSelector(getSubscriptionTier);

    const [currentStep, setCurrentStep] = useState(0);
    const steps = useOnboardingSteps();

    if (!steps) {
        return (
            <Wrapper>
                <ContentWrapper>
                    <LoaderSquare />
                </ContentWrapper>
            </Wrapper>
        );
    }

    // If the user ends onboarding with some subscribed podcasts, send them to the Podcasts page.
    // Otherwise send them to Discover to go find some podcasts.
    const defaultRedirect =
        subscribedPodcastUuids.length === 0
            ? NavigationItems.DISCOVER.path
            : NavigationItems.PODCASTS.path;

    const ContentComponent = steps[Math.min(currentStep, steps.length - 1)];

    return (
        <Wrapper>
            <TrackOnMount event="welcome_shown" />
            <TrackOnUnmount event="welcome_dismissed" />
            <SubscriberConfetti tier={tier} />
            <ContentWrapper>
                <Content>
                    <ContentComponent />
                </Content>
            </ContentWrapper>
            <Footer>
                <ScrollbarPadding>
                    <FooterButton>
                        {currentStep < steps.length - 1 ? (
                            // Show "Continue" button when there's more steps
                            <Button onClick={() => setCurrentStep(currentStep + 1)} kind="primary">
                                <FormattedMessage id="continue" />
                            </Button>
                        ) : (
                            // Show "Done" button when this is the last step
                            <ButtonLink to={redirect ?? defaultRedirect} kind="primary">
                                <FormattedMessage id="done" />
                            </ButtonLink>
                        )}
                    </FooterButton>
                </ScrollbarPadding>
            </Footer>
        </Wrapper>
    );
};

export default WelcomePage;
