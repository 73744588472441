import { getTracksSortOrder } from 'helper/TracksSortOrderHelper';
import { connect } from 'react-redux';
import { getUploadedFilesInOrder } from 'redux/reducers/selectors';
import { PlayingStatus } from '../../../helper/PlayingStatus';
import { UPLOADED_FILES_PODCAST_UUID } from '../../../model/uploaded-files';
import * as fromEpisodeActions from '../../../redux/actions/episode.actions';
import * as fromPlayerActions from '../../../redux/actions/player.actions';
import * as fromTracksActions from '../../../redux/actions/tracks.actions';
import * as fromUpNextActions from '../../../redux/actions/up-next.actions';
import * as fromUploadedFilesActions from '../../../redux/actions/uploaded-files.actions';
import FilesList from './view';

const mapStateToProps = state => ({
    theme: state.settings.theme,
    files: getUploadedFilesInOrder(state),
    account: state.uploadedFiles.data.account,
    playerIsPlaying: state.player.isPlaying,
    playerEpisodeUuid: state.player.episode && state.player.episode.uuid,
    uuidsInUpNextMap: state.upNext.order.reduce((result, current) => {
        result[current] = true;
        return result;
    }, {}),
});

const AUTOPLAY_CONFIG = { source: 'files' };

const mapDispatchToProps = dispatch => ({
    playFile: fileUuid => {
        dispatch(
            fromPlayerActions.Actions.playEpisode(
                fileUuid,
                UPLOADED_FILES_PODCAST_UUID,
                {
                    eventSource: 'files',
                },
                { autoplay: AUTOPLAY_CONFIG },
            ),
        );
        dispatch(
            fromTracksActions.Actions.recordEvent('user_file_play_pause_button_tapped', {
                option: 'play',
            }),
        );
    },
    pause: () => {
        dispatch(fromPlayerActions.Actions.pause({ eventSource: 'files' }));
        dispatch(
            fromTracksActions.Actions.recordEvent('user_file_play_pause_button_tapped', {
                option: 'pause',
            }),
        );
    },
    markFileAsPlayed: fileUuid => {
        dispatch(
            fromUploadedFilesActions.Actions.requestUpdateFile(fileUuid, {
                playingStatus: PlayingStatus.COMPLETED,
            }),
        );
        dispatch(
            fromTracksActions.Actions.recordEvent('user_file_option_tapped', {
                option: 'mark_played',
            }),
        );
    },
    markFileAsUnplayed: fileUuid => {
        dispatch(
            fromUploadedFilesActions.Actions.requestUpdateFile(fileUuid, {
                playingStatus: PlayingStatus.NOT_PLAYED,
                playedUpTo: 0,
            }),
        );
        dispatch(
            fromTracksActions.Actions.recordEvent('user_file_option_tapped', {
                option: 'mark_unplayed',
            }),
        );
    },
    removeFromUpNext: fileUuid => {
        dispatch(fromUpNextActions.Actions.removeFromUpNext(fileUuid, { eventSource: null }));
        dispatch(
            fromTracksActions.Actions.recordEvent('user_file_option_tapped', {
                option: 'up_next_delete',
            }),
        );
    },
    upNextPlayNext: fileAsEpisode => {
        dispatch(
            fromUpNextActions.Actions.upNextPlayNext(UPLOADED_FILES_PODCAST_UUID, fileAsEpisode, {
                eventSource: null,
            }),
        );
        dispatch(
            fromTracksActions.Actions.recordEvent('user_file_option_tapped', {
                option: 'up_next_add_top',
            }),
        );
    },
    upNextPlayLast: fileAsEpisode => {
        dispatch(
            fromUpNextActions.Actions.upNextPlayLast(UPLOADED_FILES_PODCAST_UUID, fileAsEpisode, {
                eventSource: null,
            }),
        );
        dispatch(
            fromTracksActions.Actions.recordEvent('user_file_option_tapped', {
                option: 'up_next_add_bottom',
            }),
        );
    },
    editFile: fileUuid => {
        dispatch(fromUploadedFilesActions.Actions.editFile(fileUuid));
        dispatch(
            fromTracksActions.Actions.recordEvent('user_file_option_tapped', {
                option: 'edit',
            }),
        );
    },
    downloadFile: (fileUuid, fileName) => {
        dispatch(fromUploadedFilesActions.Actions.downloadFile(fileUuid, fileName));
        dispatch(
            fromTracksActions.Actions.recordEvent('user_file_option_tapped', {
                option: 'download',
            }),
        );
    },
    deleteFile: (fileUuid, fileTitle) => {
        dispatch(fromUploadedFilesActions.Actions.showDeleteFileConfirmation(fileUuid, fileTitle));
        dispatch(
            fromTracksActions.Actions.recordEvent('user_file_option_tapped', {
                option: 'delete',
            }),
        );
    },
    sortFiles: (sortColumn, sortOrder) => {
        dispatch(fromUploadedFilesActions.Actions.sortFiles(sortColumn, sortOrder));
        dispatch(
            fromTracksActions.Actions.recordEvent('uploaded_files_sort_by_changed', {
                sort_order: getTracksSortOrder(sortColumn, sortOrder),
            }),
        );
    },
    openFileAsEpisode: file =>
        dispatch(
            fromEpisodeActions.Actions.openEpisode(file, {
                eventSource: 'files',
                autoplayConfig: AUTOPLAY_CONFIG,
            }),
        ),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilesList);
