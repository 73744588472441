import styled from 'styled-components';

// This component does not style itself, but it provides styled components so that
// other components can use them as class names, to style different types in different
// ways. For example the styles for a component that uses EpisodeTypeText could do:
//
// ```
// export const MyComponent = styled.div`
//     // All type text is small
//     ${EpisodeTypeText} {
//         font-size: 12px;
//     }
//
//     // The word "Bonus" is red
//     ${Bonus} {
//         color: red;
//     }
//
//     // The word "Trailer" is blue
//     ${Trailer} {
//         color: blue;
//     }
// `;

export const EpisodeTypeText = styled.span``;
export const Bonus = styled(EpisodeTypeText)``;
export const Trailer = styled(EpisodeTypeText)``;
export const SeasonTrailer = styled(EpisodeTypeText)``;
export const Season = styled(EpisodeTypeText)``;
