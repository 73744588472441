import useFormatMessage from 'hooks/useFormatMessage';
import { MIN_GIFT_DAYS_FOR_LIFETIME_MEMBERSHIP } from 'model/subscription';
import React from 'react';
import { getCorrectFreeGiftMessage } from '../../../../helper/GiftDaysHelper';
import { GiftDaysWrapper } from './GiftDaysText.styled';

type Props = {
    days?: number;
    freeTrial?: boolean;
};

export function GiftDaysText({ days, freeTrial }: Props) {
    const formatMessage = useFormatMessage();
    if (freeTrial === true) {
        return <GiftDaysWrapper>Free Trial</GiftDaysWrapper>;
    }

    let tooltip = '';
    if (days && days >= MIN_GIFT_DAYS_FOR_LIFETIME_MEMBERSHIP) {
        tooltip = formatMessage('pocket-casts-champion-tooltip');
    }

    return <GiftDaysWrapper title={tooltip}>{getCorrectFreeGiftMessage(days)}</GiftDaysWrapper>;
}

export default GiftDaysText;
