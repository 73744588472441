import MD5 from 'md5.js';
import React, { ReactElement, useState } from 'react';

type Props = {
    email: string;
    fallback: ReactElement;
    size?: number;
    alt?: string;
    className?: string;
    asSvgImage?: boolean;
};

const Gravatar = ({ className, email, fallback, asSvgImage, size = 96, alt = '' }: Props) => {
    const [failedToLoad, setFailedToLoad] = useState(false);

    const onError = () => setFailedToLoad(true);

    const hash = new MD5()
        .update(email.trim().toLowerCase())
        .digest('hex')
        .toLowerCase();

    const gravatarUrl = `https://www.gravatar.com/avatar/${hash}?d=404&s=${size}`;

    if (failedToLoad) {
        return fallback;
    }

    const props = {
        className,
        width: size,
        height: size,
        onError,
    };

    if (asSvgImage) {
        return <image {...props} href={gravatarUrl} />;
    }

    return <img {...props} src={gravatarUrl} alt={alt} />;
};

export default Gravatar;
