import { Icon } from 'components/Icon';
import { TrackOnMount } from 'components/Tracks';
import { ExpiresDateMessage } from 'components/messages';
import { SubscriptionTier } from 'model/types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import * as fromTracksActions from 'redux/actions/tracks.actions';
import { NavigationItems } from '../../../helper/NavigationHelper';
import { LOCAL_STORAGE_KEY_EMAIL } from '../../../model/local-storage';
import { MAX_DAYS_FOR_EXPIRY_COUNTDOWN } from '../../../model/subscription';
import { DynamicAccountIcon } from '../DynamicAccountIcon';
import {
    EmailText,
    FilesFullText,
    FilesMenuItemSubTextWrapper,
    ProfileChevronWrapper,
    ProfileDroplist,
    ProfileItemTextWrapper,
    ProfileMenuItemButton,
    ProfileMenuItemImage,
    ProfileMenuItemImageLarge,
    ProfileMenuItemLink,
    ProfileMenuItemText,
    ProfileSubtitleText,
    ProfileSubtitleWarn,
    Separator,
    ThemeMenuItemTopWrapper,
    ThemeMenuItemWrapper,
} from './ProfileMenu.styled';
import { ThemeToggle } from './ThemeToggle';

type Props = {
    uploadedFilesSuspended: boolean;
    onNavigate: () => void;
    fetchUploadedFilesUsage: () => void;
    uploadedFilesPercent: string;
    uploadedFilesPercentExact: number;
    uploadedFilesNumber: number;
    recordEvent: typeof fromTracksActions.Actions.recordEvent;
    signOut: () => void;
    subscriptionAutoRenewing: boolean;
    subscriptionExpiryDate: string;
    toggleTheme: () => void;
    tier: SubscriptionTier;
} & RouteComponentProps;

export class ProfileMenu extends Component<Props> {
    componentDidMount() {
        if (
            !this.props.uploadedFilesSuspended &&
            (!this.props.uploadedFilesPercent || !this.props.uploadedFilesNumber)
        ) {
            this.props.fetchUploadedFilesUsage();
        }
    }

    renderProfileSubtitle() {
        const { subscriptionAutoRenewing, subscriptionExpiryDate, tier } = this.props;

        if (subscriptionExpiryDate) {
            if (subscriptionAutoRenewing) {
                return <ProfileSubtitleText>Pocket Casts {tier}</ProfileSubtitleText>;
            }

            const currentDate = new Date();
            const expiryDate = new Date(subscriptionExpiryDate);
            const diffTime = Math.abs(expiryDate.getTime() - currentDate.getTime());
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

            if (diffDays <= MAX_DAYS_FOR_EXPIRY_COUNTDOWN) {
                return (
                    <ProfileSubtitleWarn>
                        <ExpiresDateMessage expiryDate={expiryDate} />
                    </ProfileSubtitleWarn>
                );
            }
        }

        return <ProfileSubtitleText>Pocket Casts {tier}</ProfileSubtitleText>;
    }

    renderItems() {
        const {
            recordEvent,
            uploadedFilesPercent,
            uploadedFilesPercentExact,
            uploadedFilesNumber,
            uploadedFilesSuspended,
        } = this.props;

        const email = localStorage.getItem(LOCAL_STORAGE_KEY_EMAIL);

        return (
            <>
                <TrackOnMount event="profile_shown" />
                <ProfileMenuItemLink
                    onClick={() => {
                        this.props.onNavigate();
                        recordEvent('profile_account_button_tapped');
                    }}
                    to={NavigationItems.PROFILE.path}
                >
                    <ProfileMenuItemImageLarge>
                        <DynamicAccountIcon large email={email} />
                    </ProfileMenuItemImageLarge>
                    <ProfileItemTextWrapper>
                        {this.renderProfileSubtitle()}
                        <EmailText>{email}</EmailText>
                    </ProfileItemTextWrapper>
                    <ProfileChevronWrapper>
                        <Icon id="chevron-down" />
                    </ProfileChevronWrapper>
                </ProfileMenuItemLink>
                <Separator />
                {!uploadedFilesSuspended && (
                    <ProfileMenuItemLink
                        onClick={this.props.onNavigate}
                        to={NavigationItems.UPLOADED_FILES.path}
                    >
                        <ProfileMenuItemImage>
                            <Icon id="files" />
                        </ProfileMenuItemImage>
                        <ProfileMenuItemText>
                            <FormattedMessage id="files" />
                            {!Number.isNaN(uploadedFilesNumber) &&
                            !Number.isNaN(uploadedFilesPercent) ? (
                                <FilesMenuItemSubTextWrapper>
                                    {uploadedFilesNumber}{' '}
                                    <FormattedMessage
                                        id={uploadedFilesNumber === 1 ? 'file' : 'files'}
                                    />
                                    &nbsp;/&nbsp;
                                    <FilesFullText percentage={uploadedFilesPercentExact}>
                                        {uploadedFilesPercent}% <FormattedMessage id="full" />
                                    </FilesFullText>
                                </FilesMenuItemSubTextWrapper>
                            ) : (
                                <FilesMenuItemSubTextWrapper>&nbsp;</FilesMenuItemSubTextWrapper>
                            )}
                        </ProfileMenuItemText>
                    </ProfileMenuItemLink>
                )}
                <ProfileMenuItemLink
                    onClick={this.props.onNavigate}
                    to={NavigationItems.STATS.path}
                >
                    <ProfileMenuItemImage>
                        <Icon id="stats" />
                    </ProfileMenuItemImage>
                    <ProfileMenuItemText>
                        <FormattedMessage id="stats" />
                    </ProfileMenuItemText>
                </ProfileMenuItemLink>
                <Separator />
                <ThemeMenuItemWrapper>
                    <ThemeMenuItemTopWrapper>
                        <ProfileMenuItemImage>
                            <Icon id="theme" />
                        </ProfileMenuItemImage>
                        <ProfileMenuItemText>
                            <FormattedMessage id="theme-toggle" />
                        </ProfileMenuItemText>
                    </ThemeMenuItemTopWrapper>
                    <ThemeToggle />
                </ThemeMenuItemWrapper>
                <Separator />

                <ProfileMenuItemLink
                    onClick={() => {
                        this.props.onNavigate();
                        recordEvent('profile_settings_button_tapped');
                    }}
                    to={NavigationItems.SETTINGS.path}
                >
                    <ProfileMenuItemImage>
                        <Icon id="settings" />
                    </ProfileMenuItemImage>
                    <ProfileMenuItemText>
                        <FormattedMessage id="settings" />
                    </ProfileMenuItemText>
                </ProfileMenuItemLink>
                <ProfileMenuItemButton
                    onClick={() => {
                        this.props.onNavigate();
                        this.props.signOut();
                    }}
                >
                    <ProfileMenuItemImage>
                        <Icon id="sign-out" />
                    </ProfileMenuItemImage>
                    <ProfileMenuItemText>
                        <FormattedMessage id="sign-out" />
                    </ProfileMenuItemText>
                </ProfileMenuItemButton>
            </>
        );
    }

    render() {
        return <ProfileDroplist>{this.renderItems()}</ProfileDroplist>;
    }
}

export default withRouter(ProfileMenu);
