import styled from 'styled-components';
import { gridSize } from 'styles';

export const StyledLegacyNotificationBanner = styled.div`
    background-color: ${props => props.theme.legacyScss['theme-text-link-warning']};
    color: ${props => props.theme.legacyScss['theme-background']};
    padding: ${3 * gridSize}px;
    text-align: center;
    width: 100%;
`;

export const StyledLegacyNotificationLink = styled.a`
    color: ${props => props.theme.legacyScss['theme-background']};
    text-decoration: underline;
`;
