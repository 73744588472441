import { Folder, PodcastListPodcast } from 'model/types';

export enum PodcastGridLayouts {
    GRID_LARGE = 0,
    GRID_SMALL = 1,
    LIST = 2,
}

export enum PodcastGridOrder {
    DATE_ADDED = 0,
    TITLE = 1,
    RELEASE_DATE = 2,
    USER_SORTED = 3,
}

export enum PodcastBadges {
    OFF = 0,
    NEWEST_EPISODE = 2,
}

export type PodcastListItem = {
    uuid: string;
    title: string;
    lastEpisodePublished: string;
    dateAdded: string;
    showUnplayedBadge: boolean;
    sortPosition: number;
} & ({ podcast: PodcastListPodcast } | { folder: Folder; podcastUuids: string[] });

export type PodcastListFolders = Record<string, PodcastListItem[]>;

// TODO: We should really use Labeled Tuples here so the type is better documented. But those are only
// available on TypeScript 4.0 and we're using 3.9 now. Upgrading involves some risk and changes to a
// bunch of related packages as well. So for now we'll leave it alone.
// https://www.typescriptlang.org/docs/handbook/release-notes/typescript-4-0.html#labeled-tuple-elements
export type PodcastListRearrangeAction =
    | ['MOVE', string, string, number] // MOVE, itemUuid, toFolderUuid, position
    | ['PUSH', string, string] // PUSH, itemUuid, toFolderUuid
    | ['REMOVE', string]; // REMOVE, itemUuid
