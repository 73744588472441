import { Button } from 'components/Button';
import { ModalTypes } from 'helper/UiHelper';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
    addBookmarkFailed,
    addBookmarkSucceeded,
    deleteBookmarkFailed,
    deleteBookmarkSucceeded,
    editBookmarkFailed,
    editBookmarkSucceeded,
} from 'redux/actions/episode-sync.actions';
import * as fromFlagsActions from 'redux/actions/flags.actions';
import * as fromModalActions from 'redux/actions/modal.actions';
import * as fromTracksActions from 'redux/actions/tracks.actions';
import { registerMiddleware } from 'redux/api';
import { api } from 'services/api';

registerMiddleware('BOOKMARK_ADD', async (action, store) => {
    try {
        const {
            player: { muted, volume },
        } = store.getState();
        const bookmark = await api.addBookmark(action.payload.bookmark);
        store.dispatch(addBookmarkSucceeded(bookmark));
        store.dispatch(
            fromTracksActions.Actions.recordEvent('bookmark_created', {
                time: bookmark.time,
                episode_uuid: bookmark.episodeUuid,
                podcast_uuid: bookmark.podcastUuid,
                source: action.payload.options.eventSource,
            }),
        );

        if (action.payload.options.showConfirmation) {
            const successMessage = (
                <div style={{ display: 'flex' }}>
                    <FormattedMessage id="bookmark-added" />
                    <span style={{ margin: 'auto', width: 16 }} />
                    <Button
                        kind="text"
                        onClick={() =>
                            store.dispatch(
                                fromModalActions.Actions.showModal(ModalTypes.listBookmarks, {
                                    podcastUuid: bookmark.podcastUuid,
                                    episodeUuid: bookmark.episodeUuid,
                                }),
                            )
                        }
                    >
                        <FormattedMessage id="show-all" />
                    </Button>
                </div>
            );
            store.dispatch(fromFlagsActions.Actions.addFlag(successMessage));

            if (!muted) {
                const chime = new Audio('/assets/bookmark-creation-sound.wav');
                // The chime sound cuts through really well, so it doesn't need to be very loud
                // to be heard over the audio. Scale it to 1/2 of the current player volume.
                chime.volume = volume * 0.5;
                chime.play();
            }
        }
    } catch {
        store.dispatch(addBookmarkFailed());
    }
});

registerMiddleware('BOOKMARK_DELETE', async (action, store) => {
    try {
        await api.deleteBookmark(action.payload.bookmark.bookmarkUuid);
        store.dispatch(deleteBookmarkSucceeded(action.payload.bookmark));
        store.dispatch(fromTracksActions.Actions.recordEvent('bookmark_deleted'));
    } catch {
        store.dispatch(deleteBookmarkFailed());
    }
});

registerMiddleware('BOOKMARK_EDIT', async (action, store) => {
    try {
        const bookmark = await api.editBookmark(action.payload);
        store.dispatch(editBookmarkSucceeded(bookmark));
        store.dispatch(fromTracksActions.Actions.recordEvent('bookmark_update_title'));
    } catch {
        store.dispatch(editBookmarkFailed());
    }
});
