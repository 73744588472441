import { LOCAL_STORAGE_KEY_LOGS } from 'model/local-storage';

export type Log = [number, string];

// Most browsers cap localStorage at 5MB. We want to make sure the logger doesn't use too much
// of that, so we'll limit the log size to prevent it getting greedy and causing problems.
export const MAX_STORAGE_IN_MB = 0.5;

const stringifyLog = ([timestamp, message]: Log) => {
    const d = new Date(timestamp);
    const year = d.getFullYear();
    const month = `0${d.getMonth() + 1}`.slice(-2);
    const date = `0${d.getDate()}`.slice(-2);
    const time = d.toTimeString().split(' ')[0];
    const formattedDate = `${year}-${month}-${date} ${time}`;
    return `${formattedDate} — ${message}`;
};

const getLogsFromLocalStorage = (): Log[] =>
    JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_LOGS) || '[]');

const appendLog = (message: string) => {
    // Get the list of logs and add this message, with timestamp
    const logs = getLogsFromLocalStorage();
    logs.push([Date.now(), message]);

    // If needed, remove the earliest logs until the array fits our max size
    let storageInBytes = JSON.stringify(logs).length;
    while (storageInBytes > MAX_STORAGE_IN_MB * 1024 * 1024) {
        const removed = logs.shift();
        storageInBytes -= JSON.stringify(removed).length;
    }

    // Store the new log array in localStorage
    localStorage.setItem(LOCAL_STORAGE_KEY_LOGS, JSON.stringify(logs));
};

export const Logger = {
    log: (message: string, verbose = true) => {
        appendLog(message);

        if (process.env.NODE_ENV !== 'test' && verbose) {
            console.info(message);
        }
    },

    getLogs: getLogsFromLocalStorage,

    getLogsAsString: () =>
        getLogsFromLocalStorage()
            .map(stringifyLog)
            .join('\n'),

    clear: () => {
        localStorage.removeItem(LOCAL_STORAGE_KEY_LOGS);
    },
};
