import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import SmallErrorIcon from './small-error-icon.svg';
import {
    ErrorIconWrapper,
    ErrorNumberWrapper,
    ErrorTagContainer,
    MainText,
    MainTextWithLeftMargin,
    UploadInformationHeadingWrapper,
} from './styled';

export class UploadInformationHeading extends Component {
    renderErrorTag() {
        const { errorCount } = this.props;

        const errorString = errorCount < 10 ? `${errorCount}` : '9+';

        return (
            <ErrorTagContainer>
                <ErrorIconWrapper withMargin>
                    <img width={16} height={16} src={SmallErrorIcon} alt="" />
                </ErrorIconWrapper>
                <ErrorNumberWrapper number={errorCount}>{errorString}</ErrorNumberWrapper>
            </ErrorTagContainer>
        );
    }

    renderUploadingFilesText() {
        const { numberOfUploads } = this.props;

        return (
            <MainText>
                {numberOfUploads === 1 ? (
                    <FormattedMessage id="uploading-one-file" />
                ) : numberOfUploads >= 100 ? (
                    <FormattedMessage id="uploading-more-than-99-files" />
                ) : (
                    <FormattedMessage
                        id="uploading-n-files"
                        values={{
                            number: numberOfUploads,
                        }}
                    />
                )}
            </MainText>
        );
    }

    render() {
        const { allFinished, errorCount } = this.props;

        if (errorCount === 0) {
            return (
                <UploadInformationHeadingWrapper>
                    {this.renderUploadingFilesText()}
                </UploadInformationHeadingWrapper>
            );
        }

        if (!allFinished) {
            return (
                <UploadInformationHeadingWrapper>
                    {this.renderUploadingFilesText()}
                    {this.renderErrorTag()}
                </UploadInformationHeadingWrapper>
            );
        }

        // All finished but with some errors
        return (
            <UploadInformationHeadingWrapper>
                <ErrorIconWrapper>
                    <img width={16} height={16} src={SmallErrorIcon} alt="" />
                </ErrorIconWrapper>
                <MainTextWithLeftMargin>
                    {errorCount === 1 ? (
                        <FormattedMessage id="uploading-files-one-error" />
                    ) : (
                        <FormattedMessage
                            id="uploading-files-more-errors"
                            values={{
                                number: errorCount,
                            }}
                        />
                    )}
                </MainTextWithLeftMargin>
            </UploadInformationHeadingWrapper>
        );
    }
}

export default UploadInformationHeading;
