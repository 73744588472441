import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import UsageMeter from './UsageMeter';
import {
    UsageTooltipWrapper,
    TooltipTriangle,
    TooltipBodyWrapper,
    TooltipBody,
    UsageMeterWrapper,
    UsageTextWrapper,
    FilesText,
    BytesText,
} from './styled';

export class UsageTooltip extends Component {
    render() {
        const { theme, numFiles, usage, total, percentUsage } = this.props;

        const percent = Math.max(percentUsage.toFixed(0), 1);

        return (
            <UsageTooltipWrapper>
                <TooltipTriangle />
                <TooltipBodyWrapper>
                    <TooltipBody>
                        <UsageMeterWrapper>
                            <UsageMeter
                                percent={percent}
                                percentExact={percentUsage}
                                theme={theme}
                            />
                        </UsageMeterWrapper>
                        <UsageTextWrapper>
                            <FilesText>
                                <FormattedMessage
                                    id="uploaded-files-usage-number"
                                    values={{
                                        number: numFiles,
                                    }}
                                />
                            </FilesText>
                            <BytesText>
                                {usage} / {total}
                            </BytesText>
                        </UsageTextWrapper>
                    </TooltipBody>
                </TooltipBodyWrapper>
            </UsageTooltipWrapper>
        );
    }
}

export default UsageTooltip;
