import { DiscoverPodcast, DiscoverSection, LoaderRound } from 'components';
import { EpisodesTable } from 'components/EpisodesTable';
import { FolderImage } from 'components/FolderImage';
import { Icon } from 'components/Icon';
import OnMount from 'components/OnMount/OnMount';
import { SortOptions } from 'components/SortOptions';
import { GridItem } from 'components/discover/GridItem';
import { PodcastCount } from 'components/messages';
import { NavigationItems } from 'helper/NavigationHelper';
import { EpisodeSortOrder } from 'helper/PodcastHelper';
import useFormatMessage from 'hooks/useFormatMessage';
import { useScrollRestoration } from 'hooks/useScrollRestoration';
import useTracks from 'hooks/useTracks';
import {
    EpisodeSearchResult,
    FolderSearchResult,
    PodcastSearchResult,
    SearchHistoryItem,
} from 'model/types';
import qs from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router';
import * as fromEpisodeActions from 'redux/actions/episode.actions';
import * as fromSearchActions from 'redux/actions/search.actions';
import { RootState } from 'redux/reducers';
import { getPlayingEpisode, getUpNext } from 'redux/reducers/selectors';
import {
    getMatchingSubscriptions,
    getSearchResponse,
} from 'redux/reducers/selectors/search.selectors';
import { NoResults, SearchHeading, SearchPageWrapper } from './SearchPage.styled';

export type Props = {
    expanded: boolean;
    term: string;
};

const MaybeSearchPage = () => {
    const location = useLocation();
    const term = qs.parse(location.search).q as string | undefined;
    const expandedQueryParam = qs.parse(location.search).expanded as string | undefined;
    const expanded = expandedQueryParam === 'true';

    if (!term) {
        return <Redirect to="/" />;
    }

    return (
        <SearchPage
            term={term}
            expanded={expanded}
            key={`${term}-${String(expanded)}`} // Reset state for the page whenever the term or expanded changes
        />
    );
};

const normalizeEpisodes = (episodes: EpisodeSearchResult[]) =>
    episodes.map(e => ({ ...e, podcastUuid: e.podcast_uuid, podcastTitle: e.podcast_title }));

const sortEpisodes = (episodes: EpisodeSearchResult[], sortOrder: number) => {
    switch (sortOrder) {
        case EpisodeSortOrder.NAME_A_TO_Z:
            return episodes.sort((a, b) => a.title.localeCompare(b.title));
        case EpisodeSortOrder.NAME_Z_TO_A:
            return episodes.sort((a, b) => b.title.localeCompare(a.title));
        case EpisodeSortOrder.NEWEST_TO_OLDEST:
            return episodes.sort(
                (a, b) => new Date(b.published).getTime() - new Date(a.published).getTime(),
            );
        case EpisodeSortOrder.OLDEST_TO_NEWEST:
            return episodes.sort(
                (a, b) => new Date(a.published).getTime() - new Date(b.published).getTime(),
            );
        case EpisodeSortOrder.LENGTH_DESC:
            return episodes.sort((a, b) => b.duration - a.duration);
        case EpisodeSortOrder.LENGTH_ASC:
            return episodes.sort((a, b) => a.duration - b.duration);
        default:
            return episodes;
    }
};

const PodcastResults = ({
    podcasts,
    onItemClick,
    isExpanded,
    onSetExpanded,
}: {
    podcasts: (PodcastSearchResult | FolderSearchResult)[];
    onItemClick: (item: SearchHistoryItem) => void;
    isExpanded: boolean;
    onSetExpanded: (isExpanded: boolean) => void;
}) => {
    const formatMessage = useFormatMessage();
    const [numShown, setNumShown] = useState(podcasts.length);
    const showSeeAllButton = numShown < podcasts.length && !isExpanded;

    return (
        <DiscoverSection
            title={formatMessage('podcasts')}
            discoverFormat="grid"
            minWidth={152}
            maxRowCount={isExpanded ? -1 : 1}
            onChangeNumShown={setNumShown}
            onSeeAllClick={showSeeAllButton ? () => onSetExpanded(true) : undefined}
        >
            {podcasts.map(item =>
                'podcastUuids' in item ? (
                    <GridItem
                        key={item.uuid}
                        image={
                            <FolderImage
                                color={item.color}
                                name={item.name}
                                showName={false}
                                podcastUuids={item.podcastUuids}
                                sortType={item.sortType}
                            />
                        }
                        title={item.name}
                        subtitle={<PodcastCount count={item.podcastUuids.length} />}
                        to={`${NavigationItems.PODCASTS.path}/folders/${item.uuid}`}
                        onClick={() => onItemClick({ folder: item })}
                    />
                ) : (
                    <DiscoverPodcast
                        key={item.uuid}
                        podcast={item}
                        onClick={() => onItemClick({ podcast: item })}
                    />
                ),
            )}
        </DiscoverSection>
    );
};

const EpisodeResults = ({
    episodes,
    onItemClick,
}: {
    episodes: EpisodeSearchResult[];
    onItemClick: (item: SearchHistoryItem) => void;
}) => {
    const formatMessage = useFormatMessage();
    const dispatch = useDispatch();
    const playingEpisode = useSelector(getPlayingEpisode);
    const upNext = useSelector(getUpNext);
    const [epiosodeSortOrder, setEpisodeSortOrder] = useState(EpisodeSortOrder.NEWEST_TO_OLDEST);

    const handleEpisodeClick = (episode: EpisodeSearchResult) => {
        dispatch(
            fromEpisodeActions.Actions.openEpisode(
                {
                    uuid: episode.uuid,
                    podcastUuid: episode.podcast_uuid,
                },
                { eventSource: 'search_list' },
            ),
        );
        onItemClick({ episode });
    };

    const handlePodcastClick = (podcast: PodcastSearchResult) => {
        onItemClick({ podcast });
    };

    const onSelectSortStrategy = (sortOrder: number) => {
        setEpisodeSortOrder(sortOrder);
    };

    const sortedEpisodes = useMemo(() => {
        return sortEpisodes(normalizeEpisodes(episodes), epiosodeSortOrder);
    }, [episodes, epiosodeSortOrder]);

    const Options = (
        <SortOptions
            id="sort-options-search-bar"
            color={'#03a9f4'}
            onSelect={onSelectSortStrategy}
            selectedOption={epiosodeSortOrder}
            options={[
                {
                    id: EpisodeSortOrder.NAME_A_TO_Z,
                    label: formatMessage('alphabetical'),
                },
                {
                    id: EpisodeSortOrder.NAME_Z_TO_A,
                    label: formatMessage('alphabetical-reversed'),
                },
                {
                    id: EpisodeSortOrder.OLDEST_TO_NEWEST,
                    label: formatMessage('release-date-reversed'),
                },
                {
                    id: EpisodeSortOrder.NEWEST_TO_OLDEST,
                    label: formatMessage('release-date'),
                },
                {
                    id: EpisodeSortOrder.LENGTH_DESC,
                    label: formatMessage('duration-order'),
                },
                {
                    id: EpisodeSortOrder.LENGTH_ASC,
                    label: formatMessage('duration-reversed'),
                },
            ]}
        />
    );

    return (
        <DiscoverSection title={formatMessage('episodes')} discoverFormat="grid" controls={Options}>
            <EpisodesTable
                episodes={sortedEpisodes}
                upNextEpisodes={upNext.episodes}
                onEpisodeClick={handleEpisodeClick}
                onPodcastClick={handlePodcastClick}
                playerEpisodeUuid={playingEpisode?.uuid}
                isPlaying={!!playingEpisode}
                showsArchived={true}
                showCompleted={true}
                showSyncActions={false}
                eventSource="search_list"
            />
        </DiscoverSection>
    );
};

const SearchPage = ({ expanded, term }: Props) => {
    const formatMessage = useFormatMessage();
    const { recordSearchResultTapped } = useTracks();
    const dispatch = useDispatch();
    const response = useSelector((state: RootState) => getSearchResponse(state, term));
    const matchingSubscriptions = useSelector((state: RootState) =>
        getMatchingSubscriptions(state, term),
    );
    const isLoading = !response?.episodes || !response?.podcasts;

    const [contentMounted, setContentMounted] = React.useState(false);

    const isReady = !isLoading && contentMounted;

    const { pageRef, updateScrollPosition } = useScrollRestoration(isReady);

    const history = useHistory();

    const setExpandPodcasts = (isExpanded: boolean) => {
        history.push(
            `${history.location.pathname}?${qs.stringify({
                q: term,
                expanded: isExpanded,
            })}`,
        );
    };

    // This is a workaround to force React Virtualized to re-render the list when it's mounted and the scroll position is restored
    useEffect(() => {
        setTimeout(() => {
            if (!pageRef.current?.parentElement) {
                return;
            }
            pageRef.current.parentElement.scrollTop = pageRef.current?.parentElement?.scrollTop - 1;
            pageRef.current.parentElement.scrollTop = pageRef.current?.parentElement?.scrollTop + 1;
        }, 0);
    }, [pageRef]);

    const handleItemClick = (item: SearchHistoryItem) => {
        updateScrollPosition();
        dispatch(fromSearchActions.Actions.addHistoryItem(item));
        recordSearchResultTapped(item);
    };

    useEffect(() => {
        if (isLoading) {
            // Only fetch remote results if we don't already have them cached when the component mounts or the term changes
            dispatch(fromSearchActions.Actions.fetchSearchResults(term));
        }
        dispatch(fromSearchActions.Actions.addHistoryItem({ term }));
        dispatch(fromSearchActions.Actions.setSearchTerm(term));
    }, [term, dispatch, isLoading]);

    const podcasts = matchingSubscriptions.concat(response?.podcasts || []);
    const episodes = response?.episodes || [];

    let content = isLoading ? (
        ''
    ) : (
        <NoResults>
            <Icon id="search-alt" size={40} />
            <h1>{formatMessage('find-podcasts-empty')}</h1>
            <p>{formatMessage('find-podcasts-empty-desc')}</p>
        </NoResults>
    );

    if (podcasts.length > 0 || episodes.length > 0) {
        content = (
            <>
                <OnMount callback={() => setContentMounted(true)} />
                {podcasts.length > 0 && (
                    <PodcastResults
                        podcasts={podcasts}
                        onItemClick={handleItemClick}
                        isExpanded={expanded && episodes.length > 0}
                        onSetExpanded={setExpandPodcasts}
                    />
                )}
                {episodes.length > 0 && (
                    <EpisodeResults episodes={episodes} onItemClick={handleItemClick} />
                )}
            </>
        );
    }

    return (
        <SearchPageWrapper ref={pageRef as React.MutableRefObject<HTMLDivElement>}>
            <Helmet>
                <title>{formatMessage('showing-results-for', { searchTerm: term })}</title>
            </Helmet>
            <SearchHeading>
                <FormattedMessage
                    id="showing-results-for"
                    values={{ searchTerm: <strong>{term}</strong> }}
                />
                {isLoading && <LoaderRound />}
            </SearchHeading>
            {content}
        </SearchPageWrapper>
    );
};

export default MaybeSearchPage;
