import { IconSvgs } from 'components/SvgDefinitions';
import useFormatMessage from 'hooks/useFormatMessage';
import React from 'react';
import { NoRatingsText, RatingsText, Wrapper } from './StarRating.styled';

export type Props = {
    onClick?: () => void;
    rating: number;
    reviewCount: number;
};

/**
 * Based on the overall rating and this star's position, return the star filled to
 * the appriate percentage. We accomplish this by showing the full star-empty icon,
 * and then showing a rectangle the correct width for the rating percent, masked
 * by the star-full icon. This overlays a partial star-full on top of the star-empty.
 */
const Star = ({ rating, position }: { rating: number; position: number }) => {
    const fillableWidth = 15; // The star-empty icon has 15px of empty width inside the border
    const percentFull = +Math.max(0, Math.min(1, rating - (position - 1))).toFixed(2);
    return (
        <svg width={16} height={16} viewBox="0 0 24 24" fill="currentColor">
            <mask id="starMask">
                <g fill="white">{IconSvgs['star-full']}</g>
            </mask>
            <use xlinkHref="#star-empty" />
            <rect
                x="4.5"
                y="0"
                width={percentFull * fillableWidth}
                height="24"
                mask={`url(#starMask)`}
            />
        </svg>
    );
};

/**
 * Return the count, abbreviated as 6.5K if in the thousands, or 1.21M if in the millions
 */
const AbbreviatedCount = ({ count }: { count: number }) => {
    const formatMessage = useFormatMessage();

    if (count < 1000) {
        return <>{count}</>;
    }

    if (count < 1000000) {
        return (
            <>
                {formatMessage('abbreviated-thousands', {
                    numberInThousands: (count / 1000).toFixed(1),
                })}
            </>
        );
    }

    return (
        <>
            {formatMessage('abbreviated-millions', {
                numberInMillions: (count / 1000000).toFixed(2),
            })}
        </>
    );
};

const StarRating = ({ onClick, rating, reviewCount }: Props) => {
    const formatMessage = useFormatMessage();
    return (
        <Wrapper onClick={onClick}>
            <Star rating={rating} position={1} />
            <Star rating={rating} position={2} />
            <Star rating={rating} position={3} />
            <Star rating={rating} position={4} />
            <Star rating={rating} position={5} />
            {rating > 0 ? (
                <RatingsText>
                    <strong>{rating}</strong> (<AbbreviatedCount count={reviewCount} />)
                </RatingsText>
            ) : (
                <NoRatingsText>{formatMessage('no-ratings')}</NoRatingsText>
            )}
        </Wrapper>
    );
};

export default StarRating;
