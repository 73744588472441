import { WebPlayerTheme } from 'model/theme';
import React, { Component } from 'react';
import { withTheme } from 'styled-components';
import { calculatePartialRingPath } from '../helper';

type Props = {
    uploadProgressPercent: number;
    uploadsAreAllComplete?: boolean;
    hasUploadError?: boolean;
    theme: WebPlayerTheme;
};

export class UploadingFilesAccountIcon extends Component<Props> {
    renderBasePath() {
        const { theme } = this.props;
        return (
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15 28C16.4732 28 17.8891 27.755 19.2093 27.3034C17.7989 24.1765 14.6541 22 11.001 22C8.92987 22 7.0221 22.6996 5.50118 23.8755C7.87371 26.4135 11.2516 28 15 28ZM14.7745 20.6643C17.5715 21.6857 19.8377 23.8102 21.0465 26.5112C25.1809 24.3351 28 19.9967 28 15C28 7.8203 22.1797 2 15 2C7.8203 2 2 7.8203 2 15C2 17.7121 2.8305 20.2302 4.25105 22.3139C5.14313 21.6196 6.14497 21.0598 7.22616 20.6648C5.86814 19.5648 5 17.8838 5 16C5 12.6863 7.68629 10 11 10C14.3137 10 17 12.6863 17 16C17 17.8835 16.1321 19.5643 14.7745 20.6643ZM30 15C30 20.8771 26.6201 25.9647 21.698 28.4252L21.6665 28.4409C21.0731 28.7358 20.4574 28.9925 19.8226 29.2079C18.3091 29.7215 16.6871 30 15 30C10.6874 30 6.79987 28.18 4.06357 25.2663L4.01857 25.2181C3.57237 24.7388 3.15717 24.2302 2.77617 23.6956C1.02817 21.2428 0 18.2415 0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15ZM15 16C15 18.2091 13.2091 20 11 20C8.79086 20 7 18.2091 7 16C7 13.7909 8.79086 12 11 12C13.2091 12 15 13.7909 15 16Z"
                fill={theme.legacyScss['theme-interactive-secondary']}
            />
        );
    }

    renderOuterCircle() {
        const { uploadsAreAllComplete, hasUploadError, theme } = this.props;

        const ringPath = calculatePartialRingPath(23, 8, 6, 0, 359.99);

        const outerBlankPath = (
            <path
                stroke={theme.legacyScss['theme-background']}
                d={calculatePartialRingPath(23, 8, 8, -90, 180)}
                fillRule="evenodd"
                clipRule="evenodd"
                strokeWidth="2"
                fill="none"
            />
        );

        if (uploadsAreAllComplete) {
            return (
                <g>
                    <path
                        stroke="#78D549"
                        d={ringPath}
                        fillRule="evenodd"
                        clipRule="evenodd"
                        strokeWidth="2"
                        fill={theme.legacyScss['theme-background']}
                    />
                    {outerBlankPath}
                </g>
            );
        }

        if (hasUploadError) {
            return (
                <g>
                    <path
                        stroke="#F43E37"
                        d={ringPath}
                        fillRule="evenodd"
                        clipRule="evenodd"
                        strokeWidth="2"
                        fill={theme.legacyScss['theme-background']}
                    />
                    {outerBlankPath}
                </g>
            );
        }

        return (
            <g>
                <linearGradient id="upload_progress_linear_gradient">
                    <stop offset="0%" stopColor="#fed745" />
                    <stop offset="100%" stopColor="#feb525" />
                </linearGradient>
                <path
                    stroke="url(#upload_progress_linear_gradient)"
                    d={ringPath}
                    fillRule="evenodd"
                    clipRule="evenodd"
                    strokeWidth="2"
                    fill={theme.legacyScss['theme-background']}
                />
                {outerBlankPath}
            </g>
        );
    }

    renderDotOrPie() {
        const { uploadProgressPercent, uploadsAreAllComplete, hasUploadError } = this.props;

        const startX = 23;
        const startY = 8;
        const radius = 3;

        if (uploadsAreAllComplete) {
            return <circle cx={startX} cy={startY} r={radius} fill="#78D549" />;
        }

        if (hasUploadError) {
            return <circle cx={startX} cy={startY} r={radius} fill="#F43E37" />;
        }

        const normalisedPercent = uploadProgressPercent >= 100 ? 99.99 : uploadProgressPercent;

        // We have to subtract 90 because we're starting at the top of the circle.
        const endAngle = (normalisedPercent / 100) * 360 - 90;
        const largeArcFlag = endAngle > 90 ? 1 : 0;

        const x1 = startX;
        const y1 = 5;
        const x2 = startX + radius * Math.cos((Math.PI * endAngle) / 180);
        const y2 = startY + radius * Math.sin((Math.PI * endAngle) / 180);

        const path = `M${startX},${startY} L${x1},${y1} A${radius},${radius} 0 ${largeArcFlag},1 ${x2},${y2} z`;

        return <path d={path} fill="#FF9D3B" />;
    }

    render() {
        return (
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none">
                {this.renderBasePath()}
                {this.renderOuterCircle()}
                {this.renderDotOrPie()}
            </svg>
        );
    }
}

export default withTheme(UploadingFilesAccountIcon);
