import React from 'react';
import useFormatMessage from 'hooks/useFormatMessage';
import { FolderSearchResult } from 'model/types';
import { NavigationItems } from 'helper/NavigationHelper';
import { FolderImage } from 'components/FolderImage';
import { formattedPodcastCount } from 'components/messages';
import { SearchResultItem } from '.';

type Props = {
    folder: FolderSearchResult;
    subscribed?: boolean;
    onClear?: () => void;
    onClick?: () => void;
};

const FolderSearchResultItem = ({ folder, onClear, onClick, subscribed }: Props) => {
    const formatMessage = useFormatMessage();
    const podcastCount = formattedPodcastCount(folder.podcastUuids.length, formatMessage);
    const label = `${formatMessage('folder')}. ${folder.name}. ${podcastCount}`;
    return (
        <SearchResultItem
            image={
                <FolderImage
                    color={folder.color}
                    name={folder.name}
                    podcastUuids={folder.podcastUuids}
                    sortType={folder.sortType}
                    showName={false}
                />
            }
            title={folder.name}
            description={podcastCount}
            subscribed={subscribed}
            onClear={onClear}
            onClick={onClick}
            href={`${NavigationItems.PODCASTS.path}/folders/${folder.uuid}`}
            aria-label={label}
        />
    );
};

export default FolderSearchResultItem;
