import { TrackOnMount } from 'components/Tracks';
import { withAnalyticsContext } from 'context/AnalyticsContext';
import useFormatMessage from 'hooks/useFormatMessage';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { Header, LoaderSquare } from '../../components';
import FilesEmptyState from './FilesEmptyState';
import FilesList from './FilesList';
import { UploadedFilesErrorWrapper, UploadedFilesPageWrapper } from './styled';

const UploadedFilesPage = ({
    serverError,
    isLoading,
    filesOrder,
    fetchUploadedFilesData,
    filesWebOpenEvent,
}) => {
    const formatMessage = useFormatMessage();

    useEffect(() => {
        fetchUploadedFilesData();
        filesWebOpenEvent();
    }, []);

    const renderCorrectContent = () => {
        if (serverError) {
            return (
                <UploadedFilesErrorWrapper>
                    <FormattedMessage id="files-loading-issue" />
                </UploadedFilesErrorWrapper>
            );
        }

        if (isLoading && !filesOrder) {
            return <LoaderSquare />;
        }

        if (filesOrder.length === 0) {
            return <FilesEmptyState />;
        }

        return <FilesList />;
    };

    return (
        <UploadedFilesPageWrapper>
            <TrackOnMount event="uploaded_files_shown" />
            <Helmet>
                <title>{formatMessage('files')}</title>
            </Helmet>
            <Header title={formatMessage('files')} />
            {renderCorrectContent()}
        </UploadedFilesPageWrapper>
    );
};

export default withAnalyticsContext(UploadedFilesPage);
