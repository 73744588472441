import { Folder } from 'model/types';
import * as fromFoldersActions from 'redux/actions/folders.actions';
import * as fromPodcastsActions from 'redux/actions/podcasts.actions';

export default (
    state: Folder[] = [],
    action: fromFoldersActions.Actions | fromPodcastsActions.Actions,
): Folder[] => {
    switch (action.type) {
        case fromPodcastsActions.ActionTypes.DOWNLOAD_SUBSCRIBED_PODCASTS_SUCCESS:
            return action.payload.folders || state;
        case fromFoldersActions.ActionTypes.FOLDER_ADD:
            return state.concat(action.payload.folder);
        case fromFoldersActions.ActionTypes.FOLDER_UPDATE:
            return state.map(folder =>
                folder.uuid === action.payload.folder.uuid ? action.payload.folder : folder,
            );
        case fromFoldersActions.ActionTypes.FOLDER_REMOVE:
            return state.filter(folder => folder.uuid !== action.payload.uuid);
        case fromPodcastsActions.ActionTypes.PODCAST_LIST_UPDATE_POSITIONS: {
            return state.map(folder => ({
                ...folder,
                sortPosition: action.payload.positions.folders[folder.uuid],
            }));
        }
        default:
    }
    return state;
};
