import { getCategoryByLabel } from 'helper/CategoriesHelper';
import { NavigationItems } from 'helper/NavigationHelper';
import React from 'react';
import { Link } from 'react-router-dom';
import { Wrapper } from './CategoryLinks.styled';

export type Props = {
    categories: string; // A newline-separated list of category labels, as returned by the API
    onClick?: (category: ReturnType<typeof getCategoryByLabel>) => void;
};

const CategoryLinks = ({ categories, onClick }: Props) => {
    const filteredCategories = categories
        // Turn the newline-separated list into an array of category label strings
        .split('\n')
        // Remove whitespace
        .map(label => label.trim())
        // Remove duplicates
        .filter((label, index, self) => self.indexOf(label) === index)
        // Find the category object for each label
        .map(getCategoryByLabel)
        // And remove empty values, which are labels that don't correspond to an approved category
        .filter(category => !!category);

    return (
        <Wrapper>
            {filteredCategories.map(category => (
                <span key={category.id}>
                    <Link
                        to={`${NavigationItems.DISCOVER.path}/category/${category.id}`}
                        onClick={() => onClick?.(category)}
                    >
                        {category.formattedLabel}
                    </Link>
                </span>
            ))}
        </Wrapper>
    );
};

export default CategoryLinks;
