import * as fromSettingsActions from 'redux/actions/settings.actions';
import { registerMiddleware } from 'redux/api';
import { getSettings } from 'redux/reducers/selectors';
import { api } from 'services/api';

// https://pocketcastsp2.wordpress.com/2024/03/07/changing-the-scope-of-named-settings
registerMiddleware(
    fromSettingsActions.ActionTypes.MIGRATE_GLOBAL_SETTINGS,
    async (action, store) => {
        const { settingsToMigrate } = action.payload;
        const settings: { [key: string]: any } = getSettings(store.getState());

        const newSettings: Record<string, unknown> = {};
        settingsToMigrate.forEach(setting => {
            newSettings[`${setting}Global`] = settings[setting];
        });

        try {
            const savedSettings = await api.saveSettings(newSettings);
            store.dispatch(fromSettingsActions.Actions.importSettings(savedSettings));
        } catch {
            console.error('Failed to migrate global settings');
        }
    },
);
