import PropTypes from 'prop-types';
import SpeedControl from './view';

SpeedControl.propTypes = {
    speed: PropTypes.number,
    onSpeedChanged: PropTypes.func,
};

SpeedControl.defaultProps = {
    speed: 1,
};

export default SpeedControl;
