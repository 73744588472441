import React, { useCallback, useEffect } from 'react';
import useNavigation from 'hooks/useNavigation';
import { useHistory } from 'react-router';
import useFormatMessage from 'hooks/useFormatMessage';
import key from 'keymaster';
import { isAppleDevice } from 'helper/Browser';
import useTracks from 'hooks/useTracks';
import { tooltipAndLabel } from 'util/tooltip';
import ReactTooltip from 'react-tooltip';
import { NavigationWrapper } from './Navigation.styled';
import NavigationButton from './NavigationButton';
import NavigationTooltip from './NavigationTooltip';

const modifierKey = isAppleDevice() ? 'command' : 'alt';
const goBackShortcut = `${modifierKey}+left`;
const goForwardShortcut = `${modifierKey}+right`;

const NavigationButtons = () => {
    const navigation = useNavigation();
    const history = useHistory();
    const formatMessage = useFormatMessage();
    const { recordEvent } = useTracks();

    const onBackClick = useCallback(
        (source: 'click' | 'shortcut') => {
            if (navigation.canGoBack) {
                history.goBack();
                ReactTooltip.hide();
                recordEvent('navigation_history_back', { source });
            }

            return false;
        },
        [history, navigation.canGoBack, recordEvent],
    );

    const onForwardClick = useCallback(
        (source = 'click') => {
            if (navigation.canGoForward) {
                history.goForward();
                ReactTooltip.hide();
                recordEvent('navigation_history_forward', { source });
            }

            return false;
        },
        [history, navigation.canGoForward, recordEvent],
    );

    useEffect(() => {
        key(goBackShortcut, () => onBackClick('shortcut'));
        key(goForwardShortcut, () => onForwardClick('shortcut'));

        return () => {
            key.unbind(goBackShortcut);
            key.unbind(goForwardShortcut);
        };
    }, [onBackClick, onForwardClick]);

    return (
        <NavigationWrapper>
            <NavigationTooltip id="navigation-back-tooltip" direction="back" />
            <NavigationButton
                data-testid="back-button"
                data-for="navigation-back-tooltip"
                onClick={() => onBackClick('click')}
                disabled={!navigation.canGoBack}
                direction="left"
                {...tooltipAndLabel(formatMessage('navigation-go-back'))}
            ></NavigationButton>
            <NavigationTooltip id="navigation-forward-tooltip" direction="forward" />
            <NavigationButton
                data-testid="forward-button"
                data-for="navigation-forward-tooltip"
                onClick={() => onForwardClick('click')}
                disabled={!navigation.canGoForward}
                direction="right"
                {...tooltipAndLabel(formatMessage('navigation-go-back'))}
            ></NavigationButton>
        </NavigationWrapper>
    );
};

export default NavigationButtons;
