import { RouteMatchMonitor } from 'components/RouteMatchMonitor';
import React, { Component } from 'react';
import { clearTokenData } from 'services/auth';
import { LoggedOutPageWrapper } from './LoggedOutPageChrome.styled';

type Props = {
    unAuthenticate: () => void;
    updateIntl: (locale: string) => void;
    children: React.ReactNode;
};

class LoggedOutPageChrome extends Component<Props> {
    componentDidMount() {
        // We're loading a logged-out page, let's just make sure we're not storing
        // sensitive user data in localStorage.
        clearTokenData();
        this.props.unAuthenticate();

        this.updateDocumentTagLanguage();
        document.addEventListener('oauth-callback', this.handleOAuthCallback);
        document.addEventListener('oauth-error', this.handleOAuthError);
        document.addEventListener('oauth-unsupported', this.handleOAuthUnsupported);
    }

    componentWillUnmount() {
        document.removeEventListener('oauth-callback', this.handleOAuthCallback);
        document.removeEventListener('oauth-error', this.handleOAuthError);
        document.removeEventListener('oauth-unsupported', this.handleOAuthUnsupported);
    }

    handleOAuthCallback = (evt: Event) => {
        window.location.href = (evt as CustomEvent<{ url: string }>).detail.url;
    };

    handleOAuthError = () => {
        // This fires even when the user intentionally closes OAuth from the Mac App.
        // So for now let's leave this as a no-op. Here's the message sent in case
        // we want to use it in the future:
    };

    handleOAuthUnsupported = (evt: Event) => {
        // The Desktop app does not support native OAuth, and will pass back the OAuth URL.
        // Redirect to that URL to start the downgraded redirect signin process.
        window.location.href = (evt as CustomEvent<{ url: string }>).detail.url;
    };

    updateDocumentTagLanguage = () => {
        document.documentElement.lang = window.navigator.language;
        this.props.updateIntl(window.navigator.language);
    };

    render() {
        return (
            <LoggedOutPageWrapper>
                {this.props.children}
                <RouteMatchMonitor />
            </LoggedOutPageWrapper>
        );
    }
}

export default LoggedOutPageChrome;
