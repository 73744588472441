import styled from 'styled-components';
import { gridSize, Typography } from '../../../styles';

export const SettingsFilesWrapper = styled.div``;

export const SettingsFilesTitle = styled.h1``;

export const FilesSettings = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;

    width: 100%;
`;

const controlWidth = 38 * gridSize;

export const Setting = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    width: ${controlWidth}px;
    height: ${8 * gridSize}px;

    border-bottom: 1px solid ${props => props.theme.tokens['primary-ui-05']};
    margin-right: ${3 * gridSize}px;
`;

export const SettingTitle = styled.h2`
    ${Typography.Desktop.H70};
    color: ${props => props.theme.tokens['primary-text-01']};
`;

export const SettingSwitchWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;
