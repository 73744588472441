import { connect } from 'react-redux';
import * as fromUploadedFilesActions from '../../../../redux/actions/uploaded-files.actions';
import FileUploadButton from './view';

const mapDispatchToProps = dispatch => ({
    requestFileUploadPreflight: file =>
        dispatch(fromUploadedFilesActions.Actions.requestFileUploadPreflight(file)),
});

export default connect(null, mapDispatchToProps)(FileUploadButton);
