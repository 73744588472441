import React from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import useFormatMessage from 'hooks/useFormatMessage';
import { Subscription, PaddleStatus } from 'model/types';
import { getEmail } from 'redux/reducers/selectors';
import { Header, LoaderSquare } from '../../components';

import {
    ProfilePageWrapper,
    ThreeColumns,
    ProfileInformationColumnWrapper,
    Space,
    ErrorWrapper,
} from './ProfilePage.styled';
import { ActionsColumn } from './ActionsColumn';
import { SmallLinks } from './SmallLinks';
import { ProfileInformation } from './ProfileInformation';
import { SubscriptionErrorBubble } from './SubscriptionErrorBubble';

type Props = {
    serverError: string;
    subscriptionData: Subscription;
    withinExpiryCountdown: boolean;
    subscriptionErrorOccurred: boolean;
    isLoading: boolean;
    plusSubscription: Subscription;
};

export function ProfilePage({
    serverError,
    subscriptionData,
    withinExpiryCountdown,
    subscriptionErrorOccurred,
    isLoading,
    plusSubscription,
}: Props) {
    const formatMessage = useFormatMessage();
    const email = useSelector(getEmail);

    if (serverError) {
        return (
            <ProfilePageWrapper>
                <Helmet>
                    <title>{formatMessage('profile')}</title>
                </Helmet>
                <Header title={formatMessage('profile')} />
                <ErrorWrapper>{serverError}</ErrorWrapper>
            </ProfilePageWrapper>
        );
    }

    if (isLoading || !subscriptionData) {
        return (
            <ProfilePageWrapper>
                <Helmet>
                    <title>{formatMessage('profile')}</title>
                </Helmet>
                <Header title={formatMessage('profile')} />
                <LoaderSquare />
            </ProfilePageWrapper>
        );
    }

    return (
        <ProfilePageWrapper>
            <Helmet>
                <title>{formatMessage('profile')}</title>
            </Helmet>
            <Header title={formatMessage('profile')} />
            {subscriptionErrorOccurred && <SubscriptionErrorBubble />}
            <ThreeColumns>
                <ProfileInformationColumnWrapper>
                    <ProfileInformation
                        email={email}
                        platform={subscriptionData.platform}
                        autoRenewing={subscriptionData.autoRenewing}
                        frequency={subscriptionData.frequency}
                        expiryDate={subscriptionData.expiryDate}
                        giftDays={subscriptionData.giftDays}
                        withinExpiryCountdown={withinExpiryCountdown}
                        isPaddleTrial={subscriptionData.webStatus === PaddleStatus.trialing}
                        nextPayment={subscriptionData.nextPayment}
                    />
                    <Space />
                    <SmallLinks
                        autoRenewing={subscriptionData.autoRenewing}
                        platform={subscriptionData.platform}
                    />
                </ProfileInformationColumnWrapper>
                <ActionsColumn
                    withinExpiryCountdown={withinExpiryCountdown}
                    subscriptionErrorOccurred={subscriptionErrorOccurred}
                    plusSubscription={plusSubscription}
                />
            </ThreeColumns>
        </ProfilePageWrapper>
    );
}

export default ProfilePage;
