import classNames from 'classnames';
import { SlidingModal } from 'components/SlidingModal';
import { TrackOnMount, TrackOnUnmount } from 'components/Tracks';
import ScreenReaderText from 'components/format/ScreenReaderText';
import { EpisodeCount } from 'components/messages';
import { withAnalyticsContext } from 'context/AnalyticsContext';
import { DurationStringFromSeconds } from 'helper/DurationHelper';
import {
    ModalTypes,
    pauseKeyboardShortcuts,
    resumeKeyboardShortcuts,
    shouldCancelDragEvent,
} from 'helper/UiHelper';
import * as key from 'keymaster';
import React, { useCallback, useRef } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, useDispatch } from 'react-redux';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { AutoSizer, List } from 'react-virtualized';
import { getQueuedEpisodeUuids } from 'redux/reducers/selectors';
import { getEpisodeSyncForUpNext } from 'redux/reducers/selectors/episode-sync.selectors';
import * as fromEpisodeActions from '../../../redux/actions/episode.actions';
import * as fromModalActions from '../../../redux/actions/modal.actions';
import * as fromPlayerActions from '../../../redux/actions/player.actions';
import * as fromPodcastsActions from '../../../redux/actions/podcasts.actions';
import * as fromTracksActions from '../../../redux/actions/tracks.actions';
import * as fromUpNextActions from '../../../redux/actions/up-next.actions';
import { UpNextEmptyMessage } from './UpNext.styled';
import { UpNextRow } from './UpNextRow';

const SortableUpNextRow = SortableElement(UpNextRow);
const SortableVirtualizedList = SortableContainer(List, { withRef: true });

const SortableUpNextRows = ({
    rows,
    removeFromUpNext,
    upNextPlayNext,
    upNextPlayLast,
    isSorting,
    openEpisode,
    playEpisode,
    onSortStart,
    onSortOver,
    onSortEnd,
}) => {
    const dispatch = useDispatch();
    const fetchedPodcastUuids = useRef([]);

    // When a virtualized Up Next row renders, it may need to download podcast data.
    // This callback function makes sure we only fetch each podcast once, allowing us
    // to fetch on-demand without over-fetching.
    const maybeDownloadPodcast = useCallback(
        podcastUuid => {
            if (!fetchedPodcastUuids.current.includes(podcastUuid)) {
                fetchedPodcastUuids.current.push(podcastUuid);
                dispatch(fromPodcastsActions.Actions.downloadPodcast(podcastUuid));
            }
        },
        [dispatch],
    );

    const rowRenderer = ({ index, style }) => {
        const row = rows[index];

        // Only send play next/last functions if that's a valid action for this episode
        const maybePlayNext =
            index > 0 ? () => upNextPlayNext(row.episode.podcast, row.episode) : null;
        const maybePlayLast =
            index < rows.length - 1 ? () => upNextPlayLast(row.episode.podcast, row.episode) : null;

        return (
            <SortableUpNextRow
                role="list"
                style={style} // For react-virtualized positioning
                index={index} // For react-sortable-hoc ordering
                key={row.episode.uuid}
                episode={row.episode}
                episodeSync={row.episodeSync}
                podcast={row.podcast}
                openEpisode={() => openEpisode(row.episode)}
                playEpisode={() => playEpisode(row.episode.uuid, row.episode.podcast)}
                upNextPlayNext={maybePlayNext}
                upNextPlayLast={maybePlayLast}
                removeFromUpNext={() => removeFromUpNext(row.episode.uuid)}
                isSorting={isSorting}
                onRequestPodcastDownload={maybeDownloadPodcast}
            />
        );
    };

    return (
        <AutoSizer>
            {({ width, height }) => (
                <SortableVirtualizedList
                    shouldCancelStart={shouldCancelDragEvent}
                    onSortStart={onSortStart}
                    onSortOver={onSortOver}
                    onSortEnd={onSortEnd}
                    helperClass="sortable-drag-item"
                    disableAutoscroll={false}
                    distance={5}
                    lockAxis="y"
                    //
                    // ********
                    // Props for List (react-virtualized)
                    height={height}
                    width={width}
                    rowCount={rows.length}
                    rowHeight={82}
                    rowRenderer={rowRenderer}
                    aria-label=""
                    role="list"
                    containerRole="list"
                    tabIndex={null} // react-virtualized defaults this to 0 but there's no reason for the list wrapper to be tab-navigable
                />
            )}
        </AutoSizer>
    );
};

const UpNextEmptyIcon = () => (
    <svg
        width="66"
        height="48"
        viewBox="0 0 66 48"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
    >
        <rect opacity="0.5" x="46" y="12" width="20" height="4" rx="2" />
        <rect opacity="0.5" x="46" y="32" width="20" height="4" rx="2" />
        <rect opacity="0.5" x="48" y="22" width="18" height="4" rx="2" />
        <rect y="4" width="44" height="40" rx="20" />
        <path
            d="M22 32.3975C25.835 32.3975 28.1553 29.3574 28.1553 24.2334C28.1553 19.0879 25.8027 16.1016 22 16.1016C18.1865 16.1016 15.8447 19.0879 15.8447 24.2227C15.8447 29.3682 18.165 32.3975 22 32.3975ZM22 29.7549C20.2275 29.7549 19.1318 27.8965 19.1318 24.2227C19.1318 20.5811 20.2383 18.7549 22 18.7549C23.7725 18.7549 24.8682 20.5703 24.8682 24.2227C24.8682 27.8965 23.7832 29.7549 22 29.7549Z"
            fill="#303336"
        />
    </svg>
);

class UpNext extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            liveText: '',
            sorting: false,
        };

        this.closeButton = React.createRef();
    }

    componentDidMount() {
        // When pressing ESC we want to close Up Next — unless there are popups open. In that
        // case we should ignore the ESC and let it close the modal.
        key('escape', this.closeUnlessPopupsOpen);
    }

    componentWillUnmount() {
        key.unbind('escape');
        resumeKeyboardShortcuts();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isOpen === false && this.props.isOpen === true) {
            this.props.openUpNextEvent();
        }
    }

    closeUnlessPopupsOpen = () => {
        if (!this.props.isOpen) {
            return;
        }

        const modalRoot = document.getElementById('modal-root');
        if (modalRoot && modalRoot.childNodes.length > 0) {
            return;
        }

        this.props.closeUpNext();
    };

    recordReorderEvent = (oldIndex, newIndex) => {
        this.props.recordEvent('up_next_queue_reordered', {
            direction: newIndex < oldIndex ? 'up' : 'down',
            slots: Math.abs(newIndex - oldIndex),
            is_next: newIndex === 0,
        });
    };

    onSortStart = ({ index }) => {
        const { episodes, intl, uuids } = this.props;
        pauseKeyboardShortcuts(); // So that keyboard sorting doesn't trigger shortcuts — spacebar drops the episode but also plays/pauses the player
        const liveText = `${episodes[uuids[index]].title}, ${intl.formatMessage({
            id: 'grabbed',
        })}. ${intl.formatMessage(
            {
                id: 'current-position',
            },
            { position: index + 1, count: uuids.length },
        )}. ${intl.formatMessage({
            id: 'reorder-move-instructions',
        })}`;
        this.setState({ liveText, sorting: true });
    };

    onSortOver = ({ newIndex }) => {
        const { intl, uuids } = this.props;
        const liveText = `${intl.formatMessage({
            id: 'moved',
        })}. ${intl.formatMessage(
            {
                id: 'current-position',
            },
            { position: newIndex + 1, count: uuids.length },
        )}.`;
        this.setState({ liveText });
    };

    onSortEnd = ({ oldIndex, newIndex }) => {
        const { episodes, intl, uuids } = this.props;
        const episodeTitle = episodes[uuids[oldIndex]].title;
        let liveText;
        if (oldIndex === newIndex) {
            liveText = `${episodeTitle}, ${intl.formatMessage({
                id: 'dropped-in-original-position',
            })}.`;
        } else {
            liveText = `${episodeTitle}, ${intl.formatMessage({
                id: 'dropped',
            })}. ${intl.formatMessage(
                {
                    id: 'final-position',
                },
                { position: newIndex + 1, count: uuids.length },
            )}.`;
        }
        this.setState({ liveText, sorting: false });
        if (oldIndex === newIndex) {
            return;
        }
        this.recordReorderEvent(oldIndex, newIndex);
        this.props.moveUpNextEpisode(oldIndex, newIndex);
        resumeKeyboardShortcuts();
    };

    handleTransitionEnd = evt => {
        // Focus the "close" button when Up Next is opened.
        // Even though we're using <FocusOn>, we can't use its auto-focusing features because
        // the focus lock is enabled before Up Next scrolls onto screen. If we focus an element
        // while it's still off-screen, the browser attempts to bring the focused element into
        // view and the entire layout breaks in a way that can't be fixed without a refresh.
        // So we'll wait for the transition to finish, and _then_ focus on the button.
        if (!evt.target.classList.contains('up-next')) {
            return;
        }
        if (evt.propertyName !== 'transform') {
            return;
        }
        if (!this.props.isOpen) {
            return;
        }
        this.closeButton.current?.focus();
    };

    handleClearUpNextClick = () => {
        const { clearUpNext, confirmClearUpNext, uuids } = this.props;

        if (uuids.length > 2) {
            confirmClearUpNext();
        } else {
            clearUpNext(uuids);
        }
    };

    handleOpenEpisode = episode => {
        this.props.openEpisode(episode);
        this.props.recordEvent('up_next_queue_episode_tapped');
    };

    handlePlayNext = (podcastUuid, episode) => {
        this.props.upNextPlayNext(podcastUuid, episode);
        const oldIndex = this.props.uuids.indexOf(episode.uuid);
        const newIndex = 0;
        this.recordReorderEvent(oldIndex, newIndex);
    };

    handlePlayLast = (podcastUuid, episode) => {
        this.props.upNextPlayLast(podcastUuid, episode);
        const oldIndex = this.props.uuids.indexOf(episode.uuid);
        const newIndex = this.props.uuids.length - 1;
        this.recordReorderEvent(oldIndex, newIndex);
    };

    render() {
        const {
            uuids,
            episodes,
            episodeSync,
            uuidToPodcast,
            intl,
            isOpen,
            closeUpNext,
            playEpisode,
            removeFromUpNext,
        } = this.props;
        const upNextEmpty = uuids.length === 0;

        const rows = uuids
            .filter(uuid => !!episodes[uuid])
            .map(uuid => ({
                uuid,
                episode: episodes[uuid],
                episodeSync: episodeSync[uuid],
                podcast: uuidToPodcast[episodes[uuid].podcast],
            }));

        const timeRemainingInSeconds = Object.values(episodeSync).reduce(
            (sum, sync) => sum + (sync.duration || 0) - (sync.playedUpTo || 0),
            0,
        );
        const timeRemainingString = DurationStringFromSeconds({
            durationSecsStrOrNum: timeRemainingInSeconds,
            short: true,
        });

        const toolbar = uuids.length > 0 && (
            <>
                <EpisodeCount count={uuids.length} />
                &nbsp;&nbsp;&middot;&nbsp;&nbsp;
                <FormattedMessage
                    id="total-time-remaining"
                    values={{ time: timeRemainingString }}
                />
                <button onClick={this.handleClearUpNextClick}>
                    <FormattedMessage id="up-next-clear" />
                </button>
            </>
        );

        return (
            <SlidingModal
                isOpen={isOpen}
                onClose={closeUpNext}
                className={classNames('up-next', { sorting: this.state.sorting })}
                title={intl.formatMessage({ id: 'up-next' })}
                toolbar={toolbar}
            >
                {isOpen && (
                    <>
                        <TrackOnMount event="up_next_shown" />
                        <TrackOnUnmount event="up_next_dismissed" />
                    </>
                )}
                {upNextEmpty ? (
                    <UpNextEmptyMessage>
                        <UpNextEmptyIcon />
                        <h2>
                            <FormattedMessage id="nothing-in-up-next" />
                        </h2>
                        <p>
                            <FormattedMessage id="add-to-up-next-instructions" />
                        </p>
                    </UpNextEmptyMessage>
                ) : (
                    <SortableUpNextRows
                        rows={rows}
                        onSortStart={this.onSortStart}
                        onSortOver={this.onSortOver}
                        onSortEnd={this.onSortEnd}
                        isSorting={this.state.sorting}
                        openEpisode={this.handleOpenEpisode}
                        removeFromUpNext={removeFromUpNext}
                        playEpisode={playEpisode}
                        upNextPlayNext={this.handlePlayNext}
                        upNextPlayLast={this.handlePlayLast}
                    />
                )}
                <ScreenReaderText>
                    <p id="up-next-dnd-instructions">
                        {intl.formatMessage({ id: 'reorder-start-instructions' })}
                    </p>
                    <p aria-live="assertive">
                        <span key={this.state.liveText}>{this.state.liveText}</span>
                    </p>
                </ScreenReaderText>
            </SlidingModal>
        );
    }
}

const mapStateToProps = state => ({
    uuidToPodcast: state.podcasts.uuidToPodcast,
    uuids: getQueuedEpisodeUuids(state),
    episodes: state.upNext.episodes,
    episodeSync: getEpisodeSyncForUpNext(state),
    files: state.uploadedFiles.data && state.uploadedFiles.data.files,
});

const mapDispatchToProps = dispatch => ({
    playEpisode: (episodeUuid, podcastUuid) =>
        dispatch(
            fromPlayerActions.Actions.playEpisode(episodeUuid, podcastUuid, {
                eventSource: 'up_next',
            }),
        ),
    openEpisode: episode =>
        dispatch(fromEpisodeActions.Actions.openEpisode(episode, { eventSource: 'up_next' })),
    closeEpisode: () => dispatch(fromEpisodeActions.Actions.closeEpisode()),
    moveUpNextEpisode: (oldIndex, newIndex) =>
        dispatch(fromUpNextActions.Actions.moveUpNextEpisode(oldIndex, newIndex)),
    removeFromUpNext: episodeUuid =>
        dispatch(fromUpNextActions.Actions.removeFromUpNext(episodeUuid)),
    clearUpNext: episodeUuids => dispatch(fromUpNextActions.Actions.clearUpNext(episodeUuids)),
    closeUpNext: () => dispatch(fromUpNextActions.Actions.closeUpNext()),
    confirmClearUpNext: () =>
        dispatch(fromModalActions.Actions.showModal(ModalTypes.confirmClearUpNext)),
    upNextPlayNext: (podcastUuid, episode) =>
        dispatch(
            fromUpNextActions.Actions.upNextPlayNext(podcastUuid, episode, { eventSource: null }),
        ),
    upNextPlayLast: (podcastUuid, episode) =>
        dispatch(
            fromUpNextActions.Actions.upNextPlayLast(podcastUuid, episode, { eventSource: null }),
        ),
    recordEvent: (event, properties) =>
        dispatch(fromTracksActions.Actions.recordEvent(event, properties)),
});

export default withAnalyticsContext(
    injectIntl(connect(mapStateToProps, mapDispatchToProps)(UpNext)),
);
