import { TodoFixmeMigrationType, UploadedFilesAccount } from 'model/types';
import { ActionsUnion, createAction } from './action-creators';

export enum ActionTypes {
    FILES_FETCH_REQUEST = '@uploaded-files/FILES_FETCH_REQUEST',
    FILES_FETCH_SUCCESS = '@uploaded-files/FILES_FETCH_SUCCESS',
    FILES_FETCH_FAILURE = '@uploaded-files/FILES_FETCH_FAILURE',

    FILE_FETCH_REQUEST = '@uploaded-files/FILE_FETCH_REQUEST',
    FILE_FETCH_SUCCESS = '@uploaded-files/FILE_FETCH_SUCCESS',
    FILE_FETCH_FAILURE = '@uploaded-files/FILE_FETCH_FAILURE',

    FILES_USAGE_REQUEST = '@uploaded-files/FILES_USAGE_REQUEST',
    FILES_USAGE_SUCCESS = '@uploaded-files/FILES_USAGE_SUCCESS',
    FILES_USAGE_FAILURE = '@uploaded-files/FILES_USAGE_FAILURE',

    FILES_UPDATE_REQUEST = '@uploaded-files/FILES_UPDATE_REQUEST',
    FILES_UPDATE_SUCCESS = '@uploaded-files/FILES_UPDATE_SUCCESS',
    FILES_UPDATE_FAILURE = '@uploaded-files/FILES_UPDATE_FAILURE',

    FILE_UPLOAD_PREFLIGHT_REQUEST = '@uploaded-files/FILE_UPLOAD_PREFLIGHT_REQUEST',
    FILE_IMAGE_UPLOAD_PREFLIGHT_REQUEST = '@uploaded-files/FILE_IMAGE_UPLOAD_PREFLIGHT_REQUEST',

    FILE_DELETE_REQUEST = '@uploaded-files/FILE_DELETE_REQUEST',
    FILE_DELETE_SUCCESS = '@uploaded-files/FILE_DELETE_SUCCESS',
    FILE_DELETE_FAILURE = '@uploaded-files/FILE_DELETE_FAILURE',

    FILE_SHOW_EDIT_DIALOG = '@uploaded-files/FILE_SHOW_EDIT_DIALOG',
    FILE_LOAD_FOR_EDITING = '@uploaded-files/FILE_LOAD_FOR_EDITING',
    FILE_CLOSE_EDIT_DIALOG = '@uploaded-files/FILE_CLOSE_EDIT_DIALOG',

    FILE_DELETE_CONFIRMATION_SHOW = '@uploaded-files/FILE_DELETE_CONFIRMATION_SHOW',
    FILE_DELETE_CONFIRMATION_DISMISS = '@uploaded-files/FILE_DELETE_CONFIRMATION_DISMISS',

    FILES_SORT = '@uploaded-files/FILES_SORT',
    FILE_DOWNLOAD = '@uploaded-files/FILE_DOWNLOAD',
}

export const Actions = {
    fetchUploadedFilesData: () => createAction(ActionTypes.FILES_FETCH_REQUEST),

    filesFetchSuccess: (
        files: TodoFixmeMigrationType[],
        account: UploadedFilesAccount,
        lastModified?: string,
    ) =>
        createAction(ActionTypes.FILES_FETCH_SUCCESS, {
            files,
            account,
            lastModified,
        }),

    filesFetchFailure: (error: Error) => createAction(ActionTypes.FILES_FETCH_FAILURE, { error }),

    fetchUploadedFile: (uuid: string) => createAction(ActionTypes.FILE_FETCH_REQUEST, { uuid }),

    fileFetchSuccess: (
        uuid: string,
        title: string,
        size: number,
        contentType: string,
        color: number,
        imageUrl: string,
        artworkImageUrl: string,
        playedUpTo: number,
        playingStatus: number,
        duration: number,
        published: string,
        lastModified?: string,
    ) =>
        createAction(ActionTypes.FILE_FETCH_SUCCESS, {
            uuid,
            title,
            size,
            contentType,
            color,
            imageUrl,
            artworkImageUrl,
            playedUpTo,
            playingStatus,
            duration,
            published,
            lastModified,
        }),

    fileFetchFailure: (message: string) =>
        createAction(ActionTypes.FILE_FETCH_FAILURE, { message }),

    fetchUploadedFilesUsage: () => createAction(ActionTypes.FILES_USAGE_REQUEST),

    uploadedFilesUsageSuccess: ({ totalSize, usedSize, totalFiles }: UploadedFilesAccount) =>
        createAction(ActionTypes.FILES_USAGE_SUCCESS, { totalSize, usedSize, totalFiles }),

    uploadedFilesUsageFailure: () => createAction(ActionTypes.FILES_USAGE_FAILURE),

    // Only have to provide a uuid and the field(s) you want to change for each file, which can be any
    // of: title, color, playedUpTo, playingStatus
    requestUpdateFiles: (files: TodoFixmeMigrationType[]) =>
        createAction(ActionTypes.FILES_UPDATE_REQUEST, { files: [...files] }),

    // For convenience
    // export const requestUpdateFile = (uuid, fields) =>
    //     requestUpdateFiles([
    //         {
    //             uuid,
    //             ...fields,
    //         },
    //     ]);
    requestUpdateFile: (uuid: string, fields: TodoFixmeMigrationType) =>
        createAction(ActionTypes.FILES_UPDATE_REQUEST, {
            files: [
                ...[
                    {
                        uuid,
                        ...fields,
                    },
                ],
            ],
        }),

    updateFilesSuccess: (fileUpdateMap: TodoFixmeMigrationType) =>
        createAction(ActionTypes.FILES_UPDATE_SUCCESS, { fileUpdates: fileUpdateMap }),

    updateFilesFailure: (message: string) =>
        createAction(ActionTypes.FILES_UPDATE_FAILURE, { message }),

    requestFileUploadPreflight: (file: TodoFixmeMigrationType) =>
        createAction(ActionTypes.FILE_UPLOAD_PREFLIGHT_REQUEST, { file }),

    requestImageUploadPreflight: (fileUuid: string, imageFile: TodoFixmeMigrationType) =>
        createAction(ActionTypes.FILE_IMAGE_UPLOAD_PREFLIGHT_REQUEST, {
            uuid: fileUuid,
            file: imageFile,
        }),

    requestDeleteFile: (uuid: string) => createAction(ActionTypes.FILE_DELETE_REQUEST, { uuid }),

    deleteFileSuccess: (uuid: string) => createAction(ActionTypes.FILE_DELETE_SUCCESS, { uuid }),

    deleteFileFailure: (uuid: string, message: string) =>
        createAction(ActionTypes.FILE_DELETE_FAILURE, { uuid, message }),

    editFile: (uuid: string) => createAction(ActionTypes.FILE_SHOW_EDIT_DIALOG, { uuid }),

    loadFileToEdit: (file: TodoFixmeMigrationType) =>
        createAction(ActionTypes.FILE_LOAD_FOR_EDITING, { file }),

    closeEditFileDialog: () => createAction(ActionTypes.FILE_CLOSE_EDIT_DIALOG),

    showDeleteFileConfirmation: (uuid: string, title: string) =>
        createAction(ActionTypes.FILE_DELETE_CONFIRMATION_SHOW, {
            uuid,
            title,
        }),

    dismissDeleteFileConfirmation: () => createAction(ActionTypes.FILE_DELETE_CONFIRMATION_DISMISS),

    sortFiles: (sortColumn: string, sortOrder: string) =>
        createAction(ActionTypes.FILES_SORT, {
            sortColumn,
            sortOrder,
        }),

    downloadFile: (uuid: string, fileName: string) =>
        createAction(ActionTypes.FILE_DOWNLOAD, {
            uuid,
            fileName,
        }),
};

export type Actions = ActionsUnion<typeof Actions>;
