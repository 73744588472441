import React from 'react';
import { rgbToHue } from 'helper/ColorHelper';

export type Props = {
    color: string;
    highlight?: string;
    className?: string;
};

/**
 * BlurryBackground accepts up to two RGB colors. It extracts the hue from those colors
 * ans uses them with pre-defined saturation and lightness values. This gives the background
 * the tones of the passed-in colors, while ensuring accessible contrast against white text.
 */
const BlurryBackground = ({ color, highlight = color, className }: Props) => {
    const hue = rgbToHue(color);
    const highlightHue = rgbToHue(highlight);

    return (
        <svg
            viewBox="0 0 720 1024"
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ display: 'block', width: '100%', height: '100%' }}
            className={className}
        >
            <style>{`
                :root {
                    --hue: ${hue};
                    --highlight: ${highlightHue};
                    --top-left-color: hsl(var(--highlight), 79%, 65%);
                    --top-right-color: hsl(var(--hue), 69%, 44%);
                    --bottom-left-color: hsl(var(--hue), 69%, 40%);
                    --bottom-right-color: hsl(var(--hue), 50%, 27%);
                }
            `}</style>
            <rect width="720" height="1024" fill="url(#bg-gradient)" />
            <g>
                {/* TOP RIGHT */}
                <ellipse
                    cx="640"
                    cy="213.5"
                    rx="500"
                    ry="500"
                    fill="url(#top-right-circle-gradient)"
                />

                {/* TOP LEFT HIGHLIGHT */}
                <ellipse
                    cx="168.5"
                    cy="132"
                    rx="421.5"
                    ry="352"
                    fill="url(#top-left-circle-gradient)"
                />

                {/* BOTTOM RIGHT */}
                <ellipse
                    cx="718.5"
                    cy="912"
                    rx="401.5"
                    ry="402"
                    opacity="0.8"
                    fill="url(#bottom-right-circle-gradient)"
                />

                {/* BOTTOM LEFT */}
                <ellipse
                    cx="30"
                    cy="612.5"
                    rx="511"
                    ry="511.5"
                    fill="url(#bottom-left-circle-gradient)"
                />
            </g>

            <defs>
                <filter id="blur" colorInterpolationFilters="sRGB">
                    <feBlend mode="normal" />
                    <feGaussianBlur stdDeviation="1" />
                </filter>
                <linearGradient id="bg-gradient">
                    <stop stopColor="hsl(var(--hue), 50%, 36%)" />
                    <stop offset="1" stopColor="hsl(var(--hue), 45%, 25%)" />
                </linearGradient>
                <radialGradient id="top-right-circle-gradient">
                    <stop stopOpacity={0.9} stopColor="var(--top-right-color)" />
                    <stop offset="30%" stopOpacity={0.8} stopColor="var(--top-right-color)" />
                    <stop offset="70%" stopOpacity={0.5} stopColor="var(--top-right-color)" />
                    <stop offset="100%" stopOpacity={0} stopColor="var(--top-right-color)" />
                </radialGradient>
                <radialGradient id="top-left-circle-gradient">
                    <stop stopOpacity={0.65} stopColor="var(--top-left-color)" />
                    <stop offset="50%" stopOpacity={0.48} stopColor="var(--top-left-color)" />
                    <stop offset="70%" stopOpacity={0.33} stopColor="var(--top-left-color)" />
                    <stop offset="100%" stopOpacity={0} stopColor="var(--top-left-color)" />
                </radialGradient>
                <radialGradient id="bottom-right-circle-gradient">
                    <stop stopColor="var(--bottom-right-color)" />
                    <stop offset="40%" stopColor="var(--bottom-right-color)" />
                    <stop offset="100%" stopOpacity={0} stopColor="var(--bottom-right-color)" />
                </radialGradient>
                <radialGradient id="bottom-left-circle-gradient">
                    <stop stopColor="var(--bottom-left-color)" />
                    <stop offset="20%" stopColor="var(--bottom-left-color)" />
                    <stop offset="100%" stopOpacity={0} stopColor="var(--bottom-left-color)" />
                </radialGradient>
            </defs>
        </svg>
    );
};

export default BlurryBackground;
