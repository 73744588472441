import styled from 'styled-components';
import { PopupChoiceItemBase, PopupWindowBase } from '../../../../styles/popups';

export const MenuPopupWindow = styled(PopupWindowBase)``;

export const PopupChoiceItem = styled(PopupChoiceItemBase)<{ itemIndex: number }>`
    min-width: 80px;
    padding: 0;
`;

export const MenuTitle = styled.h3`
    font-size: 14px;
    font-weight: 500;
    color: ${props => props.theme.tokens['primary-text-02']};
    text-transform: uppercase;
    border-bottom: 1px solid ${props => props.theme.tokens['primary-ui-05']};
    padding: 24px 0px 4px 0px;
    width: 80%;
    margin: 0 auto 10px auto;
`;
