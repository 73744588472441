import React, { Component } from 'react';
import { ESCAPE_KEY_PRESSED_EVENT } from '../../../model/page';
import ImageDropZoneInformation from './ImageDropZoneInformation/index';
import { ImageDropZoneWrapper, ImageZoneDashedContainer } from './styled';
import { imageDropZoneIdPrefix } from './model';

// To simplify the dragEnter and dragLeave logic in the parent, every component and child
// from here has to have an id that begins with imageDropZoneIdPrefix (see also FilesDropZone).
export class ImageDropZone extends Component {
    componentDidMount() {
        window.addEventListener(ESCAPE_KEY_PRESSED_EVENT, this.onEsc);
    }

    componentWillUnmount() {
        window.removeEventListener(ESCAPE_KEY_PRESSED_EVENT, this.onEsc);
    }

    onEsc = () => {
        this.props.forceClose();
    };

    render() {
        return (
            <ImageDropZoneWrapper
                id={`${imageDropZoneIdPrefix}-wrapper`}
                onClick={this.props.forceClose}
            >
                <ImageZoneDashedContainer id={`${imageDropZoneIdPrefix}-container`}>
                    <ImageDropZoneInformation id={`${imageDropZoneIdPrefix}-info`} />
                </ImageZoneDashedContainer>
            </ImageDropZoneWrapper>
        );
    }
}

export default ImageDropZone;
