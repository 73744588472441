import { WebPlayerTheme } from 'model/theme';
import React, { Component } from 'react';
import { withTheme } from 'styled-components';
import { calculatePartialRingPath } from '../../helper/SvgHelper';
import { PlayPauseButton, ScalableSvg } from './DynamicPlayPauseCircle.styled';

const playArrowPath = () =>
    'M13.534 20.032C12.8681 20.4526 12 19.9741 12 19.1865L12 10.8152C12 10.0276 12.8681 9.54913 13.534 9.96967L20.1613 14.1552C20.7826 14.5476 20.7827 15.4537 20.1613 15.8462L13.534 20.032Z';

const completeRingPath = () =>
    'M15 29C22.732 29 29 22.732 29 15C29 7.26801 22.732 1 15 1C7.26801 1 1 7.26801 1 15C1 22.732 7.26801 29 15 29Z';

type Props = {
    className?: string;
    size: number;
    color: string;
    playedColor: string;
    isPlaying: boolean;
    isPlayed: boolean;
    percent: number;
    disabled?: boolean;
    theme: WebPlayerTheme;
    onPlayClick: () => void;
    onPauseClick: () => void;
};

type StateProps = {
    hover: boolean;
};

class DynamicPlayPauseCircle extends Component<Props, StateProps> {
    constructor(props: Props) {
        super(props);

        this.state = {
            hover: false,
        };
    }

    onPlayClick = (event: React.MouseEvent | React.KeyboardEvent) => {
        event.stopPropagation();
        this.props.onPlayClick();
    };

    onPauseClick = (event: React.MouseEvent | React.KeyboardEvent) => {
        event.stopPropagation();
        this.props.onPauseClick();
    };

    renderPlayArrow() {
        const { color, playedColor, isPlayed } = this.props;
        const { hover } = this.state;

        const fillColor = hover
            ? this.props.theme.legacyScss['theme-background']
            : isPlayed
            ? playedColor
            : color;

        return <path fillRule="evenodd" clipRule="evenodd" d={playArrowPath()} fill={fillColor} />;
    }

    renderPauseLines() {
        const { color, playedColor, isPlayed } = this.props;
        const { hover } = this.state;

        const fillColor = hover
            ? this.props.theme.legacyScss['theme-background']
            : isPlayed
            ? playedColor
            : color;

        return (
            <g fill="none" fillRule="evenodd">
                <rect fill={fillColor} width="3" height="12" x="17" y="9" rx="1" />
                <rect fill={fillColor} width="3" height="12" x="10" y="9" rx="1" />
            </g>
        );
    }

    renderRing() {
        const { color, playedColor, isPlayed, percent } = this.props;
        const { hover } = this.state;

        const strokeColor = isPlayed ? playedColor : color;
        const shouldRenderCompleteRing =
            hover || isPlayed || percent <= 0 || percent >= 100 || Number.isNaN(percent);

        if (shouldRenderCompleteRing) {
            const completeRing = completeRingPath();

            return (
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d={completeRing}
                    stroke={hover ? color : strokeColor}
                    fill={hover ? color : 'none'}
                    strokeWidth="2"
                />
            );
        }

        const partialRingLighter = calculatePartialRingPath(15, 15, 14, 0, 3.6 * percent);
        const partialRingColor = calculatePartialRingPath(15, 15, 14, 3.6 * percent, 360);

        return (
            <g>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d={partialRingLighter}
                    stroke={strokeColor}
                    opacity={0.3}
                    fill="none"
                    strokeWidth="2"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d={partialRingColor}
                    stroke={strokeColor}
                    fill="none"
                    strokeWidth="2"
                />
            </g>
        );
    }

    render() {
        const { className, size, isPlaying } = this.props;
        const { hover } = this.state;

        return (
            <PlayPauseButton
                className={className}
                disabled={this.props.disabled}
                onMouseEnter={() => this.setState({ hover: true })}
                onMouseLeave={() => this.setState({ hover: false })}
                onClick={isPlaying ? this.onPauseClick : this.onPlayClick}
                aria-label="Play"
                aria-pressed={this.props.isPlaying}
                onKeyUp={e => {
                    e.preventDefault();
                }}
            >
                <ScalableSvg
                    disabled={this.props.disabled}
                    width={size}
                    height={size}
                    $isHovering={hover}
                    viewBox={`0 0 30 30`}
                >
                    {this.renderRing()}
                    {isPlaying ? this.renderPauseLines() : this.renderPlayArrow()}
                </ScalableSvg>
            </PlayPauseButton>
        );
    }
}

export default withTheme(DynamicPlayPauseCircle);
