import { sendGoogleAnalyticsEvent } from './GoogleAnalyticsHelper';
import { Logger } from './Logger';
import { bumpStat, MCStatsGroup } from './MCStatsHelper';

let isWatching = false;

const logError = (event: ErrorEvent) => {
    const { error } = event;
    Logger.log(error.stack, false);
    bumpStat(MCStatsGroup.ERRORS, error.name);
    sendGoogleAnalyticsEvent('Uncaught JavaScript Error', error.name, error.message);
};

const logUnhandledRejection = (event: PromiseRejectionEvent) => {
    const { reason } = event;
    Logger.log(`Uncaught (in promise) ${reason.name}: ${reason.message}`, false);
    bumpStat(MCStatsGroup.ERRORS, reason.name);
    sendGoogleAnalyticsEvent('Unhandled Promise Rejection', reason.name, reason.message);
};

export const watchForErrors = () => {
    if (isWatching) {
        return;
    }
    isWatching = true;
    window.addEventListener('error', logError);
    window.addEventListener('unhandledrejection', logUnhandledRejection);
};
