import React, { Component } from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { InputStatus } from 'components/Input/Input';
import Input from '../../../../components/Input/index';
import Modal from '../../../../components/Modal';
import { Button } from '../../../../components/Button';
import {
    Heading,
    ButtonsWrapper,
    CredentialWrapper,
    VerticalSpace,
    ResponseInformationWrapper,
    Separator,
} from './ChangeEmailModal.styled';

type Props = {
    requestSucceeded: boolean;
    requestInProgress: boolean;
    onClose: () => void;
    requestEmailChange: (newEmail: string, password: string) => void;
    responseMessage: string;
} & WrappedComponentProps;

type StateProps = {
    newEmailValue: string;
    newEmailStatus?: InputStatus;
    passwordValue: string;
};

export class ChangeEmailModal extends Component<Props, StateProps> {
    constructor(props: Props) {
        super(props);

        this.state = {
            newEmailValue: '',
            newEmailStatus: undefined,
            passwordValue: '',
        };
    }

    componentDidUpdate() {
        if (this.props.requestSucceeded) {
            this.props.onClose();
        }
    }

    onChangeNewEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        const email = event.target.value.trim();

        const newEmailStatus = /(.+)@(.+){1,}\.(.+){1,}/.test(email)
            ? 'ok'
            : !email
            ? undefined
            : 'error';

        this.setState({
            newEmailValue: email,
            newEmailStatus,
        });
    };

    onChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            passwordValue: event.target.value,
        });
    };

    changeEmailClicked = () => {
        this.props.requestEmailChange(this.state.newEmailValue, this.state.passwordValue);
    };

    render() {
        const { intl, onClose, requestInProgress, responseMessage } = this.props;

        const { newEmailValue, newEmailStatus, passwordValue } = this.state;

        return (
            <Modal horizontalInsetPx={32} onClose={onClose}>
                <Heading>{intl.formatMessage({ id: 'change-email' })}</Heading>
                <CredentialWrapper>
                    <Input
                        width={464}
                        placeholder={intl.formatMessage({ id: 'new-email' })}
                        type="email"
                        status={newEmailStatus}
                        value={newEmailValue}
                        onChange={this.onChangeNewEmail}
                    />
                </CredentialWrapper>
                <CredentialWrapper>
                    <Input
                        width={464}
                        placeholder={intl.formatMessage({ id: 'current-password' })}
                        type="password"
                        value={passwordValue}
                        onChange={this.onChangePassword}
                    />
                </CredentialWrapper>
                {responseMessage && (
                    <ResponseInformationWrapper>{responseMessage}</ResponseInformationWrapper>
                )}
                <Separator />
                <ButtonsWrapper>
                    <Button kind="secondary" width={120} onClick={this.props.onClose}>
                        {intl.formatMessage({ id: 'cancel' })}
                    </Button>
                    <VerticalSpace />
                    <Button
                        width={194}
                        onClick={this.changeEmailClicked}
                        disabled={newEmailStatus !== 'ok'}
                        loading={requestInProgress}
                    >
                        {intl.formatMessage({ id: 'change-email' })}
                    </Button>
                </ButtonsWrapper>
            </Modal>
        );
    }
}

export default injectIntl(ChangeEmailModal);
