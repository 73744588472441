import React, { FormEvent, ChangeEventHandler, MouseEvent, useState } from 'react';
import { useDispatch, useSelector } from 'hooks/react-redux-typed';
import useTracks from 'hooks/useTracks';
import { getFolder, getPodcastUuidsForFolder } from 'redux/reducers/selectors';
import { Folder, FormStatus } from 'model/types';
import { api } from 'services/api';
import useFormatMessage from 'hooks/useFormatMessage';
import { ModalTypes } from 'helper/UiHelper';
import { Button } from 'components/Button';
import * as fromFoldersActions from 'redux/actions/folders.actions';
import * as fromModalActions from 'redux/actions/modal.actions';
import { GenericErrorMessage } from 'components/GenericErrorMessage';
import { Icon } from 'components/Icon';
import Input from 'components/Input';
import { TrackOnMount } from 'components/Tracks';
import { FolderColorSelector } from '../FolderColorSelector';
import { DeleteButton, ErrorMessage, Footer, FormSection } from './FolderEditDetailsForm.styled';

export type Props = {
    folderUuid: string;
    onSuccess: () => void;
};

type WithFolderProps = Props & {
    folder: Folder;
};

const MaybeFolderEditDetailsForm = ({ folderUuid, onSuccess }: Props) => {
    const { folder } = useSelector(state => ({
        folder: getFolder(state, folderUuid),
    }));

    if (!folder) {
        return <EmptyFolderEditDetailsForm />;
    }

    return <FolderEditDetailsForm folder={folder} folderUuid={folderUuid} onSuccess={onSuccess} />;
};

const EmptyFolderEditDetailsForm = () => {
    return (
        <p>
            <GenericErrorMessage />
        </p>
    );
};

const FolderEditDetailsForm = ({ folderUuid, onSuccess, folder }: WithFolderProps) => {
    const dispatch = useDispatch();
    const formatMessage = useFormatMessage();
    const { recordEvent } = useTracks();

    const podcasts = useSelector(state => getPodcastUuidsForFolder(state, folderUuid));

    const [formStatus, setFormStatus] = useState<FormStatus>(FormStatus.READY);
    const [name, setName] = useState<string>(folder.name);
    const [color, setColor] = useState<number>(folder.color);

    const getSanitizedName = () => name.trim() || formatMessage('new-folder');

    const submitForm = (e: FormEvent) => {
        e.preventDefault();

        setFormStatus(FormStatus.SUBMITTING);

        api.updateFolder({ ...folder, name: getSanitizedName(), color }, podcasts).then(
            updatedFolder => {
                dispatch(fromFoldersActions.Actions.updateFolder(updatedFolder, podcasts));
                setFormStatus(FormStatus.READY);
                recordEvent('folder_edit_dismissed', {
                    did_change_name: folder.name !== updatedFolder.name,
                    did_change_color: folder.color !== updatedFolder.color,
                });
                onSuccess();
            },
            () => {
                setFormStatus(FormStatus.ERROR);
            },
        );
    };

    const handleNameChange: ChangeEventHandler<HTMLInputElement> = e =>
        setName(e.currentTarget.value);

    const handleDeleteClick = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        dispatch(fromModalActions.Actions.showModal(ModalTypes.deleteFolder, { folderUuid }));
        recordEvent('folder_edit_delete_button_tapped');
    };

    return (
        <form onSubmit={submitForm} style={{ width: 340 }}>
            <TrackOnMount event="folder_edit_shown" />
            <FormSection>
                <Input
                    value={name}
                    onChange={handleNameChange}
                    aria-label={formatMessage('folder-name')}
                    placeholder={formatMessage('folder-name')}
                    icon="folder"
                    maxLength={100}
                />
            </FormSection>
            <FormSection>
                <FolderColorSelector value={color} onChange={setColor} />
            </FormSection>
            <FormSection>
                <DeleteButton type="button" kind="text" onClick={handleDeleteClick}>
                    <Icon id="delete" />
                    <span>{formatMessage('delete-folder')}</span>
                </DeleteButton>
            </FormSection>
            <Footer>
                {formStatus === FormStatus.ERROR && (
                    <ErrorMessage>
                        <GenericErrorMessage />
                    </ErrorMessage>
                )}
                <Button loading={formStatus === FormStatus.SUBMITTING} type="submit">
                    Save folder
                </Button>
            </Footer>
        </form>
    );
};

export default MaybeFolderEditDetailsForm;
