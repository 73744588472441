import styled from 'styled-components';
import { coolGrey60, gridSize, H70, userSelectNoneMixin } from '../../../../../../styles';

export const UploadingFileItemWrapper = styled.div`
    display: flex;
    width: 380px;
    height: ${7 * gridSize - 1}px;

    flex-direction: row-reverse;
    align-items: center;
    ${props =>
        !props.hideBorder && `border-bottom: 1px solid ${props.theme.legacyScss['theme-line']};`}

    &:hover {
        background-color: ${props => props.theme.legacyScss['theme-background-hover']};
    }
`;

export const FileNameText = styled(H70)`
    flex-grow: 100;
    margin-left: ${2 * gridSize}px;
    text-align: left;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    ${userSelectNoneMixin};
`;

export const FileSizeText = styled(H70)`
    color: ${coolGrey60};
    width: ${10 * gridSize}px;
    text-align: right;

    ${userSelectNoneMixin};
`;

export const IconWrapper = styled.div`
    display: flex;
    width: 22px;
    height: 22px;

    align-items: center;
    margin-left: ${2 * gridSize}px;
    margin-right: ${3 * gridSize}px;
`;
