import styled from 'styled-components';
import { blueTextLinkMixin, coolGrey70, gridSize, H70, red } from '../../../../../styles';

// Matches ImageWrapper
export const ImageUploadProgressBoxWrapper = styled.div`
    position: relative;
    width: ${25 * gridSize}px;
    height: ${25 * gridSize}px;

    background-color: ${props => props.theme.legacyScss['theme-button-disabled']};
    border-radius: 4px;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;

export const BackgroundImage = styled.img`
    filter: blur(${2 * gridSize}px);
    opacity: 0.9;
`;

export const TextOverlay = styled.div`
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;

    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const InfoText = styled(H70)`
    color: ${coolGrey70};
    text-align: center;
    padding-bottom: ${2 * gridSize}px;
`;

export const ErrorText = styled(H70)`
    color: ${red};
    text-align: center;
    padding-bottom: ${2 * gridSize}px;
`;

export const LinkText = styled(H70)`
    ${blueTextLinkMixin};
    text-align: center;
`;
