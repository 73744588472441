import { getUploadedFileIconUrl } from '../model/uploaded-files';
import { BadRequestError } from '../redux/sagas/saga-helper';
import * as Settings from '../settings';
import { getAccessToken } from './auth';

const GET_LAST_MODIFIED_INDEX = 0;
const GET_PAYLOAD_INDEX = 1;

const getWithAuthentication = async (path: string, optionalIfModifiedSince?: string) =>
    fetch(`${Settings.API_URL}${path}`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${await getAccessToken()}`,
            // This default value is just has a date before we released the Uploaded Files service.
            'If-Modified-Since': optionalIfModifiedSince || 'Tue, 01 Jan 2019 00:00:00 GMT',
        },
    }).then(response => {
        if (!response.ok) {
            return Promise.reject(Error(`${response.status}`));
        }

        return Promise.all([
            Promise.resolve(response.headers.get('Last-Modified')),
            response.json(),
        ]);
    });

const postWithAuthentication = async (path: string, data = {}) =>
    fetch(`${Settings.API_URL}${path}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${await getAccessToken()}`,
        },
        body: JSON.stringify(data),
    }).then(response => {
        if (response.status === 400) {
            try {
                return response
                    .json()
                    .then(json => Promise.reject(new BadRequestError(json.errorMessage)));
            } catch {
                return Promise.reject(Error(`${response.status}`));
            }
        }

        return response.json();
    });

const postWithAuthenticationExpectingEmptyResponse = async (path: string, data = {}) =>
    fetch(`${Settings.API_URL}${path}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${await getAccessToken()}`,
        },
        body: JSON.stringify(data),
    }).then(() => ({}));

const deleteWithAuthentication = async (path: string) =>
    fetch(`${Settings.API_URL}${path}`, {
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${await getAccessToken()}`,
        },
    }).then((response: Response & { message?: string }) => {
        // TOOD: FIXME - Response object doesn't contain a key called 'message' - Verify that this is correct.
        if (response.message) {
            return Promise.reject(Error(response.message));
        }
        return Promise.resolve();
    });

const calculateImageUrlField = (
    file: Record<string, unknown> & { colour: number },
    theme: number,
) =>
    file.colour === 0 && file.imageUrl ? file.imageUrl : getUploadedFileIconUrl(theme, file.colour);

/**
 * Get the user's uploaded files and metadata.
 * This comment also applies to the `getUploadedFile` function in this file.
 *
 * The user can choose between the artwork associated with the file (which may be the file's
 * embedded artwork or an image uploaded by the user), or a default colour image.
 *
 * This is the only place in the codebase where you will see the word 'colour' spelled with a 'u'.
 * We switch immediately to the American spelling.
 *
 * The processing here means that the rest of the app can always just use `imageUrl` as
 * the canonical value for the image it should present to the user.
 *
 * `imageUrl` is the field for the image that should be displayed to the user.
 * `artworkImageUrl` (if any) is the field for the file's associated image file (embedded or custom).
 */
export const getUploadedFiles = (theme: number, ifModifiedSince: string) =>
    getWithAuthentication('/files?include_bookmarks=true', ifModifiedSince).then(response => {
        const lastModified = response[GET_LAST_MODIFIED_INDEX];
        const { files } = response[GET_PAYLOAD_INDEX];

        const newFiles = files.map((file: Record<string, unknown> & { colour: number }) => ({
            ...file,
            artworkImageUrl: file.imageUrl,
            imageUrl: calculateImageUrlField(file, theme),
            color: file.colour,
            colour: undefined,
            lastModified,
        }));

        return {
            ...response[GET_PAYLOAD_INDEX],
            files: newFiles,
            lastModified,
        };
    });

/**
 * Get one of the user's uploaded files.
 *
 * See comment for `getUploadedFiles` for explanation of the processing here.
 */
export const getUploadedFile = (
    uuid: string,
    theme: number,
    ifModifiedSince: string,
    notModifiedGetter: (uuid: string) => void,
) =>
    getWithAuthentication(`/files/${uuid}?include_bookmarks=true`, ifModifiedSince)
        .then(response => {
            const lastModified = response[GET_LAST_MODIFIED_INDEX];
            const file = response[GET_PAYLOAD_INDEX];

            file.lastModified = lastModified;
            file.artworkImageUrl = file.imageUrl;
            file.imageUrl = calculateImageUrlField(file, theme);
            file.color = file.colour;
            delete file.colour;

            return file;
        })
        .catch(error => {
            if (error.message === '304') {
                return notModifiedGetter(uuid);
            }

            if (error.message === '404') {
                return null;
            }

            throw new Error(error.message);
        });

/**
 * Get the ephemeral play URL for an uploaded file.
 */
export const getMediaFileOfUploadedFile = (uuid: string) =>
    getWithAuthentication(`/files/play/${uuid}`).then(response => response[GET_PAYLOAD_INDEX]);

/**
 * Update uploaded files metadata. This is the latest possible place to transform
 * the color field back to the non-US spelling for the API server.
 */
export const updateFiles = (objectWithFilesArray: {
    files: (Record<string, unknown> & { color?: string })[];
}) => {
    const colorisedFiles = objectWithFilesArray.files.map(file => {
        const colour = file.color;

        delete file.color;

        return {
            ...file,
            colour,
        };
    });

    return postWithAuthenticationExpectingEmptyResponse('/files', {
        files: colorisedFiles,
    });
};

/**
 * Delete a file
 */
export const deleteFile = (uuid: string) => deleteWithAuthentication(`/files/${uuid}`);

/**
 * Upload an image for a file
 */
export const uploadImageForFilePreflight = (uuid: string, size: string, contentType: string) =>
    postWithAuthentication('/files/upload/image', {
        uuid,
        size,
        contentType,
    });

/**
 * Delete the uploaded image for a file
 */
export const deleteImageForFile = (uuid: string) =>
    deleteWithAuthentication(`/files/image/${uuid}`);

/**
 * Begin the upload file process. This API returns an object with a 'url' field
 * that is the ACTUAL url to which we should upload our file.
 */
export const uploadFilePreflight = (title: string, size: string, contentType: string) =>
    postWithAuthentication('/files/upload/request', {
        title,
        size,
        contentType,
        hasCustomImage: false,
    });

/**
 * Get the user's usage stats
 */
export const getUploadedFilesUsage = () =>
    getWithAuthentication('/files/usage').then(response => response[GET_PAYLOAD_INDEX]);
