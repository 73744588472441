import { PlanSelector } from 'components/PlanSelector';
import { BillingWillResumeOnDateMessage } from 'components/messages';
import { getLocalizedSubscriptionPrice } from 'helper/PaddleCheckoutHelper';
import { useSelector } from 'hooks/react-redux-typed';
import { usePaddle } from 'hooks/usePaddle';
import { CheckoutModalType, PaddlePlan } from 'model/types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '../../../../components/Button';
import Modal from '../../../../components/Modal';
import { H70 } from '../../../../styles/index';
import {
    CancelTextWrapper,
    CheckoutFooterWrapper,
    HeadingWrapper,
    PlanSelectorWrapper,
    Spaces,
    Subtitle,
} from './CheckoutModal.styled';

type Props = {
    onClose: () => void;
    onPurchaseSuccess?: () => void;
};

const CheckoutModal = ({ onClose, onPurchaseSuccess }: Props) => {
    const [plan, setPlan] = useState<PaddlePlan>('plus-yearly');
    const { product, doSubscribeFlow } = usePaddle({
        plan,
        onPaymentSuccess: () => {
            onPurchaseSuccess?.();
            onClose?.();
        },
        onPaymentFailure: onClose,
    });
    const expiryDate = useSelector(state => state.subscription.data?.expiryDate || null);
    const type = useSelector(state => state.subscription.showCheckoutModal);

    const isSetup = type === CheckoutModalType.TYPE_SETUP;
    const isWelcomeBack = type === CheckoutModalType.TYPE_WELCOME_BACK;

    const subtitle = isWelcomeBack
        ? 'modal-renew-your-subscription-subtitle'
        : 'modal-choose-payment-plan';

    const date = expiryDate ? new Date(expiryDate) : null;

    const heading = isWelcomeBack ? 'modal-welcome-back' : 'set-up-subscription';

    return (
        <Modal data-testid="checkout-modal" horizontalInsetPx={32} onClose={onClose}>
            <HeadingWrapper data-testid="checkout-modal-heading">
                <FormattedMessage id={heading} />
            </HeadingWrapper>

            <Spaces n={1.75} />

            <Subtitle data-testid="checkout-modal-subtitle">
                <FormattedMessage id={subtitle} />
            </Subtitle>
            {isSetup && (
                <Subtitle data-testid="setup-subtitle-billing">
                    <FormattedMessage id="modal-billing-starts-immediately" />
                </Subtitle>
            )}
            {isWelcomeBack && date && (
                <H70>
                    <BillingWillResumeOnDateMessage resumeDate={date} />
                </H70>
            )}
            <Spaces n={3} />
            <PlanSelectorWrapper>
                <PlanSelector
                    plan={plan}
                    onChange={setPlan}
                    trialDays={0}
                    price={product ? getLocalizedSubscriptionPrice(product) : ''}
                    showDisclaimers
                />
            </PlanSelectorWrapper>
            <Spaces n={3} />
            <Button data-testid="checkout-submit-button" width={500} onClick={doSubscribeFlow}>
                <FormattedMessage id="pay-now" />
            </Button>
            <Spaces n={2} />
            {isSetup && (
                <Button data-testid="set-up-later-button" kind="text" onClick={onClose}>
                    <CancelTextWrapper>
                        <FormattedMessage id="modal-set-up-subscription-later" />
                    </CancelTextWrapper>
                </Button>
            )}
            {!isSetup ? <Spaces n={2} /> : <Spaces n={4} />}

            {!isSetup && (
                <CheckoutFooterWrapper data-testid="checkout-footer">
                    <FormattedMessage id="cancel-any-time" />{' '}
                    <FormattedMessage id="cancel-description" />
                </CheckoutFooterWrapper>
            )}

            {!isSetup && <Spaces n={4} />}
        </Modal>
    );
};

export default CheckoutModal;
