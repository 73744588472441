import { BookmarksListResult } from 'model/types';
import { Action } from 'redux/actions';

interface BookmarksState {
    items: BookmarksListResult[] | undefined;
    isFetching?: boolean;
}

export const INITIAL_STATE: BookmarksState = {
    items: undefined,
    isFetching: false,
};

export default (state: BookmarksState = INITIAL_STATE, action: Action): BookmarksState => {
    switch (action.type) {
        case 'FETCH_BOOKMARKS': {
            return { ...state, isFetching: true };
        }
        case 'FETCH_BOOKMARKS_SUCCESS': {
            return {
                ...state,
                items: action.payload.response.bookmarks,
                isFetching: false,
            };
        }
        case 'SAVE_BOOKMARKS': {
            return { ...state, items: action.payload.bookmarks };
        }
        case 'FETCH_BOOKMARKS_FAILED': {
            return { ...state, isFetching: false, items: undefined };
        }
        default:
            return state;
    }
};
