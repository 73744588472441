import React, { KeyboardEventHandler } from 'react';
import PropTypes from 'prop-types';
import { blue50 } from '../../../styles';
import { SwitchWrapper } from './Switch.styled';

export type Props = {
    onChange: () => void;
    color: string;
    on: boolean;
    ariaLabelledBy?: string;
};

function Switch({ onChange, color, on, ariaLabelledBy }: Props) {
    const lineStyles: { backgroundColor?: string } = {};
    if (on) {
        lineStyles.backgroundColor = color;
    }

    const handleKeyPress: KeyboardEventHandler = e => {
        if (e.key === ' ' || e.code === 'Space') {
            onChange();
        }
    };

    return (
        <SwitchWrapper
            role="switch"
            tabIndex={0}
            aria-labelledby={ariaLabelledBy}
            onClick={onChange}
            onKeyPress={handleKeyPress}
            aria-checked={on}
        >
            <div className="knob" />
            <div className="line" style={lineStyles} />
        </SwitchWrapper>
    );
}

Switch.propTypes = {
    on: PropTypes.bool,
    onChange: PropTypes.func,
    color: PropTypes.string,
    ariaLabelledBy: PropTypes.string,
};

Switch.defaultProps = {
    on: false,
    color: blue50,
};

export default Switch;
