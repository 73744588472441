import styled from 'styled-components';
import { Typography } from 'styles';

export const PageTitle = styled.h1`
    ${Typography.Desktop.H20}
    line-height: 64px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
