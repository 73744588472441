import styled from 'styled-components';
import { SubscribeButton } from '../../../components/buttons/SubscribeButton';
import { PAGE_MAX_WIDTH } from '../../../model/page';
import { fontFamilyRetina, H70, B50 } from '../../../styles/typography';
import { Separator } from '../../../components/Separator';
import { coolGrey60 } from '../../../styles';

export const DescriptiveGridFlexRow = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: -16px;
    flex-wrap: wrap;
    max-width: 130%;
`;

export const DescriptiveGridItemTitle = styled(B50)`
    font-family: ${fontFamilyRetina};
    line-height: 120%;
`;

export const PodcastGridItem = styled.div`
    position: relative;
    padding: 20px;

    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
`;

export const PodcastSub5cribeButton = styled(SubscribeButton)`
    top: 16px;
    right: 0px;
    position: absolute;
    &.sub5cribed {
        display: block;
    }
`;

export const PodcastGridItemDescription = styled(H70)`
    max-height: 80px;
    overflow: hidden;
    margin-top: 20px;
    margin-bottom: 40px;
    color: ${coolGrey60};
`;

export const PodcastAuthorDetails = styled.div`
    display: grid;
    grid-template-columns: 96px auto 30px;
`;

export const PodcastSummaryContainer = styled.div`
    min-width: 229px;
    margin-left: 16px;
    flex-direction: row;
`;

export const PodcastAuthor = styled(H70)`
    color: ${coolGrey60};
`;

export const PodcastGridFlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    @media only screen and (max-width: ${PAGE_MAX_WIDTH}px) {
        flex-grow: 1;
        flex: 0 0 50%;
    }
    flex: 0 0 33.333333%;
`;

export const StyledSeparator = styled(Separator)`
    margin-bottom: 30px;
`;
