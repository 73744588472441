export const getTumblrUrl = (url: string) => {
    return `http://tumblr.com/widgets/share/tool?canonicalUrl=${encodeURIComponent(url)}`;
};

export const getFacebookUrl = (url: string) => {
    return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
};

export const getTwitterUrl = (url: string, title: string) => {
    const titleEncoded = encodeURIComponent(title);
    return `https://twitter.com/intent/tweet?url=${encodeURIComponent(
        url,
    )}&via=pocketcasts&text=${titleEncoded}`;
};

export const getLinkedInUrl = (url: string) => {
    return `https://www.linkedin.com/feed/?shareActive=true&shareUrl=${encodeURIComponent(
        url,
    )}&mini=true`;
};
export const getEmailUrl = (url: string) => {
    return `mailto:?body=${encodeURIComponent(url)}`;
};

export const formatTimeForUrl = (secs: number) => {
    const hours = Math.floor(secs / 3600);
    const minutes = Math.floor((secs % 3600) / 60);
    const seconds = Math.floor(secs % 60);

    if (minutes === 0 && hours === 0) {
        return `${seconds}s`;
    }
    if (hours > 0) {
        return `${hours}h${minutes}m${seconds}s`;
    }

    return `${minutes}m${seconds}s`;
};

export const timestampToSeconds = (time: string) => {
    const parts = time.split(':').map(Number);
    if (parts.some(Number.isNaN)) {
        return -1;
    }
    if (parts.length === 3) {
        const [hours, minutes, seconds] = parts;
        return hours * 3600 + minutes * 60 + seconds;
    }
    if (parts.length === 2) {
        const [minutes, seconds] = parts;
        return minutes * 60 + seconds;
    }
    return 0;
};

export const hashUrl = (str: string) => {
    let hash = 0;
    for (let i = 0; i < str.length; i += 1) {
        hash = (hash << 5) - hash + str.charCodeAt(i);
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
};

export const lcg = (url: string) => {
    let seed = hashUrl(url);
    return function _lcg() {
        seed = Math.imul(48271, seed) | 0 % 2147483647;
        return (seed & 2147483647) / 2147483648;
    };
};

export const createSvgElement = (type: string, attributes: Record<string, string | number>) => {
    const svgns = 'http://www.w3.org/2000/svg';
    const element = document.createElementNS(svgns, type);
    for (const [key, value] of Object.entries(attributes)) {
        element.setAttributeNS(null, key, value.toString());
    }
    return element;
};
