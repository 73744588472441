import React from 'react';
import { LoaderWrapper } from './Loader.styled';

type Props = {
    color: string;
};

export function Loader({ color = '#03a9f4' }: Props) {
    return (
        <LoaderWrapper className="loader">
            <div style={{ transform: 'rotate(0deg) translate(0,-9px)', backgroundColor: color }} />
            <div style={{ transform: 'rotate(30deg) translate(0,-9px)', backgroundColor: color }} />
            <div style={{ transform: 'rotate(60deg) translate(0,-9px)', backgroundColor: color }} />
            <div style={{ transform: 'rotate(90deg) translate(0,-9px)', backgroundColor: color }} />
            <div
                style={{
                    transform: 'rotate(120deg) translate(0,-9px)',
                    backgroundColor: color,
                }}
            />
            <div
                style={{
                    transform: 'rotate(150deg) translate(0,-9px)',
                    backgroundColor: color,
                }}
            />
            <div
                style={{
                    transform: 'rotate(180deg) translate(0,-9px)',
                    backgroundColor: color,
                }}
            />
            <div
                style={{
                    transform: 'rotate(210deg) translate(0,-9px)',
                    backgroundColor: color,
                }}
            />
            <div
                style={{
                    transform: 'rotate(240deg) translate(0,-9px)',
                    backgroundColor: color,
                }}
            />
            <div
                style={{
                    transform: 'rotate(270deg) translate(0,-9px)',
                    backgroundColor: color,
                }}
            />
            <div
                style={{
                    transform: 'rotate(300deg) translate(0,-9px)',
                    backgroundColor: color,
                }}
            />
            <div
                style={{
                    transform: 'rotate(330deg) translate(0,-9px)',
                    backgroundColor: color,
                }}
            />
        </LoaderWrapper>
    );
}
