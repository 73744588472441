import styled from 'styled-components';
import { gridSize, H40, H70 } from '../../../../styles/index';

export const ImageWrapper = styled.div`
    display: flex;
    width: ${14 * gridSize}px;
    height: ${14 * gridSize}px;

    align-items: center;
`;

export const TitleWrapper = styled(H40)`
    text-align: center;
`;

export const SubtitleWrapper = styled(H70)`
    width: 380px;
    min-width: 380px;
    text-align: center;
`;

export const SubtitleDowngradedWrapper = styled(H70)`
    width: 264px;

    color: ${props => props.theme.legacyScss['theme-text-secondary']};
    text-align: center;
`;

export const Spaces = styled.div<{ n: number }>`
    height: ${props => gridSize * (props.n || 1)}px;
`;

export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
`;
