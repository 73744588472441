import React from 'react';
import { connect } from 'react-redux';
import { Logger } from 'helper/Logger';
import * as fromPlayerActions from '../../../../redux/actions/player.actions';
import * as EpisodeHelper from '../../../../helper/EpisodeHelper';
import {
    UPLOADED_FILES_PODCAST_UUID,
    getUploadedFileIconPngUrl,
} from '../../../../model/uploaded-files';
import * as PodcastHelper from '../../../../helper/PodcastHelper';
import Base from './Base';

class NativePlayer extends Base {
    constructor(props) {
        super(props);

        this.player = window.webkit.messageHandlers.player;
        this.speed = 1;
    }

    componentDidMount() {
        super.componentDidMount();
        document.addEventListener('media-playback-play', this.remotePlay);
        document.addEventListener('media-playback-pause', this.remotePause);
        document.addEventListener('media-playback-progress', this.remoteProgress);
        document.addEventListener('media-playback-buffering', this.remoteBuffering);
        document.addEventListener('media-playback-finished', this.remoteFinished);
        document.addEventListener('media-playback-failed', this.remoteFailed);
        document.addEventListener('media-playback-chapterinfo', this.remoteChaptersFound);
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        document.removeEventListener('media-playback-play', this.remotePlay);
        document.removeEventListener('media-playback-pause', this.remotePause);
        document.removeEventListener('media-playback-progress', this.remoteProgress);
        document.removeEventListener('media-playback-buffering', this.remoteBuffering);
        document.removeEventListener('media-playback-finished', this.remoteFinished);
        document.removeEventListener('media-playback-failed', this.remoteFailed);
        document.removeEventListener('media-playback-chapterinfo', this.remoteChaptersFound);
    }

    componentDidUpdate(prevProps) {
        super.componentDidUpdate(prevProps);
        if (!this.playing && this.upTo !== this.props.playedUpTo) {
            this.seekTo(this.props.playedUpTo);
        }
    }

    remotePlay = () => {
        if (this.playing) {
            return;
        }
        this.playing = true;
        this.send({
            action: 'speed',
            speed: this.speed,
        });
        this.props.onPlay('desktop_media_controls');
    };

    remotePause = () => {
        if (!this.playing) {
            return;
        }
        this.playing = false;
        this.props.onPause('desktop_media_controls');
        this.props.onBuffering(false);
    };

    remoteProgress = event => {
        const { upTo, duration } = event.detail;
        const durationInt = parseInt(duration, 10);
        if (durationInt !== this.duration) {
            this.duration = durationInt;
            this.updateDuration();
        }

        if (upTo !== this.upTo) {
            this.upTo = upTo;
            this.updateProgress();
            this.saveProgressWhilePaused();
        }
    };

    remoteBuffering = event => {
        const { buffering } = event.detail;
        // don't buffer while paused
        this.props.onBuffering(this.playing && buffering);
    };

    remoteFinished = () => {
        this.stopTimer();
        this.props.onEnded();
        this.autoPlayNextEpisode = true;
    };

    remoteChaptersFound = event => {
        const { chapters, hasChapters } = event.detail;
        if (hasChapters) {
            const normalizedChapters = chapters
                .map(({ startTimeInSeconds, title }) => ({
                    startTime: startTimeInSeconds,
                    title,
                }))
                .sort((a, b) =>
                    a.startTime < b.startTime ? -1 : a.startTime > b.startTime ? 1 : 0,
                );
            this.props.onChaptersFound(normalizedChapters);
        }
    };

    remoteFailed = () => {
        const title = 'The episode could not be played.';
        const message =
            "Some of the reasons why the browser media player won't play a podcast are:<br/>- The episode may have been removed by the podcast author.<br/>- Problems with the internet connection to the player can cause it to stop playing.<br/>- Some browsers require you to install additional software to play audio and video files.";
        this.props.onError(title, message);
    };

    resetPlayer() {
        super.resetPlayer();
        this.upTo = -1;
        this.duration = -1;
        this.playing = false;
        if (this.props.url) {
            if (this.autoPlayNextEpisode) {
                // This literally just changes the playing flag to true
                this.props.onPlay();
            }
            this.load();
        } else {
            this.stop();
        }

        if (this.autoPlayNextEpisode) {
            this.autoPlayNextEpisode = false;
        }
    }

    load() {
        const { episode, playing, playedUpTo, color, speed, skipForward, skipBack, volume } =
            this.props;

        this.upTo = playedUpTo;
        this.playing = playing;
        this.speed = speed;

        this.send({
            action: 'load',
            episodeUuid: episode.uuid,
            podcastUuid: episode.podcastUuid,
            episodeTitle: episode.title,
            podcastTitle: episode.podcastTitle,
            imageUrl: this.getImageUrl(episode),
            playing,
            url: episode.url,
            upTo: playedUpTo,
            color,
            speed,
            video: EpisodeHelper.isVideo(episode) === true,
            seekForwardTime: skipForward,
            seekBackTime: skipBack,
            volume,
        });

        // When an episode is loaded in the Mac wrapper doesn't receive info whether we're muted, so
        // on first play or whenever a new episode plays the volume is on — even if the UI shows it's
        // muted. This should be fixed in the Mac wrapper, but for now this patches the user experience.
        // See: https://github.com/shiftyjelly/pocketcasts-mac-wrapper/issues/85
        this.setMuted(this.props.muted);

        if (playing) {
            this.startTimer();
        }
    }

    stop() {
        this.send({
            action: 'stop',
        });
        this.stopTimer();
    }

    send(object) {
        const message = JSON.stringify(object);
        Logger.log(`Calling Mac player ${message}`);
        this.player.postMessage(message);
    }

    play() {
        if (!this.playing) {
            this.send({
                action: 'play',
            });
        }
        this.startTimer();
    }

    pause() {
        this.saveProgress();
        this.stopTimer();
        if (this.playing) {
            this.send({
                action: 'pause',
            });
        }
    }

    setVolume(volume) {
        this.send({
            action: 'volume',
            volume,
        });
    }

    setSpeed(speed) {
        this.speed = speed;
        this.send({
            action: 'speed',
            speed,
        });
    }

    setTheme(theme) {
        this.theme = theme;
        this.send({
            action: 'theme',
            theme,
        });
    }

    setUpdatedArtwork(episode) {
        this.send({
            action: 'artworkUpdated',
            imageUrl: this.getImageUrl(episode),
        });
    }

    getImageUrl = episode => {
        const { podcastUuid } = episode;
        if (podcastUuid === UPLOADED_FILES_PODCAST_UUID) {
            return getUploadedFileIconPngUrl(this.props.theme, episode);
        }
        return PodcastHelper.getImageUrl(280, podcastUuid);
    };

    getSpeed() {
        return this.speed;
    }

    setMuted(muted) {
        this.send({
            action: 'muted',
            muted,
        });

        // When the player un-mutes, the Mac wrapper sets to full volume instead of the user-selected volume.
        // Eventually we should fix this from the Mac app side, but for now this patches over the problem.
        // See: https://github.com/shiftyjelly/pocketcasts-mac-wrapper/issues/85
        if (!muted) {
            this.setVolume(this.props.volume);
        }
    }

    setSkipTimes(skipForward, skipBack) {
        this.send({
            action: 'skipTimes',
            skipForward,
            skipBack,
        });
    }

    skipForward() {
        this.send({ action: 'skipForward' });
    }

    skipBack() {
        this.send({ action: 'skipBack' });
    }

    seekTo(timeSecs) {
        if (timeSecs < 0) {
            return;
        }
        this.upTo = timeSecs;
        this.send({
            action: 'seek',
            upTo: timeSecs,
        });
    }

    getDuration() {
        return this.duration >= 0 ? this.duration : -1;
    }

    getCurrentTime() {
        return this.upTo >= 0 ? this.upTo : -1;
    }

    render() {
        return <div className="native-player" />;
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
    updateMuted: muted => dispatch(fromPlayerActions.Actions.updateMuted(muted)),
    updateVolume: volume => dispatch(fromPlayerActions.Actions.updateVolume(volume)),
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    NativePlayer,
);
