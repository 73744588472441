import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import useFormatMessage from 'hooks/useFormatMessage';
import Modal from '../../../../components/Modal';
import { Button } from '../../../../components/Button';
import * as fromSubscriptionActions from '../../../../redux/actions/subscription.actions';
import SetupAccountImage from './setup-account.svg';
import {
    ImageWrapper,
    Spaces,
    TextContainer,
    ModalTitle,
    ModalMessage,
} from './AlreadyPlusSubscriberModal.styled';

function AlreadyPlusSubscriberModal() {
    const formatMessage = useFormatMessage();
    const dispatch = useDispatch();
    const isPromo = useSelector((state: RootState) => {
        return state.subscription.modalData.showAlreadyPlusSubscriberModal?.promo === true;
    });

    const onCloseClicked = () => {
        dispatch(
            fromSubscriptionActions.Actions.hideModalGeneric('showAlreadyPlusSubscriberModal'),
        );
    };

    return (
        <Modal horizontalInsetPx={32} onClose={onCloseClicked}>
            <ImageWrapper>
                <img alt="" width={112} height={112} src={SetupAccountImage} />
            </ImageWrapper>
            <TextContainer>
                <ModalTitle>{formatMessage('modal-already-plus-title')}</ModalTitle>
                <Spaces n={2} />
                <ModalMessage>
                    {isPromo
                        ? formatMessage('modal-already-plus-message-promo')
                        : formatMessage('modal-already-plus-message')}
                </ModalMessage>
                <Spaces n={4} />
            </TextContainer>
            <Button onClick={onCloseClicked} width={355}>
                {formatMessage('done')}
            </Button>
            <Spaces n={4} />
        </Modal>
    );
}

export default AlreadyPlusSubscriberModal;
