import React from 'react';
import styled from 'styled-components';

export const TextWithIconContainer = styled.div`
    display: flex;
    align-items: center;

    svg {
        margin-right: 6px;
        margin-top: -0.15em;
        opacity: 0.6;
    }
`;

type Props = {
    icon: React.ReactNode;
    text: string | React.ReactNode;
    iconMargin?: number;
    className?: string;
};

export function TextWithIcon({ icon, text, className }: Props) {
    return (
        <TextWithIconContainer className={className}>
            {icon}
            {text}
        </TextWithIconContainer>
    );
}
