import styled from 'styled-components';

export const MiniPlayerWrapper = styled.div`
    height: 24px;
    width: 24px;
    position: absolute;
    right: 6px;
    top: 6px;
    cursor: pointer;
    opacity: 0.5;
    background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2210%22%20height%3D%2210%22%20viewBox%3D%220%200%2010%2010%22%3E%3Cpath%20fill%3D%22%23FFF%22%20d%3D%22M6.586%202H3c-.552%200-1-.448-1-1s.448-1%201-1h6c.552%200%201%20.448%201%201v6c0%20.552-.448%201-1%201s-1-.448-1-1V3.414L1.707%209.707c-.39.39-1.024.39-1.414%200-.39-.39-.39-1.024%200-1.414L6.586%202z%22%2F%3E%3C%2Fsvg%3E')
        no-repeat right top;
    &:hover {
        opacity: 1;
        @media (prefers-reduced-motion: no-preference) {
            animation: mini-player-expand 1 400ms ease-in-out;
            animation-direction: alternate;
        }
    }

    @keyframes mini-player-expand {
        0% {
            transform: translate(0px, 0px);
        }
        50% {
            transform: translate(2px, -2px);
        }
        100% {
            transform: translate(0px, 0px);
        }
    }
`;
