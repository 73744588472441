import React, { Component } from 'react';
import { getThemeFromId } from '../../../../../../../model/theme';
import { calculatePartialRingPath } from '../../../../../../../helper/SvgHelper';
import { CancelProgressIndicatorWrapper } from './styled';

export class CancelProgressIndicator extends Component {
    render() {
        const { percent, theme } = this.props;

        const midPoint = 360 * (percent / 100);

        return (
            <CancelProgressIndicatorWrapper onClick={this.props.onClick}>
                <svg width={22} height={22} viewBox="0 0 24 24" fill="none">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M15.5355 8.46424C15.145 8.07372 14.5118 8.07372 14.1213 8.46424L12 10.5856L9.87868 8.46424C9.48816 8.07372 8.85499 8.07372 8.46447 8.46424C8.07394 8.85477 8.07394 9.48793 8.46447 9.87846L10.5858 11.9998L8.46447 14.1211C8.07394 14.5116 8.07394 15.1448 8.46447 15.5353C8.85499 15.9258 9.48816 15.9258 9.87868 15.5353L12 13.414L14.1213 15.5353C14.5118 15.9258 15.145 15.9258 15.5355 15.5353C15.9261 15.1448 15.9261 14.5116 15.5355 14.1211L13.4142 11.9998L15.5355 9.87846C15.9261 9.48793 15.9261 8.85477 15.5355 8.46424Z"
                        fill={getThemeFromId(theme).legacyScss['theme-text-secondary']}
                    />
                    <path
                        stroke={getThemeFromId(theme).legacyScss['theme-text-secondary']}
                        d={calculatePartialRingPath(12, 12, 11, 0, midPoint)}
                        fillRule="evenodd"
                        clipRule="evenodd"
                        strokeWidth="2"
                        fill="none"
                    />
                    <path
                        stroke={getThemeFromId(theme).legacyScss['theme-interactive-secondary']}
                        d={calculatePartialRingPath(12, 12, 11, midPoint, 359.99)}
                        fillRule="evenodd"
                        clipRule="evenodd"
                        strokeWidth="2"
                        fill="none"
                    />
                </svg>
            </CancelProgressIndicatorWrapper>
        );
    }
}

export default CancelProgressIndicator;
