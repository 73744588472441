import { isWindowsApp } from 'helper/Browser';
import { PLAYER_HEIGHT } from 'model/page';
import { DARK_THEME } from 'model/theme/theme-dark';
import { rgba } from 'polished';
import styled, { createGlobalStyle, css } from 'styled-components';

export const PlayerControlsGlobalStyles = createGlobalStyle`
    /* 
    Overriding global-styles.js: Note that for firefox only - for some reason the color 
    contrast does not get detected properly by firefox and the outline is hidden for
    buttons in the playback controls. So we need to manually set the outline 
    color here for accessibility
    */

    div:-moz-focusring,
    button:-moz-focusring {
    outline: 1px dotted white;
    }
    body:hover {
        .video-large {
            opacity: 1;
        }
    }
`;

export const PlayerActions = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;

    ${isWindowsApp() &&
    css`
        & > * {
            margin-right: 24px;
        }

        & > *:last-child {
            margin-right: 0;
        }

        & > *:first-child {
            margin-top: 2px;
            margin-right: -10px;
        }
    `}
`;

export const PlayerControlsWrapper = styled.div`
    height: ${PLAYER_HEIGHT}px;
    width: 100%;
    box-shadow: 0 -2px 2px 0 ${rgba(DARK_THEME.tokens['primary-ui-04'], 0.25)};
    background-color: black;
    position: relative;

    transition: background-color 0.2s ease;
    ${props =>
        props.isUpNextOpen &&
        `
            background-color: ${DARK_THEME.tokens['primary-ui-04']};
            .color-gradient, .player-gradient {
                opacity: 0 !important;
            }
        `}

    .color-gradient {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        background: radial-gradient(
            closest-corner,
            currentColor 0,
            currentColor 30%,
            transparent 100%
        );
        opacity: 0.2;
        transform: scale(1.4);
    }
    .player-gradient {
        position: absolute;
        top: 0;
        width: 100%;
        height: ${PLAYER_HEIGHT}px;

        // This blur is huge and expensive, so to make it run fast we need to put it on its own composite rendering layer
        // using the "translateZ(0)" trick. Without that, any rendering change to the page will also cause the gradient
        // to re-calc the blur, eating CPU faster than a can of Pringles.
        filter: blur(150px);
        transform: translateZ(0); // Enable GPU hardware acceleration

        opacity: 0.8;
        @-moz-document url-prefix() {
            opacity: 0.4;
        }
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50%;
        transition: opacity 0.2s ease;

        @supports (-ms-ime-align: auto) {
            opacity: 0.2;
            filter: none;
            transform: none;
            perspective: none;
        }
    }
    .player-gradient-loader {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 100%;
    }

    &.video-large {
        .color-gradient,
        .player-gradient {
            display: none;
        }
    }
    .audio {
        position: absolute;
        top: 0;
        left: 0;
    }
    .controls {
        position: relative;
        height: ${PLAYER_HEIGHT}px;
        width: 100%;
        display: flex;
        align-items: center;
        .controls-left {
            flex: 0 0 333px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 34px;
            height: ${PLAYER_HEIGHT}px;
            .player-image {
                position: relative;
                width: 65px;
                .video-collapse-button {
                    position: absolute;
                    top: 0;
                    left: 0;
                    background-color: rgba(0, 0, 0, 0.5);
                }
                margin-right: 30px;
                &.grow-on-hover {
                    transition: transform 100ms ease-out;
                    &:hover {
                        transform: scale(1.1);
                    }
                }
            }
        }
        .controls-center {
            min-width: 150px;
            flex: 10 1 auto;
            align-self: flex-start;
            padding-top: 22px;
            text-align: center;
            position: relative;
            font-size: 17.28px;
            font-weight: 400;
            color: #ffffff;
            letter-spacing: -0.2px;
            line-height: 22px;
            height: ${PLAYER_HEIGHT}px;
            margin-bottom: 22px;
            overflow: hidden;
            .episode {
                overflow: hidden;
                height: 22px;
                margin-bottom: 5px;
                white-space: nowrap;
                text-overflow: ellipsis;
                .episode-title {
                    cursor: pointer;
                    user-select: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
            .podcast-title-date,
            .podcast-title {
                opacity: 0.5;
                font-size: 14.4px;
                font-weight: 500;
                line-height: 14px;
                color: #ffffff;
                letter-spacing: -0.18px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                user-select: none;
            }

            .podcast-title {
                text-decoration: none;
                opacity: 1;
                &:hover {
                    text-decoration: underline;
                }
            }

            .seek-bar {
                position: absolute;
                top: 72px;
                left: 0;
            }
            .chapter-previous,
            .chapter-next {
                position: absolute;
                top: 39px;
                width: 11px;
                height: 12px;
                opacity: 0.5;
                &.enabled {
                    opacity: 1;
                    cursor: pointer;
                }
            }
            .chapter-previous {
                left: 0;
                transform: scaleX(-1);
                background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2211%22%20height%3D%2212%22%20viewBox%3D%220%200%2011%2012%22%3E%3Cpath%20fill%3D%22%23FFF%22%20d%3D%22M4.293%2010.293c-.39.39-.39%201.024%200%201.414.39.39%201.024.39%201.414%200l5-5c.39-.39.39-1.024%200-1.414l-5-5c-.39-.39-1.024-.39-1.414%200-.39.39-.39%201.024%200%201.414L8.586%206l-4.293%204.293z%22%2F%3E%3C%2Fsvg%3E');
            }
            .chapter-next {
                right: 0;
                background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2211%22%20height%3D%2212%22%20viewBox%3D%220%200%2011%2012%22%3E%3Cpath%20fill%3D%22%23FFF%22%20d%3D%22M4.293%2010.293c-.39.39-.39%201.024%200%201.414.39.39%201.024.39%201.414%200l5-5c.39-.39.39-1.024%200-1.414l-5-5c-.39-.39-1.024-.39-1.414%200-.39.39-.39%201.024%200%201.414L8.586%206l-4.293%204.293z%22%2F%3E%3C%2Fsvg%3E');
            }
            .error-title {
                display: inline-block;
                font-size: 14px;
                padding-top: 34px;
                color: #ffc12e;

                a {
                    color: #03a9f4;
                    cursor: pointer;
                    text-decoration: none;

                    &:hover {
                        color: #50d0f1;
                    }

                    transition: all 0.1s ease;
                }
            }
        }
        .controls-right {
            flex: 0 0 310px;
            &.large {
                flex: 0 0 350px;
            }
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 30px;
            height: ${PLAYER_HEIGHT}px;
            .chrome-cast-button {
                button {
                    margin-top: 3px;
                }
            }
            .speed-control {
                margin-top: 1px;
            }
        }
        @media (max-width: 960px) {
            .controls-left,
            .controls-right {
                padding: 0 20px;
                flex: 0;
            }
            .player-image {
                width: 48px !important;
                margin-right: 20px !important;
            }
            .play_pause_button {
                width: 80px;
            }
            .skip-back-button,
            .skip-forward-button {
                width: 35px;
                height: 35px;
                background-size: 35px 35px;
                p {
                    font-size: 11px;
                    width: 35px;
                    height: 30px;
                    line-height: 30px;
                }
            }
            .speed-control {
                margin-right: 15px !important;
            }
            .volume-slider {
                margin-right: 5px !important;
            }
        }
    }
    &.video-small {
        .controls {
            .controls-left {
                .player-image {
                    opacity: 0;
                }
            }
        }
    }
`;

export const PlayerBackground = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
`;
