import styled from 'styled-components';
import { H30, H60 } from '../../../styles';

export const DialogIcon = styled.img`
    width: 112px;
    height: 112px;
`;

export const DialogTitle = styled(H30)``;
export const DialogDescription = styled(H60)`
    padding-top: 20px;
`;

export const DialogContent = styled.div`
    padding: 30px;
`;

export const DialogText = styled.div`
    margin: 10px 0px 50px 0px;
`;

export const ModalDialog = styled.div`
    display: flex;
    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 515px;
    background-color: white;

    padding: 30px;
    border-radius: 8px;

    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1002;
`;
