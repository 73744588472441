import React from 'react';
import { LinkProps } from 'react-router-dom';
import { SubscribeButton } from 'components/buttons/SubscribeButton';
import { WrapperLink, Rank, Image, Text, Title, Subtitle } from './ListItem.styled';

export type Props = {
    image: React.ReactNode;
    title: React.ReactNode;
    subtitle: React.ReactNode;
    to: LinkProps['to'];
    onClick?: () => void;
    rank?: number;
    onSubscribe?: () => void;
    podcastUuid?: string;
};

const ListItem = ({
    image,
    title,
    subtitle,
    to,
    onClick,
    rank,
    onSubscribe,
    podcastUuid,
}: Props) => {
    return (
        <WrapperLink onClick={onClick} to={to}>
            {!!rank && <Rank>{rank}</Rank>}
            <Image>{image}</Image>
            <Text>
                <Title>{title || ''}</Title>
                <Subtitle>{subtitle || ''}</Subtitle>
                {podcastUuid && (
                    <SubscribeButton
                        podcastUuid={podcastUuid}
                        onSubscribe={onSubscribe}
                        eventSource="discover"
                    />
                )}
            </Text>
        </WrapperLink>
    );
};

export default ListItem;
