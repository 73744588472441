import styled, { css } from 'styled-components';

const SeekBarTracksMixin = css`
    height: 3px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 9px;
`;

export const SeekBarWrapper = styled.div<{ isBuffering: boolean }>`
    width: 100%;
    .tracks {
        position: relative;
        height: 23px;
        cursor: pointer;
        &:disabled {
            opacity: 0.5;
        }
        width: 100%;
        .buffering-bar {
            ${SeekBarTracksMixin};
            height: 3px;
            border-radius: 1.5px;
            width: 100%;
            opacity: 0;
            visibility: hidden;
            transition: opacity 600ms, visibility 600ms;
            background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+Cjxzdmcgd2lkdGg9IjEyIiBoZWlnaHQ9IjMiIHZpZXdQb3J0PSIwIDAgMTIgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogIDxlbGxpcHNlIGN4PSIxLjUiIGN5PSIxLjUiIHJ4PSIxLjUiIHJ5PSIxLjUiIGZpbGw9InJnYmEoMjU1LDI1NSwyNTUsMC4yKSI+CiAgICA8YW5pbWF0ZSBhdHRyaWJ1dGVOYW1lPSJjeCIgZnJvbT0iMiIgdG89Ii0xMCIgZHVyPSIwLjZzIiByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSIgLz4KICA8L2VsbGlwc2U+CiAgPGVsbGlwc2UgY3g9IjE0IiBjeT0iMS41IiByeD0iMS41IiByeT0iMS41IiBmaWxsPSJyZ2JhKDI1NSwyNTUsMjU1LDAuMikiPgogICAgPGFuaW1hdGUgYXR0cmlidXRlTmFtZT0iY3giIGZyb209IjE0IiB0bz0iMiIgZHVyPSIwLjZzIiByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSIgLz4KICA8L2VsbGlwc2U+Cjwvc3ZnPgo=');
        }
        .track-bar {
            ${SeekBarTracksMixin};
            height: 3px;
            background: rgba(255, 255, 255, 0.1);
            border-radius: 1.5px;
            opacity: 1;
            transition: opacity 0.5s;
        }
        .buffered-bar {
            ${SeekBarTracksMixin};
            height: 3px;
            border-radius: 1.5px;
            background: rgba(255, 255, 255, 0.2);
        }
        .played-bar {
            ${SeekBarTracksMixin};
            height: 3px;
            border-radius: 1.5px;
        }
        .knob-bar {
            left: 0;
            top: 3px;
            right: 14px;
            position: absolute;
            .knob {
                position: absolute;
                height: 13px;
                width: 13px;
                border-radius: 50%;
                background-color: ${props => props.theme.tokens['contrast-01']};
                top: 1px;
                left: 0;
                box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.5);
                z-index: 100;
                cursor: pointer;
            }
        }
        ${props =>
            props.isBuffering &&
            `
                .buffering-bar {
                    opacity: 1;
                    visibility: visible;
                }
                .track-bar {
                    opacity: 0;
                }
        `};
    }
    .chapters {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.2);
        .chapter {
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            .dot {
                position: absolute;
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background-color: ${props => props.theme.tokens['contrast-01']};
                top: 8px;
                opacity: 0.7;
                &:hover {
                    transform: scale(1.5);
                }
            }
            .hit {
                position: absolute;
                width: 21px;
                height: 21px;
                cursor: pointer;
                z-index: 99;
            }
        }
    }

    .current-time,
    .time-remaining {
        position: absolute;
        top: 20px;

        opacity: 0.5;
        font-size: 12px;
        font-weight: 500;
        line-height: 22px;
    }
    .current-time {
        left: 0;
    }
    .time-remaining {
        right: 0;
        &:before {
            content: '-';
        }
    }
`;
