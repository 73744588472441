import React, { Component } from 'react';
import { red50 } from 'styles';
import { Icon } from 'components/Icon';
import { DynamicPie } from '../../../../../../components/DynamicPie';
import { getSizeStringFromBytes } from '../../../../../../helper/UploadedFilesHelper';
import CancelProgressIndicator from './CancelProgressIndicator';
import UploadErrorTooltip from './UploadErrorTooltip';
import { UploadingFileItemWrapper, FileNameText, FileSizeText, IconWrapper } from './styled';

export class UploadingFileItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hovering: false,
        };
    }

    onMouseEnter = () => this.setState({ hovering: true });

    onMouseLeave = () => this.setState({ hovering: false });

    cancelUpload = () => {
        const { uuid } = this.props;
        this.props.cancelFileUpload(uuid);
    };

    render() {
        const { hideBorder } = this.props;
        const { name, progress, size, error, xhr } = this.props.file;
        const { hovering } = this.state;

        const percent = (100 * progress) / size;

        // Flex-direction of wrapper is row-reverse
        return (
            <UploadingFileItemWrapper
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
                hideBorder={hideBorder}
            >
                <IconWrapper>
                    {error ? (
                        <Icon id="bang-circle" size={22} color={red50} />
                    ) : hovering && percent < 100 && xhr ? (
                        <CancelProgressIndicator percent={percent} onClick={this.cancelUpload} />
                    ) : (
                        <DynamicPie size={22} percent={percent} />
                    )}
                </IconWrapper>
                <FileSizeText>{getSizeStringFromBytes(size)}</FileSizeText>
                <FileNameText>{name}</FileNameText>
                {error && hovering && (
                    <UploadErrorTooltip
                        fileName={name}
                        message={error}
                        dismissNowCallback={this.onMouseLeave}
                    />
                )}
            </UploadingFileItemWrapper>
        );
    }
}

export default UploadingFileItem;
