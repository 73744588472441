import React from 'react';
import useFormatMessage from 'hooks/useFormatMessage';
import { Icon } from 'components/Icon';
import { UploadImageButtonWrapper, UploadImageLabel, UploadImageInput } from './styled';

const UPLOAD_IMAGE_INPUT_ID = 'file-custom-image-upload';

const UploadImageButton = ({ uuid, requestImageUploadPreflight, onMouseEnter, onMouseLeave }) => {
    const formatMessage = useFormatMessage();
    const fileUploadInputChanged = event => {
        const file = (event.target.files && event.target.files[0]) || null;

        if (file !== null) {
            requestImageUploadPreflight(uuid, file);
        }
    };

    return (
        <UploadImageButtonWrapper>
            <UploadImageLabel
                htmlFor={UPLOAD_IMAGE_INPUT_ID}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                <Icon id="image-add" aria-label={formatMessage('upload-custom-image')} />
            </UploadImageLabel>
            <UploadImageInput
                id={UPLOAD_IMAGE_INPUT_ID}
                type="file"
                onChange={fileUploadInputChanged}
            />
        </UploadImageButtonWrapper>
    );
};

export default UploadImageButton;
