import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Portal } from 'react-portal';
import { FocusOn } from 'react-focus-on';
import { Icon } from 'components/Icon';
import { onKeyDownEnter } from '../../../../helper/UiHelper';
import { PopupChoiceItem } from './EditFolderContextMenu.styled';
import { PopupBase, PopupBackgroundBase, PopupWindowBase } from '../../../../styles/popups';
import { usePopupContextMenu } from '../../../../hooks/usePopupContextMenu';

type Props = {
    onClosed: () => void;
    onEditDetailsClick: () => void;
    onEditPodcastsClick: () => void;
    anchorEl: Element;
    ariaAttributes: Record<string, string>;
};

function EditFolderContextMenu({
    onClosed,
    onEditDetailsClick,
    onEditPodcastsClick,
    anchorEl,
    ariaAttributes,
}: Props) {
    const { popupWindowRef, frameRef, offsets, menuItemRefs } = usePopupContextMenu({
        onClosed,
        anchorEl,
        offsets: {
            left: 110,
            top: 10,
        },
        size: 2,
    });

    return (
        <Portal node={document && document.getElementById('modal-root')}>
            <PopupBase>
                <FocusOn ref={frameRef} scrollLock={false} noIsolation>
                    <PopupWindowBase
                        ref={popupWindowRef as React.MutableRefObject<HTMLDivElement>}
                        aria-labelledby={ariaAttributes['aria-labelledby']}
                        offsets={offsets}
                    >
                        <PopupChoiceItem
                            ref={menuItemRefs[0] as React.MutableRefObject<HTMLDivElement>}
                            onClick={onEditDetailsClick}
                            onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    onClosed();
                                    onEditDetailsClick();
                                }
                            }}
                        >
                            <Icon id="pencil" />
                            <FormattedMessage id="edit-folder" />
                        </PopupChoiceItem>
                        <PopupChoiceItem
                            ref={menuItemRefs[1] as React.MutableRefObject<HTMLDivElement>}
                            onClick={onEditPodcastsClick}
                            onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    onClosed();
                                    onEditPodcastsClick();
                                }
                            }}
                        >
                            <Icon id="grid-small" />
                            <FormattedMessage id="add-or-remove-podcasts" />
                        </PopupChoiceItem>
                    </PopupWindowBase>
                    <PopupBackgroundBase
                        onKeyPress={event => onKeyDownEnter(event, onClosed)}
                        onClick={onClosed}
                    />
                </FocusOn>
            </PopupBase>
        </Portal>
    );
}

export default EditFolderContextMenu;
