import { PodcastTintColors } from 'model/types';
import {
    blue50,
    coolGrey50,
    green50,
    orange50,
    pink50,
    purple60,
    red50,
    yellow50,
} from '../styles';

export const getSafeLightDarkTintColors = (colors: PodcastTintColors): PodcastTintColors => {
    if (!colors || !colors.lightTint || !colors.darkTint) {
        return {
            lightTint: blue50,
            darkTint: '#1BA0DC',
            darkTintUnsafe: '#1BA0DC',
            background: '#000000',
        };
    }
    return colors;
};

export const getThemeColorsForUploadedFileFromColorId = (id: number) => {
    switch (id) {
        default:
        case 1: {
            return {
                lightTint: coolGrey50,
                darkTint: '#BDC2C4',
                darkTintUnsafe: '#BDC2C4',
                background: '#000000',
            };
        }
        case 2: {
            return {
                lightTint: red50,
                darkTint: '#DF514C',
                darkTintUnsafe: '#DF514C',
                background: '#000000',
            };
        }
        case 3: {
            return {
                lightTint: blue50,
                darkTint: '#1BA0DC',
                darkTintUnsafe: '#1BA0DC',
                background: '#000000',
            };
        }
        case 4: {
            return {
                lightTint: green50,
                darkTint: '#7FBF5F',
                darkTintUnsafe: '#7FBF5F',
                background: '#000000',
            };
        }
        case 5: {
            return {
                lightTint: yellow50,
                darkTint: '#EABD49',
                darkTintUnsafe: '#EABD49',
                background: '#000000',
            };
        }
        case 6: {
            return {
                lightTint: orange50,
                darkTint: '#EB9D4F',
                darkTintUnsafe: '#EB9D4F',
                background: '#000000',
            };
        }
        case 7: {
            return {
                lightTint: purple60,
                darkTint: '#6749AB',
                darkTintUnsafe: '#6749AB',
                background: '#000000',
            };
        }
        case 8: {
            return {
                lightTint: pink50,
                darkTint: '#D24D7A',
                darkTintUnsafe: '#D24D7A',
                background: '#000000',
            };
        }
    }
};

export const lightenColor = (color: string, percent: number) => {
    const f = parseInt(color.slice(1), 16);
    const t = percent < 0 ? 0 : 255;
    const p = percent < 0 ? percent * -1 : percent;
    const R = f >> 16;
    const G = (f >> 8) & 0x00ff;
    const B = f & 0x0000ff;

    return `#${(
        0x1000000 +
        (Math.round((t - R) * p) + R) * 0x10000 +
        (Math.round((t - G) * p) + G) * 0x100 +
        (Math.round((t - B) * p) + B)
    )
        .toString(16)
        .slice(1)}`;
};

// https://stackoverflow.com/a/5624139
export const hexToRgb = (hex: string) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
          }
        : null;
};

// Borrowed from https://css-tricks.com/converting-color-spaces-in-javascript/
export const rgbToHue = (rgb: string) => {
    // Convert hex to RGB first
    let r = 0;
    let g = 0;
    let b = 0;

    if (rgb.length === 4) {
        r = parseInt(`0x${rgb[1]}${rgb[1]}`, 16);
        g = parseInt(`0x${rgb[2]}${rgb[2]}`, 16);
        b = parseInt(`0x${rgb[3]}${rgb[3]}`, 16);
    } else if (rgb.length === 7) {
        r = parseInt(`0x${rgb[1]}${rgb[2]}`, 16);
        g = parseInt(`0x${rgb[3]}${rgb[4]}`, 16);
        b = parseInt(`0x${rgb[5]}${rgb[6]}`, 16);
    }
    // Then to HSL
    r /= 255;
    g /= 255;
    b /= 255;
    const cmin = Math.min(r, g, b);
    const cmax = Math.max(r, g, b);
    const delta = cmax - cmin;
    let h = 0;

    if (delta === 0) h = 0;
    else if (cmax === r) h = ((g - b) / delta) % 6;
    else if (cmax === g) h = (b - r) / delta + 2;
    else h = (r - g) / delta + 4;

    h = Math.round(h * 60);

    if (h < 0) h += 360;

    return h;
};
