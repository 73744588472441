import { ModalTypes } from 'helper/UiHelper';
import {
    Episode,
    EpisodeAndPodcastUuidsArray,
    EpisodeWithSyncInfo,
    PodcastCacheParsed,
    TracksProperties,
} from 'model/types';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from 'redux/reducers';
import { getPodcastRating, getShowArchivedForPodcast } from 'redux/reducers/selectors';
import {
    getBookmarksForPodcast,
    getEpisodeSyncForPodcast,
} from 'redux/reducers/selectors/episode-sync.selectors';
import { getSortedEpisodesWithSyncForPodcast } from 'redux/reducers/selectors/podcasts.selectors';
import { blue } from 'styles';
import { getSafeLightDarkTintColors } from '../../helper/ColorHelper';
import * as EpisodeHelper from '../../helper/EpisodeHelper';
import { PlayingStatus } from '../../helper/PlayingStatus';
import { EpisodeSortOrder } from '../../helper/PodcastHelper';
import { getPodcastTint } from '../../model/theme';
import * as fromEpisodeActions from '../../redux/actions/episode.actions';
import * as fromModalActions from '../../redux/actions/modal.actions';
import * as fromPodcastActions from '../../redux/actions/podcast.actions';
import * as fromPodcastsActions from '../../redux/actions/podcasts.actions';
import * as fromShareActions from '../../redux/actions/share.actions';
import * as fromTracksActions from '../../redux/actions/tracks.actions';
import PodcastPage, { Props } from './PodcastPage';

const mapStateToProps = (state: RootState, ownProps: Props) => {
    const { uuid } = ownProps.match.params;

    const podcast = state.podcasts.uuidToPodcast[uuid];
    const isSubscribed = state.podcasts.subscribedUuids.includes(uuid);
    const colors = getSafeLightDarkTintColors(state.podcasts.uuidToColors[uuid]);
    const color = getPodcastTint(colors, state.settings.theme) ?? blue;
    const uuidToEpisodeSync = getEpisodeSyncForPodcast(state, uuid);
    const searchTermPresent = state.podcast.searchTerm;
    const sortedEpisodes = getSortedEpisodesWithSyncForPodcast(state, uuid);
    const showArchived = getShowArchivedForPodcast(state, uuid);

    const episodeCount = sortedEpisodes?.length ?? 0;
    let archivedCount = 0;
    sortedEpisodes?.forEach((episode: Episode) => {
        const episodeSync = uuidToEpisodeSync[episode.uuid];
        if (episodeSync && episodeSync.isDeleted) {
            archivedCount += 1;
        }
    });

    let episodes: EpisodeWithSyncInfo[];
    if (!sortedEpisodes) {
        episodes = [];
    } else if (searchTermPresent) {
        const searchUuidMatches = state.podcast.searchEpisodes.map(episode => episode.uuid);
        episodes = sortedEpisodes.filter((episode: Episode) =>
            searchUuidMatches.includes(episode.uuid),
        );
    } else {
        episodes = showArchived
            ? sortedEpisodes
            : sortedEpisodes.filter(
                  (episode: Episode) => !EpisodeHelper.isArchived(uuidToEpisodeSync[episode.uuid]),
              );
    }

    let episodeSortOrder =
        state.podcasts.uuidToPodcast[uuid] && state.podcasts.uuidToPodcast[uuid].episodesSortOrder;

    // This two-step is necessary because 0 is a valid sort order
    if (episodeSortOrder === undefined || episodeSortOrder === null) {
        episodeSortOrder = EpisodeSortOrder.NEWEST_TO_OLDEST;
    }

    const anyPlayedButNotArchived = !!sortedEpisodes?.find(
        (episode: Episode) =>
            uuidToEpisodeSync[episode.uuid] &&
            uuidToEpisodeSync[episode.uuid].playingStatus === PlayingStatus.COMPLETED &&
            !uuidToEpisodeSync[episode.uuid].isDeleted,
    );

    const allArchived = episodeCount > 0 && episodeCount === archivedCount;
    return {
        isSubscribed,
        uuid,
        podcast,
        noEpisodes: !sortedEpisodes?.length,
        episodes,
        allEpisodes: sortedEpisodes ?? [],
        episodeCount,
        archivedCount,
        anyPlayedButNotArchived,
        allArchived,
        searchTerm: state.podcast.searchTerm,
        episodeSortOrder,
        uuidToEpisodeSync,
        loadFailed: state.podcast.loadFailed,
        isSearching: state.podcast.isSearching,
        isLoadingEpisodes: sortedEpisodes === null,
        player: state.player,
        color,
        settings: state.settings,
        showArchived,
        upNext: state.upNext,
        theme: state.settings.theme,
        bookmarks: getBookmarksForPodcast(state, uuid),
        rating: getPodcastRating(state, uuid),
    };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: Props) => ({
    openPodcast: (podcastUuid: string) =>
        dispatch(fromPodcastActions.Actions.openPodcast(podcastUuid)),
    updateEpisodeOrder: (uuid: string, sortOrder: number) =>
        dispatch(fromPodcastActions.Actions.updateEpisodeOrder(uuid, sortOrder)),
    subscribeToPodcast: (podcast: PodcastCacheParsed) =>
        dispatch(
            fromPodcastsActions.Actions.subscribeToPodcast(podcast, {
                eventSource: 'podcast_screen',
            }),
        ),
    openEpisode: (...args: Parameters<typeof fromEpisodeActions.Actions.openEpisode>) =>
        dispatch(fromEpisodeActions.Actions.openEpisode(...args)),
    unsubscribeFromPodcast: (podcastUuid: string) =>
        dispatch(
            fromPodcastsActions.Actions.unsubscribeFromPodcast(podcastUuid, {
                eventSource: 'podcast_screen',
            }),
        ),
    showArchivedEpisodes: (podcastUuid: string, showArchived: boolean) =>
        dispatch(fromPodcastActions.Actions.showArchived(podcastUuid, showArchived)),
    searchEpisodes: (term: string, podcastUuid: string) =>
        dispatch(fromPodcastActions.Actions.searchEpisodes(term, podcastUuid)),
    openPodcastShare: (podcastUuid: string) =>
        dispatch(
            fromShareActions.Actions.openPodcastShare(podcastUuid, {
                eventSource: 'podcast_screen',
            }),
        ),
    recordEvent: (event: string, properties?: TracksProperties) =>
        dispatch(fromTracksActions.Actions.recordEvent(event, properties)),
    showArchiveAllConfirmation: (
        episodeUuidAndPodcastUuids: EpisodeAndPodcastUuidsArray,
        playedOnly = false,
    ) =>
        dispatch(
            fromPodcastActions.Actions.showArchiveAllConfirmation(
                episodeUuidAndPodcastUuids,
                playedOnly,
            ),
        ),
    unarchiveAll: (episodeUuidAndPodcastUuids: EpisodeAndPodcastUuidsArray) =>
        dispatch(fromPodcastActions.Actions.unarchiveAll(episodeUuidAndPodcastUuids)),

    fetchPodcastRating: () =>
        dispatch(
            fromPodcastsActions.Actions.fetchPodcastRating({ uuid: ownProps.match.params.uuid }),
        ),
    openBookmarks: () =>
        dispatch(
            fromModalActions.Actions.showModal(ModalTypes.listBookmarks, {
                podcastUuid: ownProps.match.params.uuid,
            }),
        ),
    openRatingModal: () => {
        dispatch(fromPodcastActions.Actions.fetchUserRating(ownProps.match.params.uuid));
        dispatch(
            fromModalActions.Actions.showModal(ModalTypes.ratePodcast, {
                podcastUuid: ownProps.match.params.uuid,
            }),
        );
    },
});

const ConnectedPage = connect(mapStateToProps, mapDispatchToProps)(PodcastPage);

export { ConnectedPage as PodcastPage };
