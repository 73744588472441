import { connect } from 'react-redux';
import * as fromModalActions from 'redux/actions/modal.actions';
import * as fromSettingsActions from '../../redux/actions/settings.actions';
import * as fromSubscriptionActions from '../../redux/actions/subscription.actions';
import * as fromTracksEvents from '../../redux/actions/tracks.actions';
import * as fromUploadedFilesActions from '../../redux/actions/uploaded-files.actions';
import * as fromUserActions from '../../redux/actions/user.actions';
import { isOnPaddleFreeTrial } from '../../redux/reducers/selectors';
import LoggedInPageChrome from './view';

const mapStateToProps = state => {
    // State for episode modal
    const { episode } = state;

    return {
        episode,
        playerEpisode: state.player.episode,
        isOnPaddleFreeTrial: isOnPaddleFreeTrial(state),
        upNextOpen: state.upNext.open,
        chaptersOpen: state.player.chaptersOpen,
        shareOpen: state.share ? state.share.open : false,
        email: state.user.email,
        settings: state.settings,
        showRecommendations: state.settings.showRecommendations && state.settings.recommendationsOn,
        archiveAll: state.archiveAll,
        deleteFile: state.uploadedFiles.fileToDelete,
        editFile: state.uploadedFiles.fileToEdit,
        showClearHistoryConfirmation: state.filter.showClearHistoryConfirmation,
        theme: state.settings.theme,

        shouldShowEmailChangeModal: state.user.shouldShowEmailChangeModal,
        shouldShowPasswordChangeModal: state.user.shouldShowPasswordChangeModal,
        shouldShowAccountDeleteModal: state.user.shouldShowAccountDeleteModal,
        shouldShowFarewellModal: state.subscription.showFarewellModal,
        showCancelSubscriptionModal: state.subscription.modals.showCancelModal,
        showBillingDetailsModal: state.subscription.modals.showChangeBillingDetailsModal,
        shouldShowCheckoutModal: !!state.subscription.showCheckoutModal,

        shouldShowThankYouModal: state.subscription.showThankYouModalWelcomeBack,
        shouldShowAlreadyPlusSubscriberModal:
            state.subscription.modals.showAlreadyPlusSubscriberModal,
        shouldShowKeyboardShortcuts: state.settings.keyboardShortcutsOpen,

        modals: state.modals,
    };
};

const mapDispatchToProps = dispatch => ({
    toggleTheme: () => dispatch(fromSettingsActions.Actions.toggleTheme()),
    updateTheme: theme => dispatch(fromSettingsActions.Actions.updateTheme(theme)),
    openCloseKeyboardShortcuts: () =>
        dispatch(fromSettingsActions.Actions.openCloseKeyboardShortcuts()),
    showFarewellModal: () => {
        dispatch(fromSubscriptionActions.Actions.fetchSubscriptionData());
        dispatch(fromSubscriptionActions.Actions.showFarewellModal());
    },
    hideFarewellModal: () => dispatch(fromSubscriptionActions.Actions.hideFarewellModal()),
    showCheckoutModal: type => dispatch(fromSubscriptionActions.Actions.showCheckoutModal(type)),
    hideCheckoutModal: () => {
        dispatch(fromSubscriptionActions.Actions.fetchSubscriptionData());
        dispatch(fromSubscriptionActions.Actions.hideCheckoutModal());
    },
    showThankYouModal: () => dispatch(fromSubscriptionActions.Actions.showThankYouModal(true)),
    hideRatingModal: (dismissedEvent = false) => {
        // If we submitted a rating, we don't consider the modal dismissed
        // We also need to handle two different possible modal types:
        // rating_screen and not_allowed_to_rate_screen
        if (dismissedEvent) {
            dispatch(fromTracksEvents.Actions.recordEvent(dismissedEvent));
        }
        dispatch(fromModalActions.Actions.hideModal());
    },
    requestFileUploadPreflight: file =>
        dispatch(fromUploadedFilesActions.Actions.requestFileUploadPreflight(file)),
    requestImageUploadPreflight: (fileUuid, imageFile) =>
        dispatch(fromUploadedFilesActions.Actions.requestImageUploadPreflight(fileUuid, imageFile)),
    userReturned: () => dispatch(fromUserActions.Actions.userReturned()),
    updatePreferredColorScheme: theme =>
        dispatch(fromSettingsActions.Actions.updatePreferredColorScheme(theme)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoggedInPageChrome);
