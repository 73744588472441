import {
    MinusButton,
    NumberButton,
    PlusButton,
} from 'pages/LoggedInPageChrome/PlayerControls/SpeedControl/styled';
import styled, { css } from 'styled-components';
import { Typography, gridSize } from '../../styles';

const FadeInMixin = css`
    @keyframes fade {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    opacity: 1;

    @media (prefers-reduced-motion: no-preference) {
        animation: fade 0.7s linear forwards;
    }
`;

export const PodcastSettingsPageWrapper = styled.div``;

export const SettingsSection = styled.div`
    h2 {
        ${Typography.Desktop['H80-BC']}
        color: ${props => props.theme.tokens['primary-text-02']};
    }

    margin-bottom: 32px;
`;

export const PageHeader = styled.div`
    padding: ${2 * gridSize}px 0;
    h1 {
        ${Typography.Desktop.H30};
    }
`;

export const SettingsRowDescription = styled.p`
    ${Typography.Desktop.H70};
    ${FadeInMixin};
    color: ${props => props.theme.tokens['primary-text-02']};
`;

export const SettingsRowError = styled.p`
    ${Typography.Desktop.H70};
    ${FadeInMixin};
    color: ${props => props.theme.tokens['support-05']};
`;

export const SettingsRow = styled.div`
    display: flex;
    flex-direction: row;

    align-items: center;

    padding-top: 18px;
    padding-bottom: 18px;

    border-bottom: 1px solid ${props => props.theme.tokens['primary-ui-05']};
`;

export const SpeedSettingsRow = styled(SettingsRow)`
    padding-top: 0;
    padding-bottom: 10px;
    border-bottom: 0;

    ${props =>
        props.theme.name === 'light' &&
        css`
            ${PlusButton}, ${MinusButton} {
                background-color: ${props => props.theme.tokens['primary-field-03']};
            }

            ${NumberButton} {
                color: ${props => props.theme.tokens['primary-text-01']};
                border: 2px solid ${props => props.theme.tokens['primary-field-03']};
            }
        `}
`;

export const SettingsRowName = styled.p`
    ${Typography.Desktop['H70-B']};
    min-width: 60px;
    margin-right: 116px;
`;

export const RowTitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 215px;
    white-space: nowrap;

    svg {
        margin-right: 15px;
        width: 24px;
        height: 24px;
        min-height: 24px;
        min-width: 24px;
        color: ${props => props.theme.tokens['primary-icon-01']};
    }
`;

export const MinutesSettingsText = styled.div`
    width: 300px;
    margin-right: 142px;
`;

export const SwitchWrapper = styled.div`
    margin-right: 40px;
`;

export const EffectSettingWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 142px;
`;

export const MissingEffectsMessage = styled.p`
    ${Typography.Desktop.H80};
    color: ${props => props.theme.tokens['primary-text-02']};
    margin-top: 10px;
`;

export const SettingsSwitchWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 40px;
`;
