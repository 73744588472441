import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import useFormatMessage from 'hooks/useFormatMessage';
import { Icon } from 'components/Icon';
import * as fromPodcastActions from '../../../redux/actions/podcast.actions';
import { Button } from '../../Button';
import { ArchiveAllPopupWrapper } from './ArchiveAllPopup.styled';

function ArchiveAllPopup() {
    const dispatch = useDispatch();
    const formatMessage = useFormatMessage();

    const { episodes, playedOnly } = useSelector((state: RootState) => ({
        episodes: state.archiveAll?.episodeUuidAndPodcastUuids,
        playedOnly: state.archiveAll?.playedOnly,
    }));

    const headerText = playedOnly
        ? formatMessage('archive-all-played-episodes')
        : formatMessage('archive-all-episodes');
    const cancelText = formatMessage('cancel');
    const confirmText = formatMessage('confirm');

    const onCancel = () => dispatch(fromPodcastActions.Actions.dismissArchiveAllConfirmation());

    const onConfirmClick = () => {
        dispatch(fromPodcastActions.Actions.archiveAll(episodes));
        dispatch(fromPodcastActions.Actions.dismissArchiveAllConfirmation());
    };

    return (
        <ArchiveAllPopupWrapper onClose={onCancel}>
            <div className="dialog-content">
                <div className="icon-wrapper">
                    <Icon id="archive-all" size={30} />
                </div>
                <h2>{headerText}</h2>
                <div className="body-text">
                    {formatMessage('this-will-archive-n-episodes', { number: episodes.length })}
                </div>
                <div className="buttons">
                    <Button kind="secondary" width={163} onClick={onCancel}>
                        {cancelText}
                    </Button>
                    <div style={{ width: '16px' }} />
                    <Button width={163} onClick={onConfirmClick}>
                        {confirmText}
                    </Button>
                </div>
            </div>
        </ArchiveAllPopupWrapper>
    );
}

export default ArchiveAllPopup;
