import { connect } from 'react-redux';
import * as fromUploadedFilesActions from '../../../../redux/actions/uploaded-files.actions';
import FileDeleteModal from './view';

const mapStateToProps = state => ({
    uuid: state.uploadedFiles.fileToDelete.uuid,
    title: state.uploadedFiles.fileToDelete.title,
    theme: state.settings.theme,
});

const mapDispatchToProps = dispatch => ({
    onClose: () => dispatch(fromUploadedFilesActions.Actions.dismissDeleteFileConfirmation()),
    onConfirm: uuid => {
        dispatch(fromUploadedFilesActions.Actions.requestDeleteFile(uuid));
        dispatch(fromUploadedFilesActions.Actions.dismissDeleteFileConfirmation());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(FileDeleteModal);
