import React from 'react';
import { FormattedMessage } from 'react-intl';
import { put, takeEvery } from 'redux-saga/effects';
import * as fromUserActions from '../actions/user.actions';
import * as fromFlagsActions from '../actions/flags.actions';

/*
 * Unlike the other Saga files, this file cuts across domains and contains watchers
 * for anything that needs to interact with the Flag system.
 */

function* flagEmailChangeResponse(
    action: ReturnType<typeof fromUserActions.Actions.emailChangeResponse>,
) {
    const { success } = action.payload;

    if (success) {
        yield put(
            fromFlagsActions.Actions.addFlag(<FormattedMessage id="email-change-successful" />),
        );
    }
}

function* flagPasswordChangeResponse(
    action: ReturnType<typeof fromUserActions.Actions.passwordChangeResponse>,
) {
    const { success } = action.payload;

    if (success) {
        yield put(
            fromFlagsActions.Actions.addFlag(<FormattedMessage id="password-change-successful" />),
        );
    }
}

export function* watchEmailChangeResponse() {
    yield takeEvery(fromUserActions.ActionTypes.EMAIL_CHANGE_RESPONSE, flagEmailChangeResponse);
}

export function* watchPasswordChangeResponse() {
    yield takeEvery(
        fromUserActions.ActionTypes.PASSWORD_CHANGE_RESPONSE,
        flagPasswordChangeResponse,
    );
}
