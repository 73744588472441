import PropTypes from 'prop-types';
import ImageDropZone from './view';

export { imageDropZoneIdPrefix as IMAGE_DROP_ZONE_ID_PREFIX } from './model';

ImageDropZone.propTypes = {
    forceClose: PropTypes.func.isRequired,
};

export default ImageDropZone;
