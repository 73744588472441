import styled from 'styled-components';
import { ModernStyle } from 'styles/ModernStyle.styled';

export const LoggedOutPageWrapper = styled(ModernStyle)`
    width: 100vw;
    height: 100%;

    color: ${props => props.theme.legacyScss['theme-text-primary']};
    background: ${props => props.theme.legacyScss['theme-background']};

    overflow: auto;
`;
