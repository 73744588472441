import { Icon } from 'components/Icon';
import { SLIDING_MODAL_ANIMATION_TIME_IN_MS } from 'components/SlidingModal/SlidingModal.styled';
import { isMacApp } from 'helper/Browser';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { PAGE_MAX_WIDTH, PAGE_MAX_WIDTH_FOR_SMALL_NAVIGATION } from '../../../model/page';
import {
    Typography,
    black,
    coolGrey50,
    darkGrey10,
    darkGrey30,
    gridSize,
    red50,
    white,
} from '../../../styles';

export const NavigationWrapper = styled.nav`
    position: relative;
    flex-shrink: 0;

    padding-bottom: ${2 * gridSize}px;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;

    background-color: ${props => props.theme.legacyScss['theme-background']};
    box-shadow: inset -1px 0 0 ${props => props.theme.legacyScss['theme-line']};

    @media (min-width: ${PAGE_MAX_WIDTH_FOR_SMALL_NAVIGATION}px) and (max-width: ${PAGE_MAX_WIDTH}px) {
        box-shadow: none;
    }
`;

export const Spaces = styled.div<{ n?: number }>`
    height: ${props => gridSize * (props.n || 1)}px;
`;

export const LogoLink = styled(Link)`
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    height: ${8 * gridSize}px;
    padding-left: ${3 * gridSize}px;

    ${isMacApp() && `padding-top: ${3 * gridSize}px;`}

    display: flex;
    align-items: center;

    text-decoration: none;

    z-index: 21;

    svg.logo {
        width: 32px;
        height: 32px;
        @media (min-width: ${PAGE_MAX_WIDTH_FOR_SMALL_NAVIGATION}px) {
            width: 40px;
            height: 40px;
        }
    }

    svg.logo path {
        transition: fill ${SLIDING_MODAL_ANIMATION_TIME_IN_MS}ms ease;
    }
`;

export const NavigationLink = styled(Link)<{ selected: boolean }>`
    ${props => (props.selected ? Typography.Desktop['H60-B'] : Typography.Desktop.H60)}
    line-height: 1;

    height: ${6 * gridSize}px;
    width: ${10 * gridSize}px;
    display: flex;
    align-items: center;
    justify-content: center;

    text-decoration: none;

    color: ${props =>
        props.selected ? (props.theme.name === 'dark' ? white : darkGrey30) : coolGrey50};

    &:hover {
        color: ${red50};
    }

    @media (min-width: ${PAGE_MAX_WIDTH_FOR_SMALL_NAVIGATION}px) {
        width: ${30 * gridSize}px;
        justify-content: flex-start;
        transition: transform 0.1s ease-in-out;
        transform: translateX(${props => (props.selected ? gridSize : 0)}px);
        &:hover {
            transform: translateX(${gridSize}px);
        }
        color: ${props => {
            if (props.theme.name === 'dark') {
                return props.selected ? white : coolGrey50;
            }
            return props.selected ? black : darkGrey10;
        }} !important;
    }
`;

export const LinkIcon = styled(Icon)`
    @media (min-width: ${PAGE_MAX_WIDTH_FOR_SMALL_NAVIGATION}px) {
        width: 26px;
        height: 26px;
        margin-left: ${3 * gridSize}px;
        margin-right: ${2 * gridSize}px;
    }
`;

export const LinkNotch = styled.svg<{ selected: boolean }>`
    position: absolute;
    right: 100%;
    fill: ${props => (props.selected ? red50 : coolGrey50)};
`;

export const LinkText = styled.span`
    height: 0.9em;
    display: none;
    @media (min-width: ${PAGE_MAX_WIDTH_FOR_SMALL_NAVIGATION}px) {
        display: block;
    }
`;

export const ScrollingCover = styled.div`
    position: sticky;
    width: calc(100% - 1px); // Leave pixel on right so we don't cover the border line
    height: ${8 * gridSize}px;
    margin-top: -${8 * gridSize}px;
    background: blue;
    top: 0;
    left: 0;
    z-index: 1;
    background: ${props => props.theme.legacyScss['theme-background']};
    opacity: 0.8;
`;
