import useFormatMessage from 'hooks/useFormatMessage';
import React from 'react';
import { SkipBackButtonContainer } from './SkipBackButton.styled';

type Props = {
    amount?: number;
    onSkipBackClicked: () => void;
};

const SkipBackButton = ({ amount, onSkipBackClicked }: Props) => {
    const formatMessage = useFormatMessage();
    return (
        <SkipBackButtonContainer
            className="skip-back-button skip_back_button"
            tabIndex={0}
            onClick={onSkipBackClicked}
            onMouseDown={e => e.preventDefault()}
            aria-label={`${formatMessage('skip-backwards')} ${amount}`}
        >
            {amount && <p>{amount}</p>}
        </SkipBackButtonContainer>
    );
};

export default SkipBackButton;
