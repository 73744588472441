import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FilePlayCircle from './view';

// The purpose of this component is to localise the fetch of the player playedUpTo so that we
// don't have to re-render the whole table every time the player updates its playedUpTo

FilePlayCircle.propTypes = {
    size: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired,
    playedColor: PropTypes.string.isRequired,
    isPlayed: PropTypes.bool.isRequired,
    isPlaying: PropTypes.bool.isRequired,
    onPauseClick: PropTypes.func.isRequired,
    onPlayClick: PropTypes.func.isRequired,

    duration: PropTypes.number.isRequired,
};

const mapStateToProps = (state, ownProps) => {
    const playedUpTo = state.player.episode ? state.player.episode.playedUpTo : 0;

    return {
        percent: 100 * (playedUpTo / Math.max(1, ownProps.duration)),
    };
};

export default connect(mapStateToProps)(FilePlayCircle);
