import styled from 'styled-components';

const StyledChapterButtonWrapper = styled.div`
    height: 48px;
    display: flex;
    justify-content: center;
    &:hover svg * {
        fill-opacity: 1;
    }
`;

export default StyledChapterButtonWrapper;
