import { EpisodeImage } from 'components/EpisodeImage';
import { Icon } from 'components/Icon';
import {
    Row,
    RowAction,
    RowCell,
    RowContent,
    RowHandle,
    RowImage,
    RowPlayPauseButton,
    RowTitle,
} from 'components/Row';
import { isFuture, parseISO } from 'date-fns';
import { DurationStringFromSeconds } from 'helper/DurationHelper';
import { PlayingStatus } from 'helper/PlayingStatus';
import useFormatMessage from 'hooks/useFormatMessage';
import { EpisodeSyncInfo, UpNextEpisode } from 'model/types';
import { UPLOADED_FILES_PODCAST_UUID } from 'model/uploaded-files';
import React, { useEffect } from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import { useTheme } from 'styled-components';
import { DateText, PodcastImage, PopupMenu } from '../../../../components';
import { UploadedFileMessage } from '../../../../components/messages';
import { ImageTextOption } from '../../../../components/popup/ImageTextOption';
import { DurationCell } from './UpNextRow.styled';

type Props = {
    episode: UpNextEpisode;
    episodeSync: EpisodeSyncInfo;
    podcast: { uuid: string; title: string };
    openEpisode: () => void;
    removeFromUpNext: () => void;
    playEpisode: () => void;
    upNextPlayNext?: () => void;
    upNextPlayLast?: () => void;
    isSorting: boolean;
    style: React.CSSProperties;
    onRequestPodcastDownload: (podcastUuid: string) => void;
};

const SortableDragHandle = SortableHandle(RowHandle);

export function UpNextRow({
    episode,
    episodeSync,
    podcast,
    openEpisode,
    playEpisode,
    removeFromUpNext,
    upNextPlayNext,
    upNextPlayLast,
    isSorting,
    style,
    onRequestPodcastDownload,
}: Props) {
    const formatMessage = useFormatMessage();
    const theme = useTheme();
    const getDate = () => {
        const { published } = episode;
        const displayDate = isFuture(parseISO(published)) ? new Date().toUTCString() : published;
        if (!displayDate) {
            return '';
        }
        return <DateText date={displayDate} showToday={true} />;
    };

    useEffect(() => {
        if (!podcast && episode.podcast !== UPLOADED_FILES_PODCAST_UUID && episode.podcast) {
            onRequestPodcastDownload(episode.podcast);
        }
    }, [podcast, episode.podcast, onRequestPodcastDownload]);

    const podcastTitle =
        episode.podcastUuid === UPLOADED_FILES_PODCAST_UUID
            ? UploadedFileMessage()
            : podcast?.title;

    const percentComplete = episodeSync.duration
        ? ((episodeSync.playedUpTo || 0) / episodeSync.duration) * 100
        : 0;

    const isUploadedFile = episode.podcastUuid === UPLOADED_FILES_PODCAST_UUID;

    return (
        <Row
            onClick={openEpisode}
            isSorting={isSorting}
            tabIndex={0}
            style={style}
            role="listitem"
            aria-label={`${episode.title}, ${podcastTitle}`}
            aria-describedby="up-next-dnd-instructions"
        >
            <RowContent>
                <RowImage>
                    {isUploadedFile && <EpisodeImage episode={episode} />}
                    {!isUploadedFile && <PodcastImage uuid={episode.podcastUuid} />}
                </RowImage>
                <RowTitle>
                    <h2>{episode.title}</h2>
                    {podcastTitle ?? <>&nbsp;</>}
                </RowTitle>
                <RowCell>{getDate()}</RowCell>
                <DurationCell>
                    {!!episodeSync.duration && (
                        <DurationStringFromSeconds
                            durationSecsStrOrNum={Math.max(
                                0,
                                (episodeSync.duration || 0) - (episodeSync.playedUpTo || 0),
                            )}
                            remaining={!!episodeSync.playedUpTo}
                            short={true}
                        />
                    )}
                </DurationCell>
                <RowAction
                    onClick={e => {
                        e.stopPropagation();
                        removeFromUpNext();
                    }}
                    aria-label={formatMessage('remove-up-next')}
                >
                    <Icon id="remove-from-upnext" />
                </RowAction>
                <RowCell>
                    <PopupMenu id="up-next-popup" aria-label="Options">
                        {upNextPlayNext && (
                            <ImageTextOption
                                icon="move-to-top"
                                color={theme.tokens['primary-icon-01']}
                                text={formatMessage('play-next')}
                                onClick={upNextPlayNext}
                            />
                        )}
                        {upNextPlayLast && (
                            <ImageTextOption
                                icon="move-to-bottom"
                                color={theme.tokens['primary-icon-01']}
                                text={formatMessage('play-last')}
                                onClick={upNextPlayLast}
                            />
                        )}
                        <ImageTextOption
                            icon="remove-from-upnext"
                            color={theme.tokens['support-05']}
                            text={formatMessage('remove-up-next')}
                            onClick={removeFromUpNext}
                        />
                    </PopupMenu>
                </RowCell>
                <RowCell>
                    <RowPlayPauseButton
                        size={28}
                        color="currentColor"
                        playedColor="currentColor"
                        isPlaying={false}
                        isPlayed={episodeSync.playingStatus === PlayingStatus.COMPLETED}
                        percent={percentComplete}
                        onPlayClick={playEpisode}
                        onPauseClick={() => ({})}
                    />
                </RowCell>
            </RowContent>
            <SortableDragHandle />
        </Row>
    );
}

export default UpNextRow;
