import React from 'react';
import { RowActionsStyled } from './RowActions.styled';

type Props = {
    children: React.ReactNode;
    placeholder: React.ReactNode;
};

export const RowActions = ({ children, placeholder }: Props) => {
    return (
        <RowActionsStyled>
            <div className="action-buttons">
                <div className="show-on-hover">{children}</div>
                {placeholder && <div className="hide-on-hover">{placeholder}</div>}
            </div>
        </RowActionsStyled>
    );
};
