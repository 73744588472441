import styled, { css } from 'styled-components';
import { Typography, black, white } from 'styles';

export const SliderWrapper = styled.div`
    width: 300px;
    margin: 0 auto;
`;

export const Dots = styled.ul`
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;

    list-style: none;
    padding-left: 0;

    top: -22px;
    // We need to use a different top value for Firefox because of
    // the way it renders the slider thumb and range differently
    @supports (-moz-appearance: none) {
        top: -29px;
    }
    z-index: 1;
`;

export const Dot = styled.li`
    width: 11px;
    height: 11px;
    background-color: ${props => props.theme.tokens['primary-ui-07']};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: var(--dot-left);

    cursor: pointer;

    &.selected {
        background-color: ${white};
        z-index: 10;
        ${props =>
            props.theme.name === 'light' &&
            css`
                background: ${black};
            `}
    }
`;

export const SliderComponent = styled.input`
    width: 100%;
    display: flex;
    cursor: pointer;
    z-index: 2;
    outline: none;
    background: transparent;
    appearance: none;

    margin-top: 20px;
    // We need to use a different margin-top value for Firefox because of
    // the way it renders the slider thumb and range with differnt spacing
    @supports (-moz-appearance: none) {
        margin-top: 12px;
    }

    // Combining the ::-webkit-slider-* and ::-moz-range- selectors
    // casues layout issues in Chrome, so we need to separate them
    &::-webkit-slider-runnable-track {
        -webkit-appearance: none;
        width: 100%;
        height: 3px;
        cursor: pointer;
        box-shadow: none;
        background: ${props => props.theme.tokens['primary-ui-07']};
        border-radius: 8px;
        border: none;
    }

    &::-moz-range-track {
        -moz-appearance: none;
        width: 100%;
        height: 3px;
        cursor: pointer;
        box-shadow: none;
        background: ${props => props.theme.tokens['primary-ui-07']};
        border-radius: 8px;
        border: none;
    }

    &::-moz-range-thumb {
        background: transparent;
        border: none;
        pointer-events: none;
    }

    &::-webkit-slider-thumb {
        display: none;
        pointer-events: none;
    }
`;

export const DurationLabels = styled.div`
    width: 300px;
    display: flex;
    justify-content: space-between;
    color: ${props => props.theme.legacyScss['theme-text-secondary']};
    ${Typography.Desktop['H70-B']};
    position: absolute;
    left: -2px;

    top: 178px;

    button {
        max-width: 25px;
        position: absolute;
        left: var(--dot-left);
        margin-left: 9px;

        &.selected {
            color: ${white};
            ${props =>
                props.theme.name === 'light' &&
                css`
                    color: ${black};
                `}
        }

        &.over-an-hour {
            margin-left: 2px;
        }
    }
`;
