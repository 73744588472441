import React from 'react';
import { FormattedDate, useIntl } from 'react-intl';
import { selectUnit } from 'helper/TimeHelper';
import { isThisYear, isToday, isYesterday, parseISO } from 'date-fns';

export type Props = {
    date?: string | null;
    showToday?: boolean;
};

const capitalize = (text: string) => text[0].toUpperCase() + text.slice(1);

function DateText({ date = null, showToday = false }: Props) {
    const intl = useIntl();
    const formatDate = (dateStr: string | null) => {
        if (!dateStr || dateStr.length === 0) {
            return '';
        }

        const date = new Date(dateStr);
        if (showToday) {
            if (isToday(parseISO(date.toISOString()))) {
                const { value, unit } = selectUnit(date, new Date());
                return capitalize(
                    intl.formatRelativeTime(value, unit, {
                        numeric: 'auto',
                    }),
                );
            }
            if (isYesterday(parseISO(date.toISOString()))) {
                return capitalize(
                    intl.formatRelativeTime(-1, 'day', {
                        numeric: 'auto',
                    }),
                );
            }
        }
        if (isThisYear(parseISO(date.toISOString()))) {
            return <FormattedDate value={dateStr} day="numeric" month="long" />;
        }

        return <FormattedDate value={dateStr} day="numeric" month="short" year="numeric" />;
    };
    return <span className="date-text">{formatDate(date)}</span>;
}

export default DateText;
