import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import NotificationContainer from './view';

NotificationContainer.propTypes = {
    playerOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    filesAreUploading: !!state.uploadManager.filesOrder.length,
    flags: state.flags,
});

export default connect(mapStateToProps)(NotificationContainer);
