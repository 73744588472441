import { RootState } from '..';

export const getUpNext = (state: RootState) => state.upNext || {};

export const getUpNextServerModified = (state: RootState) =>
    state.upNext && state.upNext.serverModified ? state.upNext.serverModified : 0;

export const getUpNextTopEpisode = (state: RootState) => {
    if (
        state.upNext &&
        state.upNext.order &&
        state.upNext.order.length > 0 &&
        state.upNext.episodes
    ) {
        const episodeUuid = state.upNext.order[0];
        const episode = (state.upNext.episodes || {})[episodeUuid];
        return episode ? Object.assign(episode, { uuid: episodeUuid }) : null;
    }
    return null;
};

export const getUpNextEpisodeWithUuid = (state: RootState, episodeUuid: string) =>
    state.upNext && state.upNext.episodes && state.upNext.episodes[episodeUuid];

export const getQueuedEpisodeUuids = (state: RootState) =>
    state.upNext.order.slice(1, state.upNext.order.length);

export const isEpisodeInUpNext = (state: RootState, episodeUuid: string) =>
    !!getUpNextEpisodeWithUuid(state, episodeUuid);

export const getUpNextIsOpen = (state: RootState) => state.upNext.open;
