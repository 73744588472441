import StyledLink from 'components/StyledLink';
import { isElectronApp, isMacApp, isWindowsApp } from 'helper/Browser';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ErrorMessageWrapper } from './ErrorMessage.styled';

type Props = {
    title?: string;
    description?: string;
};

function ErrorMessage({ title, description }: Props) {
    const isDesktopApp = isWindowsApp() || isMacApp() || isElectronApp();

    return (
        <ErrorMessageWrapper className="error-message">
            <div className="title">{title ?? <FormattedMessage id={'error-default-title'} />}</div>
            <div className="description">{description}</div>
            <div className="contact">
                <FormattedMessage
                    id={isDesktopApp ? 'error-contact-us-desktop' : 'error-contact-us-web'}
                    values={{
                        contactUsLink: (
                            <StyledLink href="mailto:support@pocketcasts.com">
                                <FormattedMessage id="contact-us" />
                            </StyledLink>
                        ),
                    }}
                />
            </div>
        </ErrorMessageWrapper>
    );
}

export default ErrorMessage;
