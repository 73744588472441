import { useEffect } from 'react';

interface Props {
    callback: () => void;
}

/**
 * Helper component that will just run the provided callback on mount
 */
function OnMount({ callback }: Props) {
    useEffect(() => {
        callback?.();
    }, [callback]);
    return null;
}

export default OnMount;
