import styled from 'styled-components';
import { gridSize, H30, H70 } from '../../../../styles';

export const ImageWrapper = styled.div`
    display: flex;
    width: ${14 * gridSize}px;
    height: ${14 * gridSize}px;
    padding-bottom: ${4 * gridSize}px;

    align-items: center;
    margin-left: 20px;
`;
export const Spaces = styled.div<{ n: number }>`
    height: ${props => gridSize * (props.n || 1)}px;
`;

export const TextContainer = styled.div`
    text-align: center;
    max-width: 330px;
`;

export const ModalTitle = styled(H30)``;

export const ModalMessage = styled(H70)``;

export const ModalContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
