import {
    KeyboardShortcut,
    NavigateBackShortcut,
    NavigateForwardShortcut,
} from 'components/KeyboardShortcut';
import { ModalMonitor } from 'components/ModalMonitor';
import useFormatMessage from 'hooks/useFormatMessage';
import React from 'react';
import { useDispatch } from 'react-redux';
import * as fromSettingsActions from '../../../redux/actions/settings.actions';
import { CloseButton } from '../../index';
import { Popup } from '../Popup';
import { Container, Group, GroupHeading, Heading, Shortcut } from './KeyboardShortcuts.styled';

function KeyboardShortcuts() {
    const dispatch = useDispatch();
    const formatMessage = useFormatMessage();

    const onCloseKeyboardShortcuts = () => {
        dispatch(fromSettingsActions.Actions.closeKeyboardShortcuts());
    };

    return (
        <Popup isOpen={true} width={700} height={512} onClosed={onCloseKeyboardShortcuts}>
            <ModalMonitor onCloseModals={onCloseKeyboardShortcuts} />
            <Container>
                <CloseButton onClick={onCloseKeyboardShortcuts} />
                <Heading>{formatMessage('keyboard-shortcuts')}</Heading>
                <Group>
                    <GroupHeading>{formatMessage('navigation')}</GroupHeading>
                    <Shortcut>
                        <KeyboardShortcut>1</KeyboardShortcut>
                        {formatMessage('podcasts')}
                    </Shortcut>
                    <Shortcut>
                        <KeyboardShortcut>2</KeyboardShortcut>
                        {formatMessage('discover')}
                    </Shortcut>
                    <Shortcut>
                        <KeyboardShortcut>3</KeyboardShortcut>
                        {formatMessage('new-releases')}
                    </Shortcut>
                    <Shortcut>
                        <KeyboardShortcut>4</KeyboardShortcut>
                        {formatMessage('in-progress')}
                    </Shortcut>
                    <Shortcut>
                        <KeyboardShortcut>5</KeyboardShortcut>
                        {formatMessage('starred')}
                    </Shortcut>
                    <Shortcut>
                        <KeyboardShortcut>H</KeyboardShortcut>
                        {formatMessage('history')}
                    </Shortcut>
                    <Shortcut>
                        <KeyboardShortcut>U</KeyboardShortcut>
                        {formatMessage('up-next')}
                    </Shortcut>
                    <Shortcut>
                        <KeyboardShortcut>?</KeyboardShortcut>
                        {formatMessage('keyboard-shortcuts')}
                    </Shortcut>
                    <Shortcut>
                        <KeyboardShortcut>S</KeyboardShortcut>
                        {formatMessage('search')}
                    </Shortcut>
                    <Shortcut>
                        <NavigateBackShortcut />
                        {formatMessage('navigation-go-back')}
                    </Shortcut>
                    <Shortcut>
                        <NavigateForwardShortcut />
                        {formatMessage('navigation-go-forward')}
                    </Shortcut>{' '}
                </Group>
                <Group>
                    <GroupHeading>{formatMessage('playback')}</GroupHeading>
                    <Shortcut>
                        <KeyboardShortcut>{formatMessage('space-bar-short-word')}</KeyboardShortcut>
                        {formatMessage('play-pause')}
                    </Shortcut>
                    <Shortcut>
                        <KeyboardShortcut>F</KeyboardShortcut>
                        {formatMessage('toggle-video-full-screen')}
                    </Shortcut>
                    <Shortcut>
                        <KeyboardShortcut right />
                        {formatMessage('skip-forwards')}
                    </Shortcut>
                    <Shortcut>
                        <KeyboardShortcut left />
                        {formatMessage('skip-backwards')}
                    </Shortcut>
                    <Shortcut>
                        <KeyboardShortcut>M</KeyboardShortcut>
                        {formatMessage('mute-sound')}
                    </Shortcut>
                    <Shortcut>
                        <KeyboardShortcut>E</KeyboardShortcut>
                        {formatMessage('open-episode-popup')}
                    </Shortcut>
                    <Shortcut>
                        <KeyboardShortcut>-</KeyboardShortcut>
                        {formatMessage('reduce-speed')}
                    </Shortcut>
                    <Shortcut>
                        <KeyboardShortcut>+</KeyboardShortcut>
                        {formatMessage('increase-speed')}
                    </Shortcut>
                    <GroupHeading>{formatMessage('settings')}</GroupHeading>
                    <Shortcut>
                        <KeyboardShortcut>T</KeyboardShortcut>
                        {formatMessage('change-theme')}
                    </Shortcut>
                </Group>
            </Container>
        </Popup>
    );
}

export default KeyboardShortcuts;
