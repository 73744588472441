import React, { Component } from 'react';
import { DynamicPlayPauseCircle } from '../../../../components/DynamicPlayPauseCircle';

export class FilePlayCircle extends Component {
    render() {
        return <DynamicPlayPauseCircle {...this.props} />;
    }
}

export default FilePlayCircle;
