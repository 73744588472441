import React, {
    ChangeEventHandler,
    FormEvent,
    MouseEvent,
    TransitionEvent,
    useRef,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uniq from 'lodash/uniq';
import useFormatMessage from 'hooks/useFormatMessage';
import useTracks from 'hooks/useTracks';
import { getGridLayout, getGridOrder } from 'redux/reducers/selectors';
import { api } from 'services/api';
import { PodcastCount } from 'components/messages';
import { Button } from 'components/Button';
import Input from 'components/Input';
import { BackButton } from 'components/BackButton';
import { TrackOnMount } from 'components/Tracks';
import {
    Folder,
    FormStatus,
    PodcastListPodcast,
    TracksEventSource,
    UnsavedFolder,
} from 'model/types';
import { PodcastGridLayouts } from 'pages/PodcastsPage/model';
import * as fromFoldersActions from 'redux/actions/folders.actions';
import * as fromPodcastsActions from 'redux/actions/podcasts.actions';
import { FolderImage } from 'components/FolderImage';
import { GenericErrorMessage } from 'components/GenericErrorMessage';
import { FolderPodcastsSelector } from '../FolderPodcastsSelector';
import { FolderColorSelector } from '../FolderColorSelector';
import {
    ErrorMessage,
    Footer,
    FormSection,
    MultiStepWrapper,
    Preview,
    PreviewImage,
    PreviewName,
    PreviewText,
    SlidingStepContainer,
    Step,
} from './FolderCreateForm.styled';

export type Props = {
    defaultPodcasts?: string[];
    eventSource: TracksEventSource;
    onSuccess: (folder: Folder) => void;
};

const FolderCreateForm = ({ defaultPodcasts = [], eventSource, onSuccess }: Props) => {
    const dispatch = useDispatch();
    const gridLayout = useSelector(getGridLayout);
    const gridOrder = useSelector(getGridOrder);
    const formatMessage = useFormatMessage();
    const { recordEvent } = useTracks();

    const podcastsRef = useRef<HTMLInputElement>(null);
    const nameRef = useRef<HTMLInputElement>(null);
    const stepRefs = [podcastsRef, nameRef];

    const [currentStep, setCurrentStep] = useState(0);
    const [formStatus, setFormStatus] = useState<FormStatus>(FormStatus.READY);
    const [formData, setFormData] = useState<UnsavedFolder & { podcasts: string[] }>({
        name: '',
        color: 0,
        podcasts: defaultPodcasts,
        sortPosition: 0,
        sortType: gridOrder,
    });
    const [filteredPodcasts, setFilteredPodcasts] = useState<PodcastListPodcast[]>([]);

    const hasSelectedAllFilteredPodcasts = () =>
        !filteredPodcasts.some(podcast => formData.podcasts.indexOf(podcast.uuid) === -1);

    const getSanitizedName = () => formData.name.trim() || formatMessage('new-folder');

    const handleNameChange: ChangeEventHandler<HTMLInputElement> = e =>
        setFormData({ ...formData, name: e.currentTarget.value });

    const handleColorChange = (color: number) => setFormData({ ...formData, color });

    const handlePodcastsChange = (podcastUuids: string[]) =>
        setFormData({ ...formData, podcasts: podcastUuids });

    const submitForm = (e: FormEvent) => {
        e.preventDefault();
        setFormStatus(FormStatus.SUBMITTING);
        const newFolder: UnsavedFolder = {
            name: getSanitizedName(),
            color: formData.color,
            sortPosition: formData.sortPosition,
            sortType: formData.sortType,
        };

        api.createFolder(newFolder, formData.podcasts).then(
            folder => {
                // Add the folder to Redux state
                dispatch(fromFoldersActions.Actions.addFolder(folder, formData.podcasts));
                // Then ensure that the folder is in the first position by explicitly moving it there.
                dispatch(
                    fromPodcastsActions.Actions.rearrangePodcastList([
                        ['MOVE', folder.uuid, 'home', 0],
                    ]),
                );

                setFormStatus(FormStatus.READY);
                recordEvent('folder_saved', {
                    number_of_podcasts: formData.podcasts.length,
                    color: folder.color,
                });
                onSuccess(folder);
            },
            () => {
                setFormStatus(FormStatus.ERROR);
            },
        );
    };

    const handleStepChangeClick = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        const step = Number(e.currentTarget.value);
        setCurrentStep(step);
    };

    const handleStepTransitionEnd = (e: TransitionEvent<HTMLDivElement>) => {
        // Once the step transition has ended and the new step is fully in view, focus on the new step
        if (!(e.target as HTMLDivElement).classList.contains('sliding-step-container')) {
            return;
        }
        if (e.propertyName !== 'transform') {
            return;
        }
        stepRefs[currentStep].current?.focus();
    };

    const handleSelectAllClick = () =>
        hasSelectedAllFilteredPodcasts()
            ? handlePodcastsChange(
                  formData.podcasts.filter(
                      uuid => !filteredPodcasts.some(podcast => uuid === podcast.uuid),
                  ),
              )
            : handlePodcastsChange(
                  uniq([...filteredPodcasts.map(p => p.uuid), ...formData.podcasts]),
              );

    return (
        <form onSubmit={submitForm}>
            <TrackOnMount event="folder_create_shown" properties={{ source: eventSource }} />
            <MultiStepWrapper stepCount={2} currentStep={currentStep}>
                <SlidingStepContainer
                    className="sliding-step-container"
                    onTransitionEnd={handleStepTransitionEnd}
                >
                    <Step isOpen={currentStep === 0}>
                        <FormSection fullWidth={true}>
                            <FolderPodcastsSelector
                                selected={formData.podcasts}
                                onChange={handlePodcastsChange}
                                onFilteredPodcastsChange={setFilteredPodcasts}
                                innerRef={podcastsRef}
                            />
                        </FormSection>
                        <Footer>
                            <Button onClick={handleStepChangeClick} value={1}>
                                {formData.podcasts.length === 0
                                    ? formatMessage('continue')
                                    : formData.podcasts.length === 1
                                    ? formatMessage('choose-podcasts-singular')
                                    : formatMessage('choose-podcasts-plural', {
                                          count: formData.podcasts.length,
                                      })}
                            </Button>
                            <Button kind="text" type="button" onClick={handleSelectAllClick}>
                                {formatMessage(
                                    hasSelectedAllFilteredPodcasts()
                                        ? 'unselect-all'
                                        : 'select-all',
                                )}
                            </Button>
                        </Footer>
                    </Step>

                    <Step isOpen={currentStep === 1}>
                        {currentStep === 1 && (
                            <TrackOnMount
                                event="folder_create_name_shown"
                                properties={{ number_of_podcasts: formData.podcasts.length }}
                            />
                        )}
                        <FormSection>
                            <Input
                                icon="folder"
                                value={formData.name}
                                onChange={handleNameChange}
                                aria-label={formatMessage('folder-name')}
                                placeholder={formatMessage('folder-name')}
                                ref={nameRef}
                                maxLength={100}
                            />
                        </FormSection>

                        <FormSection>
                            <FolderColorSelector
                                value={formData.color}
                                onChange={handleColorChange}
                            />
                        </FormSection>

                        <FormSection>
                            <Preview aria-label={formatMessage('preview')}>
                                {gridLayout === PodcastGridLayouts.LIST ? (
                                    <>
                                        <PreviewText>
                                            <PreviewName>{getSanitizedName()}</PreviewName>
                                            <PodcastCount count={formData.podcasts.length} />
                                        </PreviewText>
                                        <PreviewImage>
                                            <FolderImage
                                                name={formData.name}
                                                color={formData.color}
                                                podcastUuids={formData.podcasts}
                                                size={60}
                                                showName={false}
                                                sortType={formData.sortType}
                                            />
                                        </PreviewImage>
                                    </>
                                ) : (
                                    <FolderImage
                                        name={getSanitizedName()}
                                        color={formData.color}
                                        podcastUuids={formData.podcasts}
                                        size={
                                            gridLayout === PodcastGridLayouts.GRID_LARGE ? 130 : 80
                                        }
                                        sortType={formData.sortType}
                                    />
                                )}
                            </Preview>
                        </FormSection>

                        <Footer>
                            {formStatus === FormStatus.ERROR && (
                                <ErrorMessage>
                                    <GenericErrorMessage />
                                </ErrorMessage>
                            )}
                            <Button loading={formStatus === FormStatus.SUBMITTING} type="submit">
                                {formatMessage('create-folder')}
                            </Button>
                            <BackButton
                                buttonText={formatMessage('back')}
                                onClick={handleStepChangeClick}
                            />
                        </Footer>
                    </Step>
                </SlidingStepContainer>
            </MultiStepWrapper>
        </form>
    );
};

export default FolderCreateForm;
