import LiquidMetal from 'liquidmetal';
import { RootState } from 'redux/reducers';
import { getFolders, getPodcastUuidsByFolderUuid } from './folders.selectors';
import { getSubscribedPodcasts, getSubscribedPodcastUuids } from './podcasts.selectors';

export const getRawSearchResponse = (state: RootState, term: string) =>
    state.search.responses[term];

export const getSearchResponse = (state: RootState, term: string) => {
    const response = state.search.responses[term];
    if (!response) {
        return response;
    }

    const matchingSubscriptionUuids = getMatchingSubscriptions(state, term).map(item => item.uuid);
    const dedupedPodcasts = response?.podcasts?.filter(
        podcast => !matchingSubscriptionUuids.includes(podcast.uuid),
    );

    return { ...response, podcasts: dedupedPodcasts };
};

export const getMatchingSubscriptions = (state: RootState, term: string) => {
    const termLower = term.toLowerCase();
    const podcastUuidsByFolderUuid = getPodcastUuidsByFolderUuid(state);
    const folders = getFolders(state).map(folder => ({
        uuid: folder.uuid,
        name: folder.name,
        sortType: folder.sortType,
        color: folder.color,
        podcastUuids: podcastUuidsByFolderUuid[folder.uuid],
    }));
    const podcasts = getSubscribedPodcasts(state);

    // Gather match scores for each Folder/Podcast. We require that the search term exactly appears,
    // and then we use LiquidMetal to score by best match.
    const matchScores: Record<string, number> = {};
    folders.forEach(folder => {
        matchScores[folder.uuid] =
            folder.name.toLowerCase().indexOf(termLower) === -1
                ? 0
                : LiquidMetal.score(folder.name, term);
    });
    podcasts.forEach(podcast => {
        const title = typeof podcast.title === 'string' ? podcast.title : '';
        const author = typeof podcast.author === 'string' ? podcast.author : '';
        matchScores[podcast.uuid] = Math.max(
            title.toLowerCase().indexOf(termLower) === -1 ? 0 : LiquidMetal.score(title, term),
            author.toLowerCase().indexOf(termLower) === -1 ? 0 : LiquidMetal.score(author, term),
        );
    });

    // Return mixed list of Folders and Podcasts, sorted by best match first
    return [...folders, ...podcasts]
        .filter(item => matchScores[item.uuid] > 0)
        .sort((a, b) => (matchScores[a.uuid] > matchScores[b.uuid] ? -1 : 1));
};

export const getSearchHistory = (state: RootState) => {
    const subscribedPodcastUuids = getSubscribedPodcastUuids(state);
    return state.search.history.map(item => {
        if ('podcast' in item) {
            // Attach current subscribed value to podcasts
            return {
                podcast: item.podcast,
                subscribed: subscribedPodcastUuids.includes(item.podcast.uuid),
            };
        }
        return item;
    });
};
