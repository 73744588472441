import styled from 'styled-components';

export const CancelProgressIndicatorWrapper = styled.div`
    display: flex;
    width: 22px;
    height: 22px;

    cursor: pointer;
    z-index: 11;
`;
