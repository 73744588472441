import styled from 'styled-components';
import { blue50, gridSize, H70 } from '../../../../../styles';

export const UploadManagerHeaderWrapper = styled.div`
    display: flex;
    height: ${9 * gridSize}px;

    align-items: center;
    ${props =>
        !props.collapsed && `border-bottom: 1px solid ${props.theme.legacyScss['theme-line']};`}
`;

export const HeaderContentWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const UploadingIconWrapper = styled.div`
    display: flex;
    width: ${7 * gridSize}px;
    height: ${7 * gridSize}px;

    align-items: center;
    justify-content: center;

    border-radius: 4px;
    margin-left: ${gridSize}px;
    margin-right: ${gridSize}px;
`;

export const InformationWrapper = styled.div`
    display: flex;
    width: ${27 * gridSize}px;

    flex-direction: column;
    margin-left: ${gridSize}px;
    margin-right: ${gridSize}px;
`;

export const ShowHideFilesText = styled(H70)`
    color: ${blue50};
    cursor: pointer;
`;

export const DismissText = styled(H70)`
    color: ${blue50};
    cursor: pointer;
`;

export const StatusWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const StatusIconWrapper = styled.div`
    display: flex;
    width: 22px;
    height: 22px;
    align-items: center;
    margin-left: 30px;
`;
