import PropTypes from 'prop-types';
import UsageMeter from './view';

UsageMeter.propTypes = {
    percent: PropTypes.number.isRequired,
    percentExact: PropTypes.number.isRequired,
    theme: PropTypes.number.isRequired,
};

export default UsageMeter;
