import styled from 'styled-components';
import { blue50, gridSize, Typography } from '../../../styles';

export const VersionSubtitle = styled.p`
    ${Typography.Desktop.H70};
    color: ${props => props.theme.tokens['primary-text-02']};
    margin-left: ${9 * gridSize}px;
`;

export const VersionWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

export const VersionStats = styled.div`
    display: flex;
    flex-direction: column;

    margin-right: ${5 * gridSize}px;
`;

export const VersionDetails = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    color: ${blue50};
    margin-top: 4px;
`;

export const VersionNumber = styled.p`
    ${Typography.Desktop.H60};
    color: ${props => props.theme.tokens['primary-text-01']};
`;

export const VersionDate = styled.p`
    ${Typography.Desktop['H80-B']};
    color: ${props => props.theme.tokens['primary-text-02']};
`;

export const AboutLogo = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 272px;
    height: 54px;
`;

export const SettingsAboutWrapper = styled.div`
    white-space: normal;
    .loader-square {
        margin: 100px auto;
    }
    .local-version {
        ${Typography.Desktop.H60};
    }
    p {
        padding-top: 4px;
    }
    .separator {
        margin: 20px 0 30px 0;
    }
    .about-content {
        ${Typography.Desktop.H70};
        .release-notes {
            a {
                color: ${props => props.theme.tokens['primary-interactive-01']};
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
            .version-wrapper {
                display: flex;
                flex-direction: row;
                margin-bottom: 48px;

                .version-stats {
                    display: flex;
                    flex-direction: column;
                    margin-right: 40px;
                    min-width: 218px;

                    .version-number {
                        ${Typography.Desktop.H60};
                        color: ${props => props.theme.tokens['primary-text-01']};
                    }

                    .version-date {
                        ${Typography.Desktop['H80-B']};
                        padding: 4px 0 0 0;
                        color: ${props => props.theme.tokens['primary-text-02']};
                    }
                }
                .version-details {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;

                    p {
                        padding: 0;
                        margin: 0 0 1.5em;
                    }
                    ul {
                        margin: 0;
                        padding: 0;
                        list-style-type: '-  ';
                    }
                    li {
                        padding: 0 0 0 0.33em;
                        margin: 0 0 1.25em;
                        p {
                            margin-bottom: 0.75em;
                        }
                    }
                    img {
                        display: block;
                        max-width: 100%;
                    }

                    .emoji {
                        font-size: 18px;
                        line-height: 22px;
                        vertical-align: middle;
                        margin-right: 4px;
                    }
                }
            }
            .separator {
                width: 100%;
                border-bottom: 1px solid ${props => props.theme.tokens['primary-ui-05']};
            }
        }
    }
`;

export const StyledBeta = styled.div`
    margin-top: 12px;
`;
