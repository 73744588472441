import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import useFormatMessage from 'hooks/useFormatMessage';
import { RootState } from 'redux/reducers';
import { Logo as PlusLogo } from '../../components/PocketcastsLogo';
import { LoginRegisterForm } from '../../components/form/LoginRegisterForm';
import * as fromUserActions from '../../redux/actions/user.actions';
import * as fromSubscriptionActions from '../../redux/actions/subscription.actions';
import { LoaderSquare } from '../../components';
import { TrialDescription, Wrapper } from './RedeemPromoPage.styled';
import { PromoErrorModal } from './PromoErrorModal';
import { PromoExpiredModal } from './PromoExpiredModal';

type Props = RouteComponentProps<{ id: string }>;

function RedeemPromoPage({ match }: Props) {
    const dispatch = useDispatch();
    const formatMessage = useFormatMessage();
    const promoCode = match.params.id;
    const {
        isValidating,
        isRedeeming,
        showMiscelleneousErrorModal,
        errorMessage,
        validatedTrial,
        showExpiredInvalidCodeModal,
    } = useSelector((state: RootState) => {
        const { trial } = state.subscription;
        const serverError = state.user.errorMessage ?? undefined;
        const showMiscelleneousErrorModal = (() => {
            if (trial.errorMessage && !serverError) {
                return true;
            }
            return false;
        })();
        const showExpiredInvalidCodeModal = trial.isExpiredCode === true;
        return {
            ...trial,
            showMiscelleneousErrorModal,
            showExpiredInvalidCodeModal,
        };
    });

    // Clear errors on unmount
    useEffect(
        () => () => {
            dispatch(fromUserActions.Actions.clearUserError());
        },
        [],
    );

    useEffect(() => {
        dispatch(fromSubscriptionActions.Actions.validatePromoCodeRequest(promoCode));
    }, [dispatch, promoCode]);

    if (isValidating) {
        return <LoaderSquare />;
    }

    if (showExpiredInvalidCodeModal) {
        return (
            <Wrapper>
                <PromoExpiredModal />
            </Wrapper>
        );
    }

    if (showMiscelleneousErrorModal) {
        return <PromoErrorModal message={errorMessage} />;
    }

    return (
        <Wrapper>
            <TrialDescription>
                <PlusLogo size="small" />
                <p>{validatedTrial.description || formatMessage('enjoy-a-free-trial')}</p>
            </TrialDescription>

            <LoginRegisterForm
                allowSignup
                onLoggedIn={() =>
                    dispatch(fromSubscriptionActions.Actions.redeemPromoRequest(promoCode))
                }
                isLoading={isRedeeming}
                title={formatMessage('redeem-promo-headline')}
                submitButtonText={formatMessage('redeem-offer-and-start-free-trial')}
            />
        </Wrapper>
    );
}

export default RedeemPromoPage;
