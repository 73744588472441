import styled from 'styled-components';

export const CategoryOptionWrapper = styled.div<{ token: string }>`
    border-bottom: 1px solid ${props => props.theme.tokens['primary-ui-05']};

    svg {
        padding: 0;
        margin: 0;
        color: ${props => props.token};
    }
`;
