import { connect } from 'react-redux';
import { RootState } from 'redux/reducers';
import { Dispatch } from 'redux';
import * as fromUserActions from '../../redux/actions/user.actions';
import PasswordForgottenPage from './PasswordForgottenPage';

const mapStateToProps = (state: RootState) => ({
    isLoading: state.user.isFetching,
    isSent: state.user.isForgottenPasswordSent,
    error: state.user.errorMessage,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    forgottenPassword: (email: string) =>
        dispatch(fromUserActions.Actions.forgottenPassword(email)),
    clearUserError: () => dispatch(fromUserActions.Actions.clearUserError()),
});

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(PasswordForgottenPage);

export { ConnectedComponent as PasswordForgottenPage };
