import { assign, chain, freeze, setIn } from 'icepick';
import { AutoplayConfig, FullEpisode } from 'model/types';
import { PlayingStatus } from '../../helper/PlayingStatus';
import { ActionTypes, Actions } from '../actions/episode.actions';
import * as fromPodcastActions from '../actions/podcast.actions';
import * as fromUploadedFilesActions from '../actions/uploaded-files.actions';
import * as fromUserActions from '../actions/user.actions';

export type EpisodePopupSource =
    | 'discover'
    | 'filters'
    | 'keyboard_shortcut'
    | 'listening_history'
    | 'player'
    | 'podcast_screen'
    | 'recommended_episodes'
    | 'search'
    | 'search_list'
    | 'starred'
    | 'files'
    | 'up_next'
    | 'bookmarks';

export type EpisodeState =
    | (FullEpisode & { eventSource: EpisodePopupSource; autoplayConfig: AutoplayConfig | null })
    | null;

const INITIAL_STATE: EpisodeState = null;

export default (
    state: EpisodeState = INITIAL_STATE,
    action:
        | Actions
        | fromPodcastActions.Actions
        | fromUploadedFilesActions.Actions
        | fromUserActions.Actions,
) => {
    switch (action.type) {
        case ActionTypes.OPEN_EPISODE:
            return freeze({
                ...action.payload.episode,
                eventSource: action.payload.options.eventSource,
                autoplayConfig: action.payload.options.autoplayConfig ?? null,
            });
        case ActionTypes.CLOSE_EPISODE:
            return null;
        case ActionTypes.RECEIVE_EPISODE_SYNC:
            if (state && state.uuid === action.payload.syncData.uuid) {
                return assign(state, action.payload.syncData);
            }
            return state;
        case fromUploadedFilesActions.ActionTypes.FILES_UPDATE_REQUEST: {
            const updates = action.payload.files.find(({ uuid }) => uuid === state?.uuid);
            if (updates) {
                return assign(state, updates);
            }
            return state;
        }
        case fromPodcastActions.ActionTypes.MARK_AS_PLAYED:
            if (state && state.uuid === action.payload.episodeUuid) {
                return setIn(state, ['playingStatus'], PlayingStatus.COMPLETED);
            }
            return state;
        case fromPodcastActions.ActionTypes.MARK_AS_UNPLAYED:
            if (state && state.uuid === action.payload.episodeUuid) {
                return chain(state)
                    .setIn(['playingStatus'], PlayingStatus.NOT_PLAYED)
                    .setIn(['playedUpTo'], 0)
                    .value();
            }
            return state;
        case fromPodcastActions.ActionTypes.STAR_EPISODE:
            if (state && state.uuid === action.payload.episodeUuid) {
                return setIn(state, ['starred'], action.payload.starred);
            }
            return state;
        case fromPodcastActions.ActionTypes.ARCHIVE:
            if (state && state.uuid === action.payload.episodeUuid) {
                return setIn(state, ['isDeleted'], true);
            }
            return state;
        case fromPodcastActions.ActionTypes.UNARCHIVE:
            if (state && state.uuid === action.payload.episodeUuid) {
                return setIn(state, ['isDeleted'], false);
            }
            return state;
        case fromUserActions.ActionTypes.SIGN_IN:
            return null;
        default:
            return state;
    }
};
