import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import { RootState } from 'redux/reducers';
import * as fromDiscoverActions from '../../redux/actions/discover.actions';
import DiscoverPage from './DiscoverPage';

const getContentSpec = (state: RootState) =>
    Array.isArray(state.discover.contentSpec.layout) ? state.discover.contentSpec.layout : [];

const mapStateToProps = (state: RootState) => {
    const contentSpecLayout = getContentSpec(state);
    return {
        contentSpecLayout,
        region: state.settings.region,
        isLoading: !contentSpecLayout || contentSpecLayout.length === 0 || !state.settings.region,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getContentJson: () => dispatch(fromDiscoverActions.Actions.getDiscoverContent()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DiscoverPage));
