import { Button } from 'components/Button';
import styled from 'styled-components';

export const LoginButton = styled(Button)`
    width: 100%;
    font-weight: 500;
    color: ${props => props.theme.legacyScss['theme-text-primary']};
    border-color: ${props => props.theme.legacyScss['theme-interactive-secondary']};
    position: relative;

    svg {
        position: absolute;
        top: calc(50% - 1px);
        left: 0;
        transform: translate(75%, -50%);
    }

    &:hover,
    &:focus {
        background: inherit;
        border-color: ${props => props.theme.legacyScss['theme-text-secondary']};
        color: initial;
    }
`;
