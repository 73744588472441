import styled from 'styled-components';
import { Typography, gridSize } from 'styles';

export const Wrapper = styled.div`
    width: 480px;
    max-height: 70vh;
    display: flex;
    flex-direction: column;
`;

export const SourceTitle = styled.div`
    ${Typography.Desktop['H80-BC']}
    line-height: 1;
    color: ${props => props.theme.tokens['primary-text-02']};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: ${3 * gridSize}px;
    flex-shrink: 0;
`;

export const Heading = styled.h1`
    ${Typography.Desktop.H10}
    line-height: 1;
    margin: ${2 * gridSize}px 0;
`;

export const Content = styled.div`
    overflow-y: auto;
    margin: auto -48px;
    padding: 0 48px 24px 48px;
`;
