import { EpisodeWithImage } from 'model/types';
import React from 'react';
import { MiniPlayerWrapper } from './MiniPlayer.styled';

interface Props {
    episode: EpisodeWithImage;
    isPlaying: boolean;
}

const openMiniPlayer = ({ episode, isPlaying }: Props) => {
    if (window.electron) {
        window.electron.miniPlayer.open({ episode, isPlaying });
        return;
    }

    if (window?.webkit?.messageHandlers?.player) {
        window.webkit.messageHandlers.player.postMessage(
            JSON.stringify({ action: 'openMiniPlayer' }),
        );
    }
};

function MiniPlayer({ episode, isPlaying }: Props) {
    return (
        <MiniPlayerWrapper
            className="mini-player"
            onClick={() => openMiniPlayer({ episode, isPlaying })}
        />
    );
}

export default MiniPlayer;
