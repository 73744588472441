import { PopupMenu } from 'components/popup/PopupMenu';
import useFormatMessage from 'hooks/useFormatMessage';
import React from 'react';
import { SelectOption } from './SelectOption';

type Props = {
    onSelect: (sortId: number) => void;
    options: {
        id: number;
        label: string;
    }[];
    selectedOption: number;
    id: string;
    color?: string;
    iconColor?: string;
    iconHoverColor?: string;
};

const SortOptions = ({
    options,
    selectedOption,
    id = '',
    color = '#03a9f4',
    onSelect = () => null,
    iconColor,
    iconHoverColor,
}: Props) => {
    const formatMessage = useFormatMessage();
    return (
        <div id={id} className="sort-options">
            <PopupMenu
                aria-label={formatMessage('show-sort-options')}
                id="sort-options-menu"
                color={color}
                iconColor={iconColor}
                iconHoverColor={iconHoverColor}
                iconName="sort"
                showTooltip={true}
            >
                {options.map(option => (
                    <SelectOption
                        key={option.id}
                        optionId={option.id}
                        text={option.label}
                        color={color}
                        isSelected={selectedOption === option.id}
                        onClick={() => onSelect(option.id)}
                    />
                ))}
            </PopupMenu>
        </div>
    );
};

export default SortOptions;
