import React, { useEffect, useRef, useState } from 'react';
import { CastButtonWrapper } from './CastButton.styled';

const isVisible = (element: HTMLElement) => {
    return getComputedStyle(element).display !== 'none';
};

function CastButton() {
    const castButtonRef = useRef() as React.MutableRefObject<HTMLDivElement>;
    const [containerVisible, setContainerVisible] = useState(false);

    useEffect(() => {
        const buttonElement = document.createElement('button', { is: 'google-cast-button' });
        castButtonRef?.current?.appendChild(buttonElement);

        /**
         * Observe the ChromeCast button element to determine if it's visible or not.
         *
         * We need to do this because for layout purposes we need to hide the CastButtonWrapper element
         * when the ChromeCast button is not visible.
         */
        const observer = new MutationObserver(() => {
            setContainerVisible(isVisible(buttonElement));
        });

        observer.observe(buttonElement, {
            attributes: true,
            subtree: true,
            childList: true,
        });

        setContainerVisible(isVisible(buttonElement));

        return () => {
            observer.disconnect();
        };
    }, []);

    return (
        <CastButtonWrapper
            style={{
                display: containerVisible ? 'block' : 'none',
            }}
            className="chrome-cast-button"
            ref={castButtonRef}
        />
    );
}

export default CastButton;
