import styled from 'styled-components';
import { ModernStyle } from 'styles/ModernStyle.styled';
import { coolGrey60, gridSize, Typography } from '../../../styles';

export const MarketingWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 420px;
    ${ModernStyle} && {
        max-width: unset;
    }
`;

export const IconWrapper = styled.div`
    width: ${3 * gridSize}px;
    height: ${3 * gridSize}px;

    margin-left: ${gridSize}px;
    margin-right: ${2 * gridSize}px;
    flex-grow: 0;

    color: ${props => props.theme.tokens['primary-text-02']};
    ${ModernStyle} && {
        display: none;
    }
`;

export const TextWrapper = styled.div`
    ${Typography.Desktop.H80};
    color: ${coolGrey60};
    text-align: left;
    margin-right: ${gridSize}px;
    ${ModernStyle} && {
        color: inherit;
        margin-right: ${2 * gridSize}px;
    }
`;

export const SwitchWrapper = styled.div`
    margin-right: ${gridSize}px;
`;

export const VerticalSpace = styled.div`
    flex-grow: 100;
    ${ModernStyle} && {
        display: none;
    }
`;
