import useFormatMessage from 'hooks/useFormatMessage';
import React from 'react';
import { SkipButtonContainer } from './SkipForwardButton.styled';

type Props = {
    amount?: number;
    onSkipForwardClicked: () => void;
};

const SkipForwardButton = ({ amount, onSkipForwardClicked }: Props) => {
    const formatMessage = useFormatMessage();

    return (
        <SkipButtonContainer
            className="skip-forward-button skip_forward_button"
            role="button"
            onClick={onSkipForwardClicked}
            onMouseDown={e => e.preventDefault()}
            tabIndex={0}
            aria-label={`${formatMessage('skip-forwards')} ${amount}`}
        >
            <p>{amount}</p>
        </SkipButtonContainer>
    );
};

export default SkipForwardButton;
