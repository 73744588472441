import styled from 'styled-components';

export const PodcastsPageWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const PodcastsContentWrapper = styled.main`
    display: flex;
    flex-direction: column;
`;

const badgeBackgrounds: Record<string, string | null> = {
    light:
        "background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD4AAAA+CAYAAABzwahEAAAABGdBTUEAALGPC/xhBQAAA61JREFUaAXd28lu1DAcx3FPJ2U6bU8sj4IQUtlfggPtpadWHFjEUyDggtoD4kIRz8KBN2FRD22n7SzBXw+/4AZm2plOJrYtOXYSJ/XHfyepNEmj2+3mjUbDkEkqy3XW8zyncEl1SmV2aPuwVX1LOWTz1+lVNhgMHFYN2KhGIFRnu5KgKjmH6rShXmdSn2VaWFgojPSL7Vmv1zPaQUnWTkoQNBRGQErAfvb3qb072RwXPloe+uIb6U5mp7rb2Gw2CxwHqyGNygjWBe73+0bZj3z5GM4zr6T+C45NmX6xP2u1WntHR0frdDrLMtc3DiCV8RwgUIH/9tU0d96Z5s/v7pjQFvnV66a/9cyYm7eL6e7wdLTT6Xw6ODhYB66s0RK+DCbKXCaNzcfGBIpWEBz+wxezuLhYRN6FuN1ub9ioNw4PD5+osaLPumYAdQ0A9VhS49eP4tLEgmE4p61geXl5fWVl5TPXPJEk6zrWtSuoj+9tbjEy2hVsKYv6fqbH4FdXV8fiuc7JJFfeWjOnT18Fj/eDdybiCtUkeOBu6qzdN8fbL4PGK9Iqz0R8HJ4DNGpuxOz0Bk3mUWHuPDCdrRdR4HEOn18SeyWRtzc8Y+/2xQ3PAW0bTXfdKLS9C97ub+++NXaUvLOFVx0Jp6tlPJHmbg+YxAAI7TbYRSz4sXAwZTzbUsCfC08VfyF4ivgLw1PDTwRPCT8xPBX8VPAU8FPDY8dfCh4z/tLwWPEzgceInxk8NvxM4THhZw6PBV8JPAZ8ZfDQ8ZXCQ8ZXDg8VPxd4iPi5wUPDzxUeEn7u8FDwtcBDwNcGrxtfK7xOfO3wuvBBwOvABwOvGq8fOvk7pKDgdKiq3+oEpyQFB6dTVeAF5/zUg4RPi+/dfWiOLWpp580/v8/z07aPDxY+Lb5v8acWeeX967/4azfc7/g+Pmj4pHgiCm5w75HpLbVM9nGXU5jB9vMCXuDdnggW9rWUPV5L0Ut6ejlB01fv6Pjv6YgFtpyHtzi1CLw8D0/3BfcpGpwi2nZmRAUHcx4eJNEnUyf5pQYhOjiQUXg/osBJgrqVP+tuuzbEVo7DawBkUsS1Thn8Xd3vrF8v/5NTxvl4f9pzDtpGCwdQxgvlX+P+gPj1qOGj8OVXzgX2Ix89fFp8EvBp8MnAJ8UnBR+H5zr3n+3JwX28vrHRzY19POZc6ZYJLnjU8Y1N+fsaRf2/XyikMg7g7Tc2e/ogUM934VNxjnTwXd3+/n5up35+cnKS8yHxb+4ZzqYkEjEfAAAAAElFTkSuQmCC');",
    dark:
        "background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD4AAAA+CAYAAABzwahEAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAdVJREFUeNrk27tKQ0EQBuBZUWwtrKwUIVjY2Fv7INZ2PoedtU9gZ2dtb2MhgaCVoCjaBiFrNiIczTHZ+8z8u5AizYGP2T1nL/8aIrLUYFuhRlu7cGutaRE+Q0/xZIyJGutHmxt0vrdDW+trIoFP4086uX+g69ePeXgKfnh4IBbdxQ9ubvvH+BRNqN2+rzC/Xm4x+NPhI00Q3uqh+KuXdzq+G6nD937OQvGXz2/q8P9+x9HxCycwyPilMzdUvNeUFRHvPVdHwwctUpDwwaszFHzUshQBn7Qe14xP3ojQio+Gu+6uGZ9Ucc345K6uFZ9ls1EjPtsuqzZ8se1l6fiscFd1LZVfLfHQv3jf3VuHd+1if7f4SUeVkxSJlS8O/+n+0vDVzs6k4aseGkrCs5yWSsCzHRNz46vDu996TjxbxbnxIhIRHHhWOGe3F1FxjkmOqPBPTby41FMtvNi4V2m8SHiNMS864FcSLz7ZmAPv4l5VdmBK4UN2csZ2QmeD7dl/F/CbeyYpaikJTNXwnHiVScYceLURzlS86uxqCl59aDcWD5FWjsHDxLRD8VD59BA8XDDfFw95I8EHD3sDaRke+urVIjz8nbOcCxuVePq+Q9v9tYn/EmAAOmi1QXqcJ4kAAAAASUVORK5CYII=');",
};

export const UnplayedBadge = styled.div`
    position: absolute;
    top: -2px;
    right: -2px;
    width: 31px;
    height: 31px;
    background-repeat: no-repeat;
    background-size: 100%;
    ${({ theme }) => badgeBackgrounds[theme.name]}
`;
