import { Button } from 'components/Button';
import Input from 'components/Input';
import styled from 'styled-components';
import { Typography, gridSize } from 'styles';
import useFormatMessage from 'hooks/useFormatMessage';
import React, { ChangeEventHandler, FormEventHandler } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'hooks/react-redux-typed';
import { getDefaultBookmarkTitleForEpisode } from 'redux/reducers/selectors/episode-sync.selectors';
import { addBookmark } from 'redux/actions/episode-sync.actions';
import { TimeText } from 'components/format/TimeText';
import { AddBookmarkShortcut } from 'components/KeyboardShortcut';
import { Key, KeyGroup } from 'components/KeyboardShortcut/KeyboardShortcut.styled';

export type Props = {
    time: number;
    episodeUuid: string;
    podcastUuid: string;
    onSubmit?: () => void;
};

const Form = styled.form`
    p {
        ${Typography.Desktop['H70-B']}
        color: ${props => props.theme.tokens['primary-text-02']};
        text-transform: uppercase;
        margin-bottom: ${2 * gridSize}px;
    }
    button {
        width: 100%;
        margin-top: ${2 * gridSize}px;
    }
    ${KeyGroup} {
        margin: 0.09em 0 0 ${gridSize}px;
    }
    ${Key} {
        background: rgba(0,0,0,0.2);
        color: #fff;
    }
`;

const AddBookmarkForm = ({ time, episodeUuid, podcastUuid, onSubmit }: Props) => {
    const dispatch = useDispatch();
    const formatMessage = useFormatMessage();
    const defaultTitle = useSelector(state =>
        getDefaultBookmarkTitleForEpisode(state, episodeUuid, formatMessage('bookmark')),
    );

    const [title, setTitle] = React.useState(defaultTitle);

    const handleTitleChange: ChangeEventHandler<HTMLInputElement> = e => {
        setTitle(e.target.value);
    };

    const handleSubmit: FormEventHandler = evt => {
        evt.preventDefault(); // Prevent page reload
        dispatch(
            addBookmark(
                { episodeUuid, podcastUuid, time, title },
                { eventSource: 'add_bookmark_popup' },
            ),
        );
        onSubmit?.();
    };

    return (
        <Form onSubmit={handleSubmit}>
            <p>
                {formatMessage('add-bookmark-at-time', {
                    time: <TimeText timeSecs={time} key="time" />,
                })}
            </p>
            <Input
                type="text"
                value={title}
                onChange={handleTitleChange}
                aria-label={formatMessage('bookmark-title')}
                maxLength={100}
            />
            <Button kind="primary" type="submit">
                {formatMessage('add-bookmark')}
                <AddBookmarkShortcut />
            </Button>
        </Form>
    );
};

export default AddBookmarkForm;
