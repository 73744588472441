import { ModalTypes } from 'helper/UiHelper';
import { createAction, ActionsUnion } from './action-creators';

export enum ActionTypes {
    SHOW_MODAL = '@modals/SHOW_MODAL',
    HIDE_MODAL = '@modals/HIDE_MODAL',
}

export const Actions = {
    showModal: (type: ModalTypes, data?: Record<string, unknown>) =>
        createAction(ActionTypes.SHOW_MODAL, {
            type,
            data,
        }),

    hideModal: () => createAction(ActionTypes.HIDE_MODAL),
};

export type Actions = ActionsUnion<typeof Actions>;
