import { Icon } from 'components/Icon';
import React, { ForwardedRef, forwardRef } from 'react';
import { CheckboxWrapper, FakeCheckbox } from './Checkbox.styled';

export type InputStatus = 'ok' | 'error';

export type Props = React.InputHTMLAttributes<HTMLInputElement>;

export const Checkbox = forwardRef(({ ...props }: Props, ref: ForwardedRef<HTMLInputElement>) => {
    return (
        <CheckboxWrapper>
            <input ref={ref} type="checkbox" {...props} />
            <FakeCheckbox>
                <Icon id="tick" />
            </FakeCheckbox>
        </CheckboxWrapper>
    );
});
