import styled from 'styled-components';
import { ModernStyle } from 'styles/ModernStyle.styled';

const height = 32;

export const SwitchWrapper = styled.div`
    position: relative;
    height: 18px;
    width: 38px;
    display: inline-block;
    &:hover {
        cursor: pointer;
        .knob {
            transform: scale(1.1);
            transition: all 150ms ease-in-out;
        }
    }
    .line {
        position: absolute;
        top: 4px;
        left: 6px;
        display: inline-block;
        width: 26px;
        height: 10px;
        background-color: ${props => props.theme.legacyScss['theme-interactive-secondary']};
        border-radius: 10px;
        z-index: 1;
    }

    .knob {
        background-color: white;
        display: inline-block;
        position: absolute;
        left: 0;
        z-index: 2;
        width: 18px;
        height: 18px;
        color: white;
        border-radius: 18px;
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.5);
        transition: all 150ms ease-in-out;
    }
    &[aria-checked='true'] {
        &:hover {
            .knob {
                transform: scale(1.1);
            }
        }
        .line {
            background-color: ${props => props.theme.legacyScss['theme-interactive']};
        }
        .knob {
            left: 20px;
            transition: all 150ms ease-in-out;
        }
    }
    ${ModernStyle} && {
        background: ${props => props.theme.tokens['support-06']};
        border-radius: 1000px;
        height: ${height}px;
        width: 52px;
        transition: background 100ms ease-in-out;
        .line {
            display: none;
        }
        .knob {
            background-color: ${props => props.theme.tokens['primary-ui-01']};
            left: 2px;
            top: 2px;
            border-radius: 1000px;
            height: ${height - 4}px;
            width: ${height - 4}px;
            transition: all 100ms ease-in-out;
        }
        &:hover {
            .knob {
                transform: none;
            }
        }
        &[aria-checked='true'] {
            background: ${props => props.theme.tokens['primary-text-01']};
            .knob {
                left: calc(100% - ${height - 2}px);
            }
        }
    }
`;
