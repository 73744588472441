import React, { Component } from 'react';
import FilesEmptyStateContent from './FilesEmptyStateContent';
import { FilesEmptyStateWrapper, UploadFilesLabel, UploadFilesInput } from './styled';

const BROWSE_FILES_INPUT_ID = 'browse-files-input';

export class FilesEmptyState extends Component {
    fileUploadInputChanged = event => {
        const { files } = event.target;

        for (const file of files) {
            this.props.requestFileUploadPreflight(file);
        }
    };

    render() {
        return (
            <FilesEmptyStateWrapper>
                <UploadFilesLabel htmlFor={BROWSE_FILES_INPUT_ID}>
                    <FilesEmptyStateContent />
                </UploadFilesLabel>
                <UploadFilesInput
                    id={BROWSE_FILES_INPUT_ID}
                    onClick={this.props?.onClick}
                    type="file"
                    onChange={this.fileUploadInputChanged}
                    multiple
                />
            </FilesEmptyStateWrapper>
        );
    }
}

export default FilesEmptyState;
