import { PodcastTintColors } from 'model/types';
import { rgba } from 'polished';

export const LIGHT_THEME = {
    name: 'light',

    getOnBackgroundTint: (colors?: { onLightBackground?: string; onDarkBackground?: string }) => {
        if (colors?.onLightBackground) {
            return colors?.onLightBackground;
        }
        // primary-text-01
        return '#292B2E';
    },
    getLightOrDarkTint: (colors?: PodcastTintColors) => {
        if (colors?.lightTint) {
            return colors.lightTint;
        }
        return '#03A9F4';
    },

    // These are one to one mappings with what was previously defined as scss variables (without the
    // light/dark prefix)  as well as those that were already custom theme variables. I've put them in
    // a legacyScss structure to make it easy to refactor existing scss without problems
    // and then to replace these with the correct tokens once all scss is replaced.
    legacyScss: {
        'fixme-popup-background': rgba('#000000', 0.4),
        'theme-background': '#ffffff',
        'theme-background-hover': '#f6f6f6',
        'theme-popup-background': '#ffffff',
        'theme-text-primary': '#1e1e1f',
        'theme-text-secondary': '#8f97a4',
        'theme-text-disabled': '#bdc2c5',
        'theme-text-link': '#03a9f4',
        'theme-interactive': '#03a9f4',
        'theme-interactive-secondary': '#b8c3c9',
        'theme-text-link-warning': '#f44336',
        'theme-line': '#e0e6ea',
        'theme-icon-hover': '#e9e9e9',
        'theme-paragraph': '#333333',
        'theme-button-disabled': '#f2f4f5',
        'theme-button-disabled-text': '#b8c3c9',
        'theme-success': '#78d549',
        'navigation-background': '#1e1f1e',
        'navigation-background-hover': '#3f403f',
        'navigation-text': '#ffffff',
    },

    modernStyle: {
        border: '#d6d6d6',
    },

    tokens: {
        'primary-ui-01': '#FFFFFF',
        'primary-ui-01-active': '#F7F9FA',
        'primary-ui-02': '#FFFFFF',
        'primary-ui-02-selected': '#03A9F4',
        'primary-ui-02-active': '#F7F9FA',
        'primary-ui-03': '#FAFAFA',
        'primary-ui-04': '#F7F9FA',
        'primary-ui-05': '#E0E6EA',
        'primary-ui-05-selected': '#8F97A4',
        'primary-ui-06': '#F7F9FA',
        'primary-ui-07': '#CCCCCC',
        'primary-icon-01': '#03A9F4',
        'primary-icon-01-active': '#8AE9FF',
        'primary-icon-02': '#B8C3C9',
        'primary-icon-02-selected': '#03A9F4',
        'primary-icon-02-active': '#8F97A4',
        'primary-icon-02-disabled': '#EFF3F5',
        'primary-icon-03': '#8F97A4',
        'primary-icon-03-active': '#03A9F4',
        'primary-text-01': '#292B2E',
        'primary-text-02': '#8F97A4',
        'primary-text-02-selected': '#03A9F4',
        'primary-field-01': '#EFF3F5',
        'primary-field-01-active': '#E0E6EA',
        'primary-field-02': '#FBFBFC',
        'primary-field-02-active': '#FFFFFF',
        'primary-field-03': '#B8C3C9',
        'primary-field-03-active': '#03A9F4',
        'primary-interactive-01': '#03A9F4',
        'primary-interactive-01-hover': '#50D0F1',
        'primary-interactive-01-active': '#8AE9FF',
        'primary-interactive-01-disabled': rgba('#03A9F4', 0.25),
        'primary-interactive-02': '#FFFFFF',
        'primary-interactive-02-hover': rgba('#33B8F4', 0.95),
        'primary-interactive-02-active': rgba('#33B8F4', 0.9),
        'primary-interactive-03': '#D9D9D9',

        'secondary-ui-01': '#FFFFFF',
        'secondary-ui-02': '#E0E6EA',
        'secondary-icon-01': '#03A9F4',
        'secondary-icon-02': '#8F97A4',
        'secondary-text-01': '#292B2E',
        'secondary-text-02': '#8F97A4',
        'secondary-field-01': '#EFF3F5',
        'secondary-field-01-active': '#E0E6EA',
        'secondary-interactive-01': '#03A9F4',
        'secondary-interactive-01-hover': '#50D0F1',
        'secondary-interactive-01-active': '#8AE9FF',

        'podcast-ui-01': '#FFFFFF',
        'podcast-ui-02': '',
        'podcast-ui-03': '',
        'podcast-ui-04': '#E0E6EA',
        'podcast-ui-05': '',
        'podcast-ui-06': '',
        'podcast-icon-01': '',
        'podcast-icon-02': '',
        'podcast-icon-03': '',
        'podcast-text-01': '',
        'podcast-text-02': '',
        'podcast-interactive-01': '',
        'podcast-interactive-01-active': '',
        'podcast-interactive-02': '#FFFFFF',
        'podcast-interactive-03': '',
        'podcast-interactive-03-active': '',
        'podcast-interactive-04': '#000000',
        'podcast-interactive-05': '',
        'podcast-onDark': '',
        'podcast-onLight': '',

        'filter-ui-01': '#FFFFFF',
        'filter-ui-02': '',
        'filter-ui-03': '',
        'filter-ui-04': '#E0E6EA',
        'filter-icon-01': '',
        'filter-icon-02': '',
        'filter-text-01': '',
        'filter-text-02': '',
        'filter-interactive-01': '',
        'filter-interactive-01-active': '',
        'filter-interactive-02': '#FFFFFF',
        'filter-interactive-03': '',
        'filter-interactive-03-active': '',
        'filter-interactive-04': '#FFFFFF',
        'filter-interactive-05': '#686C74',
        'filter-interactive-06': '#686C74',

        'support-01': '#03A9F4',
        'support-02': '#78D549',
        'support-03': '#03A9F4',
        'support-04': '#187ADC',
        'support-05': '#F43E37',
        'support-06': '#686C74',
        'support-07': '#FFFFFF',
        'support-08': '#FF9D3B',
        'support-09': '#080808',
        'support-10': '#FEB700',

        'player-background-01': '',
        'player-background-02': '',
        'player-highlight-01': '',
        'player-highlight-02': '',
        'player-highlight-03': '',
        'player-highlight-04': '',
        'player-highlight-05': '',
        'player-highlight-06': '',
        'player-highlight-07': '',
        'player-contrast-01': '#FFFFFF',
        'player-contrast-02': rgba('#FFFFFF', 0.5),
        'player-contrast-03': rgba('#FFFFFF', 0.4),
        'player-contrast-04': rgba('#FFFFFF', 0.3),
        'player-contrast-05': rgba('#FFFFFF', 0.2),
        'player-contrast-06': rgba('#FFFFFF', 0.1),

        'contrast-01': '#FFFFFF',
        'contrast-02': rgba('#FFFFFF', 0.7),
        'contrast-03': rgba('#FFFFFF', 0.5),
        'contrast-04': rgba('#FFFFFF', 0.1),

        'filter-01': '#F43E37', // Red
        'filter-02': '#FF9D3B', // Orange
        'filter-03': '#FEC635', // Yellow
        'filter-04': '#78D549', // Green
        'filter-05': '#03A9F4', // Blue
        'filter-06': '#5D31C4', // Purple
        'filter-07': '#E93673', // Pink
        'filter-08': '#B8C3C9', // Grey
        'filter-09': '#A177D9', // Lilac
        'filter-10': '#3DAD88', // Teal
        'filter-11': '#2555CF', // Navy Blue
        'filter-12': '#945712', // Brown

        veil: rgba(0, 0, 0, 0.3),

        'gradient-01-a': '#FED745',
        'gradient-01-e': '#FEB525',
        'gradient-02-a': '#03A9F4',
        'gradient-02-e': '#50D0F1',
        'gradient-03-a': '#F43769',
        'gradient-03-e': '#FB5246',
        'gradient-04-a': '#78D549',
        'gradient-04-e': '#9BE45E',
        'gradient-05-a': '#E74B8A',
        'gradient-05-e': '#6046E9',

        'image-filter-01': '#FFFFFF',
        'image-filter-02': '#FFFFFF',
        'image-filter-03': '#FFFFFF',
        'image-filter-04': '#FFFFFF',

        'category-01': '#F43E37',
        'category-02': '#03A9F4',
        'category-03': '#FEC635',
        'category-04': '#187ADC',
        'category-05': '#3B862D',
        'category-06': '#8F97A4',
        'category-07': '#B25900',
        'category-08': '#78D549',
        'category-09': '#FB8ECE',
        'category-10': '#A866DB',
        'category-11': '#D61F59',
        'category-12': '#686C74',
        'category-13': '#FF9D3B',
        'category-14': '#50D0F1',
        'category-15': '#E93673',
        'category-16': '#0059B2',
        'category-17': '#CC98F0',
        'category-18': '#D9201C',
        'category-19': '#5D31C4',
    },
};
