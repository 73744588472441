import styled from 'styled-components';
import { darkGrey60, white } from 'styles';

export const LoadWrapper = styled.div`
    background: ${darkGrey60};
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
`;

export const PlayerImage = styled.img`
    width: 40px;
    height: 40px;
    position: absolute;
    left: 8px;
    top: 10px;
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100vw;
    background: ${darkGrey60};

    -webkit-app-region: drag;

    .seek-bar,
    button,
    button * {
        -webkit-app-region: no-drag;
    }

    .player-gradient {
        position: absolute;
        top: 0;
        width: 100%;
        height: 60px;

        filter: blur(150px);
        transform: translateZ(0);

        opacity: 0.8;
        @-moz-document url-prefix() {
            opacity: 0.4;
        }
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50%;
        transition: opacity 0.2s ease;

        @supports (-ms-ime-align: auto) {
            opacity: 0.2;
            filter: none;
            transform: none;
            perspective: none;
        }
    }
`;

export const Controls = styled.div`
    display: flex;
    width: 200px;
    position: absolute;
    left: 60px;
    flex-direction: column;
    margin: 0 auto;

    .tracks .knob-bar {
        top: 5px;
        .knob {
            height: 8px;
            width: 8px;
        }
    }

    .current-time,
    .time-remaining {
        font-size: 10px;
        line-height: 24px;
        color: ${white};
        opacity: 0.5;
    }

    .play_pause_button {
        width: 40px;
        height: 40px;
    }

    .skip-back-button {
        width: 20px;
        height: 20px;
        background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2045%2045%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ctitle%3Eplayer%2Fskipback%3C%2Ftitle%3E%3Cpath%20d%3D%22M12%209h11.5C32.06%209%2039%2015.94%2039%2024.5%2039%2033.06%2032.06%2040%2023.5%2040%2014.94%2040%208%2033.06%208%2024.5c0-.552.448-1%201-1s1%20.448%201%201C10%2031.956%2016.044%2038%2023.5%2038S37%2031.956%2037%2024.5%2030.956%2011%2023.5%2011H12v4l-6-5%206-5v4z%22%20fill%3D%22%23FFF%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E');
    }

    .skip-forward-button {
        width: 20px;
        height: 20px;
        background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2045%2045%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ctitle%3Eplayer%2Fskipforward%3C%2Ftitle%3E%3Cpath%20d%3D%22M33%209H21.5C12.94%209%206%2015.94%206%2024.5%206%2033.06%2012.94%2040%2021.5%2040%2030.06%2040%2037%2033.06%2037%2024.5c0-.552-.448-1-1-1s-1%20.448-1%201C35%2031.956%2028.956%2038%2021.5%2038S8%2031.956%208%2024.5%2014.044%2011%2021.5%2011H33v4l6-5-6-5v4z%22%20fill%3D%22%23FFF%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E');
    }
`;

export const Actions = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 12px;
    height: 35px;
    position: relative;
    top: 8px;
`;

export const CloseButtonWrapper = styled.div`
    button,
    svg {
        width: 16px;
        height: 16px;
    }

    button {
        top: 6px;
        right: 6px;
    }
`;
