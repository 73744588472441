import { PLAYER_HEIGHT } from 'model/page';
import { createGlobalStyle } from 'styled-components';

export const GlobalVideoStyles = createGlobalStyle`
    .video-player {
        position: absolute;
        .background {
            video {
                cursor: pointer;
            }
            .video-layer {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                display: flex;
                flex: 1 0 auto;
                justify-content: center;
                align-items: center;
            }
        }
        &.large {
            width: 100%;
            z-index: 21;
            top: 0;
            left: 0;
            bottom: ${PLAYER_HEIGHT}px;
            right: 0;
            background-color: #000;
            .background {
                display: flex;
                flex: 1 0 100%;
                video {
                    width: 100%;
                    max-height: 100%;
                }
                .video-controls {
                    opacity: 1;
                    .video-expand-button {
                        display: none;
                    }
                }
            }
        }
        &.small {
            bottom: 13px;
            z-index: 101;
            left: 20px;
            width: 95px;
            height: 95px;
            video {
                border-radius: 2px;
                max-width: 95px;
                max-height: 95px;
            }
            .video-controls {
                background-color: rgba(0, 0, 0, 0.5);
                opacity: 0;
                .controls-bar,
                .video-fullscreen {
                    display: none;
                }
                .video-expand-button {
                    opacity: 1;
                }
            }
            &:hover {
                .video-controls {
                    opacity: 1;
                }
            }
        }
    }
`;
