import '@babel/polyfill';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { getTranslations } from 'translations';
import { registeredMiddleware } from 'redux/middleware';
import localStorageMiddleware from 'redux/local-storage.middleware';
import sagas from './redux/sagas/index';
import { rootReducer } from './redux/reducers';
import { locale } from './util/locale';

const sagaMiddleware = createSagaMiddleware();

const middlewares = [localStorageMiddleware, sagaMiddleware, ...registeredMiddleware];

const initialState = {
    intl: {
        locale,
        messages: getTranslations(locale),
    },
};

export const store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middlewares)),
);

sagaMiddleware.run(sagas);
