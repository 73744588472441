import { useEffect, useState } from 'react';

type Timer = ReturnType<typeof setTimeout>;

export const useTimedErrorMessage = (errorMessage: string) => {
    let timer: Timer | null;
    const [message, setMessage] = useState<string | null>(null);

    const trigger = () => {
        if (message == null) {
            setMessage(errorMessage);
            clearTimeout(timer as Timer);
            timer = setTimeout(() => {
                setMessage(null);
            }, 3000);
        }
    };

    // Ensure the timer is cleared when unmounting to avoid a memory leak
    useEffect(
        () => () => {
            clearTimeout(timer as Timer);
            timer = null;
        },
        [],
    );

    return {
        hasError: message != null,
        message,
        trigger,
    };
};
