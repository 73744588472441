import { Icon } from 'components/Icon';
import Image from 'components/Image';
import useTracks from 'hooks/useTracks';
import { DiscoverCategory, DiscoverSummaryStyle } from 'model/types';
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import { useTheme } from 'styled-components';
import { FriendlyUrl } from '../../../components';
import { Icon as CategoryIcon, getToken } from '../../../helper/CategoriesHelper';
import { THEME } from '../../../model/theme';
import {
    AccentSubtitle,
    CategoryCircle,
    ListLogoImage,
    ListTitle,
    PodcastDetails,
    PodcastUrl,
    ReadMoreText,
    StyledHeaderDescription,
    TitleAndActions,
    TokenSubtitle,
} from './ListPageHeader.styled';

type Props = {
    title: string | ReactElement;
    subtitle?: string;
    url?: string;
    urlText?: string;
    description?: string;
    colors?: { onLightBackground: string; onDarkBackground: string };
    imageUrl?: string;
    summaryStyle: string;
    category?: DiscoverCategory | null;
    listId: string;
};

export function ListPageHeader({
    title,
    subtitle,
    url,
    urlText,
    description,
    colors,
    imageUrl,
    category,
    summaryStyle,
    listId,
}: Props) {
    const isCategory = summaryStyle === DiscoverSummaryStyle.CATEGORY;
    const theme = useTheme();
    const { recordEvent } = useTracks();

    const accentColor = useSelector((state: RootState) => {
        if (!colors) {
            return theme.tokens['primary-interactive-01'];
        }
        switch (state.settings.theme) {
            default:
            case THEME.light:
                return colors.onLightBackground;
            case THEME.dark:
                return colors.onDarkBackground;
        }
    });

    let token = null;
    if (isCategory && category) {
        token = theme.tokens[getToken(category.id)] || theme.tokens['primary-text-01'];
    }

    return (
        <StyledHeaderDescription>
            {!isCategory && imageUrl && (
                <ListLogoImage>
                    <Image borderRadius={88} src={imageUrl} alt={title} />
                </ListLogoImage>
            )}
            {isCategory && category && (
                <ListLogoImage>
                    <CategoryCircle token={token}>
                        <CategoryIcon id={category.id} />
                    </CategoryCircle>
                </ListLogoImage>
            )}
            <PodcastDetails>
                {subtitle && !isCategory && (
                    <AccentSubtitle color={accentColor}>{subtitle}</AccentSubtitle>
                )}
                {subtitle && isCategory && token && (
                    <TokenSubtitle token={token}>{subtitle}</TokenSubtitle>
                )}
                <TitleAndActions>
                    <ListTitle>{title}</ListTitle>
                </TitleAndActions>

                {url && (
                    <PodcastUrl>
                        <Icon id="link" size={20} />
                        <FriendlyUrl
                            url={url}
                            color={accentColor}
                            text={urlText}
                            onClick={() =>
                                recordEvent('discover_collection_link_tapped', { list_id: listId })
                            }
                        />
                    </PodcastUrl>
                )}
                {description && <ReadMoreText text={description} lines={4} color={accentColor} />}
            </PodcastDetails>
        </StyledHeaderDescription>
    );
}
