import { LOCAL_STORAGE_KEY_SUBSCRIPTION_DATA } from 'model/local-storage';
import { CheckoutModalType, SubscriptionData, SubscriptionPlatform } from 'model/types';
import { ActionTypes, Actions } from '../actions/subscription.actions';
import * as fromUserActions from '../actions/user.actions';

export interface SubscriptionState {
    isLoading: boolean;
    serverError: string;
    data: SubscriptionData;
    trial: {
        justActivatedTrial: boolean;
        isValidating: boolean;
        isRedeeming: boolean;
        errorMessage: string | undefined;
        isExpiredCode: boolean;
        validatedTrial: {
            code: string | undefined;
            description: string | undefined;
            startsAt: string | undefined;
            endsAt: string | undefined;
        };
    };
    showFarewellModal: boolean;
    showCheckoutModal: null | CheckoutModalType;
    showThankYouModalWelcomeBack: boolean;
    introOfferEligible: boolean;
    modals: {
        showAlreadyPlusSubscriberModal: boolean;
        showCancelModal: boolean;
        showChangeBillingDetailsModal: boolean;
    };
    modalData: {
        showCancelModal: unknown;
        showChangeBillingDetailsModal: undefined | { platform: SubscriptionPlatform };
        showAlreadyPlusSubscriberModal: { promo?: boolean } | undefined;
    };
}

export const INITIAL_STATE = {
    isLoading: false,
    serverError: '',
    data: JSON.parse(
        localStorage.getItem(LOCAL_STORAGE_KEY_SUBSCRIPTION_DATA) ?? '{}',
    ) as SubscriptionData,
    trial: {
        justActivatedTrial: false,
        isValidating: false,
        isRedeeming: false,
        errorMessage: undefined,
        isExpiredCode: false,
        validatedTrial: {
            code: undefined,
            description: undefined,
            startsAt: undefined,
            endsAt: undefined,
        },
    },
    showFarewellModal: false,
    showCheckoutModal: null,
    showThankYouModalWelcomeBack: false,
    introOfferEligible: false,
    modals: {
        showAlreadyPlusSubscriberModal: false,
        showCancelModal: false,
        showChangeBillingDetailsModal: false,
    },
    modalData: {
        showCancelModal: undefined,
        showChangeBillingDetailsModal: undefined,
        showAlreadyPlusSubscriberModal: undefined,
    },
};

export default (
    state: SubscriptionState = INITIAL_STATE,
    action: Actions | fromUserActions.Actions,
): SubscriptionState => {
    switch (action.type) {
        case ActionTypes.SUBSCRIPTION_FETCH_AFTER_CANCEL:
        case ActionTypes.SUBSCRIPTION_FETCH_REQUEST:
            return { ...state, isLoading: true, serverError: '' };
        case ActionTypes.SUBSCRIPTION_FETCH_SUCCESS:
            return {
                ...state,
                isLoading: false,
                serverError: '',
                data: action.payload,
            };
        case ActionTypes.SUBSCRIPTION_FETCH_FAILURE:
            // If the fetch failed but we already have data, just keep the same data.
            // The only page that uses the serverError is the profile page.
            if (state.data.platform || state.data.expiryDate) {
                return { ...state, isLoading: false, serverError: action.payload.errorMessage };
            }

            return {
                ...state,
                isLoading: false,
                serverError: action.payload.errorMessage,
                data: {} as SubscriptionData,
            };
        case fromUserActions.ActionTypes.SIGN_IN_TRY_AGAIN:
            return { ...state, data: {} as SubscriptionData };
        case fromUserActions.ActionTypes.PURCHASE_WEB_PLAYER_REQUEST: {
            return { ...state, isLoading: true };
        }
        case fromUserActions.ActionTypes.PURCHASE_WEB_PLAYER_FAILURE: {
            return { ...state, isLoading: false };
        }
        case fromUserActions.ActionTypes.PURCHASE_WEB_PLAYER_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                serverError: '',
                data: action.payload.subscriptionData,
            };
        }
        case ActionTypes.SHOW_FAREWELL_MODAL:
            return { ...state, showFarewellModal: true };
        case ActionTypes.HIDE_FAREWELL_MODAL:
            return { ...state, showFarewellModal: false };
        case ActionTypes.SHOW_CHECKOUT_MODAL:
            return { ...state, showCheckoutModal: action.payload.modalType as CheckoutModalType };
        case ActionTypes.HIDE_CHECKOUT_MODAL:
            return { ...state, showCheckoutModal: null };
        case ActionTypes.SHOW_THANK_YOU_MODAL:
            return { ...state, showThankYouModalWelcomeBack: action.payload.welcomeBack };
        case ActionTypes.HIDE_THANK_YOU_MODAL:
            return { ...state, showThankYouModalWelcomeBack: false };
        case ActionTypes.SHOW_MODAL_GENERIC: {
            if (action.payload.modalName in state) {
                return { ...state, [action.payload.modalName]: true };
            }
            if (action.payload.modalName in state.modals) {
                return {
                    ...state,
                    modals: {
                        ...state.modals,
                        [action.payload.modalName]: true,
                    },
                    modalData: {
                        ...state.modalData,
                        [action.payload.modalName]: action.payload.data,
                    },
                };
            }
            return state;
        }

        case ActionTypes.HIDE_MODAL_GENERIC: {
            if (action.payload.modalName in state) {
                return { ...state, [action.payload.modalName]: false };
            }
            if (action.payload.modalName in state.modals) {
                return {
                    ...state,
                    modals: {
                        ...state.modals,
                        [action.payload.modalName]: false,
                    },
                    modalData: {
                        ...state.modalData,
                        [action.payload.modalName]: undefined,
                    },
                };
            }
            return state;
        }

        case ActionTypes.SAVE_INTRO_OFFER_ELIGIBILITY: {
            return {
                ...state,
                introOfferEligible: action.payload.introOfferEligible,
            };
        }

        case ActionTypes.VALIDATE_PROMO_REQUEST: {
            return {
                ...state,
                trial: {
                    ...state.trial,
                    isValidating: true,
                },
            };
        }
        case ActionTypes.VALIDATE_PROMO_SUCCESS: {
            return {
                ...state,
                trial: {
                    ...state.trial,
                    isValidating: false,
                    isRedeeming: false,
                    validatedTrial: {
                        ...action.payload,
                    },
                },
            };
        }
        case ActionTypes.VALIDATE_PROMO_ERROR: {
            return {
                ...state,
                trial: {
                    ...state.trial,
                    isValidating: false,
                    isRedeeming: false,
                    errorMessage: action.payload,
                },
            };
        }
        case ActionTypes.VALIDATE_PROMO_EXPIRED_INVALID: {
            return {
                ...state,
                trial: {
                    ...state.trial,
                    isValidating: false,
                    isRedeeming: false,
                    isExpiredCode: true,
                },
            };
        }

        case ActionTypes.REDEEM_PROMO_SUCCESS:
            return {
                ...state,
                trial: {
                    ...state.trial,
                    justActivatedTrial: true,
                    isValidating: false,
                    isRedeeming: false,
                    errorMessage: undefined,
                },
            };

        default:
            return state;
    }
};
