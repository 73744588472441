import styled from 'styled-components';
import { Button } from 'components/Button';
import { fontFamilyRetina, Typography, fadeInKeyFrames, gridSize } from '../../styles';
import { ShareButton, TextWithReadMore } from '../../components';

const authorDetailsBottomPadding = 12;

export const PagePodcast = styled.div`
    height: 100%;
    position: relative;
`;

export const PodcastInformation = styled.div`
    display: flex;
    align-items: flex-start;
    padding: 30px 0 15px 0;
`;

export const PodcastImageWrapper = styled.div`
    position: relative;
    width: 200px;
    flex-shrink: 0;
`;

export const TitleAndActions = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0 0 12px 0;
`;

export const PodcastTitle = styled.h1`
    display: block;
    font-family: ${fontFamilyRetina};
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 120%;
    word-break: break-word;
    letter-spacing: -0.8px;

    color: ${props => props.theme.legacyScss['theme-text-primary']};
    margin: 0; /* Margin is specified in container to align properly with actions */
    margin-right: 40px;
`;

export const PodcastAuthorSection = styled.div`
    ${Typography.Desktop['H70-B']};
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    min-height: 35px;
    color: ${props => props.theme.tokens['primary-text-02']};
    margin-top: ${1.5 * gridSize}px;
    border-top: 1px solid ${props => props.theme.tokens['primary-ui-05']};
    padding-top: ${1.5 * gridSize}px;
    & > * {
        margin-right: 19px;
        padding-bottom: ${authorDetailsBottomPadding}px;
    }
`;

export const NextEpisodeDetails = styled.div`
    animation: ${fadeInKeyFrames} 0.2s ease-in-out;
`;

export const StyledShareButton = styled(ShareButton)`
    position: absolute;
    display: inline-block;
    opacity: 0;
    will-change: opacity;
    transition: opacity 0.3s;
    width: 32px;
    height: 32px;
    margin-left: 4px;
    margin-top: 4px;
    svg {
        margin: 4px;
    }
    &:focus {
        opacity: 1;
    }
`;

export const PodcastActionsWrapper = styled.div`
    margin-top: 5px;
    flex-shrink: 0;
`;

export const PodcastDetails = styled.div`
    width: 100%;
    padding-left: 30px;

    &:hover {
        ${StyledShareButton} {
            display: inline-block;
            opacity: 1;
        }
    }
    .text-with-read-more {
        padding-top: 6px;
    }
`;

export const EpisodeSummary = styled.div`
    text-transform: uppercase;
    text-align: right;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.4px;
    padding-bottom: 15px;
    color: #b8c3c9;
`;

export const BookmarksButton = styled(Button)<{ color: string }>`
    text-transform: inherit;

    color: ${props => props.color};
    &:hover {
        opacity: 0.8;
    }
`;

export const EpisodeRows = styled.div``;

export const SearchBox = styled.div`
    display: flex;
    height: 57px;
    align-items: center;

    border-top: 1px solid ${props => props.theme.legacyScss['theme-line']};
    border-bottom: 1px solid ${props => props.theme.legacyScss['theme-line']};

    color: ${props => props.theme.tokens['primary-text-02']};

    .icon-search-episodes {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: ${props => props.theme.legacyScss['theme-text-secondary']};
    }

    input {
        font-size: 16px;
        border: 0;
        outline: none;
        width: 100%;
        margin-left: 5px;
        margin-right: 30px;
        background-color: ${props => props.theme.legacyScss['theme-background']};
        color: ${props => props.theme.legacyScss['theme-text-primary']};
    }

    #episode-options-search-bar {
        margin-left: 5px;
    }

    #sort-options-search-bar {
        margin-left: 5px;
    }

    #clear-search-bar {
        width: 30px;
        height: 30px;
        margin-right: -4px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .loading-icon-wrapper {
        display: flex;
        align-items: center;
        width: 30px;
        height: 30px;
        margin-right: -4px;
        padding-left: 5px;
    }
`;

export const ReadMoreText = styled(TextWithReadMore)`
    padding-top: 7px;
    padding-bottom: 19px;
`;
export const PodcastMeta = styled.div`
    display: flex;
    align-items: center;
    > * + * {
        margin-left: ${3 * gridSize}px;
    }
`;

export const RateButton = styled(Button)<{ color: string }>`
    ${Typography.Desktop['H70-B']}
    color: ${props => props.color};
    line-height: 1;
    margin-left: ${1.5 * gridSize}px;
    &:hover, &.focus-visible {
        opacity: 0.8;
    }
`;
