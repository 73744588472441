import { connect } from 'react-redux';
import * as fromUploadedFilesActions from '../../../redux/actions/uploaded-files.actions';
import * as fromTracksEvents from '../../../redux/actions/tracks.actions';
import FilesEmptyState from './view';

const mapDispatchToProps = dispatch => ({
    requestFileUploadPreflight: file =>
        dispatch(fromUploadedFilesActions.Actions.requestFileUploadPreflight(file)),
    onClick: () => dispatch(fromTracksEvents.Actions.recordEvent('uploaded_files_add_file_tapped')),
});

export default connect(null, mapDispatchToProps)(FilesEmptyState);
