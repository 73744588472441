import React, { ButtonHTMLAttributes, MouseEventHandler } from 'react';
import ReactTooltip from 'react-tooltip';
import useFormatMessage from 'hooks/useFormatMessage';
import { TranslatableStringKey } from 'translations/glotpress';
import { Icon, IconId } from 'components/Icon';
import { tooltipAndLabel } from 'util/tooltip';
import { RowButtonStyled } from './RowButton.styled';

type Props = {
    label: TranslatableStringKey;
    icon: IconId;
    onClick: MouseEventHandler<HTMLButtonElement>;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const RowButton = ({ label, icon, onClick, ...props }: Props) => {
    const formatMessage = useFormatMessage();

    const handleClick: MouseEventHandler<HTMLButtonElement> = event => {
        event.stopPropagation();
        onClick(event);
        ReactTooltip.hide();
    };

    return (
        <RowButtonStyled
            onClick={handleClick}
            {...tooltipAndLabel(formatMessage(label))}
            {...props}
        >
            <Icon id={icon} />
        </RowButtonStyled>
    );
};
