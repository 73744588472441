import styled from 'styled-components';
import { gridSize, H60 } from '../../styles';

export const UploadedFilesPageWrapper = styled.div`
    height: 100%;
`;

export const UploadedFilesErrorWrapper = styled(H60)`
    padding-top: ${2 * gridSize}px;
`;
