import React from 'react';
import { lightenColor } from '../../helper/ColorHelper';
import { FriendlyLink } from './FriendlyUrl.styled';

type Props = {
    url: string;
    color: string;
    text?: string;
} & React.AnchorHTMLAttributes<HTMLAnchorElement>;

class FriendlyUrl extends React.Component<Props> {
    render() {
        const { url, color, text, ...props } = this.props;

        const displayText = text || this.convertUrl(url);

        return (
            <FriendlyLink
                {...props}
                href={url}
                className="friendly-url"
                target="_blank"
                rel="noopener noreferrer"
                color={color}
                hoverColor={lightenColor(color, 0.3)}
            >
                {displayText}
            </FriendlyLink>
        );
    }

    convertUrl = (url: string) => {
         

        if (!url || url.length === 0) {
            return '';
        }
        // remove invalid urls such as http://null
        if (url.indexOf('.') === -1) {
            return '';
        }

        url = url
            .replace('http://', '')
            .replace('https://', '')
            .replace('www.', '');

        const pos = url.indexOf('?');
        if (pos !== -1) {
            url = url.substring(0, pos);
        }
        if (url.endsWith('/')) {
            url = url.substring(0, url.length - 1);
        }

        return url;
    };
}

export default FriendlyUrl;
