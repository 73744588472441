export const sendGoogleAnalyticsEvent = (
    action: string,
    category?: string,
    label?: string,
    value?: number,
) => {
    if ('gtag' in window === true) {
        gtag('event', action, {
            event_category: category,
            event_label: label,
            value,
            non_interaction: true,
        });
    }
};
