import styled from 'styled-components';
import { gridSize, Typography } from 'styles';
import {
    EpisodeTypeText,
    Bonus,
    Trailer,
    SeasonTrailer,
} from 'components/format/EpisodeTypeText/EpisodeTypeText.styled';

export const Wrapper = styled.article`
    ${Typography.Desktop.H70}
    display: flex;
    cursor: pointer;
`;

export const Image = styled.div`
    margin: 3px ${gridSize * 2}px 0 0;
    width: 96px;
`;

export const Content = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    min-width: 0; // https://stackoverflow.com/questions/38223879/white-space-nowrap-breaks-flexbox-layout
`;

export const EpisodeTitle = styled.h2`
    ${Typography.Desktop.H65};
    -webkit-line-clamp: 2;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
`;

export const PodcastLink = styled.a<{ color?: string }>`
    color: ${props => props.theme.tokens['primary-text-02']};
    white-space: nowrap;
    overflow: hidden;
    text-decoration: none;
    text-overflow: ellipsis;
    &:hover {
        opacity: 0.8;
    }
`;

export const PlayButton = styled.div`
    margin-right: ${gridSize * 1.5}px;
    margin-top: -0.075em;
`;

export const Actions = styled.div`
    color: ${props => props.theme.tokens['primary-text-02']};
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: auto;
    ${EpisodeTypeText} {
        &:after {
            content: '•';
            margin: 0 ${gridSize * 0.75}px;
        }
    }
    ${Bonus}, ${Trailer}, ${SeasonTrailer} {
        ${Typography.Desktop.H80}
        line-height: 1;
        text-transform: uppercase;
    }
`;
