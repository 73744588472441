import { ModalSubscriptionActiveUntilDateMessage } from 'components/messages';
import { SubscriptionPlatform } from 'model/types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '../../../../components/Button';
import Modal from '../../../../components/Modal';
import { openPaddleCancelCheckout } from '../../../../helper/PaddleCheckoutHelper';
import { POCKET_CASTS_PLUS_SUBSCRIPTION_INFO } from '../../../../model/external-links';
import {
    DetailTextWrapper,
    DetailWrapper,
    DetailsWrapper,
    HeaderWrapper,
    HelpLink,
    HelpWrapper,
    HopeIsNotLostWrapper,
    ImageWrapper,
    Separator,
    Spaces,
    SubtitleWrapper,
    WhenYouCancelSubheading,
} from './CancelSubscriptionModal.styled';
import CancelAndroidImage from './cancel-android.svg';
import CanceliOSImage from './cancel-ios.svg';
import CancelWebImage from './cancel-web.svg';
import FolderIcon from './folder.svg';
import LockedIcon from './locked.svg';
import PopularIcon from './popular.svg';
import SubscriptionIcon from './subscription.svg';
import UploadIcon from './upload.svg';

type Props = {
    platform: number;
    subscriptionCancelUrl: string;
    isOnPaddleFreeTrial: boolean;
    expiryDate: Date;
    onClose: () => void;
    fetchSubscriptionDataAfterCancel: (hideFarewellModalAfterCancelling: boolean) => void;
};

export class CancelSubscriptionModal extends Component<Props> {
    renderImage() {
        const { platform } = this.props;

        const size = 112;

        if (platform === SubscriptionPlatform.iOS) {
            return (
                <ImageWrapper>
                    <img width={size} height={size} src={CanceliOSImage} alt="" />
                </ImageWrapper>
            );
        }

        if (platform === SubscriptionPlatform.Android) {
            return (
                <ImageWrapper>
                    <img width={size} height={size} src={CancelAndroidImage} alt="" />
                </ImageWrapper>
            );
        }

        return (
            <ImageWrapper>
                <img width={size} height={size} src={CancelWebImage} alt="" />
            </ImageWrapper>
        );
    }

    renderHeader() {
        const { platform } = this.props;

        let message = 'cancel-subscription';

        if (platform === SubscriptionPlatform.iOS) {
            message = 'modal-subscribed-on-ios';
        } else if (platform === SubscriptionPlatform.Android) {
            message = 'modal-subscribed-on-android';
        }

        return (
            <HeaderWrapper>
                <FormattedMessage id={message} />
            </HeaderWrapper>
        );
    }

    renderSubtitle() {
        const { platform } = this.props;

        return (
            <SubtitleWrapper>
                <FormattedMessage
                    id={
                        platform === SubscriptionPlatform.Paddle
                            ? 'modal-change-to-free-account'
                            : 'modal-cant-cancel-subscription-here'
                    }
                />
            </SubtitleWrapper>
        );
    }

    renderDeviceMessage() {
        return (
            <HopeIsNotLostWrapper>
                <FormattedMessage id="modal-all-is-not-lost" />
            </HopeIsNotLostWrapper>
        );
    }

    renderDeviceLink() {
        const { platform } = this.props;

        const message =
            platform === SubscriptionPlatform.iOS ? 'modal-find-out-ios' : 'modal-find-out-android';

        return (
            <HelpLink href={POCKET_CASTS_PLUS_SUBSCRIPTION_INFO} target="_blank">
                <HelpWrapper>
                    <FormattedMessage id={message} />
                </HelpWrapper>
            </HelpLink>
        );
    }

    renderWhenYouCancelSubheading() {
        return (
            <WhenYouCancelSubheading>
                <FormattedMessage id="modal-when-you-cancel" />
            </WhenYouCancelSubheading>
        );
    }

    renderWebStayButton() {
        return (
            <Button width={355} kind="secondary" onClick={this.props.onClose}>
                <FormattedMessage id="modal-i-want-to-stay" />
            </Button>
        );
    }

    doCancelSubscription = () => {
        const { subscriptionCancelUrl } = this.props;

        openPaddleCancelCheckout({
            override: subscriptionCancelUrl,
            successCallback: this.paddleCancellationCallback,
            closeCallback: this.paddleCancellationCallback,
        });
    };

    paddleCancellationCallback = () => {
        const hideFarewellModalAfterCancelling = this.props.isOnPaddleFreeTrial;
        this.props.onClose();
        this.props.fetchSubscriptionDataAfterCancel(hideFarewellModalAfterCancelling);
    };

    renderWebCancelButton() {
        return (
            <Button width={355} kind="primary-red" onClick={this.doCancelSubscription}>
                <FormattedMessage id="modal-cancel-my-subscription" />
            </Button>
        );
    }

    render() {
        const { platform, expiryDate } = this.props;
        return (
            <Modal horizontalInsetPx={32} onClose={this.props.onClose}>
                {this.renderImage()}
                {this.renderHeader()}
                {this.renderSubtitle()}
                <Spaces n={3} />
                {platform !== SubscriptionPlatform.Paddle && (
                    <>
                        <Separator />
                        <Spaces n={3} />
                        {this.renderDeviceMessage()}
                        <Spaces n={2} />
                        {this.renderDeviceLink()}
                        <Spaces n={3} />
                    </>
                )}

                <Separator />
                <Spaces n={3} />
                {platform !== SubscriptionPlatform.Paddle && (
                    <>
                        {this.renderWhenYouCancelSubheading()}
                        <Spaces n={2} />
                    </>
                )}
                <DetailsWrapper>
                    <DetailWrapper>
                        <img src={SubscriptionIcon} width={24} height={24} alt="" />
                        <DetailTextWrapper>
                            <ModalSubscriptionActiveUntilDateMessage expiryDate={expiryDate} />
                        </DetailTextWrapper>
                    </DetailWrapper>
                    <Spaces n={1} />

                    <Spaces n={2} />
                    <DetailWrapper>
                        <img src={LockedIcon} width={24} height={24} alt="" />
                        <DetailTextWrapper>
                            <FormattedMessage id="modal-access-locked-after-date" />
                        </DetailTextWrapper>
                    </DetailWrapper>
                    <Spaces n={3} />
                    <DetailWrapper>
                        <img src={FolderIcon} width={24} height={24} alt="" />
                        <DetailTextWrapper>
                            <FormattedMessage id="folders-will-be-deleted" />
                        </DetailTextWrapper>
                    </DetailWrapper>
                    <Spaces n={3} />
                    <DetailWrapper>
                        <img src={UploadIcon} width={24} height={24} alt="" />
                        <DetailTextWrapper>
                            <FormattedMessage id="modal-uploaded-files-deleted" />
                        </DetailTextWrapper>
                    </DetailWrapper>
                    <Spaces n={3} />
                    <DetailWrapper>
                        <img src={PopularIcon} width={24} height={24} alt="" />
                        <DetailTextWrapper>
                            <FormattedMessage id="modal-everything-else-free" />
                        </DetailTextWrapper>
                    </DetailWrapper>

                    <Spaces n={2} />
                </DetailsWrapper>
                {platform === SubscriptionPlatform.Paddle && (
                    <>
                        <Spaces n={2} />
                        {this.renderWebStayButton()}
                        <Spaces n={3} />
                        {this.renderWebCancelButton()}
                    </>
                )}
                {platform === SubscriptionPlatform.Paddle ? <Spaces n={4} /> : <Spaces n={1} />}
            </Modal>
        );
    }
}

export default CancelSubscriptionModal;
