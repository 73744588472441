import styled from 'styled-components';
import { blue, gridSize } from '../../../../../../styles';

export const UploadImageButtonWrapper = styled.div`
    width: ${5 * gridSize}px;
    height: ${5 * gridSize}px;

    margin-left: ${gridSize}px;
    margin-right: ${gridSize}px;
`;

export const UploadImageLabel = styled.label`
    display: flex;
    width: 100%;
    height: 100%;

    align-items: center;
    justify-content: center;

    color: ${blue};
    border: 2px solid ${blue};
    border-radius: ${2.5 * gridSize}px;
    cursor: pointer;

    &:hover {
        transform: scale(1.2);
    }

    transition: all 100ms ease-in-out;
    transform-origin: center center;
`;

export const UploadImageInput = styled.input`
    opacity: 0;
    height: 1px;
    width: 1px;
`;
