import Cookies from 'universal-cookie';
import { AutoplayConfig } from 'model/types';
import { Action } from 'redux/actions';
import { LOCAL_STORAGE_KEY_AUTOPLAY_CONFIG } from 'model/local-storage';

const cookies = new Cookies();

const restoredConfig: AutoplayConfig | null = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_KEY_AUTOPLAY_CONFIG) ?? 'null',
);

export type AutoplayState = {
    isEnabled: boolean;
    config: AutoplayConfig | null;
};

const INITIAL_STATE: AutoplayState = {
    isEnabled: cookies.get('autoplay') === 'true',
    config: restoredConfig,
};

export default (state: AutoplayState = INITIAL_STATE, action: Action): AutoplayState => {
    switch (action.type) {
        case 'AUTOPLAY_SET_ENABLED':
            return {
                ...state,
                isEnabled: action.payload.isEnabled,
                config: null, // When Autoplay is enabled or disabled, reset the source
            };
        case '@user/SIGN_UP_SUCCESS':
        case '@user/PURCHASE_WEB_PLAYER_SUCCESS':
            // New signups should auto-opt-in to Autoplay
            return { ...state, isEnabled: true };

        case 'AUTOPLAY_CLEAR':
            return { ...state, config: null };
        case 'AUTOPLAY_START':
            return {
                isEnabled: true,
                config: action.payload.config,
            };
        default:
            return state;
    }
};
