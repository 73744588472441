import React from 'react';
import { ButtonWrapper } from './AnimatedPlayButton.styled';

interface Props {
    onClick: () => void;
}

export const PlayButton = (props: Props) => {
    return (
        <ButtonWrapper {...props}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 96 96"
                width="96"
                height="96"
                style={{ width: '100%', height: '100%' }}
                preserveAspectRatio="xMidYMid meet"
            >
                <g
                    style={{ userSelect: 'none' }}
                    transform="matrix(1,0,0,1,48.023,48.016)"
                    opacity="1"
                >
                    <g opacity="1" transform="matrix(0,1,-1,0,-0.027,-11.473)">
                        <path
                            fill="rgb(255,255,255)"
                            fillOpacity="1"
                            d="M0 0 M11.488,-20.934 C11.484,-20.926000000000002 11.484,-20.926 11.488,-20.938 C11.488,-20.938 11.426,-20.969 11.426,-20.969 C11.469,-20.957 11.469,-20.956999999999997 11.484,-20.961 C11.484,-20.961 32.414,11.016 32.414,11.016 C32.414,11.568 31.966,12.016 31.414,12.016 C31.414,12.016 11.5,11.969 11.5,11.969 C11.489,11.981 11.488,11.981 11.484,11.977 C11.484,11.977 11.488,-20.934 11.488,-20.934zM0 0 M11.09,-20.785 C11.243,-20.972 11.441,-20.96 11.445,-20.98 C11.445,-20.98 11.457,-20.977 11.457,-20.977 C11.469000000000001,-20.965 12.481692166990705,-16.965009255890678 12.469692166990704,-16.973009255890677 C12.469692166990704,-16.973009255890677 12.472691868413586,12.004 12.472691868413586,12.004 C12.472691868413586,12.016 12.472691868413586,12.016 12.476691868413585,11.996 C12.476691868413585,11.996 -8.539,11.969 -8.539,11.969 C-9.091,11.969 -9.539,11.520999999999999 -9.539,10.969 C-9.539,10.969 11.09,-20.785 11.09,-20.785z"
                        ></path>
                        <path
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            fillOpacity="0"
                            strokeMiterlimit="4"
                            stroke="rgb(255,255,255)"
                            strokeOpacity="1"
                            strokeWidth="0"
                            d="M0 0 M11.488,-20.934 C11.484,-20.926000000000002 11.484,-20.926 11.488,-20.938 C11.488,-20.938 11.426,-20.969 11.426,-20.969 C11.469,-20.957 11.469,-20.956999999999997 11.484,-20.961 C11.484,-20.961 32.414,11.016 32.414,11.016 C32.414,11.568 31.966,12.016 31.414,12.016 C31.414,12.016 11.5,11.969 11.5,11.969 C11.489,11.981 11.488,11.981 11.484,11.977 C11.484,11.977 11.488,-20.934 11.488,-20.934zM0 0 M11.09,-20.785 C11.243,-20.972 11.441,-20.96 11.445,-20.98 C11.445,-20.98 11.457,-20.977 11.457,-20.977 C11.469000000000001,-20.965 12.481692166990705,-16.965009255890678 12.469692166990704,-16.973009255890677 C12.469692166990704,-16.973009255890677 12.472691868413586,12.004 12.472691868413586,12.004 C12.472691868413586,12.016 12.472691868413586,12.016 12.476691868413585,11.996 C12.476691868413585,11.996 -8.539,11.969 -8.539,11.969 C-9.091,11.969 -9.539,11.520999999999999 -9.539,10.969 C-9.539,10.969 11.09,-20.785 11.09,-20.785z"
                        ></path>
                    </g>
                    <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                        <path
                            fill="rgb(255,255,255)"
                            fillOpacity="1"
                            d="M0 0 M-146.023,310.984"
                        ></path>
                        <path
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            fillOpacity="0"
                            strokeMiterlimit="4"
                            stroke="rgb(255,255,255)"
                            strokeOpacity="1"
                            strokeWidth="0"
                            d="M0 0 M-146.023,310.984"
                        ></path>
                    </g>
                </g>
            </svg>
        </ButtonWrapper>
    );
};
