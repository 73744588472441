import styled from 'styled-components';
import { B60, gridSize, H30, white } from '../../../../styles/index';

export const ImageDropZoneInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${white};
`;

export const ImageWrapper = styled.div`
    display: flex;
    width: ${9 * gridSize}px;
    height: ${9 * gridSize}px;

    align-items: center;
    margin-bottom: ${2 * gridSize}px;
`;

export const MainTextWrapper = styled(H30)`
    padding-bottom: ${2 * gridSize}px;
`;

export const SubTextWrapper = styled(B60)``;
