import * as fromTracksActions from 'redux/actions/tracks.actions';
import { store } from '../store';
import { Logger } from './Logger';

/*
 * This no-return function should be called when the user should be signed out.
 */
export const doSignOut = () => {
    // Clear all local storage values
    localStorage.clear();

    // Navigate to the login page. This page pop means no other reducers have to
    // handle the SIGN_OUT action as the Redux environment will be re-initialised.
    window.location.assign('/');
};

/**
 * This flag is used to prevent multiple sign out requests being made at once while
 * a signout process in in progresss. This can happen if multiple parallel requests
 * fail due to a 401/402/403 response.
 */
let isSigningOut = false;

/*
 * This function should be called on receipt of an HTTP response. It will log the user out if
 * it detects an HTTP 401 Unauthorized, HTTP 402 Payment Required or HTTP 403 Forbidden.
 * Otherwise it just returns the response object it was passed.
 */
export const signOutIfHttpResponseIs401402403 = (response: Response) => {
    const invalidAccountStatuses = [401, 402, 403];

    if (invalidAccountStatuses.includes(response.status) && !isSigningOut) {
        isSigningOut = true;
        store.dispatch(
            fromTracksActions.Actions.recordEvent('user_signed_out', {
                user_initiated: false,
            }),
        );
        Logger.log(`Logging out: API permission denied (${response.status} ${response.url})`, true);
        doSignOut();
    }

    return response;
};
