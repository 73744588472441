import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '../../../../components/Button';
import Modal from '../../../../components/Modal';
import {
    ButtonWrapper,
    ImageWrapper,
    Spaces,
    SubtitleDowngradedWrapper,
    SubtitleWrapper,
    TitleWrapper,
} from './FarewellModal.styled';
import FarewellImage from './farewell-image.svg';

type Props = {
    onClose: () => void;
};

export class FarewellModal extends Component<Props> {
    render() {
        const { onClose } = this.props;

        return (
            <Modal horizontalInsetPx={64} onClose={onClose}>
                <ImageWrapper>
                    <img width={112} height={112} src={FarewellImage} alt="" />
                </ImageWrapper>
                <Spaces n={2.5} />
                <TitleWrapper>
                    <FormattedMessage id="modal-sorry-to-see-you-go" />
                </TitleWrapper>
                <Spaces n={2.5} />
                <SubtitleWrapper>
                    <FormattedMessage id="modal-thank-you-for-your-support" />
                </SubtitleWrapper>
                <Spaces n={2} />
                <SubtitleDowngradedWrapper>
                    <FormattedMessage id="modal-downgrade" />
                </SubtitleDowngradedWrapper>
                <Spaces n={5} />
                <ButtonWrapper>
                    <Button onClick={onClose} width={355}>
                        Done
                    </Button>
                </ButtonWrapper>
                <Spaces n={4} />
            </Modal>
        );
    }
}

export default FarewellModal;
