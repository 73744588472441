import { all, fork } from 'redux-saga/effects';
import { watchGetDiscoverContent, watchOpenDiscoverList } from './discover.saga';
import { watchEmailChangeResponse, watchPasswordChangeResponse } from './flags.saga';
import {
    watchDownloadRecommendations,
    watchDownloadUpNext,
    watchEpisodeFinished,
    watchHistoryChanges,
    watchPauseEpisode,
    watchPlayEpisode,
    watchUpNextChanged,
    watchUpNextChanges,
    watchUpdateDuration,
    watchUpdatePlayedUpTo,
    watchUserReturned,
} from './player.saga';
import {
    watchAddPodcastByUrl,
    watchArchive,
    watchDownloadPodcast,
    watchDownloadPodcastColor,
    watchDownloadSubscribedPodcasts,
    watchEpisodeProgress,
    watchMarkAsPlayedChanges,
    watchOpenEpisode,
    watchOpenFilter,
    watchOpenPodcast,
    watchOpenShare,
    watchPodcastListRearrange,
    watchPodcastListUpdatePositions,
    watchSearchHistoryClear,
    watchSearchPodcastEpisodes,
    watchSearchRemote,
    watchSetSearchTerm,
    watchShowArchived,
    watchStarredChanges,
    watchSubscribeToPodcast,
    watchUnsubscribeToPodcast,
    watchUpdateAutoArchive,
    watchUpdateAutoArchivePlayed,
    watchUpdateAutoSkipLast,
    watchUpdateAutoStartFrom,
    watchUpdateEpisodeOrder,
    watchUpdatePlaybackEffects,
    watchUpdatePlaybackSpeed,
} from './podcasts.saga';
import { watchUploadStats } from './stats.saga';
import {
    watchFetchSubscriptionData,
    watchFreeGiftExperienceAcknowledge,
    watchMarketingSubscribe,
    watchMarketingUnsubscribe,
    watchRedeemPromoRequest,
    watchValidatePromoCode,
} from './subscription.saga';
import { watchTracksRecordEvent } from './tracks.saga';
import {
    watchAbortFileInUploadManager,
    watchAbortImageInUploadManager,
    watchAddFileToUploadManager,
    watchAddImageToUploadManager,
    watchCompleteFileInUploadManager,
    watchCompleteImageInUploadManager,
    watchFailedFileInUploadManager,
} from './upload-manager.saga';
import {
    watchFetchFile,
    watchFetchUploadedFilesData,
    watchFetchUploadedFilesSuccess,
    watchFetchUploadedFilesUsage,
    watchFileDelete,
    watchFileDownload,
    watchFileUploadPreflight,
    watchFilesUpdate,
    watchFilesUpdateSuccess,
    watchImageUploadPreflight,
    watchShowEditDialog,
} from './uploaded-files.saga';
import {
    calculateSettings,
    watchChangeEmail,
    watchChangePassword,
    watchDeleteAccount,
    watchDownloadStats,
    watchForgottenPassword,
    watchPurchaseWebPlayer,
    watchPurchaseWebPlayerFailure,
    watchResetPassword,
    watchSendSupportMessage,
    watchSettingsChanges,
    watchSignIn,
    watchSignOut,
    watchSignUp,
} from './user.saga';

export default function* root() {
    yield all([
        fork(watchDownloadSubscribedPodcasts),
        fork(calculateSettings),
        fork(watchDownloadUpNext),
        fork(watchUpNextChanged),
        fork(watchPlayEpisode),
        fork(watchPauseEpisode),
        fork(watchSubscribeToPodcast),
        fork(watchUnsubscribeToPodcast),
        fork(watchUpNextChanges),
        fork(watchEpisodeProgress),
        fork(watchEpisodeFinished),
        fork(watchOpenShare),
        fork(watchOpenEpisode),
        fork(watchDownloadPodcast),
        fork(watchSettingsChanges),
        fork(watchOpenPodcast),
        fork(watchStarredChanges),
        fork(watchUpdateEpisodeOrder),
        fork(watchUpdateAutoStartFrom),
        fork(watchUpdatePlaybackEffects),
        fork(watchUpdatePlaybackSpeed),
        fork(watchUpdateAutoArchive),
        fork(watchUpdateAutoArchivePlayed),
        fork(watchUpdateAutoSkipLast),
        fork(watchShowArchived),
        fork(watchPodcastListRearrange),
        fork(watchPodcastListUpdatePositions),
        fork(watchDownloadPodcastColor),
        fork(watchMarkAsPlayedChanges),
        fork(watchArchive),
        fork(watchSearchHistoryClear),
        fork(watchSearchRemote),
        fork(watchSearchPodcastEpisodes),
        fork(watchSetSearchTerm),
        fork(watchSignIn),
        fork(watchSignUp),
        fork(watchSignOut),
        fork(watchForgottenPassword),
        fork(watchUserReturned),
        fork(watchDownloadStats),
        fork(watchOpenFilter),
        fork(watchGetDiscoverContent),
        fork(watchOpenDiscoverList),
        fork(watchSendSupportMessage),
        fork(watchResetPassword),
        fork(watchChangeEmail),
        fork(watchDeleteAccount),
        fork(watchChangePassword),
        fork(watchUpdateDuration),
        fork(watchUpdatePlayedUpTo),
        fork(watchHistoryChanges),
        fork(watchAddPodcastByUrl),
        fork(watchDownloadRecommendations),
        fork(watchPurchaseWebPlayer),
        fork(watchPurchaseWebPlayerFailure),
        fork(watchFetchSubscriptionData),
        fork(watchMarketingSubscribe),
        fork(watchMarketingUnsubscribe),
        fork(watchFreeGiftExperienceAcknowledge),
        fork(watchFetchUploadedFilesData),
        fork(watchFetchUploadedFilesSuccess),
        fork(watchFetchUploadedFilesUsage),
        fork(watchFetchFile),
        fork(watchFilesUpdate),
        fork(watchFilesUpdateSuccess),
        fork(watchFileUploadPreflight),
        fork(watchImageUploadPreflight),
        fork(watchFileDelete),
        fork(watchShowEditDialog),
        fork(watchFileDownload),
        fork(watchAddFileToUploadManager),
        fork(watchCompleteFileInUploadManager),
        fork(watchFailedFileInUploadManager),
        fork(watchAbortFileInUploadManager),
        fork(watchAddImageToUploadManager),
        fork(watchCompleteImageInUploadManager),
        fork(watchAbortImageInUploadManager),
        fork(watchEmailChangeResponse),
        fork(watchPasswordChangeResponse),
        fork(watchValidatePromoCode),
        fork(watchRedeemPromoRequest),
        fork(watchUploadStats),
        fork(watchTracksRecordEvent),
    ]);
}
