import React, { useState } from 'react';
import Helmet from 'react-helmet';
import ScreenReaderText from 'components/format/ScreenReaderText';

// For right now this component is just announcing page title changes. However in the future we could
// set up a simple a11yMessage value in Redux, to allow any component to announce messages by dispatching
// an event without any more setup. This component could listen for those changes in Redux state and
// set the text (and probably politeness level) accordingly.
const GlobalA11yAnnouncements = () => {
    const [text, setText] = useState('');

    return (
        <>
            <ScreenReaderText>
                <span aria-live="assertive">
                    <span key={text}>{text}</span>
                </span>
            </ScreenReaderText>
            <Helmet
                onChangeClientState={({ title }: { title?: string }) => {
                    if (!title) {
                        return;
                    }
                    const cleanTitle = title.replace(' - Pocket Casts', '');
                    const titleAnnouncement = `Navigated to ${cleanTitle}`;
                    if (text !== titleAnnouncement) {
                        setText(titleAnnouncement);
                    }
                }}
            />
        </>
    );
};

export default GlobalA11yAnnouncements;
