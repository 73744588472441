import { createBrowserHistory, Location, LocationDescriptor, LocationState } from 'history';

/**
 * Our custom LocationState includes properties to determine if this history item has
 * previous or next history items.
 */
export type PocketCastsLocationState = LocationState & {
    canGoBack?: boolean;
    canGoForward?: boolean;
    scrollKey?: string;
};

/**
 * An instance of the normal react-router browser history object, used
 * to provide the basic functionality of a history object.
 */
const browserHistory = createBrowserHistory<PocketCastsLocationState>();

/**
 * The current location of the browser history object.
 */
let currentLocation = browserHistory.location;

const { push, replace } = browserHistory;

/**
 * Get the full path from a location object argument.
 * @param location The location object
 */
const getPathFromLocationArg = (location: LocationDescriptor) => {
    if (typeof location === 'string') {
        return location;
    }
    const { pathname, search, hash } = location;
    return `${pathname}${search ?? ''}${hash ?? ''}`;
};

/**
 * Extract the path and state from the arguments.
 * @param arg1 The path to push or a location object
 * @param arg2 The state object
 */
const fromVariadic = (arg1: LocationDescriptor, arg2?: PocketCastsLocationState) => {
    const path = getPathFromLocationArg(arg1);
    const state = (typeof arg1 === 'string' ? arg2 : arg1.state) ?? {};

    return { path, state: state as PocketCastsLocationState };
};

/**
 * Push a new location to the browser history object. This function adds `canGoForward` to the
 * current location state to allow forward navigation if we go back to it, and adds `canGoBack`
 * to the new location state to signal that we can traverse back.
 * @param arg1 The path to push or a location object
 * @param arg2 An optional state object
 */
const pocketCastsPush: typeof browserHistory.push = (
    arg1: LocationDescriptor,
    arg2?: PocketCastsLocationState,
) => {
    const { path, state } = fromVariadic(arg1, arg2);

    // There are some cases where the app may push the same location onto the stack. If this happens, we
    // abandon the push so we don't add a duplicate entry and mess with back/forward expectations.
    if (path === getPathFromLocationArg(currentLocation)) {
        return;
    }

    const scrollKey = currentLocation.state?.scrollKey || currentLocation.key || '/';

    // Update the current location to mark that forward navigation is possible
    replace(getPathFromLocationArg(currentLocation), {
        ...currentLocation.state,
        canGoForward: true,
        scrollKey,
    });

    push(path, { ...state, canGoBack: true, canGoForward: false });
};

/**
 * Replace the current location in the browser history object. This function ensures that the
 * `canGoBack` and `canGoForward` properties are preserved from the current location state.
 * @param arg1 The path to push or a location object
 * @param arg2 An optional state object
 */
const pocketCastsReplace: typeof browserHistory.replace = (
    arg1: LocationDescriptor,
    arg2?: PocketCastsLocationState,
) => {
    const { path, state } = fromVariadic(arg1, arg2);

    replace(path, {
        ...state,
        canGoBack: currentLocation.state?.canGoBack,
        canGoForward: currentLocation.state?.canGoForward,
    });
};

/**
 * Decide if a forward navigation is possible.
 * @returns whether a forward navigation is possible
 */
export const canGoForward = () => !!currentLocation.state?.canGoForward;

/**
 * Decide if a back navigation is possible.
 * @returns whether a back navigation is possible
 */
export const canGoBack = () => !!currentLocation.state?.canGoBack;

/**
 * Clear in-app navigation history by marking the current location as unable to go back/forward.
 * Note: this does not impact browser back/forward buttons, just our custom buttons in the app.
 */
export const clearNavigationHistory = () => {
    replace(getPathFromLocationArg(currentLocation), {
        ...currentLocation.state,
        canGoBack: false,
        canGoForward: false,
    });
};

/**
 * Listen to changes in the browser history object and update the current location.
 */
browserHistory.listen((location: Location<PocketCastsLocationState>) => {
    currentLocation = location;
});

// Replace the functions directly on browserHistory. Previously we were returning a new
// object with browserHistory spread into it:
//
// {
//   ...browserHistory,
//   push: pocketCastsPush,
//   replace: pocketCastsReplace,
// }
//
// But this caused problems because we were making a new copy of history.location that was
// no longer referencing the browser native location object. It caused odd quirks, like if
// you changed language and the whole React tree re-mounted, the Router would use the
// history.location at the moment the app loaded — changing the page underneath the user
// to whatever page they started their session from.
//
// Simply mutating browserHistory works better and the object still holds reference to the
// built-in browser objects.
browserHistory.push = pocketCastsPush;
browserHistory.replace = pocketCastsReplace;

export default browserHistory;
