import styled, { css } from 'styled-components';
import { blue, gridSize, Typography, H60, B60 } from '../../styles';

export const StatsPageWrapper = styled.div``;

export const Since = styled(B60)`
    color: ${props => props.theme.legacyScss['theme-text-secondary']};
    padding-top: ${5 * gridSize}px;
`;

export const TimeListened = styled.p`
    ${Typography.Desktop.H10};
    color: ${blue};
    font-size: 51.598px;
    font-weight: 600;
    line-height: 120%;

    margin: 0;
    padding: 12px 0 18px 0;
`;

export const Quote = styled(H60)`
    padding-bottom: 40px;
`;

export const TimeSavedBy = styled(B60)`
    padding-top: ${gridSize * 5}px;
    padding-bottom: ${gridSize * 2}px;
`;

export const SettingTitle = styled.span`
    width: 165px;
    padding-left: 10px;
`;

const SettingOptionMixin = css`
    display: flex;
    align-items: center;
    height: 48px;
`;

export const SettingOption = styled.div`
    ${SettingOptionMixin}
    svg {
        color: ${props => props.theme.tokens['primary-icon-01']};
    }
`;

export const SettingOptionTotal = styled.div`
    ${SettingOptionMixin}
    padding-left: 24px;
`;

export const Separator = styled.div`
    border-bottom: 1px solid ${props => props.theme.legacyScss['theme-line']};
`;
