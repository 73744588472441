import { TrackOnMount } from 'components/Tracks';
import { isMacApp } from 'helper/Browser';
import { useDispatch, useSelector } from 'hooks/react-redux-typed';
import useFormatMessage from 'hooks/useFormatMessage';
import useTracks from 'hooks/useTracks';
import React, { useCallback } from 'react';
import { setAutoplayEnabled } from 'redux/actions/autoplay.actions';
import { getSettings } from 'redux/reducers/selectors';
import { isAutoplayEnabled } from 'redux/reducers/selectors/autoplay.selectors';
import { Switch } from '../../../components/index';
import * as fromSettingsActions from '../../../redux/actions/settings.actions';
import {
    SettingsDescription,
    SettingsOption,
    SettingsTitle,
    SwitchWrapper,
} from '../SettingsPage.styled';
import { SettingsNumberText } from './SettingsNumberText';

const SettingsPlayback = () => {
    const formatMessage = useFormatMessage();
    const { recordEvent } = useTracks();
    const dispatch = useDispatch();
    const {
        skipForward,
        skipBack,
        performanceModeOn,
        recommendationsOn,
        intelligentResumption,
        remoteSkipChapters,
        showArchived,
    } = useSelector(getSettings);
    const autoplayOn = useSelector(isAutoplayEnabled);

    const onSaveSkipForward = useCallback(
        (secs: number) => {
            dispatch(fromSettingsActions.Actions.saveSkipForward(secs));
            recordEvent('settings_general_skip_forward_changed', { value: secs });
        },
        [dispatch, recordEvent],
    );

    const onSaveSkipBack = useCallback(
        (secs: number) => {
            dispatch(fromSettingsActions.Actions.saveSkipBack(secs));
            recordEvent('settings_general_skip_back_changed', { value: secs });
        },
        [dispatch, recordEvent],
    );

    const onToggleRecommendations = useCallback(() => {
        dispatch(fromSettingsActions.Actions.toggleRecommendations());
    }, [dispatch]);

    const onTogglePerformanceMode = useCallback(() => {
        dispatch(fromSettingsActions.Actions.togglePerformanceMode());
    }, [dispatch]);

    const onToggleAutoplay = useCallback(() => {
        dispatch(setAutoplayEnabled(!autoplayOn));
        recordEvent('settings_general_autoplay_toggled', { enabled: !autoplayOn });
    }, [dispatch, autoplayOn, recordEvent]);

    const onToggleIntelligentResumption = useCallback(() => {
        dispatch(fromSettingsActions.Actions.saveIntelligentResumption(!intelligentResumption));
    }, [dispatch, intelligentResumption]);

    const onToggleRemoteSkipChapters = useCallback(() => {
        dispatch(fromSettingsActions.Actions.saveRemoteSkipChapters(!remoteSkipChapters));
    }, [dispatch, remoteSkipChapters]);

    const onToggleShowArchived = useCallback(() => {
        dispatch(fromSettingsActions.Actions.saveShowArchived(!showArchived));
    }, [dispatch, showArchived]);

    return (
        <div>
            <TrackOnMount event="settings_general_shown" />
            <h1>{formatMessage('playback')}</h1>
            <SettingsOption>
                <SettingsTitle>{formatMessage('skip-forwards')}</SettingsTitle>
                <SettingsNumberText value={`${skipForward}`} onSave={onSaveSkipForward} />
            </SettingsOption>
            <SettingsOption>
                <SettingsTitle>{formatMessage('skip-backwards')}</SettingsTitle>
                <SettingsNumberText value={`${skipBack}`} onSave={onSaveSkipBack} />
            </SettingsOption>
            <SettingsOption>
                <SettingsTitle>{formatMessage('recommendations-toggle')}</SettingsTitle>
                <SwitchWrapper>
                    <Switch
                        on={recommendationsOn}
                        onChange={onToggleRecommendations}
                        color="#03A9F4"
                    />
                </SwitchWrapper>
                <SettingsDescription>
                    {formatMessage('recommendation-toggle-desc')}
                </SettingsDescription>
            </SettingsOption>
            <SettingsOption>
                <SettingsTitle>{formatMessage('autoplay-toggle')}</SettingsTitle>
                <SwitchWrapper>
                    <Switch on={autoplayOn} onChange={onToggleAutoplay} color="#03A9F4" />
                </SwitchWrapper>
                <SettingsDescription>{formatMessage('autoplay-toggle-desc')}</SettingsDescription>
            </SettingsOption>
            <SettingsOption>
                <SettingsTitle>{formatMessage('intelligent-playback-resumption')}</SettingsTitle>
                <SwitchWrapper>
                    <Switch
                        on={intelligentResumption}
                        onChange={onToggleIntelligentResumption}
                        color="#03A9F4"
                    />
                </SwitchWrapper>
                <SettingsDescription>
                    {formatMessage('intelligent-playback-resumption-desc')}
                </SettingsDescription>
            </SettingsOption>
            <SettingsOption>
                <SettingsTitle>{formatMessage('remote-skip-chapters')}</SettingsTitle>
                <SwitchWrapper>
                    <Switch
                        on={remoteSkipChapters}
                        onChange={onToggleRemoteSkipChapters}
                        color="#03A9F4"
                    />
                </SwitchWrapper>
                <SettingsDescription>
                    {formatMessage('remote-skip-chapters-desc')}
                </SettingsDescription>
            </SettingsOption>
            <SettingsOption>
                <SettingsTitle>{formatMessage('default-show-archived')}</SettingsTitle>
                <SwitchWrapper>
                    <Switch on={showArchived} onChange={onToggleShowArchived} color="#03A9F4" />
                </SwitchWrapper>
                <SettingsDescription>
                    {formatMessage('default-show-archived-desc')}
                </SettingsDescription>
            </SettingsOption>
            {isMacApp() && (
                <SettingsOption>
                    <SettingsTitle>{formatMessage('performance-mode')}</SettingsTitle>
                    <Switch
                        on={performanceModeOn}
                        onChange={onTogglePerformanceMode}
                        color="#03A9F4"
                    />
                    <SettingsDescription>
                        {formatMessage('performance-mode-description')}
                    </SettingsDescription>
                </SettingsOption>
            )}
        </div>
    );
};

export default SettingsPlayback;
