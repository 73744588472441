import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
    body {
        div:-moz-focusring,
        button:-moz-focusring,
        [type='button']:-moz-focusring,
        [type='reset']:-moz-focusring,
        [type='submit']:-moz-focusring {
            outline: 2px solid #63A0F8;
        }
    }
`;

// Global styles aren't highly recommended - if something can be global for a specific feature
// please add a global style to that component.
export const GlobalAppStyles = createGlobalStyle`
.app {
    position: relative;
    display: flex;
    height: 100%;
    width: 100vw;
    background-color: ${props => props.theme.legacyScss['theme-background']};
    color: ${props => props.theme.legacyScss['theme-text-primary']};
}

fieldset {
    padding: 0;
    margin: 0;
    border: none;
}

// Remove automatic X and eyeball button from input fields in Edge/IE11
::-ms-clear,
::-ms-reveal {
    display: none;
}

::-webkit-scrollbar {
    //display:none;
    background-color: transparent;
    width: 14px;
}

::-webkit-scrollbar-track {
    border: solid 3px transparent;
}

::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-color: ${props => props.theme.tokens['primary-ui-05']};
    color: red;
    background-clip: padding-box;
    padding: 0 4px;
    border-radius: 7px;
}

::-webkit-scrollbar-corner {
    background-color: transparent;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 30px ${props => props.theme.legacyScss['theme-background']} inset;
    -webkit-text-fill-color: ${props => props.theme.legacyScss['theme-text-primary']};
}

input::-webkit-input-placeholder, input:-ms-input-placeholder, input::-moz-placeholder, input:-moz-placeholder {
    /* Chrome */
    color: ${props => props.theme.legacyScss['theme-button-disabled-text']};
    opacity: 1;
}
`;
