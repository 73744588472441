import { DateText, DurationText, PodcastImage } from 'components';
import { PlayPauseButton } from 'components/PlayPauseButton';
import { onKeyDownEnter } from 'helper/UiHelper';
import { EpisodeType } from 'model/types';
import React, { KeyboardEvent, MouseEvent, useRef } from 'react';
import { FormattedMessage } from 'react-intl';

import {
    Actions,
    Content,
    EpisodeTitle,
    Image,
    Label,
    PlayButton,
    PodcastLink,
    Text,
    Wrapper,
} from './EpisodeCardFeature.styled';

export type Props = {
    podcastUuid: string;
    podcastTitle: string;
    title: string;
    published: string;
    duration: number;
    season?: number;
    number?: number;
    type: EpisodeType;
    text?: string;
    label?: string;
    color?: string;
    isPlaying?: boolean;
    onClick: (evt: MouseEvent | KeyboardEvent) => void;
    onPlayPauseClick: (evt: MouseEvent) => void;
    onPodcastClick: (evt: MouseEvent | KeyboardEvent) => void;
};

const EpisodeCardFeature = ({
    podcastUuid,
    podcastTitle,
    title,
    published,
    duration,
    type,
    text,
    label,
    color,
    isPlaying = false,
    onClick,
    onPlayPauseClick,
    onPodcastClick,
}: Props) => {
    const podcastLink = useRef<HTMLAnchorElement>(null);
    const playPauseButton = useRef<HTMLButtonElement>(null);

    const handleClick = (evt: MouseEvent | KeyboardEvent) => {
        if (!(evt.target instanceof Element)) {
            return;
        }
        if (podcastLink.current?.contains(evt.target)) {
            return;
        }
        if (playPauseButton.current?.contains(evt.target)) {
            return;
        }
        onClick(evt);
    };

    return (
        <Wrapper
            onClick={handleClick}
            onKeyPress={event => onKeyDownEnter(event, handleClick)}
            role="button"
            tabIndex={0}
        >
            <Image>
                <PodcastImage uuid={podcastUuid} />
            </Image>
            <Content>
                {label && <Label color={color}>{label}</Label>}
                <EpisodeTitle>{title}</EpisodeTitle>
                <PodcastLink
                    ref={podcastLink}
                    onClick={onPodcastClick}
                    onKeyDown={event => onKeyDownEnter(event, onPodcastClick)}
                    role="button"
                    tabIndex={0}
                >
                    {podcastTitle}
                </PodcastLink>

                {text && <Text>{text}</Text>}
                <Actions>
                    <PlayButton>
                        <PlayPauseButton
                            text={
                                type === EpisodeType.TRAILER ? (
                                    <FormattedMessage id="play-trailer" />
                                ) : (
                                    <FormattedMessage id="play-episode" />
                                )
                            }
                            color={color}
                            isPlaying={isPlaying}
                            onClick={onPlayPauseClick}
                            ref={playPauseButton}
                        />
                    </PlayButton>
                    <DurationText durationSecs={duration} playedUpToSecs={0} playingStatus={0} />
                    <DateText date={published} />
                </Actions>
            </Content>
        </Wrapper>
    );
};

export default EpisodeCardFeature;
