import React from 'react';
import AudioVideo from './AudioVideo';

class Audio extends AudioVideo {
    render() {
        const { url, playing } = this.props;
        return (
            <audio
                className="audio"
                src={url}
                autoPlay={playing}
                preload="auto"
                controls={false}
                ref={ref => {
                    this.player = ref;
                }}
            />
        );
    }

    isVideo() {
        return false;
    }
}

export default Audio;
