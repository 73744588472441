import React, { Component } from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { InputStatus } from 'components/Input/Input';
import Modal from '../../../../components/Modal';
import { Button } from '../../../../components/Button';
import Input from '../../../../components/Input/index';
import { MINIMUM_PASSWORD_LENGTH, MAXIMUM_PASSWORD_LENGTH } from '../../../../model/password';
import {
    Heading,
    CredentialWrapper,
    ButtonsWrapper,
    VerticalSpace,
    ResponseInformationWrapper,
    Separator,
} from './ChangePasswordModal.styled';

type Props = {
    requestSucceeded: boolean;
    requestPasswordChange: (oldPassword: string, newPassword: string) => void;
    onClose: () => void;
    requestInProgress: boolean;
    responseMessage: string;
} & WrappedComponentProps;

type StateProps = {
    oldPasswordValue: string;
    newPasswordValue: string;
    newPasswordStatus?: InputStatus;
    confirmPasswordValue: string;
    confirmPasswordStatus?: InputStatus;
};

export class ChangePasswordModal extends Component<Props, StateProps> {
    constructor(props: Props) {
        super(props);

        this.state = {
            oldPasswordValue: '',
            newPasswordValue: '',
            newPasswordStatus: undefined,
            confirmPasswordValue: '',
            confirmPasswordStatus: undefined,
        };
    }

    componentDidUpdate() {
        if (this.props.requestSucceeded) {
            this.props.onClose();
        }
    }

    onChangeOldPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            oldPasswordValue: event.target.value,
        });
    };

    onChangeNewPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { confirmPasswordValue } = this.state;

        const newPasswordValue = event.target.value;
        const newPasswordStatus =
            newPasswordValue.length === 0
                ? undefined
                : newPasswordValue.length < MINIMUM_PASSWORD_LENGTH ||
                  newPasswordValue.length > MAXIMUM_PASSWORD_LENGTH
                ? 'error'
                : 'ok';

        const confirmPasswordStatus =
            confirmPasswordValue.length === 0
                ? undefined
                : confirmPasswordValue === newPasswordValue
                ? 'ok'
                : 'error';

        this.setState({
            newPasswordValue,
            newPasswordStatus,
            confirmPasswordStatus,
        });
    };

    onChangeConfirmPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { newPasswordValue } = this.state;

        const confirmPasswordValue = event.target.value;

        const confirmPasswordStatus =
            confirmPasswordValue.length === 0
                ? undefined
                : confirmPasswordValue === newPasswordValue
                ? 'ok'
                : 'error';

        this.setState({
            confirmPasswordValue,
            confirmPasswordStatus,
        });
    };

    changePasswordClicked = () => {
        this.props.requestPasswordChange(
            this.state.oldPasswordValue,
            this.state.confirmPasswordValue,
        );
    };

    render() {
        const { intl, onClose, requestInProgress, responseMessage } = this.props;

        const {
            oldPasswordValue,
            newPasswordValue,
            newPasswordStatus,
            confirmPasswordValue,
            confirmPasswordStatus,
        } = this.state;

        return (
            <Modal horizontalInsetPx={32} onClose={onClose}>
                <Heading>{intl.formatMessage({ id: 'change-password' })}</Heading>
                <CredentialWrapper>
                    <Input
                        width={464}
                        placeholder={intl.formatMessage({ id: 'old-password' })}
                        type="password"
                        value={oldPasswordValue}
                        onChange={this.onChangeOldPassword}
                    />
                </CredentialWrapper>
                <CredentialWrapper>
                    <Input
                        width={464}
                        placeholder={intl.formatMessage({ id: 'new-password' })}
                        type="password"
                        status={newPasswordStatus}
                        value={newPasswordValue}
                        onChange={this.onChangeNewPassword}
                    />
                </CredentialWrapper>
                <CredentialWrapper>
                    <Input
                        width={464}
                        placeholder={intl.formatMessage({ id: 'confirm-new-password' })}
                        type="password"
                        status={confirmPasswordStatus}
                        value={confirmPasswordValue}
                        onChange={this.onChangeConfirmPassword}
                    />
                </CredentialWrapper>
                {newPasswordStatus === 'error' && (
                    <ResponseInformationWrapper>
                        {intl.formatMessage({ id: 'password-restriction' })}
                    </ResponseInformationWrapper>
                )}
                {confirmPasswordStatus === 'error' && newPasswordStatus !== 'error' && (
                    <ResponseInformationWrapper>
                        {intl.formatMessage({ id: 'password-change-mismatch' })}
                    </ResponseInformationWrapper>
                )}
                {responseMessage && (
                    <ResponseInformationWrapper>{responseMessage}</ResponseInformationWrapper>
                )}
                <Separator />
                <ButtonsWrapper>
                    <Button kind="secondary" width={120} onClick={this.props.onClose}>
                        {intl.formatMessage({ id: 'cancel' })}
                    </Button>
                    <VerticalSpace />
                    <Button
                        width={160}
                        onClick={this.changePasswordClicked}
                        disabled={newPasswordStatus !== 'ok' || confirmPasswordStatus !== 'ok'}
                        loading={requestInProgress}
                    >
                        {intl.formatMessage({ id: 'change-password' })}
                    </Button>
                </ButtonsWrapper>
            </Modal>
        );
    }
}

export default injectIntl(ChangePasswordModal);
