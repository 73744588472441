import { UploadedFile } from 'model/types';
import { RootState } from '..';

export const getUploadedFilesSuspended = (state: RootState) => state.uploadedFiles.suspended;

export const getUploadedFilesOrder = (state: RootState): string[] =>
    (state.uploadedFiles && state.uploadedFiles.data && state.uploadedFiles.data.filesOrder) || [];

export const getUploadedFilesMap = (state: RootState): Record<string, UploadedFile> =>
    (state.uploadedFiles && state.uploadedFiles.data && state.uploadedFiles.data.files) || {};

export const getUploadedFilesInOrder = (state: RootState) => {
    const files = getUploadedFilesMap(state);
    const order = getUploadedFilesOrder(state);
    return order.map(uuid => files[uuid]);
};

export const getUploadedFile = (state: RootState, uuid: string): UploadedFile | null =>
    (state.uploadedFiles &&
        state.uploadedFiles.data &&
        state.uploadedFiles.data.files &&
        state.uploadedFiles.data.files[uuid]) ||
    null;

export const getUploadedFileCurried = (state: RootState) => (uuid: string) =>
    getUploadedFile(state, uuid);

export const getUploadedFilesLastModified = (state: RootState) =>
    state.uploadedFiles && state.uploadedFiles.data && state.uploadedFiles.data.lastModified;

export const getUploadedFileLastModified = (state: RootState, uuid: string) =>
    state.uploadedFiles &&
    state.uploadedFiles.data &&
    state.uploadedFiles.data.files &&
    state.uploadedFiles.data.files[uuid] &&
    state.uploadedFiles.data.files[uuid].lastModified;
