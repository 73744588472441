import { Button } from 'components/Button';
import Input from 'components/Input';
import { OAuthLoginButton } from 'components/OAuthLoginButton';
import { Separator } from 'components/Separator';
import { useDispatch, useSelector } from 'hooks/react-redux-typed';
import useFormatMessage from 'hooks/useFormatMessage';
import isNaN from 'lodash/isNaN';
import { POCKET_CASTS_PRIVACY_POLICY, POCKET_CASTS_TERMS_OF_USE } from 'model/external-links';
import React, { FormEventHandler, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import * as fromUserActions from 'redux/actions/user.actions';
import { getEmail } from 'redux/reducers/selectors';
import { emailFieldSchema, passwordFieldSchema, useForm } from '../../../hooks/useForm';
import { StyledForm, StyledFormError } from '../form.styled';
import {
    ForgotPasswordLink,
    SSOButtons,
    SignedInWrapper,
    TermsAgreement,
} from './LoginRegisterForm.styled';

type Props = {
    allowSignup?: boolean;
    onLoggedIn?: () => void;
    isLoading?: boolean;
    title: string;
    submitButtonText: string;
};

function LoginRegisterForm({ allowSignup, onLoggedIn, isLoading, title, submitButtonText }: Props) {
    const dispatch = useDispatch();
    const formatMessage = useFormatMessage();
    const { form } = useForm({
        emailField: emailFieldSchema(formatMessage),
        passwordField: passwordFieldSchema(formatMessage),
    });
    const { fields, isValid, onChange, onFocusChange } = form;

    const accountEmail = useSelector(getEmail);
    const error = useSelector(state => {
        const { errorMessage } = state.user;
        if (!errorMessage) {
            return null;
        }

        if (errorMessage === '401' || errorMessage === '403') {
            return formatMessage(
                allowSignup ? 'signup-with-existing-email-failed' : 'login-failed',
            );
        }

        const errorString = isNaN(errorMessage) ? errorMessage : `Error: ${errorMessage}`;
        return `${formatMessage('login-request-failed')} (${errorString})`;
    });

    const isAuthLoading = useSelector(state => state.user.isFetching);
    const isSubscriptionLoading = useSelector(state => state.subscription.isLoading);
    const hasSubscriptionData = useSelector(
        state => Object.keys(state.subscription.data ?? {}).length > 0,
    );
    const isLoggedIn = !isSubscriptionLoading && hasSubscriptionData && !!accountEmail;
    const prevIsLoggedIn = useRef(false);

    useEffect(() => {
        // As soon as the user goes from "not logged in" to "logged in", call the callback
        if (!prevIsLoggedIn.current && isLoggedIn) {
            onLoggedIn?.();
        }
        prevIsLoggedIn.current = isLoggedIn;
    }, [isLoggedIn, onLoggedIn]);

    const clearForm = () => {
        form.setValue('emailField', '');
        form.setValue('passwordField', '');
        dispatch(fromUserActions.Actions.unAuthenticate());
    };

    const handleSubmit: FormEventHandler<HTMLFormElement> = evt => {
        evt.preventDefault();
        if (isLoggedIn) {
            // We're already logged in, don't log in again but do call the callback
            onLoggedIn?.();
        } else {
            const { emailField: email, passwordField: password } = form.getValues();

            if (allowSignup) {
                dispatch(fromUserActions.Actions.signUp(email, password));
            } else {
                dispatch(fromUserActions.Actions.signIn({ email, password }));
            }
        }
    };

    return (
        <StyledForm onSubmit={handleSubmit}>
            {accountEmail && (
                <SignedInWrapper>
                    <p>
                        {formatMessage('signed-in-as')} <strong>{accountEmail}</strong>
                    </p>
                    <Button kind="text" type="button" onClick={clearForm}>
                        {formatMessage('use-a-different-account')}
                    </Button>
                </SignedInWrapper>
            )}

            {!accountEmail && (
                <>
                    <h1 data-testid="title">{title}</h1>

                    <div data-testid="emailField">
                        <label htmlFor="LoginRegisterForm__email">
                            {fields.emailField.placeholder}
                        </label>
                        <Input
                            id="LoginRegisterForm__email"
                            name="emailField"
                            data-testid="emailFieldInput"
                            aria-label="Email"
                            type={fields.emailField.type}
                            value={fields.emailField.value}
                            status={fields.emailField.state}
                            onChange={onChange}
                            onFocus={e => onFocusChange(e, true)}
                            onBlur={e => onFocusChange(e, false)}
                        />
                        {!fields.emailField.focus && fields.emailField.error && (
                            <StyledFormError data-testid="emailField-error">
                                {fields.emailField.error}
                            </StyledFormError>
                        )}
                    </div>

                    <div data-testid="passwordField">
                        <label htmlFor="LoginRegisterForm__password">
                            {fields.passwordField.placeholder}
                        </label>
                        <ForgotPasswordLink to="/user/forgotten_password">
                            {formatMessage('forgot-password')}
                        </ForgotPasswordLink>
                        <Input
                            id="LoginRegisterForm__password"
                            name="passwordField"
                            data-testid="passwordFieldInput"
                            aria-label="Password"
                            type={fields.passwordField.type}
                            value={fields.passwordField.value}
                            status={fields.passwordField.state}
                            onChange={onChange}
                            onFocus={e => onFocusChange(e, true)}
                            onBlur={e => onFocusChange(e, false)}
                        />
                        {!fields.passwordField.focus && fields.passwordField.error && (
                            <StyledFormError data-testid="passwordField-error">
                                {fields.passwordField.error}
                            </StyledFormError>
                        )}
                    </div>
                </>
            )}

            {error && <StyledFormError>{error}</StyledFormError>}

            <Button
                type="submit"
                kind="primary"
                data-testid="submit-button"
                loading={isLoading || isAuthLoading || isSubscriptionLoading}
                disabled={isValid === false && !accountEmail}
            >
                {submitButtonText}
            </Button>

            {!accountEmail && (
                <>
                    <Separator message="or" />
                    <SSOButtons>
                        <OAuthLoginButton provider="apple" />
                        <OAuthLoginButton provider="google" />
                    </SSOButtons>
                </>
            )}

            {allowSignup && (
                <TermsAgreement>
                    <FormattedMessage
                        id="terms-agreement-wrapper"
                        values={{
                            terms: (
                                <a
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    href={POCKET_CASTS_TERMS_OF_USE}
                                >
                                    {formatMessage('terms-and-conditions')}
                                </a>
                            ),
                            privacy: (
                                <a
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    href={POCKET_CASTS_PRIVACY_POLICY}
                                >
                                    {formatMessage('privacy-policy')}
                                </a>
                            ),
                        }}
                    />
                </TermsAgreement>
            )}
        </StyledForm>
    );
}

export default LoginRegisterForm;
