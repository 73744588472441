import styled from 'styled-components';
import { Typography } from '../../../../styles';
import Modal from '../../../../components/Modal/Modal';
import ListItem from './CountryListItem';

export const ModalTitle = styled.h1`
    ${Typography.Desktop.H30};
    padding-bottom: 24px;
`;

export const ModalWrapper = styled(Modal)`
    width: 500px;
    background: ${props => props.theme.tokens['primary-ui-01']};
    color: ${props => props.theme.tokens['primary-text-01']};
`;

export const CountryList = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: 0;

    width: 100%;
    height: 500px;
    overflow-y: auto;
`;

export const CountryListItemDetails = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
export const CountryListItemTickWrapper = styled.div`
    svg {
        color: ${props => props.theme.tokens['primary-icon-01']};
        width: 24px;
        height: 24px;
    }
`;

export const CountryTitle = styled.p`
    ${Typography.Desktop.H65};
    margin-left: 16px;
`;

export const CountryFlag = styled.img`
    width: 32px;
    height: 32px;
    border-radius: 50%;
`;

export const CountryListItem = styled(ListItem)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    outline: none;

    width: 100%;
    height: 56px;
    border-top: 1px solid ${props => props.theme.tokens['primary-ui-05']};

    &:hover,
    &:focus {
        color: ${props => props.theme.tokens['primary-icon-01']};
    }
`;
