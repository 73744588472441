import qs from 'query-string';

export function buildQueryString(map: Record<string, unknown>) {
    const queryBuilder = {} as Record<string, unknown>;

    Object.keys(map).forEach(key => {
        if (map[key] != null) {
            queryBuilder[key] = map[key];
        }
    });

    const query = qs.stringify(queryBuilder);
    return query !== '' ? `?${query}` : '';
}

export const getRedirectQueryParam = () => qs.parse(window.location.search).redirect;
