import React from 'react';
import styled from 'styled-components';
import { gridSize } from 'styles';
import { Bookmark } from 'model/types';
import { useDispatch } from 'hooks/react-redux-typed';
import useFormatMessage from 'hooks/useFormatMessage';
import { deleteBookmark } from 'redux/actions/episode-sync.actions';
import { Button } from 'components/Button';

export type Props = {
    bookmark: Bookmark;
    onSubmit?: () => void;
};

const Form = styled.form`
    button {
        width: 100%;
        margin-top: ${gridSize}px;
    }
`;

const DeleteBookmarkForm = ({ bookmark, onSubmit }: Props) => {
    const dispatch = useDispatch();
    const formatMessage = useFormatMessage();

    const handleSubmit = () => {
        dispatch(deleteBookmark(bookmark));
        onSubmit?.();
    };

    return (
        <Form onSubmit={handleSubmit}>
            <p>{formatMessage('delete-bookmark-confirmation')}</p>
            <Button kind="primary-red" type="submit">
                {formatMessage('delete-bookmark')}
            </Button>
        </Form>
    );
};

export default DeleteBookmarkForm;
