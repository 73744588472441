import formatCurrency from '@automattic/format-currency';
import JwtDecode from 'jwt-decode';
import {
    PaddleCheckoutResponse,
    PaddleOrderResponse,
    PaddleProduct,
    PaddleProductId,
    PaddleResponse,
} from 'model/types';

type CheckoutPayload = PaddleSubscribeProps & { userUuid: string };

type PaddleSubscribeProps = {
    accountEmail: string | null;
    accountToken: string | null;
    coupon?: string;
    paddleSku?: PaddleProductId;
    successCallback: (data: PaddleResponse) => void;
    closeCallback?: () => void;
    paymentFailedCallback: (data: PaddleCheckoutResponse) => void;
};

/**
 * Paddle subscription logic.
 * As we have multiple places where you can purchase a subscription and multiple web 'platforms'
 * available, it is much safer to have this in once place, even if it is a bit verbose to work with.
 *
 * @param accountEmail - string - Email address fo the account holder
 * @param accountToken - string - JWT token of the account holder
 * @param successCallback - Function(data: object) - Subscription success callback, passed the Paddle data payload
 * @param closeCallback - Function() - Callback when the subscription process is closed before completion
 */
export const openPaddleCheckout = (paddleSubscribeProps: PaddleSubscribeProps) => {
    const { accountToken } = paddleSubscribeProps;

    // eslint-disable-next-line
    // @ts-ignore
    const userUuid = JwtDecode<string>(accountToken ?? '').sub as string;

    checkoutUsingPaddle({
        ...paddleSubscribeProps,
        userUuid,
    });
};

export const openPaddleCancelCheckout = ({
    override,
    successCallback,
    closeCallback,
}: {
    override: string;
    successCallback: () => void;
    closeCallback: () => void;
}) => {
    window.Paddle.Checkout.open({
        override,
        successCallback,
        closeCallback,
    });
};

export const openUpdateDetailsPaddleCheckout = (override: string) => {
    window.Paddle.Checkout.open({
        override,
    });
};

function checkoutUsingPaddle({
    userUuid,
    accountEmail,
    coupon,
    paddleSku,
    successCallback,
    paymentFailedCallback,
    closeCallback,
}: CheckoutPayload) {
    window.Paddle.Checkout.open({
        product: paddleSku,
        coupon,
        passthrough: `{"uuid": "${userUuid}"}`,
        email: accountEmail,
        disableLogout: true,
        closeCallback,
        successCallback: (checkoutResponse: PaddleCheckoutResponse) => {
            const checkoutId = checkoutResponse.checkout.id;
            // Get the subscription ID
            window.Paddle.Order.details(checkoutId, (orderResponse: PaddleOrderResponse) => {
                // According to documentation, if orderResponse exists the payment went through. Otherwise
                // there was some unrecoverable problem, like the payment being flagged as high-risk.
                // See https://developer.paddle.com/guides/b299bce2a2f40-post-checkout#order-details
                if (orderResponse) {
                    successCallback({
                        checkoutResponse,
                        orderResponse,
                    });
                } else {
                    paymentFailedCallback(checkoutResponse);
                }
            });
        },
    });
}

export const getLocalizedSubscriptionPrice = (product: PaddleProduct) =>
    formatCurrency(product.subscription.price.gross, product.currency);

export const getLocalizedSubscriptionDiscountPrice = (product: PaddleProduct) =>
    formatCurrency(product.price.gross, product.currency);
