import { LOCAL_STORAGE_KEY_SEARCH_HISTORY } from 'model/local-storage';
import { SearchHistoryItem, SearchResponse } from 'model/types';
import * as fromSearchActions from '../actions/search.actions';

const getStoredHistory = (): SearchHistoryItem[] =>
    JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_SEARCH_HISTORY) || '[]');
const updateStoredHistory = (history: SearchHistoryItem[]) =>
    localStorage.setItem(LOCAL_STORAGE_KEY_SEARCH_HISTORY, JSON.stringify(history));

const dedupeHistory = (history: SearchHistoryItem[], item: SearchHistoryItem) =>
    history.filter(historyItem => {
        if (
            'podcast' in item &&
            'podcast' in historyItem &&
            item.podcast.uuid === historyItem.podcast.uuid
        ) {
            return false;
        }
        if (
            'folder' in item &&
            'folder' in historyItem &&
            item.folder.uuid === historyItem.folder.uuid
        ) {
            return false;
        }
        if (
            'episode' in item &&
            'episode' in historyItem &&
            item.episode.uuid === historyItem.episode.uuid
        ) {
            return false;
        }
        if ('term' in item && 'term' in historyItem && item.term === historyItem.term) {
            return false;
        }
        return true;
    });

export interface SearchState {
    history: SearchHistoryItem[];
    responses: Record<string, SearchResponse | undefined>;
    isSearching: boolean;
    term: string;
    addUrlErrorMessage: string;
    addUrlLoading: boolean;
}

export const INITIAL_STATE = {
    history: getStoredHistory(),
    responses: {},
    isSearching: false,
    term: '',
    addUrlErrorMessage: '',
    addUrlLoading: false,
};

export default (
    state: SearchState = INITIAL_STATE,
    action: fromSearchActions.Actions,
): SearchState => {
    switch (action.type) {
        case fromSearchActions.ActionTypes.SEARCH_HISTORY_ITEM_ADD: {
            const history = [
                { ...action.payload.item, date: Date.now() },
                ...dedupeHistory(state.history, action.payload.item),
            ];
            updateStoredHistory(history);
            return { ...state, history };
        }
        case fromSearchActions.ActionTypes.SEARCH_HISTORY_ITEM_REMOVE: {
            const history = dedupeHistory(state.history, action.payload.item);
            updateStoredHistory(history);
            return { ...state, history };
        }
        case fromSearchActions.ActionTypes.SEARCH_HISTORY_CLEAR: {
            updateStoredHistory([]);
            return { ...state, history: [] };
        }
        case fromSearchActions.ActionTypes.SET_SEARCH_TERM:
            if (action.payload.term === '') {
                // When clearing the search term, reset state to default except cached responses and history
                return { ...INITIAL_STATE, responses: state.responses, history: state.history };
            }
            return { ...state, isSearching: true, term: action.payload.term };
        case fromSearchActions.ActionTypes.FETCH_EPISODES_SEARCH_RESULTS_SUCCESS: {
            const { term, episodes } = action.payload;
            const currentResponse = state.responses[term];
            return {
                ...state,
                responses: { ...state.responses, [term]: { ...currentResponse, episodes } },
            };
        }
        case fromSearchActions.ActionTypes.FETCH_PODCASTS_SEARCH_RESULTS_SUCCESS: {
            const { term, podcasts } = action.payload;
            const currentResponse = state.responses[term];
            return {
                ...state,
                responses: { ...state.responses, [term]: { ...currentResponse, podcasts } },
            };
        }
        case fromSearchActions.ActionTypes.FETCH_SEARCH_RESULTS_SUCCESS:
            return {
                ...state,
                responses: { ...state.responses, [action.payload.term]: action.payload.response },
                isSearching: false,
                addUrlErrorMessage: '',
                addUrlLoading: false,
            };
        case fromSearchActions.ActionTypes.FETCH_SEARCH_RESULTS_FAILED:
            return { ...state, isSearching: false };
        case fromSearchActions.ActionTypes.ADD_PODCAST_BY_URL_FAILED:
            return {
                ...state,
                addUrlErrorMessage: action.payload.errorMessage,
                addUrlLoading: false,
            };
        case fromSearchActions.ActionTypes.ADD_PODCAST_BY_URL:
            return { ...state, addUrlErrorMessage: '', addUrlLoading: true };
        case fromSearchActions.ActionTypes.ADD_PODCAST_BY_URL_SUCCESS:
            return { ...state, addUrlErrorMessage: '', addUrlLoading: false };
        default:
            return state;
    }
};
