import useFormatMessage from 'hooks/useFormatMessage';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, Route } from 'react-router-dom';
import { USE_IMPORT_EXPORT } from 'settings';
import { Header } from '../../components';
import { isMacApp, isWindowsApp } from '../../helper/Browser';
import { SettingNavigation } from './SettingNavigation';
import { SettingsAbout } from './SettingsAbout';
import { SettingsApps } from './SettingsApps';
import { SettingsArchiving } from './SettingsArchiving';
import { SettingsBeta } from './SettingsBeta';
import { SettingsFiles } from './SettingsFiles';
import { SettingsHelp } from './SettingsHelp';
import { SettingsImportExport } from './SettingsImportExport';
import { SettingsLanguage } from './SettingsLanguage';
import {
    SettingsPageContentWrapper,
    SettingsPageWrapper,
    SettingsRouteContainer,
    SettingsSideNavigation,
} from './SettingsPage.styled';
import { SettingsPlayback } from './SettingsPlayback';
import { SettingsPrivacy } from './SettingsPrivacy';

const SettingsPage = () => {
    const formatMessage = useFormatMessage();
    const isInNativeApp = isMacApp() || isWindowsApp();
    return (
        <SettingsPageWrapper>
            <Helmet>
                <title>{formatMessage('settings')}</title>
            </Helmet>
            <Header title={formatMessage('settings')} />
            <SettingsPageContentWrapper>
                <SettingsSideNavigation>
                    <SettingNavigation title={formatMessage('playback')} to="/settings/playback" />
                    <SettingNavigation
                        title={formatMessage('settings-archiving')}
                        to="/settings/archiving"
                    />
                    <SettingNavigation title={formatMessage('files')} to="/settings/files" />
                    <SettingNavigation title={formatMessage('language')} to="/settings/language" />
                    {!isInNativeApp && (
                        <SettingNavigation title={formatMessage('apps')} to="/settings/apps" />
                    )}
                    <SettingNavigation title={formatMessage('privacy')} to="/settings/privacy" />
                    <SettingNavigation title={formatMessage('help')} to="/settings/help" />
                    <SettingNavigation title={formatMessage('beta')} to="/settings/beta" />
                    {USE_IMPORT_EXPORT && (
                        <SettingNavigation
                            title={formatMessage('import-and-export')}
                            to="/settings/import-export"
                        />
                    )}
                    <SettingNavigation title={formatMessage('about')} to="/settings/about" />
                </SettingsSideNavigation>
                <SettingsRouteContainer>
                    <Route
                        exact
                        path="/settings"
                        render={() => <Redirect to="/settings/playback" />}
                    />
                    <Route path="/settings/playback" component={SettingsPlayback} />
                    <Route path="/settings/archiving" component={SettingsArchiving} />
                    <Route path="/settings/files" component={SettingsFiles} />
                    <Route path="/settings/language" component={SettingsLanguage} />
                    {!isInNativeApp && <Route path="/settings/apps" component={SettingsApps} />}
                    <Route path="/settings/privacy" component={SettingsPrivacy} />
                    <Route path="/settings/help" component={SettingsHelp} />
                    <Route path="/settings/beta" component={SettingsBeta} />
                    {USE_IMPORT_EXPORT && (
                        <Route path="/settings/import-export" component={SettingsImportExport} />
                    )}
                    <Route path="/settings/about" component={SettingsAbout} />
                </SettingsRouteContainer>
            </SettingsPageContentWrapper>
        </SettingsPageWrapper>
    );
};

export default SettingsPage;
