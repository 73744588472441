import { call, select, throttle, put } from 'redux-saga/effects';
import { api } from '../../services/api';
import * as fromStatsActions from '../actions/stats.actions';
import { getStats } from '../reducers/selectors';
import * as StatsHelper from '../../helper/StatsHelper';
import { logSagaError } from './saga-helper';

export function* sendLatestStatsToServer() {
    try {
        const stats: ReturnType<typeof getStats> = yield select(getStats);

        const skippingStat = StatsHelper.calcSecsWithRemainingMs(stats.timeSavedSkippingMs);
        const skippingIntro = StatsHelper.calcSecsWithRemainingMs(stats.timeSavedSkippingIntroMs);
        const variableSpeed = StatsHelper.calcSecsWithRemainingMs(stats.timeSavedVariableSpeedMs);
        const totalListening = StatsHelper.calcSecsWithRemainingMs(stats.totalListeningTimeMs);

        yield put(
            fromStatsActions.Actions.updateStats({
                totalListeningMs: totalListening.remainingMs,
                skippingStatMs: skippingStat.remainingMs,
                skippingIntroMs: skippingIntro.remainingMs,
                variableSpeedMs: variableSpeed.remainingMs,
            }),
        );

        yield call(
            api.addStats,
            0,
            skippingStat.timeSecs,
            skippingIntro.timeSecs,
            variableSpeed.timeSecs,
            totalListening.timeSecs,
        );
    } catch (error) {
        logSagaError('Failed to upload stats', error);
    }
}

export function* watchUploadStats() {
    yield throttle(5000, fromStatsActions.ActionTypes.UPLOAD_STATS, sendLatestStatsToServer);
}
