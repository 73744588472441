import styled from 'styled-components';
import { yellow50 } from 'styles';

export const StarButtonWrapper = styled.span`
    width: 16px;
    height: 16px;
    cursor: pointer;
    color: ${props => props.theme.tokens['primary-text-02']};
    opacity: 0.4;
    &:hover {
        opacity: 1;
    }
    &.on {
        opacity: 1;
        color: ${yellow50};
    }
`;
