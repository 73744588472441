import { useCallback, useState, useEffect } from 'react';

export function useListFocus(size: number) {
    const [focus, setFocus] = useState(0);

    const handleKeyDown = useCallback(
        e => {
            if (e.key === 'ArrowUp') {
                e.preventDefault();
                setFocus(focus === 0 ? size - 1 : focus - 1);
            } else if (e.key === 'ArrowDown') {
                e.preventDefault();
                setFocus(focus === size - 1 ? 0 : focus + 1);
            }
        },
        [size, focus, setFocus],
    );

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown, false);
        return () => {
            document.removeEventListener('keydown', handleKeyDown, false);
        };
    }, [handleKeyDown]);

    return [focus, setFocus] as const;
}
