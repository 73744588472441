import styled from 'styled-components';
import { gridSize, red } from 'styles';
import { Wrapper as InputWrapper } from 'components/Input/Input.styled';
import { CredentialNotificationWrapper } from 'components/form/CredentialNotification/CredentialNotification.styled';

export const StyledForm = styled.form`
    width: 100%;
    h1 {
        font-size: 28px;
        letter-spacing: -0.02em;
        margin: 0 0 ${3 * gridSize}px;
        @media (min-width: 400px) {
            font-size: 32px;
        }
        @media (min-width: 800px) {
            font-size: 28px;
        }
        @media (min-width: 1000px) {
            font-size: 32px;
        }
    }
    > p {
        color: ${props => props.theme.tokens['primary-text-02']};
        line-height: 1.4;
        font-weight: 400;
        margin: 0 0 ${3 * gridSize}px;
    }
    > label {
        font-size: 14px;
        font-weight: 500;
        > input {
            margin-right: ${gridSize}px;
            position: relative;
            top: 0.1em;
        }
    }
    > button {
        margin: ${2 * gridSize}px 0;
    }
    ${InputWrapper} {
        margin: ${gridSize}px 0 ${3 * gridSize}px;
    }
    ${CredentialNotificationWrapper} {
        margin-bottom: ${3 * gridSize}px;
    }
`;

export const StyledFormError = styled.span`
    display: block;
    color: ${red};
    font-size: 14px;
    font-weight: 500;
    margin: ${-2 * gridSize}px 0 ${3 * gridSize}px;
    a {
        color: inherit;
        font-weight: 800;
    }
`;
