import { useDispatch, useSelector } from 'react-redux';
import { api } from 'services/api';
import * as fromFlagsActions from 'redux/actions/flags.actions';
import { getSubscribedPodcasts } from 'redux/reducers/selectors';
import useFormatMessage from './useFormatMessage';
import useTracks from './useTracks';

const getPodcastsUuidsFromOPML = async (opmlData: string) => {
    try {
        const podcastUuids = await api.importOPML(opmlData);
        return podcastUuids;
    } catch (e) {
        console.error('Error importing OPML:', e);
        return [];
    }
};

const getImportedPodcastsUUIDs = async (fileContents: Blob) =>
    new Promise<string[]>((resolve, reject) => {
        const reader = new FileReader();

        reader.readAsText(fileContents, 'utf-8');

        reader.onload = async () => {
            const podcastUuids = await getPodcastsUuidsFromOPML(reader.result as string);

            resolve(podcastUuids);
        };

        reader.onerror = () => {
            reject(reader.error);
        };
    });

const subscribeToNewPodcasts = async (podcastUUIDs: string[]) => {
    let importedPodcastsCount = 0;
    // Subscribe one by one to avoid overwhelming the server (would result in 500s if so)
    for (const uuid of podcastUUIDs) {
        try {
             
            await api.subscribeToPodcast(uuid);
            importedPodcastsCount += 1;
        } catch (e) {
            console.error('Error subscribing to podcast:', e);
        }
    }
    return importedPodcastsCount;
};

const useImportPodcasts = () => {
    const dispatch = useDispatch();

    const formatMessage = useFormatMessage();
    const { recordEvent } = useTracks();

    const subscribedPodcasts = useSelector(getSubscribedPodcasts);

    const subscribedPodcastsIds = subscribedPodcasts.map(podcast => podcast.uuid);

    const importPodcasts = async (file: Blob) => {
        recordEvent('opml_import_started');

        dispatch(
            fromFlagsActions.Actions.addFlag(formatMessage('import-in-progress'), {
                id: 'import-podcasts',
                persistent: true,
            }),
        );

        const importedPodcastsUUIDs = await getImportedPodcastsUUIDs(file);

        const newPodcastsToSubscribe = importedPodcastsUUIDs.filter(
            uuid => !subscribedPodcastsIds.includes(uuid),
        );

        if (!newPodcastsToSubscribe.length) {
            dispatch(fromFlagsActions.Actions.removeFlag('import-podcasts'));
            dispatch(fromFlagsActions.Actions.addFlag(formatMessage('import-no-new')));
            return;
        }

        const newSubscriptionsCount = await subscribeToNewPodcasts(newPodcastsToSubscribe);

        dispatch(fromFlagsActions.Actions.removeFlag('import-podcasts'));

        if (newSubscriptionsCount === 0) {
            dispatch(fromFlagsActions.Actions.addFlag(formatMessage('import-failed')));
            recordEvent('opml_import_failed', { reason: 'No podcasts found in OPML file' });
            return;
        }

        recordEvent('opml_import_finished', { number_parsed: newSubscriptionsCount });

        dispatch(
            fromFlagsActions.Actions.addFlag(
                formatMessage('import-success', { count: newSubscriptionsCount }),
            ),
        );
    };

    return importPodcasts;
};

export default useImportPodcasts;
