import { SmallIconSvgs } from 'components/SvgDefinitions';
import React from 'react';
import styled from 'styled-components';
import { IconId } from './index';

export type Props = {
    id: IconId;
    size?: number;
} & Omit<React.SVGProps<SVGElement>, 'ref'>; // Need to omit 'ref' due to typing conflict with styled-components

const SVG = styled('svg')`
    display: block;
`;

const Icon = ({ id, size, ...props }: Props) => {
    let viewBox = '0 0 24 24';
    let width = size ?? 24;
    let height = size ?? 24;

    if (Object.keys(SmallIconSvgs).includes(id)) {
        viewBox = '0 0 16 16';
        width = size ?? 16;
        height = size ?? 16;
    }

    return (
        <SVG width={width} height={height} viewBox={viewBox} {...props}>
            <use xlinkHref={`#${id}`} />
        </SVG>
    );
};

export default Icon;
