import React from 'react';
import { PlayingStatus } from '../../../../helper/PlayingStatus';

const INITIAL_STATE = {
    seeking: false,
    allowedToSave: false,
    isReady: false,
};

class Base extends React.Component {
    constructor(props) {
        super(props);
        this.state = INITIAL_STATE;
    }

    componentDidMount() {
        const { url, playing, volume, speed, muted, theme } = this.props;
        if (url !== null) {
            this.resetPlayer();
        } else if (playing) {
            this.play();
        }
        if (volume) {
            this.setVolume(volume);
        }
        if (muted) {
            this.setMuted(muted);
        }
        if (speed) {
            this.setSpeed(speed);
        }
        if (theme) {
            this.setTheme(theme);
        }
    }

    componentWillUnmount() {
        this.stop();
    }

    componentDidUpdate(prevProps) {
        const { url, playing, volume, speed, muted, skipBack, skipForward, theme, episode } =
            this.props;
        if (prevProps.url !== url && url) {
            this.resetPlayer();
        } else if (prevProps.url && !url) {
            this.stop();
        } else if (!prevProps.playing && playing) {
            this.play();
        } else if (prevProps.playing && !playing) {
            this.pause();
        } else if (prevProps.volume !== volume) {
            this.setVolume(volume);
        } else if (prevProps.muted !== muted) {
            this.setMuted(muted);
        } else if (prevProps.speed !== speed) {
            this.setSpeed(speed);
        } else if (prevProps.skipForward !== skipForward) {
            this.setSkipTimes(skipForward, skipBack);
        } else if (prevProps.skipBack !== skipBack) {
            this.setSkipTimes(skipForward, skipBack);
        } else if (prevProps.theme !== theme) {
            this.setTheme(theme);
        } else if (
            prevProps.episode &&
            episode &&
            prevProps.episode.imageUrl !== episode.imageUrl
        ) {
            if (this.setUpdatedArtwork !== undefined) {
                this.setUpdatedArtwork(episode);
            }
        }
    }

    resetPlayer() {
        this.setState(INITIAL_STATE);
    }

    onReady() {
        this.setState({ isReady: true });
    }

    skip(amountSecs) {
        const playedUpTo = this.getCurrentTime();
        if (playedUpTo === null || playedUpTo === -1) {
            return;
        }
        let newTime = this.getCurrentTime() + amountSecs;
        if (newTime < 0) {
            newTime = 0;
        }
        if (amountSecs > 0) {
            this.props.addTimeSavedSkipping?.(amountSecs * 1000);
        }
        this.props.onProgress?.(newTime);
        this.seekTo(newTime);
    }

    msToNextPlayerSecond = () => (1 - (this.getCurrentTime() % 1)) * 1000;

    markProgress = () => {
        this.addProgressStats();
        this.updateProgress();
        this.progressTimeout = setTimeout(this.markProgress, this.msToNextPlayerSecond() + 50);
    };

    startTimer() {
        this.setState({ allowedToSave: true });

        this.timerStartedAt = Date.now();

        if (!this.progressTimeout) {
            clearTimeout(this.progressTimeout);
            this.progressTimeout = setTimeout(this.markProgress, this.msToNextPlayerSecond() + 50);
        }

        if (!this.saveInterval) {
            clearInterval(this.saveInterval);
            this.saveInterval = setInterval(() => {
                this.saveProgress();
            }, 10000);
        }
    }

    addProgressStats() {
        if (!this.timerStartedAt) {
            // The timer hasn't been started, this shouldn't have been called yet
            return;
        }

        const now = Date.now();
        const listeningTime = now - this.timerStartedAt;
        this.timerStartedAt = now;

        if (listeningTime < 0) {
            // Danger, Will Robinson. This should never happen but let's protect against it just in case.
            return;
        }

        this.props.addTotalListeningTime(listeningTime);
        if (this.getSpeed() > 1) {
            this.props.addTimeSavedVariableSpeed(listeningTime * this.getSpeed() - listeningTime);
        }
    }

    stopTimer() {
        this.setState({ allowedToSave: false });

        this.timerStartedAt = null;

        if (this.progressTimeout) {
            clearTimeout(this.progressTimeout);
            this.progressTimeout = null;
        }
        if (this.saveInterval) {
            clearInterval(this.saveInterval);
            this.saveInterval = null;
        }
    }

    saveProgressWhilePaused() {
        if (this.props.playing) {
            return;
        }
        const playedUpTo = this.getCurrentTime();
        if (
            playedUpTo === null ||
            playedUpTo === -1 ||
            (playedUpTo === this.props.lastSentPlayedUpTo &&
                PlayingStatus.IN_PROGRESS === this.props.lastSentPlayingStatus)
        ) {
            return;
        }
        this.props.onSaveProgress?.(playedUpTo);
    }

    saveProgress() {
        if (!this.state.allowedToSave || this.state.seeking) {
            return;
        }
        const playedUpTo = this.getCurrentTime();
        if (
            playedUpTo === null ||
            playedUpTo === -1 ||
            playedUpTo === this.props.lastSentPlayedUpTo
        ) {
            return;
        }
        this.props.onSaveProgress?.(playedUpTo);
    }

    updateProgress() {
        const playedUpTo = this.getCurrentTime();
        if (this.state.seeking || playedUpTo === null || playedUpTo === -1) {
            return;
        }
        this.props.onProgress?.(playedUpTo);
    }

    updateDuration() {
        if (this.duration) {
            this.props.onDurationChanged?.(this.duration);
        }
    }
}

export default Base;
