import styled from 'styled-components';
import { blueTextLinkMixin, H70 } from '../../styles';

export const FilterPageWrapper = styled.div`
    position: relative;
    display: flex;
    height: 100%;
    flex-direction: column;
`;

export const EpisodesWrapper = styled.div`
    display: flex;
    height: 100%;
    min-height: 0; // Take up as much height as possible, without squishing neighbors
    flex: 1 1 auto;
    flex-direction: column;
`;

export const ClearHistoryWrapper = styled.div`
    display: flex;
    height: 40px;
    justify-content: flex-end;
    align-items: center;
`;

export const ClearHistory = styled(H70)`
    ${blueTextLinkMixin};
    cursor: pointer;
`;

export const ErrorWrapper = styled.div`
    padding-top: 168px;
`;

export const Separator = styled.div`
    width: 100%;
    border-bottom: 1px solid ${props => props.theme.legacyScss['theme-line']};
`;
