import React from 'react';
import { LoaderSquareWrapper } from './LoaderSquare.styled';

type Props = {
    color?: string;
};

export function LoaderSquare({ color }: Props) {
    const styles = color ? { backgroundColor: color } : {};
    return (
        <LoaderSquareWrapper className="loader-square" data-testid="loader-square">
            <div className="spinners">
                <div className="spinner1" style={styles} />
                <div className="spinner2" style={styles} />
                <div className="spinner3" style={styles} />
                <div className="spinner4" style={styles} />
            </div>
        </LoaderSquareWrapper>
    );
}
