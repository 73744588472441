import styled from 'styled-components';

export const SkipButtonContainer = styled.button`
    width: 45px;
    height: 45px;
    background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2245%22%20height%3D%2245%22%20viewBox%3D%220%200%2045%2045%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ctitle%3Eplayer%2Fskipforward%3C%2Ftitle%3E%3Cpath%20d%3D%22M33%209H21.5C12.94%209%206%2015.94%206%2024.5%206%2033.06%2012.94%2040%2021.5%2040%2030.06%2040%2037%2033.06%2037%2024.5c0-.552-.448-1-1-1s-1%20.448-1%201C35%2031.956%2028.956%2038%2021.5%2038S8%2031.956%208%2024.5%2014.044%2011%2021.5%2011H33v4l6-5-6-5v4z%22%20fill%3D%22%23FFF%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat;
    cursor: pointer;
    p {
        margin: 0;
        padding: 3px 1px 0 0;
        display: block;
        width: 45px;
        height: 43px;
        text-align: center;
        font-size: 12px;
        font-weight: 500;
        color: #fff;
        letter-spacing: 0;
        line-height: 40px;
        box-sizing: border-box;
        user-select: none;
    }
`;
