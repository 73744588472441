import * as Sentry from '@sentry/browser';
import {
    MediaErrorLinkMessage,
    MediaErrorMessageAborted,
    MediaErrorMessageDecode,
    MediaErrorMessageNetwork,
    MediaErrorMessageNotSupported,
    MediaErrorMessageOther,
} from 'components/messages';
import { isWindowsApp } from 'helper/Browser';
import { sendGoogleAnalyticsEvent } from 'helper/GoogleAnalyticsHelper';
import { Logger } from 'helper/Logger';
import { MCStatsGroup, bumpStat } from 'helper/MCStatsHelper';
import debounce from 'lodash/debounce';
import Base from './Base';

const DecodingErrorRetryStatus = {
    AttemptRetry: 'AttemptRetry',
    IsRetrying: 'IsRetrying',
};

class AudioVideo extends Base {
    edgeRetryTimer = null;

    autoRetryPlayTimer = null;

    constructor(props) {
        super(props);

        this.state = {
            attemptRetryStatus: null,
            networkErrorRetryCount: 0,
        };

        this.onEventPlaying = this.onEventPlaying.bind(this);
        this.onEventPause = this.onEventPause.bind(this);
        this.onEventAbort = this.onEventAbort.bind(this);
        this.onEventEnded = this.onEventEnded.bind(this);
        this.onEventSeeking = this.onEventSeeking.bind(this);
        this.onEventSeeked = this.onEventSeeked.bind(this);

        // On some browsers (Chromium) this event is fired far too many times when the actual duration
        // value has not changed, or in a very quick succession which causes performance problems.
        this.onEventDurationChange = debounce(this.onEventDurationChange.bind(this), { wait: 100 });

        this.onEventVolumeChange = this.onEventVolumeChange.bind(this);
        this.onEventWaiting = this.onEventWaiting.bind(this);
        this.onEventLoadedMetadata = this.onEventLoadedMetadata.bind(this);
        this.onEventCanPlay = this.onEventCanPlay.bind(this);
        this.onEventError = this.onEventError.bind(this);
    }

    componentDidMount() {
        this.player.volume = 1;
        this.player.addEventListener('playing', this.onEventPlaying);
        this.player.addEventListener('pause', this.onEventPause);
        this.player.addEventListener('abort', this.onEventAbort);
        this.player.addEventListener('ended', this.onEventEnded);
        this.player.addEventListener('seeking', this.onEventSeeking);
        this.player.addEventListener('seeked', this.onEventSeeked);
        this.player.addEventListener('durationchange', this.onEventDurationChange);
        this.player.addEventListener('volumechange', this.onEventVolumeChange);
        this.player.addEventListener('waiting', this.onEventWaiting);
        this.player.addEventListener('loadedmetadata', this.onEventLoadedMetadata);
        this.player.addEventListener('canplay', this.onEventCanPlay);
        this.player.addEventListener('error', this.onEventError);
    }

    componentWillUnmount() {
        this.player.removeEventListener('playing', this.onEventPlaying);
        this.player.removeEventListener('pause', this.onEventPause);
        this.player.removeEventListener('abort', this.onEventAbort);
        this.player.removeEventListener('ended', this.onEventEnded);
        this.player.removeEventListener('seeking', this.onEventSeeking);
        this.player.removeEventListener('seeked', this.onEventSeeked);
        this.player.removeEventListener('durationchange', this.onEventDurationChange);
        this.player.removeEventListener('volumechange', this.onEventVolumeChange);
        this.player.removeEventListener('waiting', this.onEventWaiting);
        this.player.removeEventListener('loadedmetadata', this.onEventLoadedMetadata);
        this.player.removeEventListener('canplay', this.onEventCanPlay);
        this.player.removeEventListener('error', this.onEventError);

        clearTimeout(this.edgeRetryTimer);
        clearTimeout(this.autoRetryPlayTimer);

        this.stop();
    }

    logEvent(message, includeDetails = false) {
        const { episode, podcast } = this.props;
        const details = includeDetails ? `\npodcast=${podcast?.uuid} episode=${episode?.uuid}` : '';
        Logger.log(`[Audio] ${message}${details}`);
    }

    onEventPlaying() {
        this.logEvent(`playing ${this.props.url}`);
        this.hideBufferingMessage();
        this.startTimer();
        this.setState({ networkErrorRetryCount: 0 });
        if (!this.props.playing) {
            // The player element started playing but Redux state was not updated (meaning the play likely came from
            // native player controls). Let's force state to update:
            this.props.onPlay?.('native_controls');
        }
    }

    onEventCanPlay() {
        this.logEvent('can play');
        const { playedUpTo } = this.props;
        // fix for Safari on High Sierra
        if (!this.state.isReady && this.player.currentTime !== playedUpTo) {
            this.player.currentTime = playedUpTo;
        }
        this.onReady();
    }

    onEventPause() {
        this.logEvent('pause');
        this.saveProgress();
        this.stopTimer();
        if (this.props.playing && !this.player.ended) {
            // The player element paused but Redux state was not updated. This could be because the media ended (in
            // which case we do nothing because we want the player to keep playing). But otherwise the pause likely
            // came from native player controls. So let's force Redux state to update to match the paused state:
            this.props.onPause?.('native_controls');
        }
    }

    onEventAbort() {
        this.logEvent('abort');
        this.stopTimer();
    }

    onEventEnded(event) {
        this.logEvent('ended', true);
        this.stopTimer();
        this.props.onEnded?.(event);
    }

    onEventSeeking(event) {
        this.logEvent(`seeking: ${this.getCurrentTime()}`);
        this.setState({ seeking: true });
        this.props.onSeeked?.(event);
    }

    onEventSeeked() {
        this.setState({ seeking: false });
        this.props.onBuffering?.(false);
        // After seeking is done, update our playback time. This is useful in case native controls are
        // used to change the seek time, to make sure Redux state is informed of the new timestamp.
        this.updateProgress();
        this.logEvent(`seeked: ${this.getCurrentTime()}`);
    }

    onEventDurationChange(event) {
        this.logEvent('duration change');
        if (Number.isNaN(event.target.duration)) {
            return;
        }

        this.props.onDurationChanged?.(event.target.duration);
    }

    // FIXME: This is a temporary hack to fix Windows 10 app playback issues
    // Remove this once the windows app is replaced and no longer uses Edge 17/18
    checkIfAutoRetryAfterDecodingError = errorCode => {
        if (errorCode !== MediaError.MEDIA_ERR_DECODE) {
            return false;
        }

        if (this.state.attemptRetryStatus) {
            return true;
        }

        if (!this.state.attemptRetryStatus) {
            this.setState({
                attemptRetryStatus: DecodingErrorRetryStatus.AttemptRetry,
            });

            // Reset the edge retry flag after x seconds, this should ensure that
            // if the error occurs occurs every 10 minutes like many reports suggest, it
            // the episode should still continue playing
            this.edgeRetryTimer = setTimeout(() => {
                this.setState({ attemptRetryStatus: null });
            }, 5000);

            return true;
        }
        return false;
    };

    onEventError() {
        this.logEvent(
            `error ${this.player.error.code}: ${this.player.error.message}\nURL=${this.props.url}`,
        );

        // Get an idea of how often each type of error is happening
        bumpStat(MCStatsGroup.AUDIO_VIDEO_ERRORS, this.player.error.code);
        sendGoogleAnalyticsEvent(
            'Audio/Video Log',
            'Error',
            `${this.player.error.code}: ${this.player.error.message}\nURL=${this.props.url}`,
        );

        let message = '';

        switch (this.player.error.code) {
            case MediaError.MEDIA_ERR_ABORTED:
                message = MediaErrorMessageAborted(MediaErrorLinkMessage());
                break;
            case MediaError.MEDIA_ERR_DECODE: {
                // https://github.com/shiftyjelly/pocketcasts-webplayer/issues/1292
                // FIXME: Remove once Edge 17/18 is no longer used in Windows App
                if (isWindowsApp()) {
                    const isAttemptingToRecoverFromError = this.checkIfAutoRetryAfterDecodingError(
                        this.player.error.code,
                    );

                    if (isAttemptingToRecoverFromError) {
                        if (this.state.attemptRetryStatus !== DecodingErrorRetryStatus.IsRetrying) {
                            this.logEvent('Error decoding stream - retrying...', true);

                            this.setState({
                                attemptRetryStatus: DecodingErrorRetryStatus.IsRetrying,
                            });

                            // Add a slight delay - if it is caused by a corrupted packet or network error in Edge,
                            // this should theoretically allow enough time to connect/recover. Note: Depending on the situation
                            // the user may experience some slight buffering
                            this.autoRetryPlayTimer = setTimeout(() => {
                                this.play();
                            }, 350);
                        }

                        return;
                    }
                }

                message = MediaErrorMessageDecode(MediaErrorLinkMessage());
                break;
            }
            case MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED:
                message = MediaErrorMessageNotSupported(MediaErrorLinkMessage());
                break;
            case MediaError.MEDIA_ERR_NETWORK:
                // On media error, first try re-loading the player twice. This handles cases where a token in
                // an MP3's URL has become invalid, by re-fetching from the source URL which should issue a new
                // token (see https://github.com/shiftyjelly/pocketcasts-webplayer/issues/1326). If that doesn't
                // work, assume it's a network issue and show the message.
                if (this.state.networkErrorRetryCount < 2) {
                    this.logEvent(
                        `MediaError ${this.player.error.code} — ${
                            this.player.error.message
                        }\nRetry #${this.state.networkErrorRetryCount + 1}`,
                    );

                    this.setState({
                        networkErrorRetryCount: this.state.networkErrorRetryCount + 1,
                    });

                    this.player.load();
                    return;
                }

                Sentry.captureException(
                    new Error(
                        `MediaError ${this.player.error.code} — ${this.player.error.message}`,
                    ),
                );

                message = MediaErrorMessageNetwork(MediaErrorLinkMessage());
                break;
            default:
                message = MediaErrorMessageOther(MediaErrorLinkMessage());
                break;
        }

        this.props.onError(message);
        this.props.onPause?.('unexpected_error');
        this.props.onBuffering?.(false);
    }

    onEventVolumeChange(event) {
        this.logEvent(`volume change: ${event.target.volume}`);
        if (this.props.volume !== event.target.volume) {
            this.props.onVolumeChanged?.(event.target.volume);
        }
        if (this.props.muted !== event.target.muted) {
            this.props.onMuteChanged?.(event.target.muted);
        }
    }

    onEventWaiting() {
        this.logEvent('waiting');

        // wait a short period for showing the buffering text so it doesn't flash on the screen
        this.changeToBufferingTimer = setTimeout(() => {
            if (this.changeToBufferingTimer === null) {
                return;
            }
            this.props.onBuffering?.(true);
        }, 500);
    }

    onEventLoadedMetadata() {
        this.logEvent('loaded metadata');
        const { playedUpTo, volume, muted } = this.props;

        this.setPodcastSpeed();
        this.player.currentTime = playedUpTo;
        this.player.volume = volume;
        this.player.muted = muted;
        if (this.isVideo()) {
            this.onVideoLoad();
        }
    }

    setPodcastSpeed() {
        const defaultPlaybackRate = this.props.speed; // last saved default playback rate
        if (this.props.podcast && this.props.podcast.settings) {
            const {
                playbackEffects: { value: playbackEffects },
                playbackSpeed: { value: playbackSpeed },
            } = this.props.podcast.settings;
            if (playbackEffects && playbackSpeed) {
                this.setSpeed(playbackSpeed);
            } else {
                this.setSpeed(defaultPlaybackRate);
            }
        } else {
            this.setSpeed(defaultPlaybackRate);
        }
    }

    hideBufferingMessage() {
        if (this.changeToBufferingTimer !== null) {
            clearInterval(this.changeToBufferingTimer);
            this.changeToBufferingTimer = null;
        }

        this.props.onBuffering?.(false);
    }

    play() {
        if (this.player.error) {
            this.player.load();
        }

        const { playedUpTo } = this.props;
        if (this.player.currentTime !== playedUpTo) {
            this.player.currentTime = playedUpTo;
        }

        this.logEvent(`play: ${this.player.currentTime}`, true);

        this.player.play();
    }

    pause() {
        this.player.pause();
    }

    stop() {
        this.player.src = '';
        this.player.removeAttribute('src');
        this.stopTimer();
    }

    setVolume(volume) {
        this.player.volume = volume;
    }

    setMuted(muted) {
        this.player.muted = muted;
    }

    setSpeed(speed) {
        // Audio/Video elements have both playbackRate and defaultPlaybackRate. When these values are
        // different, it's interpreted as a _temporary_ rate change, so when the media changes the
        // element resets playbackRate to defaultPlaybackRate.
        //
        // For our purposes we never want a playback rate change to be temporary, it should persist
        // until explicitly changed by the user. So we need to make sure whenever we change playbackRate
        // we also change defaultPlaybackRate.
        //
        // More: https://developer.mozilla.org/en-US/docs/Web/Guide/Audio_and_video_delivery/WebAudio_playbackRate_explained#defaultplaybackrate_and_ratechange
        this.player.defaultPlaybackRate = speed;
        this.player.playbackRate = speed;
    }

    setTheme(theme) {
        this.player.theme = theme;
    }

    getSpeed() {
        return this.player.playbackRate;
    }

    seekTo(timeSecs) {
        this.player.currentTime = timeSecs;
    }

    getDuration() {
        if (!this.state.isReady) return null;
        return this.player.duration;
    }

    getCurrentTime() {
        if (!this.state.isReady) return null;
        return this.player.currentTime;
    }

    // eslint-disable-next-line
    setSkipTimes(skipForward, skipBack) {}
}

export default AudioVideo;
