import React from 'react';
import useFormatMessage from 'hooks/useFormatMessage';
import { Icon } from 'components/Icon';
import { ReplaceImageButtonWrapper, ReplaceImageInput, ReplaceImageLabel } from './styled';

const REPLACE_IMAGE_INPUT_ID = 'file-custom-image-replace';

const ReplaceImageButton = ({ uuid, requestImageUploadPreflight, onMouseEnter, onMouseLeave }) => {
    const formatMessage = useFormatMessage();

    const fileUploadInputChanged = event => {
        const file = (event.target.files && event.target.files[0]) || null;

        if (file !== null) {
            requestImageUploadPreflight(uuid, file);
        }
    };

    return (
        <ReplaceImageButtonWrapper>
            <ReplaceImageLabel
                htmlFor={REPLACE_IMAGE_INPUT_ID}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                aria-label={formatMessage('replace-image')}
            >
                <Icon id="pencil" />
            </ReplaceImageLabel>
            <ReplaceImageInput
                id={REPLACE_IMAGE_INPUT_ID}
                type="file"
                onChange={fileUploadInputChanged}
            />
        </ReplaceImageButtonWrapper>
    );
};

export default ReplaceImageButton;
