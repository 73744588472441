import Image from 'components/Image';
import { PodcastImagePlaceholder } from 'components/PodcastImagePlaceholder';
import * as PodcastHelper from 'helper/PodcastHelper';
import useResize from 'hooks/useResize';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';

type Props = {
    title?: string;
    uuid?: string;
    borderRadius?: number;
    className?: string;
};

function PodcastImage({ uuid = '', borderRadius, title, className = '' }: Props) {
    const webp = useSelector((state: RootState) => state.settings.webpSupported);

    const ref = useRef<HTMLDivElement>(null);
    const { width } = useResize(ref);

    const url = width > 0 ? PodcastHelper.getImageUrl(width, uuid, webp) : undefined;

    return (
        <Image
            src={url}
            className={className}
            alt={title}
            borderRadius={borderRadius}
            placeholder={<PodcastImagePlaceholder />}
            ref={ref}
        />
    );
}

export default PodcastImage;
