import { DeleteAccountForm } from 'components/AccountDeleteForm';
import React from 'react';
import { useDispatch } from 'react-redux';
import Modal from '../../../../components/Modal';
import * as fromUserActions from '../../../../redux/actions/user.actions';
import { DeleteAccountFormWrapper } from './DeleteAccountModal.styled';

const DeleteAccountModal = () => {
    const dispatch = useDispatch();

    const handleModalOnClose = () => {
        dispatch(fromUserActions.Actions.dismissDeleteAccountModal());
        dispatch(fromUserActions.Actions.cancelAccountDelete());
    };

    return (
        <Modal horizontalInsetPx={32} onClose={handleModalOnClose}>
            <DeleteAccountFormWrapper>
                <DeleteAccountForm showCancel onCancel={handleModalOnClose} />
            </DeleteAccountFormWrapper>
        </Modal>
    );
};

export default DeleteAccountModal;
