import styled from 'styled-components';

/**
 * A div that forces a scrollbar, but is hidden, to pad the spacing correctly when sibling elements
 * have a scrollbar and we need both components to be vertically aligned.
 */
export const ScrollbarPaddingSpacer = styled.div`
    overflow-y: scroll;
    visibility: hidden;
`;

export const ScrollbarPaddingWrapper = styled.div`
    display: flex;
    width: 100%;
`;

export const ScrollbarContentWrapper = styled.div`
    flex: 1;
`;
