import { PodcastImage } from 'components';
import { Icon } from 'components/Icon';
import { RecommendedEpisode } from 'model/types';
import React from 'react';
import {
    CardBackground,
    EpisodeAuthor,
    EpisodeLink,
    EpisodeTextWrapper,
    EpisodeTitle,
    PlayEpisodeButton,
    RecommendEpisodeWrapper,
} from './RecommendEpisode.styled';

type Props = {
    episode: RecommendedEpisode;
    podcastLink: string;
    onEpisodeClick: () => void;
    onPlayClick: () => void;
};

class RecommendEpisode extends React.Component<Props> {
    render() {
        const { episode, onEpisodeClick, podcastLink, onPlayClick } = this.props;

        if (!episode) {
            return null;
        }

        return (
            <RecommendEpisodeWrapper>
                <CardBackground />
                <PlayEpisodeButton onClick={onPlayClick}>
                    <PodcastImage uuid={episode.podcastUuid} />
                    <Icon id="play-icon" />
                </PlayEpisodeButton>

                <EpisodeTextWrapper>
                    <EpisodeTitle onClick={onEpisodeClick}>{episode.title || ''}</EpisodeTitle>
                    <EpisodeAuthor>
                        <EpisodeLink to={podcastLink}>{episode.podcastTitle || ''}</EpisodeLink>
                    </EpisodeAuthor>
                </EpisodeTextWrapper>
            </RecommendEpisodeWrapper>
        );
    }
}

export default RecommendEpisode;
