import { CheckoutModalType, Subscription } from 'model/types';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from 'redux/reducers';
import * as fromSubscriptionActions from '../../../redux/actions/subscription.actions';
import * as fromTracksActions from '../../../redux/actions/tracks.actions';
import * as fromUserActions from '../../../redux/actions/user.actions';
import ActionsColumn from './ActionsColumn';

const mapStateToProps = (state: RootState) => ({
    marketingOptIn: state.settings.marketingOptIn,
    accountEmail: state.user.email,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    showChangeEmailModal: () => dispatch(fromUserActions.Actions.showChangeEmailModal()),
    showDeleteAccountModal: () => dispatch(fromUserActions.Actions.showDeleteAccountModal()),
    showChangePasswordModal: () => dispatch(fromUserActions.Actions.showChangePasswordModal()),
    marketingSubscribeRequest: () => {
        dispatch(fromSubscriptionActions.Actions.marketingSubscribeRequest());
        dispatch(
            fromTracksActions.Actions.recordEvent('newsletter_opt_in_changed', {
                enabled: true,
                source: 'profile',
            }),
        );
    },
    marketingUnsubscribeRequest: () => {
        dispatch(fromSubscriptionActions.Actions.marketingUnsubscribeRequest());
        dispatch(
            fromTracksActions.Actions.recordEvent('newsletter_opt_in_changed', {
                enabled: false,
                source: 'profile',
            }),
        );
    },
    showBillingDetailsModal: (plusSubscription: Subscription) =>
        dispatch(
            fromSubscriptionActions.Actions.showModalGeneric(
                'showChangeBillingDetailsModal',
                plusSubscription,
            ),
        ),
    showSubscriptionCancelModal: (plusSubscription: Subscription) =>
        dispatch(
            fromSubscriptionActions.Actions.showModalGeneric('showCancelModal', plusSubscription),
        ),
    showCheckoutModalForCancelled: () =>
        dispatch(
            fromSubscriptionActions.Actions.showCheckoutModal(CheckoutModalType.TYPE_WELCOME_BACK),
        ),
    showCheckoutModalForGifted: () =>
        dispatch(fromSubscriptionActions.Actions.showCheckoutModal(CheckoutModalType.TYPE_SETUP)),
    signOut: () => dispatch(fromUserActions.Actions.signOut()),
});

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(ActionsColumn);

export { ConnectedComponent as ActionsColumn };
