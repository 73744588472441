import formatCurrency from '@automattic/format-currency';
import { LoaderSquare } from 'components';
import { BackButton } from 'components/BackButton';
import { PlanSelector } from 'components/PlanSelector';
import DiscountedPlanSelector from 'components/PlanSelector/DiscountedPlanSelector';
import { TrackOnMount, TrackOnUnmount } from 'components/Tracks';
import { CredentialNotification } from 'components/form/CredentialNotification';
import { LoginRegisterForm } from 'components/form/LoginRegisterForm';
import {
    getLocalizedSubscriptionDiscountPrice,
    getLocalizedSubscriptionPrice,
} from 'helper/PaddleCheckoutHelper';
import { isInfoOfferAvailableForCountry } from 'helper/PlansHelper';
import * as TimeHelper from 'helper/TimeHelper';
import { Location } from 'history';
import useFormatMessage from 'hooks/useFormatMessage';
import { useGeolocation } from 'hooks/useGeolocation';
import { usePaddle } from 'hooks/usePaddle';
import useResize from 'hooks/useResize';
import { PaddleDiscountedPlan, PaddlePlan } from 'model/types';
import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router';
import * as fromSubscriptionActions from 'redux/actions/subscription.actions';
import * as fromUserActions from 'redux/actions/user.actions';
import { RootState } from 'redux/reducers';
import { USE_50_PERCENT_BY_DEFAULT } from 'settings';
import {
    ContentWrapper,
    FinePrint,
    Form,
    Plans,
    RegisterPageWrapper,
    TWO_COLUMNS_BREAKPOINT,
} from './RegisterPage.styled';

const discountedPlan = 'switch-to-pocket-casts';

const defaultPlan = (location: Location) => {
    const { plan } = qs.parse(location.search);
    switch (plan) {
        case 'plus-monthly':
        case 'plus-yearly':
        case 'patron-monthly':
        case 'patron-yearly':
            return plan;
        case 'monthly':
            return 'plus-monthly';
        case discountedPlan:
            return plan;
        default:
            return 'plus-yearly';
    }
};

type Props = RouteComponentProps;

function RegisterPage({ location }: Props) {
    const formatMessage = useFormatMessage();
    const dispatch = useDispatch();
    const history = useHistory();
    const { windowWidth } = useResize();
    const [plan, setPlan] = useState<PaddlePlan | PaddleDiscountedPlan>(defaultPlan(location));
    const { code: couponCodeFromQuery } = qs.parse(location.search);
    const couponCode = couponCodeFromQuery?.toString();
    const { countryCode, hasFetchedLocation } = useGeolocation();

    const { isPurchasing, subscriptionPurchaseFailed } = useSelector((state: RootState) => ({
        isPurchasing: state.payments.isPurchasing,
        accountEmail: state.user.email,
        // Occurs when there is a delay in Paddle sending the purchase webhook to the server
        // (usually because the fraud score of the transaction is too high).
        subscriptionPurchaseFailed: state.payments.subscriptionPurchaseFailed,
    }));

    const { product, doSubscribeFlow } = usePaddle({ plan, code: couponCode });
    const priceString = product ? getLocalizedSubscriptionPrice(product) : '';

    const isCouponCode = !!product?.applied_coupon?.code;
    const offerIntroDiscount = isInfoOfferAvailableForCountry(countryCode);

    const offerHalfOffIntro =
        !isCouponCode &&
        (plan === discountedPlan ||
            (plan === 'plus-yearly' && USE_50_PERCENT_BY_DEFAULT && offerIntroDiscount));
    const isDiscounted = plan === discountedPlan || isCouponCode || offerHalfOffIntro;

    const halfOffPriceString = product
        ? formatCurrency(product.subscription.price.gross / 2, product.currency)
        : '';
    const discountedPriceString =
        product && isCouponCode
            ? getLocalizedSubscriptionDiscountPrice(product)
            : halfOffPriceString;

    const trialDays =
        isCouponCode || !offerIntroDiscount ? 0 : product?.subscription.trial_days ?? 0;
    const isTrial = trialDays > 0 && !offerHalfOffIntro;

    // Make sure the URL always contains the selected plan, so it doesn't get reset on
    // page refreshes and redirects from OAuth.
    useEffect(() => {
        // Update the current query string with the new plan, and re-stringify it
        const search = qs.stringify({ ...qs.parse(location.search), plan });
        history.replace({
            pathname: location.pathname,
            search: `?${search}`,
        });
    }, [plan]);

    // During checkout, we need to know if we offered them an intro offer or not
    // so we can apply the correct discount code or trial days.
    useEffect(() => {
        const offerIntroDiscount = isInfoOfferAvailableForCountry(countryCode);
        dispatch(
            fromSubscriptionActions.Actions.saveIntroOfferEligibility(
                !couponCode && offerIntroDiscount,
            ),
        );
    }, [couponCode]);

    // if plan === discountedPlan and offerIntroDiscount is false, set plan to yearly
    useEffect(() => {
        if (plan === discountedPlan && !offerIntroDiscount) {
            setPlan('plus-yearly');
        }
    }, [offerIntroDiscount, plan]);

    // Clear errors on unmount
    useEffect(
        () => () => {
            dispatch(fromUserActions.Actions.clearUserError());
        },
        [],
    );

    const showCheckoutForm = !subscriptionPurchaseFailed;

    if (!hasFetchedLocation) {
        return <LoaderSquare />;
    }

    return (
        <RegisterPageWrapper>
            <TrackOnMount event="create_account_shown" properties={{ product: plan }} />
            <TrackOnUnmount event="create_account_dismissed" />
            <Helmet>
                <title>{formatMessage('sign-up')}</title>
            </Helmet>
            <BackButton buttonText={formatMessage('back')} />
            <ContentWrapper>
                <Plans>
                    {isDiscounted ? (
                        <DiscountedPlanSelector
                            plan={plan}
                            onChange={setPlan}
                            couponCode={isCouponCode ? couponCode : ''}
                            product={product}
                            discountInterval={'first-year'}
                            discountPrice={discountedPriceString}
                            fullInterval={'year'}
                            fullPrice={priceString}
                            variant={windowWidth < TWO_COLUMNS_BREAKPOINT ? 'mini' : 'regular'}
                        />
                    ) : (
                        <PlanSelector
                            plan={plan}
                            price={priceString}
                            trialDays={trialDays}
                            onChange={setPlan}
                            variant={windowWidth < TWO_COLUMNS_BREAKPOINT ? 'mini' : 'regular'}
                        />
                    )}
                </Plans>

                <Form>
                    {subscriptionPurchaseFailed && (
                        <CredentialNotification>
                            {formatMessage('payment-provider-delay-or-error-1')}{' '}
                            {formatMessage('payment-provider-delay-or-error-2')}
                        </CredentialNotification>
                    )}

                    {showCheckoutForm && (
                        <LoginRegisterForm
                            allowSignup
                            onLoggedIn={doSubscribeFlow}
                            isLoading={isPurchasing}
                            title={formatMessage(
                                plan === discountedPlan
                                    ? 'switch-to-signup-headline'
                                    : 'setup-need-details',
                            )}
                            submitButtonText={
                                isTrial
                                    ? formatMessage('start-free-trial-and-subscribe')
                                    : formatMessage('subscribe')
                            }
                        />
                    )}
                </Form>
                <FinePrint data-testid="checkout-footer">
                    {offerHalfOffIntro && (
                        <p>
                            {formatMessage('yearly-discount-fine-print', {
                                discountedPrice: halfOffPriceString,
                                fullPrice: priceString,
                            })}
                        </p>
                    )}
                    {isTrial && (
                        <>
                            <p>
                                {formatMessage('trial-recurring-payments', {
                                    endOfTrialDate: TimeHelper.daysFromNow(trialDays),
                                })}
                            </p>
                            <p>
                                {formatMessage('cancel-trial-any-time')}{' '}
                                {formatMessage('cancel-trial-description')}
                            </p>
                        </>
                    )}
                    <p>{formatMessage('taxes-may-apply')}</p>
                </FinePrint>
            </ContentWrapper>
        </RegisterPageWrapper>
    );
}

export default RegisterPage;
