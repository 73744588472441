import classNames from 'classnames';
import { Icon } from 'components/Icon';
import { RowActions } from 'components/RowActions';
import { isFuture, parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import React, { createRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { AutoSizer, Column, WindowScroller } from 'react-virtualized';
import { withAnalyticsContext } from '../../context/AnalyticsContext';
import * as EpisodeHelper from '../../helper/EpisodeHelper';
import { PlayingStatus } from '../../helper/PlayingStatus';
import { EpisodeSortOrder } from '../../helper/PodcastHelper';
import { getThemeFromId } from '../../model/theme';
import * as fromHistoryActions from '../../redux/actions/history.actions';
import * as fromPlayerActions from '../../redux/actions/player.actions';
import * as fromPodcastActions from '../../redux/actions/podcast.actions';
import * as fromShareActions from '../../redux/actions/share.actions';
import * as fromUpNextActions from '../../redux/actions/up-next.actions';
import { DynamicPlayPauseCircle } from '../DynamicPlayPauseCircle';
import { RowButton } from '../RowButton';
import DateText from '../format/DateText';
import DurationText from '../format/DurationText';
import { EpisodeTableCell } from './EpisodeTableCell';
import { EpisodesTableStyled } from './EpisodesTable.styled';

class EpisodesTable extends React.Component {
    constructor(props) {
        super(props);

        this.tableRef = createRef();
        this.state = {
            hoverIndex: -1,
        };
    }

    render() {
        const { color, episodes, sortEnabled, showSort, sortOrder } = this.props;

        let sortBy;
        let sortDirection;

        if (!sortEnabled) {
            sortBy = '';
            sortDirection = null;
        } else if (sortOrder === 0 || sortOrder === 1) {
            sortBy = 'title';
            sortDirection = sortOrder === 0 ? 'ASC' : 'DESC';
        } else if (sortOrder === 4 || sortOrder === 5) {
            sortBy = 'duration';
            sortDirection = sortOrder === 5 ? 'ASC' : 'DESC';
        } else {
            sortBy = 'published';
            sortDirection = sortOrder === 2 ? 'ASC' : 'DESC';
        }

        const sortColumn = showSort ? sortBy : '';

        const rowGetter = ({ index }) => (episodes ? episodes[index] : 0);

        return (
            <WindowScroller scrollElement={window.contentElement}>
                {({ height, isScrolling, scrollTop }) => (
                    <AutoSizer disableHeight>
                        {({ width }) => (
                            <EpisodesTableStyled
                                ref={this.tableRef}
                                autoHeight
                                disableHeader={true}
                                headerHeight={36}
                                height={height || 0}
                                overscanRowCount={5}
                                isScrolling={isScrolling}
                                scrollTop={scrollTop}
                                rowClassName={this.rowClassNames}
                                containerStyle={{}}
                                rowHeight={72}
                                rowGetter={rowGetter}
                                onRowMouseOver={this.onRowMouseOver}
                                onRowMouseOut={this.onRowMouseOut}
                                onRowClick={this.onRowClick}
                                noRowsRenderer={this.noPodcastsRenderer}
                                rowCount={episodes ? episodes.length : 0}
                                sort={this.sort}
                                sortBy={sortColumn}
                                sortDirection={sortDirection}
                                width={width}
                                color={color}
                            >
                                <Column
                                    label={<FormattedMessage id="table-episode" />}
                                    dataKey="title"
                                    headerClassName={classNames({ sorted: sortColumn === 'title' })}
                                    disableSort={!sortEnabled}
                                    cellRenderer={this.renderColumnEpisode}
                                    flexGrow={8}
                                    width={300}
                                />
                                <Column
                                    label={<FormattedMessage id="table-released" />}
                                    dataKey="published"
                                    headerClassName={classNames({
                                        sorted: sortColumn === 'published',
                                    })}
                                    className="published"
                                    disableSort={!sortEnabled}
                                    width={110}
                                    cellRenderer={this.renderColumnPublished}
                                />
                                <Column
                                    label={<FormattedMessage id="table-time-left" />}
                                    dataKey="duration"
                                    headerClassName={classNames({
                                        sorted: sortColumn === 'duration',
                                    })}
                                    className="duration"
                                    disableSort={!sortEnabled}
                                    width={80}
                                    cellRenderer={this.renderColumnLength}
                                />
                                <Column
                                    dataKey="uuid"
                                    className="actions"
                                    disableSort={true}
                                    width={0}
                                    cellRenderer={this.renderColumnActions}
                                />
                            </EpisodesTableStyled>
                        )}
                    </AutoSizer>
                )}
            </WindowScroller>
        );
    }

    sort = ({ sortBy, sortDirection }) => {
        if (!this.props.sortEnabled) {
            return;
        }
        this.setState({ sortBy, sortDirection });
        if (this.props.onSort) {
            let orderId;
            if (sortBy === 'title') {
                orderId =
                    sortDirection === 'ASC'
                        ? EpisodeSortOrder.NAME_A_TO_Z
                        : EpisodeSortOrder.NAME_Z_TO_A;
            } else if (sortBy === 'published') {
                orderId =
                    sortDirection === 'ASC'
                        ? EpisodeSortOrder.OLDEST_TO_NEWEST
                        : EpisodeSortOrder.NEWEST_TO_OLDEST;
            } else if (sortBy === 'duration') {
                orderId =
                    sortDirection === 'ASC'
                        ? EpisodeSortOrder.LENGTH_ASC
                        : EpisodeSortOrder.LENGTH_DESC;
            }
            this.props.onSort(orderId);
        }
    };

    rowClassNames = ({ index }) => {
        const episode = this.props.episodes ? this.props.episodes[index] : null;

        const episodeSync = this.props.uuidToEpisodeSync
            ? (episode && this.props.uuidToEpisodeSync[episode.uuid]) ||
              EpisodeHelper.getDefaultEpisodeSync()
            : episode;

        return classNames('row', {
            hover: this.state.hoverIndex === index,
            played: episodeSync && episodeSync.playingStatus === PlayingStatus.COMPLETED,
            archived: episodeSync && episodeSync.isDeleted,
            clickable: index !== -1 || (index === -1 && this.props.sortEnabled),
        });
    };

    onRowMouseOver = ({ index }) => {
        this.setState({ hoverIndex: index });
    };

    onRowMouseOut = ({ event, index }) => {
        if (this.state.hoverIndex === index) {
            this.setState({ hoverIndex: -1 });
        }

        /*
        Clicking on a row "action" button leaves the button focused, and when an action is focused
        they will stay visible for keyboard navigation purposes.
        The code below workarounds that issue by forcing a blur event when a hover happens on any
        other row.

        See https://github.com/Automattic/pocket-casts-webplayer/pull/2407 for more details.
         */
        if (event.target.parentElement.contains(document.activeElement)) {
            document.activeElement.blur();
        }
    };

    noPodcastsRenderer = () => <div className="no-episodes">{this.props.emptyMessage}</div>;

    renderColumnEpisode = ({ rowData, rowIndex }) => {
        const { color, upNextEpisodes, showSyncActions, onPodcastClick } = this.props;
        const { hoverIndex } = this.state;

        const episodeUuid = rowData.uuid;
        const isInUpNext = !!upNextEpisodes[episodeUuid];

        if (this.props.podcast) {
            const { uuidToEpisodeSync } = this.props;

            let starred = false;
            if (uuidToEpisodeSync) {
                const episodeSync = uuidToEpisodeSync[rowData.uuid];
                if (episodeSync) {
                    starred = episodeSync.starred;
                }
            }

            return (
                <EpisodeTableCell
                    episode={rowData}
                    color={color}
                    starred={starred}
                    onPodcastClick={() => onPodcastClick({ uuid: rowData.podcastUuid })}
                    isInUpNext={isInUpNext}
                    hover={rowIndex === hoverIndex}
                    onStarClick={() => {
                        starred ? this.onUnstar(rowData) : this.onStar(rowData);
                    }}
                    showSyncActions={showSyncActions}
                />
            );
        }

        return (
            <EpisodeTableCell
                showPodcast={true}
                episode={rowData}
                starred={rowData.starred}
                isInUpNext={isInUpNext}
                onPodcastClick={() => onPodcastClick({ uuid: rowData.podcastUuid })}
                color={color}
                onStarClick={() => {
                    rowData.starred ? this.onUnstar(rowData) : this.onStar(rowData);
                }}
                hover={rowIndex === hoverIndex}
                showSyncActions={showSyncActions}
            />
        );
    };

    renderColumnLength = ({ rowData }) => {
        const episodeSync = this.props.uuidToEpisodeSync
            ? this.props.uuidToEpisodeSync[rowData.uuid] || EpisodeHelper.getDefaultEpisodeSync()
            : rowData;
        return (
            <DurationText
                durationSecs={rowData.duration || episodeSync.duration}
                playedUpToSecs={episodeSync.playedUpTo}
                playingStatus={episodeSync.playingStatus}
            />
        );
    };

    renderColumnPublished = data => {
        const { cellData } = data;
        const displayDate = isFuture(parseISO(cellData)) ? new Date().toUTCString() : cellData;
        return <DateText date={displayDate} showToday={true} />;
    };

    renderColumnActions = ({ rowData }) => {
        const episode = rowData;
        const {
            color,
            theme,
            upNextEpisodes,
            playerEpisodeUuid,
            isPlaying,
            playerPlayedUpTo,
            uuidToEpisodeSync,
            inHistory,
            showSyncActions,
        } = this.props;

        const inUpNext = !!upNextEpisodes[episode.uuid];
        const isPlayerEpisode = playerEpisodeUuid && episode.uuid === playerEpisodeUuid;
        const episodeSync = uuidToEpisodeSync
            ? uuidToEpisodeSync[episode.uuid] || EpisodeHelper.getDefaultEpisodeSync()
            : episode;
        const played = EpisodeHelper.isPlayed(episodeSync);
        const isArchived = episodeSync && episodeSync.isDeleted;
        const podcastUuid = this.getEpisodePodcastUuid(episode);

        const percentageComplete =
            (100 * (isPlayerEpisode ? playerPlayedUpTo : episodeSync.playedUpTo)) /
            (episode.duration || episodeSync.duration || 1);

        return (
            <>
                <RowActions
                    placeholder={
                        showSyncActions && (
                            <div className="archived-status">
                                <Icon id="archive" size={20} />
                                <FormattedMessage id="archived" />
                            </div>
                        )
                    }
                >
                    {/* Given that when the episode is not in upNext we have two actions (play-next and play-last),
                        we need to have a placeholder button to keep the layout consistent when the episode *is* in upNext
                        which only has one action. */}
                    {inUpNext && <RowButton hidden label={'placeholder'} />}

                    <RowButton
                        label="share-episode"
                        icon="share"
                        onClick={() => this.onShare(episode)}
                    />

                    {showSyncActions &&
                        (isArchived ? (
                            <RowButton
                                label="unarchive"
                                icon="unarchive"
                                onClick={() => this.onUnarchiveClick(episode.uuid, podcastUuid)}
                            />
                        ) : (
                            <RowButton
                                label="archive"
                                icon="archive"
                                onClick={() => this.onArchiveClick(episode.uuid, podcastUuid)}
                            />
                        ))}

                    {showSyncActions &&
                        (played ? (
                            <RowButton
                                icon="mark-as-unplayed"
                                label="mark-as-unplayed"
                                onClick={this.onMarkAsUnplayedClick.bind(
                                    this,
                                    episode.uuid,
                                    podcastUuid,
                                )}
                            />
                        ) : (
                            <RowButton
                                icon="mark-as-played"
                                label="mark-as-played"
                                onClick={this.onMarkAsPlayedClick.bind(
                                    this,
                                    episode.uuid,
                                    podcastUuid,
                                )}
                            />
                        ))}
                    {!inUpNext && (
                        <>
                            <RowButton
                                icon="play-next"
                                label="play-next"
                                onClick={this.onPlayNextClick.bind(this, episode)}
                            />
                            <RowButton
                                icon="play-last"
                                label="play-last"
                                onClick={this.onPlayLastClick.bind(this, episode)}
                            />
                        </>
                    )}
                    {inUpNext && (
                        <RowButton
                            icon="remove-from-upnext"
                            label="remove-up-next"
                            onClick={this.onRemoveFromUpNext.bind(this, episode)}
                        />
                    )}
                    {inHistory && (
                        <RowButton
                            icon="cancel"
                            label="remove-history"
                            onClick={this.onRemoveFromHistory.bind(this, episode)}
                        />
                    )}
                </RowActions>

                <DynamicPlayPauseCircle
                    size={28}
                    color={color}
                    playedColor={getThemeFromId(theme).legacyScss['theme-button-disabled']}
                    isPlaying={isPlayerEpisode && isPlaying}
                    isPlayed={episodeSync.playingStatus === PlayingStatus.COMPLETED}
                    percent={percentageComplete}
                    onPlayClick={this.onPlayClick.bind(this, episode)}
                    onPauseClick={this.onPauseClick.bind(this)}
                />
            </>
        );
    };

    onMarkAsPlayedClick = (episodeUuid, podcastUuid) => {
        this.props.markAsPlayed(episodeUuid, podcastUuid);
        this.props.onMarkAsPlayed && this.props.onMarkAsPlayed(episodeUuid, podcastUuid);
    };

    onMarkAsUnplayedClick = (episodeUuid, podcastUuid) => {
        this.props.markAsUnplayed(episodeUuid, podcastUuid);
    };

    onArchiveClick = (episodeUuid, podcastUuid) => {
        this.props.archive(episodeUuid, podcastUuid);
    };

    onUnarchiveClick = (episodeUuid, podcastUuid) => {
        this.props.unarchive(episodeUuid, podcastUuid);
    };

    onRemoveFromUpNext(episode) {
        this.props.removeFromUpNext(episode.uuid);
    }

    onRemoveFromHistory(episode) {
        this.props.deleteHistory(episode);
    }

    onRowClick = data => {
        const { rowData } = data;
        const episodeUuid = rowData.uuid;
        if (this.props.uuidToEpisodeSync) {
            const episodeSync = this.props.uuidToEpisodeSync[episodeUuid];
            const episode = EpisodeHelper.combineEpisodeWithSync(rowData, episodeSync);
            this.props.onEpisodeClick(episode);
        } else {
            this.props.onEpisodeClick(rowData);
        }
    };

    onUpNextAction(episode, podcastUuid) {
        const { uuidToEpisodeSync } = this.props;

        const isArchived =
            (uuidToEpisodeSync &&
                uuidToEpisodeSync[episode.uuid] &&
                uuidToEpisodeSync[episode.uuid].isDeleted) ||
            episode.isDeleted;

        const isPlayed =
            (uuidToEpisodeSync &&
                uuidToEpisodeSync[episode.uuid] &&
                uuidToEpisodeSync[episode.uuid].playingStatus === PlayingStatus.COMPLETED) ||
            episode.playingStatus === PlayingStatus.COMPLETED;

        if (isArchived) {
            this.props.unarchive(episode.uuid, podcastUuid);
        }

        if (isPlayed) {
            this.props.markAsUnplayed(episode.uuid, podcastUuid);
        }
    }

    onPlayNextClick(episode) {
        const podcastUuid = this.getEpisodePodcastUuid(episode);

        this.onUpNextAction(episode, podcastUuid);
        this.props.upNextPlayNext(podcastUuid, episode);
    }

    onPlayLastClick(episode) {
        const podcastUuid = this.getEpisodePodcastUuid(episode);

        this.onUpNextAction(episode, podcastUuid);
        this.props.upNextPlayLast(podcastUuid, episode);
    }

    onStar(episode) {
        const podcastUuid = this.getEpisodePodcastUuid(episode);
        this.props.starEpisode(episode.uuid, podcastUuid, true);
    }

    onUnstar(episode) {
        const podcastUuid = this.getEpisodePodcastUuid(episode);
        this.props.starEpisode(episode.uuid, podcastUuid, false);
    }

    onShare(episode) {
        const podcastUuid = this.getEpisodePodcastUuid(episode);
        this.props.openEpisodeShare(
            episode.uuid,
            podcastUuid,
            episode.title,
            episode.duration,
            episode.url,
        );
    }

    onPlayClick(episode) {
        const { uuidToEpisodeSync } = this.props;

        const isArchived =
            (uuidToEpisodeSync &&
                uuidToEpisodeSync[episode.uuid] &&
                uuidToEpisodeSync[episode.uuid].isDeleted) ||
            episode.isDeleted;

        const podcastUuid = this.getEpisodePodcastUuid(episode);

        if (isArchived) {
            this.props.unarchive(episode.uuid, podcastUuid);
        }

        this.props.discoverEpisodePlayEvent(podcastUuid);
        this.props.playEpisode(episode.uuid, podcastUuid);
    }

    getEpisodePodcastUuid(episode) {
        return this.props.podcast ? this.props.podcast.uuid : episode.podcastUuid;
    }

    onPauseClick() {
        this.props.pause();
    }
}

EpisodesTable.propTypes = {
    onSort: PropTypes.func,
    onEpisodeClick: PropTypes.func,
    onMarkAsPlayed: PropTypes.func,
    color: PropTypes.string,
    hoverColor: PropTypes.string,
    emptyMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    episodes: PropTypes.array.isRequired,
    uuidToEpisodeSync: PropTypes.object,
    uuidToPodcast: PropTypes.any,
    podcast: PropTypes.object,
    playerEpisodeUuid: PropTypes.string,
    playerPlayedUpTo: PropTypes.number,
    isPlaying: PropTypes.bool,
    upNextEpisodes: PropTypes.object,
    sortEnabled: PropTypes.bool,
    showSort: PropTypes.bool,
    inHistory: PropTypes.bool,
    sortOrder: PropTypes.number,
    showsArchived: PropTypes.bool.isRequired,
    showCompleted: PropTypes.bool,
    showSyncActions: PropTypes.bool,
    eventSource: PropTypes.oneOf([
        'filters',
        'listening_history',
        'podcast_screen',
        'starred',
        'search_list',
    ]),
    onPodcastClick: PropTypes.func,
};

EpisodesTable.defaultProps = {
    color: '#03a9f4',
    hoverColor: '#03a9f4',
    emptyMessage: '',
    sortEnabled: true,
    showSort: true,
    inHistory: false,
    sortOrder: -1,
    showCompleted: true,
    showSyncActions: true,
    onPodcastClick: () => {},
};

const mapStateToProps = (state, ownProps) => {
    const { episodes, showCompleted, showsArchived } = ownProps;

    let tableEpisodes = episodes;
    if (!showCompleted && !showsArchived) {
        tableEpisodes = episodes.filter(
            ep => !ep.isDeleted && ep.playingStatus !== PlayingStatus.COMPLETED,
        );
    } else if (!showCompleted) {
        tableEpisodes = episodes.filter(ep => ep.playingStatus !== PlayingStatus.COMPLETED);
    } else if (!showsArchived) {
        tableEpisodes = episodes.filter(ep => !ep.isDeleted);
    }

    return {
        theme: state.settings.theme,
        episodes: tableEpisodes,
    };
};

const mapDispatchToProps = (dispatch, { eventSource, autoplay }) => ({
    markAsPlayed: (episodeUuid, podcastUuid) =>
        dispatch(
            fromPodcastActions.Actions.markAsPlayed(episodeUuid, podcastUuid, {
                eventSource,
            }),
        ),
    markAsUnplayed: (episodeUuid, podcastUuid) =>
        dispatch(
            fromPodcastActions.Actions.markAsUnplayed(episodeUuid, podcastUuid, {
                eventSource,
            }),
        ),
    archive: (episodeUuid, podcastUuid) =>
        dispatch(
            fromPodcastActions.Actions.archive(episodeUuid, podcastUuid, {
                eventSource,
            }),
        ),
    unarchive: (episodeUuid, podcastUuid) =>
        dispatch(
            fromPodcastActions.Actions.unarchive(episodeUuid, podcastUuid, {
                eventSource,
            }),
        ),
    upNextPlayNext: (podcastUuid, episode) =>
        dispatch(
            fromUpNextActions.Actions.upNextPlayNext(podcastUuid, episode, {
                eventSource,
            }),
        ),
    upNextPlayLast: (podcastUuid, episode) =>
        dispatch(
            fromUpNextActions.Actions.upNextPlayLast(podcastUuid, episode, {
                eventSource,
            }),
        ),
    removeFromUpNext: episodeUuid =>
        dispatch(
            fromUpNextActions.Actions.removeFromUpNext(episodeUuid, {
                eventSource,
            }),
        ),
    playEpisode: (episodeUuid, podcastUuid) =>
        dispatch(
            fromPlayerActions.Actions.playEpisode(
                episodeUuid,
                podcastUuid,
                {
                    eventSource,
                },
                { autoplay },
            ),
        ),
    pause: () => dispatch(fromPlayerActions.Actions.pause({ eventSource })),
    starEpisode: (episodeUuid, podcastUuid, starred) =>
        dispatch(
            fromPodcastActions.Actions.starEpisode(episodeUuid, podcastUuid, starred, {
                eventSource,
            }),
        ),
    openEpisodeShare: (episodeUuid, podcastUuid, episodeTitle, episodeDuration, audioUrl) =>
        dispatch(
            fromShareActions.Actions.openEpisodeShare(
                episodeUuid,
                podcastUuid,
                episodeTitle,
                episodeDuration,
                audioUrl,
                {
                    eventSource,
                },
            ),
        ),
    deleteHistory: episode => dispatch(fromHistoryActions.Actions.deleteHistory(episode)),
});

export default withAnalyticsContext(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(EpisodesTable)),
);
