import { getPodcastTint } from 'model/theme';
import { TracksProperties } from 'model/types';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import * as fromFlagsActions from 'redux/actions/flags.actions';
import * as fromShareActions from 'redux/actions/share.actions';
import * as fromTracksActions from 'redux/actions/tracks.actions';
import { RootState } from 'redux/reducers';
import SharePopup from './SharePopup';

const mapStateToProps = (state: RootState) => {
    const { share } = state;
    const sharePodcast = share?.podcastUuid
        ? state.podcasts.uuidToPodcast[share.podcastUuid]
        : null;
    const shareColors = share?.podcastUuid ? state.podcasts.uuidToColors[share.podcastUuid] : null;
    const shareColor =
        (shareColors && getPodcastTint(shareColors, state.settings.theme)) || '#03a9f4';
    const isPlaying = state.player.episode && state.player.episode.uuid === share.episodeUuid;

    return {
        podcastUuid: share.podcastUuid || '',
        episodeUuid: share.episodeUuid || '',
        podcastTitle: sharePodcast ? sharePodcast.title : '',
        podcastAuthor: sharePodcast ? sharePodcast.author : '',
        episodeTitle: share.episodeTitle || '',
        url: share.url || '',
        audioUrl: share.audioUrl || '',
        shareTime: share.currentTime || undefined,
        color: shareColor,
        loading: (share && share.loading) || true,
        isPlaying,
        duration: share.episodeDuration || 0,
        playedUpTo: state.player.episode ? state.player.episode.playedUpTo : 0,
        eventSource: share.eventSource || '',
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    closeShare: () => dispatch(fromShareActions.Actions.closeShare()),
    recordEvent: (event: string, properties: TracksProperties) =>
        dispatch(fromTracksActions.Actions.recordEvent(event, properties)),
    addFlag: (message: string) => dispatch(fromFlagsActions.Actions.addFlag(message)),
});

const SharePopupConnected = connect(mapStateToProps, mapDispatchToProps)(SharePopup);
export { SharePopupConnected as SharePopup };
