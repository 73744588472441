import { Button as ButtonComponent } from 'components/Button';
import { isWindowsApp } from 'helper/Browser';
import styled, { css } from 'styled-components';
import { AllCategoriesMenu } from './AllCategoriesMenu';

const buttonStyles = css`
    font-size: 14.4px;
    font-weight: 500;
    justify-content: left;
    align-items: center;
    gap: 8px;
    background: ${props => props.theme.legacyScss['theme-background']};
    border: 1px solid ${props => props.theme.tokens['primary-field-03']};
    color: ${props => props.theme.tokens['primary-text-01']};
    border-radius: 48px;
    padding: 8px 20px;
    height: 36px;
    line-height: 36px;

    &:hover {
        background: ${props => props.theme.legacyScss['theme-background-hover']};
        border: 1px solid ${props => props.theme.tokens['primary-field-03']};
        box-shadow: none;
    }
`;

export const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
    margin-bottom: 32px;
    flex-direction: row;
    gap: 8px;
    ${isWindowsApp() &&
        css`
            & > *:not(:last-child) {
                margin-right: 8px;
            }
        `}

    .all-categories-menu {
        width: fit-content;

        ${isWindowsApp() &&
            css`
                display: table;
                white-space: nowrap;
            `}

        button {
            ${buttonStyles}
        }

        svg {
            ${isWindowsApp() &&
                css`
                    margin-left: 8px;
                `}

            stroke: ${props => props.theme.tokens['primary-text-01']};
        }
    }
`;

export const StyledAllCategoriesMenu = styled(AllCategoriesMenu)`
    .all-categories-menu-item {
        margin-right: 24px;
        margin-left: 16px;
        border-bottom: 0;

        svg {
            left: 4px;
        }

        .option-title {
            padding: 0 25px 0 38px;
        }
    }
`;

export const Button = styled(ButtonComponent)`
    ${buttonStyles}
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 8px 20px;
`;

export const ClearSelection = styled.div`
    width: 34px;
    height: 34px;

    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    border: 1px solid ${props => props.theme.tokens['primary-icon-02']};
    border-radius: 48px;

    &:hover {
        background: ${props => props.theme.legacyScss['theme-background-hover']};
    }

    ${isWindowsApp() &&
        css`
            margin-right: 8px;
        `}
`;

export const SelectedWrapper = styled.div`
    display: flex;
    gap: 8px;
    margin-top: 16px;
    margin-bottom: 32px;
`;

export const SelectedChip = styled.div`
    display: flex;
    align-items: center;
    padding: 8px 20px;
    border-radius: 48px;
    background: ${props => props.theme.tokens['primary-icon-01']};
    color: ${props => props.theme.tokens['primary-ui-01']};
    font-size: 14.4px;
    font-weight: 500;
    line-height: 36px;
    height: 36px;
`;
