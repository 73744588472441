import { oAuthLoginPath } from 'App';
import { Icon, IconId } from 'components/Icon';
import {
    canDispatchToMacApp,
    canDispatchToWindowsApp,
    dispatchToMacApp,
    dispatchToWindowsApp,
    getPlatformName,
    isElectronApp,
    macAppHasCapability,
} from 'helper/Browser';
import useTracks from 'hooks/useTracks';
import { Base64 } from 'js-base64';
import { OAuthLoginConfig, OAuthProvider } from 'model/types';
import { MAC_APP_OAUTH_CALLBACK_URL_SCHEME } from 'pages/OAuthLoginPage/OAuthLoginPage';
import qs from 'query-string';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router';
import { setOAuthLoginConfig } from 'services/auth';
import { API_URL, WEB_PLAYER_URL } from 'settings';
import uuid from 'uuid/v4';
import { LoginButton } from './OAuthLoginButton.styled';

const ProviderSettings: Record<OAuthProvider, { name: string; icon: IconId }> = {
    apple: {
        name: 'Apple',
        icon: 'apple-logo',
    },
    google: {
        name: 'Google',
        icon: 'google-logo',
    },
};

// This is the API endpoint that receives the POST from the provider, and forwards it as a GET back to the web player
const getOAuthRedirectURL = (provider: OAuthProvider) => `${API_URL}/user/callback_${provider}`;

// This is the web player URL that receives the GET from the API and actually logs the user in
const oAuthLoginURL = () => WEB_PLAYER_URL + oAuthLoginPath;

/**
 * Return the authorization URL for the given OAuth provider
 */
const getOAuthURL = (config: OAuthLoginConfig) => {
    const { provider, nonce } = config;

    const state = Base64.encode(
        JSON.stringify({
            platform: getPlatformName(),
            // This redirect_uri tells the API POST handler where to forward the GET request
            redirect_uri: oAuthLoginURL(),
        }),
    );

    switch (provider) {
        case 'apple':
            // Ref: https://developer.apple.com/documentation/sign_in_with_apple/sign_in_with_apple_js/incorporating_sign_in_with_apple_into_other_platforms
            return qs.stringifyUrl({
                url: 'https://appleid.apple.com/auth/authorize',
                query: {
                    client_id: process.env.APPLE_AUTH_CLIENT_ID,
                    redirect_uri: getOAuthRedirectURL('apple'),
                    response_type: 'code id_token',
                    response_mode: 'form_post',
                    scope: 'email',
                    nonce,
                    state,
                },
            });

        case 'google':
            // Ref: https://developers.google.com/identity/openid-connect/openid-connect#authenticationuriparameters
            return qs.stringifyUrl({
                url: 'https://accounts.google.com/o/oauth2/v2/auth',
                query: {
                    client_id: process.env.GOOGLE_AUTH_CLIENT_ID,
                    redirect_uri: getOAuthRedirectURL('google'),
                    response_type: 'id_token',
                    response_mode: 'form_post',
                    scope: 'email',
                    nonce,
                    state,
                },
            });
        default:
            return '';
    }
};

export type Props = {
    disabled?: boolean;
    provider: OAuthProvider;
};

const OAuthLoginButton = ({ disabled, provider }: Props) => {
    const location = useLocation();
    const { recordEvent } = useTracks();

    const handleClick = () => {
        recordEvent('sso_started', { source: provider });

        const { redirect } = qs.parse(location.search);

        const config = {
            provider,
            nonce: uuid(),
            source: location.pathname + location.search,
            redirect: typeof redirect === 'string' ? redirect : undefined,
        };

        setOAuthLoginConfig(config);
        const url = getOAuthURL(config);

        if (isElectronApp()) {
            window?.electron?.oauthRequest({
                url,
                redirectUrl: oAuthLoginURL(),
            });
        } else if (canDispatchToMacApp() && macAppHasCapability('nativeOAuth')) {
            // This is the Mac App with native OAuth capability
            dispatchToMacApp('oauthRequest', {
                url,
                callbackURLScheme: MAC_APP_OAUTH_CALLBACK_URL_SCHEME,
            });
        } else if (canDispatchToWindowsApp()) {
            // This is the Windows App, which introduced dispatching at the same time
            // as native OAuth to it will be available
            dispatchToWindowsApp('oauthRequest', {
                url,
                // The Windows App will pass OAuth back to the Web Player once redirectUrl has been reached —
                // so this needs to be the final landing page for OAuth requests in the Web Player, not the
                // API POST handler that we initially route OAuth requests through.
                redirectUrl: oAuthLoginURL(),
            });
        } else {
            // This is either a browser, or a Desktop app without native OAuth.
            // Use redirect OAuth instead.
            window.location.href = url;
        }
    };

    const { icon, name } = ProviderSettings[provider];

    return (
        <LoginButton type="button" kind="secondary" onClick={handleClick} disabled={disabled}>
            <Icon id={icon} />
            <FormattedMessage id="continue-with" values={{ provider: name }} />
        </LoginButton>
    );
};

export default OAuthLoginButton;
