import { CloseButton } from 'components';
import { EpisodeWithImage } from 'model/types';
import { AnimatedPlayButton } from 'pages/LoggedInPageChrome/PlayerControls/AnimatedPlayButton';
import { PlayerBackground } from 'pages/LoggedInPageChrome/PlayerControls/PlayerControls.styled';
import { SeekBar } from 'pages/LoggedInPageChrome/PlayerControls/SeekBar';
import { SkipBackButton } from 'pages/LoggedInPageChrome/PlayerControls/SkipBackButton';
import { SkipForwardButton } from 'pages/LoggedInPageChrome/PlayerControls/SkipForwardButton';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { electronMiniPlayerOpened } from 'redux/actions/electron.actions';
import { STATIC_URL } from 'settings';

import {
    Actions,
    CloseButtonWrapper,
    Controls,
    PlayerImage,
    Wrapper,
} from './MiniPlayerWindow.styled';

type Props = {
    episode: EpisodeWithImage | null;
    imageUrl: string;
    playing: boolean;
    playedUpTo: number;
    colorTint: string;
    play: () => void;
    pause: () => void;
    seekTo: (uuid: string, time: number) => void;
    skipBack: () => void;
    skipForward: () => void;
    closePlayer: () => void;
};

const MiniPlayerWindow = ({
    episode,
    imageUrl,
    playing,
    playedUpTo,
    colorTint,
    play,
    pause,
    seekTo,
    skipBack,
    skipForward,
    closePlayer,
}: Props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(electronMiniPlayerOpened(true));
    }, []);

    const focusMainWindow = useCallback(() => {
        window.electron?.player.focus();
    }, []);

    if (!episode) {
        return null;
    }

    const onUserSeeking = (time: number) => seekTo(episode?.uuid, time);
    const onUserSeeked = (time: number) => seekTo(episode?.uuid, time);

    const backgroundStyles = {
        backgroundImage: `url(${STATIC_URL}/discover/images/400/${episode.podcastUuid}.jpg)`,
    };

    return (
        <Wrapper>
            <PlayerBackground>
                <div className="player-gradient" style={backgroundStyles} />
            </PlayerBackground>

            <button onClick={focusMainWindow}>
                <PlayerImage src={imageUrl} />
            </button>

            <Controls>
                <Actions>
                    <SkipBackButton onSkipBackClicked={skipBack} />
                    <AnimatedPlayButton
                        playing={playing}
                        onPlayClicked={play}
                        onPauseClicked={pause}
                    />
                    <SkipForwardButton onSkipForwardClicked={skipForward} />
                </Actions>

                <SeekBar
                    color={colorTint}
                    buffering={false}
                    chapters={[]}
                    playedUpTo={playedUpTo}
                    duration={episode.duration ? episode.duration : 0}
                    onUserSeeking={time => onUserSeeking(time)}
                    onUserSeeked={time => onUserSeeked(time)}
                />
            </Controls>

            <CloseButtonWrapper>
                <CloseButton onClick={closePlayer} />
            </CloseButtonWrapper>
        </Wrapper>
    );
};

export default MiniPlayerWindow;
