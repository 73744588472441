import { PodcastImage } from 'components';
import { useAnalyticsContext } from 'context/AnalyticsContext';
import { NavigationItems } from 'helper/NavigationHelper';
import useFormatMessage from 'hooks/useFormatMessage';
import useTracks from 'hooks/useTracks';
import { PodcastDiscover } from 'model/types';
import React, { useRef } from 'react';
import { InView } from 'react-intersection-observer';
import { useHistory } from 'react-router';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { DiscoverCarouselWrapper, SlickGlobalStyle } from './DiscoverCarousel.styled';
import DiscoverCarouselCard from './DiscoverCarouselCard';

type Props = {
    podcasts: PodcastDiscover[];
    mode?: string;
    onPodcastClick: (podcast: PodcastDiscover) => void;
    onSubscribe: (podcast: PodcastDiscover) => void;
};

const DiscoverCarousel = ({ podcasts = [], mode, onPodcastClick, onSubscribe }: Props) => {
    const { recordEvent } = useTracks();
    const formatMessage = useFormatMessage();
    const podcastsToDisplay = podcasts.slice(0, 7);
    const impressedListIds = useRef<string[]>([]);
    const sliderRef = useRef<Slider>(null);
    const history = useHistory();

    const settings = {
        customPaging: (i: number) => {
            const podcast = podcasts[i];
            return (
                <button aria-label={podcast.title}>
                    <PodcastImage uuid={podcast.uuid} borderRadius={2} />
                </button>
            );
        },
        afterChange: (i: number) => {
            recordEvent('discover_featured_page_changed', {
                current_page: i + 1,
                total_pages: podcastsToDisplay.length,
            });
            markImpression(podcastsToDisplay[i]);
        },
        onInit: () => {
            markImpression(podcastsToDisplay[0]);
        },
        autoplay: true,
        autoplaySpeed: 10000,
        dots: true,
        dotsClass: 'slick-dots slick-thumb',
        infinite: true,
        pauseOnDotsHover: true,
        pauseOnFocus: true,
        pauseOnHover: true,
        speed: 500,
        slidesToShow: mode === 'wide' ? 2 : 1,
        slidesToScroll: 1,
    };

    const {
        discoverListImpressionEvent,
        discoverPodcastSubscribeEvent,
        discoverPodcastTapEvent,
    } = useAnalyticsContext();

    const markImpression = (podcast: PodcastDiscover) => {
        const { sponsoredListId } = podcast;
        if (sponsoredListId && !impressedListIds.current.includes(sponsoredListId)) {
            discoverListImpressionEvent(sponsoredListId);
            impressedListIds.current.push(sponsoredListId);
        }
    };

    const startStopCarousel = (inView: boolean) => {
        if (inView) {
            sliderRef.current?.slickPlay();
        } else {
            sliderRef.current?.slickPause();
        }
    };

    const handlePodcastClick = (podcast: PodcastDiscover) => {
        recordEvent('discover_featured_podcast_tapped', { uuid: podcast.uuid });
        discoverPodcastTapEvent(podcast.sponsoredListId, podcast.uuid);
        history.push(`${NavigationItems.DISCOVER.path}/podcast/${podcast.uuid}`, {
            curatedListId: podcast.sponsoredListId,
        });
        onPodcastClick(podcast);
    };

    const handleSubscribe = (podcast: PodcastDiscover) => {
        discoverPodcastSubscribeEvent(podcast.sponsoredListId, podcast.uuid);
        onSubscribe(podcast);
    };

    return (
        <DiscoverCarouselWrapper aria-label={formatMessage('featured-all-caps')}>
            <SlickGlobalStyle />
            {podcasts.length > 0 && (
                <InView onChange={startStopCarousel} threshold={0.8}>
                    <Slider {...settings} ref={sliderRef}>
                        {podcastsToDisplay.map(podcast => (
                            <DiscoverCarouselCard
                                podcast={podcast}
                                key={podcast.uuid}
                                onPodcastClick={handlePodcastClick}
                                onSubscribe={handleSubscribe}
                            />
                        ))}
                    </Slider>
                </InView>
            )}
        </DiscoverCarouselWrapper>
    );
};

export default DiscoverCarousel;
