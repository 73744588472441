import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NextEpisodePredictionOnDayOrDateMessage } from '../messages';

const getTomorrowDate = () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow;
};

const isSameDay = (dateOne: Date, dateTwo: Date) =>
    dateOne.getDate() === dateTwo.getDate() &&
    dateOne.getMonth() === dateTwo.getMonth() &&
    dateOne.getFullYear() === dateTwo.getFullYear();

const minusDays = (date: Date, number: number) => new Date(date.getTime() - number * 86400000);

const addDays = (date: Date, number: number) => new Date(date.getTime() + number * 86400000);

type Props = {
    estimatedNextEpisodeAt: string;
    episodeFrequency: string;
    className?: string;
};

function NextEpisodeText({ estimatedNextEpisodeAt, episodeFrequency }: Props) {
    const podcastIsOnBreakOrFinished = () => {
        const expectedDate = new Date(estimatedNextEpisodeAt);
        const today = new Date();

        return expectedDate < minusDays(today, 7);
    };

    const getEstimatedText = () => {
        const expectedDate = new Date(estimatedNextEpisodeAt);
        const today = new Date();
        const tomorrow = getTomorrowDate();

        if (podcastIsOnBreakOrFinished()) {
            return <FormattedMessage id="episode-prediction-finished" />;
        }

        if (isSameDay(expectedDate, today)) {
            return <FormattedMessage id="episode-prediction-today" />;
        }
        if (isSameDay(expectedDate, tomorrow)) {
            return <FormattedMessage id="episode-prediction-tomorrow" />;
        }
        if (expectedDate < today && expectedDate >= minusDays(today, 7)) {
            return <FormattedMessage id="episode-prediction-any-day-now" />;
        }
        if (expectedDate < addDays(today, 7)) {
            return <NextEpisodePredictionOnDayOrDateMessage date={expectedDate} dayOnly={true} />;
        }

        return <NextEpisodePredictionOnDayOrDateMessage date={expectedDate} dayOnly={false} />;
    };

    const getFrequencyText = () => {
        if (podcastIsOnBreakOrFinished()) {
            return null;
        }

        if (episodeFrequency === 'Hourly') {
            return <FormattedMessage id="podcast-frequency-hourly" />;
        }

        if (episodeFrequency === 'Daily') {
            return <FormattedMessage id="podcast-frequency-daily" />;
        }

        if (episodeFrequency === 'Weekly') {
            return <FormattedMessage id="podcast-frequency-weekly" />;
        }

        if (episodeFrequency === 'Fortnightly') {
            return <FormattedMessage id="podcast-frequency-fortnightly" />;
        }

        if (episodeFrequency === 'Monthly') {
            return <FormattedMessage id="podcast-frequency-monthly" />;
        }

        return null;
    };

    if (!estimatedNextEpisodeAt) {
        return null;
    }

    const frequencyMessage = getFrequencyText();
    const text = getEstimatedText();

    if (text) {
        return (
            <>
                {frequencyMessage} {text}
            </>
        );
    }

    return null;
}

export default NextEpisodeText;
