import styled from 'styled-components';
import { gridSize, H80 } from '../../../../../../../styles';

export const UploadErrorTooltipWrapper = styled.div`
    position: absolute;
    display: flex;

    flex-direction: column;
    align-items: center;

    right: -1px;
    ${props => (props.isFirefox || props.isEdge) && 'margin-top: -115px;'}
    ${props => !props.isFirefox && !props.isEdge && 'margin-top: -58px;'}
    z-index: 10;
`;

export const TooltipTriangle = styled.div`
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid ${props => props.theme.legacyScss['theme-text-primary']};

    margin-right: ${props => props.triangleOffset}px;
    top: 20px;
`;

export const TooltipBodyWrapper = styled.div`
    display: flex;
    width: ${props => props.width}px;
    height: ${10 * gridSize}px;

    align-items: center;
    flex-direction: column;
    justify-content: center;

    border-radius: 4px;
    background-color: ${props => props.theme.legacyScss['theme-text-primary']};
`;

export const FileName = styled(H80)`
    color: ${props => props.theme.legacyScss['theme-background']};
    margin: 4px ${gridSize}px;
    opacity: 0.6;
    text-align: center;

    max-width: ${props => props.maxWidth}px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const ErrorMessage = styled(H80)`
    color: ${props => props.theme.legacyScss['theme-background']};
    margin: 4px ${gridSize}px;
    text-align: center;
`;
