import Gravatar from 'components/Gravatar';
import { Navigation } from 'components/Navigation';
import { LOCAL_STORAGE_KEY_EMAIL } from 'model/local-storage';
import { isDarkTheme } from 'model/theme';
import React from 'react';
import { FocusOn } from 'react-focus-on';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import { SimpleAccountIcon } from './DynamicAccountIcon/SimpleAccountIcon';
import { ProfileMenu } from './ProfileMenu';
import { SearchBar } from './SearchBar';
import {
    NavBarWrapper,
    ProfileIconWrapper,
    ProfileWrapper,
    RoundedGravatar,
    SearchWrapper,
} from './SecondaryNav.styled';

const SecondaryNav = () => {
    const [showProfileMenu, setShowProfileMenu] = React.useState(false);
    const email = localStorage.getItem(LOCAL_STORAGE_KEY_EMAIL);

    const { theme } = useSelector((state: RootState) => ({
        theme: state.settings.theme,
    }));

    const Outline = () => (
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M 23.999999999999996 1.5 A 22.5 22.5 0 1 0 24.003926990797055 1.5000003426945945"
            stroke={isDarkTheme(theme) ? '#5F6568' : '#B8C3C9'}
            fill="none"
            strokeWidth="3"
        ></path>
    );

    return (
        <NavBarWrapper>
            <Navigation />
            <SearchWrapper>
                <SearchBar />
            </SearchWrapper>
            <FocusOn
                enabled={showProfileMenu}
                scrollLock={false}
                noIsolation
                onClickOutside={() => setShowProfileMenu(false)}
                onEscapeKey={() => setShowProfileMenu(false)}
            >
                <ProfileWrapper>
                    <ProfileIconWrapper
                        aria-label="Open Profile Menu"
                        role="button"
                        onClick={() => setShowProfileMenu(true)}
                    >
                        <RoundedGravatar width="50" height="50" viewBox="0 0 48 48" fill="none">
                            <Gravatar
                                asSvgImage
                                email={email || ''}
                                fallback={<SimpleAccountIcon asSvgImage width={48} height={48} />}
                                size={48}
                            ></Gravatar>
                            <Outline />
                        </RoundedGravatar>
                    </ProfileIconWrapper>
                    {showProfileMenu && (
                        <ProfileMenu onNavigate={() => setShowProfileMenu(false)} />
                    )}
                </ProfileWrapper>
            </FocusOn>
        </NavBarWrapper>
    );
};

export default SecondaryNav;
