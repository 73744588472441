import { Icon } from 'components/Icon';
import { isMacApp, isWindowsApp } from 'helper/Browser';
import useFormatMessage from 'hooks/useFormatMessage';
import useTracks from 'hooks/useTracks';
import SpeedControl from 'pages/LoggedInPageChrome/PlayerControls/SpeedControl';
import React, { useCallback, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { LoaderSquare } from '../../components';
import { Switch } from '../../components/form/Switch';
import { NavigationItems } from '../../helper/NavigationHelper';
import * as fromPodcastActions from '../../redux/actions/podcast.actions';
import { RootState } from '../../redux/reducers';
import { MinutesSecondsInputField } from './MinutesSecondsInputField';
import {
    EffectSettingWrapper,
    MinutesSettingsText,
    MissingEffectsMessage,
    PageHeader,
    PodcastSettingsPageWrapper,
    RowTitleContainer,
    SettingsRow,
    SettingsRowDescription,
    SettingsRowError,
    SettingsRowName,
    SettingsSection,
    SettingsSwitchWrapper,
    SpeedSettingsRow,
    SwitchWrapper,
} from './PodcastSettingsPage.styled';
import { useTimedErrorMessage } from './useTimedErrorMessage';

type Props = {
    match: {
        params: {
            uuid: string;
        };
    };
};

function PodcastSettingsPage({ match }: Props) {
    const formatMessage = useFormatMessage();
    const history = useHistory();
    const dispatch = useDispatch();
    const { recordEvent } = useTracks();
    const autoStartFromError = useTimedErrorMessage(
        'Skip First is limited to a maximum of 40 minutes.',
    );
    const autoSkipLastError = useTimedErrorMessage(
        'Skip Last is limited to a maximum of 40 minutes.',
    );

    const speedControlRef = useRef(null);

    const podcastUuid = match.params.uuid;
    const {
        podcast,
        isLoaded,
        loadFailed,
        isSubscribed,
        playbackSpeed,
        playbackEffects,
        autoArchive,
        autoArchivePlayed,
        autoStartFrom,
        autoSkipLast,
    } = useSelector((state: RootState) => ({
        podcast: state.podcasts.uuidToPodcast[podcastUuid],
        loadFailed: state.podcasts.loadFailed || state.podcast.loadFailed,
        isSubscribed: state.podcasts.subscribedUuids.includes(podcastUuid),
        isLoaded: state.podcasts.isLoaded && state.podcasts.uuidToPodcast[podcastUuid] != null,
        playbackSpeed:
            state.podcasts.uuidToPodcast[podcastUuid]?.settings?.playbackSpeed?.value ||
            state.settings.playbackSpeed,
        playbackEffects:
            state.podcasts.uuidToPodcast[podcastUuid]?.settings?.playbackEffects?.value || false,
        autoArchive: state.podcasts.uuidToPodcast[podcastUuid]?.settings?.autoArchive?.changed
            ? state.podcasts.uuidToPodcast[podcastUuid]?.settings?.autoArchive?.value
            : false,
        autoArchivePlayed: state.podcasts.uuidToPodcast[podcastUuid]?.settings?.autoArchivePlayed
            ?.changed
            ? Boolean(state.podcasts.uuidToPodcast[podcastUuid]?.settings?.autoArchivePlayed?.value)
            : Boolean(state.settings.autoArchivePlayed),
        autoStartFrom: state.podcasts.uuidToPodcast[podcastUuid]?.autoStartFrom,
        autoSkipLast: state.podcasts.uuidToPodcast[podcastUuid]?.autoSkipLast,
    }));

    const skipFirstUpdated = (value: number) => {
        dispatch(fromPodcastActions.Actions.updateAutoStartFrom(podcastUuid, value));
        recordEvent('podcast_settings_skip_first_changed', { value });
    };

    const skipLastUpdated = (value: number) => {
        dispatch(fromPodcastActions.Actions.updateAutoSkipLast(podcastUuid, value));
        recordEvent('podcast_settings_skip_last_changed', { value });
    };

    const onSpeedChanged = useCallback(
        (speed: number) => {
            if (!playbackEffects) {
                dispatch(fromPodcastActions.Actions.updatePlaybackEffects(podcastUuid, true));
            }
            dispatch(fromPodcastActions.Actions.updatePlaybackSpeed(podcastUuid, speed));
        },
        [dispatch, podcastUuid, playbackEffects],
    );

    const toggleAutoArchive = useCallback(() => {
        if (!autoArchive) {
            dispatch(fromPodcastActions.Actions.updateAutoArchive(podcastUuid, true));
        }
        dispatch(
            fromPodcastActions.Actions.updateAutoArchivePlayed(podcastUuid, !autoArchivePlayed),
        );
    }, [dispatch, podcastUuid, autoArchive, autoArchivePlayed]);

    useEffect(() => {
        if (!podcast) {
            dispatch(fromPodcastActions.Actions.openPodcast(podcastUuid));
        }
    }, []);

    useEffect(() => {
        if (loadFailed || (isLoaded && !isSubscribed)) {
            history.push(`${NavigationItems.PODCASTS.path}/${podcastUuid}`);
        }
    }, [loadFailed, isLoaded, isSubscribed]);

    const isDesktopApp = isWindowsApp() || isMacApp();

    return (
        <PodcastSettingsPageWrapper>
            <Helmet>
                <title>{formatMessage('podcast-settings')}</title>
            </Helmet>
            {!isLoaded && <LoaderSquare />}
            {isLoaded && (
                <>
                    <PageHeader>
                        <h1>{podcast.title}</h1>
                    </PageHeader>

                    <SettingsSection>
                        <h2>{formatMessage('podcast-settings')}</h2>

                        <SettingsRow>
                            <RowTitleContainer>
                                <Icon id="skip-first" />
                                <SettingsRowName>{formatMessage('skip-first')}</SettingsRowName>
                            </RowTitleContainer>
                            <MinutesSettingsText>
                                <MinutesSecondsInputField
                                    initialValueInSecs={autoStartFrom || 0}
                                    maximumValue={2400}
                                    onSave={skipFirstUpdated}
                                    onExceededMaxValue={() => {
                                        autoStartFromError.trigger();
                                    }}
                                />
                            </MinutesSettingsText>
                            {autoStartFromError.hasError ? (
                                <SettingsRowError>{autoStartFromError.message}</SettingsRowError>
                            ) : (
                                <SettingsRowDescription>
                                    {formatMessage('podcast-settings-skip-first')}
                                </SettingsRowDescription>
                            )}
                        </SettingsRow>
                        <SettingsRow>
                            <RowTitleContainer>
                                <Icon id="skip-last" />
                                <SettingsRowName>{formatMessage('skip-last')}</SettingsRowName>
                            </RowTitleContainer>

                            <MinutesSettingsText>
                                <MinutesSecondsInputField
                                    initialValueInSecs={autoSkipLast || 0}
                                    maximumValue={2400}
                                    onSave={skipLastUpdated}
                                    onExceededMaxValue={() => {
                                        autoSkipLastError.trigger();
                                    }}
                                />
                            </MinutesSettingsText>
                            {autoSkipLastError.hasError ? (
                                <SettingsRowError>{autoSkipLastError.message}</SettingsRowError>
                            ) : (
                                <SettingsRowDescription>
                                    {formatMessage('podcast-settings-skip-last')}
                                </SettingsRowDescription>
                            )}
                        </SettingsRow>
                        <SettingsRow>
                            <RowTitleContainer>
                                <Icon id="archive" />
                                <SettingsRowName>
                                    {formatMessage('settings-archiving')}
                                </SettingsRowName>
                            </RowTitleContainer>

                            <SettingsSwitchWrapper>
                                <Switch on={autoArchivePlayed} onChange={toggleAutoArchive} />
                            </SettingsSwitchWrapper>
                            <SettingsRowDescription>
                                {formatMessage('settings-archiving-per-podcast-desc')}
                            </SettingsRowDescription>
                        </SettingsRow>
                    </SettingsSection>

                    <SettingsSection>
                        <h2>{formatMessage('playback-effects')}</h2>
                        <SpeedSettingsRow>
                            <RowTitleContainer>
                                <Icon id="playback-speed" />
                                <SettingsRowName>{formatMessage('playback-speed')}</SettingsRowName>
                            </RowTitleContainer>

                            <EffectSettingWrapper>
                                <SwitchWrapper>
                                    <SpeedControl
                                        ref={speedControlRef}
                                        speed={playbackSpeed}
                                        onSpeedChanged={onSpeedChanged}
                                        variant="large"
                                    />
                                </SwitchWrapper>
                                <SettingsRowDescription>
                                    {formatMessage('podcast-settings-playback-speed')}
                                </SettingsRowDescription>
                            </EffectSettingWrapper>
                        </SpeedSettingsRow>
                        <MissingEffectsMessage>
                            {isDesktopApp
                                ? formatMessage('missing-playback-effects-desktop')
                                : formatMessage('missing-playback-effects-web')}
                        </MissingEffectsMessage>
                    </SettingsSection>
                </>
            )}
        </PodcastSettingsPageWrapper>
    );
}

export default PodcastSettingsPage;
