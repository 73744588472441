import { isElectronApp, isMac, isMacApp } from 'helper/Browser';
import styled, { css } from 'styled-components';
import { gridSize } from 'styles';
import { PLAYER_HEIGHT, VIEWPORT_MINIMUM_WIDTH } from '../../model/page';

export const LayoutFooter = styled.div`
    flex-shrink: 0;
    height: ${PLAYER_HEIGHT}px !important;

    ${props => props.blurred && 'filter: blur(5px);'}
`;

export const LayoutWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    // Fix for mobile Safari: https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/
    height: -webkit-fill-available;
    width: 100vw;
    /* min-width: ${VIEWPORT_MINIMUM_WIDTH}px; */
`;

export const Layout = styled.div`
    flex: 1 1 auto;
    min-height: 0;
    display: flex;
    flex-direction: row;
    position: relative;

    // This is to prevent parts of the application to be hidden behind the invisible title bar
    // of the native Mac wrapper.
    ${isMacApp() &&
    css`
        padding-top: 14px;
    `}

    ${isElectronApp() &&
    isMac() &&
    css`
        padding-top: 28px;
    `}

    ${props => props.blurred && 'filter: blur(5px);'}
`;

export const Content = styled.div`
    // This means a scroll bar will always be present on inital load
    // and components' offsetWidth values can depend on that.
    overflow-y: scroll;
    overflow-x: auto;

    flex: 1 1 auto;

    > div {
        margin: 0 auto !important;
        padding: 0px 40px 40px 40px !important;
        max-width: 1300px;
        min-width: 800px;
    }
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    min-width: 0;
`;

export const SecondaryNavWrapper = styled.div`
    width: 100%;
    margin: 0 auto;
    padding: 0 40px;
    max-width: 1300px;
    display: flex;

    ${isMacApp() && `padding-top: ${1.5 * gridSize}px;`}
`;
