import { Icon } from 'components/Icon';
import { EpisodeTypeText } from 'components/format/EpisodeTypeText/';
import { NavigationItems } from 'helper/NavigationHelper';
import PropTypes from 'prop-types';
import React from 'react';
import * as UiHelper from '../../../helper/UiHelper';
import PodcastImage from '../../PodcastImage';
import { StarButton } from '../../buttons/StarButton';
import { PodcastLink } from './EpisodesTableCell.styled';

class EpisodeTableCell extends React.Component {
    render() {
        const { showPodcast } = this.props;
        return showPodcast ? this.renderPodcastEpisode() : this.renderEpisode();
    }

    renderPodcastEpisode() {
        const { episode, hover, color, showPodcast, starred, isInUpNext, showSyncActions } =
            this.props;
        const hoverStyle = hover ? { color } : {};
        return (
            <div className="episode-table-cell">
                {showPodcast && (
                    <PodcastImage
                        className="episode-table-image"
                        title={episode ? episode.podcastTitle : ''}
                        uuid={episode ? episode.podcastUuid : null}
                    />
                )}
                <div className="text">
                    <div className="text-primary" style={hoverStyle}>
                        <span className="title">{episode.title}</span>
                        {showSyncActions && <StarButton on={starred} onClick={this.starClick} />}
                    </div>
                    <div className="secondary-line">
                        {isInUpNext && this.renderUpNextIconSmall()}
                        <PodcastLink
                            to={`${NavigationItems.PODCASTS.path}/${episode.podcastUuid}`}
                            onClick={this.onPodcastClick}
                        >
                            {episode && episode.podcastTitle
                                ? episode.podcastTitle
                                : showPodcast
                                  ? '\u00A0'
                                  : ''}
                        </PodcastLink>
                    </div>
                </div>
            </div>
        );
    }

    starClick = event => {
        UiHelper.stopPropagation(event);
        this.props.onStarClick();
    };

    onPodcastClick = event => {
        event.stopPropagation();
        const { onPodcastClick } = this.props;
        onPodcastClick?.();
    };

    renderEpisode() {
        const { episode, hover, color, starred, isInUpNext } = this.props;
        const { season, number, type } = episode;
        const hoverStyle = hover ? { color } : {};

        return (
            <div className="episode-table-cell no-podcast">
                <div className="text">
                    <div className="text-primary" style={hoverStyle}>
                        {isInUpNext && this.renderUpNextIcon()}
                        <EpisodeTypeText season={season} number={number} type={type} />
                        <span className="title">{episode.title}</span>
                        <StarButton on={starred} onClick={this.starClick} />
                    </div>
                </div>
            </div>
        );
    }

    renderUpNextIcon() {
        return (
            <div className="up-next-icon">
                <Icon id="up-next" aria-label="Up Next" />
            </div>
        );
    }

    renderUpNextIconSmall() {
        return (
            <div className="up-next-icon">
                <Icon id="up-next" size={16} aria-label="Up Next" />
            </div>
        );
    }
}

EpisodeTableCell.propTypes = {
    episode: PropTypes.any.isRequired,
    starred: PropTypes.bool,
    isInUpNext: PropTypes.bool,
    hover: PropTypes.bool,
    color: PropTypes.string,
    showPodcast: PropTypes.bool,
    onStarClick: PropTypes.func,
    showSyncActions: PropTypes.bool,
    onPodcastClick: PropTypes.func,
};

EpisodeTableCell.defaultProps = {
    showPodcast: false,
    isInUpNext: false,
    showSyncActions: true,
};

export default EpisodeTableCell;
