import { css } from 'styled-components';
import { hoverStateTransitionMixin } from './transitions';
import { blue40, blue50 } from './colors';

export const blueTextLinkMixin = css`
    color: ${blue50};
    cursor: pointer;
    text-decoration: none;

    &:hover {
        color: ${blue40};
    }

    ${hoverStateTransitionMixin};
`;
