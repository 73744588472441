import { MutableRefObject, useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router';

/**
 * Hook to manage restoring scroll position for a page
 *
 * @param triggerScroll - A trigger for whether or not to trigger a scroll (ie. if loading page)
 */
export function useScrollRestoration(triggerScroll: boolean) {
    const pageRef = useRef() as MutableRefObject<HTMLElement>;
    const location = useLocation<{ scrollKey: string }>();

    const scrollKey = location?.state?.scrollKey || location?.key || '/';

    const updateScrollPosition = useCallback(position => {
        sessionStorage.setItem(scrollKey, position);
    }, []);

    useEffect(() => {
        const item = sessionStorage.getItem(scrollKey) ?? '0';
        const position = parseInt(item, 10) || 0;
        if (triggerScroll && pageRef && pageRef.current && pageRef.current.parentElement) {
            pageRef.current.parentElement.scrollTop = position;
            updateScrollPosition(0);
        }
    }, [scrollKey, triggerScroll, updateScrollPosition]);

    return {
        pageRef,
        updateScrollPosition: (val?: number) => {
            if (pageRef && pageRef.current && pageRef.current.parentElement) {
                const scrollPosition = val != null ? val : pageRef.current.parentElement.scrollTop;
                updateScrollPosition(scrollPosition);
            }
        },
    };
}
