import * as fromPodcastActions from '../actions/podcast.actions';

export interface PodcastState {
    uuid: string | null;
    loadFailed: boolean;
    isSearching: boolean;
    searchEpisodes: { uuid: string }[];
    searchTerm: string;
}

export const INITIAL_STATE: PodcastState = {
    uuid: null,
    loadFailed: false,
    isSearching: false,
    searchEpisodes: [],
    searchTerm: '',
};

export default (
    state: PodcastState = INITIAL_STATE,
    action: fromPodcastActions.Actions,
): PodcastState => {
    switch (action.type) {
        case fromPodcastActions.ActionTypes.OPEN_PODCAST:
            if (state.uuid !== null && state.uuid === action.payload.uuid) {
                return state;
            }

            return { ...INITIAL_STATE, uuid: action.payload.uuid };
        case fromPodcastActions.ActionTypes.CLOSE_PODCAST:
            return { ...INITIAL_STATE };
        case fromPodcastActions.ActionTypes.SEARCH_EPISODES:
            return { ...state, isSearching: action.payload.term.length > 0 };
        case fromPodcastActions.ActionTypes.SEARCH_EPISODES_SUCCESS:
            return {
                ...state,
                isSearching: false,
                searchEpisodes: action.payload.episodes,
                searchTerm: action.payload.term,
            };
        case fromPodcastActions.ActionTypes.SEARCH_EPISODES_FAILED:
            return { ...state, isSearching: false };
        case fromPodcastActions.ActionTypes.CLEAR_SEARCH_EPISODES:
            return { ...state, isSearching: false, searchTerm: '' };
        case fromPodcastActions.ActionTypes.OPEN_PODCAST_FAILED:
            return { ...state, loadFailed: true };
        default:
            return state;
    }
};
