import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { withRouter, useHistory, RouteComponentProps, Link } from 'react-router-dom';
import qs from 'query-string';
import useFormatMessage from 'hooks/useFormatMessage';
import { BackButton } from 'components/BackButton';
import { Button } from 'components/Button';
import { CredentialNotification } from 'components/form/CredentialNotification';
import { StyledForm, StyledFormError } from 'components/form/form.styled';
import Input from 'components/Input';
import { POCKET_CASTS_SUPPORT } from 'model/external-links';
import { confirmPasswordFieldSchema, useForm, newPasswordFieldSchema } from '../../hooks/useForm';

type Props = {
    requestInProgress: boolean;
    requestSucceeded: boolean;
    responseMessage: string;
    requestResetPassword: (newPassword: string, token: string) => void;
} & RouteComponentProps;

function PasswordResetPage({
    location,
    requestResetPassword,
    requestInProgress,
    requestSucceeded,
    responseMessage,
}: Props) {
    const history = useHistory();
    const formatMessage = useFormatMessage();

    const {
        form,
        form: { fields, isValid, onChange, onFocusChange },
    } = useForm({
        newPasswordField: newPasswordFieldSchema(formatMessage),
        confirmPasswordField: confirmPasswordFieldSchema(formatMessage),
    });

    const onResetPasswordClick = (event: React.MouseEvent) => {
        event.preventDefault();

        const { newPasswordField } = form.getValues();

        const resetPasswordToken = qs.parse(location.search).reset_password_token as string;

        requestResetPassword(newPasswordField, resetPasswordToken);
    };

    useEffect(() => {
        if (requestSucceeded) {
            history.push('/user/login', {
                loginSuccessMessage: formatMessage('password-reset-success'),
            });
        }
    }, [requestSucceeded]);

    const showError = !requestSucceeded && responseMessage;
    const isInvalidTokenError = showError && responseMessage.includes('Token not valid');

    return (
        <StyledForm>
            <Helmet>
                <title>{formatMessage('reset-password')}</title>
            </Helmet>
            <BackButton buttonText={formatMessage('back')} />
            <h1>{formatMessage('reset-password')}</h1>
            {!showError && <p>{formatMessage('please-enter-new-password')}</p>}
            {showError && (
                <CredentialNotification>
                    {isInvalidTokenError && (
                        <>
                            <p>
                                This reset link has expired or you have already used it to change
                                your password.
                            </p>
                            <Link to="/user/forgotten_password">
                                You can reset your password again.
                            </Link>
                        </>
                    )}
                    {!isInvalidTokenError && (
                        <>
                            <p>{formatMessage('password-reset-failure')}</p>
                            <p>{`(Error: ${responseMessage})`}</p>
                            <a
                                target="_blank"
                                href={POCKET_CASTS_SUPPORT}
                                rel="noopener noreferrer"
                            >
                                {formatMessage('if-you-still-have-issues-please-let-us-know')}
                            </a>
                        </>
                    )}
                </CredentialNotification>
            )}

            <label>{fields.newPasswordField.placeholder}</label>
            <Input
                type="password"
                name={fields.newPasswordField.name}
                value={fields.newPasswordField.value}
                status={fields.newPasswordField.state}
                onChange={onChange}
                onFocus={e => onFocusChange(e, true)}
                onBlur={e => onFocusChange(e, false)}
            />
            {!fields.newPasswordField.focus && fields.newPasswordField.error && (
                <StyledFormError>{fields.newPasswordField.error}</StyledFormError>
            )}
            <label>{fields.confirmPasswordField.placeholder}</label>
            <Input
                aria-label="Password Again"
                name={fields.confirmPasswordField.name}
                type={fields.confirmPasswordField.type}
                value={fields.confirmPasswordField.value}
                status={fields.confirmPasswordField.state}
                onChange={onChange}
                onFocus={e => onFocusChange(e, true)}
                onBlur={e => onFocusChange(e, false)}
            />
            {(!fields.confirmPasswordField.focus || !fields.newPasswordField.focus) &&
                fields.confirmPasswordField.error && (
                    <StyledFormError>{fields.confirmPasswordField.error}</StyledFormError>
                )}
            <Button
                type="submit"
                kind="primary"
                className="submit-button"
                data-testid="submit-button"
                onClick={onResetPasswordClick}
                loading={requestInProgress}
                disabled={isValid === false}
            >
                {formatMessage('reset-password')}
            </Button>
        </StyledForm>
    );
}

export default withRouter(PasswordResetPage);
