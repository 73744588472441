import styled from 'styled-components';
import Modal from 'components/Modal';
import { fontFamilyRetinaNarrow } from 'styles';

export const ClearHistoryPopupWrapper = styled(Modal)`
    display: block;
    position: relative;
    width: 480px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.24);
    border-radius: 6px;
    z-index: 20;
    background: ${props => props.theme.legacyScss['theme-popup-background']};
    .dialog-content {
        display: flex;
        flex-direction: column;
        align-items: center;

        margin-top: -20px;

        .icon-wrapper {
            padding-top: 30px;
            color: ${props => props.theme.tokens['primary-icon-01']};
        }

        h2 {
            font-family: ${fontFamilyRetinaNarrow};
            font-size: 24.88px;
            font-weight: 500;

            width: 90%;
            padding: 0 0 0 0;
            margin-bottom: 10px;
            text-align: center;
            color: ${props => props.theme.legacyScss['theme-text-primary']};
        }

        .body-text {
            width: 90%;
            text-align: center;
            font-size: 17.28px;
            font-weight: 400;
            color: ${props => props.theme.legacyScss['theme-text-secondary']};
        }

        .buttons {
            display: flex;
            justify-content: center;
            width: 100%;
            padding: 32px 40px 0 40px;
            margin-bottom: 30px;
        }
    }
`;
