import styled from 'styled-components';
import { gridSize } from 'styles';

export const Form = styled.form`
    max-width: 70vw;
    button {
        display: block;
        width: 100%;
        margin: ${gridSize * 3}px 0 ${gridSize * 4}px;
    }
    svg {
        margin: 0 auto ${gridSize * 3}px;
        color: ${props => props.theme.tokens['support-05']};
    }
`;
