import classNames from 'classnames';
import { ErrorBoundary } from 'components/ErrorBoundary';
import GlobalA11yAnnouncements from 'components/GlobalA11yAnnouncements/GlobalA11yAnnouncements';
import { PodcastPromo, PromoChrome } from 'components/PromoChrome';
import { clearNavigationHistory } from 'customHistory';
import { getRedirectQueryParam } from 'helper/QueryStringHelper';
import * as key from 'keymaster';
import { BookmarksPage } from 'pages/BookmarksPage';
import { DeleteAccountPage } from 'pages/DeleteAccountPage';
import { OAuthLoginPage } from 'pages/OAuthLoginPage';
import { SearchPage } from 'pages/SearchPage';
import { StartPage } from 'pages/StartPage';
import { WelcomePage } from 'pages/WelcomePage';
import qs from 'query-string';
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { Route, Switch, withRouter } from 'react-router-dom';
import { USE_PATRON } from 'settings';
import { ThemeProvider } from 'styled-components';
import { getShortLanguage } from 'translations';
import { OutsideAppAuthenticatorPage } from './OutsideAppAuthenticatorPage';
import { ErrorMessage, SvgDefinitions } from './components/index';
import { AnalyticsContextProvider } from './context/AnalyticsContext';
import { isWindowsApp } from './helper/Browser';
import { NavigationItems as NavItems } from './helper/NavigationHelper';
import { ESCAPE_KEY_PRESSED_EVENT } from './model/page';
import {
    THEME,
    getThemeFromId,
    prefersDarkThemeMediaQuery,
    prefersLightThemeMediaQuery,
} from './model/theme';
import { CategoryPage } from './pages/CategoryPage';
import { ChoosePlanPage } from './pages/ChoosePlanPage';
import DiscoverPage from './pages/DiscoverPage';
import FilterPage from './pages/FilterPage';
import LoggedInPageChrome from './pages/LoggedInPageChrome';
import { LoggedOutPageChrome } from './pages/LoggedOutPageChrome';
import { LoginPage } from './pages/LoginPage';
import { MiniPlayerWindow } from './pages/MiniPlayerWindow';
import { PasswordForgottenPage } from './pages/PasswordForgottenPage';
import { PasswordResetPage } from './pages/PasswordResetPage';
import { PodcastListPage } from './pages/PodcastListPage';
import { PodcastPage } from './pages/PodcastPage';
import { PodcastSettingsPage } from './pages/PodcastSettingsPage';
import PodcastsPage from './pages/PodcastsPage';
import ProfilePage from './pages/ProfilePage';
import { RedeemPromoPage } from './pages/RedeemPromoPage';
import { RegisterPage } from './pages/RegisterPage';
import { SettingsPage } from './pages/SettingsPage';
import { StatsPage } from './pages/StatsPage';
import UploadedFilesPage from './pages/UploadedFilesPage';
import * as fromPodcastsActions from './redux/actions/podcasts.actions';
import * as fromSettingsActions from './redux/actions/settings.actions';
import * as fromSubscriptionActions from './redux/actions/subscription.actions';
import * as fromUserActions from './redux/actions/user.actions';
import { getDisplayedTheme, hasPaidSubscription } from './redux/reducers/selectors';
import { GlobalAppStyles } from './styles';

/**
 * The web player was previously hosted under the /web path. With the TTF work, the web player
 * now exists at the root of its own domain but people may have old links to /web/* URLs.
 * We'll keep redirects around for these for a while until we indicate that old links will die.
 */
const oldWebPlayerPath = '/web';

export const startPath = '/';
export const signInPath = '/user/login';
export const choosePlanPath = '/user/plans';
export const registerPath = '/user/register';
export const oAuthLoginPath = '/oauth_login';

const trialRedeem = '/redeem';

const forgottenPasswordPath = '/user/forgotten_password';
const resetPasswordPath = '/account/reset_password';

// This path is uncharacteristically prefixed with /user, but is unrelated to normal /user paths.
export const episodeSharePath = '/user/share';
export const podcastSharePath = `${NavItems.PODCASTS.path}/share`;

class App extends React.Component {
    loggedInRoutes = () => (
        <>
            {/* Redirect all old Web Player paths prefixed with /web. */}
            <Route
                path={oldWebPlayerPath}
                render={({ location }) => (
                    <Redirect
                        to={{
                            ...location,
                            pathname: location.pathname.replace(oldWebPlayerPath, ''),
                        }}
                    />
                )}
            />

            {/* Podcast share link from pca.st */}
            <Route
                path={podcastSharePath}
                render={() => {
                    const { id } = qs.parse(this.props.location.search, {
                        ignoreQueryPrefix: true,
                    });

                    return <Redirect to={`${NavItems.PODCASTS.path}/${id || ''}`} />;
                }}
            />

            {/* Episode share link from pca.st */}
            <Route
                exact
                path={episodeSharePath}
                render={() => {
                    const { podcast, episode, t } = qs.parse(this.getSanitisedSearch(), {
                        ignoreQueryPrefix: true,
                    });
                    if (podcast && !episode) {
                        return <Redirect to={`${NavItems.PODCASTS.path}/${podcast}`} />;
                    }
                    if (!podcast && !episode) {
                        return <Redirect to={`${NavItems.PODCASTS.path}`} />;
                    }

                    const episodePath = t
                        ? `${NavItems.PODCASTS.path}/${podcast}/${episode}?t=${t}`
                        : `${NavItems.PODCASTS.path}/${podcast}/${episode}`;

                    return <Redirect to={episodePath} />;
                }}
            />

            {/* Basic navigation */}

            <LoggedInPageRoute
                path={`${NavItems.PODCASTS.path}/folders/:folderUuid`}
                Component={PodcastsPage}
            />

            <LoggedInPageRoute
                path={`${NavItems.PODCASTS.path}/:uuid/:episode`}
                Component={PodcastPage}
            />

            {/* This shows up in our server logs. The /index suffix is parsed as an invalid :uuid */}
            <Route
                path={`${NavItems.PODCASTS.path}/index`}
                exact
                render={() => <Redirect to={NavItems.PODCASTS.path} />}
            />

            <LoggedInPageRoute path={`${NavItems.PODCASTS.path}/:uuid`} Component={PodcastPage} />

            <LoggedInPageRoute path={`${NavItems.PODCASTS.path}`} Component={PodcastsPage} />

            <LoggedInPageRoute
                path={`${NavItems.SETTINGS.path}/podcast/:uuid`}
                Component={PodcastSettingsPage}
            />

            <LoggedInPageRoute
                path={`${NavItems.DISCOVER.path}/podcast/:uuid`}
                Component={PodcastPage}
            />

            <LoggedInPageRoute
                path={`${NavItems.DISCOVER.path}/list/:listId`}
                Component={PodcastListPage}
            />

            <LoggedInPageRoute
                path={`${NavItems.DISCOVER.path}/category/:categoryId`}
                Component={CategoryPage}
            />

            <LoggedInPageRoute path={`${NavItems.DISCOVER.path}`} Component={DiscoverPage} />

            <LoggedInPageRoute
                exact
                path={`${NavItems.NEW_RELEASES.path}`}
                render={() => (
                    <FilterPage
                        id="new_releases"
                        eventSource="filters"
                        autoplay={{ source: 'new_releases' }}
                    />
                )}
            />
            <LoggedInPageRoute
                exact
                path={`${NavItems.IN_PROGRESS.path}`}
                render={() => (
                    <FilterPage
                        id="in_progress"
                        eventSource="filters"
                        autoplay={{ source: 'in_progress' }}
                    />
                )}
            />
            <LoggedInPageRoute
                exact
                path={`${NavItems.STARRED.path}`}
                render={() => (
                    <FilterPage
                        id="starred"
                        eventSource="starred"
                        autoplay={{ source: 'starred' }}
                    />
                )}
            />
            <LoggedInPageRoute
                exact
                path={`${NavItems.BOOKMARKS.path}`}
                Component={BookmarksPage}
            />
            <LoggedInPageRoute
                exact
                path={`${NavItems.HISTORY.path}`}
                render={() => <FilterPage id="history" eventSource="listening_history" />}
            />

            <LoggedInPageRoute exact path={`${NavItems.PROFILE.path}`} Component={ProfilePage} />

            <LoggedInPageRoute path={`${NavItems.STATS.path}`} Component={StatsPage} />

            <LoggedInPageRoute
                path={`${NavItems.UPLOADED_FILES.path}`}
                Component={UploadedFilesPage}
            />

            <LoggedInPageRoute path={`${NavItems.SETTINGS.path}`} Component={SettingsPage} />

            <LoggedInPageRoute path="/search" Component={SearchPage} />

            {/* /user paths */}

            {/* This path will be hit when they've just signed up or redeemed a code */}
            <Route
                path={[registerPath, trialRedeem]}
                render={({ location }) => {
                    if (this.props.justSignedUp) {
                        return <Redirect push to={{ ...location, pathname: '/welcome' }} />;
                    }
                    this.props.showModalGeneric('showAlreadyPlusSubscriberModal');
                    return <Redirect push to={getRedirectQueryParam() ?? NavItems.PODCASTS.path} />;
                }}
            />

            {/* User must be logged out to see this page */}
            <Route
                path={forgottenPasswordPath}
                render={() => {
                    this.props.signOut();
                    window.location.reload(false);
                }}
            />

            {/* User must be logged out to see this page */}
            <Route
                path={resetPasswordPath}
                render={() => {
                    this.props.signOut();
                    window.location.reload(false);
                }}
            />

            <Route
                path="/welcome"
                render={() => <WelcomePage redirect={getRedirectQueryParam()} />}
            />

            <Route path="/miniplayer" render={() => <MiniPlayerWindow />} />

            {/* Fallback (with redirect if available) if the user has just logged in */}
            <Route
                render={() => <Redirect to={getRedirectQueryParam() ?? NavItems.PODCASTS.path} />}
            />
        </>
    );

    loggedOutRoutes = () => (
        <>
            <Route
                path={oldWebPlayerPath}
                render={({ location }) => (
                    <Redirect
                        to={{
                            ...location,
                            pathname: location.pathname.replace(oldWebPlayerPath, ''),
                        }}
                    />
                )}
            />

            <LoggedOutPageRoute path={`${trialRedeem}/:id`} Component={RedeemPromoPage} />
            {USE_PATRON && <LoggedOutPageRoute path={choosePlanPath} Component={ChoosePlanPage} />}
            <LoggedOutPageRoute path={registerPath} Component={RegisterPage} />
            <LoggedOutPageRoute
                exact
                path={[startPath, signInPath, forgottenPasswordPath, resetPasswordPath]}
                render={() => {
                    const { episodeUuid, podcastUuid, podcast, colors } =
                        this.props.redirectToPodcast;

                    const customPromo = podcastUuid ? (
                        <PodcastPromo
                            podcastUuid={podcastUuid}
                            podcast={podcast}
                            episodeUuid={episodeUuid}
                            colors={colors}
                        />
                    ) : undefined;

                    return (
                        <PromoChrome promo={customPromo}>
                            <Route
                                exact
                                path={startPath}
                                render={() => (
                                    <StartPage
                                        podcastUuid={podcastUuid}
                                        podcastTitle={podcast?.title}
                                    />
                                )}
                            />
                            <Route path={signInPath} component={LoginPage} />
                            <Route path={forgottenPasswordPath} component={PasswordForgottenPage} />
                            <Route path={resetPasswordPath} component={PasswordResetPage} />
                        </PromoChrome>
                    );
                }}
            />

            <LoggedOutPageRoute path="/token-login" Component={OutsideAppAuthenticatorPage} />

            <Route
                render={() => {
                    const { pathname } = this.props.location;
                    const redirect = encodeURIComponent(pathname + this.getSanitisedSearch());
                    return <Redirect to={`${startPath}?redirect=${redirect}`} />;
                }}
            />
        </>
    );

    componentDidMount() {
        key('escape', this.shortcutEsc);
        if (!isWindowsApp()) {
            this.watchBrowserSystemThemesChanges();
        }
        this.fetchRedirectPodcast();
    }

    componentWillUnmount() {
        key.unbind('escape');
    }

    componentDidUpdate(prevProps) {
        if (this.props.redirectToPodcast.podcastUuid !== prevProps.redirectToPodcast.podcastUuid) {
            this.fetchRedirectPodcast();
        }
        if (prevProps.isSubscriber !== this.props.isSubscriber) {
            // Whenever the user signs in or out, clear the navigation history so back/forward buttons
            // don't accidentally send you to logged-in/out pages you shouldn't access.
            clearNavigationHistory();
        }
    }

    fetchRedirectPodcast() {
        const { podcastUuid } = this.props.redirectToPodcast;
        if (podcastUuid) {
            this.props.downloadPodcast(podcastUuid);
        }
    }

    getSanitisedSearch() {
        // Query params from Share Pages can include improperly-encoded `&amp;`. We need
        // to sanitise this until the problem is fixed at the root.
        // See: https://github.com/Automattic/pocket-casts-refresh-server/issues/320
        const { location } = this.props;
        if (location.pathname === episodeSharePath) {
            return location.search.replace(/&amp;/g, '&');
        }
        return location.search;
    }

    watchBrowserSystemThemesChanges = () => {
        const { theme, updatePreferredColorScheme } = this.props;
        if (window.matchMedia) {
            const onPreferredThemeUpdated = () => {
                if (theme === THEME.system) {
                    if (prefersLightThemeMediaQuery.matches) {
                        updatePreferredColorScheme(THEME.light);
                    } else if (prefersDarkThemeMediaQuery.matches) {
                        updatePreferredColorScheme(THEME.dark);
                    }
                }
            };
            prefersDarkThemeMediaQuery.addListener(onPreferredThemeUpdated);
            prefersLightThemeMediaQuery.addListener(onPreferredThemeUpdated);
        }
    };

    shortcutEsc = () => {
        window.dispatchEvent(new CustomEvent(ESCAPE_KEY_PRESSED_EVENT, {}));
    };

    render() {
        const { language, displayedTheme, isSubscriber, intl } = this.props;

        const styleClasses = classNames('app', `language-${getShortLanguage(language)}`);

        const currentTheme = getThemeFromId(displayedTheme);

        return (
            <ThemeProvider theme={currentTheme}>
                <GlobalAppStyles />
                <GlobalA11yAnnouncements />
                <Helmet titleTemplate="%s - Pocket Casts">
                    <title>{intl.formatMessage({ id: 'modal-web-player' })}</title>
                    <meta
                        name="theme-color"
                        content={currentTheme.name === 'dark' ? '#303337' : '#F7F9FA'}
                    />
                </Helmet>
                <div className={styleClasses}>
                    <SvgDefinitions />
                    <AnalyticsContextProvider>
                        <ErrorBoundary logError fallback={<ErrorMessage />}>
                            <FragmentSupportingSwitch>
                                {/* Routes that do not require subscription check to render can be directly added here */}
                                <LoggedOutPageRoute
                                    path={oAuthLoginPath}
                                    Component={OAuthLoginPage}
                                />
                                <Route
                                    path="/user/delete_account"
                                    render={() => <DeleteAccountPage />}
                                />
                                {isSubscriber ? this.loggedInRoutes() : this.loggedOutRoutes()}
                            </FragmentSupportingSwitch>
                        </ErrorBoundary>
                    </AnalyticsContextProvider>
                </div>
            </ThemeProvider>
        );
    }
}

function FragmentSupportingSwitch({ children }) {
    const flattenedChildren = [];
    flatten(flattenedChildren, children);

    return React.createElement.apply(React, [Switch, null].concat(flattenedChildren));
}

function flatten(target, children) {
    React.Children.forEach(children, child => {
        if (React.isValidElement(child)) {
            if (child.type === Fragment) {
                flatten(target, child.props.children);
            } else {
                target.push(child);
            }
        }
    });
}

/**
 * Default authenticated layout with the sidebar.
 * Note: React **does not** unncessarily rerender LoggedInPageChrome
 */
function LoggedInPageRoute({ path, exact = false, Component, render }) {
    return (
        <Route
            path={path}
            exact={exact}
            render={props => (
                <LoggedInPageChrome>
                    {render ? render(props) : <Component {...props} />}
                </LoggedInPageChrome>
            )}
        />
    );
}

function LoggedOutPageRoute({ path, exact = false, Component, render }) {
    return (
        <Route
            path={path}
            exact={exact}
            render={props => (
                <LoggedOutPageChrome>
                    {render ? render(props) : <Component {...props} />}
                </LoggedOutPageChrome>
            )}
        />
    );
}

export const getPodcastFromRedirect = url => {
    const {
        query: { redirect },
    } = qs.parseUrl(url);

    if (!redirect) {
        return {};
    }

    const parsedRedirect = qs.parseUrl(redirect);

    if (parsedRedirect.url === podcastSharePath) {
        return { podcastUuid: parsedRedirect.query.id };
    }

    if (parsedRedirect.url === episodeSharePath) {
        return {
            podcastUuid: parsedRedirect.query.podcast,
            episodeUuid: parsedRedirect.query.episode,
        };
    }

    // Matches:
    // - /podcasts/{podcastUUID}
    // - /podcasts/{podcastUUID}
    // - /discover/podcast/{podcastUUID}/{episodeUUID}
    // - /discover/podcast/{podcastUUID}/{episodeUUID}
    const matches = parsedRedirect.url.match(
        /^(\/podcasts|\/discover\/podcast)\/([a-zA-Z0-9-]+)\/?([a-zA-Z0-9-]+)?$/,
    );

    if (matches) {
        return {
            podcastUuid: matches[2],
            episodeUuid: matches[3],
        };
    }

    return {};
};

const mapStateToProps = (state, ownProps) => {
    const { podcastUuid, episodeUuid } = getPodcastFromRedirect(ownProps.location.search);

    return {
        language: state.settings.language,
        theme: state.settings.theme,
        displayedTheme: getDisplayedTheme(state.settings.theme),
        isSubscriber: hasPaidSubscription(state),
        justSignedUp: state.user.justSignedUp,
        redirectToPodcast: {
            podcastUuid,
            episodeUuid,
            podcast: state.podcasts.uuidToPodcast[podcastUuid],
            colors: state.podcasts.uuidToColors[podcastUuid],
        },
    };
};

const mapDispatchToProps = {
    downloadPodcast: fromPodcastsActions.Actions.downloadPodcast,
    signOut: fromUserActions.Actions.signOut,
    showModalGeneric: fromSubscriptionActions.Actions.showModalGeneric,
    updatePreferredColorScheme: fromSettingsActions.Actions.updatePreferredColorScheme,
};

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(App)));
