import { PopupMenu } from 'components/popup/PopupMenu';
import { Props as PopupMenuProps } from 'components/popup/PopupMenu/PopupMenu';
import React from 'react';

const AllCategoriesMenu = ({
    className,
    children,
    ...rest
}: PopupMenuProps & { className?: string }) => {
    // This allows us to wrap the PopupMenu in a styled component and pass the class name all the way down.
    // Otherwise there is no way to target the inner portal class name.
    return (
        <PopupMenu {...rest} popupClassName={className}>
            {children}
        </PopupMenu>
    );
};

export default AllCategoriesMenu;
