import styled from 'styled-components';

export const VolumeKnob = styled.div`
    position: absolute;
    top: -5px;
    opacity: 0;
    height: 13px;
    width: 13px;
    border-radius: 7.5px;
    background-color: white;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.5);
    ${({ size }) => `left: ${size - 6}px`};
    ${({ seeking }) =>
        seeking &&
        `
            opacity: 1;
        `}
    ${({ muted }) => muted === true && `opacity: 0`}
`;

export const VolumeBarFill = styled.div`
    position: absolute;
    opacity: 1;
    height: 3px;
    background-color: white;
    border-radius: 1.5px;
    ${({ size }) => `width: ${size}px`};
`;

export const VolumeBar = styled.div`
    display: block;
    width: 55px;
    height: 3px;
    border-radius: 1.5px;
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute;
    left: 36px;
    top: 15px;
`;

export const VolumeBarTouch = styled.div`
    position: absolute;
    left: 30px;
    top: 0;
    display: block;
    width: 67px;
    height: 34px;
    cursor: pointer;
`;

export const MuteButton = styled.button`
    display: inline-block;
    position: absolute;
    width: 34px;
    height: 34px;
    top: 0;
    left: 0;
    cursor: pointer;
    background: no-repeat;

    ${({ muted }) =>
        muted &&
        `
        background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2234%22%20height%3D%2234%22%20viewBox%3D%220%200%2034%2034%22%3E%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Cpath%20fill%3D%22%23FFF%22%20d%3D%22M7%2013.997c0-.55.443-.997.999-.997H10v8H7.999C7.447%2021%207%2020.547%207%2020.003v-6.006zM10%2013l4.174-2.922c.456-.32.826-.13.826.43v12.985c0%20.556-.36.755-.826.429L10%2021v-8z%22%2F%3E%3Cpath%20stroke%3D%22%23FFF%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20stroke-width%3D%222%22%20d%3D%22M19%2014l6%206m0-6l-6%206%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E');
    `}

    ${({ muted, position }) =>
        !muted &&
        position <= 0 &&
        `
        background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2234%22%20height%3D%2234%22%20viewBox%3D%220%200%2034%2034%22%3E%3Cpath%20fill%3D%22%23FFF%22%20fill-rule%3D%22evenodd%22%20d%3D%22M7%2013.997c0-.55.443-.997.999-.997H10v8H7.999C7.447%2021%207%2020.547%207%2020.003v-6.006zM10%2013l4.174-2.922c.456-.32.826-.13.826.43v12.985c0%20.556-.36.755-.826.429L10%2021v-8z%22%2F%3E%3C%2Fsvg%3E');
    `}

    ${({ muted, position }) =>
        !muted &&
        position > 0 &&
        position < 0.5 &&
        `
        background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2234%22%20height%3D%2234%22%20viewBox%3D%220%200%2034%2034%22%3E%3Cpath%20fill%3D%22%23FFF%22%20fill-rule%3D%22evenodd%22%20d%3D%22M7%2013.997c0-.55.443-.997.999-.997H10v8H7.999C7.447%2021%207%2020.547%207%2020.003v-6.006zM10%2013l4.174-2.922c.456-.32.826-.13.826.43v12.985c0%20.556-.36.755-.826.429L10%2021v-8zm7.793.707c-.39-.39-.39-1.024%200-1.414.39-.39%201.024-.39%201.414%200%20.25.25.59.684.92%201.29.546%201%20.873%202.145.873%203.417%200%201.272-.327%202.418-.872%203.416-.331.607-.67%201.04-.92%201.291-.391.39-1.025.39-1.415%200-.39-.39-.39-1.024%200-1.414.124-.124.348-.41.58-.834.392-.72.627-1.543.627-2.459%200-.916-.235-1.739-.628-2.459-.231-.424-.455-.71-.58-.834z%22%2F%3E%3C%2Fsvg%3E');
    `}


    ${({ muted, position }) =>
        !muted &&
        position >= 0.5 &&
        `
        background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2234%22%20height%3D%2234%22%20viewBox%3D%220%200%2034%2034%22%3E%3Cpath%20fill%3D%22%23FFF%22%20fill-rule%3D%22evenodd%22%20d%3D%22M7%2013.997c0-.55.443-.997.999-.997H10v8H7.999C7.447%2021%207%2020.547%207%2020.003v-6.006zM10%2013l4.174-2.922c.456-.32.826-.13.826.43v12.985c0%20.556-.36.755-.826.429L10%2021v-8zm12.707%2011.707c-.39.39-1.024.39-1.414%200-.39-.39-.39-1.024%200-1.414.071-.071.213-.227.402-.457.322-.394.646-.847.948-1.35C23.493%2020.066%2024%2018.548%2024%2017c0-1.548-.506-3.067-1.357-4.486-.302-.503-.626-.956-.948-1.35-.19-.23-.33-.386-.402-.457-.39-.39-.39-1.024%200-1.414.39-.39%201.024-.39%201.414%200%20.429.429%201.041%201.177%201.65%202.193C25.381%2013.192%2026%2015.048%2026%2017c0%201.952-.619%203.808-1.643%205.514-.609%201.016-1.221%201.764-1.65%202.193zm-4.914-11c-.39-.39-.39-1.024%200-1.414.39-.39%201.024-.39%201.414%200%20.25.25.59.684.92%201.29.546%201%20.873%202.145.873%203.417%200%201.272-.327%202.418-.872%203.416-.331.607-.67%201.04-.92%201.291-.391.39-1.025.39-1.415%200-.39-.39-.39-1.024%200-1.414.124-.124.348-.41.58-.834.392-.72.627-1.543.627-2.459%200-.916-.235-1.739-.628-2.459-.231-.424-.455-.71-.58-.834z%22%2F%3E%3C%2Fsvg%3E');
    `}
`;

export const VolumeSliderContainer = styled.div`
    width: 107px;
    height: 34px;
    position: relative;
    margin-left: -6px;
    margin-right: -16px;

    &:hover,
    &:focus,
    &:focus-within {
        ${VolumeKnob} {
            opacity: 1;
        }
    }

    ${({ muted }) =>
        muted === true &&
        `
        ${VolumeBarTouch} {
            cursor: default;
        }
        ${VolumeBarFill} {
            opacity: 0;
        }
    `}
`;
