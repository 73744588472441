import styled from 'styled-components';
import { B70, gridSize } from '../../../../../styles';

const tooltipHeight = 5 * gridSize;

export const FileEditModalTooltipWrapper = styled.div`
    position: absolute;
    ${props => (props.top || props.top === 0) && `top: ${props.top}px;`}
    ${props => (props.left || props.left === 0) && `left: ${props.left}px;`}
    ${props => (props.right || props.right === 0) && `right: ${props.right}px;`}
    ${props => (props.bottom || props.bottom === 0) && `bottom: ${props.bottom}px;`}

    display: flex;
    flex-direction: column;
    align-items: center;

    z-index: 10;

    @keyframes fade-in {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    animation: fade-in 0.1s ease;
`;

export const TooltipTriangle = styled.div`
    position: relative;

    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid ${props => props.theme.legacyScss['theme-text-primary']};
`;

export const TooltipBodyWrapper = styled.div`
    background-color: transparent;
    width: 171px;
    height: ${tooltipHeight}px;
    display: flex;
    justify-content: center;
`;

export const TooltipBody = styled.div`
    display: flex;
    height: ${tooltipHeight}px;
    width: 100%;

    align-items: center;
    justify-content: center;

    border-radius: 4px;
    background-color: ${props => props.theme.legacyScss['theme-text-primary']};
`;

export const TooltipText = styled(B70)`
    color: ${props => props.theme.legacyScss['theme-background']};
    text-align: center;
`;
