import styled from 'styled-components';
import { Typography } from '../../../styles';

export const UpNextEmptyMessage = styled.div`
    text-align: center;
    ${Typography.Desktop.H60};
    color: #686c74;
    width: 480px;
    padding: 70px;
    background: ${props => props.theme.tokens['primary-ui-01']};
    border: 2px solid ${props => props.theme.tokens['primary-ui-05']};
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    h2 {
        color: ${props => props.theme.tokens['primary-text-01']};
        ${Typography.Desktop.H30};
        padding: 20px 0;
    }
    p {
        margin: 0;
    }
    svg {
        color: ${props => props.theme.tokens['primary-icon-01']};
    }
`;
