import React from 'react';
import classNames from 'classnames';
import { Icon } from 'components/Icon';
import { LoaderRoundWrapper } from './LoaderRound.styled';

type Props = {
    visible?: boolean;
    size?: number;
};

export function LoaderRound({ visible = true, size = 18 }: Props) {
    const classes = classNames('loader-round', { visible });

    return (
        <LoaderRoundWrapper $size={size} className={classes}>
            <Icon id="in-progress" />
        </LoaderRoundWrapper>
    );
}
