import styled from 'styled-components';

export const VideoCollapseButtonWrapper = styled.button`
    width: 65px;
    height: 65px;
    cursor: pointer;
    position: absolute;
    opacity: 0;
    .frame {
        width: 65px;
        height: 65px;
        background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2222%22%20height%3D%2218%22%20viewBox%3D%220%200%2022%2018%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ctitle%3Evideo%2Ficonframe%3C%2Ftitle%3E%3Cpath%20d%3D%22M21%2011v5c0%20.552-.455%201-1%201H2c-.552%200-1-.447-1-.999V2C1%201.447%201.456%201%201.995%201H9%22%20stroke%3D%22%23FFF%22%20stroke-width%3D%222%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3C%2Fsvg%3E');
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        .arrow {
            height: 11px;
            width: 11px;
            transform: rotate(180deg);
            position: absolute;
            top: 19px;
            right: 18px;
            background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2211%22%20height%3D%2211%22%20viewBox%3D%220%200%2011%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ctitle%3Evideo%2Ficonarrow%3C%2Ftitle%3E%3Cg%20stroke-width%3D%222%22%20stroke%3D%22%23FFF%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%20stroke-linecap%3D%22round%22%3E%3Cpath%20d%3D%22M10%208V1H3M9%202L1.5%209.5%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E');
        }
    }
    &:hover {
        opacity: 1;
        .frame {
            .arrow {
                @media (prefers-reduced-motion: no-preference) {
                    animation: video-collapse 1 400ms ease-in-out;
                    animation-direction: alternate;
                }
            }
        }
    }
    @keyframes video-collapse {
        0% {
            transform: translate(0px, 0px) rotate(180deg);
        }
        50% {
            transform: translate(-2px, 2px) rotate(180deg);
        }
        100% {
            transform: translate(0px, 0px) rotate(180deg);
        }
    }
`;
