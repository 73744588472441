import { DiscoverCategory } from 'model/types';
import React from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';
import { Icon, getCategoryByLabel, getToken } from '../../../helper/CategoriesHelper';
import {
    CategoryButton,
    CategoryItemTitle,
    CategoryItemWrapper,
    CategoryListItem,
    DiscoverCategoriesWrapper,
    SectionTitle,
} from './DiscoverCategories.styled';

type Props = {
    categories: DiscoverCategory[];
    title: string;
    onCategoryClicked: (id: number) => void;
};

function DiscoverCategories({ categories, title, onCategoryClicked }: Props) {
    const openCategory = (id: number) => {
        onCategoryClicked(id);
    };

    const theme = useTheme();
    const intl = useIntl();

    const getName = (label: string): string => {
        const formatted = getCategoryByLabel(label)?.formattedLabel;
        return formatted ? intl.formatMessage({ id: formatted.props.id }) : label;
    };

    return (
        <DiscoverCategoriesWrapper>
            <SectionTitle>{title}</SectionTitle>
            <CategoryItemWrapper aria-label={title}>
                {categories.map(category => (
                    <CategoryListItem key={category.id} tabIndex={-1}>
                        <CategoryButton
                            onClick={() => openCategory(category.id)}
                            token={theme.tokens[getToken(category.id)]}
                            tabIndex={0}
                            aria-label={getName(category.name)}
                        >
                            <Icon aria-hidden="true" id={category.id} />
                            <CategoryItemTitle aria-hidden="true">
                                {getName(category.name)}
                            </CategoryItemTitle>
                        </CategoryButton>
                    </CategoryListItem>
                ))}
            </CategoryItemWrapper>
        </DiscoverCategoriesWrapper>
    );
}

export default DiscoverCategories;
