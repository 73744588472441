import React, { Children } from 'react';

type Props = {
    minWidth: number;
    onColumnCountCalc: (count: number) => void;
    gap?: number | string;
};

type StateProps = {
    gridWidth: number;
    columnCount: number;
};

class ResponsiveGrid extends React.Component<Props, StateProps> {
    gridRef: React.MutableRefObject<HTMLDivElement>;

    static defaultProps = {
        gap: 8,
    };

    constructor(props: Props) {
        super(props);
        this.gridRef = React.createRef() as React.MutableRefObject<HTMLDivElement>;
        this.state = {
            gridWidth: 0,
            columnCount: -1,
        };
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    render() {
        const { columnCount } = this.state;
        const { gap } = this.props;

        const rowCount =
            columnCount > 0 ? Math.ceil(Children.count(this.props.children) / columnCount) : 0;

        const style = {
            gridTemplateColumns: `repeat(${columnCount}, minmax(0, 1fr))`,
            gridTemplateRows: `repeat(${rowCount}, auto)`,
            gap,
        };

        return (
            <div className="responsive-grid" style={style} ref={this.gridRef}>
                {this.props.children}
            </div>
        );
    }

    handleResize() {
        if (!this.gridRef?.current) {
            return;
        }
        const gridWidth = this.gridRef.current.offsetWidth;
        const columnCount = +Number(gridWidth / this.props.minWidth).toFixed();

        // report the column change to parent, useful if the page needs more data from the server
        if (this.props.onColumnCountCalc && columnCount !== this.state.columnCount) {
            this.props.onColumnCountCalc(columnCount);
        }

        this.setState({
            gridWidth,
            columnCount,
        });
    }
}

export { ResponsiveGrid };
