import React from 'react';
import classNames from 'classnames';
import { Icon } from 'components/Icon';
import { StarButtonWrapper } from './StarButton.styled';

type Props = {
    on?: boolean;
    onClick: () => void;
};

function StarButton({ on = false, onClick = () => ({}) }: Props) {
    const classes = classNames('star-button', { on });
    return (
        <StarButtonWrapper className={classes} onClick={onClick}>
            <Icon id="star-full" size={16} />
        </StarButtonWrapper>
    );
}

export default StarButton;
