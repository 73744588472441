import styled from 'styled-components';
import { fontFamilyRetina } from '../../../styles';

export const ContentRegionSelectorWrapper = styled.section`
    margin-top: 39px;
    border-top: 1px solid ${props => props.theme.tokens['primary-ui-05']};

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding-top: 40px;
`;

// TODO: Use mixin
export const ContentRegionHeading = styled.h3`
    font-family: ${fontFamilyRetina};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 144%;

    color: ${props => props.theme.tokens['primary-text-02']};
`;

export const SelectInputCard = styled.button`
    display: flex;
    flex-direction: row;
    align-items: center;

    min-width: 148px;
    height: 56px;
    border: 2px solid ${props => props.theme.tokens['primary-ui-05']};
    box-sizing: border-box;
    border-radius: 40px;

    user-select: none;
    cursor: pointer;
`;

export const CountryFlag = styled.img`
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background-color: ${props => props.theme.tokens['primary-ui-05']};
    margin: 0px 12px;
`;
export const CountryTitle = styled.div`
    font-family: ${fontFamilyRetina};
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 144%;
    margin-right: 12px;
`;
