import isEqual from 'lodash/isEqual';
import { registerMiddleware } from 'redux/api';
import { Logger } from 'helper/Logger';
import { getAutoplayConfig, isAutoplayEnabled } from 'redux/reducers/selectors/autoplay.selectors';
import * as fromFilterActions from 'redux/actions/filter.actions';
import * as fromPlayerActions from 'redux/actions/player.actions';
import * as fromPodcastsActions from 'redux/actions/podcasts.actions';
import * as fromTracksActions from 'redux/actions/tracks.actions';
import * as fromUploadedFilesActions from 'redux/actions/uploaded-files.actions';
import * as fromUpNextActions from 'redux/actions/up-next.actions';
import * as fromUserActions from 'redux/actions/user.actions';
import {
    clearAutoplay,
    refreshAutoplayEpisodes,
    startAutoplay,
} from 'redux/actions/autoplay.actions';
import { getQueuedEpisodeUuids } from 'redux/reducers/selectors';

registerMiddleware(fromPlayerActions.ActionTypes.PLAY_EPISODE, (action, store) => {
    const state = store.getState();
    const config = getAutoplayConfig(state);
    const newConfig = action.payload.options?.autoplay ?? null;

    if (!isAutoplayEnabled(state)) {
        // Autoplay is off — don't set a config
        if (newConfig) {
            Logger.log(
                `Can't autoplay — the setting is disabled. Played episode from: ${JSON.stringify(
                    newConfig,
                )}`,
            );
        }
        return;
    }

    const queuedUpNextUUIDs = getQueuedEpisodeUuids(state);
    if (queuedUpNextUUIDs.length > 0) {
        // Up Next is not empty — Autoplay is essentially disabled
        if (newConfig) {
            Logger.log(
                `Can't autoplay — Up Next is not empty. Played episode from: ${JSON.stringify(
                    newConfig,
                )}`,
            );
        }
        return;
    }

    if (isEqual(config, newConfig)) {
        // This play has the same config — ignore!
        return;
    }

    if (newConfig === null) {
        // New config is null, which turns off autoplay
        store.dispatch(clearAutoplay());
        Logger.log(
            `Autoplay stopped, episode played from non-autoplay source (${
                action.payload.tracksProperties.eventSource
            }). Config: ${JSON.stringify(config)}`,
            true,
        );
    } else {
        // This is a new autoplay source — set it!
        store.dispatch(startAutoplay(newConfig));
        Logger.log(
            `Autoplay started. Previous: ${JSON.stringify(config)} / New: ${JSON.stringify(
                newConfig,
            )}`,
            true,
        );
    }
});

/**
 * If Up Next ever has an episode queued while Autoplay is active, Autoplay should be cleared.
 */
registerMiddleware(fromUpNextActions.ActionTypes.UP_NEXT_CHANGED, (action, store) => {
    const state = store.getState();
    const upNextQueued = getQueuedEpisodeUuids(state);
    const autoplayConfig = getAutoplayConfig(state);

    if (upNextQueued.length > 0 && autoplayConfig !== null) {
        store.dispatch(clearAutoplay());
        Logger.log(
            `Autoplay stopped, Up Next was changed (length: ${
                upNextQueued.length
            }). Config: ${JSON.stringify(autoplayConfig)}`,
            true,
        );
    }
});

registerMiddleware('AUTOPLAY_REFRESH_EPISODES', (action, store) => {
    const state = store.getState();
    const autoplayConfig = getAutoplayConfig(state);
    if (!autoplayConfig) {
        return;
    }

    switch (autoplayConfig.source) {
        case 'podcast':
            store.dispatch(fromPodcastsActions.Actions.downloadPodcast(autoplayConfig.uuid, true));
            break;
        case 'in_progress':
        case 'new_releases':
        case 'starred':
            store.dispatch(fromFilterActions.Actions.downloadFilter(autoplayConfig.source));
            break;
        case 'files':
            store.dispatch(fromUploadedFilesActions.Actions.fetchUploadedFilesData());
            break;
        default:
            break;
    }
});

/**
 * When the user returns from being away, the Autoplay's episode list may have changed
 * from another device and needs to be refreshed.
 */
registerMiddleware(fromUserActions.ActionTypes.USER_RETURNED, (action, store) => {
    const state = store.getState();
    const autoplayConfig = getAutoplayConfig(state);
    if (!autoplayConfig) {
        return;
    }

    if (autoplayConfig.source === 'files') {
        // Uploaded Files are already being updated on every USER_RETURN, which
        // refreshes the episode list for us!
        return;
    }

    store.dispatch(refreshAutoplayEpisodes());
});

/**
 * When a podcast is subscribed or unsubscribed, the New Releases filter may have new or
 * removed episodes. If we're autoplaying that filter, refresh it to keep it up-to-date
 */
registerMiddleware(
    [
        fromPodcastsActions.ActionTypes.SUBSCRIBE_TO_PODCAST,
        fromPodcastsActions.ActionTypes.UNSUBSCRIBE_FROM_PODCAST,
    ],
    (action, store) => {
        const state = store.getState();
        const autoplayConfig = getAutoplayConfig(state);
        if (autoplayConfig?.source === 'new_releases') {
            store.dispatch(refreshAutoplayEpisodes());
        }
    },
);

registerMiddleware('AUTOPLAY_START', (action, store) => {
    const { config } = action.payload;
    store.dispatch(
        fromTracksActions.Actions.recordEvent('autoplay_started', {
            episode_source: config.source,
        }),
    );
});

registerMiddleware('AUTOPLAY_CLEAR', (action, store) => {
    store.dispatch(fromTracksActions.Actions.recordEvent('autoplay_stopped'));
});
