import React from 'react';

type Props = {
    episodeUuid: string;
    podcastUuid: string;
    episodeTitle: string;
    podcastTitle: string;
    isPlaying: boolean;
    duration: number;
    playedUpTo: number;
    color: string;
};

class NativeApp extends React.Component<Props> {
    shouldComponentUpdate(nextProps: Props) {
        return (
            this.props.episodeUuid !== nextProps.episodeUuid ||
            this.props.podcastUuid !== nextProps.podcastUuid ||
            this.props.episodeTitle !== nextProps.episodeTitle ||
            this.props.podcastTitle !== nextProps.podcastTitle ||
            this.props.isPlaying !== nextProps.isPlaying ||
            this.props.duration !== nextProps.duration ||
            this.props.color !== nextProps.color ||
            this.props.playedUpTo !== nextProps.playedUpTo
        );
    }

    render() {
        const json = JSON.stringify(this.props);
        if (window.msController) {
            window.msController.sendPlaybackInfo(json);
        }
        return null;
    }
}

export default NativeApp;
