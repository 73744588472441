import { RecommendedEpisode } from 'model/types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import { RootState } from 'redux/reducers';
import { NavigationItems } from '../../../helper/NavigationHelper';
import * as fromEpisodeActions from '../../../redux/actions/episode.actions';
import * as fromPlayerActions from '../../../redux/actions/player.actions';
import * as fromSettingsActions from '../../../redux/actions/settings.actions';
import { CloseButton } from '../../buttons/CloseButton';
import {
    DiscoverPlayerWrapper,
    LoaderSquareStyled,
    RecommendTitle,
    Recommendations,
} from './DiscoverPlayer.styled';
import { RecommendEpisode } from './RecommendEpisode';

type Props = {
    show: boolean;
    hideRecommendations: () => void;
    episodes: RecommendedEpisode[];
    playEpisode: (episodeUuid: string, podcastUuid: string) => void;
    openEpisode: (episode: RecommendedEpisode) => void;
} & RouteComponentProps;

class DiscoverPlayer extends React.Component<Props> {
    render() {
        return (
            <DiscoverPlayerWrapper>
                {this.props.show && this.renderRecommendations()}
            </DiscoverPlayerWrapper>
        );
    }

    renderRecommendations() {
        const { episodes } = this.props;
        return (
            <div>
                <CloseButton onClick={this.props.hideRecommendations} left />
                <Recommendations>
                    {episodes.length !== 0 && (
                        <RecommendTitle>
                            <FormattedMessage id="recommendations" />
                        </RecommendTitle>
                    )}
                    {this.renderEpisodes()}
                    {episodes.length === 0 && <LoaderSquareStyled color="#555" />}
                </Recommendations>
            </div>
        );
    }

    renderEpisodes = () => {
        const { episodes } = this.props;
        return episodes.map(episode => this.renderEpisode(episode));
    };

    renderEpisode = (episode: RecommendedEpisode) => {
        return (
            <RecommendEpisode
                key={episode.uuid}
                episode={episode}
                onPlayClick={() => {
                    this.playClick(episode);
                }}
                podcastLink={`${NavigationItems.PODCASTS.path}/${episode.podcastUuid}`}
                onEpisodeClick={() => {
                    this.props.openEpisode(episode);
                }}
            />
        );
    };

    playClick = (episode: RecommendedEpisode) => {
        this.props.playEpisode(episode.uuid, episode.podcastUuid);
    };
}

const mapStateToProps = (state: RootState) => {
    const loading = state.player.loadingRecommendedEpisodes;
    const episodes = state.player.recommendedEpisodes;
    return {
        episodes,
        loading,
        language: state.settings.language,
        show: loading || episodes.length > 0,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    playEpisode: (episodeUuid: string, podcastUuid: string) =>
        dispatch(
            fromPlayerActions.Actions.playEpisode(episodeUuid, podcastUuid, {
                eventSource: 'recommended_episodes',
            }),
        ),
    openEpisode: (episode: RecommendedEpisode) =>
        dispatch(
            fromEpisodeActions.Actions.openEpisode(episode, {
                eventSource: 'recommended_episodes',
            }),
        ),
    hideRecommendations: () => dispatch(fromSettingsActions.Actions.hideRecommendations()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DiscoverPlayer));
