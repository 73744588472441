import { Icon } from 'components/Icon';
import React from 'react';
import { CloseButtonWrapper } from './CloseButton.styled';

type Props = {
    'aria-label'?: string;
    onClick: () => void;
    left?: boolean;
};

function CloseButton(props: Props) {
    return (
        <CloseButtonWrapper
            left={props.left}
            aria-label={props['aria-label'] || 'Close'}
            onClick={props.onClick}
        >
            <Icon id="cancel" />
        </CloseButtonWrapper>
    );
}

export default CloseButton;
