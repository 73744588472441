import { StyledArrowButton } from 'components/buttons/ArrowButton/ArrowButton.styled';
import { DiscoverSectionWrapper } from 'components/discover/DiscoverSection/DiscoverSection.styled';
import styled from 'styled-components';
import { gridSize, Typography } from 'styles';

export const SearchPageWrapper = styled.div`
    height: 100%;
    padding: 20px 40px;
    position: relative;
    ${DiscoverSectionWrapper} {
        margin-bottom: ${4 * gridSize}px;
        ${StyledArrowButton} svg {
            display: none;
        }
    }

    .sort-options {
        margin-top: 8px;
    }
`;

export const SearchHeading = styled.h1`
    ${Typography.Desktop['H70-B']}
    margin: ${2 * gridSize}px 0;
    color: ${props => props.theme.tokens['primary-text-02']};
    display: flex;
    strong {
        color: ${props => props.theme.tokens['primary-text-01']};
        margin: 0 ${gridSize}px 0 0.25em;
    }
`;

export const NoResults = styled.section`
    margin-top: 15vh;
    text-align: center;

    svg {
        color: ${props => props.theme.tokens['primary-icon-01']};
        margin: 0 auto;
    }
    h1 {
        ${Typography.Desktop.H20}
        margin: ${3 * gridSize}px 0 ${gridSize}px 0;
    }
    p {
        ${Typography.Desktop.H50}
        line-height: 120%;
        color: ${props => props.theme.tokens['primary-text-02']};
        max-width: 23em;
        margin: 0 auto;
    }
`;
