import React, { useState, useRef, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import {
    SettingsTextContainer,
    SettingsLabel,
    SettingsEdit,
    SettingsUnit,
} from './SettingsNumberText.styled';

type Props = {
    value: string;
    maxCharacters?: number;
    title?: string;
    onSave: (val: number) => void;
    unit?: 'minutes' | 'seconds';
    defaultValue?: number;
    maxValue?: number;
};

function SettingsNumberText({
    value,
    maxCharacters = 3,
    title,
    onSave,
    unit = 'seconds',
    defaultValue = 30,
    maxValue,
}: Props) {
    const [settingValue, setSettingValue] = useState(value);
    const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;

    const validateNumber = useCallback(val => {
        if (val.length === 0) {
            return val;
        }
        return parseInt(val, 10) || defaultValue;
    }, []);

    const handleBlur = useCallback(
        event => {
            let eventValue = event.target.value;
            if (eventValue.length === 0) {
                eventValue = defaultValue;
            }
            setSettingValue(eventValue);

            // Only fire the onSave callback if the value has changed
            if (value !== eventValue) {
                onSave(parseInt(eventValue, 10));
            }
        },
        [value, onSave, defaultValue],
    );

    const handleChange = useCallback(event => {
        let eventValue = event.target.value;
        if (eventValue.length > maxCharacters) {
            eventValue = eventValue.slice(0, maxCharacters);
        }
        eventValue = validateNumber(eventValue);
        if (maxValue && eventValue > maxValue) {
            eventValue = maxValue;
        }
        if (eventValue < 0) {
            eventValue = -eventValue;
        }
        setSettingValue(eventValue);
    }, []);

    const UnitsMessage = () => {
        // React Intl requires these id's to be statically defined
        if (unit === 'minutes') {
            return <FormattedMessage id="minutes" />;
        }
        return <FormattedMessage id="seconds" />;
    };

    return (
        <SettingsTextContainer className="setting">
            <SettingsLabel>{title || ''}</SettingsLabel>
            <SettingsEdit maxCharacters={maxCharacters}>
                <input
                    type="text"
                    value={settingValue}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    ref={inputRef}
                />
                <SettingsUnit>
                    <UnitsMessage />
                </SettingsUnit>
            </SettingsEdit>
        </SettingsTextContainer>
    );
}

export default SettingsNumberText;
