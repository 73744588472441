import classNames from 'classnames';
import React from 'react';
import { FocusOn } from 'react-focus-on';
import { Portal } from 'react-portal';
import { onKeyDownEnter } from '../../../helper/UiHelper';
import { ESCAPE_KEY_CODE } from '../../../model/page';
import { PopupWrapper } from './Popup.styled';

type Props = {
    isOpen: boolean;
    onClosed: (e: Event | React.UIEvent | React.KeyboardEvent) => void;
    className?: string;
    closeOnScroll?: boolean;
    left?: number;
    right?: number;
    top?: number;
    bottom?: number;
    width: number;
    height: number;
    center?: boolean;
    abovePlayer?: boolean;
    position?: string;
    'aria-label'?: string;
    children: React.ReactNode;
};

type StateProps = {
    windowWidth: number;
    windowHeight: number;
};

class Popup extends React.Component<Props, StateProps> {
    static defaultProps = {
        width: 0,
        height: 0,
        center: true,
        abovePlayer: true,
        position: 'fixed',
        closeOnScroll: false,
    };

    frameRef: React.RefObject<HTMLDivElement> | undefined;

    popupRef: React.RefObject<HTMLDivElement> | undefined;

    constructor(props: Props) {
        super(props);
        this.state = {
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight,
        };
        this.popupRef = React.createRef();
        this.frameRef = React.createRef();
    }

    componentDidMount() {
        window.addEventListener('resize', this.onResize);
        if (this.props.closeOnScroll && this.frameRef?.current) {
            this.frameRef.current.addEventListener('wheel', this.onScroll);
        }

        window.addEventListener('keydown', this.shortcutEsc);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
        if (this.props.closeOnScroll && this.frameRef?.current) {
            this.frameRef?.current.removeEventListener('wheel', this.onScroll);
        }
        window.removeEventListener('keydown', this.shortcutEsc);
    }

    shortcutEsc = (event: KeyboardEvent) => {
        if (event && event.keyCode === ESCAPE_KEY_CODE) {
            this.props.onClosed && this.props.onClosed(event);
        }
    };

    onResize = () => {
        this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
    };

    onScroll = (event: MouseEvent) => {
        if (this.props.closeOnScroll && this.props.onClosed) {
            this.props.onClosed(event);
        }
    };

    render() {
        const { windowWidth, windowHeight } = this.state;
        const {
            onClosed,
            isOpen,
            className,
            top,
            bottom,
            left,
            right,
            width,
            height,
            center,
            abovePlayer,
            position,
        } = this.props;
        const popupClasses = classNames('popup', className, { open: isOpen });

        let styles: Record<string, unknown>;
        if (center) {
            const top = windowHeight / 2 - height / 2 - (abovePlayer ? 61 : 0);
            styles = {
                left: windowWidth / 2 - width / 2,
                top: top < 10 ? 10 : top,
            };
        } else {
            styles = { position };
            if (left) {
                styles.left = left;
            } else if (right) {
                styles.right = right;
            }
            if (top) {
                styles.top = top;
            } else if (bottom) {
                styles.bottom = bottom;
            }
        }
        if (width > 0) {
            styles.width = width;
        }
        if (height > 0) {
            styles.height = height;
        }
        return (
            <Portal node={document && document.getElementById('modal-root')}>
                <FocusOn scrollLock={false} noIsolation>
                    <PopupWrapper className={popupClasses} ref={this.popupRef}>
                        <div
                            aria-label={this.props['aria-label']}
                            tabIndex={-1}
                            className="popup-window"
                            style={styles}
                        >
                            {this.props.children}
                        </div>
                        <div
                            className="popup-background"
                            onKeyPress={event => onKeyDownEnter(event, onClosed.bind(this))}
                            onClick={onClosed}
                            ref={this.frameRef}
                        />
                    </PopupWrapper>
                </FocusOn>
            </Portal>
        );
    }
}

export default Popup;
