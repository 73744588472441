import styled from 'styled-components';

export const ImageTextOptionContainer = styled.div`
    height: 50px;
    width: 100%;
    position: relative;
    cursor: pointer;
    user-select: none;
    &:hover {
        background-color: ${props => props.theme.tokens['primary-ui-01-active']};
    }
    svg {
        position: absolute;
        top: 13px;
        left: 12px;
        color: ${props => props.color};
    }
    .option-title {
        padding: 0 25px 0 52px;
        line-height: 50px;
        vertical-align: middle;
        font-size: 14.4px;
        font-weight: 400;
        color: ${props => props.theme.tokens['primary-text-01']};
    }

    .option-title-no-svg {
        padding: 0 15px 0 15px;
        line-height: 50px;
        vertical-align: middle;
        font-size: 14.4px;
        font-weight: 400;
        color: ${props => props.theme.tokens['primary-text-01']};
    }
`;
