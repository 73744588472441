import styled from 'styled-components';
import { gridSize, H70, orange20, orange30, yellow70 } from '../../../styles';

export const SubscriptionErrorBubbleWrapper = styled.div<{ height: number }>`
    display: flex;
    align-items: center;
    justify-content: center;

    height: ${10 * gridSize}px;
    width: 100%;

    margin-top: ${3 * gridSize}px;

    background-color: ${orange20};
    border: 1px solid ${orange30};
    border-radius: 10px;
`;

export const SubscriptionErrorBubbleInner = styled(H70)`
    color: ${yellow70};
    text-align: center;
`;
