import styled from 'styled-components';
import { Typography } from 'styles';
import { ModernStyle } from 'styles/ModernStyle.styled';

export const SeparatorWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
`;

export const Line = styled.div`
    width: 100%;
    height: 1px;
    background: ${props => props.theme.legacyScss['theme-line']};
    ${ModernStyle} && {
        background: ${props => props.theme.modernStyle.border};
    }
`;

export const Text = styled.div`
    ${Typography.Desktop['H80-BC']}
    flex-shrink: 1;
    padding: 0 1em;
    ${ModernStyle} & {
        font-size: 14px;
        font-weight: 700;
    }
`;
