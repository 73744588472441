import { connect } from 'react-redux';
import { getSizeStringFromBytes } from '../../../../helper/UploadedFilesHelper';
import UsageTooltip from './view';

const mapStateToProps = state => {
    if (!state.uploadedFiles.data || !state.uploadedFiles.data.account) {
        return {
            numFiles: '-',
            usageMb: '-',
            totalGb: '-',
            percentUsage: '-',
        };
    }

    const numFiles = state.uploadedFiles.data.account.totalFiles;
    const usage = getSizeStringFromBytes(Number(state.uploadedFiles.data.account.usedSize));
    const total = getSizeStringFromBytes(Number(state.uploadedFiles.data.account.totalSize));
    const percentUsage =
        (100 * Number(state.uploadedFiles.data.account.usedSize)) /
        Number(state.uploadedFiles.data.account.totalSize);

    return {
        numFiles,
        usage,
        total,
        percentUsage,
        theme: state.settings.theme,
    };
};

export default connect(mapStateToProps)(UsageTooltip);
