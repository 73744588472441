import React from 'react';
import { UpNextIconSvg } from './UpNextIcon.styled';

type Props = {
    count: number;
};

const renderEmpty = () => (
    <g transform="translate(6, 4)" fillOpacity="0.5">
        <path d="M3.7906191,2.56472793 L9.43038657,6.59313326 C9.65509286,6.75363776 9.7071387,7.0659128 9.54663421,7.2906191 C9.51456438,7.33551686 9.47528433,7.37479691 9.43038657,7.40686674 L3.7906191,11.4352721 C3.5659128,11.5957766 3.25363776,11.5437307 3.09313326,11.3190244 C3.03256157,11.2342241 3,11.1326168 3,11.0284053 L3,2.97159466 C3,2.69545229 3.22385763,2.47159466 3.5,2.47159466 C3.60421149,2.47159466 3.70581872,2.50415623 3.7906191,2.56472793 Z" />
        <path d="M13,6 L21,6 C21.5522847,6 22,6.44771525 22,7 C22,7.55228475 21.5522847,8 21,8 L13,8 C12.4477153,8 12,7.55228475 12,7 C12,6.44771525 12.4477153,6 13,6 Z M10,11 L21,11 C21.5522847,11 22,11.4477153 22,12 C22,12.5522847 21.5522847,13 21,13 L10,13 C9.44771525,13 9,12.5522847 9,12 C9,11.4477153 9.44771525,11 10,11 Z M10,16 L21,16 C21.5522847,16 22,16.4477153 22,17 C22,17.5522847 21.5522847,18 21,18 L10,18 C9.44771525,18 9,17.5522847 9,17 C9,16.4477153 9.44771525,16 10,16 Z" />
    </g>
);

const renderWithCount = (count: number) => (
    <>
        <path
            fillOpacity="0.5"
            transform="translate(4, 10)"
            d="M0 1C0 0.447715 0.447715 0 1 0H5C5.55228 0 6 0.447715 6 1C6 1.55228 5.55228 2 5 2H1C0.447715 2 0
1.55228 0 1ZM0 6C0 5.44772 0.447715 5 1 5H6C6.55228 5 7 5.44772 7 6C7 6.55228 6.55228 7 6
7H1C0.447715 7 0 6.55228 0 6ZM1 10C0.447715 10 0 10.4477 0 11C0 11.5523 0.447715 12 1
12H9C9.55229 12 10 11.5523 10 11C10 10.4477 9.55228 10 9 10H1Z"
        />

        <mask id={`up-next-icon-mask-${count}`}>
            {count < 10 ? (
                <circle cx="22" cy="10" r="10" fill="white" />
            ) : (
                <rect x="12" y="0" width="26" height="20" rx="10" fill="white" />
            )}

            <text textAnchor="middle" x={count < 10 ? 22 : 25} y="14" fill="black">
                {count < 100 ? count : '99+'}
            </text>
        </mask>
        <rect
            x="0"
            y="0"
            width="100%"
            height="100%"
            rx="10"
            mask={`url(#up-next-icon-mask-${count})`}
        />
    </>
);

const UpNextIcon = ({ count }: Props) => {
    return (
        <UpNextIconSvg width={38} height={32} viewBox="0 0 38 32">
            {count === 0 ? renderEmpty() : renderWithCount(count)}
        </UpNextIconSvg>
    );
};

export default UpNextIcon;
