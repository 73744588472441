export const emailFieldSchema = formatMessage => ({
    name: 'emailField',
    type: 'email',
    placeholder: formatMessage('email'),
    validation: {
        rules: {
            pattern: /(.+)@(.+){1,}\.(.+){1,}/,
        },
        messages: {
            pattern: formatMessage('invalid-email'),
        },
    },
    value: '',
    state: '',
    error: null,
    visible: true,
});

export const passwordFieldSchema = formatMessage => ({
    name: 'passwordField',
    type: 'password',
    placeholder: formatMessage('password'),
    validation: {
        rules: {
            minLength: 6,
            maxLength: 128,
        },
        messages: {
            minLength: formatMessage('password-restriction'),
            maxLength: formatMessage('password-restriction'), // TODO: Max error message
        },
    },
    value: '',
    state: '',
    error: null,
    visible: true,
});

export const newPasswordFieldSchema = formatMessage => ({
    name: 'newPasswordField',
    type: 'password',
    placeholder: formatMessage('new-password'),
    validation: {
        rules: {
            minLength: 6,
            maxLength: 128,
        },
        messages: {
            minLength: formatMessage('password-restriction'),
            maxLength: formatMessage('password-restriction'), // TODO: Max error message
        },
    },
    value: '',
    state: '',
    error: null,
    visible: true,
});

export const confirmPasswordFieldSchema = formatMessage => ({
    name: 'confirmPasswordField',
    type: 'password',
    placeholder: formatMessage('confirm-new-password'),
    validation: {
        rules: {
            customValidation: form => {
                if (form.confirmPasswordField.value !== '') {
                    return form.newPasswordField
                        ? form.confirmPasswordField.value === form.newPasswordField.value
                        : form.confirmPasswordField.value === form.passwordField.value;
                }
                return true;
            },
        },
        messages: {
            customValidation: formatMessage('passwords-must-match'),
        },
    },
    value: '',
    state: '',
    error: null,
    visible: true,
});
