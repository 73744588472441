import { Separator } from 'components/Separator';
import useFormatMessage from 'hooks/useFormatMessage';
import React, { ReactNode, useEffect, useState } from 'react';
import ArrowButton from '../../buttons/ArrowButton/ArrowButton';
import {
    DiscoverSectionHeader,
    DiscoverSectionPlacement,
    DiscoverSectionWrapper,
} from './DiscoverSection.styled';
import { ResponsiveGrid } from './ResponsiveGrid';

type Props = {
    children: React.ReactNode;
    title?: string;
    controls?: React.ReactNode;
    minWidth?: number;
    maxRowCount?: number;
    onSeeAllClick?: () => void;
    discoverFormat: 'grid' | 'list';
    gap?: number | string;
    renderPlacement?: ReactNode;
    onChangeNumShown?: (count: number) => void;
};

function DiscoverSection({
    children,
    controls,
    title = '',
    minWidth = 350,
    onSeeAllClick,
    maxRowCount = -1,
    discoverFormat,
    gap = '32px 8px',
    renderPlacement,
    onChangeNumShown,
}: Props) {
    const [colsPerRow, setColsPerRow] = useState(6);
    const formatMessage = useFormatMessage();

    useEffect(() => onChangeNumShown?.(colsPerRow * maxRowCount), [
        colsPerRow,
        maxRowCount,
        onChangeNumShown,
    ]);

    const isList = discoverFormat === 'list';

    const elements = React.Children.toArray(children);

    const firstRowElements = elements?.slice(0, colsPerRow);

    const totalRowsToRender =
        maxRowCount === -1 ? Math.ceil(elements.length / colsPerRow) : maxRowCount;

    const remainingRowElements = elements?.slice(colsPerRow, colsPerRow * totalRowsToRender);

    return (
        <DiscoverSectionWrapper discoverFormat={discoverFormat}>
            <DiscoverSectionHeader>
                {title && <h2>{title}</h2>}
                {controls && <div>{controls}</div>}
                {onSeeAllClick && (
                    <ArrowButton
                        color="#03A9F4"
                        onClick={onSeeAllClick}
                        label={formatMessage('see-all')}
                    />
                )}
            </DiscoverSectionHeader>
            {isList && (
                <ResponsiveGrid minWidth={minWidth} onColumnCountCalc={setColsPerRow} gap={gap}>
                    {maxRowCount === -1
                        ? children
                        : React.Children.toArray(children).slice(0, colsPerRow * maxRowCount)}
                </ResponsiveGrid>
            )}
            {!isList && (
                <ResponsiveGrid minWidth={minWidth} onColumnCountCalc={setColsPerRow} gap={gap}>
                    {firstRowElements}
                    {renderPlacement && (
                        <DiscoverSectionPlacement>
                            <Separator />
                            {renderPlacement}
                            <Separator />
                        </DiscoverSectionPlacement>
                    )}
                    {remainingRowElements}
                </ResponsiveGrid>
            )}
        </DiscoverSectionWrapper>
    );
}

export default DiscoverSection;
