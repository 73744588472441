import React, { useEffect, useRef, ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import useFormatMessage from 'hooks/useFormatMessage';
import StatsTimeText from 'components/format/StatsTimeText';
import { Icon } from 'components/Icon';
import { StatsPageSinceMessage } from 'components/messages';
import { TrackOnMount, TrackOnUnmount } from 'components/Tracks';
import { Header, LoaderSquare } from '../../components';
import { createFunnyQuote } from './funny-quotes';
import {
    StatsPageWrapper,
    Since,
    TimeListened,
    Quote,
    TimeSavedBy,
    SettingOption,
    SettingOptionTotal,
    SettingTitle,
    Separator,
} from './StatsPage.styled';

type Props = {
    timeListened: number;
    timeSkipping: number;
    timeVariableSpeed: number;
    timeSilenceRemoval: number;
    timeIntroSkipping: number;
    timesStartedAt: string | null;

    locale?: string;
    downloadStats: () => void;
};

const StatsPage = ({
    timeListened,
    timeSkipping,
    timeVariableSpeed,
    timeSilenceRemoval,
    timeIntroSkipping,
    timesStartedAt,
    locale,
    downloadStats,
}: Props) => {
    const formatMessage = useFormatMessage();
    const funny = useRef<ReactNode>();

    useEffect(() => {
        downloadStats();
    }, [downloadStats]);

    useEffect(() => {
        funny.current = createFunnyQuote(timeListened, locale);
    }, [locale, timeListened]);

    const totalSavings = () => {
        return timeSkipping + timeVariableSpeed + timeSilenceRemoval + timeIntroSkipping;
    };

    const renderStats = () => {
        const statsLoaded = timesStartedAt !== null;

        if (!statsLoaded) {
            return <LoaderSquare />;
        }

        return (
            <div>
                <Since>
                    <StatsPageSinceMessage timesStartedAt={timesStartedAt ?? ''} />
                </Since>
                <TimeListened>
                    <StatsTimeText timeSecs={timeListened} />
                </TimeListened>
                <Quote>{funny.current}</Quote>
                <Separator />
                <TimeSavedBy>{formatMessage('stats-time-saved-by')}</TimeSavedBy>
                <SettingOption>
                    <Icon id="skip" />
                    <SettingTitle>{formatMessage('stats-skipping')}</SettingTitle>
                    <span>
                        <StatsTimeText timeSecs={timeSkipping} />
                    </span>
                </SettingOption>
                <SettingOption>
                    <Icon id="gauge" />
                    <SettingTitle>{formatMessage('stats-variable-speed')}</SettingTitle>
                    <span>
                        <StatsTimeText timeSecs={timeVariableSpeed} />
                    </span>
                </SettingOption>
                {timeSilenceRemoval > 0 && (
                    <SettingOption>
                        <Icon id="cut" />
                        <SettingTitle>{formatMessage('stats-trim-silence')}</SettingTitle>
                        <span>
                            <StatsTimeText timeSecs={timeSilenceRemoval} />
                        </span>
                    </SettingOption>
                )}
                <SettingOption>
                    <Icon id="skip-auto" />
                    <SettingTitle>{formatMessage('stats-skipping-auto')}</SettingTitle>
                    <span>
                        <StatsTimeText timeSecs={timeIntroSkipping} />
                    </span>
                </SettingOption>
                <Separator />
                <SettingOptionTotal>
                    <SettingTitle>{formatMessage('stats-total-time-saved')}</SettingTitle>
                    <span>
                        <StatsTimeText timeSecs={totalSavings()} />
                    </span>
                </SettingOptionTotal>
            </div>
        );
    };

    return (
        <StatsPageWrapper>
            <TrackOnMount event="stats_shown" />
            <TrackOnUnmount event="stats_dismissed" />
            <Helmet>
                <title>{formatMessage('listening-stats')}</title>
            </Helmet>
            <Header title={formatMessage('listening-stats')} />
            {renderStats()}
        </StatsPageWrapper>
    );
};

export default StatsPage;
