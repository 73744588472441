import { TracksProperties } from 'model/types';
import { createAction, ActionsUnion } from './action-creators';

export enum ActionTypes {
    TRACKS_RECORD_EVENT = '@tracks/TRACKS_RECORD_EVENT',
}

export const Actions = {
    recordEvent: (name: string, properties?: TracksProperties) =>
        createAction(ActionTypes.TRACKS_RECORD_EVENT, { name, properties }),
};

export type Actions = ActionsUnion<typeof Actions>;
