import { Logger } from 'helper/Logger';
import JwtDecode from 'jwt-decode';
import { AuthTokens, DecodedAuthToken, OAuthLoginConfig } from 'model/types';
import { api } from 'services/api';

const LOCAL_STORAGE_KEY_OAUTH_LOGIN_CONFIG = 'oauthLoginConfig';
const LOCAL_STORAGE_KEY_TOKEN = 'token';
const LOCAL_STORAGE_KEY_AUTH_TOKENS = 'authTokens';
const REFRESH_THRESHOLD_IN_SECONDS = 60 * 5; // Refresh tokens within 5 minutes of expiration

let fetchRefreshToken: Promise<AuthTokens | void> | undefined;

// =====================================
// SWAP OUT LEGACY AUTH TOKEN ON STARTUP
// Our newer SSO auth includes extended token data, such as a refresh token.
// On startup, check if this new token is already in localStorage. If not,
// check if there's a legacy token and insert that into the new token in
// localStorage. In either case, delete the legacy token afterwards.
//
// This code can be removed a couple weeks after SSO auth launches.
const legacyToken = localStorage.getItem(LOCAL_STORAGE_KEY_TOKEN);
if (!localStorage.getItem(LOCAL_STORAGE_KEY_AUTH_TOKENS) && legacyToken) {
    localStorage.setItem(
        LOCAL_STORAGE_KEY_AUTH_TOKENS,
        JSON.stringify({ accessToken: legacyToken }),
    );
}
localStorage.removeItem(LOCAL_STORAGE_KEY_TOKEN); // Delete the legacy token
// =====================================

/**
 * Check for the auth tokens in localStorage and return them. This is a private function
 * because no outside consumers should need anything except the accessToken — which they
 * should use getAccessToken() to retrieve.
 */
const getTokenData = (): AuthTokens | null => {
    const storedTokens = localStorage.getItem(LOCAL_STORAGE_KEY_AUTH_TOKENS);
    if (!storedTokens) {
        return null;
    }
    try {
        return JSON.parse(storedTokens);
    } catch {
        return null;
    }
};

export const clearTokenData = () => localStorage.removeItem(LOCAL_STORAGE_KEY_AUTH_TOKENS);

export const setTokenData = (authTokens: AuthTokens) =>
    localStorage.setItem(LOCAL_STORAGE_KEY_AUTH_TOKENS, JSON.stringify(authTokens));

// Keep track of the last time the access token was refreshed, so we don't refresh it too often
let accessTokenRefreshedAt: number | null = null;

/**
 * Retrieve the current access token. Returns a Promise, so the token can be refreshed first
 * if it's near or past expiration.
 */
export const getAccessToken = () => {
    const authTokens = getTokenData();

    // No auth tokens were found, this user is not signed in
    if (!authTokens) {
        return Promise.resolve(null);
    }

    // If no refresh token is available, return the current access token and hope it's still valid!
    if (!('refreshToken' in authTokens)) {
        return Promise.resolve(authTokens.accessToken);
    }

    const { expiresIn } = authTokens;

    if (accessTokenRefreshedAt) {
        const secondsSinceLastRefresh = (Date.now() - accessTokenRefreshedAt) / 1000;
        if (secondsSinceLastRefresh < expiresIn - REFRESH_THRESHOLD_IN_SECONDS) {
            return Promise.resolve(authTokens.accessToken);
        }
    }

    // The token will expire soon or the application is just starting. If there's no refresh in progress,
    // initiate one. This ensures that even if the app makes multiple token requests at once, the
    // refresh API will only be called once — and all the requests will resolve once that
    // single refresh API call is done.
    if (!fetchRefreshToken) {
        Logger.log('Refreshing auth token...');
        fetchRefreshToken = api
            .refreshAuthTokens(authTokens.refreshToken)
            .then(
                tokens => {
                    Logger.log('Auth token has been refreshed');
                    accessTokenRefreshedAt = Date.now();
                    return tokens;
                },
                () => Logger.log('Error refreshing auth token'),
            )
            .finally(() => {
                fetchRefreshToken = undefined;
            });
    }

    // Once the fetch is resolved, return the refreshed access token or the previous token (if there was an error)
    return fetchRefreshToken.then(tokens => (tokens ? tokens.accessToken : authTokens.accessToken));
};

/**
 * Store an OAuth login config in localStorage, so that after redirect we can pick it back up.
 */
export const setOAuthLoginConfig = (config: OAuthLoginConfig) =>
    localStorage.setItem(LOCAL_STORAGE_KEY_OAUTH_LOGIN_CONFIG, JSON.stringify(config));

/**
 * Retrieve the saved OAuth login config from localStorage. Because it contains sensitive info (the nonce),
 * the config is destroyed as soon as it's retrieved — so calling again will yield undefined.
 */
export const getOAuthLoginConfig = () => {
    const config = localStorage.getItem(LOCAL_STORAGE_KEY_OAUTH_LOGIN_CONFIG);
    if (!config) {
        return undefined;
    }
    // Delete the config as soon as it's retrieved
    localStorage.removeItem(LOCAL_STORAGE_KEY_OAUTH_LOGIN_CONFIG);
    try {
        return JSON.parse(config) as OAuthLoginConfig;
    } catch {
        return undefined;
    }
};

export const getAccessTokenProvider = () => {
    const authTokens = getTokenData();
    if (!authTokens) {
        return null;
    }
    return JwtDecode<DecodedAuthToken>(authTokens.accessToken)['pc:provider'];
};
