import { TrackOnMount } from 'components/Tracks';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { ImageTextOption } from '../../../components/popup/ImageTextOption';
import { ImageTextSwitchOption } from '../../../components/popup/ImageTextSwitchOption';
import { PopupMenu } from '../../../components/popup/PopupMenu';

type Props = {
    color?: string;
    id?: string;
    showArchived: boolean;
    allArchived?: boolean;
    onShowArchived?: () => void;
    onHideArchived?: () => void;
    onArchiveAll?: () => void;
    onUnarchiveAll?: () => void;
    onPodcastSettings?: () => void;
    onArchiveAllPlayed: () => void;
    playedAreAllArchived: boolean;
    onClearHistory?: () => void;
    theme: number;
} & WrappedComponentProps;

class EpisodesOptions extends React.Component<Props> {
    static defaultProps = {
        id: '',
        color: '#03a9f4',
        showArchived: false,
        allArchived: false,
    };

    render() {
        const {
            color,
            onShowArchived,
            onHideArchived,
            onArchiveAll,
            onUnarchiveAll,
            onPodcastSettings,
            playedAreAllArchived,
            onClearHistory,
            theme,
        } = this.props;
        const enabled = onShowArchived != null || onHideArchived != null || onClearHistory != null;
        return enabled ? (
            <div id={this.props.id}>
                <PopupMenu
                    id="episodes-options-popup"
                    aria-label="Episode List Options"
                    color={color}
                    theme={theme}
                >
                    <TrackOnMount event="podcast_screen_options_tapped" />
                    {onShowArchived && onHideArchived && this.renderShowArchived()}
                    {onArchiveAll && onUnarchiveAll && this.renderArchiveAll()}
                    {!playedAreAllArchived && this.renderArchiveAllPlayed()}
                    {onClearHistory && this.renderHistory()}
                    {onPodcastSettings && this.renderPodcastSettings()}
                </PopupMenu>
            </div>
        ) : null;
    }

    renderPodcastSettings = () => (
        <ImageTextOption
            text={this.props.intl.formatMessage({ id: 'podcast-settings' })}
            key="settings"
            icon="settings"
            onClick={this.props.onPodcastSettings}
        />
    );

    renderArchiveAll() {
        const { onUnarchiveAll, onArchiveAll, allArchived, intl } = this.props;
        return allArchived ? (
            <ImageTextOption
                key="unarchive-all"
                icon="unarchive"
                text={intl.formatMessage({ id: 'unarchive-all' })}
                onClick={onUnarchiveAll?.bind(this)}
            />
        ) : (
            <ImageTextOption
                key="archive-all"
                icon="archive-all"
                text={intl.formatMessage({ id: 'archive-all' })}
                onClick={onArchiveAll?.bind(this)}
            />
        );
    }

    renderShowArchived() {
        const { onHideArchived, onShowArchived, showArchived, intl } = this.props;

        return (
            <ImageTextSwitchOption
                key="show-archived"
                on={showArchived}
                onSvg="show-archived"
                offSvg="show-archived"
                onText={intl.formatMessage({ id: 'show-archived' })}
                offText={intl.formatMessage({ id: 'show-archived' })}
                onClick={showArchived ? onHideArchived : onShowArchived}
            />
        );
    }

    renderArchiveAllPlayed() {
        const { onArchiveAllPlayed, intl } = this.props;
        return (
            <ImageTextOption
                key="archive-all-played"
                icon="archive-all-played"
                text={intl.formatMessage({ id: 'archive-all-played' })}
                onClick={onArchiveAllPlayed.bind(this)}
            />
        );
    }

    renderHistory() {
        const { intl, onClearHistory } = this.props;
        return (
            <ImageTextOption
                key="clear-history"
                icon="cancel"
                text={intl.formatMessage({ id: 'clear-history' })}
                onClick={onClearHistory?.bind(this)}
            />
        );
    }
}

export default injectIntl(EpisodesOptions);
