import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../components/Button';
import Modal from '../../../components/Modal';
import {
    DialogIcon,
    DialogTitle,
    DialogDescription,
    DialogText,
    DialogContent,
} from './PromoErrorModal.styled';
import PlusErrorIcon from './plus-error-icon.png';

type Props = {
    message?: string;
};

export default function PromoErrorModal({ message = 'Something went wrong' }: Props) {
    const history = useHistory();

    return (
        <Modal hideCross onClose={() => ({})} useModernStyle>
            <DialogContent>
                <DialogIcon src={PlusErrorIcon} />
                <DialogText>
                    <DialogTitle>Error</DialogTitle>
                    <DialogDescription>{message}</DialogDescription>
                </DialogText>
                <Button onClick={() => history.goBack()} width={355} kind="primary">
                    Return to Promotion Page
                </Button>
            </DialogContent>
        </Modal>
    );
}
