import useFormatMessage from 'hooks/useFormatMessage';
import React from 'react';
import { LIGHT_THEME } from '../../model/theme/theme-light';

type Props = {
    className?: string;
    color?: string;
    lineColor?: string;
    size?: number;
};

const Logo = ({
    color = LIGHT_THEME.tokens['support-05'],
    lineColor = '#fff',
    size = 54,
    className,
}: Props) => {
    const formatMessage = useFormatMessage();
    return (
        <svg
            className={className}
            role="img"
            width={size}
            height={size}
            viewBox="0 0 54 54"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title>{formatMessage('pocket-casts-plus')}</title>
            <path
                fill={color}
                fillRule="evenodd"
                clipRule="evenodd"
                d="M54 27C54 41.912 41.912 54 27 54C12.088 54 0 41.912 0 27C0 12.088 12.088 0 27 0C41.912 0 54 12.088 54 27Z"
            />
            <path
                fill={lineColor}
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6 27C6 15.402 15.402 6 27 6C38.598 6 48 15.402 48 27H42.75C42.75 18.3015 35.6985 11.25 27 11.25C18.3015 11.25 11.25 18.3015 11.25 27C11.25 35.6985 18.3015 42.75 27 42.75V48C15.402 48 6 38.598 6 27ZM27 39.6C20.0412 39.6 14.4 33.9588 14.4 27C14.4 20.0412 20.0412 14.4 27 14.4C33.9588 14.4 39.6 20.0412 39.6 27H35.0182C35.0182 22.5717 31.4283 18.9818 27 18.9818C22.5717 18.9818 18.9818 22.5717 18.9818 27C18.9818 31.4283 22.5717 35.0182 27 35.0182V39.6Z"
            />
        </svg>
    );
};
export default Logo;
