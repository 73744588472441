import styled from 'styled-components';
import { gridSize, H70, H80 } from '../../../../styles';

export const UsageTooltipWrapper = styled.div`
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;

    flex-direction: column;
    align-items: center;

    z-index: 10;

    @keyframes fade-in {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    animation: fade-in 0.1s ease;
`;

export const TooltipTriangle = styled.div`
    position: relative;

    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid ${props => props.theme.legacyScss['theme-text-primary']};
`;

export const TooltipBodyWrapper = styled.div`
    background-color: transparent;
    width: 300px;
    height: 52px;
    display: flex;
    justify-content: center;
`;

export const TooltipBody = styled.div`
    display: flex;
    height: 52px;

    border-radius: 4px;
    background-color: ${props => props.theme.legacyScss['theme-text-primary']};
    flex-direction: row;
`;

export const UsageMeterWrapper = styled.div`
    display: flex;
    align-items: center;
    padding-left: ${gridSize}px;
`;

export const UsageTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: ${gridSize}px;
    padding-right: ${1.5 * gridSize}px;
`;

export const FilesText = styled(H70)`
    display: flex;

    align-items: flex-end;
    color: ${props => props.theme.legacyScss['theme-background']};
    margin-bottom: -2px;
`;

export const BytesText = styled(H80)`
    display: flex;

    align-items: flex-start;
    color: ${props => props.theme.legacyScss['theme-background']};
    opacity: 0.6;
`;
