import { tint } from 'polished';
import styled, { css } from 'styled-components';
import { blueTextLinkMixin, gridSize, userSelectNoneMixin } from '../../../styles';

export const ActionsWrapper = styled.div`
    position: relative;
    flex: 1;
    & > *:not(:last-child) {
        border-bottom: 1px solid ${props => props.theme.tokens['primary-ui-05']};
    }
`;

export const ActionWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;

    align-items: center;
    margin-top: 21px;
    padding-bottom: 18px;

    @media only screen and (min-width: 1300px) {
        width: 50%;
    }
`;

export const Action = styled.button<{ disabled?: boolean }>`
    display: flex;
    height: 100%;

    flex-shrink: 0;
    align-items: center;

    ${props =>
        props.disabled &&
        css`
            cursor: default;
            opacity: 0.25;
        `};

    svg {
        color: ${props => props.theme.tokens['primary-icon-01']};
    }
    &:hover {
        svg,
        span {
            ${props =>
                !props.disabled && `color: ${props.theme.tokens['primary-interactive-02-hover']}`};
            ${props => !props.disabled && 'opacity: 1'};
        }
    }

    ${userSelectNoneMixin};
`;

export const DangerousAction = styled(Action)`
    svg circle {
        fill: ${props => props.theme.legacyScss['theme-background']};
    }

    svg,
    span {
        ${props => !props.disabled && `color: ${props.theme.tokens['support-05']}`};
    }

    &:hover {
        svg,
        span {
            ${props => !props.disabled && `color: ${tint(0.3, props.theme.tokens['support-05'])}`};
        }
    }
`;

export const SuccessAction = styled(Action)`
    svg,
    span {
        color: ${props => props.theme.tokens['support-02']};
    }
    &:hover {
        svg,
        span {
            ${props => !props.disabled && `color: ${tint(0.3, props.theme.tokens['support-02'])}`};
        }
    }
`;

export const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    padding-right: ${2 * gridSize}px;
`;

export const TextWrapper = styled.span<{ isGreen?: boolean }>`
    display: flex;
    align-items: center;
    font-size: 17.28px;
    font-weight: 400;

    color: ${({ theme, isGreen }) =>
        isGreen ? theme.tokens['support-02'] : theme.tokens['primary-text-01']};
`;

export const SwitchWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-self: flex-end;
    cursor: pointer;
    margin-left: auto;
`;

export const HelpText = styled.small`
    width: 100%;
    padding: 4px 40px 0 40px;
    line-height: 1.3;
    color: ${props => props.theme.tokens['primary-text-02']};

    a {
        ${blueTextLinkMixin};
    }

    @media only screen and (min-width: 1300px) {
        width: 50%;
        padding: 0 0 0 ${3 * gridSize}px;

        position: absolute;
        left: 50%;
    }
`;
