import { CategoryLinks } from 'components/CategoryLinks';
import { Icon } from 'components/Icon';
import { SortOptions } from 'components/SortOptions';
import { StarRating } from 'components/StarRating';
import { TextWithIcon } from 'components/TextWithIcon';
import { TrackOnMount } from 'components/Tracks';
import { EpisodeCount } from 'components/messages';
import { EpisodeSortOrder } from 'helper/PodcastHelper';
import {
    Bookmark,
    Episode,
    EpisodeAndPodcastUuidsArray,
    EpisodeSyncInfo,
    EpisodeWithSyncInfo,
    PlayerState,
    PodcastCacheParsed,
    PodcastRating,
    TracksProperties,
    UpNextState,
} from 'model/types';
import React from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { RouteComponentProps, StaticContext } from 'react-router';
import * as fromEpisodeActions from 'redux/actions/episode.actions';
import { USE_GIVE_RATINGS } from 'settings';
import { EpisodesTable } from '../../components/EpisodesTable';
import { LoaderSquare } from '../../components/LoaderSquare';
import {
    FriendlyUrl,
    Loader,
    LoaderRound,
    NextEpisodeText,
    PodcastImage,
} from '../../components/index';
import { withAnalyticsContext } from '../../context/AnalyticsContext';
import { PlayingStatus } from '../../helper/PlayingStatus';
import { EpisodesOptions } from './EpisodesOptions';
import { PodcastActions } from './PodcastActions';
import {
    BookmarksButton,
    EpisodeRows,
    EpisodeSummary,
    NextEpisodeDetails,
    PagePodcast,
    PodcastActionsWrapper,
    PodcastAuthorSection,
    PodcastDetails,
    PodcastImageWrapper,
    PodcastInformation,
    PodcastMeta,
    PodcastTitle,
    RateButton,
    ReadMoreText,
    SearchBox,
    StyledShareButton,
    TitleAndActions,
} from './PodcastPage.styled';

export type Props = {
    uuid: string;
    loadFailed: boolean;
    isLoadingEpisodes: boolean;
    episodeSortOrder: number;
    noEpisodes: boolean;
    showArchived: boolean;
    isSubscribed: boolean;
    episodeCount: number;
    archivedCount: number;
    allArchived: boolean;
    resetJustPurchasedPodcast: () => void;
    anyPlayedButNotArchived: boolean;
    hideNextEpisodeDetails: boolean;
    isSearching: boolean;
    uuidToEpisodeSync: Record<string, EpisodeSyncInfo>;
    episodes: EpisodeWithSyncInfo[];
    allEpisodes: EpisodeWithSyncInfo[];
    theme: number;
    podcast: PodcastCacheParsed;
    upNext: UpNextState;
    player: PlayerState;
    color: string;
    searchTerm: string;
    bookmarks: Bookmark[];
    rating?: PodcastRating | null;
    openPodcast: (uuid: string) => void;
    unsubscribeFromPodcast: (uuid: string) => void;
    openPodcastShare: (uuid: string) => void;
    recordEvent: (event: string, properties?: TracksProperties) => void;
    searchEpisodes: (term: string, uuid: string) => void;
    updateEpisodeOrder: (uuid: string, orderId: number) => void;
    showArchivedEpisodes: (uuid: string, showArchived: boolean) => void;
    showArchiveAllConfirmation: (
        episodeUuidAndPodcastUuids: EpisodeAndPodcastUuidsArray,
        show?: boolean,
    ) => void;
    unarchiveAll: (episodeUuidAndPodcastUuids: EpisodeAndPodcastUuidsArray) => void;
    openEpisode: (...args: Parameters<typeof fromEpisodeActions.Actions.openEpisode>) => void;
    discoverPodcastPageSubscribeEvent: (uuid: string) => void;
    subscribeToPodcast: (podcast: PodcastCacheParsed) => void;
    openPodcastEvent: (uuid: string) => void;
    fetchPodcastRating: () => void;
    openBookmarks: () => void;
    openRatingModal: () => void;
} & WrappedComponentProps &
    RouteComponentProps<{ episode?: string; uuid: string }, StaticContext, { from: string }>;

type StateProps = {
    searchTerm: string;
    showedEpisodeModalForSharedEpisode: boolean;
};

export class PodcastPage extends React.Component<Props, StateProps> {
    constructor(props: Props) {
        super(props);
        this.state = {
            searchTerm: '',
            showedEpisodeModalForSharedEpisode: false,
        };
    }

    componentDidMount() {
        this.props.openPodcast(this.props.uuid);
        this.props.openPodcastEvent(this.props.uuid);
        this.maybeFetchRating();

        document.addEventListener('keydown', this.escFunction, false);
        window.addEventListener('focus', this.windowFocus);
    }

    componentWillUnmount() {
        this.clearSearch();

        document.removeEventListener('keydown', this.escFunction, false);
        window.removeEventListener('focus', this.windowFocus);
    }

    componentDidUpdate(prevProps: Props, prevState: StateProps) {
        const { uuid, match, isLoadingEpisodes, allEpisodes, rating, recordEvent } = this.props;
        const { searchTerm, showedEpisodeModalForSharedEpisode } = this.state;

        if (prevProps.uuid !== uuid) {
            this.props.openPodcast(uuid);
            this.setState({ searchTerm: '' });
        }

        if (prevProps.rating !== rating) {
            this.maybeFetchRating();
        }

        // An episode UUID could be passed through from a share link, and we need to show the
        // episode modal, but we can only do so once the podcast's episodes have loaded.
        if (match.params.episode && !showedEpisodeModalForSharedEpisode) {
            if (prevProps.isLoadingEpisodes && !isLoadingEpisodes) {
                const sharedEpisode = allEpisodes.find(ep => ep.uuid === match.params.episode);
                if (sharedEpisode) {
                    this.onEpisodeClick(sharedEpisode);
                    this.setState({ showedEpisodeModalForSharedEpisode: true });
                }
            }
        }

        if (!prevState.searchTerm && searchTerm) {
            recordEvent('podcast_screen_search_performed');
        }
        if (!searchTerm && prevState.searchTerm) {
            recordEvent('podcast_screen_search_cleared');
        }
    }

    maybeFetchRating = () => {
        if (this.props.rating === undefined) {
            this.props.fetchPodcastRating();
        }
    };

    windowFocus = () => {
        this.props.openPodcast(this.props.uuid);
    };

    escFunction = (event: KeyboardEvent) => {
        const ESCAPE_KEY_CODE = 27;
        if (event.keyCode === ESCAPE_KEY_CODE) {
            this.clearSearch();
        }
    };

    subscribeClick(podcast: PodcastCacheParsed) {
        if (this.props.isSubscribed) {
            this.props.unsubscribeFromPodcast(podcast.uuid);
        } else {
            this.props.discoverPodcastPageSubscribeEvent(podcast.uuid);
            this.props.subscribeToPodcast(podcast);
        }
    }

    onShare = () => {
        this.props.recordEvent('podcast_screen_share_tapped');
        this.props.openPodcastShare(this.props.podcast.uuid);
    };

    searchEpisodes = (term: string) => {
        this.props.searchEpisodes(term, this.props.uuid);
    };

    onSelectSortStrategy = (orderId: number) => {
        this.props.recordEvent('podcasts_screen_sort_order_changed', {
            sort_order:
                Object.entries(EpisodeSortOrder)
                    .filter(([, value]) => value === orderId)[0][0]
                    .toLowerCase() ?? 'unknown',
        });
        this.props.updateEpisodeOrder(this.props.uuid, orderId);
    };

    onHideArchived = () => {
        this.props.recordEvent('podcast_screen_toggle_archived', { show_archived: false });
        this.props.showArchivedEpisodes(this.props.uuid, false);
    };

    onShowArchived = () => {
        this.props.recordEvent('podcast_screen_toggle_archived', { show_archived: true });
        this.props.showArchivedEpisodes(this.props.uuid, true);
    };

    showArchiveAllConfirmation = () => {
        const { episodes, uuidToEpisodeSync, podcast } = this.props;

        const episodesToArchive = episodes.filter((episode: Episode) => {
            const episodeSync = uuidToEpisodeSync[episode.uuid];
            return episodeSync == null || !episodeSync.isDeleted;
        });

        const episodeUuidAndPodcastUuids = episodesToArchive.map((episode: Episode) => ({
            uuid: episode.uuid,
            podcast: podcast.uuid,
        }));

        this.props.showArchiveAllConfirmation(episodeUuidAndPodcastUuids);
    };

    onUnarchiveAll = () => {
        const { uuidToEpisodeSync, podcast, allEpisodes } = this.props;

        const episodesToUnarchive = allEpisodes.filter((episode: Episode) => {
            const episodeSync = uuidToEpisodeSync[episode.uuid];
            return episodeSync == null || episodeSync.isDeleted;
        });

        const episodeUuidAndPodcastUuids = episodesToUnarchive.map((episode: Episode) => ({
            uuid: episode.uuid,
            podcast: podcast.uuid,
        }));
        this.props.unarchiveAll(episodeUuidAndPodcastUuids);
    };

    onArchiveAllPlayed = () => {
        const { allEpisodes, uuidToEpisodeSync, podcast } = this.props;

        const episodesToArchive = allEpisodes.filter((episode: Episode) => {
            const episodeSync = uuidToEpisodeSync[episode.uuid];
            return (
                episodeSync &&
                episodeSync.playingStatus === PlayingStatus.COMPLETED &&
                !episodeSync.isDeleted
            );
        });

        const episodeUuidsAndPodcastUuids = episodesToArchive.map((episode: Episode) => ({
            uuid: episode.uuid,
            podcast: podcast.uuid,
        }));

        this.props.showArchiveAllConfirmation(episodeUuidsAndPodcastUuids, true);
    };

    onEpisodeClick = (episode: Episode) => {
        const episodeWithPodcast = { ...episode, podcastUuid: this.props.podcast.uuid };
        this.props.openEpisode(episodeWithPodcast, {
            eventSource: 'podcast_screen',
            autoplayConfig: { source: 'podcast', uuid: episodeWithPodcast.podcastUuid },
        });
    };

    searchInputChanged = ({ target: { value: newTerm } }: { target: { value: string } }) => {
        const trimmedTerm = newTerm.trim();

        if (trimmedTerm.length !== 1) {
            this.props.searchEpisodes(trimmedTerm, this.props.uuid);
        }

        this.setState({
            searchTerm: newTerm,
        });
    };

    clearSearch = () => {
        this.props.searchEpisodes('', this.props.uuid);

        this.setState({
            searchTerm: '',
        });
    };

    onPodcastSettingsClick(podcastUuid: string) {
        this.props.recordEvent('podcast_screen_settings_tapped');
        this.props.history.push(`/settings/podcast/${podcastUuid}`);
    }

    renderSearchIcon() {
        const { isSearching, isLoadingEpisodes } = this.props;
        const { searchTerm } = this.state;

        if (isSearching || isLoadingEpisodes) {
            return (
                <div className="loading-icon-wrapper">
                    <LoaderRound />
                </div>
            );
        }
        if (searchTerm) {
            return (
                <button id="clear-search-bar" onClick={this.clearSearch}>
                    <Icon id="cancel" />
                </button>
            );
        }

        return (
            <div className="icon-search-episodes">
                <Icon id="search" />
            </div>
        );
    }

    emptyMessage() {
        if (this.props.isLoadingEpisodes) {
            return '';
        }
        if (this.props.loadFailed) {
            return <FormattedMessage id="episodes-load-error" />;
        }
        if (!this.props.noEpisodes && !this.props.showArchived && !this.props.searchTerm) {
            return <FormattedMessage id="all-episodes-listened" />;
        }

        return <FormattedMessage id="no-episodes-found" />;
    }

    render() {
        const {
            intl,
            podcast,
            episodes,
            loadFailed,
            isSubscribed,
            isLoadingEpisodes,
            upNext,
            player,
            uuidToEpisodeSync,
            showArchived,
            episodeCount,
            archivedCount,
            theme,
            bookmarks,
            allArchived,
            anyPlayedButNotArchived,
        } = this.props;

        const loadingPlaceholder = intl.formatMessage({ id: 'loading-episodes' });
        const searchPlaceholder = intl.formatMessage({ id: 'search-episodes' });

        const isLoaded = podcast && !loadFailed;
        const color = this.props.color || '#03a9f4';

        // At this point, the isLoadingEpisodes prop is used to determine whether the remaining props are valid to
        // render everything as expected (without jank).
        return (
            <PagePodcast>
                <TrackOnMount event="podcast_screen_shown" />
                <Helmet>
                    <title>{podcast ? podcast.title : ''}</title>
                </Helmet>{' '}
                {loadFailed && <p>{<FormattedMessage id="podcast-load-error" />}</p>}
                {!podcast && !loadFailed && <LoaderSquare />}
                {isLoaded && (
                    <>
                        {isLoadingEpisodes && !episodes && <Loader color={color} />}

                        <PodcastPageHeader
                            {...this.props}
                            subscribeClick={this.subscribeClick.bind(this, podcast)}
                            onShare={this.onShare}
                        />

                        <EpisodeSummary>
                            <EpisodeCount count={episodeCount} />
                            &nbsp;&nbsp;•&nbsp;&nbsp;
                            <FormattedMessage
                                id="archived-count"
                                values={{
                                    count: archivedCount,
                                }}
                            />
                            &nbsp;&nbsp;•&nbsp;&nbsp;
                            <BookmarksButton
                                kind="text"
                                onClick={this.props.openBookmarks}
                                color={color}
                            >
                                <FormattedMessage
                                    id={
                                        bookmarks.length > 1
                                            ? 'bookmark-count-plural'
                                            : bookmarks.length === 1
                                            ? 'bookmark-count-singular'
                                            : 'bookmarks'
                                    }
                                    values={{
                                        count: bookmarks.length,
                                    }}
                                />
                            </BookmarksButton>
                        </EpisodeSummary>
                        <EpisodeRows>
                            <SearchBox>
                                {this.renderSearchIcon()}
                                <input
                                    className="episode-search-input"
                                    placeholder={
                                        isLoadingEpisodes ? loadingPlaceholder : searchPlaceholder
                                    }
                                    value={this.state.searchTerm}
                                    onChange={this.searchInputChanged}
                                    spellCheck="false"
                                />
                                <SortOptions
                                    id="sort-options-search-bar"
                                    color={color}
                                    onSelect={this.onSelectSortStrategy}
                                    selectedOption={this.props.episodeSortOrder}
                                    options={[
                                        {
                                            id: EpisodeSortOrder.NAME_A_TO_Z,
                                            label: intl.formatMessage({ id: 'alphabetical' }),
                                        },
                                        {
                                            id: EpisodeSortOrder.NAME_Z_TO_A,
                                            label: intl.formatMessage({
                                                id: 'alphabetical-reversed',
                                            }),
                                        },
                                        {
                                            id: EpisodeSortOrder.OLDEST_TO_NEWEST,
                                            label: intl.formatMessage({
                                                id: 'release-date-reversed',
                                            }),
                                        },
                                        {
                                            id: EpisodeSortOrder.NEWEST_TO_OLDEST,
                                            label: intl.formatMessage({ id: 'release-date' }),
                                        },
                                        {
                                            id: EpisodeSortOrder.LENGTH_DESC,
                                            label: intl.formatMessage({ id: 'duration-order' }),
                                        },
                                        {
                                            id: EpisodeSortOrder.LENGTH_ASC,
                                            label: intl.formatMessage({
                                                id: 'duration-reversed',
                                            }),
                                        },
                                    ]}
                                />
                                <EpisodesOptions
                                    id="episode-options-search-bar"
                                    color={color}
                                    showArchived={showArchived}
                                    onPodcastSettings={
                                        isSubscribed
                                            ? this.onPodcastSettingsClick.bind(this, podcast.uuid)
                                            : undefined
                                    }
                                    theme={theme}
                                    onHideArchived={this.onHideArchived}
                                    onShowArchived={this.onShowArchived}
                                    allArchived={allArchived}
                                    onArchiveAll={this.showArchiveAllConfirmation}
                                    onUnarchiveAll={this.onUnarchiveAll}
                                    onArchiveAllPlayed={this.onArchiveAllPlayed}
                                    playedAreAllArchived={!anyPlayedButNotArchived}
                                />
                            </SearchBox>
                            <EpisodesTable
                                onEpisodeClick={this.onEpisodeClick}
                                emptyMessage={this.emptyMessage()}
                                episodes={episodes}
                                uuidToEpisodeSync={uuidToEpisodeSync}
                                podcast={podcast}
                                sortEnabled={true}
                                showSort={false}
                                sortOrder={podcast.episodesSortOrder}
                                color={color}
                                playerEpisodeUuid={
                                    player && player.episode ? player.episode.uuid : null
                                }
                                isPlaying={player.isPlaying}
                                playerPlayedUpTo={
                                    player && player.episode ? player.episode.playedUpTo : 0
                                }
                                upNextEpisodes={upNext.episodes}
                                hoverColor={color}
                                showsArchived={showArchived}
                                showCompleted={true}
                                eventSource="podcast_screen"
                                autoplay={{ source: 'podcast', uuid: podcast.uuid }}
                            />
                        </EpisodeRows>
                    </>
                )}
            </PagePodcast>
        );
    }
}

function PodcastPageHeader({
    podcast,
    color,
    onShare,
    openRatingModal,
    subscribeClick,
    rating,
    recordEvent,
}: Props & { subscribeClick: () => void; onShare: () => void }) {
    const { title, author, description, url } = podcast;

    return (
        <PodcastInformation>
            <PodcastImageWrapper>
                <PodcastImage title={title} uuid={podcast?.uuid} borderRadius={8} />
            </PodcastImageWrapper>
            <PodcastDetails>
                <TitleAndActions>
                    <PodcastTitle>
                        {title}
                        <StyledShareButton color={color} onClick={onShare} />
                    </PodcastTitle>
                    <PodcastActionsWrapper>
                        <PodcastActions
                            podcastUuid={podcast.uuid}
                            onSubscribeClick={subscribeClick}
                        />
                    </PodcastActionsWrapper>
                </TitleAndActions>

                <PodcastMeta>
                    {USE_GIVE_RATINGS ? (
                        <>
                            <StarRating
                                rating={rating ? rating.average : 0}
                                reviewCount={rating ? rating.total : 0}
                            />
                            <RateButton kind="text" onClick={openRatingModal} color={color}>
                                <FormattedMessage id="rate" />
                            </RateButton>
                        </>
                    ) : (
                        <>
                            {rating && (
                                <StarRating
                                    rating={rating.average}
                                    reviewCount={rating.total}
                                    // Clicking the rating does nothing, and there's no indication it's clickable.
                                    // But we'll track clicks on it for now to see how often users expect they can
                                    // see more details or leave their own rating.
                                    onClick={() =>
                                        recordEvent('rating_stars_tapped', { uuid: podcast.uuid })
                                    }
                                />
                            )}
                        </>
                    )}

                    {podcast.category && (
                        <CategoryLinks
                            categories={podcast.category}
                            onClick={category =>
                                recordEvent('podcast_screen_category_tapped', {
                                    category: category.label,
                                })
                            }
                        />
                    )}
                </PodcastMeta>

                <PodcastAuthorSection>
                    {author && author !== '' && (
                        <TextWithIcon
                            text={author}
                            icon={
                                <svg
                                    width="17"
                                    height="16"
                                    viewBox="0 0 17 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M5.00001 3C5.00001 1.34315 6.34315 0 8.00001 0C9.65686 0 11 1.34315 11 3V7C11 8.65685 9.65686 10 8.00001 10C6.34315 10 5.00001 8.65685 5.00001 7V3ZM9.00001 3V7C9.00001 7.55228 8.55229 8 8.00001 8C7.44772 8 7.00001 7.55228 7.00001 7V3C7.00001 2.44772 7.44772 2 8.00001 2C8.55229 2 9.00001 2.44772 9.00001 3Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        d="M3.01565 8.08365C3.52174 7.86253 4.11126 8.09354 4.33238 8.59963C4.95054 10.0144 6.36128 11 7.99998 11C9.63868 11 11.0494 10.0144 11.6676 8.59963C11.8887 8.09354 12.4782 7.86253 12.9843 8.08365C13.4904 8.30477 13.7214 8.89428 13.5003 9.40037C12.7029 11.2254 11.0223 12.5789 8.99666 12.9176C8.99888 12.9448 9.00001 12.9723 9.00001 13V14H11C11.5523 14 12 14.4477 12 15C12 15.5523 11.5523 16 11 16H5.00001C4.44772 16 4.00001 15.5523 4.00001 15C4.00001 14.4477 4.44772 14 5.00001 14H7.00001V13C7.00001 12.9723 7.00114 12.9448 7.00336 12.9176C4.97765 12.5789 3.29705 11.2254 2.49967 9.40037C2.27855 8.89428 2.50957 8.30477 3.01565 8.08365Z"
                                        fill="currentColor"
                                    />
                                </svg>
                            }
                        />
                    )}

                    {url && (
                        <TextWithIcon
                            icon={
                                <svg
                                    width="17"
                                    height="16"
                                    viewBox="0 0 17 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M7.68159 4.11221C7.29689 4.4969 6.67318 4.4969 6.28849 4.11221C5.9038 3.72751 5.9038 3.1038 6.28849 2.71911L7.48257 1.52503C9.51595 -0.508344 12.8127 -0.508344 14.8461 1.52503C16.8794 3.55841 16.8794 6.85516 14.8461 8.88853L13.652 10.0826C13.2673 10.4673 12.6436 10.4673 12.2589 10.0826C11.8742 9.69792 11.8742 9.07421 12.2589 8.68952L13.453 7.49544C14.717 6.23145 14.717 4.18212 13.453 2.91813C12.189 1.65414 10.1397 1.65414 8.87567 2.91813L7.68159 4.11221ZM3.09021 5.91081C3.4749 5.52612 4.09861 5.52612 4.4833 5.91081C4.86799 6.29551 4.86799 6.91922 4.4833 7.30391L3.28922 8.49799C2.02523 9.76198 2.02523 11.8113 3.28922 13.0753C4.55321 14.3393 6.60254 14.3393 7.86653 13.0753L9.06061 11.8812C9.4453 11.4965 10.069 11.4965 10.4537 11.8812C10.8384 12.2659 10.8384 12.8896 10.4537 13.2743L9.25963 14.4684C7.22625 16.5018 3.9295 16.5018 1.89612 14.4684C-0.13725 12.435 -0.13725 9.13827 1.89612 7.10489L3.09021 5.91081ZM5.58787 9.39355C5.20318 9.77824 5.20318 10.402 5.58787 10.7866C5.97256 11.1713 6.59627 11.1713 6.98096 10.7866L11.1602 6.60736C11.5449 6.22267 11.5449 5.59896 11.1602 5.21427C10.7756 4.82957 10.1518 4.82957 9.76715 5.21426L5.58787 9.39355Z"
                                        fill="currentColor"
                                    />
                                </svg>
                            }
                            text={<FriendlyUrl url={url} color={color} />}
                        />
                    )}

                    {podcast.estimatedNextEpisodeAt && podcast.episodeFrequency && (
                        <NextEpisodeDetails>
                            <TextWithIcon
                                icon={
                                    <svg
                                        width="17"
                                        height="16"
                                        viewBox="0 0 17 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M5.95708 0C5.40479 0 4.95708 0.447715 4.95708 1C4.95708 1.55228 5.40479 2 5.95708 2H9.95708C10.5094 2 10.9571 1.55228 10.9571 1C10.9571 0.447715 10.5094 0 9.95708 0H5.95708ZM7.95708 3C4.36723 3 1.45708 5.91015 1.45708 9.5C1.45708 13.0899 4.36723 16 7.95708 16C11.5469 16 14.4571 13.0899 14.4571 9.5C14.4571 8.06589 13.9926 6.74025 13.206 5.66529L13.9675 4.90378C14.358 4.51325 14.358 3.88009 13.9675 3.48956C13.577 3.09904 12.9438 3.09904 12.5533 3.48956L11.7917 4.25109C10.7168 3.46443 9.39117 3 7.95708 3ZM3.45708 9.5C3.45708 7.01472 5.4718 5 7.95708 5C10.4424 5 12.4571 7.01472 12.4571 9.5C12.4571 11.9853 10.4424 14 7.95708 14C5.4718 14 3.45708 11.9853 3.45708 9.5ZM8.95708 7C8.95708 6.44772 8.50936 6 7.95708 6C7.40479 6 6.95708 6.44772 6.95708 7V10C6.95708 10.5523 7.40479 11 7.95708 11C8.50936 11 8.95708 10.5523 8.95708 10V7Z"
                                            fill="currentColor"
                                        />
                                    </svg>
                                }
                                text={
                                    <NextEpisodeText
                                        estimatedNextEpisodeAt={podcast.estimatedNextEpisodeAt}
                                        episodeFrequency={podcast.episodeFrequency}
                                    />
                                }
                            />
                        </NextEpisodeDetails>
                    )}
                </PodcastAuthorSection>
                <ReadMoreText
                    text={description}
                    lines={2}
                    color={color}
                    onExpand={() =>
                        recordEvent('podcast_screen_toggle_summary', {
                            is_expanded: true,
                        })
                    }
                />
            </PodcastDetails>
        </PodcastInformation>
    );
}

export default injectIntl(withAnalyticsContext(PodcastPage));
