import React, { useRef, KeyboardEvent, MouseEvent } from 'react';
import { DateText, DurationText, PodcastImage } from 'components';
import { EpisodeTypeText } from 'components/format/EpisodeTypeText';
import { PlayPauseButton } from 'components/PlayPauseButton';
import { onKeyDownEnter } from 'helper/UiHelper';
import { EpisodeType } from 'model/types';

import {
    Actions,
    Content,
    EpisodeTitle,
    Image,
    PlayButton,
    PodcastLink,
    Wrapper,
} from './EpisodeCardLarge.styled';

export type Props = {
    podcastUuid: string;
    podcastTitle: string;
    title: string;
    published: string;
    duration: number;
    season?: number;
    number?: number;
    type: EpisodeType;
    color?: string;
    isPlaying?: boolean;
    onClick: (evt: MouseEvent | KeyboardEvent) => void;
    onPlayPauseClick: (evt: MouseEvent) => void;
    onPodcastClick: (evt: MouseEvent | KeyboardEvent) => void;
};

const EpisodeCardLarge = ({
    podcastUuid,
    podcastTitle,
    title,
    published,
    duration,
    season,
    number,
    type,
    color,
    isPlaying = false,
    onClick,
    onPlayPauseClick,
    onPodcastClick,
}: Props) => {
    const podcastLink = useRef<HTMLAnchorElement>(null);
    const playPauseButton = useRef<HTMLButtonElement>(null);

    const handleClick = (evt: MouseEvent | KeyboardEvent) => {
        if (!(evt.target instanceof Element)) {
            return;
        }
        if (podcastLink.current?.contains(evt.target)) {
            return;
        }
        if (playPauseButton.current?.contains(evt.target)) {
            return;
        }
        onClick(evt);
    };

    return (
        <Wrapper
            onClick={handleClick}
            onKeyPress={event => onKeyDownEnter(event, handleClick)}
            role="button"
            tabIndex={0}
        >
            <Image>
                <PodcastImage uuid={podcastUuid} />
            </Image>
            <Content>
                <EpisodeTitle>{title}</EpisodeTitle>
                <PodcastLink
                    color={color}
                    ref={podcastLink}
                    onClick={onPodcastClick}
                    onKeyDown={event => onKeyDownEnter(event, onPodcastClick)}
                    tabIndex={0}
                >
                    {podcastTitle}
                </PodcastLink>
                <Actions>
                    <PlayButton>
                        <PlayPauseButton
                            color={color}
                            isPlaying={isPlaying}
                            onClick={onPlayPauseClick}
                            ref={playPauseButton}
                            text={
                                duration ? (
                                    <DurationText
                                        durationSecs={duration}
                                        playedUpToSecs={0}
                                        playingStatus={0}
                                    />
                                ) : (
                                    undefined
                                )
                            }
                        />
                    </PlayButton>

                    <EpisodeTypeText season={season} number={number} type={type} />
                    <DateText date={published} showToday={true} />
                </Actions>
            </Content>
        </Wrapper>
    );
};

export default EpisodeCardLarge;
