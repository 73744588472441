import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as fromUploadManagerActions from '../../../../../../redux/actions/upload-manager.actions';
import UploadingFileItem from './view';

UploadingFileItem.propTypes = {
    file: PropTypes.object.isRequired,
    uuid: PropTypes.string.isRequired,
};

const mapDispatchToProps = dispatch => ({
    cancelFileUpload: uuid =>
        dispatch(fromUploadManagerActions.Actions.abortFileInUploadManager(uuid)),
});

export default connect(null, mapDispatchToProps)(UploadingFileItem);
