import { Button } from 'components/Button';
import { Icon, IconId } from 'components/Icon';
import React, {
    FocusEventHandler,
    ForwardedRef,
    forwardRef,
    KeyboardEventHandler,
    useRef,
    useState,
} from 'react';
import { AuxIconWrapper, PrefixIcon, StyledInput, Wrapper } from './Input.styled';

export type InputStatus = 'ok' | 'error';

export type Props = {
    width?: number;
    icon?: IconId;
    status?: InputStatus;
    noAuxiliaryIcon?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

const iconMap: Record<string, IconId> = {
    password: 'key',
    email: 'mail',
    search: 'search',
};

const statusIconMap: Record<string, IconId> = {
    error: 'cancel-circle',
    ok: 'tick-circle',
};

export const Input = forwardRef<HTMLInputElement, Props>(
    (
        { width, icon, status, noAuxiliaryIcon, type = 'text', ...props }: Props,
        ref: ForwardedRef<HTMLInputElement>,
    ) => {
        const wrapperRef = useRef<HTMLLabelElement>(null);

        const [focused, setFocused] = useState<boolean>(false);
        const [showPassword, setShowPassword] = useState<boolean>(false);

        const handleFocus: FocusEventHandler<HTMLInputElement> = e => {
            props.onFocus && props.onFocus(e);
            setFocused(true);
        };
        const handleBlur: FocusEventHandler<HTMLInputElement> = e => {
            props.onBlur && props.onBlur(e);
            if (
                e.relatedTarget instanceof Element &&
                wrapperRef.current?.contains(e.relatedTarget)
            ) {
                // We're moving focus from one element in this component to another. Keep the overall component focused.
                return;
            }
            setFocused(false);
            setShowPassword(false);
        };

        const toggleShowPassword = () => setShowPassword(!showPassword);

        const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = event => {
            props.onKeyDown && props.onKeyDown(event);

            if (event.key === 'Escape') {
                event.preventDefault();
                wrapperRef.current?.querySelector<HTMLInputElement>('input')?.blur();
            }
        };

        const inputIcon = icon ?? iconMap[type];
        const statusIcon = !focused && !noAuxiliaryIcon && status && statusIconMap[status];

        return (
            <Wrapper focused={focused} status={status} ref={wrapperRef} width={width}>
                <PrefixIcon hasExplicitIcon={!!icon}>
                    {inputIcon && <Icon id={inputIcon} />}
                </PrefixIcon>

                <StyledInput
                    {...props}
                    type={type === 'password' && showPassword ? 'text' : type}
                    ref={ref}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                />

                {statusIcon && (
                    <AuxIconWrapper>
                        <Icon id={statusIcon} />
                    </AuxIconWrapper>
                )}

                {type === 'password' && !statusIcon && (
                    // Always insert the wrapper so that password manager icons don't jump around, but only show the button when the
                    // input is focused. This is aria-hidden because screen readers do not need to visually show/hide their password.
                    <AuxIconWrapper aria-hidden>
                        {focused && (
                            <Button
                                kind="text"
                                type="button"
                                onClick={() => {
                                    // If we toggle during the click event, the cursor is moved to the start of the
                                    // input. By delaying the toggle until the event callback completes, the cursor
                                    // does not jump at all.
                                    setTimeout(toggleShowPassword, 0);
                                }}
                                onMouseDown={e => {
                                    // Prevent focus from being stolen from the input at the start of a click
                                    e.preventDefault();
                                }}
                                onBlur={handleBlur}
                                onFocus={handleFocus}
                            >
                                <Icon id={showPassword ? 'eyeball-cross' : 'eyeball'} />
                            </Button>
                        )}
                    </AuxIconWrapper>
                )}
            </Wrapper>
        );
    },
);

export default Input;
