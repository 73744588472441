import * as fromFlagsActions from '../actions/flags.actions';

type FlagsState = {
    id: string;
    message: JSX.Element | string;
    persistent: boolean;
}[];

export const INITIAL_STATE = [];

export default (
    state: FlagsState = INITIAL_STATE,
    action: fromFlagsActions.Actions,
): FlagsState => {
    switch (action.type) {
        case fromFlagsActions.ActionTypes.FLAG_ADD: {
            return [
                ...state.slice(),
                {
                    id: action.payload.id,
                    message: action.payload.message,
                    persistent: action.payload.persistent,
                },
            ];
        }
        case fromFlagsActions.ActionTypes.FLAG_REMOVE: {
            return state.filter(flag => flag.id !== action.payload.id);
        }
        default:
            return state;
    }
};
