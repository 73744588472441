import styled from 'styled-components';
import { gridSize } from 'styles';

export const RowActionsStyled = styled.div`
    .action-buttons {
        .show-on-hover,
        .hide-on-hover {
            /**
             * These two elements exist in the same space but at different times, depending on whether
             * the user is hovering the row. If we use display: none to control visibility, the width
             * of the column will change whether you're hovering or not. Giving them height: 0 allows
             * them to exist in the same vertical space, but still take up the width they need. Controlling
             * display with visibility: hidden means they'll space out the parent cell correctly,
             * even when the user can't see them.
             */
            height: 0;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            & > * {
                margin-left: ${gridSize * 1.5}px;
            }
        }
    }
    .show-on-hover {
        visibility: hidden;
    }
`;
