import { ImageTextOption } from 'components/popup/ImageTextOption';
import { ImageTextOptionProps } from 'components/popup/ImageTextOption/ImageTextOption';
import React from 'react';

import { CategoryOptionWrapper } from './CategoryOption.styled';

type Props = {
    token: string;
};

function CategoryOption({ token, icon, text, onClick }: Props & ImageTextOptionProps) {
    return (
        <CategoryOptionWrapper token={token}>
            <ImageTextOption icon={icon} text={text} onClick={onClick} />
        </CategoryOptionWrapper>
    );
}

export default CategoryOption;
