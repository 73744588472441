import React from 'react';
import { RowActions } from 'components/RowActions';
import { RowButton } from 'components/RowButton';
import { red } from '../../../../styles';

const UploadedFileRowActions = ({
    isInUpNext,
    isPlayed,
    onPlayNextClick,
    onPlayLastClick,
    onRemoveFromUpNextClick,
    onMarkAsUnplayedClick,
    onMarkAsPlayedClick,
    onEditClick,
    onDownloadFileClick,
    onDeleteClick,
}) => {
    return (
        <RowActions>
            {/* Given that when the episode is not in upNext we have two actions (play-next and play-last),
                we need to have a placeholder button to keep the layout consistent when the episode *is* in upNext
                which only has one action. */}
            {isInUpNext && <RowButton label={'placeholder'} />}
            {isInUpNext && (
                <RowButton
                    icon="remove-from-upnext"
                    label="remove-up-next"
                    onClick={onRemoveFromUpNextClick}
                />
            )}
            {!isInUpNext && (
                <RowButton icon="play-next" label="play-next" onClick={onPlayNextClick} />
            )}
            {!isInUpNext && (
                <RowButton icon="play-last" label="play-last" onClick={onPlayLastClick} />
            )}
            {isPlayed ? (
                <RowButton
                    icon="mark-as-unplayed"
                    label="mark-as-unplayed"
                    onClick={onMarkAsUnplayedClick}
                />
            ) : (
                <RowButton
                    icon="mark-as-played"
                    label="mark-as-played"
                    onClick={onMarkAsPlayedClick}
                />
            )}
            <RowButton icon="edit" label="edit" onClick={onEditClick} />
            <RowButton icon="download" label="download-file" onClick={onDownloadFileClick} />
            <RowButton icon="delete" color={red} label="delete" onClick={onDeleteClick} />
        </RowActions>
    );
};

export default UploadedFileRowActions;
