// There's a few variations of episode types that are used in the app.
// Placing them in here is useful to help reduce/combine useless variations

import { Bookmark, UploadedFile } from '.';

// https://app.swaggerhub.com/apis-docs/Automattic/pocket-casts-show-notes-api/1.0.0#/PodcastResponse
export interface EpisodeShowNotes {
    uuid: string;
    title: string;
    url: string;
    published: string;
    showNotes: string;
    hash: string;
    modified: number;
    image?: string;
    chaptersUrl?: string;
    chapters?: EpisodeChapter[];
}

// https://app.swaggerhub.com/apis/pocketcasts/pocketcasts-sync-api/2.5.2#/podcasts/post_user_podcast_episode
export interface EpisodeSyncInfo {
    uuid: string;
    playingStatus: number;
    playedUpTo: number;
    isDeleted: boolean;
    starred: boolean;
    duration: number;
    bookmarks: Bookmark[];
}

export interface Episode {
    duration: number;
    file_size: number;
    file_type: string;
    number: number;
    published: string;
    season: number;
    title: string;
    type: string;
    url: string;
    uuid: string;
    podcastUuid: string;
}

export type EpisodeWithSyncInfo = Episode & EpisodeSyncInfo;
export type EpisodeWithImage = EpisodeWithSyncInfo & { imageUrl?: string };

export type AutoplayEpisode = EpisodeWithSyncInfo | UploadedFile | FilterEpisode;

export interface FilterEpisode {
    uuid: string;
    url: string;
    published: string;
    duration: number;
    fileType: string;
    title: string;
    size: string;
    playingStatus: number;
    playedUpTo: number;
    starred: boolean;
    podcastUuid: string;
    podcastTitle: string;
    episodeType: string;
    episodeSeason: number;
    episodeNumber: number;
    isDeleted: boolean;
}

export type RecommendedEpisode = FilterEpisode;

export interface FullEpisode extends Episode, EpisodeSyncInfo {}

export interface EpisodeChapter {
    startTime: number;
    title?: string;
    endTime?: number;
    img?: string;
    url?: string;
}

export enum EpisodeType {
    FULL = 'full',
    BONUS = 'bonus',
    TRAILER = 'trailer',
}
