import { connect } from 'react-redux';
import { RootState } from 'redux/reducers';
import { Dispatch } from 'redux';
import { normalisedUpdateIntlAction } from 'translations';
import * as fromUserActions from 'redux/actions/user.actions';
import LoggedOutPageChrome from './LoggedOutPageChrome';

const mapStateToProps = (state: RootState) => ({
    settings: state.settings,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    unAuthenticate: () => dispatch(fromUserActions.Actions.unAuthenticate()),
    updateIntl: (locale: string) => dispatch(normalisedUpdateIntlAction(locale)),
});

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(LoggedOutPageChrome);

export { ConnectedComponent as LoggedOutPageChrome };
