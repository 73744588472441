import { Button } from 'components/Button';
import styled from 'styled-components';
import { Typography, gridSize } from 'styles';

export const EpisodePopupToolbarContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    margin: 4px 32px 0 32px;
    height: 41px;
`;

export const PopupMenuBarContent = styled.div`
    ${Typography.Desktop['H70-B']};
    color: ${props => props.theme.tokens['primary-text-02']};
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid ${props => props.theme.tokens['primary-ui-05']};
    border-width: 1px 0;
    & > * {
        margin-right: ${2 * gridSize}px;
    }
`;

export const EpisodeActions = styled.div`
    margin: 0 0 0 auto;
    color: var(--tint-color);
    display: flex;
    justify-content: right;
    align-items: center;
    svg {
        display: inline-block;
    }
    a {
        color: inherit;
    }
`;

export const PlayButtonContainer = styled.button`
    justify-content: center;
    margin-right: 14px;
    cursor: pointer;
    svg {
        color: var(--tint-color);
        width: 46px;
        height: 46px;
        border-radius: 23px;
        opacity: 1;
    }
`;

export const BookmarksButton = styled(Button)`
    color: var(--tint-color);
    &:hover {
        opacity: 0.8;
    }
    svg {
        opacity: 1;
    }
`;
