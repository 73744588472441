import { SlidingModal } from 'components/SlidingModal';
import { TrackOnMount, TrackOnUnmount } from 'components/Tracks';
import { ChapterCount } from 'components/messages';
import useFormatMessage from 'hooks/useFormatMessage';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AutoSizer, List, ListRowProps } from 'react-virtualized';
import * as fromPlayerActions from 'redux/actions/player.actions';

import useTracks from 'hooks/useTracks';
import { ESCAPE_KEY_PRESSED_EVENT } from 'model/page';
import {
    getChaptersForPlayerEpisode,
    getDeselectedChapters,
    getPlayerEpisode,
} from 'redux/reducers/selectors';
import ChapterRow from './ChapterRow';

interface Props {
    isOpen: boolean;
}

export default function Chapters({ isOpen }: Props) {
    const dispatch = useDispatch();
    const formatMessage = useFormatMessage();

    const onClose = useCallback(() => dispatch(fromPlayerActions.Actions.closeChapters()), [
        dispatch,
    ]);

    const chapters = useSelector(getChaptersForPlayerEpisode);

    const playerEpisode = useSelector(getPlayerEpisode);

    const deselectedChapters = useSelector(getDeselectedChapters);

    const { recordEvent } = useTracks();

    useEffect(() => {
        if (!isOpen) {
            return () => null;
        }

        window.addEventListener(ESCAPE_KEY_PRESSED_EVENT, onClose);

        return () => {
            window.removeEventListener(ESCAPE_KEY_PRESSED_EVENT, onClose);
        };
    }, [isOpen, onClose]);

    const toggleSelectedChapter = async (chapterPosition: number, isSelected: boolean) => {
        const updatedDeselectedChapters = isSelected
            ? deselectedChapters.filter(chapter => chapter !== chapterPosition)
            : [...deselectedChapters, chapterPosition];

        const uniqueDeselectedChapters = Array.from(new Set(updatedDeselectedChapters));

        // Optimistically update the deselected chapters, so we don't have to wait for the API
        // response for this to have an effect both on the playback and the UI
        dispatch(fromPlayerActions.Actions.updateDeselectedChapters(uniqueDeselectedChapters));

        dispatch(
            fromPlayerActions.Actions.saveChaptersDeselection(
                playerEpisode.podcastUuid,
                playerEpisode.uuid,
                uniqueDeselectedChapters,
            ),
        );

        recordEvent(
            isSelected
                ? 'deselect_chapters_chapter_selected'
                : 'deselect_chapters_chapter_deselected',
            {
                episode_uuid: playerEpisode?.uuid,
                podcast_uuid: playerEpisode?.podcastUuid,
            },
        );
    };

    const rowRenderer = ({ index, key, style }: ListRowProps) => {
        return (
            <ChapterRow
                key={key}
                onSelectionChange={isSelected => toggleSelectedChapter(index, isSelected)}
                isSelected={!deselectedChapters.includes(index)}
                position={index}
                style={style}
                chapter={chapters[index]}
            />
        );
    };

    const toolbar = <ChapterCount count={chapters.length} />;
    return (
        <SlidingModal
            title={formatMessage('chapters')}
            isOpen={isOpen}
            onClose={onClose}
            toolbar={toolbar}
        >
            {isOpen && (
                <>
                    <TrackOnMount
                        event="chapters_shown"
                        properties={{
                            episode_uuid: playerEpisode.uuid,
                            podcast_uuid: playerEpisode.podcastUuid,
                        }}
                    />
                    <TrackOnUnmount
                        event="chapters_dismissed"
                        properties={{
                            episode_uuid: playerEpisode.uuid,
                            podcast_uuid: playerEpisode.podcastUuid,
                        }}
                    />
                    <AutoSizer>
                        {({ width, height }) => (
                            <List
                                disableAutoscroll={false}
                                distance={5}
                                lockAxis="y"
                                // Props for List (react-virtualized)
                                height={height}
                                width={width}
                                rowCount={chapters.length}
                                rowHeight={73}
                                rowRenderer={rowRenderer}
                                aria-label=""
                                role="list"
                                containerRole="list"
                                tabIndex={null} // react-virtualized
                            />
                        )}
                    </AutoSizer>
                </>
            )}
        </SlidingModal>
    );
}
