import { registerPath, startPath } from 'App';
import { LoaderSquare } from 'components';
import { Icon } from 'components/Icon';
import { SplitPlanSelector } from 'components/PlanSelector';
import { TrackOnMount, TrackOnUnmount } from 'components/Tracks';
import useFormatMessage from 'hooks/useFormatMessage';
import { usePaddleProducts } from 'hooks/usePaddleProducts';
import useResize from 'hooks/useResize';
import useTracks from 'hooks/useTracks';
import { PaddlePlan } from 'model/types';
import { FinePrintLinks } from 'pages/StartPage/StartPage';
import qs from 'query-string';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation } from 'react-router';
import { CloseButton, Text, Wrapper } from './ChoosePlanPage.styled';

function ChoosePlanPage() {
    const history = useHistory();
    const location = useLocation();
    const formatMessage = useFormatMessage();
    const products = usePaddleProducts();
    const { recordEvent } = useTracks();
    const { windowWidth } = useResize();

    const handleSubscribe = (plan: PaddlePlan) => {
        recordEvent('select_payment_frequency_next_button_tapped', { product: plan });
        const search = qs.stringify({ ...qs.parse(location.search), plan });
        history.push({ pathname: registerPath, search: `?${search}` });
    };

    const planNames: PaddlePlan[] = [
        'plus-monthly',
        'plus-yearly',
        'patron-monthly',
        'patron-yearly',
    ];
    const hasProducts = planNames.every(plan => products[plan]);

    if (!hasProducts) {
        return <LoaderSquare />;
    }

    return (
        <Wrapper>
            <TrackOnMount event="select_payment_frequency_shown" />
            <TrackOnUnmount event="select_payment_frequency_dismissed" />
            <Helmet>
                <title>{formatMessage('choose-a-plan')}</title>
            </Helmet>
            <Text>
                <h1>{formatMessage('everything-you-love')}</h1>
            </Text>
            <SplitPlanSelector
                products={products}
                onSubscribe={handleSubscribe}
                orientation={windowWidth > 800 ? 'horizontal' : 'vertical'}
            />
            <Text>
                <FinePrintLinks
                    onClick={link => recordEvent('select_payment_frequency_link_tapped', { link })}
                />
            </Text>
            <CloseButton to={{ pathname: startPath, search: location.search }}>
                <Icon id="cancel" aria-label={formatMessage('back')} />
            </CloseButton>
        </Wrapper>
    );
}

export default ChoosePlanPage;
