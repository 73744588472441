import React, { Component } from 'react';
import { calculatePartialRingPath } from '../../helper/SvgHelper';

type Props = {
    size: number;
    percent: number;
    color: string;
    ringStrokeWidth: number;
};

type StateProps = {
    millis: number;
};

export class DynamicPie extends Component<Props, StateProps> {
    static defaultProps = {
        ringStrokeWidth: 2,
    };

    constructor(props: Props) {
        super(props);

        this.state = {
            millis: new Date().getMilliseconds(),
        };
    }

    render() {
        const { size, percent, color, ringStrokeWidth } = this.props;
        const { millis } = this.state;

        const renderAdjustedPercent = percent >= 100 || Number.isNaN(percent) ? 99.99 : percent;

        const startX = size / 2;
        const startY = size / 2;
        const radius = size / 2;

        const x1 = startX;
        const y1 = 0;

        // We have to subtract 90 because we're starting at the top of the circle.
        const endAngle = (renderAdjustedPercent / 100) * 360 - 90;
        const largeArcFlag = endAngle > 90 ? 1 : 0;

        const x2 = startX + radius * Math.cos((Math.PI * endAngle) / 180);
        const y2 = startY + radius * Math.sin((Math.PI * endAngle) / 180);

        const path = `M${startX},${startY} L${x1},${y1} A${radius},${radius} 0 ${largeArcFlag},1 ${x2},${y2} z`;

        const ringPath = calculatePartialRingPath(
            size / 2,
            size / 2,
            size / 2 - ringStrokeWidth / 2,
            0,
            359.99,
        );

        // Linear gradients are global, so each different color needs its own
        // unique ID or it will bleed into other DynamicPies around the page.
        const gradientId = `dynamic_pie_linear_gradient_${color || 'plus'}_${millis}`;

        return (
            <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
                <linearGradient id={gradientId} x1="0" y1={size / 2} x2={size} y2={size / 2}>
                    <stop offset="0%" stopColor={color || '#fed745'} />
                    <stop offset="100%" stopColor={color || '#feb525'} />
                </linearGradient>
                <path
                    stroke={`url(#${gradientId})`}
                    d={ringPath}
                    fillRule="evenodd"
                    clipRule="evenodd"
                    strokeWidth={ringStrokeWidth}
                    fill="none"
                />
                <path fill={`url(#${gradientId})`} fillOpacity={color ? 0.5 : 1.0} d={path} />
            </svg>
        );
    }
}

export default DynamicPie;
