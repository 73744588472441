import styled from 'styled-components';
import { visuallyHidden } from 'styles';

export const CheckboxWrapper = styled.div`
    position: relative;
    box-sizing: border-box;

    input {
        ${visuallyHidden}
        position: absolute;
        height: 100%;
    }
`;

export const FakeCheckbox = styled.div`
    width: 24px;
    height: 24px;
    border: 2px solid ${props => props.theme.tokens['primary-icon-01']};
    border-radius: 50%;
    transition: border 0.075s ease-out, transform 0.075s ease-out;
    position: relative;
    svg {
        position: absolute;
        left: 50%;
        top: 50%;
        color: ${props => props.theme.tokens['primary-interactive-02']};
        transform: translate(-50%, -50%);
        display: none;
    }

    input:checked + & {
        border-width: 12px;
        svg {
            display: block;
        }
    }
`;
