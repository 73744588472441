import { MAX_PLAYBACK_SPEED, MIN_PLAYBACK_SPEED } from 'settings';

export const safeSpeed = (speed: number) => {
    // If the speed is not a number, return 1
    if (Number.isNaN(speed)) {
        return 1;
    }

    // if the speed is less than the minimum, return the minimum
    if (speed < MIN_PLAYBACK_SPEED) {
        return 1;
    }

    // if the speed is greater than the maximum, return the maximum
    if (speed > MAX_PLAYBACK_SPEED) {
        return MAX_PLAYBACK_SPEED;
    }

    // otherwise, return the speed
    return speed;
};
