import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RootState } from 'redux/reducers';
import * as fromUserActions from '../../../../redux/actions/user.actions';
import ChangeEmailModal from './ChangeEmailModal';

const mapStateToProps = (state: RootState) => ({
    requestInProgress: state.user.emailChangeResult.inProgress as boolean,
    requestSucceeded: state.user.emailChangeResult.success as boolean,
    responseMessage: state.user.emailChangeResult.message as string,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    onClose: () => {
        dispatch(fromUserActions.Actions.dismissChangeEmailModal());
        dispatch(fromUserActions.Actions.cancelEmailChange());
    },
    requestEmailChange: (newEmail: string, password: string) =>
        dispatch(fromUserActions.Actions.requestEmailChange(newEmail, password)),
});

const ChangeEmailModalComponent = connect(mapStateToProps, mapDispatchToProps)(ChangeEmailModal);
export { ChangeEmailModalComponent as ChangeEmailModal };
