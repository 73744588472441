import styled from 'styled-components';
import { fontFamilyRetinaNarrow } from '../../styles';

export const ErrorMessageWrapper = styled.div`
    color: ${props => props.theme.legacyScss['theme-text-primary']};
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title {
        font-family: ${fontFamilyRetinaNarrow};
        font-size: 24.88px;
        font-weight: 600;
        line-height: 40px;
        text-align: center;
    }
    .description {
        text-align: center;
        font-size: 17.28px;
        font-weight: 400;
        line-height: 200%;
    }

    .contact {
        color: ${props => props.theme.legacyScss['theme-text-secondary']};
    }
`;
