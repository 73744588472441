import { ChaptersIcon } from 'components/ChaptersIcon';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getChaptersForPlayerEpisode, getChaptersOpen } from 'redux/reducers/selectors';
import * as fromPlayerActions from '../../../../redux/actions/player.actions';
import * as fromUpNextActions from '../../../../redux/actions/up-next.actions';
import { SlideUpButton } from '../SlideUpButton';
import StyledChapterButtonWrapper from './ChaptersButton.styled';

export default function ChaptersButton() {
    const dispatch = useDispatch();

    const chapters = useSelector(getChaptersForPlayerEpisode);
    const isOpen = useSelector(getChaptersOpen);

    const onOpen = () => {
        dispatch(fromUpNextActions.Actions.closeUpNext());
        dispatch(fromPlayerActions.Actions.openChapters());
    };

    const onClose = () => {
        dispatch(fromPlayerActions.Actions.closeChapters());
    };

    if (!chapters.length) {
        return null;
    }

    return (
        <StyledChapterButtonWrapper>
            <SlideUpButton
                isOpen={isOpen}
                onOpen={onOpen}
                onClose={onClose}
                aria-label="Show Chapters"
                icon={<ChaptersIcon />}
            />
        </StyledChapterButtonWrapper>
    );
}
