import styled from 'styled-components';

export const LoaderWrapper = styled.div`
    position: relative;
    background: none;
    width: 30px;
    height: 30px;

    > div {
        width: 2.1px;
        height: 6px;
        border-radius: 1.2px;
        position: absolute;
    }

    > div:nth-of-type(1) {
        animation: uil-default-anim 0.8s linear infinite;
        animation-delay: -0.4s;
    }
    > div:nth-of-type(2) {
        animation: uil-default-anim 0.8s linear infinite;
        animation-delay: -0.333s;
    }
    > div:nth-of-type(3) {
        animation: uil-default-anim 0.8s linear infinite;
        animation-delay: -0.267s;
    }
    > div:nth-of-type(4) {
        animation: uil-default-anim 0.8s linear infinite;
        animation-delay: -0.199s;
    }
    > div:nth-of-type(5) {
        animation: uil-default-anim 0.8s linear infinite;
        animation-delay: -0.133s;
    }
    > div:nth-of-type(6) {
        animation: uil-default-anim 0.8s linear infinite;
        animation-delay: -0.066s;
    }
    > div:nth-of-type(7) {
        animation: uil-default-anim 0.8s linear infinite;
        animation-delay: 0s;
    }
    > div:nth-of-type(8) {
        animation: uil-default-anim 0.8s linear infinite;
        animation-delay: 0.066s;
    }
    > div:nth-of-type(9) {
        animation: uil-default-anim 0.8s linear infinite;
        animation-delay: 0.133s;
    }
    > div:nth-of-type(10) {
        animation: uil-default-anim 0.8s linear infinite;
        animation-delay: 0.199s;
    }
    > div:nth-of-type(11) {
        animation: uil-default-anim 0.8s linear infinite;
        animation-delay: 0.266s;
    }
    > div:nth-of-type(12) {
        animation: uil-default-anim 0.8s linear infinite;
        animation-delay: 0.333s;
    }
    @-webkit-keyframes uil-default-anim {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
    @keyframes uil-default-anim {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
`;
