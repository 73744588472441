import styled from 'styled-components';

export const CastButtonWrapper = styled.div`
    button {
        width: 24px;
        height: 24px;
        background-color: transparent;
        border: 0;
        cursor: pointer;
        --disconnected-color: rgba(255, 255, 255, 0.5);
        --connected-color: rgba(255, 255, 255, 1);
        &:hover {
            --connected-color: rgba(255, 255, 255, 1);
            --disconnected-color: rgba(255, 255, 255, 1);
        }
    }
`;
