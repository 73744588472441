import { SearchHistoryItem } from 'model/types';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as fromTracksActions from 'redux/actions/tracks.actions';
import { getSubscribedPodcastUuids } from 'redux/reducers/selectors';

// This is a convenience hook to make it simpler to dispatch Tracks recordEvent actions
const useTracks = () => {
    const dispatch = useDispatch();

    const subscribedPodcastUuids = useSelector(getSubscribedPodcastUuids);

    // Use a callback to ensure that `recordEvent` is always the same function instance. This
    // is important because it is used as a dependency in other hooks, and we don't want those
    // hooks to fire unnecessarily by returning a new function instance every render.
    const recordEvent = useCallback(
        (name: string, properties = {}) => {
            dispatch(fromTracksActions.Actions.recordEvent(name, properties));
        },
        [dispatch],
    );

    const recordSearchResultTapped = useCallback(
        (item: SearchHistoryItem) => {
            let type: string | undefined;
            let uuid: string | undefined;
            if ('podcast' in item) {
                uuid = item.podcast.uuid;
                type = subscribedPodcastUuids.includes(uuid)
                    ? 'podcast_local_result'
                    : 'podcast_remote_result';
            } else if ('episode' in item) {
                type = 'episode';
                uuid = item.episode.uuid;
            } else if ('folder' in item) {
                type = 'folder';
                uuid = item.folder.uuid;
            }
            if (type) {
                recordEvent('search_result_tapped', { result_type: type, uuid });
            }
        },
        [recordEvent, subscribedPodcastUuids],
    );

    return { recordEvent, recordSearchResultTapped };
};

export default useTracks;
