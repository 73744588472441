import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from 'redux/reducers';
import CancelSubscriptionModal from './CancelSubscriptionModal';
import * as fromSubscriptionActions from '../../../../redux/actions/subscription.actions';

const mapStateToProps = (state: RootState) => {
    const subscriptionData = (state.subscription.modalData.showCancelModal as {
        expiryDate: string;
        platform: number;
        cancelUrl: string;
        type: number;
    }) ?? {
        expiryDate: '',
        platform: 0,
        cancelUrl: '',
        type: 0,
        podcasts: [],
    };
    return {
        expiryDate: new Date(subscriptionData.expiryDate || ''),
        platform: subscriptionData.platform,
        subscriptionCancelUrl: subscriptionData.cancelUrl,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    onClose: () => dispatch(fromSubscriptionActions.Actions.hideModalGeneric('showCancelModal')),
    fetchSubscriptionDataAfterCancel: (hideFarewellModalAfterCancelling: boolean) =>
        dispatch(
            fromSubscriptionActions.Actions.fetchSubscriptionDataAfterCancel(
                hideFarewellModalAfterCancelling,
            ),
        ),
});

export default connect(mapStateToProps, mapDispatchToProps)(CancelSubscriptionModal);
