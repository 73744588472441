import { IconId } from 'components/Icon';
import { TranslatableStringKey } from 'translations/glotpress';

export type NavigationItem = {
    id: number;
    path: string;
    icon?: IconId;
    text: TranslatableStringKey;
};

export const NavigationItems: Record<string, NavigationItem> = {
    PODCASTS: {
        id: 1,
        path: '/podcasts',
        icon: 'grid-small',
        text: 'podcasts',
    },
    DISCOVER: {
        id: 2,
        path: '/discover',
        icon: 'discover',
        text: 'discover',
    },
    NEW_RELEASES: {
        id: 3,
        path: '/new-releases',
        icon: 'sparkle',
        text: 'new-releases',
    },
    IN_PROGRESS: {
        id: 4,
        path: '/in-progress',
        icon: 'in-progress',
        text: 'in-progress',
    },
    STARRED: {
        id: 5,
        path: '/starred',
        icon: 'star-full-small',
        text: 'starred',
    },
    BOOKMARKS: {
        id: 6,
        path: '/bookmarks',
        icon: 'bookmark',
        text: 'bookmarks',
    },
    HISTORY: {
        id: 7,
        path: '/history',
        icon: 'history',
        text: 'history',
    },
    SETTINGS: {
        id: 8,
        path: '/settings',
        text: 'settings',
    },
    PROFILE: {
        id: 9,
        path: '/profile',
        text: 'profile',
    },
    STATS: {
        id: 10,
        path: '/stats',
        text: 'stats',
    },
    UPLOADED_FILES: {
        id: 11,
        path: '/uploaded-files',
        icon: 'files',
        text: 'files',
    },
};

export const searchUrl = (term: string, expanded = false) =>
    `/search?q=${encodeURIComponent(term)}${expanded ? '&expanded=true' : ''}`;
