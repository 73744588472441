import classNames from 'classnames';
import { EpisodeImage } from 'components/EpisodeImage';
import { logCORSCheck } from 'helper/MCStatsHelper';
import { canUseKeyboardShortcuts } from 'helper/UiHelper';
import * as key from 'keymaster';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { DateText, DiscoverPlayer, NativeApp } from '../../../components';
import { LoaderSquare } from '../../../components/LoaderSquare';
import { VideoCollapseButton } from '../../../components/buttons/VideoCollapseButton';
import * as Browser from '../../../helper/Browser';
import { hexToRgb } from '../../../helper/ColorHelper';
import * as EpisodeHelper from '../../../helper/EpisodeHelper';
import { clearMediaSession, createGlobalMediaSession } from '../../../helper/MediaSessionHelper';
import { NavigationItems } from '../../../helper/NavigationHelper';
import { isUploadedFile } from '../../../model/uploaded-files';
import * as Settings from '../../../settings';
import { Accordion } from './Accordion';
import { AddBookmarkButton } from './AddBookmarkButton';
import { AnimatedPlayButton } from './AnimatedPlayButton';
import { CastButton } from './CastButton';
import { ChaptersButton } from './ChaptersButton';
import { MiniPlayer } from './MiniPlayer';
import { MoreActionsMenu } from './MoreActionsMenu';
import {
    PlayerActions,
    PlayerBackground,
    PlayerControlsGlobalStyles,
    PlayerControlsWrapper,
} from './PlayerControls.styled';
import Player from './Players/Player';
import { SeekBar } from './SeekBar';
import { SkipBackButton } from './SkipBackButton';
import { SkipForwardButton } from './SkipForwardButton';
import SpeedControl from './SpeedControl';
import { UpNextButton } from './UpNextButton';
import VolumeSlider from './VolumeSlider';

export const seekPlayerTo = (time, eventSource) =>
    document.dispatchEvent(new CustomEvent('player-seek', { detail: { time, eventSource } }));

export const playerPlay = eventSource =>
    document.dispatchEvent(new CustomEvent('player-play', { detail: { eventSource } }));

export const playerPause = eventSource =>
    document.dispatchEvent(new CustomEvent('player-pause', { detail: { eventSource } }));

export const playerPlayPause = eventSource =>
    document.dispatchEvent(new CustomEvent('player-play-pause', { detail: { eventSource } }));

export const playerSkipBack = eventSource =>
    document.dispatchEvent(new CustomEvent('player-skip-back', { detail: { eventSource } }));

export const playerSkipForward = eventSource =>
    document.dispatchEvent(new CustomEvent('player-skip-forward', { detail: { eventSource } }));

class PlayerControls extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userSeeking: false,
        };
    }

    addKeyboardShortcuts = () => {
        key('space', this.shortcutSpace);
        key('right', () => this.skipForwardClicked('keyboard_shortcut'));
        key('left', () => this.skipBackClicked('keyboard_shortcut'));
        key('u', this.shortcutU);
        key('m', this.shortcutM);
        key('e', this.shortcutE);

        // Keymaster hasn't been updated in 7 years and Firefox maps the + and = keys differently to other browsers.
        // https://github.com/madrobby/keymaster/pull/154
        if (Browser.isFirefox()) {
            document.addEventListener('keypress', this.firefoxPlusMinusCheck);
        } else {
            key('-, shift+-', this.shortcutMinus);
            key('=, shift+=', this.shortcutPlus);
        }

        // Ensure that global keyboard events are not triggered when the following are focused elements
        // getAttribute('role') may need to be split out into individual roles
        // eslint-disable-next-line no-import-assign
        key.filter = event => {
            if (!canUseKeyboardShortcuts()) {
                return false;
            }
            const element = event.target || event.srcElement;
            const { tagName } = element;
            return !(tagName === 'INPUT' || tagName === 'SELECT' || tagName === 'TEXTAREA');
        };
    };

    mediaControlsPlayClicked = () => {
        this.playClicked('desktop_media_controls');
    };

    mediaControlsPauseClicked = () => {
        this.pauseClicked('desktop_media_controls');
    };

    mediaControlsSkipForwardClicked = () => {
        this.skipForwardClicked('desktop_media_controls');
    };

    mediaControlsSkipBackClicked = () => {
        this.skipBackClicked('desktop_media_controls');
    };

    componentDidMount() {
        this.props.downloadRecommendations();

        this.addKeyboardShortcuts();

        document.addEventListener('media-controls-play', this.mediaControlsPlayClicked);
        document.addEventListener('media-controls-pause', this.mediaControlsPauseClicked);
        document.addEventListener(
            'media-controls-skip-forward',
            this.mediaControlsSkipForwardClicked,
        );
        document.addEventListener('media-controls-skip-back', this.mediaControlsSkipBackClicked);

        document.addEventListener('player-play', this.handlePlayerPlay);
        document.addEventListener('player-pause', this.handlePlayerPause);
        document.addEventListener('player-play-pause', this.handlePlayerPlayPause);
        document.addEventListener('player-seek', this.handlePlayerSeekEvent);
        document.addEventListener('player-skip-back', this.handlePlayerSkipBackEvent);
        document.addEventListener('player-skip-forward', this.handlePlayerSkipForwardEvent);

        const { episode, podcast } = this.props;
        if (podcast && episode && !(Browser.isWindowsApp() || Browser.isMacApp())) {
            createGlobalMediaSession({
                episode,
                podcast,
                onPlay: () => this.playClicked('media_session'),
                onPause: () => this.pauseClicked('media_session'),
                seekBackwards: () => this.skipBackClicked('media_session'),
                seekForward: () => this.skipForwardClicked('media_session'),
            });
        }
    }

    componentDidUpdate(prevProps) {
        const { episode, podcast, unplayableFile } = this.props;

        // We want to avoid having a media session created on every rerender and not
        // be created if using the Windows or Mac apps
        const episodeUuid = episode ? episode.uuid : null;
        const prevEpisodeUuid = prevProps.episode ? prevProps.episode.uuid : null;
        if (
            !unplayableFile &&
            episodeUuid !== prevEpisodeUuid &&
            !(Browser.isWindowsApp() || Browser.isMacApp())
        ) {
            if (podcast && episode) {
                createGlobalMediaSession({
                    episode,
                    podcast,
                    onPlay: () => this.playClicked('media_session'),
                    onPause: () => this.pauseClicked('media_session'),
                    seekBackwards: () => this.skipBackClicked('media_session'),
                    seekForward: () => this.skipForwardClicked('media_session'),
                });
            } else {
                clearMediaSession();
            }
        }
        if (episode?.url && episode.url !== prevProps.episode?.url) {
            logCORSCheck(episode.url);
        }
    }

    componentWillUnmount() {
        key.unbind('space');
        key.unbind('right');
        key.unbind('left');
        key.unbind('u');
        key.unbind('m');
        key.unbind('e');

        if (Browser.isFirefox()) {
            document.removeEventListener('keypress', this.firefoxPlusMinusCheck);
        } else {
            key.unbind('-');
            key.unbind('=');
        }

        document.removeEventListener('media-controls-play', this.mediaControlsPlayClicked);
        document.removeEventListener('media-controls-pause', this.mediaControlsPauseClicked);
        document.removeEventListener(
            'media-controls-skip-forward',
            this.mediaControlsSkipForwardClicked,
        );
        document.removeEventListener('media-controls-skip-back', this.mediaControlsSkipBackClicked);

        document.removeEventListener('player-seek', this.handlePlayerSeekEvent);
        document.removeEventListener('player-play', this.handlePlayerPlay);
        document.removeEventListener('player-pause', this.handlePlayerPause);
        document.removeEventListener('player-skip-back', this.handlePlayerSkipBackEvent);
        document.removeEventListener('player-skip-forward', this.handlePlayerSkipForwardEvent);
    }

    shortcutE = () => {
        this.toggleEpisodeModal('keyboard_shortcut');
    };

    shortcutU = () => {
        if (this.props.upNext.open) {
            this.props.closeUpNext();
        } else {
            this.props.openUpNext();
        }
    };

    shortcutM = () => {
        if (this.props.episode) {
            this.props.updateMuted(!this.props.muted);
        }
    };

    shortcutMinus = () => {
        if (this.props.episode) {
            this.speedControl.onMinusClicked();
        }
    };

    firefoxPlusMinusCheck = () => {
        if (key.isPressed(61)) {
            this.shortcutPlus();
        }
        if (key.isPressed(173)) {
            this.shortcutMinus();
        }
    };

    shortcutPlus = () => {
        if (this.props.episode) {
            this.speedControl.onPlusClicked();
        }
    };

    shortcutSpace = event => {
        const { episode, playing } = this.props;
        if (episode) {
            event.preventDefault();
            if (playing) {
                this.pauseClicked('keyboard_shortcut');
            } else {
                this.playClicked('keyboard_shortcut');
            }
        }
    };

    toggleEpisodeModal = eventSource => {
        const { episode, episodeOpen, autoplayConfig } = this.props;
        if (episodeOpen) {
            this.props.closeEpisode();
        } else {
            this.props.openEpisode(episode, eventSource, autoplayConfig);
        }
    };

    getPodcastOrFileLink = () => {
        const { episode } = this.props;
        if (isUploadedFile(episode)) {
            return NavigationItems.UPLOADED_FILES.path;
        }
        return `${NavigationItems.PODCASTS.path}/${episode.podcastUuid}`;
    };

    playClicked = eventSource => {
        const { episode, playing, autoplayConfig, unplayableFile, playerFailed } = this.props;

        if (unplayableFile) {
            playerFailed(<FormattedMessage id="error-on-play-file-not-uploaded" />);
            return;
        }

        if (!playing && episode) {
            this.props.playEpisode(episode.uuid, episode.podcastUuid, eventSource, autoplayConfig);
        }
    };

    pauseClicked = eventSource => {
        this.props.pause(eventSource);
    };

    // Browser/OS media control keys or keyboard shortcuts
    isEventSourceRemote = eventSource =>
        eventSource === 'keyboard_shortcut' || eventSource === 'media_session';

    skipBackClicked = eventSource => {
        const {
            episode,
            unplayableFile,
            playerFailed,
            chapters,
            chapterIndex,
            settings,
        } = this.props;
        const hasChapters = chapters && chapters.length > 0;

        if (unplayableFile) {
            playerFailed(<FormattedMessage id="error-on-play-file-not-uploaded" />);
            return;
        }

        if (episode) {
            if (
                settings.remoteSkipChapters &&
                this.isEventSourceRemote(eventSource) &&
                hasChapters
            ) {
                const prevChapter = chapters[chapterIndex - 1];
                if (prevChapter) {
                    this.backwardSkipChaptersAndSeekTo(prevChapter.startTime);
                    return;
                }
            }

            this.props.onSkipBackClicked?.({ eventSource, episode });
            this.player.skip(-this.props.settings.skipBack);
        }
    };

    skipForwardClicked = eventSource => {
        const {
            episode,
            unplayableFile,
            playerFailed,
            chapters,
            chapterIndex,
            settings,
        } = this.props;

        const hasChapters = chapters && chapters.length > 0;

        if (unplayableFile) {
            playerFailed(<FormattedMessage id="error-on-play-file-not-uploaded" />);
            return;
        }

        if (episode) {
            if (
                settings.remoteSkipChapters &&
                this.isEventSourceRemote(eventSource) &&
                hasChapters
            ) {
                const nextChapter = chapters[chapterIndex + 1];
                if (nextChapter) {
                    this.forwardSkipChaptersAndSeekTo(nextChapter.startTime);
                    return;
                }
            }

            this.props.onSkipForwardClicked?.({ eventSource, episode });
            this.player.skip(this.props.settings.skipForward);
        }
    };

    nextChapterClick = () => {
        const { chapters, chapterIndex, recordEvent } = this.props;
        recordEvent('player_next_chapter_tapped');
        const nextChapter = chapters[chapterIndex + 1];
        this.forwardSkipChaptersAndSeekTo?.(nextChapter.startTime);
    };

    previousChapterClick = () => {
        const { chapters, chapterIndex, recordEvent } = this.props;
        recordEvent('player_previous_chapter_tapped');
        const prevChapter = chapters[chapterIndex - 1];
        this.backwardSkipChaptersAndSeekTo?.(prevChapter.startTime);
    };

    onProgress = playedUpTo => {
        if (!this.state.userSeeking) {
            this.props.updatePlayedUpTo(this.props.episode?.uuid, playedUpTo);
            if (this.props.playing) {
                this.skipChaptersOnProgress(playedUpTo);
            }
        }
    };

    isCurrentlyPlayingChapterDeselected = playedUpTo => {
        return this.props.deselectedChapters?.includes(
            this.props.getCurrentPlayingChapterPosition(playedUpTo),
        );
    };

    skipChaptersOnProgress = playedUpTo => {
        // If the episode is almost finished, don't skip chapters
        if (this.props.episode?.duration - playedUpTo < 1) {
            return;
        }

        // Do not attempt to skip chapters if the current episode has no chapters
        if (!this.props.chapters || this.props.chapters.length === 0) {
            return;
        }

        // If the currently playing chapter should be skipped...
        if (this.isCurrentlyPlayingChapterDeselected(playedUpTo)) {
            this.forwardSkipChaptersAndSeekTo(playedUpTo);
        }
    };

    forwardSkipChaptersAndSeekTo(seekTo) {
        const {
            episode,
            recordEvent,
            getCurrentPlayingChapterPosition,
            deselectedChapters,
            chapters,
        } = this.props;

        // Find the next chapter that should be played (not skipped)
        const currentChapterIndex = getCurrentPlayingChapterPosition(seekTo);
        let nextChapterIndex = currentChapterIndex;
        while (
            deselectedChapters.includes(nextChapterIndex) &&
            nextChapterIndex < chapters.length
        ) {
            nextChapterIndex += 1;
        }

        // If we reached past the last chapger, skip to the end of the episode
        if (nextChapterIndex >= chapters.length) {
            // Skipping right to the edge of the episode triggers a bug in Chrome which causes the calculated
            // duration to change repeatedly, so here we skip to one second before the end
            return this.player.seekTo(episode?.duration - 1);
        }

        // Otherwise, skip to the start of the next chapter
        const nextChapterStartTime = chapters[nextChapterIndex].startTime;

        if (nextChapterIndex !== currentChapterIndex) {
            recordEvent('player_next_chapter_skipped', {
                content_type: EpisodeHelper.getContentType(episode),
            });
        }

        return this.player.seekTo(nextChapterStartTime);
    }

    backwardSkipChaptersAndSeekTo(seekTo) {
        const {
            getCurrentPlayingChapterPosition,
            chapters,
            deselectedChapters,
            episode,
            recordEvent,
        } = this.props;

        const currentChapterIndex = getCurrentPlayingChapterPosition(seekTo);

        // Find the previous chapter that should be played (not skipped)
        let prevChapterIndex = currentChapterIndex;

        while (prevChapterIndex > 0 && deselectedChapters.includes(prevChapterIndex)) {
            prevChapterIndex -= 1;
        }

        const prevChapterStartTime = chapters[prevChapterIndex].startTime;

        if (prevChapterIndex !== currentChapterIndex) {
            recordEvent('player_next_chapter_skipped', {
                content_type: EpisodeHelper.getContentType(episode),
            });
        }

        this.player.seekTo(prevChapterStartTime);
    }

    onSaveProgress = playedUpTo => {
        const { episode } = this.props;
        if (episode) {
            this.props.saveProgress(episode.uuid, episode.podcastUuid, playedUpTo);
        }
    };

    onDurationChanged = duration => {
        if (!this.props.episode) {
            return;
        }

        if (duration === 0 || (duration && isFinite(duration))) {
            this.props.updateDuration(this.props.episode, duration);
        }
    };

    onBuffering = buffering => {
        this.props.updateBuffering(buffering);
    };

    onEnded = () => {
        const { episode } = this.props;
        if (episode) {
            this.props.episodeFinished(episode, episode.podcastUuid);
        }
    };

    onError = errorMessage => {
        this.props.playerFailed(errorMessage);
    };

    onUserSeeking = time => {
        this.setState({ userSeeking: true });
        this.props.updatePlayedUpTo(this.props.episode?.uuid, time);
    };

    onChaptersFound = chapters => {
        this.props.updateChapters(chapters);
    };

    handlePlayerSeekEvent = event => {
        const { unplayableFile, playerFailed } = this.props;

        if (unplayableFile) {
            playerFailed(<FormattedMessage id="error-on-play-file-not-uploaded" />);
            return;
        }

        const { time, eventSource } = event.detail;
        this.onUserSeeked(time, eventSource);
    };

    handlePlayerPlay = event => {
        const { eventSource } = event.detail;
        this.playClicked(eventSource);
    };

    handlePlayerPause = event => {
        const { eventSource } = event.detail;
        this.pauseClicked(eventSource);
    };

    // This is used for the play/pause media session button, so we don't need to check if the episode is playing
    // and can just send a single event to the player.
    handlePlayerPlayPause = event => {
        const { eventSource } = event.detail;
        if (this.props.playing) {
            this.pauseClicked(eventSource);
        } else {
            this.playClicked(eventSource);
        }
    };

    handlePlayerSkipBackEvent = event => {
        const { eventSource } = event.detail;
        this.skipBackClicked(eventSource);
    };

    handlePlayerSkipForwardEvent = event => {
        const { eventSource } = event.detail;
        this.skipForwardClicked(eventSource);
    };

    onUserSeeked = (time, eventSource) => {
        const { episode, recordEvent } = this.props;

        // Avoid missing duration and divide-by-zero errors
        if (episode?.duration) {
            recordEvent('playback_seek', {
                seek_from_percent: Math.round((episode.playedUpTo / episode.duration) * 100),
                seek_to_percent: Math.round((time / episode.duration) * 100),
                source: eventSource,
            });
        }
        this.player.seekTo(time);
        this.props.updatePlayedUpTo(this.props.episode?.uuid, time);
        this.setState({ userSeeking: false });
    };

    onSpeedChanged = speed => {
        const {
            playbackEffects,
            episode: { podcastUuid },
        } = this.props;
        this.props.updateSpeed(podcastUuid, speed, playbackEffects);
        this.props.recordEvent('playback_effect_speed_changed', {
            speed,
            source: 'player_playback_effects',
        });
    };

    getEpisodeTitle = () => {
        const { episode, chapters, chapterIndex } = this.props;
        if (chapterIndex !== -1 && chapters && chapters.length > chapterIndex) {
            return chapters[chapterIndex].title;
        }
        if (episode && episode.title) {
            return episode.title;
        }
        return '';
    };

    getPlayerSubheadingLeftHandSide = () => {
        const { podcast, unplayableFile } = this.props;

        if (podcast && podcast.title) {
            return podcast.title;
        }

        return (
            <FormattedMessage
                id={unplayableFile ? 'error-on-play-file-not-uploaded' : 'uploaded-file'}
            />
        );
    };

    getPlayerSubheadingRightHandSide = () => {
        const { episode } = this.props;

        if (episode && episode.published) {
            return (
                <span>
                    {' - '}
                    <DateText date={episode && episode.published} />
                </span>
            );
        }

        return null;
    };

    render() {
        const {
            colorTint,
            upNext,
            webp,
            episode,
            videoMode,
            settings,
            isMacApp,
            performanceModeOn,
            recommendedEpisodes,
            recommendedEpisodesAreLoading,
            unplayableFile,
        } = this.props;

        const isCasting = settings.isCastConnected;
        const isRemote = isCasting || isMacApp;
        const isVideo = EpisodeHelper.isVideo(episode);
        const isSmallLocalVideo = isVideo && videoMode === 1 && !isRemote;
        const isLargeLocalVideo = isVideo && videoMode === 2 && !isRemote;

        const classes = classNames('player-controls', {
            video: isVideo && !isRemote,
            'video-small': isSmallLocalVideo,
            'video-large': isLargeLocalVideo,
        });

        const backgroundEpisode =
            episode || (recommendedEpisodes.length === 0 ? null : recommendedEpisodes[0]);

        const backgroundStyles = Browser.isWindows()
            ? { background: this.getBackgroundCss(backgroundEpisode) }
            : { backgroundImage: this.getBackgroundImageCss(backgroundEpisode, webp) };

        const shouldRenderActivePlayer = episode || !upNext.isLoaded || upNext.order.length > 0;
        const isLoading = !upNext.isLoaded && recommendedEpisodesAreLoading && !unplayableFile;
        return (
            <PlayerControlsWrapper className={classes} isUpNextOpen={upNext.open}>
                <PlayerControlsGlobalStyles />
                <PlayerBackground>
                    {performanceModeOn ? (
                        !isLoading && (
                            <div className="color-gradient" style={{ color: colorTint }}></div>
                        )
                    ) : (
                        <div className="player-gradient" style={backgroundStyles} />
                    )}
                </PlayerBackground>
                {shouldRenderActivePlayer && this.renderPlayer()}
                {shouldRenderActivePlayer && this.renderNativeAppIfNecessary()}
                {shouldRenderActivePlayer && this.renderControls(isLargeLocalVideo)}
                {!shouldRenderActivePlayer && this.renderDiscoverPlayer()}
                {isLoading && this.renderLoader()}
            </PlayerControlsWrapper>
        );
    }

    renderDiscoverPlayer() {
        return <DiscoverPlayer />;
    }

    renderLoader() {
        return (
            <div className="player-gradient-loader">
                <LoaderSquare color="#555" />
            </div>
        );
    }

    renderPlayer() {
        const {
            colorBackground,
            episode,
            podcast,
            skipForward,
            skipBack,
            updateVideoMode,
            updateVideoSize,
            playing,
            videoWidth,
            videoHeight,
            lastSeekTo,
            speed,
            theme,
            isMacApp,
            lastSentPlayedUpTo,
            lastSentPlayingStatus,
            muted,
            volume,
            videoMode,
            settings,
            addTotalListeningTime,
            addTimeSavedSkipping,
            addTimeSavedVariableSpeed,
            unplayableFile,
        } = this.props;

        const isCasting = settings.isCastConnected;
        const isVideo = EpisodeHelper.isVideo(episode);

        return (
            <Player
                ref={ref => {
                    this.player = ref;
                }}
                upNext={this.props.upNext}
                url={unplayableFile ? null : episode && episode.url}
                seekTo={lastSeekTo}
                speed={speed}
                theme={theme}
                playedUpTo={episode ? episode.playedUpTo : 0}
                lastSentPlayedUpTo={lastSentPlayedUpTo}
                lastSentPlayingStatus={lastSentPlayingStatus}
                playing={playing}
                muted={muted}
                volume={volume}
                isVideo={isVideo}
                videoMode={videoMode}
                videoWidth={videoWidth}
                videoHeight={videoHeight}
                isCastConnected={isCasting}
                isNativeApp={isMacApp}
                skipForward={skipForward}
                skipBack={skipBack}
                podcast={podcast}
                episode={episode}
                color={colorBackground}
                onProgress={this.onProgress}
                onPlay={this.playClicked}
                onPause={this.pauseClicked}
                onSkipForward={this.props.onSkipForwardClicked}
                onSkipBack={this.props.onSkipBackClicked}
                onEnded={this.onEnded}
                onError={this.onError}
                onSaveProgress={this.onSaveProgress}
                onBuffering={this.onBuffering}
                onChaptersFound={this.onChaptersFound}
                onDurationChanged={this.onDurationChanged}
                onVideoModeChanged={updateVideoMode.bind(this)}
                onVideoSizeChanged={updateVideoSize.bind(this)}
                addTotalListeningTime={addTotalListeningTime.bind(this)}
                addTimeSavedSkipping={addTimeSavedSkipping.bind(this)}
                addTimeSavedVariableSpeed={addTimeSavedVariableSpeed.bind(this)}
                onMuteChanged={this.props.updateMuted.bind(this)}
                onVolumeChanged={this.props.updateVolume.bind(this)}
                onSpeedChanged={this.onSpeedChanged}
            />
        );
    }

    renderNativeAppIfNecessary() {
        const { colorBackground, episode, podcast, playing } = this.props;

        return (
            <NativeApp
                episodeUuid={episode && episode.uuid}
                podcastUuid={podcast && podcast.uuid}
                episodeTitle={(episode && episode.title) || ''}
                podcastTitle={(podcast && podcast.title) || ''}
                isPlaying={playing}
                color={colorBackground}
                playedUpTo={episode ? episode.playedUpTo : 0}
                duration={episode ? episode.duration : -1}
            />
        );
    }

    getPlayerDescription = () => {
        const episodeTitle = this.getEpisodeTitle();
        if (this.props.playing) {
            return `${episodeTitle} Playing`;
        }
        return `${episodeTitle} Paused`;
    };

    renderControls(isLargeVideo) {
        const {
            episode,
            playing,
            settings,
            speed,
            chapters,
            chapterIndex,
            colorTint,
            muted,
            volume,
            buffering,
            isMacApp,
            isElectronApp,
            podcast,
            unplayableFile,
        } = this.props;

        if (!episode) {
            return null;
        }

        return (
            <div
                aria-label={`Player Controls (${this.getPlayerDescription()})`}
                tabIndex="-1"
                className="controls"
            >
                <div className="controls-left">
                    {this.renderImage(episode, podcast, isLargeVideo)}
                    <SkipBackButton
                        amount={settings.skipBack}
                        onSkipBackClicked={() => this.skipBackClicked('player')}
                    />
                    <AnimatedPlayButton
                        playing={playing}
                        onPlayClicked={() => this.playClicked('player')}
                        onPauseClicked={() => this.pauseClicked('player')}
                    />
                    <SkipForwardButton
                        amount={settings.skipForward}
                        onSkipForwardClicked={() => this.skipForwardClicked('player')}
                    />
                </div>
                <div className="controls-center">
                    <div className="episode">
                        <button
                            className="episode-title player_episode"
                            onClick={() => this.toggleEpisodeModal('player')}
                        >
                            {this.getEpisodeTitle()}
                        </button>
                    </div>
                    <div className="podcast-title-date">
                        <Link
                            className="podcast-title player_podcast_title"
                            to={this.getPodcastOrFileLink()}
                        >
                            {this.getPlayerSubheadingLeftHandSide()}
                        </Link>
                        {this.getPlayerSubheadingRightHandSide()}
                    </div>
                    {chapters &&
                        chapters.length > 0 &&
                        this.renderChapterButtons(chapters, chapterIndex)}
                    <SeekBar
                        disabled={unplayableFile}
                        color={colorTint}
                        buffering={buffering}
                        playedUpTo={episode && episode.playedUpTo ? episode.playedUpTo : 0}
                        duration={episode && episode.duration ? episode.duration : 0}
                        chapters={chapters}
                        onUserSeeking={time => this.onUserSeeking(time)}
                        onUserSeeked={time => this.onUserSeeked(time, 'player')}
                    />
                </div>
                <div
                    className={classNames('controls-right', {
                        large: window.cast && window.cast.framework,
                    })}
                >
                    <PlayerActions>
                        {Settings.USE_ACCORDION_EXPERIMENT && (
                            <Accordion
                                episodeUuid={episode.uuid}
                                podcastUuid={episode.podcastUuid}
                            />
                        )}
                        <AddBookmarkButton
                            time={episode.playedUpTo}
                            episodeUuid={episode.uuid}
                            podcastUuid={episode.podcastUuid}
                        />
                        <ChaptersButton />
                        {window.cast && window.cast.framework && <CastButton />}
                        <SpeedControl
                            ref={ref => {
                                this.speedControl = ref;
                            }}
                            speed={speed}
                            onSpeedChanged={this.onSpeedChanged}
                        />
                        <VolumeSlider
                            muted={muted}
                            volume={volume}
                            onVolumeChanged={this.props.updateVolume.bind(this)}
                            onMuteChanged={this.props.updateMuted.bind(this)}
                        />
                        <UpNextButton />
                        <MoreActionsMenu />
                        {(isMacApp || isElectronApp) && <MiniPlayer />}
                    </PlayerActions>
                </div>
            </div>
        );
    }

    renderImage(episode, podcast, showVideoCollapseButton) {
        const classes = classNames('player-image', { 'grow-on-hover': !showVideoCollapseButton });

        if (showVideoCollapseButton) {
            return (
                <div className={classes}>
                    <EpisodeImage episode={episode} />
                    <VideoCollapseButton key="video-collapse" />
                </div>
            );
        }

        return (
            <Link
                to={this.getPodcastOrFileLink()}
                className={classes}
                aria-label={`Open Podcast - ${podcast ? podcast.title : ''}`}
            >
                <EpisodeImage episode={episode} />
            </Link>
        );
    }

    getBackgroundImageCss(episode, webp) {
        if (episode) {
            // For files
            if (episode.imageUrl) {
                this.lastBackgroundImageCss = `url(${episode.imageUrl})`;
                return this.lastBackgroundImageCss;
            }

            this.lastBackgroundImageCss = `url(${Settings.STATIC_URL}/discover/images/${
                webp ? 'webp/480' : '400'
            }/${episode.podcastUuid}${webp ? '.webp' : '.jpg'})`;
            return this.lastBackgroundImageCss;
        }

        return this.lastBackgroundImageCss ? this.lastBackgroundImageCss : '';
    }

    getBackgroundCss(episode) {
        if (episode) {
            const rgb = hexToRgb(this.props.colorTint) || { r: 0, g: 0, b: 0 };
            const rgbString = `${rgb.r},${rgb.g},${rgb.b}`;
            this.lastBackgroundCss = `linear-gradient(to right, rgba(${rgbString}, 0.2), rgba(${rgbString}, 0.9) 40%, rgba(${rgbString}, 0.9) 60%, rgba(${rgbString}, 0.2))`;
            return this.lastBackgroundCss;
        }

        return this.lastBackgroundCss ? this.lastBackgroundCss : '';
    }

    renderChapterButtons(chapters, chapterIndex) {
        const previousStyles = classNames('chapter-previous', { enabled: chapterIndex > 0 });
        const nextStyles = classNames('chapter-next', {
            enabled: chapterIndex < chapters.length - 1,
        });
        return [
            <button
                className={previousStyles}
                onClick={this.previousChapterClick.bind(this)}
                key="previous-chapter"
            />,
            <button
                className={nextStyles}
                onClick={this.nextChapterClick.bind(this)}
                key="next-chapter"
            />,
        ];
    }
}

export default PlayerControls;
