import styled, { css } from 'styled-components';
import { gridSize, Typography } from '../../../styles';

export const DiscoverSectionWrapper = styled.section<{ discoverFormat: string }>`
    position: relative;
    padding-bottom: ${4 * gridSize}px;

    .responsive-grid {
        display: grid;
    }

    ${props =>
        props.discoverFormat === 'list' &&
        css`
            .responsive-grid {
                grid-auto-flow: column;
            }
        `};
`;

export const DiscoverSectionHeader = styled.div`
    display: flex;
    align-items: flex-start;
    width: 100%;
    h2 {
        ${Typography.Desktop.H20}
        flex: 1 1 auto;
        color: ${props => props.theme.tokens['primary-text-01']};
        margin-bottom: 32px;
    }
    .arrow-button {
        margin-top: 5px;
    }
`;

export const DiscoverSectionPlacement = styled.div`
    grid-column: 1 / -1;
    display: flex;
    flex-direction: column;
    gap: ${4 * gridSize}px;
`;
