import { isElectronApp } from 'helper/Browser';
import { EpisodeChapter } from 'model/types';
import * as fromPlayerActions from 'redux/actions/player.actions';
import { registerMiddleware } from 'redux/api';
import { getChaptersForPlayerEpisode, getPlayerEpisode } from 'redux/reducers/selectors';
import { getEpisodeShowNotes } from 'redux/reducers/selectors/episode-show-notes.selector';
import { api } from 'services/api';

/**
 * Whenever an episode is loaded into the player, or new show notes are fetched, let's check if
 * we need to fetch Podcast Index chapters from chaptersUrl.
 */
registerMiddleware(
    [fromPlayerActions.ActionTypes.LOAD_EPISODE, 'PODCAST_FETCH_SHOW_NOTES_SUCCEEDED'],
    async (action, store) => {
        const state = store.getState();

        if (getChaptersForPlayerEpisode(state).length > 0) {
            // Chapters were already fetched
            return;
        }

        const playerEpisode = getPlayerEpisode(state);

        // Get the chapter URL either from the just-fetched show notes, or the existing show notes
        const chaptersUrl =
            action.type === 'PODCAST_FETCH_SHOW_NOTES_SUCCEEDED'
                ? action.payload.episodeShowNotes.find(notes => notes.uuid === playerEpisode?.uuid)
                      ?.chaptersUrl
                : getEpisodeShowNotes(state, playerEpisode?.uuid)?.chaptersUrl;

        // If we have a chapters URL, let's fetch the chapters
        if (chaptersUrl) {
            const response = await fetch(chaptersUrl).then(
                res => res.json() as Promise<{ chapters: EpisodeChapter[] }>,
            );

            store.dispatch(fromPlayerActions.Actions.updateChapters(response.chapters));
        } else if (isElectronApp() && playerEpisode?.url) {
            // As a last resort, let's try to fetch the chapters from the episodes metadata server
            const metadata = await window.electron?.getMetadataForEpisodeUrl({
                url: playerEpisode.url,
            });

            if (metadata?.chapters?.length) {
                store.dispatch(fromPlayerActions.Actions.updateChapters(metadata.chapters));
            }
        }
    },
);

registerMiddleware([fromPlayerActions.ActionTypes.LOAD_EPISODE], async (action, store) => {
    const deselectedChapters =
        action.payload.episode.deselectedChapters
            ?.split(',')
            .filter(Boolean)
            .map(Number) || [];

    store.dispatch(fromPlayerActions.Actions.updateDeselectedChapters(deselectedChapters));
});

registerMiddleware([fromPlayerActions.ActionTypes.SAVE_CHAPTERS_DESELECTION], async action => {
    await api.saveDeselectedChapters(
        action.payload.podcastUuid,
        action.payload.episodeUuid,
        action.payload.deselectedChapters,
    );
});
