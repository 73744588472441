import React from 'react';
import useFormatMessage from 'hooks/useFormatMessage';
import PodcastImage from 'components/PodcastImage';
import { NavigationItems } from 'helper/NavigationHelper';
import { PodcastSearchResult } from 'model/types';
import { SearchResultItem } from '.';

type Props = {
    podcast: PodcastSearchResult;
    subscribed?: boolean;
    onClear?: () => void;
    onClick?: () => void;
};

const PodcastSearchResultItem = ({ onClear, onClick, podcast, subscribed }: Props) => {
    const formatMessage = useFormatMessage();
    const label = `${formatMessage('podcast')}. ${podcast.title}. ${podcast.author}`;
    return (
        <SearchResultItem
            image={<PodcastImage uuid={podcast.uuid} />}
            title={podcast.title}
            description={podcast.author}
            onClear={onClear}
            onClick={onClick}
            subscribed={subscribed}
            href={`${NavigationItems.PODCASTS.path}/${podcast.uuid}`}
            aria-label={label}
        />
    );
};

export default PodcastSearchResultItem;
