import { rgba } from 'polished';
import styled from 'styled-components';
import { H70 } from 'styles';

export const TermsAndConditionsWrapper = styled(H70)`
    a {
        color: ${props => rgba(props.theme.legacyScss['theme-text-primary'], 0.6)};
        text-decoration-color: ${props => rgba(props.theme.legacyScss['theme-text-primary'], 0.6)};
        text-decoration: underline;
    }
    font-weight: 400;
    font-size: 13px;
    color: ${props => rgba(props.theme.legacyScss['theme-text-primary'], 0.6)};
    opacity: 0.8;
`;
