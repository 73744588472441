import { choosePlanPath, registerPath, signInPath } from 'App';
import { Button } from 'components/Button';
import { ButtonLink } from 'components/ButtonLink';
import { TrackOnMount, TrackOnUnmount } from 'components/Tracks';
import { isMobile } from 'helper/Browser';
import useFormatMessage from 'hooks/useFormatMessage';
import useTracks from 'hooks/useTracks';
import { POCKET_CASTS_PLUS } from 'model/external-links';
import React, { ReactNode, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { isShowingLightTheme } from 'redux/reducers/selectors';
import { USE_PATRON } from 'settings';
import { DownloadModal, FinePrint, Heading, Logo, Separator, Wrapper } from './StartPage.styled';
import QRCodeSrcWhite from './mobile-download-qr-white.png';
import QRCodeSrc from './mobile-download-qr.png';

type Props = {
    podcastUuid?: string;
    podcastTitle?: string;
};

export const FinePrintLinks = ({ onClick }: { onClick?: (link: string) => void }) => {
    const formatMessage = useFormatMessage();
    const [showDownloadModal, setShowDownloadModal] = useState(false);
    const isLightTheme = useSelector(isShowingLightTheme);

    return (
        <>
            <FormattedMessage
                id="learn-more-or-listen-free"
                values={{
                    plusLink: (text: ReactNode) => (
                        <a
                            href={POCKET_CASTS_PLUS}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() => onClick?.('pocket_casts_plus')}
                        >
                            {text}
                        </a>
                    ),
                    mobileLink: (text: ReactNode) => (
                        <Button
                            kind="text"
                            onClick={() => {
                                onClick?.('listen_on_mobile');
                                if (isMobile()) {
                                    window.open('https://pocketcasts.com/get', '_blank');
                                } else {
                                    setShowDownloadModal(true);
                                }
                            }}
                        >
                            {text}
                        </Button>
                    ),
                }}
            />
            {showDownloadModal && (
                <DownloadModal
                    horizontalInsetPx={32}
                    onClose={() => setShowDownloadModal(false)}
                    title={formatMessage('download-pocket-casts-mobile')}
                    useModernStyle
                >
                    <img src={isLightTheme ? QRCodeSrc : QRCodeSrcWhite} alt="QR Code" />
                    {formatMessage('mobile-qr-instructions')}
                </DownloadModal>
            )}
        </>
    );
};

const StartPage = ({ podcastUuid, podcastTitle }: Props) => {
    const location = useLocation();
    const formatMessage = useFormatMessage();
    const { recordEvent } = useTracks();

    return (
        <Wrapper>
            <TrackOnMount event="setup_account_shown" />
            <TrackOnUnmount event="setup_account_dismissed" />
            <Helmet>
                <title>{formatMessage('welcome-to-pocket-casts')}</title>
            </Helmet>

            <Logo />
            <Heading>
                {podcastUuid
                    ? formatMessage('continue-listening-to', { title: podcastTitle ?? '...' })
                    : formatMessage('welcome-to-pocket-casts')}
            </Heading>
            <ButtonLink
                to={{
                    pathname: USE_PATRON ? choosePlanPath : registerPath,
                    search: location.search,
                }}
                kind="plus"
                onClick={() =>
                    recordEvent('setup_account_button_tapped', { button: 'create_account' })
                }
            >
                {formatMessage('subscribe-to-pocket-casts')}
            </ButtonLink>

            <FinePrint>
                <FinePrintLinks
                    onClick={link =>
                        recordEvent('setup_account_link_tapped', {
                            link,
                        })
                    }
                />
            </FinePrint>

            <Separator message="or" />

            <ButtonLink
                to={{ pathname: signInPath, search: location.search }}
                kind="secondary"
                onClick={() => recordEvent('setup_account_button_tapped', { button: 'sign_in' })}
            >
                {formatMessage('log-in')}
            </ButtonLink>
        </Wrapper>
    );
};

export default StartPage;
