import React, { Component } from 'react';
import UploadManagerHeader from './UploadManagerHeader';
import UploadManagerDetail from './UploadManagerDetail';
import { UploadManagerWrapper } from './styled';

export class UploadManager extends Component {
    constructor(props) {
        super(props);

        this.state = {
            collapsed: true,
        };
    }

    onCollapse = () => {
        this.setState({
            collapsed: true,
        });
    };

    onExpand = () => {
        this.setState({
            collapsed: false,
        });
    };

    render() {
        const { collapsed } = this.state;

        return (
            <UploadManagerWrapper>
                <UploadManagerHeader
                    collapsed={collapsed}
                    onCollapse={this.onCollapse}
                    onExpand={this.onExpand}
                />
                <UploadManagerDetail collapsed={collapsed} />
            </UploadManagerWrapper>
        );
    }
}

export default UploadManager;
