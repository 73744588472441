import styled from 'styled-components';
import { black, blue10, blue40, blue50, coolGrey70, gridSize, H30, H60 } from '../../../../styles';
import DropZoneIconBlue50 from '../../../../styles/borders/drop-zone-border-blue50.svg';
import DropZoneIconBlue40 from '../../../../styles/borders/drop-zone-border-blue40.svg';

export const EmptyStateBox = styled.div`
    display: block;
    width: 480px;
    height: 360px;

    border-radius: 4px;
    border: 2px dashed ${blue50};
    border-image: url(${DropZoneIconBlue50}) 7 round;
    background-color: ${props => props.theme.legacyScss['theme-background']};

    &:hover {
        background-color: ${blue10};
        border: 2px dashed ${blue40};
        border-image: url(${DropZoneIconBlue40}) 7 round;
        color: ${black};
    }

    transition: all 0.1s ease;
`;

export const EmptyStateWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const GiantUploadIconWrapper = styled.div`
    margin-top: ${12 * gridSize}px;
    margin-bottom: ${2 * gridSize}px;
    width: ${6 * gridSize}px;
    height: ${6 * gridSize}px;
    color: ${props => props.theme.tokens['primary-icon-01']};
`;

export const NoFilesHeadingWrapper = styled(H30)`
    padding-bottom: ${2 * gridSize}px;
`;

export const DropOrBrowseWrapper = styled(H60)`
    width: ${20 * gridSize}px;
    color: ${coolGrey70};
    text-align: center;
`;
