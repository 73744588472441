import React from 'react';

export const HourGlassIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.99984 2.5H14.9998C15.4601 2.5 15.8332 2.8731 15.8332 3.33333C15.8332 3.79357 15.4601 4.16667 14.9998 4.16667V5.83333C14.9998 7.57248 14.1119 9.10423 12.7647 10C14.1119 10.8958 14.9998 12.4275 14.9998 14.1667V15.8328C15.4601 15.8328 15.8332 16.2064 15.8332 16.6667C15.8332 17.1269 15.4601 17.5 14.9998 17.5H4.99984C4.5396 17.5 4.1665 17.1269 4.1665 16.6667C4.1665 16.2064 4.5396 15.8333 4.99984 15.8333V14.1667C4.99984 12.4275 5.88776 10.8958 7.23501 10C5.88776 9.10423 4.99984 7.57248 4.99984 5.83333V4.16667C4.5396 4.16667 4.1665 3.79357 4.1665 3.33333C4.1665 2.8731 4.5396 2.5 4.99984 2.5ZM6.6665 4.16667H13.3332V5.83333C13.3332 6.44048 13.1708 7.00971 12.8872 7.5H7.11244C6.82883 7.00971 6.6665 6.44048 6.6665 5.83333V4.16667ZM6.6665 14.1667C6.6665 12.3257 8.15889 10.8333 9.99984 10.8333C11.8408 10.8333 13.3332 12.3257 13.3332 14.1667V15.8328L12.4393 15.8333L10.5097 14.3408C10.2094 14.1086 9.79023 14.1086 9.49 14.3408L7.56035 15.8333H6.6665V14.1667Z"
                fill="currentColor"
            />
        </svg>
    );
};

export const CalendarIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.4083 14.1583C10.0083 14.1583 9.68333 13.825 9.68333 13.4333V10.3583L9.125 10.925C9.01667 11.025 8.875 11.0916 8.70833 11.0916C8.39167 11.0916 8.125 10.8333 8.125 10.5083C8.125 10.3416 8.2 10.1916 8.3 10.0833L9.70833 8.69162C9.85833 8.54995 10.0667 8.44995 10.2917 8.44995C10.75 8.44995 11.125 8.82495 11.125 9.28328V13.4333C11.125 13.825 10.8 14.1583 10.4083 14.1583Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.8333 2.5H4.16667C3.24619 2.5 2.5 3.24619 2.5 4.16667V15.8333C2.5 16.7538 3.24619 17.5 4.16667 17.5H15.8333C16.7538 17.5 17.5 16.7538 17.5 15.8333V4.16667C17.5 3.24619 16.7538 2.5 15.8333 2.5ZM4.16667 15.8333V6.66667H15.8333V15.8333H4.16667Z"
                fill="currentColor"
            />
        </svg>
    );
};
