import React from 'react';
import useFormatMessage from 'hooks/useFormatMessage';
import GreyFile from './grey-file.png';
import {
    ImageUploadProgressBoxWrapper,
    BackgroundImage,
    TextOverlay,
    InfoText,
    ErrorText,
    LinkText,
} from './styled';

const ImageUploadProgressBox = ({ errorIfExists, onCancel, shouldShowCancelButton }) => {
    const formatMessage = useFormatMessage();
    return (
        <ImageUploadProgressBoxWrapper>
            <BackgroundImage width={200} height={200} src={GreyFile} />
            <TextOverlay>
                <InfoText>&nbsp;</InfoText>
                {errorIfExists ? (
                    <ErrorText>{errorIfExists}</ErrorText>
                ) : (
                    <InfoText>{formatMessage('uploading-new-image')}</InfoText>
                )}
                {shouldShowCancelButton ? (
                    <button onClick={onCancel}>
                        <LinkText>{formatMessage('cancel')}</LinkText>
                    </button>
                ) : (
                    <button>
                        <LinkText>&nbsp;</LinkText>
                    </button>
                )}
            </TextOverlay>
        </ImageUploadProgressBoxWrapper>
    );
};

export default ImageUploadProgressBox;
