import { EpisodeSyncInfo, UpNextEpisode, UpNextState } from 'model/types';
import { createAction, ActionsUnion } from './action-creators';
import { EpisodeTracksProperties } from './podcast.actions';

export enum ActionTypes {
    UP_NEXT_CHANGED = '@up-next/UP_NEXT_CHANGED',
    UP_NEXT_DOWNLOAD_FAILED = '@up-next/UP_NEXT_DOWNLOAD_FAILED',
    OPEN_UP_NEXT = '@up-next/OPEN_UP_NEXT',
    CLOSE_UP_NEXT = '@up-next/CLOSE_UP_NEXT',
    PLAY_EPISODE_NOW = '@up-next/PLAY_EPISODE_NOW',
    PLAY_EPISODE_NEXT = '@up-next/PLAY_EPISODE_NEXT',
    PLAY_EPISODE_LAST = '@up-next/PLAY_EPISODE_LAST',
    REMOVE_EPISODE_FROM_UP_NEXT = '@up-next/REMOVE_EPISODE_FROM_UP_NEXT',
    MOVE_UP_NEXT_EPISODE = '@up-next/MOVE_UP_NEXT_EPISODE',
    CLEAR_UP_NEXT = '@up-next/CLEAR_UP_NEXT',
    UP_NEXT_SET_FILE_DETAILS = '@up-next/UP_NEXT_SET_FILE_DETAILS',
}

export const Actions = {
    openUpNext: () => createAction(ActionTypes.OPEN_UP_NEXT),

    closeUpNext: () => createAction(ActionTypes.CLOSE_UP_NEXT),

    upNextDownloadFailed: () => createAction(ActionTypes.UP_NEXT_DOWNLOAD_FAILED),

    upNextChanged: (upNext: Partial<UpNextState> & { episodeSync?: EpisodeSyncInfo[] }) =>
        createAction(ActionTypes.UP_NEXT_CHANGED, { data: upNext }),

    upNextPlayNext: (
        podcastUuid: string,
        episode: UpNextEpisode,
        tracksProperties: EpisodeTracksProperties,
    ) => createAction(ActionTypes.PLAY_EPISODE_NEXT, { podcastUuid, episode, tracksProperties }),

    upNextPlayNow: (podcastUuid: string, episode: UpNextEpisode) =>
        createAction(ActionTypes.PLAY_EPISODE_NOW, { podcastUuid, episode }),

    upNextPlayLast: (
        podcastUuid: string,
        episode: UpNextEpisode,
        tracksProperties: EpisodeTracksProperties,
    ) => createAction(ActionTypes.PLAY_EPISODE_LAST, { podcastUuid, episode, tracksProperties }),

    removeFromUpNext: (episodeUuid: string, tracksProperties: EpisodeTracksProperties) =>
        createAction(ActionTypes.REMOVE_EPISODE_FROM_UP_NEXT, { episodeUuid, tracksProperties }),

    moveUpNextEpisode: (oldIndex: number, newIndex: number) =>
        createAction(ActionTypes.MOVE_UP_NEXT_EPISODE, { oldIndex, newIndex }),

    clearUpNext: (episodeUuids: string[]) =>
        createAction(ActionTypes.CLEAR_UP_NEXT, { episodeUuids }),

    setUpNextFileDetails: (fileUuid: string, title: string, imageUrl: string, exists?: boolean) =>
        createAction(ActionTypes.UP_NEXT_SET_FILE_DETAILS, { fileUuid, title, imageUrl, exists }),
};

export type Actions = ActionsUnion<typeof Actions>;
