import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { gridSize } from 'styles';
import { ModernStyle } from 'styles/ModernStyle.styled';

export const Wrapper = styled(ModernStyle)`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: ${3 * gridSize}px;
    max-width: 600px;
    overflow: auto;
`;

export const LogoLink = styled(Link)`
    margin-bottom: ${8 * gridSize}px;
    color: ${props => props.theme.tokens['primary-text-01']};
`;
