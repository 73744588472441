import { signInPath } from 'App';
import { LoaderSquare } from 'components';
import { getPlatformName } from 'helper/Browser';
import { Base64 } from 'js-base64';
import JwtDecode from 'jwt-decode';
import { OAuthTokenJWT } from 'model/types';
import qs from 'query-string';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import * as fromUserActions from 'redux/actions/user.actions';
import { RootState } from 'redux/reducers';
import { hasFreeSubscription, hasPaidSubscription } from 'redux/reducers/selectors';
import { getOAuthLoginConfig } from 'services/auth';
import { OAuthOpenInApp } from './OAuthOpenInApp';

// The Mac App requires the OAuth flow to redirect to a custom URL scheme, to signify that
// authentication is done. So after the user lands on this page, if the request originated
// from the Mac App, we'll redirect from https://play.pocketcasts.com/oauth_login#...
// to pcweb-oauth-callback://play.pocketcasts.com/oauth_login#... and that will
// trigger the Swift code to take back over and process the auth.
export const MAC_APP_OAUTH_CALLBACK_URL_SCHEME = 'pcweb-oauth-callback';
// The Electron App also redirects to a custom URL scheme, to signify that
// authentication is done and the request can be processed by the Electron App.
export const ELECTRON_APP_SCHEME = 'pocket-casts';

const MaybeOAuthLoginPage = () => {
    const { state } = qs.parse(window.location.hash);
    const originPlatform =
        typeof state === 'string' ? JSON.parse(Base64.decode(state)).platform : '';

    // If the request originated from the Electron App, and this is not currently the Electron App,
    // that means we kicked off a request from the app to the browser and now need to be sent back.
    if (originPlatform === 'Electron App' && originPlatform !== getPlatformName()) {
        const redirectionUrl = new URL(window.location.href);
        window.location.href = `${ELECTRON_APP_SCHEME}://${redirectionUrl.host}${redirectionUrl.pathname}${redirectionUrl.search}${redirectionUrl.hash}`;
        return <OAuthOpenInApp />;
    }

    // If the request originated from the Mac App, and this is not currently the Mac App, we're probably
    // inside the ASWebAuthenticationSession. We'll redirect from https://... to oauth-callback://...
    // which signals to the Mac Wrapper that OAuth has completed and the callback can be executed.
    if (originPlatform === 'Mac App' && originPlatform !== getPlatformName()) {
        const redirectionUrl = new URL(window.location.href);
        window.location.href = `${MAC_APP_OAUTH_CALLBACK_URL_SCHEME}://${redirectionUrl.host}${redirectionUrl.pathname}${redirectionUrl.search}${redirectionUrl.hash}`;
        return null;
    }

    return <OAuthLoginPage />;
};

const OAuthLoginPage = () => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const { errorMessage } = useSelector((state: RootState) => state.user);
    const isFreeUser = useSelector(hasFreeSubscription);

    const isSubscriber = useSelector(hasPaidSubscription);

    const config = useMemo(getOAuthLoginConfig, []);

    const { id_token: idToken } = qs.parse(window.location.hash);

    useEffect(() => {
        // Bail if there doesn't seem to be an OAuth flow in-progress
        if (!config || typeof idToken !== 'string') {
            history.replace('/');
            return;
        }

        // Remove the hash params sent from the OAuth provider for security. Also add the
        // ?redirect query param, so on successful signin the page goes to the right place.
        history.replace(
            qs.stringifyUrl({
                url: location.pathname,
                query: { redirect: config.redirect || config.source },
            }),
        );

        try {
            // Decode the JWT to verify that it came from the original request
            const jwt = JwtDecode<OAuthTokenJWT>(idToken);
            if (config.nonce !== jwt?.nonce) {
                history.replace('/');
                return;
            }

            dispatch(fromUserActions.Actions.signInWithOAuth(config.provider, idToken));
        } catch {
            history.replace('/');
        }
    }, []);

    /**
     * If there was an error or this is an unpaid user, go back to the source page (Login or Register).
     * There the user will be prompted to upgrade, or be shown the error.
     */
    useEffect(() => {
        if (isFreeUser || errorMessage) {
            history.replace(config?.source ?? signInPath);
        }
    }, [isFreeUser, errorMessage]);

    /**
     * As soon as the user is authenticated, redirect them to their destination
     */
    useEffect(() => {
        if (isSubscriber) {
            history.replace(
                qs.stringifyUrl({
                    url: '/',
                    query: { redirect: config?.redirect || config?.source },
                }),
            );
        }
    }, [isSubscriber, history, config]);

    return <LoaderSquare />;
};

export default MaybeOAuthLoginPage;
