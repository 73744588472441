import { EpisodeSyncInfo } from 'model/types';
import { getSettings } from 'redux/reducers/selectors';
import { Logger } from './Logger';

export function playbackPaused(episode: EpisodeSyncInfo) {
    localStorage.setItem('lastPausedEpisodeUuid', episode.uuid);
    localStorage.setItem('lastPausedAt', episode.playedUpTo.toString());
    localStorage.setItem('lastPauseTime', new Date().getTime().toString());
}

export function adjustStartTimeIfNeeded(
    episode: EpisodeSyncInfo,
    settings: ReturnType<typeof getSettings>,
) {
    const lastPausedEpisodeUuid = localStorage.getItem('lastPausedEpisodeUuid');
    const lastPausedAt = Math.floor(Number(localStorage.getItem('lastPausedAt') ?? '0'));
    const lastPauseTime = localStorage.getItem('lastPauseTime');
    const episodePlayedUpTo = Math.floor(episode.playedUpTo);

    if (
        !settings.intelligentResumption ||
        lastPausedEpisodeUuid !== episode.uuid ||
        Math.abs(lastPausedAt - episodePlayedUpTo) > 1
    ) {
        return episode.playedUpTo;
    }

    if (lastPauseTime === null) {
        return episode.playedUpTo;
    }

    const lastPauseTimeMs = Number(lastPauseTime);

    const TWENTY_FOUR_HOURS = 24 * 60 * 60 * 1000;
    const ONE_HOUR = 60 * 60 * 1000;
    const FIVE_MINUTES = 5 * 60 * 1000;

    if (Date.now() - lastPauseTimeMs > TWENTY_FOUR_HOURS) {
        Logger.log('More than 24 hours since this episode was paused, jumping back 30 seconds');
        const seekTo = episode.playedUpTo - 30;
        return seekTo > 0 ? seekTo : 0;
    }

    if (Date.now() - lastPauseTimeMs > ONE_HOUR) {
        Logger.log('More than 1 hour since this episode was paused, jumping back 15 seconds');
        const seekTo = episode.playedUpTo - 15;
        return seekTo > 0 ? seekTo : 0;
    }

    if (Date.now() - lastPauseTimeMs > FIVE_MINUTES) {
        Logger.log('More than 5 minutes since this episode was paused, jumping back 10 seconds');
        const seekTo = episode.playedUpTo - 10;
        return seekTo > 0 ? seekTo : 0;
    }

    Logger.log(
        'Not enough time passed since this episode was last paused, no time adjustment required',
    );

    return episode.playedUpTo;
}
