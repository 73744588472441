import {
    excludeCountriesFromInfoOffers,
    USE_EXCLUDE_COUNTRIES_FROM_OFFER_DISCOUNTS,
} from 'settings';

export const isInfoOfferAvailableForCountry = (countryCode: string) => {
    // If this feature flag is enabled, all countries are eligible.
    if (!USE_EXCLUDE_COUNTRIES_FROM_OFFER_DISCOUNTS) {
        return true;
    }

    if (!countryCode) {
        return false;
    }

    return excludeCountriesFromInfoOffers.indexOf(countryCode) === -1;
};
