import Beta from 'components/Logo/Beta';
import { BetaLogoWrapper } from 'components/Logo/Beta.styled';
import { TrackOnMount } from 'components/Tracks';
import { getElectronAppVersion, isElectronApp, isWindows } from 'helper/Browser';
import useFormatMessage from 'hooks/useFormatMessage';
import useIsBeta from 'hooks/useIsBeta';
import React from 'react';
import { connect } from 'react-redux';
import { Separator } from '../../../components/Separator';
import { RootState } from '../../../redux/reducers';
import ReleaseNotesHtml from '../../../release-notes.html';
import * as Settings from '../../../settings';
import {
    AboutLogo,
    SettingsAboutWrapper,
    StyledBeta,
    VersionSubtitle,
} from './SettingsAbout.styled';

const SettingsAbout = () => {
    const [betaFeaturesEnabled] = useIsBeta();
    const formatMessage = useFormatMessage();
    const platform = isWindows() ? 'Windows' : 'Mac';

    return (
        <SettingsAboutWrapper>
            <TrackOnMount event="settings_about_shown" />
            <BetaLogoWrapper>
                <AboutLogo>
                    <svg
                        width="272"
                        height="54"
                        viewBox="0 0 272 54"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M79.4904 28.3032C82.5984 28.3032 84.1524 26.6232 84.1524 23.0522C84.1524 19.3972 82.5984 18.0112 79.5744 18.0112H78.0614V28.3032H79.4904ZM71.5934 42.0392V12.6342H79.5744C86.7574 12.6342 90.6224 16.0372 90.6224 22.8422C90.6224 30.4452 86.0434 33.5962 79.7424 33.5962H78.1034V42.0392H71.5934Z"
                            fill="currentColor"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M100.112 24.9844C98.2215 24.9844 97.0465 27.0014 97.0465 31.1174C97.0465 35.2764 98.2635 37.3344 100.196 37.3344C102.086 37.3344 103.304 35.2764 103.304 31.1594C103.304 27.0014 102.044 24.9844 100.112 24.9844ZM100.154 42.4594C95.1975 42.4594 91.2485 38.6364 91.2485 31.2014C91.2485 23.6824 95.2395 19.8174 100.196 19.8174C105.111 19.8174 109.101 23.6824 109.101 31.1174C109.101 38.5944 105.111 42.4594 100.154 42.4594Z"
                            fill="currentColor"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M119.81 42.3755C113.971 42.3755 110.526 38.3005 110.526 31.2015C110.526 24.2705 114.055 19.9015 120.146 19.9015C122.079 19.9015 123.548 20.2795 124.977 20.8255L124.052 26.1185C123.044 25.6565 121.869 25.2785 120.65 25.2785C117.835 25.2785 116.366 27.7145 116.366 31.1175C116.366 34.8145 117.835 36.9985 120.734 36.9985C121.827 36.9985 122.96 36.6625 124.178 36.2005L125.103 41.2835C123.632 41.9135 121.701 42.3755 119.81 42.3755Z"
                            fill="currentColor"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M133.374 31.6216V42.0396H127.283V11.7516H133.374V29.6896L138.331 20.2796H145.01L139.087 30.6976L145.178 42.0396H138.415L133.374 31.6216Z"
                            fill="currentColor"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M155.971 29.6895C155.845 26.4125 154.878 24.4805 153.031 24.4805C151.14 24.4805 150.174 26.4965 150.047 29.6895H155.971ZM144.376 31.2435C144.376 23.7665 148.2 19.8175 153.031 19.8175C158.155 19.8175 161.6 23.7245 161.6 31.3695V33.3445H150.047C150.258 36.3265 151.896 37.7965 154.584 37.7965C156.181 37.7965 158.071 37.2925 159.793 36.4945L160.886 40.7795C158.785 41.8295 156.307 42.3755 153.954 42.3755C148.619 42.3755 144.376 39.5615 144.376 31.2435Z"
                            fill="currentColor"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M164.872 35.5283V25.2783H162.519V20.2793H164.872V14.5673H170.921V20.2793H175.542V25.2783H170.921V34.6463C170.921 36.4523 171.888 37.2083 173.525 37.2083C174.24 37.2083 174.87 37.0823 175.542 36.9143V41.8713C174.618 42.1653 173.315 42.3333 171.804 42.3333C167.183 42.3333 164.872 40.4433 164.872 35.5283Z"
                            fill="currentColor"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M195.995 42.3755C188.349 42.3755 183.56 37.5025 183.56 27.3785C183.56 17.6335 188.475 12.2985 196.037 12.2985C198.558 12.2985 200.658 12.8025 202.296 13.4325L201.288 19.3975C199.986 18.8515 198.474 18.3475 196.667 18.3475C192.635 18.3475 190.24 21.5405 190.24 27.2945C190.24 33.2175 192.509 36.3265 196.793 36.3265C198.432 36.3265 199.986 35.9065 201.33 35.3185L202.38 41.1155C200.574 41.8715 198.348 42.3755 195.995 42.3755Z"
                            fill="currentColor"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M214.391 37.419V32.252C213.803 32.126 213.131 32.084 212.417 32.084C211.157 32.084 210.443 32.336 209.939 32.798C209.477 33.302 209.224 34.058 209.224 35.15C209.224 37.335 210.191 38.217 211.87 38.217C212.711 38.217 213.593 37.881 214.391 37.419ZM209.35 42.334C205.948 42.334 203.553 40.149 203.553 35.444C203.553 33.344 204.142 31.58 205.276 30.487C206.494 29.227 208.3 28.597 211.031 28.597C212.207 28.597 213.383 28.765 214.391 28.975V27.757C214.391 25.909 213.551 24.9 211.283 24.9C209.519 24.9 207.628 25.405 205.948 26.119L204.814 21.498C206.998 20.616 209.561 19.944 212.375 19.944C217.878 19.944 220.357 22.296 220.357 27.295V38.175L220.819 42.04H215.526L214.77 40.149C213.173 41.536 211.619 42.334 209.35 42.334Z"
                            fill="currentColor"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M230.476 42.4175C227.536 42.4175 224.805 41.6615 222.453 40.4015L223.671 35.4865C225.982 36.7465 228.291 37.5025 230.392 37.5025C231.947 37.5025 232.787 36.8305 232.787 35.9065C232.787 34.9825 232.283 34.5625 229.342 33.3865C225.267 31.7475 223.251 30.1935 223.251 26.5385C223.251 22.5055 226.024 19.9015 230.896 19.9015C233.374 19.9015 235.643 20.4895 237.702 21.4985L236.483 26.4125C234.593 25.4465 232.661 24.8165 230.896 24.8165C229.594 24.8165 228.88 25.3625 228.88 26.2445C228.88 27.0845 229.342 27.5045 232.241 28.6815C236.82 30.4875 238.415 32.1675 238.415 35.6125C238.415 40.1495 235.223 42.4175 230.476 42.4175Z"
                            fill="currentColor"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M241.899 35.5283V25.2783H239.546V20.2793H241.899V14.5673H247.948V20.2793H252.569V25.2783H247.948V34.6463C247.948 36.4523 248.915 37.2083 250.552 37.2083C251.267 37.2083 251.897 37.0823 252.569 36.9143V41.8713C251.645 42.1653 250.342 42.3333 248.831 42.3333C244.21 42.3333 241.899 40.4433 241.899 35.5283Z"
                            fill="currentColor"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M262.437 42.4175C259.497 42.4175 256.766 41.6615 254.414 40.4015L255.632 35.4865C257.943 36.7465 260.252 37.5025 262.353 37.5025C263.908 37.5025 264.748 36.8305 264.748 35.9065C264.748 34.9825 264.244 34.5625 261.303 33.3865C257.228 31.7475 255.212 30.1935 255.212 26.5385C255.212 22.5055 257.985 19.9015 262.857 19.9015C265.335 19.9015 267.604 20.4895 269.663 21.4985L268.444 26.4125C266.554 25.4465 264.622 24.8165 262.857 24.8165C261.555 24.8165 260.841 25.3625 260.841 26.2445C260.841 27.0845 261.303 27.5045 264.202 28.6815C268.781 30.4875 270.376 32.1675 270.376 35.6125C270.376 40.1495 267.184 42.4175 262.437 42.4175Z"
                            fill="currentColor"
                        />
                        <g clipPath="url(#clip0)">
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M54.2502 27C54.2502 41.912 42.1622 54 27.2502 54C12.3382 54 0.250183 41.912 0.250183 27C0.250183 12.088 12.3382 0 27.2502 0C42.1622 0 54.2502 12.088 54.2502 27Z"
                                fill="#F43E37"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M6.25018 27C6.25018 15.402 15.6522 6 27.2502 6C38.8482 6 48.2502 15.402 48.2502 27H43.0002C43.0002 18.3015 35.9487 11.25 27.2502 11.25C18.5517 11.25 11.5002 18.3015 11.5002 27C11.5002 35.6985 18.5517 42.75 27.2502 42.75V48C15.6522 48 6.25018 38.598 6.25018 27ZM27.2502 39.6C20.2914 39.6 14.6502 33.9588 14.6502 27C14.6502 20.0412 20.2914 14.4 27.2502 14.4C34.209 14.4 39.8502 20.0412 39.8502 27H35.2684C35.2684 22.5717 31.6785 18.9818 27.2502 18.9818C22.8219 18.9818 19.232 22.5717 19.232 27C19.232 31.4283 22.8219 35.0182 27.2502 35.0182V39.6Z"
                                fill="white"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0">
                                <rect
                                    width="54"
                                    height="54"
                                    fill="white"
                                    transform="translate(0.250183)"
                                />
                            </clipPath>
                        </defs>
                    </svg>
                </AboutLogo>
                <StyledBeta>{betaFeaturesEnabled && <Beta />}</StyledBeta>
            </BetaLogoWrapper>
            <VersionSubtitle>
                {formatMessage('web-player-version')} {Settings.VERSION}
                {isElectronApp() &&
                    ` - ${formatMessage(
                        'desktop-version',
                    )} ${getElectronAppVersion()} (${platform})`}
            </VersionSubtitle>
            <Separator />

            {/* Inject the existing release notes into the page. This is all static content. */}
            <div
                className="about-content"
                 
                dangerouslySetInnerHTML={{ __html: ReleaseNotesHtml }}
            />
        </SettingsAboutWrapper>
    );
};

const mapStateToProps = (state: RootState) => ({
    theme: state.settings.theme,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsAbout);
