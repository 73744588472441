import { LOCAL_STORAGE_KEY_CORS_CHECKED_URLS } from 'model/local-storage';
import { DEPLOYMENT_ENV } from 'settings';

export enum MCStatsGroup {
    CORS_CHECK = 'pc-web-cors-check',
    ERRORS = 'pocket-casts-web-player-errors',
    AUDIO_VIDEO_ERRORS = 'pocket-casts-web-player-av-errors',
    PODCAST_PROMO_SECONDS_PLAYED = 'pc-podcast-promo-seconds-played',
}

// Stats are viewable at https://mc.a8c.com/s/[group]
export const bumpStat = (group: MCStatsGroup, name: string, value?: string) => {
    // Only gather stats in production environment
    if (DEPLOYMENT_ENV !== 'production') {
        console.info('bumpStat', group, name, value);
        return;
    }

    if (value) {
        new Image().src = `https://pixel.wp.com/b.gif?v=wpcom-no-pv&x_${group}/${name}=${value}&t=${Math.random()}`;
        return;
    }

    new Image().src = `https://pixel.wp.com/b.gif?v=wpcom-no-pv&x_${group}=${name}&t=${Math.random()}`;
};

/**
 * Check whether a given URL has CORS restrictions and log the result to MC Stats. Each URL is only tested once.
 *
 * This is a temporary stat, used to determine feasibility of features that require JS access to media files.
 */
export const logCORSCheck = (url: string) => {
    const checkedUrls: string[] = JSON.parse(
        localStorage.getItem(LOCAL_STORAGE_KEY_CORS_CHECKED_URLS) || '[]',
    );

    if (checkedUrls.includes(url)) {
        // This URL has already been tested. Move on!
        return;
    }

    // Make a HEAD request to save data. We only care whether the fetch works or not.
    fetch(url, { method: 'HEAD' })
        .then(response => {
            // We got a response, which means there's no CORS in play! But it still could have been an HTTP error...
            bumpStat(MCStatsGroup.CORS_CHECK, response.ok ? 'accessible' : 'http-error');
        })
        .catch(() => {
            // The request couldn't complete, but the reason is intentionally obscured. So we can't use code to
            // tell whether it's a CORS issue, a network issue, etc. However we can make an "opaque" request for
            // the same URL to bypass CORS restrictions — if that works we know the URL is behind a CORS wall.
            fetch(url, { method: 'HEAD', mode: 'no-cors' })
                .then(() => {
                    // The opaque request succeeded, so the initial failure was because of CORS. Log it.
                    bumpStat(MCStatsGroup.CORS_CHECK, 'cors-restricted');
                })
                .catch(() => {
                    bumpStat(MCStatsGroup.CORS_CHECK, 'other-error');
                });
        });

    // Mark this URL as tested, so this user doesn't check it again
    checkedUrls.unshift(url);
    // Only keep the latest 100 URLs so this doesn't grow uncontrollably
    checkedUrls.splice(100);
    localStorage.setItem(LOCAL_STORAGE_KEY_CORS_CHECKED_URLS, JSON.stringify(checkedUrls));
};
