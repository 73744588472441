import { css, keyframes } from 'styled-components';

export const hoverStateTransitionMixin = css`
    transition: all 0.1s ease;
`;

export const fadeInKeyFrames = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;
