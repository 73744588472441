import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import { isDarkTheme } from '../../../../model/theme';
import ProfileImageDark from './profile-dark.svg';
import ProfileImageLight from './profile.svg';

interface Props {
    asSvgImage?: boolean;
    width?: number;
    height?: number;
}

function SimpleAccountIcon({ asSvgImage, width = 30, height = 30 }: Props) {
    const { theme } = useSelector((state: RootState) => ({
        theme: state.settings.theme,
    }));

    const ProfileImage = isDarkTheme(theme) ? ProfileImageDark : ProfileImageLight;

    if (asSvgImage) {
        return <image width={width} height={height} href={ProfileImage} />;
    }

    return <img width={width} height={height} alt="Profile" src={ProfileImage} />;
}

export default SimpleAccountIcon;
