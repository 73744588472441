import OnMount from 'components/OnMount/OnMount';
import { DiscoverCategoriesNav } from 'components/discover/DiscoverCategoriesNav';
import useFormatMessage from 'hooks/useFormatMessage';
import useTracks from 'hooks/useTracks';
import {
    DiscoverCategory,
    DiscoverExpandedStyle,
    DiscoverLayout,
    DiscoverSummaryStyle,
    ListData,
    PodcastDiscover,
} from 'model/types';
import React, { MutableRefObject, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { InView } from 'react-intersection-observer';
import { RouteComponentProps } from 'react-router';
import {
    DiscoverCarousel,
    DiscoverPodcast,
    DiscoverSection,
    DiscoverSingle,
    DiscoverSingleEpisode,
    Header,
    Separator,
} from '../../components';
import { LoaderSquare } from '../../components/LoaderSquare';
import { ContentRegionSelector } from '../../components/discover/ContentRegionSelector';
import { DiscoverCategories } from '../../components/discover/DiscoverCategories';
import { DiscoverCollection } from '../../components/discover/DiscoverCollection';
import { useAnalyticsContext } from '../../context/AnalyticsContext';
import { NavigationItems } from '../../helper/NavigationHelper';
import { useScrollRestoration } from '../../hooks/useScrollRestoration';
import { DiscoverPageItemWrapper, DiscoverPageWrapper } from './DiscoverPage.styled';

type Props = {
    getContentJson: () => void;
    contentSpecLayout: DiscoverLayout[];
    region?: string;
    isLoading: boolean;
} & RouteComponentProps;

function DiscoverPage(props: Props) {
    const { getContentJson, history, contentSpecLayout, region, isLoading } = props;
    const formatMessage = useFormatMessage();
    const { recordEvent } = useTracks();
    const {
        discoverOpenEvent,
        discoverEpisodePlayEvent,
        discoverEpisodeTapEvent,
        discoverPodcastTapEvent,
        discoverPodcastSubscribeEvent,
        discoverShowAllClickedEvent,
        discoverListImpressionEvent,
        discoverWebOpenEvent,
    } = useAnalyticsContext();

    const [contentMounted, setContentMounted] = React.useState(false);

    const isReady = !isLoading && contentMounted;

    const { updateScrollPosition, pageRef } = useScrollRestoration(isReady);

    useEffect(() => {
        if (region != null) {
            getContentJson();
        }
    }, [region]);

    useEffect(() => {
        discoverWebOpenEvent();
        discoverOpenEvent();
        recordEvent('discover_shown');
    }, []);

    const episodeClicked = (podcastUuid: string, episodeUuid: string, curatedListId?: string) => {
        if (!curatedListId) {
            return;
        }

        history.replace(history.location.pathname, {
            curatedListId,
        });

        discoverEpisodeTapEvent(curatedListId, podcastUuid, episodeUuid);
    };

    const episodePlayed = (podcastUuid: string, episodeUuid: string, curatedListId?: string) => {
        if (!curatedListId) {
            return;
        }
        discoverEpisodePlayEvent(podcastUuid, curatedListId);
    };

    const podcastClicked = useCallback((podcast: { uuid: string }, curatedListId?: string) => {
        updateScrollPosition();
        if (curatedListId) {
            discoverPodcastTapEvent(curatedListId, podcast.uuid);
        }

        history.push(`${NavigationItems.DISCOVER.path}/podcast/${podcast.uuid}`, {
            curatedListId,
        });
    }, []);

    const recordSubscribeEvent = useCallback((podcast: PodcastDiscover, curatedListId?: string) => {
        if (curatedListId) {
            discoverPodcastSubscribeEvent(curatedListId, podcast.uuid);
        }
    }, []);

    const openDiscoverList = useCallback(
        ({ layout, curated }) => () => {
            updateScrollPosition();
            if (curated) {
                discoverShowAllClickedEvent(layout.listName);
            } else {
                recordEvent('discover_show_all_tapped', { list_id: layout.id });
            }
            history.push(`${NavigationItems.DISCOVER.path}/list/${layout.listName}`);
        },
        [],
    );

    const onCategoryClicked = useCallback(categoryId => {
        updateScrollPosition();
        history.push(`${NavigationItems.DISCOVER.path}/category/${categoryId}`);
    }, []);

    const onDiscoverListImpression = useCallback(curatedListId => {
        discoverListImpressionEvent(curatedListId);
    }, []);

    const renderLayoutItem = (layout: DiscoverLayout, index: number) => {
        const {
            curated,
            data,
            expanded_style,
            sponsored,
            summary_style,
            title,
            type,
            popular,
        } = layout;

        if (!data) {
            return null;
        }

        // These are used for discover analytics
        const curatedListId = curated ? layout.id : undefined;

        switch (summary_style) {
            case DiscoverSummaryStyle.CAROUSEL: {
                const sectionData = data as ListData;
                return (
                    <DiscoverCarousel
                        podcasts={sectionData.podcasts}
                        onPodcastClick={podcastClicked}
                        onSubscribe={recordSubscribeEvent}
                    />
                );
            }

            case DiscoverSummaryStyle.LARGE_LIST: {
                const sectionData = data as ListData;
                return sectionData?.podcasts && sectionData.podcasts.length > 0 ? (
                    <DiscoverSection
                        title={title}
                        discoverFormat="grid"
                        minWidth={152}
                        maxRowCount={2}
                        onSeeAllClick={openDiscoverList({
                            layout,
                            curated,
                        })}
                    >
                        {sectionData?.podcasts.map((podcast, index) => (
                            <DiscoverPodcast
                                // Sometimes the same podcast can be listed twice in the same list, so we need to use a unique key.
                                // We are safe to use the index here, as the list is static and never changes across re-renders.
                                key={`${podcast.uuid}-${index}`}
                                discoverFormat="grid"
                                podcast={podcast}
                                onClick={() => podcastClicked(podcast, curatedListId)}
                                onSubscribe={() =>
                                    curatedListId &&
                                    discoverPodcastSubscribeEvent(curatedListId, podcast.uuid)
                                }
                            />
                        ))}
                    </DiscoverSection>
                ) : null;
            }

            case DiscoverSummaryStyle.SMALL_LIST: {
                const sectionData = data as ListData;
                return sectionData?.podcasts && sectionData?.podcasts.length > 0 ? (
                    <DiscoverSection
                        title={title}
                        discoverFormat="list"
                        gap="10px 8px"
                        minWidth={350}
                        maxRowCount={4}
                        onSeeAllClick={openDiscoverList({
                            layout,
                            curated,
                        })}
                    >
                        {sectionData?.podcasts?.map((podcast, index) => (
                            <DiscoverPodcast
                                // Sometimes the same podcast can be listed twice in the same list, so we need to use a unique key.
                                // We are safe to use the index here, as the list is static and never changes across re-renders.
                                key={`${podcast.uuid}-${index}`}
                                discoverFormat="list"
                                rank={
                                    expanded_style === DiscoverExpandedStyle.RANKED_LIST
                                        ? index + 1
                                        : undefined
                                }
                                podcast={podcast}
                                onClick={() => podcastClicked(podcast, curatedListId)}
                                onSubscribe={() =>
                                    curatedListId &&
                                    discoverPodcastSubscribeEvent(curatedListId, podcast.uuid)
                                }
                            />
                        ))}
                    </DiscoverSection>
                ) : null;
            }

            case DiscoverSummaryStyle.CATEGORY: {
                const sectionData = data as DiscoverCategory[];
                return (
                    <DiscoverCategories
                        onCategoryClicked={onCategoryClicked}
                        title={title}
                        categories={sectionData}
                    />
                );
            }

            case DiscoverSummaryStyle.PILLS: {
                if (type !== 'categories') {
                    return null;
                }

                const sectionData = data as DiscoverCategory[];
                return (
                    <DiscoverCategoriesNav
                        categories={sectionData}
                        popularCategories={popular}
                        onCategoryClicked={onCategoryClicked}
                    />
                );
            }

            case DiscoverSummaryStyle.COLLECTION: {
                const sectionData = data as ListData;
                const previousIsCollection = (() => {
                    if (index - 1 > 0 && contentSpecLayout[index - 1]) {
                        return (
                            contentSpecLayout[index - 1].summary_style ===
                            DiscoverSummaryStyle.COLLECTION
                        );
                    }
                    return false;
                })();
                return (
                    <DiscoverCollection
                        collection={sectionData}
                        onSeeListClick={openDiscoverList({
                            layout,
                            curated,
                        })}
                        previousIsCollection={previousIsCollection}
                    />
                );
            }

            case DiscoverSummaryStyle.SINGLE_PODCAST: {
                const sectionData = data as ListData;
                if (sectionData?.podcasts && sectionData.podcasts.length > 0) {
                    const podcast = sectionData.podcasts[0];
                    return (
                        <DiscoverSingle
                            description={sectionData.description}
                            podcast={podcast}
                            sponsored={sponsored ?? false}
                            onClick={() => podcastClicked(podcast, curatedListId)}
                            onSubscribe={() => recordSubscribeEvent(podcast, curatedListId)}
                        />
                    );
                }
                return null;
            }

            case DiscoverSummaryStyle.SINGLE_EPISODE: {
                return (
                    <DiscoverSingleEpisode
                        list={data as ListData}
                        onEpisodeClick={(podcastUuid: string, episodeUuid: string) => {
                            episodeClicked(podcastUuid, episodeUuid, curatedListId);
                        }}
                        onEpisodePlay={(podcastUuid: string, episodeUuid: string) => {
                            episodePlayed(podcastUuid, episodeUuid, curatedListId);
                        }}
                        onPodcastClick={(podcastUuid: string) =>
                            podcastClicked({ uuid: podcastUuid }, curatedListId)
                        }
                    />
                );
            }

            default:
                return null;
        }
    };

    let PreviousItem: JSX.Element;

    return (
        <DiscoverPageWrapper ref={pageRef as MutableRefObject<HTMLDivElement>}>
            <Helmet>
                <title>{formatMessage('discover')}</title>
            </Helmet>
            <Header title={formatMessage('discover')} />
            {isLoading && <LoaderSquare />}
            {!isLoading && (
                <>
                    {contentSpecLayout
                        .map((layout, index) => {
                            // Do not show layouts intended for the category page
                            if (layout.category_id) {
                                return null;
                            }

                            const Item = renderLayoutItem(layout, index);

                            if (!Item) {
                                return null;
                            }

                            const markImpression = (inView: boolean) => {
                                if (inView && layout.curated) {
                                    onDiscoverListImpression(layout.id);
                                }
                            };

                            // For taller list styles, we need to set a lower threshold, to catch impressions on small screens.
                            // See: https://github.com/shiftyjelly/pocketcasts-webplayer/issues/1410
                            const threshold = Item.type === DiscoverSection ? 0.4 : 0.8;

                            // A separator above this item should be shown if this is not the first item, and if this item
                            // and the one before it aren't Collections (which have their own special border)
                            const showSeparator =
                                PreviousItem &&
                                PreviousItem?.type !== DiscoverCollection &&
                                PreviousItem?.type !== DiscoverCategoriesNav &&
                                Item.type !== DiscoverCollection;

                            PreviousItem = Item;
                            return (
                                <React.Fragment key={layout.id || index}>
                                    {showSeparator && <Separator />}
                                    <DiscoverPageItemWrapper>
                                        <InView
                                            onChange={markImpression}
                                            triggerOnce={true}
                                            threshold={threshold}
                                        >
                                            {Item}
                                        </InView>
                                    </DiscoverPageItemWrapper>
                                </React.Fragment>
                            );
                        })
                        .filter(x => x != null)}
                    <ContentRegionSelector />
                    <OnMount callback={() => setContentMounted(true)} />
                </>
            )}
        </DiscoverPageWrapper>
    );
}

export default DiscoverPage;
