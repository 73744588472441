import { Button } from 'components/Button';
import { LoaderSquare } from 'components/LoaderSquare';
import { StarRatingInput } from 'components/StarRatingInput';
import { TrackOnMount } from 'components/Tracks';
import { checkIfUserCanRatePodcast } from 'helper/PodcastHelper';
import { useSelector } from 'hooks/react-redux-typed';
import useFormatMessage from 'hooks/useFormatMessage';
import { EpisodeSyncInfo, Podcast } from 'model/types';
import React, { FormEventHandler, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as fromFlagsActions from 'redux/actions/flags.actions';
import * as fromPodcastActions from 'redux/actions/podcast.actions';
import * as fromPodcastsActions from 'redux/actions/podcasts.actions';
import * as fromTracksActions from 'redux/actions/tracks.actions';
import { getPodcastByUuid, getUserPodcastRating } from 'redux/reducers/selectors';
import { getEpisodeSyncForPodcast } from 'redux/reducers/selectors/episode-sync.selectors';
import { H30 as Title } from 'styles';
import { CannotRateWrapper, Description, Form, PodcastImage } from './RatePodcastForm.styled';

export type Props = {
    podcastUuid: string;
    onFormLoad?: (type: 'rating_screen' | 'not_allowed_to_rate_screen') => void;
    onSubmit?: () => void;
};

const RatePodcastForm = ({ podcastUuid, onSubmit, onFormLoad }: Props) => {
    const dispatch = useDispatch();
    const formatMessage = useFormatMessage();

    const podcast = useSelector<Podcast>(state => getPodcastByUuid(state, podcastUuid));
    const episodes = useSelector<EpisodeSyncInfo[]>(state =>
        Object.values(getEpisodeSyncForPodcast(state, podcastUuid)),
    );

    const userRating = useSelector<number | null | undefined>(state =>
        getUserPodcastRating(state, podcastUuid),
    );

    const [rating, setRating] = useState<number | null | undefined>(userRating);

    const canUserRatePodcast = checkIfUserCanRatePodcast(episodes);

    useEffect(() => {
        onFormLoad?.(canUserRatePodcast ? 'rating_screen' : 'not_allowed_to_rate_screen');
    }, [canUserRatePodcast, onFormLoad]);

    useEffect(() => {
        if (rating === undefined && userRating !== undefined) {
            setRating(userRating);
        }

        // If there is no user rating yet, set the default rating to 0
        if (rating === undefined && userRating === null) {
            setRating(0);
        }
    }, [userRating, rating]);

    const handleSubmit: FormEventHandler = evt => {
        evt.preventDefault(); // Prevent page reload

        if (rating === undefined || rating === null) return;

        dispatch(fromPodcastActions.Actions.ratePodcast(podcastUuid, rating));
        dispatch(
            fromTracksActions.Actions.recordEvent('rating_screen_submit_tapped', {
                uuid: podcastUuid,
                stars: rating,
            }),
        );
        dispatch(fromFlagsActions.Actions.addFlag(formatMessage('thank-you-for-rating')));

        // Delay the fetch of the rating to give the server time to update
        setTimeout(() => {
            dispatch(fromPodcastsActions.Actions.fetchPodcastRating({ uuid: podcastUuid, forceRefetch: true }));
        }, 2000);

        onSubmit?.();
    };

    if (!canUserRatePodcast) {
        return (
            <CannotRateWrapper>
                <TrackOnMount
                    event="not_allowed_to_rate_screen_shown"
                    properties={{ uuid: podcastUuid }}
                />
                <PodcastImage uuid={podcastUuid} />
                <Title>{formatMessage('please-listen-first')}</Title>
                <Description>{formatMessage('cannot-rate-podcast')}</Description>
            </CannotRateWrapper>
        );
    }

    const userRatingIsLoading = userRating === undefined;
    const showSubmitButton = rating !== userRating || userRating === null;

    return (
        <Form onSubmit={handleSubmit} className={!showSubmitButton ? 'no-submit-button' : ''}>
            <TrackOnMount event="rating_screen_shown" properties={{ uuid: podcastUuid }} />
            <PodcastImage uuid={podcastUuid} />
            <Title>{formatMessage('rate-podcast', { podcastTitle: podcast.title })}</Title>

            {userRatingIsLoading ? (
                <LoaderSquare />
            ) : (
                <StarRatingInput value={rating || 0} onChange={setRating} />
            )}

            {showSubmitButton && (
                <Button kind="primary" type="submit" disabled={!rating}>
                    {formatMessage('save-rating')}
                </Button>
            )}
        </Form>
    );
};

export default RatePodcastForm;
