import { TracksEventSource } from 'model/types';
import * as fromShareActions from '../actions/share.actions';

interface ShareState {
    podcastUuid: string | null;
    episodeUuid: string | null;
    episodeTitle: string | null;
    episodeDuration: number | null;
    audioUrl: string | null;
    url: string | null;
    open: boolean;
    searching: boolean;
    failed: boolean;
    eventSource: TracksEventSource | null;
    currentTime?: number;
    loading?: boolean;
}

export const INITIAL_STATE = {
    podcastUuid: null,
    episodeUuid: null,
    episodeTitle: null,
    episodeDuration: null,
    url: null,
    audioUrl: null,
    open: false,
    searching: false,
    failed: false,
    eventSource: null,
};

export default (state: ShareState = INITIAL_STATE, action: fromShareActions.Actions) => {
    switch (action.type) {
        case fromShareActions.ActionTypes.OPEN_PODCAST_SHARE:
            return {
                ...INITIAL_STATE,
                podcastUuid: action.payload.podcastUuid,
                episodeUuid: null,
                episodeTitle: null,
                open: true,
                searching: true,
                eventSource: action.payload.tracksProperties.eventSource,
            };
        case fromShareActions.ActionTypes.OPEN_EPISODE_SHARE:
            return {
                ...INITIAL_STATE,
                podcastUuid: action.payload.podcastUuid,
                episodeUuid: action.payload.episodeUuid || null,
                episodeTitle: action.payload.episodeTitle || null,
                episodeDuration: action.payload.duration || null,
                audioUrl: action.payload.audioUrl || null,
                open: true,
                searching: true,
                eventSource: action.payload.options.eventSource,
            };
        case fromShareActions.ActionTypes.OPEN_SHARE_SUCCESS:
            return {
                ...state,
                searching: false,
                failed: false,
                url: action.payload.url,
                currentTime: action.payload.time,
            };
        case fromShareActions.ActionTypes.OPEN_SHARE_FAILED:
            return { ...state, searching: false, failed: true };
        case fromShareActions.ActionTypes.CLOSE_SHARE:
            return { ...state, searching: false, failed: false, open: false, url: null };
        default:
            return state;
    }
};
