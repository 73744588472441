import styled from 'styled-components';

export const ImageTextSwitchOptionContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    height: 50px;
    width: 100%;

    cursor: pointer;

    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:hover {
        background-color: ${props => props.theme.tokens['primary-ui-01-active']};
    }

    svg {
        margin-left: 12px;
        margin-right: 16px;
    }

    .option-title {
        padding-right: 24px;
        line-height: 50px;
        vertical-align: middle;
        font-size: 14.4px;
        font-weight: 400;
        color: ${props => props.theme.tokens['primary-text-01']};
    }

    .option-switch {
        display: flex;
        flex-direction: row;
        align-items: center;

        margin-right: 12px;
    }
`;
