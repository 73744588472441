import PropTypes from 'prop-types';
import UploadInformationHeading from './view';

UploadInformationHeading.propTypes = {
    numberOfUploads: PropTypes.number.isRequired,
    allFinished: PropTypes.bool.isRequired,
    errorCount: PropTypes.number.isRequired,
};

export default UploadInformationHeading;
