import { FolderImage } from 'components/FolderImage';
import { Icon } from 'components/Icon';
import { PodcastCount } from 'components/messages';
import { NavigationItems } from 'helper/NavigationHelper';
import {
    pauseKeyboardShortcuts,
    resumeKeyboardShortcuts,
    shouldCancelDragEvent,
} from 'helper/UiHelper';
import useFormatMessage from 'hooks/useFormatMessage';
import React, { useEffect, useState } from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { DateText, PodcastImage } from '../../../components';
import { UnplayedBadge } from '../PodcastsPage.styled';
import { PodcastListItem } from '../model';
import {
    AssistiveText,
    ImageWrapper,
    List,
    RowHandle,
    RowLink,
    RowWrapper,
    Subtitle,
    Text,
    Title,
} from './ListOfPodcasts.styled';

const SortableList = SortableContainer(List);
const SortableDragHandle = SortableHandle(() => <Icon id="drag-handle" />);

type RowProps = {
    item: PodcastListItem;
    onItemClick: (item: PodcastListItem) => void;
};

const SortableRow = SortableElement(({ item, onItemClick }: RowProps) => {
    let title;
    let subtitle;
    let image;
    let href = '';

    if ('podcast' in item) {
        title = item.podcast.title;
        subtitle = <DateText date={item.podcast.lastEpisodePublished} showToday={true} />;
        image = <PodcastImage title={item.podcast.title} uuid={item.podcast.uuid} />;
        href = `${NavigationItems.PODCASTS.path}/${item.podcast.uuid}`;
    } else if ('folder' in item) {
        title = item.folder.name;
        subtitle = (
            <span>
                <PodcastCount count={item.podcastUuids.length} />
            </span>
        );
        image = (
            <FolderImage
                color={item.folder.color}
                name={item.folder.name}
                podcastUuids={item.podcastUuids}
                showName={false}
                sortType={item.folder.sortType}
            />
        );
        href = `${NavigationItems.PODCASTS.path}/folders/${item.folder.uuid}`;
    }

    return (
        <RowWrapper>
            <RowLink
                to={href}
                aria-label={title}
                aria-describedby="list-of-podcasts-dnd-instructions"
                onClick={() => onItemClick(item)}
                draggable="false" // Prevent link-dragging conflicts with react-sortable-hoc
            >
                <ImageWrapper>
                    {image}
                    {item.showUnplayedBadge && <UnplayedBadge />}
                </ImageWrapper>
                <Text>
                    <Title>{title}</Title>
                    <Subtitle>{subtitle}</Subtitle>
                </Text>
            </RowLink>
            <RowHandle>
                <SortableDragHandle />
            </RowHandle>
        </RowWrapper>
    );
});

type Props = {
    items: PodcastListItem[];
    onItemClick: (item: PodcastListItem) => void;
    onSortEnd: (oldPosition: number, newPosition: number) => void;
};

function ListOfPodcasts({ items, onItemClick, onSortEnd }: Props) {
    const formatMessage = useFormatMessage();
    const [isSorting, setIsSorting] = useState(false);
    const [liveText, setLiveText] = useState('');

    // Make sure on unmount, we always resume keyboard shortcuts
    useEffect(() => resumeKeyboardShortcuts, []);

    return (
        <>
            <AssistiveText>
                <p id="list-of-podcasts-dnd-instructions">
                    {formatMessage('reorder-start-instructions')}
                </p>
                <p aria-live="assertive">
                    <span key={liveText}>{liveText}</span>
                </p>
            </AssistiveText>
            <SortableList
                axis="y"
                lockAxis="y"
                pressDelay={200}
                pressThreshold={1000}
                helperClass="is-dragging"
                shouldCancelStart={shouldCancelDragEvent}
                onSortStart={({ index }) => {
                    pauseKeyboardShortcuts();
                    setIsSorting(true);
                    setLiveText(
                        `${items[index].title}, ${formatMessage('grabbed')}. ${formatMessage(
                            'current-position',
                            {
                                position: index + 1,
                                count: items.length,
                            },
                        )}. ${formatMessage('reorder-move-instructions')}`,
                    );
                }}
                onSortOver={({ newIndex }) => {
                    setLiveText(
                        `${formatMessage('moved')}. ${formatMessage('current-position', {
                            position: newIndex + 1,
                            count: items.length,
                        })}.`,
                    );
                }}
                onSortEnd={({ oldIndex, newIndex }) => {
                    setIsSorting(false);
                    onSortEnd(oldIndex, newIndex);
                    resumeKeyboardShortcuts();
                    if (oldIndex === newIndex) {
                        setLiveText(
                            `${items[oldIndex].title}, ${formatMessage(
                                'dropped-in-original-position',
                            )}.`,
                        );
                    } else {
                        setLiveText(
                            `${items[oldIndex].title}, ${formatMessage('dropped')}. ${formatMessage(
                                'final-position',
                                {
                                    position: newIndex + 1,
                                    count: items.length,
                                },
                            )}.`,
                        );
                    }
                }}
                isSorting={isSorting}
            >
                {items &&
                    items.map((item: PodcastListItem, index) => (
                        <SortableRow
                            key={'podcast' in item ? item.podcast.uuid : item.folder.uuid}
                            index={index}
                            item={item}
                            onItemClick={onItemClick}
                        />
                    ))}
            </SortableList>
        </>
    );
}

export default ListOfPodcasts;
