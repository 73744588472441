import { EpisodeSyncInfo } from 'model/types';
import { UPLOADED_FILES_PODCAST_UUID } from 'model/uploaded-files';
import { PlayingStatus } from 'helper/PlayingStatus';
import { Action } from 'redux/actions';
import * as fromFilterActions from '../actions/filter.actions';
import * as fromPlayerActions from '../actions/player.actions';
import * as fromPodcastActions from '../actions/podcast.actions';
import * as fromPodcastsActions from '../actions/podcasts.actions';
import * as fromUpNextActions from '../actions/up-next.actions';

type EpisodeSyncState = Record<string, EpisodeSyncInfo>;

export const INITIAL_STATE: EpisodeSyncState = {};

const mergeEpisodeSync = (
    state: EpisodeSyncState,
    episodeSync: (Partial<EpisodeSyncInfo> & { uuid: string })[],
): EpisodeSyncState => {
    const newState = { ...state };
    episodeSync.forEach(sync => {
        newState[sync.uuid] = { ...newState[sync.uuid], ...sync };
    });
    return newState;
};

export default (
    state: EpisodeSyncState = INITIAL_STATE,
    action:
        | fromFilterActions.Actions
        | fromPlayerActions.Actions
        | fromPodcastActions.Actions
        | fromPodcastsActions.Actions
        | fromUpNextActions.Actions
        | Action,
): EpisodeSyncState => {
    switch (action.type) {
        case fromPodcastsActions.ActionTypes.ADD_PODCAST:
            return mergeEpisodeSync(state, action.payload.podcastSync?.episodes ?? []);
        case fromPlayerActions.ActionTypes.UPDATE_DURATION:
            return mergeEpisodeSync(state, [
                { uuid: action.payload.episodeUuid, duration: Math.floor(action.payload.duration) },
            ]);
        case fromPlayerActions.ActionTypes.UPDATE_PLAYED_UP_TO:
        case fromPlayerActions.ActionTypes.SAVE_PROGRESS:
            return mergeEpisodeSync(state, [
                {
                    uuid: action.payload.episodeUuid,
                    playedUpTo: action.payload.playedUpTo,
                },
            ]);
        case fromUpNextActions.ActionTypes.UP_NEXT_CHANGED:
            if (action.payload.data.episodeSync) {
                return mergeEpisodeSync(state, action.payload.data.episodeSync);
            }
            return state;

        case fromPodcastActions.ActionTypes.MARK_AS_UNPLAYED:
            return mergeEpisodeSync(state, [
                {
                    uuid: action.payload.episodeUuid,
                    playingStatus: PlayingStatus.NOT_PLAYED,
                    playedUpTo: 0,
                },
            ]);

        case fromPodcastActions.ActionTypes.MARK_AS_IN_PROGRESS:
            return mergeEpisodeSync(state, [
                {
                    uuid: action.payload.episodeUuid,
                    playingStatus: PlayingStatus.IN_PROGRESS,
                    playedUpTo: action.payload.playedUpTo,
                },
            ]);

        case fromPodcastActions.ActionTypes.MARK_AS_PLAYED:
            return mergeEpisodeSync(state, [
                { uuid: action.payload.episodeUuid, playingStatus: PlayingStatus.COMPLETED },
            ]);

        case fromPodcastActions.ActionTypes.ARCHIVE:
            return mergeEpisodeSync(state, [{ uuid: action.payload.episodeUuid, isDeleted: true }]);

        case fromPodcastActions.ActionTypes.UNARCHIVE:
            return mergeEpisodeSync(state, [
                { uuid: action.payload.episodeUuid, isDeleted: false },
            ]);

        case fromPodcastActions.ActionTypes.ARCHIVE_ALL: {
            let updatedState = state;
            action.payload.episodeUuidAndPodcastUuids.forEach(episodePodcast => {
                updatedState = mergeEpisodeSync(updatedState, [
                    { uuid: episodePodcast.uuid, isDeleted: true },
                ]);
            });
            return updatedState;
        }

        case fromPodcastActions.ActionTypes.UNARCHIVE_ALL: {
            let updatedState = state;
            action.payload.episodeUuidAndPodcastUuids.forEach(episodePodcast => {
                updatedState = mergeEpisodeSync(updatedState, [
                    { uuid: episodePodcast.uuid, isDeleted: false },
                ]);
            });
            return updatedState;
        }

        case fromPodcastActions.ActionTypes.STAR_EPISODE:
            return mergeEpisodeSync(state, [
                { uuid: action.payload.episodeUuid, starred: action.payload.starred },
            ]);
        case fromFilterActions.ActionTypes.DOWNLOAD_FILTER_SUCCESS:
            return mergeEpisodeSync(
                state,
                action.payload.episodes.map(
                    ({ uuid, playingStatus, playedUpTo, isDeleted, starred, duration }) => ({
                        uuid,
                        playingStatus,
                        playedUpTo,
                        isDeleted,
                        starred,
                        duration,
                    }),
                ),
            );
        case 'BOOKMARK_ADD_SUCCEEDED': {
            if (action.payload.bookmark.podcastUuid === UPLOADED_FILES_PODCAST_UUID) {
                // Files sync data does not live in episodeSync (yet)
                return state;
            }

            const existingBookmarks = state[action.payload.bookmark.episodeUuid]?.bookmarks ?? [];

            return mergeEpisodeSync(state, [
                {
                    uuid: action.payload.bookmark.episodeUuid,
                    bookmarks: existingBookmarks.concat(action.payload.bookmark),
                },
            ]);
        }
        case 'BOOKMARK_EDIT_SUCCEEDED': {
            if (action.payload.bookmark.podcastUuid === UPLOADED_FILES_PODCAST_UUID) {
                // Files sync data does not live in episodeSync (yet)
                return state;
            }

            const existingBookmarks = state[action.payload.bookmark.episodeUuid]?.bookmarks ?? [];

            return mergeEpisodeSync(state, [
                {
                    uuid: action.payload.bookmark.episodeUuid,
                    bookmarks: existingBookmarks.map(bookmark =>
                        bookmark.bookmarkUuid === action.payload.bookmark.bookmarkUuid
                            ? action.payload.bookmark
                            : bookmark,
                    ),
                },
            ]);
        }
        case 'BOOKMARK_DELETE_SUCCEEDED': {
            const existingBookmarks = state[action.payload.bookmark.episodeUuid]?.bookmarks ?? [];

            return mergeEpisodeSync(state, [
                {
                    uuid: action.payload.bookmark.episodeUuid,
                    bookmarks: existingBookmarks.filter(
                        bookmark => bookmark.bookmarkUuid !== action.payload.bookmark.bookmarkUuid,
                    ),
                },
            ]);
        }
        default:
            return state;
    }
};
