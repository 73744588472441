import classNames from 'classnames';
import useFormatMessage from 'hooks/useFormatMessage';
import React, { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Dot, Dots, DurationLabels, SliderComponent, SliderWrapper } from './Slider.styled';

interface Props {
    durations: string[];
    selectedDurationIndex: number;
    handleSliderChange: (value: number) => void;
}

const Slider = ({ durations, selectedDurationIndex, handleSliderChange }: Props) => {
    const [isDragging, setIsDragging] = useState(false);
    const [position, setPosition] = useState(selectedDurationIndex);
    const sliderRef = useRef<HTMLInputElement>(null);
    const formatMessage = useFormatMessage();

    const calculateNewPosition = useCallback(
        (event: MouseEvent, sliderRect: DOMRect, durationsLength: number) => {
            const newValue =
                ((event.clientX - sliderRect.left) / sliderRect.width) * (durationsLength - 1);
            return Math.max(0, Math.min(durationsLength - 1, newValue));
        },
        [],
    );

    const handleMouseUp = useCallback(
        (event: MouseEvent) => {
            if (sliderRef.current) {
                const sliderRect = sliderRef.current.getBoundingClientRect();
                const newValue = Math.round(
                    calculateNewPosition(event, sliderRect, durations.length),
                );
                setIsDragging(false);
                handleSliderChange(newValue);
                setPosition(newValue);
            }
        },
        [calculateNewPosition, durations.length, handleSliderChange],
    );

    const handleMouseMove = useCallback(
        (event: MouseEvent) => {
            if (!isDragging || !sliderRef.current) {
                return;
            }
            const sliderRect = sliderRef.current.getBoundingClientRect();
            const newPosition = calculateNewPosition(event, sliderRect, durations.length);
            setPosition(newPosition);
        },
        [isDragging, calculateNewPosition, durations.length],
    );

    useEffect(() => {
        if (isDragging) {
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
        } else {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        }

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isDragging, handleMouseMove, handleMouseUp]);

    const handleMouseDown = useCallback(
        (event: React.MouseEvent) => {
            event.preventDefault();
            event.stopPropagation();
            setIsDragging(true);
            setPosition(selectedDurationIndex);
        },
        [selectedDurationIndex],
    );

    const handleOnChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            handleSliderChange(parseInt(event.target.value, 10));
        },
        [handleSliderChange],
    );

    const calculateDotLeft = useCallback((index: number, length: number) => {
        // Max bound is 96% to prevent the last dot from going out of the slider
        // and click targets being off.
        return index >= length - 1 ? 97 : index * (100 / (length - 1));
    }, []);

    const handleLabelClick = useCallback(
        (index: number) => {
            handleSliderChange(index);
            setPosition(index);
        },
        [handleSliderChange],
    );

    // TODO: It would be nice if we got a single duration in seconds
    // and handled converting it to various formats ourselves. We should
    // check with the Accordion team to see if this is possible.
    const durationLabel = useCallback((duration: string) => {
        const hoursMatch = duration.match(/(\d+)h/);
        const minutesMatch = duration.match(/(\d+)m/);

        let totalMinutes = 0;
        let hours = 0;

        if (hoursMatch) {
            hours = parseInt(hoursMatch[1], 10);
            totalMinutes += hours * 60;
        }

        if (minutesMatch) {
            totalMinutes += parseInt(minutesMatch[1], 10);
        }

        if (hours > 0) {
            const remainingMinutes = totalMinutes - hours * 60;
            return {
                totalMinutes,
                label: (
                    <FormattedMessage
                        id="duration-n-hour-w-minutes-shorthand"
                        values={{ numHours: hours, numMins: remainingMinutes }}
                    />
                ),
            };
        }

        return { totalMinutes, label: totalMinutes.toString() };
    }, []);

    return (
        <SliderWrapper onMouseDown={handleMouseDown}>
            <SliderComponent
                ref={sliderRef}
                id="slider"
                type="range"
                min="0"
                max={durations.length - 1}
                step="1"
                aria-label={formatMessage('select-podcast-length')}
                value={selectedDurationIndex}
                onChange={handleOnChange}
            />
            <Dots>
                {durations.map((_, index) => (
                    <Dot
                        key={`dot-${index}`}
                        style={
                            {
                                '--dot-left': `${calculateDotLeft(index, durations.length)}%`,
                            } as CSSProperties
                        }
                        onClick={() => handleLabelClick(index)}
                    ></Dot>
                ))}
                <Dot
                    className="selected"
                    style={
                        {
                            '--dot-left': `${calculateDotLeft(position, durations.length)}%`,
                        } as CSSProperties
                    }
                ></Dot>
            </Dots>
            <DurationLabels>
                {durations.map((duration, index) => {
                    const { totalMinutes, label } = durationLabel(duration);
                    return (
                        <button
                            key={`label-${index}`}
                            style={
                                {
                                    '--dot-left': `${calculateDotLeft(index, durations.length)}%`,
                                } as CSSProperties
                            }
                            onClick={() => handleLabelClick(index)}
                            className={classNames({
                                selected: index === selectedDurationIndex,
                                'over-an-hour': totalMinutes > 60,
                            })}
                        >
                            {label}
                        </button>
                    );
                })}
            </DurationLabels>
        </SliderWrapper>
    );
};

export default Slider;
