import PropTypes from 'prop-types';
import FileEditModalTooltip from './view';

FileEditModalTooltip.propTypes = {
    text: PropTypes.string.isRequired,
    top: PropTypes.number,
    bottom: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number,
};

export default FileEditModalTooltip;
