import { DiscoverEpisodeLarge, DiscoverPodcast, DiscoverSection } from 'components';
import { TrackOnMount } from 'components/Tracks';
import { getCategoryByLabel } from 'helper/CategoriesHelper';
import { getPodcastListTint } from 'model/theme';
import {
    DiscoverCategory,
    DiscoverExpandedStyle,
    DiscoverLayout,
    DiscoverSummaryStyle,
    ListData,
    PodcastDiscover,
} from 'model/types';
import React, { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, match, withRouter } from 'react-router-dom';
import { RootState } from 'redux/reducers';
import { LoaderSquare } from '../../components/LoaderSquare';
import { useAnalyticsContext } from '../../context/AnalyticsContext';
import { NavigationItems } from '../../helper/NavigationHelper';
import { useScrollRestoration } from '../../hooks/useScrollRestoration';
import * as fromDiscoverActions from '../../redux/actions/discover.actions';
import {
    getDiscoverCategoryPageDetails,
    getDiscoverListPageDetails,
} from '../../redux/reducers/selectors';
import { ListPageHeader } from './ListPageHeader';
import { PodcastDescriptiveGrid } from './PodcastDescriptiveGrid';
import { ListTitle, PodcastListPageWrapper } from './PodcastListPage.styled';

type Props = {
    match: match<{ categoryId: string; listId: string }>;
} & RouteComponentProps;

export function PodcastListPage({ match, history }: Props) {
    const dispatch = useDispatch();
    const {
        discoverListImpressionEvent,
        discoverEpisodePlayEvent,
        discoverEpisodeTapEvent,
        discoverPodcastTapEvent,
        discoverPodcastSubscribeEvent,
        discoverCategoryPageOpen,
        discoverCategoryOpen,
    } = useAnalyticsContext();
    const {
        list,
        title,
        listId,
        ranked,
        pageHeaderDescription,
        discoverContentJsonLayout,
        category,
        region,
        curated,
    }: {
        list: ListData;
        listId: string;
        title: string;
        ranked: boolean;
        pageHeaderDescription: {
            summaryType: string;
            expandedType: string;
            data: ListData | null;
        };
        category?: DiscoverCategory | null;
        region: string;
        curated?: boolean;
        discoverContentJsonLayout: DiscoverLayout;
    } = useSelector((state: RootState) => {
        if (match.params.categoryId != null) {
            return getDiscoverCategoryPageDetails(state, match.params.categoryId);
        }
        return getDiscoverListPageDetails(state, match.params);
    });

    const isLoading = discoverContentJsonLayout == null || title == null || region == null;

    const { pageRef, updateScrollPosition } = useScrollRestoration(!isLoading);

    useEffect(() => {
        // We have to do this check because a user can load a List without having gone through
        // the discover page, but we also don't want to load the discover content on every page.
        if (region && !discoverContentJsonLayout) {
            dispatch(fromDiscoverActions.Actions.getDiscoverContent());
        }

        if (match.params.categoryId == null) {
            dispatch(fromDiscoverActions.Actions.openDiscoverList(listId));
        }
    }, [region]);

    useEffect(() => {
        if (discoverContentJsonLayout != null) {
            if (match.params.categoryId != null) {
                dispatch(fromDiscoverActions.Actions.openCategoryList(match.params.categoryId));
            }
        }
    }, [discoverContentJsonLayout]);

    useEffect(() => {
        if (!isLoading && curated) {
            discoverListImpressionEvent(listId);
        }
    }, [isLoading, curated]);

    useEffect(() => {
        if (!isLoading && region && category) {
            const categoryId = category.id;
            discoverCategoryOpen(categoryId, region);
            discoverCategoryPageOpen(categoryId);
        }
    }, [isLoading, region, category]);

    const summary = pageHeaderDescription ? pageHeaderDescription.data : null;
    const themeId = useSelector((state: RootState) => state.settings.theme);
    const accentColor = summary?.colors
        ? getPodcastListTint({ colors: summary.colors, themeId })
        : undefined;

    const podcastClicked = (podcast: { uuid: string }) => {
        updateScrollPosition();
        discoverPodcastTapEvent(listId, podcast.uuid);

        history.push(`${NavigationItems.DISCOVER.path}/podcast/${podcast.uuid}`, {
            curatedListId: listId,
        });
    };

    const recordSubscribeEvent = (podcast: PodcastDiscover) => {
        discoverPodcastSubscribeEvent(listId, podcast.uuid);
    };

    const PodcastListDescription = useMemo(() => {
        if (pageHeaderDescription) {
            switch (pageHeaderDescription.summaryType) {
                case DiscoverSummaryStyle.COLLECTION:
                    if (summary != null) {
                        return (
                            <ListPageHeader
                                title={title}
                                subtitle={summary.subtitle}
                                imageUrl={summary.collection_image}
                                url={summary.web_url}
                                urlText={summary.web_title}
                                colors={summary.colors}
                                description={summary.description}
                                summaryStyle={pageHeaderDescription.summaryType}
                                listId={listId}
                            />
                        );
                    }
                    return null;
                case DiscoverSummaryStyle.CATEGORY:
                    return (
                        <ListPageHeader
                            title={getCategoryByLabel(title)?.formattedLabel || title}
                            subtitle="category"
                            category={category}
                            summaryStyle={pageHeaderDescription.summaryType}
                            listId={listId}
                        />
                    );

                default:
                    return <ListTitle>{title}</ListTitle>;
            }
        }
        return null;
    }, [summary, pageHeaderDescription]);

    const renderPlainListItems = () => {
        if (list?.episodes?.length > 0) {
            return (
                <DiscoverSection discoverFormat="grid" minWidth={400} gap={'32px 16px'}>
                    {list.episodes.map(episode => (
                        <DiscoverEpisodeLarge
                            key={episode.uuid}
                            episode={episode}
                            color={accentColor || undefined}
                            onEpisodeClick={(podcastUuid, episodeUuid) =>
                                discoverEpisodeTapEvent(listId, podcastUuid, episodeUuid)
                            }
                            onEpisodePlay={podcastUuid =>
                                discoverEpisodePlayEvent(podcastUuid, listId)
                            }
                            onPodcastClick={() => podcastClicked({ uuid: episode.podcast_uuid })}
                        />
                    ))}
                </DiscoverSection>
            );
        }

        if (list?.podcasts?.length > 0) {
            return (
                <DiscoverSection discoverFormat="grid" minWidth={152}>
                    {list.podcasts.map((podcast, index) => (
                        <DiscoverPodcast
                            key={podcast.uuid}
                            discoverFormat="grid"
                            rank={ranked ? index + 1 : undefined}
                            podcast={podcast}
                            onClick={() => podcastClicked(podcast)}
                            onSubscribe={() => discoverPodcastSubscribeEvent(listId, podcast.uuid)}
                        />
                    ))}
                </DiscoverSection>
            );
        }

        return null;
    };

    const expandedType = pageHeaderDescription ? pageHeaderDescription.expandedType : null;

    return (
        <PodcastListPageWrapper ref={pageRef as React.MutableRefObject<HTMLDivElement>}>
            {category && (
                <TrackOnMount
                    event="discover_category_shown"
                    properties={{ id: category.id, name: title, region }}
                />
            )}
            <Helmet>
                <title>{title}</title>
            </Helmet>
            {isLoading && <LoaderSquare />}
            {!isLoading && PodcastListDescription}
            {!isLoading && expandedType === DiscoverExpandedStyle.DESCRIPTIVE && (
                <PodcastDescriptiveGrid
                    podcasts={list?.podcasts}
                    onPodcastClick={podcastClicked}
                    onSubscribe={recordSubscribeEvent}
                />
            )}
            {!isLoading &&
                expandedType !== DiscoverExpandedStyle.DESCRIPTIVE &&
                renderPlainListItems()}
        </PodcastListPageWrapper>
    );
}

export default withRouter(PodcastListPage);
