import styled from 'styled-components';

export const SkipBackButtonContainer = styled.button`
    width: 45px;
    height: 45px;
    background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2245%22%20height%3D%2245%22%20viewBox%3D%220%200%2045%2045%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ctitle%3Eplayer%2Fskipback%3C%2Ftitle%3E%3Cpath%20d%3D%22M12%209h11.5C32.06%209%2039%2015.94%2039%2024.5%2039%2033.06%2032.06%2040%2023.5%2040%2014.94%2040%208%2033.06%208%2024.5c0-.552.448-1%201-1s1%20.448%201%201C10%2031.956%2016.044%2038%2023.5%2038S37%2031.956%2037%2024.5%2030.956%2011%2023.5%2011H12v4l-6-5%206-5v4z%22%20fill%3D%22%23FFF%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat;
    cursor: pointer;
    p {
        margin: 0;
        padding: 3px 0 0 2px;
        display: block;
        width: 45px;
        height: 43px;
        text-align: center;
        font-size: 12px;
        font-weight: 500;
        color: #fff;
        letter-spacing: 0;
        line-height: 40px;
        box-sizing: border-box;
        user-select: none;
    }
`;
