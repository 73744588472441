import React, { Component } from 'react';
import { DynamicLoadingCircleWrapper } from './DynamicLoadingCircle.styled';

type Props = {
    size: number;
    color: string;
};

export class DynamicLoadingCircle extends Component<Props> {
    render() {
        const { size, color } = this.props;

        return (
            <DynamicLoadingCircleWrapper $size={size}>
                <svg width={size} height={size} viewBox="1 1 22 22">
                    <path
                        opacity="0.25"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12ZM21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
                        fill={color}
                    />
                    <path
                        d="M21.1463 16.1438C21.6286 16.3623 22.2011 16.1495 22.3768 15.65C22.8928 14.1829 23.0932 12.6193 22.96 11.0624C22.7995 9.18636 22.1601 7.38288 21.103 5.82467C20.046 4.26645 18.6066 3.00567 16.9228 2.16301C15.5254 1.46374 13.9986 1.07184 12.4446 1.00898C11.9155 0.987585 11.5061 1.44079 11.5309 1.96972C11.5556 2.49865 12.0054 2.90209 12.534 2.93322C13.7594 3.00539 14.961 3.32547 16.0646 3.87779C17.455 4.57356 18.6434 5.61456 19.5162 6.90115C20.389 8.18774 20.9169 9.67683 21.0494 11.2259C21.1546 12.4555 21.0078 13.6903 20.6219 14.8556C20.4554 15.3583 20.664 15.9253 21.1463 16.1438Z"
                        fill={color}
                    />
                </svg>
            </DynamicLoadingCircleWrapper>
        );
    }
}

export default DynamicLoadingCircle;
