import { Logo as LogoComponent } from 'components/Logo';
import { CredentialNotificationWrapper } from 'components/form/CredentialNotification/CredentialNotification.styled';
import styled from 'styled-components';
import { gridSize } from '../../styles';

export const TWO_COLUMNS_BREAKPOINT = 900;

export const RegisterPageWrapper = styled.div`
    width: 100%;
    max-width: 1280px;
    min-height: 100vh;

    padding: ${3 * gridSize}px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    @media (min-width: ${TWO_COLUMNS_BREAKPOINT}px) {
        padding: ${3 * gridSize}px ${4 * gridSize}px ${6 * gridSize}px;
        justify-content: center;
    }
    @media (min-width: 1100px) {
        padding: ${3 * gridSize}px ${6 * gridSize}px ${6 * gridSize}px;
    }
`;

export const Logo = styled(LogoComponent)`
    width: 40px;
    height: 40px;
`;

export const ContentWrapper = styled.div`
    padding-top: ${3 * gridSize}px;

    display: flex;
    flex-direction: column;
    min-height: 600px;

    @media (min-width: ${TWO_COLUMNS_BREAKPOINT}px) {
        display: grid;
        grid-template-columns: auto 400px;
        grid-template-rows: min-content auto;
        gap: 0 ${6 * gridSize}px;
    }
    @media (min-width: 1060px) {
        grid-template-columns: auto 500px;
    }
    @media (min-width: 1280px) {
        gap: 0 ${12.5 * gridSize}px;
    }
`;

export const Form = styled.div`
    grid-column: 1;
    grid-row: 1 / span 2;
    ${CredentialNotificationWrapper} {
        margin-bottom: ${3 * gridSize}px;
    }
`;

export const Plans = styled.div`
    margin: 0 ${-3 * gridSize}px ${3 * gridSize}px;
    @media (min-width: ${TWO_COLUMNS_BREAKPOINT}px) {
        margin: 0;
    }
`;

export const FinePrint = styled.small`
    display: block;
    color: ${props => props.theme.tokens['primary-text-02']};
    font-weight: 500;
    font-size: 14px;
    line-height: 1.4;

    margin-top: ${3 * gridSize}px;
    border-top: 1px solid ${props => props.theme.legacyScss['theme-line']};

    p {
        margin: ${3 * gridSize}px 0 0;
    }

    @media (min-width: ${TWO_COLUMNS_BREAKPOINT}px) {
        border: none;
        margin: 0;
    }
`;
