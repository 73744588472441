import React from 'react';
import { useDispatch } from 'react-redux';
import * as fromPlayerActions from '../../../redux/actions/player.actions';
import { VideoExpandButtonWrapper } from './VideoExpandButton.styled';

function VideoExpandButton() {
    const dispatch = useDispatch();
    const onExpandClick = (event: React.SyntheticEvent) => {
        event.stopPropagation();
        dispatch(fromPlayerActions.Actions.updateVideoMode(2));
    };

    return (
        <VideoExpandButtonWrapper className="video-expand-button" onClick={onExpandClick}>
            <div className="frame">
                <div className="arrow" />
            </div>
        </VideoExpandButtonWrapper>
    );
}

export default VideoExpandButton;
