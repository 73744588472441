import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import * as fromSettingsActions from '../../../redux/actions/settings.actions';
import { RootState } from '../../../redux/reducers';
import SettingsArchivingComponent from './SettingsArchiving';

const mapStateToProps = (state: RootState) => ({
    autoArchivePlayed: !!state.settings.autoArchivePlayed,
    autoArchiveIncludesStarred: !!state.settings.autoArchiveIncludesStarred,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    saveAutoArchivePlayed: (enabled: boolean) =>
        dispatch(fromSettingsActions.Actions.saveAutoArchivePlayed(enabled)),
    saveAutoArchiveIncludesStarred: (enabled: boolean) =>
        dispatch(fromSettingsActions.Actions.saveAutoArchiveIncludesStarred(enabled)),
});

const SettingsArchiving = connect(mapStateToProps, mapDispatchToProps)(SettingsArchivingComponent);
export { SettingsArchiving };
