import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PodcastDiscover } from 'model/types';
import {
    DiscoverCarouselCardWrapper,
    Card,
    CardText,
    CardImageWrapper,
    CardPodcastTitle,
    CardFeaturedBadge,
    CardAuthor,
    CardDescription,
    CardSubscribeButton,
} from './DiscoverCarousel.styled';
import PodcastImage from '../../PodcastImage';

type Props = {
    podcast: PodcastDiscover;
    onPodcastClick: (podcast: PodcastDiscover, sponsoredListId?: string) => void;
    onSubscribe: (podcast: PodcastDiscover, sponsoredListId?: string) => void;
};

type StateProps = {
    clientXonMouseDown: boolean | null;
    mouseDownXCoordinate?: number;
};

class DiscoverCarouselCard extends React.Component<Props, StateProps> {
    constructor(props: Props) {
        super(props);
        this.state = {
            clientXonMouseDown: null,
        };
    }

    handleOnMouseDown = (e: React.MouseEvent) => {
        this.setState({
            mouseDownXCoordinate: e.clientX,
        });
        e.preventDefault(); // stops weird link dragging effect
    };

    didClickPodcast = (e: React.MouseEvent) => {
        const {
            podcast,
            podcast: { sponsoredListId },
            onPodcastClick,
        } = this.props;
        e.stopPropagation();
        if (this.state.mouseDownXCoordinate === e.clientX) {
            onPodcastClick(podcast, sponsoredListId);
        }
    };

    render() {
        const {
            podcast,
            podcast: { sponsoredListId },
            onSubscribe,
        } = this.props;

        const carouselCardAriaLabel = sponsoredListId ? 'Sponsored podcast' : 'Featured podcast';

        return (
            <DiscoverCarouselCardWrapper>
                <Card
                    role="button"
                    aria-label={carouselCardAriaLabel}
                    onMouseDown={this.handleOnMouseDown}
                    onClick={this.didClickPodcast}
                >
                    <CardImageWrapper>
                        <PodcastImage title={podcast.title || ''} uuid={podcast.uuid} />
                    </CardImageWrapper>
                    <CardText>
                        {podcast.sponsoredListId ? (
                            <CardFeaturedBadge isSponsored>
                                <FormattedMessage id="sponsored" />
                            </CardFeaturedBadge>
                        ) : (
                            <CardFeaturedBadge>
                                <FormattedMessage id="featured-all-caps" />
                            </CardFeaturedBadge>
                        )}
                        <CardPodcastTitle>{podcast.title || ''}</CardPodcastTitle>
                        <CardAuthor long={podcast.author != null && podcast.author.length > 30}>
                            {podcast.author || ''}
                        </CardAuthor>
                        <CardDescription tagName="p" overflowNode={<span>...</span>} lines={7}>
                            {podcast.description}
                        </CardDescription>
                    </CardText>
                    <CardSubscribeButton
                        podcastUuid={podcast.uuid}
                        onSubscribe={() => onSubscribe(podcast, sponsoredListId)}
                        eventSource="discover"
                        circled
                    />
                </Card>
            </DiscoverCarouselCardWrapper>
        );
    }
}

export default DiscoverCarouselCard;
