import 'isomorphic-fetch';
import { ContentSpec, DiscoverLayout, ListData } from 'model/types';
import { hexToRgb } from '../helper/ColorHelper';
import { THEME, getThemeFromId } from '../model/theme';
import { STATIC_URL } from '../settings';

const podcastMetadataUrl = (uuid: string) => `${STATIC_URL}/discover/images/metadata/${uuid}.json`;
const discoverContentUrl = () => `${STATIC_URL}/discover/web/content_v2.json`;
// const discoverContentUrl = () => `${STATIC_URL}/discover/web/content-carousel.json`;
const trendingEpisodesUrl = () => `${STATIC_URL}/discover/json/trending-episodes.json`;

/*
 * Generic GET request to a URL, when we expect a JSON response
 */
const getJsonFromUrl = <T>(url: string) =>
    fetch(url, { method: 'GET' })
        .then(response => {
            if (response.status !== 200) {
                return response
                    .text()
                    .then(text =>
                        Promise.reject(
                            Error(`${response.status} Error: ${response.statusText}: "${text}"`),
                        ),
                    );
            }

            return response;
        })
        .then(response => response.json() as T)
        .catch(() => {
            console.error(`Could not fetch JSON file required for Discover from: ${url}`);
            return Promise.resolve(null);
        });

const colorTooBright = (color: string) => {
    const rgb = hexToRgb(color);
    if (rgb === null) {
        return true;
    }
    return 0.2126 * rgb.r + 0.7152 * rgb.g + 0.0722 * rgb.b > 205;
};

const replaceDefaultRed = (color: string) =>
    color && (color.toLowerCase() === '#c62828' || color.toLowerCase() === '#f44336')
        ? getThemeFromId(THEME.dark).tokens['primary-interactive-01']
        : color;

function fixColorsOnDarkBg(color: string) {
    const rgb = hexToRgb(color);
    if (rgb) {
        return rgb.r > 160 && Math.abs(rgb.g - rgb.b) < 50
            ? getThemeFromId(THEME.dark).tokens['primary-interactive-01']
            : color;
    }
    return color;
}

function getDiscoverSponsoredPosts(section: DiscoverLayout) {
    const { sponsored_podcasts: sponsoredPodcasts, data } = section;

    if (!sponsoredPodcasts?.length || !data || !('podcasts' in data)) {
        return Promise.resolve(section);
    }

    const listDataPromises = sponsoredPodcasts.map(({ source, position }) =>
        getJsonFromUrl<ListData>(source).then(sponsoredList => ({ sponsoredList, position })),
    );
    return Promise.all(listDataPromises)
        .then(sponsoredList => {
            const sponsoredListsPodcasts = sponsoredList.reduce(
                (podcasts, { sponsoredList, position }) => {
                    const listPodcast = sponsoredList?.podcasts[0];
                    if (!listPodcast) {
                        return podcasts;
                    }

                    podcasts.splice(position, 0, {
                        ...listPodcast,
                        sponsoredListId: sponsoredList.list_id,
                    });

                    return podcasts;
                },
                data.podcasts,
            );

            return {
                ...data,
                podcasts: sponsoredListsPodcasts,
            };
        })
        .then(sectionData => {
            return {
                ...section,
                data: sectionData,
            };
        });
}

function addSponsoredPostsToSections(content: ContentSpec | null) {
    if (!content) {
        return Promise.resolve(null);
    }
    return Promise.all(content.layout.map(getDiscoverSponsoredPosts)).then(
        sectionsWithSponsoredPodcasts => {
            return {
                ...content,
                layout: sectionsWithSponsoredPodcasts,
            };
        },
    );
}

export default {
    getPodcastColors: (uuid: string) =>
        fetch(podcastMetadataUrl(uuid), { method: 'GET' })
            .then(response => {
                if (response.status !== 200) {
                    return Promise.reject(Error(response.statusText));
                }
                return response;
            })
            .then(response => response.json())
            .then(json => {
                const { colors } = json;
                return {
                    lightTint: replaceDefaultRed(
                        colorTooBright(colors.fabForLightBg)
                            ? colors.tintForLightBg
                            : colors.fabForLightBg,
                    ),
                    darkTint: fixColorsOnDarkBg(colors.tintForDarkBg),
                    darkTintUnsafe: replaceDefaultRed(colors.tintForDarkBg),
                    background: colors.background,
                };
            })
            .catch(() => {
                const color = getThemeFromId(THEME.dark).tokens['primary-interactive-01'];
                return {
                    lightTint: color,
                    darkTint: color,
                    darkTintUnsafe: color,
                    background: '#000000',
                };
            }),

    getDiscoverContent: () => getJsonFromUrl(discoverContentUrl()),

    getWebPlayerDiscoverContent: (region: string) => {
        let regionCode = region;
        return getJsonFromUrl<ContentSpec>(discoverContentUrl())
            .then(contentWithAllRegions => {
                regionCode = region ?? contentWithAllRegions?.default_region_code;
                const contentWithCorrectLayouts = contentWithAllRegions;
                if (contentWithCorrectLayouts) {
                    contentWithCorrectLayouts.layout = contentWithCorrectLayouts.layout.filter(
                        (section: DiscoverLayout) => section.regions.includes(regionCode),
                    );
                }
                return contentWithCorrectLayouts;
            })
            .then(content => {
                const regionalSources = content?.layout.map((section: DiscoverLayout) => {
                    if (section.regions.includes(regionCode)) {
                        return section.source;
                    }
                    return null;
                });

                return Promise.all(
                    (regionalSources || []).map(src => {
                        let source = src;
                        if (source == null) {
                            return Promise.resolve(null);
                        }
                        if (source.includes('[') || source.includes(']')) {
                            source = source.replace('[regionCode]', regionCode);
                        }

                        return getJsonFromUrl(source);
                    }),
                )
                    .then(datas => {
                        const newSections = content?.layout.map(
                            (section: DiscoverLayout, index: number) => {
                                return {
                                    ...section,
                                    data: datas[index],
                                    listName: section.source
                                        .split('/')
                                        .slice(-1)[0]
                                        .replace(/\.json/, ''),
                                };
                            },
                        );

                        // This is gross. Only doing it so we don't have to spread a whole new object.
                        if (content) {
                            content.layout = newSections as unknown as DiscoverLayout[];
                        }

                        return content;
                    })
                    .then(addSponsoredPostsToSections);
            });
    },

    getTrendingEpisodes: () =>
        fetch(trendingEpisodesUrl(), { method: 'GET' })
            .then(response => {
                if (response.status !== 200) {
                    return Promise.reject(Error(`${response.status}`));
                }
                return response;
            })
            .then(response => response.json())
            .then(json => json.result),
};
