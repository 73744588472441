import { Icon as IconComponent } from 'components/Icon';
import styled from 'styled-components';

export const TRANSITION_TIME_MS = 200;
const SIZE = 30;
const ICON_SIZE = 20;

export const ExpandedIcon = styled(IconComponent)`
    display: none;
`;
export const Icon = styled(IconComponent)`
    display: block;
`;

export const IconWrapper = styled.span`
    margin: ${(SIZE - ICON_SIZE) / 2}px;
    margin-right: 0;

    @media (prefers-reduced-motion: no-preference) {
        transition: margin ${TRANSITION_TIME_MS}ms ease-in-out;
    }
`;

export const Content = styled.span`
    display: inline-block;
    width: 0;
    overflow: hidden;

    @media (prefers-reduced-motion: no-preference) {
        transition: width ${TRANSITION_TIME_MS}ms ease-in-out,
            margin ${TRANSITION_TIME_MS}ms ease-in-out;
    }

    white-space: nowrap;
    margin-top: 2px;
    margin-right: ${(SIZE - ICON_SIZE) / 2}px;
`;

export const Button = styled.button<{
    contentWidth: number;
}>`
    background: ${props => props.theme.tokens['support-02']};
    color: #fff;
    display: flex;
    align-items: center;
    border-radius: 18px;
    text-align: left;

    /**
     * When moving back to default z-index, delay until the transition has completed so it doesn't hide under
     * siblings while its animating back to collapsed.
     */
    z-index: 0;

    @media (prefers-reduced-motion: no-preference) {
        transition: z-index 0ms linear ${TRANSITION_TIME_MS}ms;
    }

    &:not(.is-collapsed):hover,
    &:not(.is-collapsed).focus-visible,
    &.is-expanded {
        z-index: 1;
        transition: none; // Override z-index transition — we want the change to z-index: 1 to be instantaneous
        ${IconWrapper} {
            margin-left: ${SIZE - ICON_SIZE}px;
            margin-right: ${(SIZE - ICON_SIZE) / 2}px;
        }
        ${Content} {
            width: ${props => props.contentWidth + 2}px;
            margin-right: 10px;
        }
        ${ExpandedIcon} {
            display: block;
        }
        ${ExpandedIcon} + ${Icon} {
            display: none;
        }
    }

    &.is-expanded {
        svg, ${Content} { transition: none; }
    }
`;

export const Wrapper = styled.span<{ alignFlyout: 'left' | 'right' | undefined }>`
    display: inline-block;
    height: ${SIZE}px;
    ${props =>
        props.alignFlyout &&
        `
            position: relative;
            width: ${SIZE}px;

            ${Button} {
                position: absolute;
                top: 0;
                ${props.alignFlyout === 'left' ? 'left: 0;' : 'right: 0;'}
            }
    `}
`;
