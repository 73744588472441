import { Middleware } from 'redux';
import isEqual from 'lodash/isEqual';
import { addYears } from 'date-fns';
import Cookies from 'universal-cookie';
import {
    LOCAL_STORAGE_KEY_AUTOPLAY_CONFIG,
    LOCAL_STORAGE_KEY_EMAIL,
    LOCAL_STORAGE_KEY_SUBSCRIPTION_DATA,
} from 'model/local-storage';
import { RootState } from './reducers';

const cookies = new Cookies();

/**
 * This middleware checks Redux state before and after every action, and persists certain values to localStorage
 * when they change. This ensures that no matter which action caused a change, the change is saved and will be
 * accurate when the user reloads.
 */
const localStorageMiddleware: Middleware<unknown, RootState> = store => next => action => {
    // Get the store state before the action executes...
    const beforeState = store.getState();
    // Then let the action finish (and save the return value)...
    const result = next(action);
    // And now grab the store state again, after the reducers have been run against the action
    const afterState = store.getState();

    // Persist changes to email in localStorage, so on refresh we know whether the user was logged in
    if (beforeState.user.email !== afterState.user.email) {
        if (afterState.user.email === null) {
            localStorage.removeItem(LOCAL_STORAGE_KEY_EMAIL);
        } else {
            localStorage.setItem(LOCAL_STORAGE_KEY_EMAIL, afterState.user.email);
        }
    }

    // Persist changes to subscription data in localStorage, so on refresh we know if this is a paid customer
    if (!isEqual(beforeState.subscription.data, afterState.subscription.data)) {
        localStorage.setItem(
            LOCAL_STORAGE_KEY_SUBSCRIPTION_DATA,
            JSON.stringify(afterState.subscription.data),
        );
    }

    // Persist the "Autoplay enabled" setting in cookies, so it sticks beyond logout/login
    if (!isEqual(beforeState.autoplay.isEnabled, afterState.autoplay.isEnabled)) {
        cookies.set('autoplay', String(afterState.autoplay.isEnabled), {
            expires: addYears(new Date(), 1),
            path: '/',
        });
    }

    // Persist the "Autoplay config" setting in localStorage, to preserve it on refresh
    if (!isEqual(beforeState.autoplay.config, afterState.autoplay.config)) {
        localStorage.setItem(
            LOCAL_STORAGE_KEY_AUTOPLAY_CONFIG,
            JSON.stringify(afterState.autoplay.config),
        );
    }

    // Don't forget, middleware needs to return the result of next(action)!
    return result;
};

export default localStorageMiddleware;
