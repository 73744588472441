import { Button } from 'components/Button';
import { WebPlayerThemeToken } from 'model/theme';
import styled from 'styled-components';
import { gridSize } from 'styles';

export const Form = styled.form`
    margin-top: -${2 * gridSize + 1}px;
    width: 352px;
`;

export const FormSection = styled.div`
    margin-bottom: ${3 * gridSize}px;
`;

export const Footer = styled.div`
    margin: ${4 * gridSize}px 0 ${2 * gridSize}px;
    display: flex;
    flex-wrap: wrap;
    button[kind='primary'] {
        padding: 0 ${3 * gridSize}px;
        width: 100%;
    }
`;

export const ErrorMessage = styled.div`
    width: 100%;
    flex-shrink: 0;
    margin-bottom: ${2 * gridSize}px;
`;

export const TextButton = styled(Button)<{ colorToken: keyof WebPlayerThemeToken }>`
    display: flex;
    align-items: flex-start;
    text-align: left;
    width: 100%;
    color: ${props => props.theme.tokens['primary-text-01']};

    &:hover {
        color: ${props => props.theme.tokens[props.colorToken]};
    }
    svg {
        color: ${props => props.theme.tokens[props.colorToken]};
        margin: -3px 10px 0 0;
    }
`;
