import classNames from 'classnames';
import { IconId } from 'components/Icon';
import React, { ComponentProps, useState } from 'react';
import {
    Button,
    Content,
    ExpandedIcon,
    IconWrapper,
    Icon,
    Wrapper,
} from './ExpandingIconButton.styled';

const ICON_SIZE = 20;

export type Props = {
    alignFlyout?: 'left' | 'right';
    forceIsExpanded?: boolean;
    icon: IconId;
    expandedIcon?: IconId;
} & ComponentProps<typeof Button>;

const ExpandingIconButton = ({
    alignFlyout,
    children,
    className,
    expandedIcon,
    forceIsExpanded,
    icon,
    ...buttonProps
}: Props) => {
    // useRef will be null on initial render, and setting the ref won't cause a re-render. This means
    // that until something _else_ triggers a second render, the code below won't have access to the
    // ref. But putting the ref in state solves this problem because as soon as the ref is set, the
    // component will re-render, meaning we'll have access to the correct content DOM object for sizing.
    const [contentRef, setContentRef] = useState<HTMLSpanElement | null>(null);

    const buttonClassNames = classNames(className, {
        'is-expanded': forceIsExpanded === true,
        'is-collapsed': forceIsExpanded === false,
    });

    return (
        <Wrapper alignFlyout={alignFlyout}>
            <Button
                className={buttonClassNames}
                contentWidth={contentRef?.offsetWidth ?? 0}
                {...buttonProps}
            >
                <IconWrapper>
                    {expandedIcon && <ExpandedIcon id={expandedIcon} size={ICON_SIZE} />}
                    <Icon id={icon} size={ICON_SIZE} />
                </IconWrapper>
                <Content>
                    <span ref={setContentRef}>{children}</span>
                </Content>
            </Button>
        </Wrapper>
    );
};

export default ExpandingIconButton;
