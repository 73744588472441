import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Typography } from '../../../styles';

export const WrapperLink = styled(Link)`
    text-decoration: none;
    width: 100%;
    height: 60px;
    position: relative;

    .sub5cribe-button {
        position: absolute;
        right: 32px;
        top: 15px;
        display: none;
        &.sub5cribed {
            display: block;
        }
    }

    &:hover .sub5cribe-button {
        display: block;
    }
`;

export const Rank = styled.div`
    ${Typography.Desktop.H70};
    color: ${props => props.theme.tokens['primary-text-02']};
    height: 0;
    overflow: visible;
    margin-left: -20px;
`;

export const Image = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 60px;
`;

export const Text = styled.div`
    padding-left: 79px;
    padding-top: 8px;
    padding-right: 60px;
`;

export const Title = styled.div`
    color: ${props => props.theme.tokens['primary-text-01']};
    overflow: hidden;

    ${Typography.Desktop.H60};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
`;
export const Subtitle = styled.div`
    ${Typography.Desktop.H70};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 250px;
    color: ${props => props.theme.tokens['primary-text-02']};
`;
