import React from 'react';
import { FormattedMessage } from 'react-intl';

export const FUNNY_QUOTES = [
    {
        message: (number: number | string) => (
            <FormattedMessage id="stats-funny-quote-1" values={{ number }} />
        ),
        perMin: 250,
    },
    {
        message: (number: number | string) => (
            <FormattedMessage id="stats-funny-quote-2" values={{ number }} />
        ),
        perMin: 15,
    },
    {
        message: (number: number | string) => (
            <FormattedMessage id="stats-funny-quote-3" values={{ number }} />
        ),
        perMin: 6000,
    },
    {
        message: (number: number | string) => (
            <FormattedMessage id="stats-funny-quote-4" values={{ number }} />
        ),
        perMin: 35000,
    },
    {
        message: (number: number | string) => (
            <FormattedMessage id="stats-funny-quote-5" values={{ number }} />
        ),
        perMin: 0.00694,
    },
    {
        message: (number: number | string) => (
            <FormattedMessage id="stats-funny-quote-6" values={{ number }} />
        ),
        perMin: 144000000,
    },
    {
        message: (number: number | string) => (
            <FormattedMessage id="stats-funny-quote-7" values={{ number }} />
        ),
        perMin: 350000,
    },
    {
        message: (number: number | string) => (
            <FormattedMessage id="stats-funny-quote-8" values={{ number }} />
        ),
        perMin: 0.77,
    },
    {
        message: (number: number | string) => (
            <FormattedMessage id="stats-funny-quote-9" values={{ number }} />
        ),
        perMin: 6,
    },
    {
        message: (number: number | string) => (
            <FormattedMessage id="stats-funny-quote-10" values={{ number }} />
        ),
        perMin: 0.0002314,
    },
    {
        message: (number: number | string) => (
            <FormattedMessage id="stats-funny-quote-11" values={{ number }} />
        ),
        perMin: 3780000,
    },
    {
        message: (number: number | string) => (
            <FormattedMessage id="stats-funny-quote-12" values={{ number }} />
        ),
        perMin: 212,
    },
    {
        message: (number: number | string) => (
            <FormattedMessage id="stats-funny-quote-13" values={{ number }} />
        ),
        perMin: 86640,
    },
];

export const createFunnyQuote = (secs: number, locale?: string) => {
    if (!secs || secs < 60) {
        return <FormattedMessage id="stats-not-much" />;
    }

    let count = 0;
    while (count < 50) {
        count += 1;

        const quote = FUNNY_QUOTES[Math.floor(Math.random() * FUNNY_QUOTES.length)];
        const minutes = secs / 60;
        const amount = minutes * quote.perMin;

        if (amount >= 1) {
            let amountFloor: number | string = 1;

            try {
                amountFloor = Math.floor(amount).toLocaleString(locale);
            } catch {
                amountFloor = Math.floor(amount).toString();
            }

            return quote.message(amountFloor);
        }
    }

    return '';
};
