import { Icon } from 'components/Icon';
import { PlayPauseButton } from 'components/PlayPauseButton';
import useFormatMessage from 'hooks/useFormatMessage';
import React, { MouseEventHandler } from 'react';
import {
    ClearButton,
    Description,
    Image,
    PlayButton,
    SmallText,
    SubscribedIcon,
    Text,
    Title,
    WrapperLink,
} from './SearchResultItem.styled';

export type Props = {
    image: React.ReactNode;
    title: string | React.ReactNode;
    description?: string | React.ReactNode;
    smallText?: string | React.ReactNode;
    subscribed?: boolean;
    href?: string;
    isPlaying?: boolean;
    onClick?: MouseEventHandler;
    onClear?: () => void;
    onPlayPauseClick?: MouseEventHandler;
    'aria-label'?: string;
};

const SearchResultItem = ({
    description,
    href = '#',
    image,
    isPlaying,
    onClick,
    onClear,
    onPlayPauseClick,
    smallText,
    subscribed = false,
    title,
    'aria-label': ariaLabel,
}: Props) => {
    const formatMessage = useFormatMessage();
    const handleClearClick: MouseEventHandler = e => {
        e.preventDefault();
        e.stopPropagation();
        onClear && onClear();
    };

    return (
        <WrapperLink
            to={href}
            onClick={onClick}
            className="search-result-item"
            aria-label={ariaLabel}
        >
            <Image>{image}</Image>
            <Text>
                {smallText && <SmallText>{smallText}</SmallText>}
                <Title>{title}</Title>
                {description && <Description>{description}</Description>}
            </Text>
            {onPlayPauseClick && (
                <PlayButton>
                    <PlayPauseButton onClick={onPlayPauseClick} isPlaying={isPlaying} />
                </PlayButton>
            )}
            {subscribed && <SubscribedIcon id="tick" />}
            {onClear && (
                <ClearButton type="button" kind="text" onClick={handleClearClick}>
                    <Icon id="cancel" aria-label={formatMessage('remove-history')} />
                </ClearButton>
            )}
        </WrapperLink>
    );
};

export default SearchResultItem;
