import React, { Component } from 'react';
import { calculatePartialRingPath } from '../../../../../helper/SvgHelper';
import { green, orange, red } from '../../../../../styles';
import { getThemeFromId } from '../../../../../model/theme';
import { hexToRgb } from '../../../../../helper/ColorHelper';

export class UsageMeter extends Component {
    renderColoredArc(percent, color) {
        const normalPercent = Math.max(0, Math.min(100, percent));

        return (
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d={calculatePartialRingPath(33, 32, 26, 270, 270 + 180 * (normalPercent / 100))}
                stroke={color}
                fill="none"
                strokeWidth="5"
            />
        );
    }

    renderRemainingArc(percent, theme) {
        const normalPercent = Math.max(0, Math.min(100, percent));

        // If we're not really rendering anything, don't.
        if (5 + 270 + 180 * (normalPercent / 100) > 445) {
            return null;
        }

        // Edge does not yet support #RRGGBBAA syntax
        const { r, g, b } = hexToRgb(getThemeFromId(theme).legacyScss['theme-background']);

        return (
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d={calculatePartialRingPath(33, 32, 26, 5 + 270 + 180 * (normalPercent / 100), 450)}
                stroke={`rgba(${r}, ${g}, ${b}, 0.2)`}
                fill="none"
                strokeWidth="5"
            />
        );
    }

    render() {
        const { percent, percentExact, theme } = this.props;

        const textX = 33;
        const textY = 32;

        const color = percentExact < 90 ? green : percentExact < 99 ? orange : red;

        return (
            <svg width="66" height="36" viewBox="0 0 66 36">
                <text textAnchor="middle" x={textX} y={textY} fill={color}>
                    {percent}%
                </text>
                {this.renderColoredArc(percent, color)}
                {this.renderRemainingArc(percent, theme)}
            </svg>
        );
    }
}

export default UsageMeter;
