const TRACKS_SORT_ORDER: Record<string, string> = {
    'name-ascending': 'title_z_to_a',
    'name-descending': 'title_a_to_z',
    'size-ascending': 'smallest_to_largest',
    'size-descending': 'largest_to_smallest',
    'duration-ascending': 'shortest_to_longest',
    'duration-descending': 'longest_to_shortest',
    'date-ascending': 'oldest_to_newest',
    'date-descending': 'newest_to_oldest',
};

/**
 * Return the sort order as understood/necessary by the Tracks system for
 * the given sort column and sort order.
 *
 * @param sortColumn the name of the column to sort by
 * @param sortOrder the direction to sort by (ascending or descending)
 * @returns the sort order as understood by the Tracks system
 */
export const getTracksSortOrder = (sortColumn: string, sortOrder: string) => {
    const key = `${sortColumn}-${sortOrder}`;
    return TRACKS_SORT_ORDER[key];
};
