import styled from 'styled-components';
import { blueTextLinkMixin, gridSize, preventFlexboxOverflow, Typography } from 'styles';

export const STEP_TRANSITION_TIME_MS = 150;
const HORIZONTAL_MARGIN_SIZE = 24;

export const SlidingStepContainer = styled.div`
    display: flex;
    transition: transform ${STEP_TRANSITION_TIME_MS}ms ease-in-out;
    height: 100%;
`;

export const MultiStepWrapper = styled.div<{ stepCount: number; currentStep: number }>`
    width: 500px;
    height: 70vh;
    min-height: 400px;
    max-height: 800px;
    overflow: hidden;
    margin: 0 -${HORIZONTAL_MARGIN_SIZE}px;

    ${SlidingStepContainer} {
        width: ${props => props.stepCount * 100}%;
        transform: translateX(${props => (props.currentStep / props.stepCount) * -100}%);
    }
`;

export const Step = styled.div<{ isOpen: boolean }>`
    min-width: 0;
    padding: 0 ${HORIZONTAL_MARGIN_SIZE}px;
    display: flex;
    flex-direction: column;
    flex-basis: 0;
    flex-grow: 1;
    visibility: ${props => (props.isOpen ? 'visible' : 'hidden')};
    transition: visibility ${STEP_TRANSITION_TIME_MS}ms;
`;

export const FormSection = styled.div<{ fullWidth?: boolean }>`
    margin-bottom: ${2 * gridSize}px;
    min-height: 0;

    ${props =>
        props.fullWidth &&
        `
            margin-left: -${HORIZONTAL_MARGIN_SIZE}px;
            margin-right: -${HORIZONTAL_MARGIN_SIZE}px;
        `}
`;

export const Footer = styled.div`
    margin: auto 0 ${2 * gridSize}px;
    display: flex;
    flex-wrap: wrap;
    button[kind='primary'] {
        padding: 0 ${3 * gridSize}px;
        margin-left: auto;
        // This button is first in markup so you tab into it before the back button,
        // but we want it visually second
        order: 2;
    }
    button[type='button'] {
        ${blueTextLinkMixin}
    }
`;

export const ErrorMessage = styled.div`
    width: 100%;
    flex-shrink: 0;
    margin: ${2 * gridSize}px 0;
`;

export const Preview = styled.section`
    display: flex;
    align-items: center;
    margin-top: ${4 * gridSize}px;
    padding-top: ${3 * gridSize}px;
    border: 1px solid ${props => props.theme.tokens['primary-ui-05']};
    border-width: 1px 0 0;
`;

export const PreviewText = styled.div`
    ${Typography.Desktop.H65};
    color: ${props => props.theme.tokens['primary-text-02']};
    // We want screen readers to encounter the text before the FolderImage, but for
    // visual users we want the FolderImage left of the text, so we change the order
    order: 2;
    margin-left: 16px;
    ${preventFlexboxOverflow};
`;

export const PreviewName = styled.h1`
    ${Typography.Desktop.H60};
    color: ${props => props.theme.tokens['primary-text-01']};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const PreviewImage = styled.div`
    flex-shrink: 0;
`;
