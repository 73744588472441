import { ErrorMessageOnPlayFileNotUploaded } from 'components/messages';
import { Logger } from 'helper/Logger';
import shuffle from 'lodash/shuffle';
import {
    Episode,
    FullEpisode,
    ListData,
    PodcastCacheParsed,
    RecommendedEpisode,
    TodoFixmeMigrationType,
    UploadedFile,
    UpNextState,
} from 'model/types';
import { seekPlayerTo } from 'pages/LoggedInPageChrome/PlayerControls/PlayerControls';
import { buffers, Channel } from 'redux-saga';
import {
    actionChannel,
    ActionChannelEffect,
    all,
    call,
    delay,
    put,
    select,
    take,
    takeEvery,
    takeLatest,
    takeLeading,
    throttle,
} from 'redux-saga/effects';
import { clearAutoplay } from 'redux/actions/autoplay.actions';
import {
    getAutoplayConfig,
    getNextAutoplayEpisode,
} from 'redux/reducers/selectors/autoplay.selectors';
import { getThemeColorsForUploadedFileFromColorId } from '../../helper/ColorHelper';
import * as EpisodeHelper from '../../helper/EpisodeHelper';
import { PlayingStatus } from '../../helper/PlayingStatus';
import * as ResumptionHelper from '../../helper/ResumptionHelper';
import {
    getUploadedFileIconUrl,
    isUploadedFile,
    UPLOADED_FILE_COLORS,
    UPLOADED_FILES_PODCAST_UUID,
} from '../../model/uploaded-files';
import { api } from '../../services/api';
import cacheApi from '../../services/cacheApi';
import * as filesApi from '../../services/filesApi';
import { getPodcastList } from '../../services/listsApi';
import * as fromFlagsActions from '../actions/flags.actions';
import * as fromHistoryActions from '../actions/history.actions';
import * as fromPlayerActions from '../actions/player.actions';
import * as fromPodcastActions from '../actions/podcast.actions';
import * as fromPodcastsActions from '../actions/podcasts.actions';
import * as fromSettingsActions from '../actions/settings.actions';
import * as fromStatsActions from '../actions/stats.actions';
import * as fromTracksActions from '../actions/tracks.actions';
import * as fromUpNextActions from '../actions/up-next.actions';
import * as fromUploadedFilesActions from '../actions/uploaded-files.actions';
import * as fromUserActions from '../actions/user.actions';
import {
    getAutoSkipLast,
    getAutoStartFrom,
    getCurrentRecommendations,
    getPlayerEpisode,
    getPlayerIsPlaying,
    getPodcastByUuid,
    getSettings,
    getSubscribedPodcastUuids,
    getTheme,
    getUploadedFile,
    getUpNext,
    getUpNextEpisodeWithUuid,
    getUpNextServerModified,
    getUpNextTopEpisode,
    isLoadingRecommendedEpisodes,
} from '../reducers/selectors';
import { downloadPodcast, downloadPodcasts } from './podcasts.saga';
import { logSagaError } from './saga-helper';
import { fetchSubscriptionData } from './subscription.saga';
import { fetchUploadedFilesData, getUploadedFileFetchingIfNecessary } from './uploaded-files.saga';
import { downloadSettings } from './user.saga';

function* downloadEpisodeDetails(episode: {
    uuid: string;
    podcastUuid: string;
}): TodoFixmeMigrationType {
    const { uuid, podcastUuid } = episode;

    const [episodeSyncData] = yield all([
        call(api.getEpisodeSyncData, {
            uuid,
            podcast: podcastUuid,
        }),
        downloadPodcast(fromPodcastsActions.Actions.downloadPodcast(podcastUuid, true)),
    ]);

    // Try to retrieve the canonical episode information from the podcast feed as podcastEpisode
    const podcast = yield select(getPodcastByUuid, podcastUuid);
    const episodes = podcast?.episodes || [];
    const podcastEpisode = episodes.find((ep: TodoFixmeMigrationType) => ep.uuid === episode.uuid);

    // Also grab episode info from Up Next, because even if the episode has been removed from the
    // podcast feed the URL saved in Up Next data might still work
    const upNextEpisode = yield select(getUpNextEpisodeWithUuid, uuid);

    // We return an object that combines the various data sources to make the best representation
    // we have of the requested episode.
    // - At the very least, we return the minimal episode data this function received.
    // - If we have data from Up Next, we use that for a fallback.
    // - If there's a canonical podcastEpisode, that overwrites the Up Next data.
    // - And the user's sync data (like playedUpTo and playingStatus) are layered on top.
    return {
        ...episode,
        ...upNextEpisode,
        ...podcastEpisode,
        ...episodeSyncData,
    };
}

function* fillInUpNextFileDetails(upNext: TodoFixmeMigrationType) {
    for (const uuid of upNext.order) {
        const episode = upNext.episodes[uuid];
        const podcastUuid = episode && episode.podcastUuid;

        if (podcastUuid === UPLOADED_FILES_PODCAST_UUID) {
            const file: UploadedFile = yield select(getUploadedFile, uuid);
            if (file) {
                // File is in our cloud so add the correct details.
                const upNextItem = upNext.episodes[uuid];
                if (upNextItem) {
                    upNextItem.title = file.title;
                    upNextItem.imageUrl = file.imageUrl;
                    upNextItem.exists = true;
                }
            } else {
                // File is not in our cloud, so add some placeholder details. The podcast field
                // will never otherwise be transformed to podcastUuid, so add that as well.
                const theme: ReturnType<typeof getTheme> = yield select(getTheme);
                const upNextItem = upNext.episodes[uuid];
                if (upNextItem) {
                    upNextItem.title = episode.title;
                    upNextItem.podcastUuid = UPLOADED_FILES_PODCAST_UUID;
                    upNextItem.imageUrl = getUploadedFileIconUrl(
                        theme,
                        UPLOADED_FILE_COLORS.noColor,
                    );
                    upNextItem.exists = false;
                }
            }
        }
    }
}

function* addSkipFirstStats(episode: { duration: number }, autoStartFrom: number) {
    const timeSavedInMs = (() => {
        // Ensure that stats don't record more than the duration of the episode being skipped
        // eg. skip first for a podcast being 40 mins - but an episode only being 2 mins long
        if (autoStartFrom > episode.duration) {
            return episode.duration * 1000;
        }
        return autoStartFrom * 1000;
    })();

    yield put(fromStatsActions.Actions.addTimeSavedAutoSkipping(timeSavedInMs));
    yield put(fromStatsActions.Actions.uploadStats());
}

function* downloadUpNext() {
    const serverModified: ReturnType<typeof getUpNextServerModified> = yield select(
        getUpNextServerModified,
    );

    try {
        const upNext: UpNextState = yield call(api.fetchUpNextList, serverModified);
        yield fillInUpNextFileDetails(upNext);
        yield put(fromUpNextActions.Actions.upNextChanged(upNext));
    } catch (error) {
        if (error instanceof Error && error.message === '304') {
            const upNextState: ReturnType<typeof getUpNext> = yield select(getUpNext);
            const upNext = JSON.parse(JSON.stringify(upNextState));
            yield fillInUpNextFileDetails(upNext);
            yield put(fromUpNextActions.Actions.upNextChanged(upNext));
        } else {
            logSagaError('Failed to download Up Next', error);
            yield put(fromUpNextActions.Actions.upNextDownloadFailed());
        }
    }
}

function* loadEpisode(
    episodeToLoad: TodoFixmeMigrationType,
    isPlaying: boolean,
    seekTo?: number, // Optionally start playback at a specific time
): TodoFixmeMigrationType {
     
    let episode = episodeToLoad;

    // If the episode doesn't have a podcastUuid for some reason, try to use episode.podcast instead
    if (!episode.podcastUuid) {
        episode.podcastUuid = episode.podcast;
    }
    const { uuid, podcastUuid } = episode;

    // Uploaded Files have to dynamically fetch their play URL and their image file
    if (podcastUuid === UPLOADED_FILES_PODCAST_UUID) {
        const { colorId } = episode;
        const colors = getThemeColorsForUploadedFileFromColorId(colorId);
        yield put(fromPodcastsActions.Actions.updatePodcastColors(podcastUuid, colors));

        // If we get to here, the File will definitely exist in our cloud
        const { imageUrl } = yield getUploadedFileFetchingIfNecessary(uuid);
        const { url } = yield call(filesApi.getMediaFileOfUploadedFile, uuid);

        episode.imageUrl = imageUrl;
        episode.url = url;
    } else if (podcastUuid) {
        episode = yield downloadEpisodeDetails(episodeToLoad);

        if (episode.playingStatus !== PlayingStatus.IN_PROGRESS) {
            const autoStartFrom: ReturnType<typeof getAutoStartFrom> = yield select(
                getAutoStartFrom,
                episodeToLoad.podcastUuid,
            );
            episode.playedUpTo = autoStartFrom;
            yield addSkipFirstStats(episode, autoStartFrom);
        }

        yield put(fromPodcastActions.Actions.downloadPodcastColor(podcastUuid));
    }

    const settings: ReturnType<typeof getSettings> = yield select(getSettings);

    // Clean up the URL and duration
    EpisodeHelper.cleanEpisode(episode);

    // Optionally override the episode's playedUpTo, to start playback at the specified seek time
    episode.playedUpTo = seekTo ?? ResumptionHelper.adjustStartTimeIfNeeded(episode, settings);

    // Let's go
    yield put(fromPlayerActions.Actions.loadEpisode(episode, isPlaying));
}

function* downloadRecommendations() {
    try {
        const settings: ReturnType<typeof getSettings> = yield select(getSettings);

        if (!settings.recommendationsOn) {
            return;
        }

        yield put(fromPlayerActions.Actions.loadingRecommendedEpisodes());

        const episodes: RecommendedEpisode[] = yield call(api.fetchEpisodeRecommendations);

        if (episodes.length < 3) {
            const trendingList: ListData = yield call(getPodcastList, 'trending');
            const subscribedUuids: ReturnType<typeof getSubscribedPodcastUuids> = yield select(
                getSubscribedPodcastUuids,
            );
            const newUuids = shuffle(
                trendingList.podcasts
                    .map((podcast: TodoFixmeMigrationType) => podcast.uuid)
                    .filter((uuid: string) => subscribedUuids.indexOf(uuid) === -1),
            );

            const originalEpisodesLength = episodes.length;
            let successfulFetchCount = 0;

            // Calls to the cache server may fail. In that case, we just want
            // to continue on to the next podcast in the trending list
            for (const uuid of newUuids) {
                let response: PodcastCacheParsed | null = null;

                try {
                    response = yield call(cacheApi.getPodcast, uuid);
                } catch (error) {
                    logSagaError('Failed to fetch podcast for recommendations.', error);
                     
                    continue;
                }

                if (response && response.episodes && response.episodes.length > 0) {
                    const episode = response.episodes[0];

                    episodes.push({
                        podcastUuid: uuid,
                        podcastTitle: response.title,
                        uuid: episode.uuid,
                        url: episode.url,
                        title: episode.title,
                        published: episode.published,
                        duration: episode.duration,
                    } as TodoFixmeMigrationType);
                }

                yield put(fromPodcastsActions.Actions.downloadPodcast(uuid));
                successfulFetchCount += 1;

                if (successfulFetchCount === 3 - originalEpisodesLength) {
                    break;
                }
            }
        }

        yield put(fromPlayerActions.Actions.loadedRecommendedEpisodes(episodes));
    } catch (error) {
        logSagaError('Failed to download recommendations', error);
    }
}

function* upNextChanged(): TodoFixmeMigrationType {
    try {
        const topEpisode: ReturnType<typeof getUpNextTopEpisode> = yield select(
            getUpNextTopEpisode,
        );

        if (topEpisode === null) {
            // There's no episode in Up Next — let's check if there's an Autoplay episode.
            // Ideally this logic could live in Autoplay middleware (perhaps attached to episodeFinished)
            // but we need it here to prevent recommendations from being loaded when an autoplay episode
            // is being queued. Otherwise you'll always see a flash of recommendations between each
            // autoplayed episode. Long-term, it probably makes sense to refactor this logic so that
            // when there's truly no next episode, `state.player.episode` is cleared, and that signals
            // the recommendations UI to appear and fetch recommendations itself.
            const nextAutoplayEpisode: ReturnType<typeof getNextAutoplayEpisode> = yield select(
                getNextAutoplayEpisode,
            );
            const autoplayConfig: ReturnType<typeof getAutoplayConfig> = yield select(
                getAutoplayConfig,
            );

            const playing: ReturnType<typeof getPlayerIsPlaying> = yield select(getPlayerIsPlaying);

            if (nextAutoplayEpisode) {
                if (!playing) {
                    yield loadEpisode(nextAutoplayEpisode, false);
                    return;
                }

                const podcastUuid =
                    'podcastUuid' in nextAutoplayEpisode
                        ? nextAutoplayEpisode.podcastUuid
                        : UPLOADED_FILES_PODCAST_UUID;

                // playEpisode is usually reserved for when a user initiates playback (e.g. not when Up Next
                // plays the next episode). However it does some important things like normalizing the episode
                // data and adding the episode to Up Next (loadEpisode doesn't do those things). Ideally we
                // could refactor so there's always a consistent way that the next episode is added to the player,
                // but for now that refactor is too expensive.
                yield put(
                    fromPlayerActions.Actions.playEpisode(
                        nextAutoplayEpisode.uuid,
                        podcastUuid,
                        { eventSource: null },
                        { autoplay: autoplayConfig },
                    ),
                );

                yield put(
                    fromTracksActions.Actions.recordEvent('playback_episode_autoplayed', {
                        episode_source: autoplayConfig?.source ?? 'unknown',
                        episode_uuid: nextAutoplayEpisode.uuid,
                        content_type: EpisodeHelper.getContentType(nextAutoplayEpisode),
                    }),
                );

                Logger.log(
                    `Autoplaying episode ${nextAutoplayEpisode.uuid}. Config: ${JSON.stringify(
                        autoplayConfig,
                    )}`,
                    true,
                );
                return;
            }

            if (autoplayConfig) {
                // If there's no Autoplay episodes, but Autoplay is still active, clear it
                yield put(clearAutoplay());
                yield put(
                    fromTracksActions.Actions.recordEvent('autoplay_finished_last_episode', {
                        episode_source: autoplayConfig.source,
                    }),
                );
                Logger.log(
                    `Autoplay stopped, last episode finished. Config: ${JSON.stringify(
                        autoplayConfig,
                    )}`,
                    true,
                );
            }

            yield put(fromPlayerActions.Actions.closePlayer());

            const recommendationsAreLoading: boolean = yield select(isLoadingRecommendedEpisodes);
            const recommendations: ReturnType<typeof getCurrentRecommendations> = yield select(
                getCurrentRecommendations,
            );
            const noRecommendations = !recommendations || !recommendations.length;

            if (noRecommendations && !recommendationsAreLoading) {
                yield put(fromPlayerActions.Actions.downloadRecommendations());
            }

            return;
        }

        const playerEpisode: Episode = yield select(getPlayerEpisode);
        const topEpisodeUuid = topEpisode.uuid;

        if (playerEpisode === null || playerEpisode.uuid !== topEpisodeUuid) {
            const playing: ReturnType<typeof getPlayerIsPlaying> = yield select(getPlayerIsPlaying);

            if (topEpisode.podcastUuid === UPLOADED_FILES_PODCAST_UUID) {
                const fileEpisode: TodoFixmeMigrationType = yield getUploadedFileFetchingIfNecessary(
                    topEpisodeUuid,
                );

                if (!fileEpisode) {
                    yield put(fromUpNextActions.Actions.closeUpNext());
                    yield put(
                        fromFlagsActions.Actions.addFlag(ErrorMessageOnPlayFileNotUploaded()),
                    );
                    yield put(fromPlayerActions.Actions.loadUnplayableFile(topEpisode));
                } else if (EpisodeHelper.isPlayed(fileEpisode)) {
                    yield put(
                        fromUpNextActions.Actions.removeFromUpNext(topEpisodeUuid, {
                            eventSource: null,
                        }),
                    );
                } else {
                    fileEpisode.podcastUuid = UPLOADED_FILES_PODCAST_UUID;
                    // This is here so that the currently played-up-to value can never overshoot an episode
                    // that might be loaded into the Up Next list in the rest of this function. If no new
                    // episode is loaded in, it will just pick up the current played-up-to value again.
                    yield put(fromPlayerActions.Actions.updatePlayedUpTo(topEpisodeUuid, 0));
                    yield loadEpisode(fileEpisode as TodoFixmeMigrationType, playing);
                }
            } else if (EpisodeHelper.isPlayed(topEpisode as TodoFixmeMigrationType)) {
                yield put(
                    fromUpNextActions.Actions.removeFromUpNext(topEpisodeUuid, {
                        eventSource: null,
                    }),
                );
            } else if (topEpisode) {
                yield loadEpisode(topEpisode as TodoFixmeMigrationType, playing);
            }
        }
    } catch (error) {
        logSagaError('Failed in Up Next Changed', error);
    }
}

function* playEpisode(
    action: ReturnType<typeof fromPlayerActions.Actions.playEpisode>,
): TodoFixmeMigrationType {
    const {
        episodeUuid,
        podcastUuid,
        tracksProperties: { eventSource },
        options: { seekTo } = {},
    } = action.payload;

    const settings: ReturnType<typeof getSettings> = yield select(getSettings);
    let playerEpisode: TodoFixmeMigrationType = yield select(getPlayerEpisode);

    try {
        const isNewEpisode = playerEpisode === null || playerEpisode.uuid !== episodeUuid;
        const isUploadedFile = podcastUuid === UPLOADED_FILES_PODCAST_UUID;

        // The simple case is just play the episode that's there
        // The complex case is adding a new episode to the Player
        if (!isNewEpisode) {
            if (typeof seekTo === 'number') {
                seekPlayerTo(seekTo, eventSource);
            } else {
                const adjustedStartTime = ResumptionHelper.adjustStartTimeIfNeeded(
                    playerEpisode,
                    settings,
                );
                if (adjustedStartTime !== Math.round(playerEpisode.playedUpTo)) {
                    seekPlayerTo(adjustedStartTime, eventSource);
                }
            }

            yield put(fromPlayerActions.Actions.play());
        } else {
            if (isUploadedFile) {
                const file: UploadedFile = yield getUploadedFileFetchingIfNecessary(episodeUuid);

                if (!file) {
                    yield put(fromUpNextActions.Actions.closeUpNext());
                    yield put(
                        fromFlagsActions.Actions.addFlag(ErrorMessageOnPlayFileNotUploaded()),
                    );
                    return;
                }

                playerEpisode = {
                    uuid: file.uuid,
                    title: file.title,
                    fileType: file.contentType,
                    duration: file.duration,
                    published: file.published,
                    podcastUuid: UPLOADED_FILES_PODCAST_UUID,
                    podcastTitle: '',
                    size: file.size,
                    playedUpTo:
                        file.playingStatus === PlayingStatus.COMPLETED ? 0 : file.playedUpTo,
                    playingStatus: PlayingStatus.IN_PROGRESS,
                    colorId: file.color,
                };

                yield put(
                    fromUploadedFilesActions.Actions.requestUpdateFile(file.uuid, {
                        playingStatus: PlayingStatus.IN_PROGRESS,
                        playedUpTo: playerEpisode.playedUpTo,
                    }),
                );

                yield loadEpisode(playerEpisode, false, seekTo);
            } else {
                yield loadEpisode(
                    {
                        podcastUuid,
                        uuid: episodeUuid,
                    },
                    false,
                    seekTo,
                );

                playerEpisode = yield select(getPlayerEpisode);
                playerEpisode.playingStatus = PlayingStatus.IN_PROGRESS;

                yield put(
                    fromPodcastActions.Actions.markAsInProgress(
                        playerEpisode.uuid,
                        playerEpisode.podcastUuid,
                        playerEpisode.playedUpTo,
                    ),
                );
            }

            yield put(fromPlayerActions.Actions.play());
            yield put(fromUpNextActions.Actions.upNextPlayNow(podcastUuid, playerEpisode));

            // Playing an episode will re-show the player if the user explicitly closed the
            // Recommendations player. Now the player is showing again, we want to re-enable
            // recommendations. This action is idempotent.
            yield put(fromSettingsActions.Actions.showRecommendations());
        }

        if (eventSource !== null) {
            yield put(
                fromTracksActions.Actions.recordEvent('playback_play', {
                    source: eventSource,
                    content_type: EpisodeHelper.getContentType(playerEpisode),
                }),
            );
        }

        if (!isUploadedFile) {
            yield put(fromHistoryActions.Actions.addHistory(podcastUuid, playerEpisode));
        }
    } catch (error) {
        if (eventSource !== null) {
            yield put(
                fromTracksActions.Actions.recordEvent('playback_failed', {
                    source: eventSource,
                    error: error instanceof Error ? error.message : String(error),
                    episode_uuid: episodeUuid,
                    content_type: EpisodeHelper.getContentType(playerEpisode),
                }),
            );
        }
        logSagaError('Failed in Play Episode', error);
    }
}

export function* pauseEpisode(action: ReturnType<typeof fromPlayerActions.Actions.pause>) {
    const {
        tracksProperties: { eventSource },
    } = action.payload;

    const playerEpisode: FullEpisode = yield select(getPlayerEpisode);

    yield put(
        fromTracksActions.Actions.recordEvent('playback_pause', {
            source: eventSource,
            content_type: EpisodeHelper.getContentType(playerEpisode),
        }),
    );

    ResumptionHelper.playbackPaused(playerEpisode);
}

export function* updateDuration(
    action: ReturnType<typeof fromPlayerActions.Actions.updateDuration>,
) {
    try {
        yield call(
            api.saveDuration,
            action.payload.episodeUuid,
            action.payload.podcastUuid,
            Math.floor(action.payload.duration),
        );
    } catch (error) {
        logSagaError('Failed to save duration change', error);
    }
}

export function* updatePlayedUpTo() {
    const isPlaying: boolean = yield select(getPlayerIsPlaying);
    if (isPlaying) {
        const episode: FullEpisode = yield select(getPlayerEpisode);
        const autoSkipLast: number = yield select(getAutoSkipLast, episode.podcastUuid);
        const timeRemaining = episode.duration - episode.playedUpTo;
        if (
            episode.duration > 0 &&
            episode.duration > autoSkipLast &&
            timeRemaining < autoSkipLast &&
            autoSkipLast > 0
        ) {
            yield put(
                fromPodcastActions.Actions.markAsPlayed(episode.uuid, episode.podcastUuid, {
                    eventSource: null,
                }),
            );

            const timeSkippedInMs = timeRemaining * 1000;
            yield put(fromStatsActions.Actions.addTimeSavedAutoSkipping(timeSkippedInMs));

            yield put(fromStatsActions.Actions.uploadStats());

            /**
             * Add a small throttle to ensure that the skip last logic doesn't get executed more than once. This throttle
             * blocks the skip last logic getting executed for ~2 seconds which is enough time for the player to remove the episode that is
             * currently playing and either stop the player and load the next episode. This doesn't effect any other action, apart from skip last.
             */
            yield delay(2000);
        }
    }
}

function* episodeFinished(action: ReturnType<typeof fromPlayerActions.Actions.episodeFinished>) {
    const { episode, podcastUuid } = action.payload;

    // Both mark-as-played actions take care of removing the episode from Up Next as a side effect
    try {
        if (podcastUuid === UPLOADED_FILES_PODCAST_UUID) {
            yield put(
                fromUploadedFilesActions.Actions.requestUpdateFile(episode.uuid, {
                    playingStatus: PlayingStatus.COMPLETED,
                }),
            );
        } else {
            // markAsPlayed also archives the episode if the setting is enabled
            yield put(
                fromPodcastActions.Actions.markAsPlayed(episode.uuid, podcastUuid, {
                    eventSource: null,
                }),
            );
        }
    } catch (error) {
        logSagaError('Episode finished failed', error);
        yield put(fromUpNextActions.Actions.upNextDownloadFailed());
    }
}

function* saveUpNextChange(action: TodoFixmeMigrationType) {
    try {
        if (
            action.payload.episode &&
            action.payload.podcastUuid &&
            !action.payload.episode.podcastUuid
        ) {
             
            action.payload.episode.podcastUuid = action.payload.podcastUuid;
        }

        // play next or last should mark played episodes as unplayed
        if (
            action.payload.episode &&
            EpisodeHelper.isPlayed(action.payload.episode) &&
            (action.type === fromUpNextActions.ActionTypes.PLAY_EPISODE_LAST ||
                action.type === fromUpNextActions.ActionTypes.PLAY_EPISODE_NEXT)
        ) {
            if (isUploadedFile(action.payload.episode)) {
                yield put(
                    fromUploadedFilesActions.Actions.requestUpdateFile(
                        action.payload.episode.uuid,
                        {
                            playingStatus: PlayingStatus.NOT_PLAYED,
                        },
                    ),
                );
            } else {
                yield put(
                    fromPodcastActions.Actions.markAsUnplayed(
                        action.payload.episode.uuid,
                        action.payload.podcastUuid,
                        { eventSource: null },
                    ),
                );
            }
        }

        // Uploaded Files in Up Next will not have a url if they have not entered the Player yet.
        // The sync server requires a non-empty URL for some requests, so we add a placeholder.
        // The placeholder URL is also added to file-backed episodes loaded from the server in the
        // UpNext reducer.

        let upNext: UpNextState | null = null;
        if (action.type === fromUpNextActions.ActionTypes.PLAY_EPISODE_LAST) {
            const { episode, tracksProperties } = action.payload;
            upNext = yield call(api.upNextPlayLast, action.payload.episode);
            if (tracksProperties.eventSource) {
                yield put(
                    fromTracksActions.Actions.recordEvent('episode_added_to_up_next', {
                        episode_uuid: episode.uuid,
                        to_top: false,
                        source: tracksProperties.eventSource,
                    }),
                );
            }
        } else if (action.type === fromUpNextActions.ActionTypes.PLAY_EPISODE_NEXT) {
            const { episode, tracksProperties } = action.payload;
            upNext = yield call(api.upNextPlayNext, action.payload.episode);
            if (tracksProperties.eventSource) {
                yield put(
                    fromTracksActions.Actions.recordEvent('episode_added_to_up_next', {
                        episode_uuid: episode.uuid,
                        to_top: true,
                        source: tracksProperties.eventSource,
                    }),
                );
            }
        } else if (action.type === fromUpNextActions.ActionTypes.PLAY_EPISODE_NOW) {
            upNext = yield call(api.upNextPlayNow, action.payload.episode);
        } else if (action.type === fromUpNextActions.ActionTypes.REMOVE_EPISODE_FROM_UP_NEXT) {
            const { episodeUuid, tracksProperties } = action.payload;
            upNext = yield call(api.upNextRemove, [action.payload.episodeUuid]);
            if (tracksProperties.eventSource) {
                yield put(
                    fromTracksActions.Actions.recordEvent('episode_removed_from_up_next', {
                        episode_uuid: episodeUuid,
                        source: tracksProperties.eventSource,
                    }),
                );
            }
        } else if (action.type === fromUpNextActions.ActionTypes.CLEAR_UP_NEXT) {
            upNext = yield call(api.upNextRemove, action.payload.episodeUuids);
            yield put(fromTracksActions.Actions.recordEvent('up_next_queue_cleared'));
        } else if (action.type === fromUpNextActions.ActionTypes.MOVE_UP_NEXT_EPISODE) {
            const data: UpNextState = yield select(getUpNext);
            upNext = yield call(api.upNextRearrange, data.order, data.episodes);
        }

        if (upNext !== null) {
            const stateUpNext: ReturnType<typeof getUpNext> = yield select(getUpNext);

            // Bring forward titles, imageUrls podcastUuids and 'exists' status from our previous
            // Up Next object because the server does not necessarily know them.
            //
            // The Up Next in state will already have the action applied to it because the reducer
            // will have executed synchronously, but that's okay because it will therefore still
            // have all the episodes needed to update the custom episode data here.
            upNext.order.forEach((episodeUuid: string) => {
                if (stateUpNext.episodes[episodeUuid]) {
                    if (stateUpNext.episodes[episodeUuid].imageUrl && upNext) {
                        upNext.episodes[episodeUuid].imageUrl =
                            stateUpNext.episodes[episodeUuid].imageUrl;
                    }
                    if (stateUpNext.episodes[episodeUuid].title && upNext) {
                        upNext.episodes[episodeUuid].title =
                            stateUpNext.episodes[episodeUuid].title;
                    }
                    if (stateUpNext.episodes[episodeUuid].podcastUuid && upNext) {
                        upNext.episodes[episodeUuid].podcastUuid =
                            stateUpNext.episodes[episodeUuid].podcastUuid;
                    }
                    if (stateUpNext.episodes[episodeUuid].exists && upNext) {
                        upNext.episodes[episodeUuid].exists = true;
                    }
                }
            });

            yield put(fromUpNextActions.Actions.upNextChanged(upNext));
        }
    } catch (error) {
        logSagaError('Failed in Save Up Next Change', error);
    }
}

function* saveHistoryChange(
    action:
        | ReturnType<typeof fromHistoryActions.Actions.addHistory>
        | ReturnType<typeof fromHistoryActions.Actions.deleteHistory>
        | ReturnType<typeof fromHistoryActions.Actions.clearHistory>,
) {
    try {
        let actionType;
        let episode;
        if (action.type === fromHistoryActions.ActionTypes.HISTORY_ADD) {
            episode = action.payload.episode;
            actionType = 1;
        } else if (action.type === fromHistoryActions.ActionTypes.HISTORY_DELETE) {
            episode = action.payload.episode;
            actionType = 2;
        } else if (action.type === fromHistoryActions.ActionTypes.HISTORY_CLEAR) {
            actionType = 3;
        } else {
            return;
        }

        yield call(api.historyDo, actionType, episode);

        if (action.type === fromHistoryActions.ActionTypes.HISTORY_CLEAR) {
            yield put(fromTracksActions.Actions.recordEvent('listening_history_cleared'));
        }
    } catch (error) {
        logSagaError('Failed in Save History Change', error);
    }
}

function* userReturned() {
    try {
        const oldPlayerEpisode: FullEpisode = yield select(getPlayerEpisode);

        yield all([
            fetchSubscriptionData(),
            downloadSettings(),
            downloadPodcasts(),
            fetchUploadedFilesData(),
        ]);

        yield downloadUpNext();

        const playing: ReturnType<typeof getPlayerIsPlaying> = yield select(getPlayerIsPlaying);
        const newPlayerEpisode: FullEpisode & { podcast: string } = yield select(
            getUpNextTopEpisode,
        );

        if (playing || !oldPlayerEpisode || !newPlayerEpisode) {
            return;
        }

        const latestEpisodeDuck: UploadedFile =
            newPlayerEpisode.podcastUuid === UPLOADED_FILES_PODCAST_UUID
                ? yield getUploadedFileFetchingIfNecessary(newPlayerEpisode.uuid)
                : yield downloadEpisodeDetails({
                      uuid: newPlayerEpisode.uuid,
                      podcastUuid: newPlayerEpisode.podcastUuid ?? newPlayerEpisode.podcast,
                  });

        if (oldPlayerEpisode.uuid === newPlayerEpisode.uuid) {
            yield put(
                fromPlayerActions.Actions.updatePlayedUpTo(
                    newPlayerEpisode.uuid,
                    (latestEpisodeDuck && latestEpisodeDuck.playedUpTo) || 0,
                ),
            );
        }
    } catch (error) {
        logSagaError('Failed in User Returned', error);
    }
}

//

export function* watchUpdateDuration() {
    yield takeLeading(fromPlayerActions.ActionTypes.UPDATE_DURATION, updateDuration);
}

export function* watchUpdatePlayedUpTo() {
    yield takeLeading(fromPlayerActions.ActionTypes.UPDATE_PLAYED_UP_TO, updatePlayedUpTo);
}

export function* watchPlayEpisode() {
    yield takeLatest(fromPlayerActions.ActionTypes.PLAY_EPISODE, playEpisode);
}

export function* watchPauseEpisode() {
    yield takeLatest(fromPlayerActions.ActionTypes.PAUSE, pauseEpisode);
}

export function* watchEpisodeFinished() {
    yield takeEvery(fromPlayerActions.ActionTypes.EPISODE_FINISHED, episodeFinished);
}

export function* watchUserReturned() {
    yield takeLeading(fromUserActions.ActionTypes.USER_RETURNED, userReturned);
}

export function* watchDownloadUpNext() {
    yield throttle(2000, [fromUpNextActions.ActionTypes.OPEN_UP_NEXT], downloadUpNext);
}

export function* watchUpNextChanged() {
    yield takeLeading(fromUpNextActions.ActionTypes.UP_NEXT_CHANGED, upNextChanged);
}

export function* watchUpNextChanges() {
    const changesChannel: Channel<ActionChannelEffect> = yield actionChannel(
        [
            fromUpNextActions.ActionTypes.PLAY_EPISODE_LAST,
            fromUpNextActions.ActionTypes.PLAY_EPISODE_NEXT,
            fromUpNextActions.ActionTypes.PLAY_EPISODE_NOW,
            fromUpNextActions.ActionTypes.REMOVE_EPISODE_FROM_UP_NEXT,
            fromUpNextActions.ActionTypes.CLEAR_UP_NEXT,
            fromUpNextActions.ActionTypes.MOVE_UP_NEXT_EPISODE,
        ],
        buffers.sliding(50),
    );
    while (true) {
        const action: ReturnType<typeof fromPlayerActions.Actions.episodeFinished> = yield take(
            changesChannel,
        );
        yield saveUpNextChange(action);
    }
}

export function* watchHistoryChanges() {
    const changesChannel: Channel<ActionChannelEffect> = yield actionChannel(
        [
            fromHistoryActions.ActionTypes.HISTORY_ADD,
            fromHistoryActions.ActionTypes.HISTORY_DELETE,
            fromHistoryActions.ActionTypes.HISTORY_CLEAR,
        ],
        buffers.sliding(50),
    );
    while (true) {
        const action: ReturnType<typeof fromHistoryActions.Actions.addHistory> = yield take(
            changesChannel,
        );
        yield saveHistoryChange(action);
    }
}

export function* watchDownloadRecommendations() {
    yield takeEvery(
        fromPlayerActions.ActionTypes.DOWNLOAD_RECOMMENDATIONS,
        downloadRecommendations,
    );
}
