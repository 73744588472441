import styled from 'styled-components';

export const PopupBase = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
`;

export const PopupBackgroundBase = styled.div.attrs({
    tabIndex: -1,
    'aria-label': 'Dismiss popup',
})`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 101;
`;

export const PopupChoiceItemBase = styled.div.attrs(
    ({ itemIndex, checkboxMenuItem }: { itemIndex: number; checkboxMenuItem: boolean }) => ({
        'data-testid': `menuitem-${itemIndex}`,
        role: checkboxMenuItem ? 'menuitemcheckbox' : 'menuitem',
        tabIndex: -1,
    }),
)`
    display: block;
    box-sizing: border-box;
    min-width: 100px;
    color: ${props => props.theme.legacyScss['theme-text-primary']};
    white-space: nowrap;
    user-select: none;
    text-decoration: none;
    font-size: 14.4px;
    font-weight: 400;
    line-height: 32px;
    vertical-align: middle;
    padding: 0 27px 0 16px;
    cursor: pointer;
    letter-spacing: 0.1px;
    &[aria-selected='true'] {
        font-weight: 500;
        color: ${props => props.theme.legacyScss['theme-interactive']};
    }
    &:hover,
    &:focus {
        background-color: ${props => props.theme.tokens['primary-ui-01-active']};
        outline: 0;
    }
    border-bottom: 1px solid ${props => props.theme.tokens['primary-ui-05']};
`;

type WindowBaseProps = { offsets: { top: number; left: number } };
export const PopupWindowBase = styled.div.attrs({
    'data-testid': 'menu-popup-window',
    role: 'menu',
    tabIndex: -1,
})`
    position: fixed;
    min-width: 100px;
    border-radius: 4px;
    overflow: hidden;
    background: ${props => props.theme.tokens['primary-ui-01']};
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: 0px 6px 9px rgba(48, 59, 64, 0.07), 0px 3px 6px rgba(26, 4, 0, 0.12),
        0px 1px 2px rgba(0, 0, 0, 0.05);
    z-index: 102;
    margin-left: ${(props: WindowBaseProps) => `${props.offsets.left}px`};
    margin-top: ${(props: WindowBaseProps) => `${props.offsets.top}px`};
    ${PopupChoiceItemBase}:last-child {
        border-bottom: 0;
    }
`;
