import { FileUploadRecord } from 'model/types';
import { warnUserBeforeLeavingPage } from '../../helper/UiHelper';
import * as fromUploadManagerActions from '../actions/upload-manager.actions';

interface UploadManagerState {
    filesOrder: string[];
    files: Record<string, FileUploadRecord>;
    images: Record<string, FileUploadRecord>;
}

export const INITIAL_STATE = {
    filesOrder: [],
    files: {},
    images: {},
};

export default (
    state: UploadManagerState = INITIAL_STATE,
    action: fromUploadManagerActions.Actions,
): UploadManagerState => {
    switch (action.type) {
        case fromUploadManagerActions.ActionTypes.FILE_ADD_TO_UPLOAD_MANAGER: {
            const { file, uuid, url, errorIfPresent } = action.payload;

            const newFilesOrder = state.filesOrder.slice();
            newFilesOrder.push(uuid);

            const newFiles = JSON.parse(JSON.stringify(state.files));
            newFiles[uuid] = {
                file,
                name: file.name,
                size: file.size,
                type: file.type,
                progress: 0,
                error: errorIfPresent,
                uploadUrl: url,
            };

            window.addEventListener('beforeunload', warnUserBeforeLeavingPage);

            return { ...state, filesOrder: newFilesOrder, files: newFiles };
        }
        case fromUploadManagerActions.ActionTypes.FILE_REMOVE_FROM_UPLOAD_MANAGER: {
            const { uuid } = action.payload;

            const newFilesOrder = state.filesOrder.filter(fileUuid => fileUuid !== uuid);
            const newFiles = JSON.parse(JSON.stringify(state.files));
            delete newFiles[uuid];

            if (newFilesOrder.length === 0) {
                window.removeEventListener('beforeunload', warnUserBeforeLeavingPage);
            }

            return { ...state, filesOrder: newFilesOrder, files: newFiles };
        }
        case fromUploadManagerActions.ActionTypes.FILE_UPDATE_IN_UPLOAD_MANAGER: {
            const { uuid, progress, total, error, xhr } = action.payload;

            return {
                ...state,
                files: {
                    ...state.files,
                    [uuid]: {
                        ...state.files[uuid],
                        progress,
                        size: total,
                        error,
                        xhr,
                    },
                },
            };
        }
        case fromUploadManagerActions.ActionTypes.FILE_COMPLETE_IN_UPLOAD_MANAGER: {
            const { uuid } = action.payload;

            return {
                ...state,
                files: {
                    ...state.files,
                    [uuid]: {
                        ...state.files[uuid],
                        complete: true,
                    },
                },
            };
        }
        case fromUploadManagerActions.ActionTypes.FILE_FAIL_IN_UPLOAD_MANAGER: {
            const { uuid, error } = action.payload;

            return {
                ...state,
                files: {
                    ...state.files,
                    [uuid]: {
                        ...state.files[uuid],
                        failed: true,
                        error: error || state.files[uuid].error,
                    },
                },
            };
        }
        case fromUploadManagerActions.ActionTypes.FILE_UPLOAD_MANAGER_CLEAR: {
            window.removeEventListener('beforeunload', warnUserBeforeLeavingPage);

            // To avoid unlikely race conditions, abort any files that might be still going.
            state.filesOrder.forEach(uuid => {
                state.files[uuid].xhr?.abort?.();
            });

            return { ...state, filesOrder: [], files: {} };
        }
        case fromUploadManagerActions.ActionTypes.IMAGE_ADD_TO_UPLOAD_MANAGER: {
            const { file, uuid, url } = action.payload;

            return {
                ...state,
                images: {
                    ...state.images,
                    [uuid]: {
                        file,
                        url,
                        progress: 0,
                        size: 1,
                    },
                },
            };
        }
        case fromUploadManagerActions.ActionTypes.IMAGE_REMOVE_FROM_UPLOAD_MANAGER: {
            const { uuid } = action.payload;

            const images = { ...state.images };
            delete images[uuid];

            return {
                ...state,
                images,
            };
        }
        case fromUploadManagerActions.ActionTypes.IMAGE_UPDATE_IN_UPLOAD_MANAGER: {
            const { uuid, progress, total, error, xhr } = action.payload;

            return {
                ...state,
                images: {
                    ...state.images,
                    [uuid]: {
                        ...state.images[uuid],
                        progress,
                        size: total,
                        error,
                        xhr,
                    },
                },
            };
        }
        case fromUploadManagerActions.ActionTypes.IMAGE_COMPLETE_IN_UPLOAD_MANAGER: {
            const { uuid } = action.payload;

            return {
                ...state,
                images: {
                    ...state.images,
                    [uuid]: {
                        ...state.images[uuid],
                        complete: true,
                    },
                },
            };
        }
        case fromUploadManagerActions.ActionTypes.IMAGE_FAIL_IN_UPLOAD_MANAGER: {
            const { uuid } = action.payload;

            return {
                ...state,
                images: {
                    ...state.images,
                    [uuid]: {
                        ...state.images[uuid],
                        progress: 1,
                        size: 1,
                        failed: true,
                    },
                },
            };
        }
        default:
            return state;
    }
};
