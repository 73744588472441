import { TrackOnMount } from 'components/Tracks';
import useFormatMessage from 'hooks/useFormatMessage';
import useIsBeta from 'hooks/useIsBeta';
import React from 'react';
import { Switch } from '../../../components/index';
import {
    SettingsDescription,
    SettingsOption,
    SettingsTitle,
    SwitchWrapper,
} from '../SettingsPage.styled';

const SettingsBeta = () => {
    const formatMessage = useFormatMessage();

    const [isBeta, toggleIsBeta] = useIsBeta();

    return (
        <div>
            <TrackOnMount event="settings_beta_shown" />
            <h1>{formatMessage('beta')}</h1>
            <SettingsOption>
                <SettingsTitle>{formatMessage('beta-enable')}</SettingsTitle>
                <SwitchWrapper>
                    <Switch on={isBeta} onChange={toggleIsBeta} color="#03A9F4" />
                </SwitchWrapper>
                <SettingsDescription>{formatMessage('beta-enable-desc')}</SettingsDescription>
            </SettingsOption>
        </div>
    );
};

export default SettingsBeta;
