import React from 'react';
import useFormatMessage from 'hooks/useFormatMessage';
import {
    SubscriptionErrorBubbleWrapper,
    SubscriptionErrorBubbleInner,
} from './SubscriptionErrorBubble.styled';

function SubscriptionErrorBubble() {
    const formatMessage = useFormatMessage();
    return (
        <SubscriptionErrorBubbleWrapper height={120}>
            <SubscriptionErrorBubbleInner>
                {formatMessage('payment-provider-delay-or-error-1')}
                <br />
                {formatMessage('payment-provider-delay-or-error-2')}
            </SubscriptionErrorBubbleInner>
        </SubscriptionErrorBubbleWrapper>
    );
}

export default SubscriptionErrorBubble;
