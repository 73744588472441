import React from 'react';
import { PodcastDiscover } from 'model/types';
import PodcastImage from '../../../components/PodcastImage';
import {
    DescriptiveGridFlexRow,
    PodcastGridFlexColumn,
    DescriptiveGridItemTitle,
    PodcastAuthorDetails,
    PodcastAuthor,
    PodcastGridItem,
    PodcastGridItemDescription,
    StyledSeparator,
    PodcastSub5cribeButton,
    PodcastSummaryContainer,
} from './PodcastDescriptiveGrid.styled';

type Props = {
    podcasts: PodcastDiscover[];
    onPodcastClick: (podcast: PodcastDiscover) => void;
    onSubscribe: (podcast: PodcastDiscover) => void;
};

export default function PodcastDescriptiveGrid({ podcasts, onPodcastClick, onSubscribe }: Props) {
    return (
        <>
            <StyledSeparator />
            {podcasts && (
                <DescriptiveGridFlexRow>
                    {podcasts.map(podcast => (
                        <PodcastGridFlexColumn key={podcast.uuid}>
                            <PodcastGridItem onClick={() => onPodcastClick(podcast)}>
                                <PodcastAuthorDetails>
                                    <PodcastImage uuid={podcast.uuid} title={podcast.title} />
                                    <PodcastSummaryContainer>
                                        <DescriptiveGridItemTitle>
                                            {podcast.title || ''}
                                        </DescriptiveGridItemTitle>
                                        <PodcastAuthor>{podcast.author || ''}</PodcastAuthor>
                                    </PodcastSummaryContainer>

                                    <PodcastSub5cribeButton
                                        podcastUuid={podcast.uuid}
                                        onSubscribe={() => onSubscribe(podcast)}
                                        eventSource="discover"
                                    />
                                </PodcastAuthorDetails>

                                <PodcastGridItemDescription>
                                    {podcast.description}
                                </PodcastGridItemDescription>
                            </PodcastGridItem>
                        </PodcastGridFlexColumn>
                    ))}
                </DescriptiveGridFlexRow>
            )}
        </>
    );
}
