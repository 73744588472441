import styled from 'styled-components';

export const DiscoverPageWrapper = styled.div`
    min-height: 100%;
    &:last-child {
        margin-bottom: 150px !important;
    }
`;

export const DiscoverPageItemWrapper = styled.div`
    margin: 50px 0;

    &:first-of-type {
        margin: 0;
    }

    &:nth-of-type(2) {
        margin-top: 36px;
    }
`;
