import PropTypes from 'prop-types';
import ImageUploadProgressBox from './view';

ImageUploadProgressBox.propTypes = {
    onCancel: PropTypes.func.isRequired,
    errorIfExists: PropTypes.string,
    shouldShowCancelButton: PropTypes.bool,
};

export default ImageUploadProgressBox;
