import styled from 'styled-components';
import { gridSize, white } from '../../../../../styles/index';

export const FileColorPickerWrapper = styled.div`
    display: flex;
    width: 496px;
    justify-content: center;
    margin-top: ${5.5 * gridSize}px;
    margin-bottom: ${5.5 * gridSize}px;
`;

export const Swatch = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    width: ${5 * gridSize}px;
    height: ${5 * gridSize}px;
    border-radius: ${(5 * gridSize) / 2}px;

    margin-left: ${gridSize}px;
    margin-right: ${gridSize}px;

    background-color: ${props => props.color};

    ${props => props.image && 'background-repeat: no-repeat'};
    ${props => props.image && 'background-position-y: center'};
    ${props => props.image && 'background-size: cover'};
    ${props => props.image && `background-image: url(${props.image})`};

    ${props => props.selected && 'transform: scale(1.2);'}

    &:hover {
        ${props => !props.selected && 'transform: scale(1.2);'}
    }

    cursor: pointer;
    transition: all 100ms ease-in-out;
    transform-origin: center center;
`;

export const WhiteDot = styled.div`
    width: 12px;
    height: 12px;
    background-color: ${white};
    border-radius: 6px;
`;

export const ProgressIndicatorWrapper = styled.div`
    display: flex;
    width: ${5 * gridSize}px;
    height: ${5 * gridSize}px;

    align-items: center;

    transform: scale(1.2);
    transform-origin: center center;

    margin-left: ${gridSize}px;
    margin-right: ${gridSize}px;
`;

export const UploadImageButtonWrapper = styled.div`
    position: relative;
`;
