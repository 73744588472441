import PropTypes from 'prop-types';
import VolumeSlider from './view';

VolumeSlider.propTypes = {
    volume: PropTypes.number,
    muted: PropTypes.bool,
    onVolumeChanged: PropTypes.func,
    onMuteChanged: PropTypes.func,
};

VolumeSlider.defaultProps = {
    volume: 1,
    muted: false,
};

export default VolumeSlider;
