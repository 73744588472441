import { AutoplayConfig } from 'model/types';
import { action } from 'redux/api';

export const setAutoplayEnabled = (isEnabled: boolean) =>
    action('AUTOPLAY_SET_ENABLED', { isEnabled });

export const clearAutoplay = () => action('AUTOPLAY_CLEAR');
export const startAutoplay = (config: AutoplayConfig) => action('AUTOPLAY_START', { config });

export const refreshAutoplayEpisodes = () => action('AUTOPLAY_REFRESH_EPISODES');
