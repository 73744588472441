import { createSelector } from 'reselect';
import { DiscoverExpandedStyle, DiscoverSummaryStyle, DiscoverType } from '../../../model/types';
import { getSettings } from './settings.selectors';
// TK
export const getDiscoverList = (state, name) => state.discover.lists[name];

export const getRegionCodeToken = state =>
    state.discover.contentSpec && state.discover.contentSpec.region_code_token;

export const getDiscoverRegion = state => {
    const settings = getSettings(state);
    const regionCode = settings.region || 'global';
    if (state.discover.contentSpec && state.discover.contentSpec.regions) {
        return state.discover.contentSpec.regions[regionCode];
    }
    return undefined;
};

const getRegionsMap = state => state.discover.contentSpec && state.discover.contentSpec.regions;
export const getRegionsArray = createSelector([getRegionsMap], regionsMap => {
    if (regionsMap) {
        return Object.keys(regionsMap)
            .map(regionCode => {
                return regionsMap[regionCode];
            })
            .sort((a, b) => {
                // Ensure that the regions are sorted automatically by name
                return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
            });
    }
    return [];
});

export const getDiscoverLayouts = state =>
    state.discover.contentSpec && state.discover.contentSpec.layout;

export const getDiscoverLayoutById = (state, id) => {
    const layouts = getDiscoverLayouts(state);
    if (layouts) {
        return layouts.find(layout => layout.type === id);
    }
    return null;
};

export const getDiscoverCategory = (state, id) => {
    const categories = getDiscoverLayoutById(state, DiscoverType.CATEGORIES);
    if (categories && Array.isArray(categories.data)) {
        return categories.data.find(category => `${category.id}` === `${id}`);
    }
    return null;
};

export const getCategoryList = (state, id) => state.discover.categories[id];

export const getDiscoverCategoryPageDetails = (state, categoryId) => {
    const categoryLayout = getDiscoverLayoutById(state, DiscoverType.CATEGORIES);
    const category = getCategoryList(state, categoryId);
    return {
        list: category,
        title: category ? category.title : null,
        category,
        pageHeaderDescription: {
            summaryType: categoryLayout ? categoryLayout.summary_style : null,
            expandedType: categoryLayout ? categoryLayout.expanded_style : null,
            data: categoryLayout,
        },
        ranked: categoryLayout
            ? categoryLayout.expanded_style === DiscoverExpandedStyle.RANKED_LIST
            : false,
        isCategory: true,
        region: state.settings.region,
        discoverContentJsonLayout: state.discover.contentSpec.layout,
        listId: '',
    };
};

export const getDiscoverListPageDetails = (state, { listId }) => {
    let ranked = false;
    const list = state.discover.lists[listId];
    let title = list?.title;
    let pageHeaderDescription = {
        summaryType: DiscoverSummaryStyle.SMALL_LIST,
        expandedType: DiscoverExpandedStyle.PLAIN_LIST,
        data: null,
    };
    let curated = false;
    const { layout } = state.discover.contentSpec;
    const contentBlock = layout
        ? layout.find(layoutObj => layoutObj.source.includes(`${listId}.json`))
        : undefined;
    if (contentBlock) {
        if (contentBlock.expanded_style === DiscoverExpandedStyle.RANKED_LIST) {
            ranked = true;
        }
        curated = contentBlock.curated;
        title = contentBlock.title;
        pageHeaderDescription = {
            summaryType: contentBlock.summary_style,
            expandedType: contentBlock.expanded_style,
            data: DiscoverSummaryStyle.COLLECTION ? contentBlock.data : null,
        };
    }
    return {
        list,
        title,
        pageHeaderDescription,
        listId,
        ranked,
        discoverContentJsonLayout: state.discover.contentSpec.layout,
        region: state.settings.region,
        curated,
    };
};
