import { getThemeFromId } from 'model/theme';
import { Folder } from 'model/types';
import { FOLDER_COLORS } from 'styles/colors';
import { RootState } from 'redux/reducers';
import { getSubscribedPodcasts, getTheme } from 'redux/reducers/selectors';

export const getFolders = (state: RootState) => state.folders;

export const getFolder = (state: RootState, uuid: string) =>
    getFolders(state).find(folder => folder.uuid === uuid);

export const getFoldersByUuid = (state: RootState) => {
    const result: Record<string, Folder> = {};
    getFolders(state).forEach(folder => {
        result[folder.uuid] = folder;
    });
    return result;
};

export const getFoldersByPodcastUuid = (state: RootState) => {
    const podcasts = getSubscribedPodcasts(state);
    const foldersByUuid = getFoldersByUuid(state);
    const result: Record<string, Folder> = {};
    podcasts.forEach(podcast => {
        result[podcast.uuid] = foldersByUuid[podcast.folderUuid];
    });
    return result;
};

export const getPodcastUuidsByFolderUuid = (state: RootState) => {
    const folders = getFolders(state);
    const podcasts = getSubscribedPodcasts(state);
    const result: Record<string, string[]> = {};
    // Create empty arrays for each folder, in case it has no podcasts
    folders.forEach(folder => {
        result[folder.uuid] = [];
    });
    // Place each podcast in its folder
    podcasts.forEach(podcast => {
        if (podcast.folderUuid) {
            result[podcast.folderUuid] = result[podcast.folderUuid] || [];
            result[podcast.folderUuid].push(podcast.uuid);
        }
    });
    return result;
};

export const getPodcastUuidsForFolder = (state: RootState, folderUuid: string) => {
    return getPodcastUuidsByFolderUuid(state)[folderUuid] || [];
};

export const getFolderForPodcast = (state: RootState, podcastUuid: string) =>
    getFoldersByPodcastUuid(state)[podcastUuid];

export const getFolderColorValues = (state: RootState) => {
    const theme = getThemeFromId(getTheme(state));
    return FOLDER_COLORS.map(({ themeToken }) => theme.tokens[themeToken]);
};
