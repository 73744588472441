import styled from 'styled-components';
import { Typography } from '../../../../styles';

export const SettingsTextContainer = styled.div`
    clear: both;
`;

export const SettingsLabel = styled.div`
    display: inline-block;
`;

export const SettingsEdit = styled.div<{ maxCharacters: number }>`
    display: inline-block;
    float: right;
    input {
        outline: none;
        display: inline-block;
        height: 33px;
        width: ${props => (props.maxCharacters > 3 ? `${props.maxCharacters + 1}ch` : '50px')};
        border-radius: 4px;
        text-align: center;
        margin-right: 8px;
        color: ${props => props.theme.tokens['primary-text-01']};
        background-color: ${props => props.theme.tokens['primary-field-02']};
        border: 1px solid ${props => props.theme.tokens['primary-field-03']};
        box-shadow: none;
        &:focus {
            border: 1px solid ${props => props.theme.tokens['primary-field-03-active']};
            background-color: ${props => props.theme.tokens['primary-field-02-active']};
        }
    }
`;

export const SettingsUnit = styled.div`
    display: inline-block;

    span: {
        ${Typography.Desktop.H70};
    }
`;
