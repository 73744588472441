import { FolderImage } from 'components/FolderImage';
import { Icon } from 'components/Icon';
import { PodcastCount } from 'components/messages';
import * as StringHelper from 'helper/StringHelper';
import { Folder } from 'model/types';
import React, { ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import {
    getFolderColorValues,
    getFolders,
    getPodcastUuidsByFolderUuid,
} from 'redux/reducers/selectors';
import {
    Checkbox,
    Fieldset,
    Label,
    Option,
    OptionCount,
    OptionText,
    OptionTitle,
} from './FolderSelector.styled';

export type Props = {
    selected: string;
    onChange: (folderUuid: string) => void;
    onKeyDown: (e: React.KeyboardEvent) => void;
};

const FolderSelector = ({ selected, onChange, onKeyDown }: Props) => {
    const folders = useSelector(getFolders) as Folder[];
    const folderColorValues = useSelector(getFolderColorValues);
    const podcastUuidsByFolderUuid = useSelector(getPodcastUuidsByFolderUuid) as Record<
        string,
        string[]
    >;

    folders.sort((a, b) => StringHelper.compareCleaned(a.name, b.name, true));

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value, checked } = e.currentTarget;
        if (checked) {
            onChange(value);
        }
    };

    if (!folders) {
        return null;
    }

    return (
        <Fieldset>
            <legend>Select a folder</legend>
            {folders.map(folder => (
                <Label key={folder.uuid} color="yellow">
                    <input
                        type="radio"
                        name="folder-select-form--folder"
                        value={folder.uuid}
                        checked={selected === folder.uuid}
                        onKeyDown={onKeyDown}
                        onChange={handleChange}
                    />
                    <Option color={folderColorValues[folder.color]}>
                        <FolderImage
                            size={60}
                            color={folder.color}
                            name={folder.name}
                            podcastUuids={podcastUuidsByFolderUuid[folder.uuid]}
                            sortType={folder.sortType}
                            showName={false}
                        />
                        <OptionText>
                            <OptionTitle>{folder.name}</OptionTitle>
                            <OptionCount>
                                <PodcastCount
                                    count={podcastUuidsByFolderUuid[folder.uuid].length}
                                />
                            </OptionCount>
                        </OptionText>
                        <Checkbox>
                            <Icon id="tick" />
                        </Checkbox>
                    </Option>
                </Label>
            ))}
        </Fieldset>
    );
};

export default FolderSelector;
