import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Modal from '../../../../components/Modal';
import { POCKET_CASTS_PLUS_SUBSCRIPTION_INFO } from '../../../../model/external-links';
import { SubscriptionPlatform } from '../../../../model/types';
import {
    AllHopeWrapper,
    HeaderWrapper,
    HelpLink,
    HelpWrapper,
    ImageWrapper,
    Separator,
    Spaces,
    SubtitleWrapper,
} from './ChangeBillingDetailsModal.styled';
import CancelAndroidImage from './cancel-android.svg';
import CanceliOSImage from './cancel-ios.svg';
import CancelWebImage from './cancel-web.svg';

type Props = {
    platform?: SubscriptionPlatform;
    onClose: () => void;
};

export class ChangeBillingDetailsModal extends Component<Props> {
    renderImage() {
        const { platform } = this.props;

        const size = 112;

        if (platform === SubscriptionPlatform.iOS) {
            return (
                <ImageWrapper>
                    <img width={size} height={size} src={CanceliOSImage} alt="" />
                </ImageWrapper>
            );
        }

        if (platform === SubscriptionPlatform.Android) {
            return (
                <ImageWrapper>
                    <img width={size} height={size} src={CancelAndroidImage} alt="" />
                </ImageWrapper>
            );
        }

        return (
            <ImageWrapper>
                <img width={size} height={size} src={CancelWebImage} alt="" />
            </ImageWrapper>
        );
    }

    renderHeader() {
        const { platform } = this.props;

        if (platform === SubscriptionPlatform.iOS) {
            return (
                <HeaderWrapper>
                    <FormattedMessage id="modal-subscribed-on-ios" />
                </HeaderWrapper>
            );
        }

        return (
            <HeaderWrapper>
                <FormattedMessage id="modal-subscribed-on-android" />
            </HeaderWrapper>
        );
    }

    renderLink() {
        const { platform } = this.props;

        const message = (
            <FormattedMessage
                id={
                    platform === SubscriptionPlatform.iOS
                        ? 'modal-find-out-ios'
                        : 'modal-find-out-android'
                }
            />
        );

        return (
            <HelpLink href={POCKET_CASTS_PLUS_SUBSCRIPTION_INFO} target="_blank">
                <HelpWrapper>{message}</HelpWrapper>
            </HelpLink>
        );
    }

    render() {
        return (
            <Modal horizontalInsetPx={32} onClose={this.props.onClose}>
                {this.renderImage()}
                <Spaces n={3} />
                {this.renderHeader()}
                <Spaces n={2} />
                <SubtitleWrapper>
                    <FormattedMessage id="modal-cant-change-subscription-here" />
                </SubtitleWrapper>
                <Spaces n={4} />
                <Separator />
                <Spaces n={3} />
                <AllHopeWrapper>
                    <FormattedMessage id="modal-all-is-not-lost" />
                </AllHopeWrapper>
                <Spaces n={2} />
                {this.renderLink()}
                <Spaces n={4} />
            </Modal>
        );
    }
}

export default ChangeBillingDetailsModal;
