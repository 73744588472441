import { Actions } from '../actions/tracks.actions';

const INITIAL_STATE = {};

/*
 * This "noop" reducer is necessary so that TypeScript can infer the correct type for the
 * tracks actions.
 */
export default (state = INITIAL_STATE, action: Actions) => {
    switch (action.type) {
        default:
            return state;
    }
};
