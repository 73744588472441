import styled from 'styled-components';
import { Typography, gridSize } from 'styles';

export const Wrapper = styled.div`
    ${Typography.Desktop.H70}
    line-height: 1;
    a {
        text-decoration: none;
        color: ${props => props.theme.tokens['primary-text-02']};
        &:hover {
            opacity: 0.8;
        }
    }
    span + span {
        &:before {
            content: '•';
            display: inline-block;
            width: ${2 * gridSize}px;
            text-align: center;
            color: ${props => props.theme.tokens['primary-text-02']};
            opacity: 0.5;
            font-size: 0.8em;
            vertical-align: middle;
        }
    }
`;
