import { connect } from 'react-redux';
import * as fromUploadedFilesActions from '../../redux/actions/uploaded-files.actions';
import UploadedFilesPage from './view';

const mapStateToProps = state => ({
    isLoading: state.uploadedFiles.isLoading,
    filesOrder: state.uploadedFiles.data.filesOrder || [],
    account: state.uploadedFiles.data.account,
});

const mapDispatchToProps = dispatch => ({
    fetchUploadedFilesData: () =>
        dispatch(fromUploadedFilesActions.Actions.fetchUploadedFilesData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadedFilesPage);
