import styled from 'styled-components';
import { Typography } from 'styles';

export const Wrapper = styled.div`
    ${Typography.Desktop.H70}
    line-height: 1;
    display: flex;
    align-items: center;
    color: ${props => props.theme.tokens['primary-icon-02']};

    svg {
        margin-bottom: 0.09em;
    }
`;

export const NoRatingsText = styled.span`
    margin-left: 0.5em;
    color: ${props => props.theme.tokens['primary-text-02']};
`;

export const RatingsText = styled.span`
    margin-left: 0.5em;
    color: ${props => props.theme.tokens['primary-text-02']};
    text-transform: lowercase;
    strong {
        font-weight: 500;
    }
`;
