import React, { useEffect } from 'react';
import { useWindowSize } from './useWindowSize';

type Props = {
    onClosed: (e: React.SyntheticEvent | MouseEvent) => void;
    anchorEl: Element;
    offsets: Record<string, number>;
    horizontalPosition?: 'left' | 'right' | 'center';
    size: number;
    positionRelativeToWindow?: boolean;
};

export const usePopupContextMenu = ({
    onClosed,
    anchorEl,
    offsets,
    horizontalPosition,
    size,
    positionRelativeToWindow = false,
}: Props) => {
    const [menuOffsets, setOffsets] = React.useState(offsets);
    const [menuItemRefs, setMenuItemRefs] = React.useState<React.MutableRefObject<HTMLElement>[]>(
        [],
    );
    const [currentFocus, setCurrentFocus] = React.useState(0);
    const clientRects = anchorEl.getClientRects()[0];
    const popupWindowRef = React.useRef() as React.MutableRefObject<HTMLElement>;
    const frameRef = React.useRef() as React.MutableRefObject<HTMLElement>;
    const { windowHeight } = useWindowSize();
    const onKeyDown = React.useCallback(
        e => {
            if (e.key === 'Tab' || e.key === 'Escape' || e.key === 'Space') {
                e.stopPropagation();
                onClosed(e);
            } else if (e.key === 'ArrowDown') {
                e.preventDefault();
                setCurrentFocus(prevFocus => {
                    if (prevFocus === -1) return 0;
                    return prevFocus === size - 1 ? 0 : prevFocus + 1;
                });
            } else if (e.key === 'ArrowUp') {
                e.preventDefault();
                setCurrentFocus(prevFocus => {
                    if (prevFocus === -1) return 0;
                    return prevFocus === 0 ? size - 1 : prevFocus - 1;
                });
            }
        },
        [size, currentFocus, setCurrentFocus],
    );

    const onScroll = (e: MouseEvent) => {
        onClosed?.(e);
    };

    useEffect(() => {
        const frame = frameRef.current;

        window.addEventListener('keydown', onKeyDown);
        frame?.addEventListener('wheel', onScroll);

        setCurrentFocus(-1);
        popupWindowRef.current.focus();

        return () => {
            window.removeEventListener('keydown', onKeyDown);
            frame?.removeEventListener('wheel', onScroll);
        };
    }, [frameRef.current]);

    useEffect(() => {
        if (currentFocus > -1) {
            if (menuItemRefs && menuItemRefs[currentFocus] && menuItemRefs[currentFocus].current) {
                menuItemRefs[currentFocus].current.focus();
            }
        }
    }, [currentFocus]);

    useEffect(() => {
        // add or remove refs
        setMenuItemRefs(menuItemRefs =>
            Array(size)
                .fill(undefined)
                .map((_, i) => menuItemRefs[i] || React.createRef()),
        );
    }, [size]);

    useEffect(() => {
        if (positionRelativeToWindow && windowHeight != null) {
            const space = windowHeight - clientRects.bottom;
            const popupWindowHeight =
                popupWindowRef && popupWindowRef.current
                    ? popupWindowRef.current.getClientRects()[0].height
                    : 300;
            const minSpace = space > popupWindowHeight ? 40 : 40 + (popupWindowHeight - space);
            setOffsets({
                top: -minSpace,
            });
        }
    }, [windowHeight, popupWindowRef]);

    return {
        popupWindowRef,
        frameRef,
        offsets: {
            left: (() => {
                let { left } = offsets;
                left = left != null ? left : 0;

                if (horizontalPosition) {
                    if (horizontalPosition === 'left' && popupWindowRef.current) {
                        return clientRects.left - popupWindowRef.current.getClientRects()[0].width;
                    }
                    if (horizontalPosition === 'right' && popupWindowRef.current) {
                        return clientRects.left + clientRects.width + left;
                    }
                }
                return clientRects.left - left;
            })(),
            top: clientRects.top + (clientRects.height + menuOffsets.top),
        },
        focus: currentFocus,
        setFocus: setCurrentFocus,
        menuItemRefs,
    };
};
