import { TodoFixmeMigrationType } from 'model/types';
import { ActionsUnion, createAction } from './action-creators';

export enum ActionTypes {
    SAVE_PODCAST_GRID_LAYOUT = '@settings/SAVE_PODCAST_GRID_LAYOUT',
    SAVE_PODCAST_GRID_ORDER = '@settings/SAVE_PODCAST_GRID_ORDER',
    SAVE_PODCAST_GRID_BADGES_ON = '@settings/SAVE_PODCAST_GRID_BADGES_ON',
    SAVE_BOOKMARKS_SORT_ORDER = '@settings/SAVE_BOOKMARKS_SORT_ORDER',
    SAVE_SKIP_FORWARD = '@settings/SAVE_SKIP_FORWARD',
    SAVE_SKIP_BACK = '@settings/SAVE_SKIP_BACK',
    SAVE_INTELLIGENT_RESUMPTION = '@settings/SAVE_INTELLIGENT_RESUMPTION',
    SAVE_FILES_AFTER_PLAYING_DELETE_CLOUD = '@settings/SAVE_FILES_AFTER_PLAYING_DELETE_CLOUD',
    SAVE_FILES_AUTO_UP_NEXT = '@settings/SAVE_FILES_AUTO_UP_NEXT',
    SAVE_AUTO_ARCHIVE_PLAYED = '@settings/SAVE_AUTO_ARCHIVE_PLAYED',
    SAVE_AUTO_ARCHIVE_INCLUDES_STARRED = '@settings/SAVE_AUTO_ARCHIVE_INCLUDES_STARRED',
    SAVE_REMOTE_SKIP_CHAPTERS = '@settings/SAVE_REMOTE_SKIP_CHAPTERS',
    SAVE_WEBP_SUPPORTED = '@settings/SAVE_WEBP_SUPPORTED',
    SAVE_PRIVACY_ANALYTICS = '@settings/SAVE_PRIVACY_ANALYTICS',
    SAVE_SHOW_ARCHIVED = '@settings/SAVE_SHOW_ARCHIVED',
    SAVE_PLAYBACK_SPEED = '@settings/SAVE_PLAYBACK_SPEED',

    IMPORT_SETTINGS = '@settings/IMPORT_SETTINGS',
    MIGRATE_GLOBAL_SETTINGS = '@settings/MIGRATE_GLOBAL_SETTINGS',

    TOGGLE_THEME = '@settings/TOGGLE_THEME',
    UPDATE_THEME = '@settings/UPDATE_THEME',

    UPDATE_PREFERRED_COLOR_SCHEME = '@settings/UPDATE_PREFERRED_COLOR_SCHEME',
    SHOW_RECOMMENDATIONS = '@settings/SHOW_RECOMMENDATIONS',
    HIDE_RECOMMENDATIONS = '@settings/HIDE_RECOMMENDATIONS',
    TOGGLE_RECOMMENDATIONS = '@settings/TOGGLE_RECOMMENDATIONS',
    TOGGLE_PERFORMANCE_MODE = '@settings/TOGGLE_PERFORMANCE_MODE',
    SAVE_LANGUAGE = '@settings/SAVE_LANGUAGE',
    SAVE_REGION = '@settings/SAVE_REGION',
    CHROME_CAST_CONNECTED = '@settings/CHROME_CAST_CONNECTED',
    CHROME_CAST_DISCONNECTED = '@settings/CHROME_CAST_DISCONNECTED',
    OPEN_KEYBOARD_SHORTCUTS = '@settings/OPEN_KEYBOARD_SHORTCUTS',
    CLOSE_KEYBOARD_SHORTCUTS = '@settings/CLOSE_KEYBOARD_SHORTCUTS',
    OPEN_CLOSE_KEYBOARD_SHORTCUTS = '@settings/OPEN_CLOSE_KEYBOARD_SHORTCUTS',
    SHOW_WHATS_NEW_MODAL = '@settings/SHOW_WHATS_NEW_MODAL',
    HIDE_WHATS_NEW_MODAL = '@settings/HIDE_WHATS_NEW_MODAL',
}

export const Actions = {
    savePodcastGridLayout: (gridLayout: number) =>
        createAction(ActionTypes.SAVE_PODCAST_GRID_LAYOUT, { gridLayout }),

    savePrivacyAnalytics: (privacyAnalytics: boolean) =>
        createAction(ActionTypes.SAVE_PRIVACY_ANALYTICS, { privacyAnalytics }),

    saveShowArchived: (showArchived: boolean) =>
        createAction(ActionTypes.SAVE_SHOW_ARCHIVED, { showArchived }),

    savePodcastGridBadgesOn: (badges: number) =>
        createAction(ActionTypes.SAVE_PODCAST_GRID_BADGES_ON, { badges }),

    saveBookmarksSortOrder: (bookmarksSortOrder: number) =>
        createAction(ActionTypes.SAVE_BOOKMARKS_SORT_ORDER, { bookmarksSortOrder }),

    saveLanguage: (language: string) => createAction(ActionTypes.SAVE_LANGUAGE, { language }),

    saveRegion: (region: string) => createAction(ActionTypes.SAVE_REGION, { region }),

    saveSkipForward: (skipForward: number) =>
        createAction(ActionTypes.SAVE_SKIP_FORWARD, { skipForward }),

    saveSkipBack: (skipBack: number) => createAction(ActionTypes.SAVE_SKIP_BACK, { skipBack }),

    saveIntelligentResumption: (intelligentResumption: boolean) =>
        createAction(ActionTypes.SAVE_INTELLIGENT_RESUMPTION, { intelligentResumption }),

    saveFilesAfterPlayingDeleteCloud: (filesAfterPlayingDeleteCloud: boolean) =>
        createAction(ActionTypes.SAVE_FILES_AFTER_PLAYING_DELETE_CLOUD, {
            filesAfterPlayingDeleteCloud,
        }),

    savePlaybackSpeed: (playbackSpeed: number) =>
        createAction(ActionTypes.SAVE_PLAYBACK_SPEED, { playbackSpeed }),

    saveRemoteSkipChapters: (remoteSkipChapters: boolean) =>
        createAction(ActionTypes.SAVE_REMOTE_SKIP_CHAPTERS, { remoteSkipChapters }),

    saveFilesAutoUpNext: (filesAutoUpNext: boolean) =>
        createAction(ActionTypes.SAVE_FILES_AUTO_UP_NEXT, { filesAutoUpNext }),

    saveAutoArchivePlayed: (autoArchivePlayed: boolean) =>
        createAction(ActionTypes.SAVE_AUTO_ARCHIVE_PLAYED, { autoArchivePlayed }),

    saveAutoArchiveIncludesStarred: (autoArchiveIncludesStarred: boolean) =>
        createAction(ActionTypes.SAVE_AUTO_ARCHIVE_INCLUDES_STARRED, {
            autoArchiveIncludesStarred,
        }),

    importSettings: (settings: TodoFixmeMigrationType) =>
        createAction(ActionTypes.IMPORT_SETTINGS, { settings }),

    migrateGlobalSettings: (settingsToMigrate: string[]) =>
        createAction(ActionTypes.MIGRATE_GLOBAL_SETTINGS, { settingsToMigrate }),

    savePodcastGridOrder: (gridOrder: number) =>
        createAction(ActionTypes.SAVE_PODCAST_GRID_ORDER, { gridOrder }),

    toggleTheme: () => createAction(ActionTypes.TOGGLE_THEME),

    updateTheme: (theme: number) => createAction(ActionTypes.UPDATE_THEME, { theme }),

    updatePreferredColorScheme: (preferredColorScheme: number) =>
        createAction(ActionTypes.UPDATE_PREFERRED_COLOR_SCHEME, { preferredColorScheme }),

    togglePerformanceMode: () => createAction(ActionTypes.TOGGLE_PERFORMANCE_MODE),

    toggleRecommendations: () => createAction(ActionTypes.TOGGLE_RECOMMENDATIONS),

    saveWebpSupported: (supported: boolean) =>
        createAction(ActionTypes.SAVE_WEBP_SUPPORTED, { supported }),

    chromeCastConnected: () => createAction(ActionTypes.CHROME_CAST_CONNECTED),

    chromeCastDisconnected: () => createAction(ActionTypes.CHROME_CAST_DISCONNECTED),

    hideRecommendations: () => createAction(ActionTypes.HIDE_RECOMMENDATIONS),

    showRecommendations: () => createAction(ActionTypes.SHOW_RECOMMENDATIONS),

    openKeyboardShortcuts: () => createAction(ActionTypes.OPEN_KEYBOARD_SHORTCUTS),

    closeKeyboardShortcuts: () => createAction(ActionTypes.CLOSE_KEYBOARD_SHORTCUTS),

    openCloseKeyboardShortcuts: () => createAction(ActionTypes.OPEN_CLOSE_KEYBOARD_SHORTCUTS),
};

export type Actions = ActionsUnion<typeof Actions>;
