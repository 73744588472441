import styled from 'styled-components';

export const LogoWrapper = styled.div<{ height: number }>`
    width: auto;
    height: ${props => props.height}px;

    img,
    svg {
        height: ${props => props.height}px;
    }

    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    svg {
        /* TODO: FIXME when*/
    }
`;
