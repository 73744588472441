import { intlReducer } from 'react-intl-redux';
import { combineReducers } from 'redux';
import archiveAllReducer from './archive-all.reducer';
import autoplayReducer from './autoplay.reducer';
import bookmarksReducer from './bookmarks.reducer';
import discoverReducer from './discover.reducer';
import electronReducer from './electron.reducer';
import episodeShowNotesReducer from './episode-show-notes.reducer';
import episodeSyncReducer from './episode-sync.reducer';
import episodeReducer from './episode.reducer';
import filterReducer from './filter.reducer';
import flagsReducer from './flags.reducer';
import foldersReducer from './folders.reducer';
import modalReducer from './modal.reducer';
import paymentsReducer from './payments.reducer';
import playerReducer from './player.reducer';
import podcastReducer from './podcast.reducer';
import podcastsReducer from './podcasts.reducer';
import searchReducer from './search.reducer';
import settingsReducer from './settings.reducer';
import shareReducer from './share.reducer';
import statsReducer from './stats.reducer';
import subscriptionReducer from './subscription.reducer';
import tracksReducer from './tracks.reducer';
import upNextReducer from './up-next.reducer';
import uploadManagerReducer from './upload-manager.reducer';
import uploadedFilesReducer from './uploaded-files.reducer';
import userReducer from './user.reducer';

export const rootReducer = combineReducers({
    autoplay: autoplayReducer,
    podcasts: podcastsReducer,
    podcast: podcastReducer,
    player: playerReducer,
    filter: filterReducer,
    folders: foldersReducer,
    upNext: upNextReducer,
    search: searchReducer,
    settings: settingsReducer,
    share: shareReducer,
    user: userReducer,
    subscription: subscriptionReducer,
    payments: paymentsReducer,
    uploadedFiles: uploadedFilesReducer,
    uploadManager: uploadManagerReducer,
    episode: episodeReducer, // For modal
    episodeShowNotes: episodeShowNotesReducer,
    episodeSync: episodeSyncReducer,
    archiveAll: archiveAllReducer, // For modal
    discover: discoverReducer,
    stats: statsReducer,
    flags: flagsReducer,
    intl: intlReducer,
    tracks: tracksReducer,
    modals: modalReducer,
    bookmarks: bookmarksReducer,
    electron: electronReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
