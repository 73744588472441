import { Bookmark, EpisodeSyncInfo } from 'model/types';
import { RootState } from '..';
import { getSortedEpisodesForPodcast } from './podcasts.selectors';
import { getUploadedFile } from './uploaded-files.selectors';

export const getEpisodeSyncByUuid = (state: RootState, uuid: string) =>
    state.episodeSync[uuid] || {};

export const getEpisodeSyncForUpNext = (state: RootState) => {
    const episodeSync = {} as Record<string, EpisodeSyncInfo>;
    state.upNext.order.forEach(uuid => {
        episodeSync[uuid] = getEpisodeSyncByUuid(state, uuid);
    });
    return episodeSync;
};

export const getEpisodeSyncForPodcast = (state: RootState, podcastUuid: string) => {
    const episodes = getSortedEpisodesForPodcast(state, podcastUuid) ?? [];
    const episodeSync: Record<string, EpisodeSyncInfo> = {};
    episodes.forEach(episode => {
        episodeSync[episode.uuid] = getEpisodeSyncByUuid(state, episode.uuid);
    });
    return episodeSync;
};

export const getBookmarksForPodcast = (state: RootState, podcastUuid: string) =>
    Object.values(getEpisodeSyncForPodcast(state, podcastUuid))
        .reduce<Bookmark[]>(
            (bookmarks, episodeSync) => bookmarks.concat(episodeSync.bookmarks ?? []),
            [],
        )
        .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));

export const getBookmarksForEpisode = (state: RootState, episodeUuid: string) => {
    const file = getUploadedFile(state, episodeUuid);
    const bookmarks = file?.bookmarks ?? getEpisodeSyncByUuid(state, episodeUuid).bookmarks ?? [];
    return [...bookmarks].sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
};

// Return "Bookmark %n" where %n is the first number that doesn't already exist as a bookmark title
export const getDefaultBookmarkTitleForEpisode = (
    state: RootState,
    episodeUuid: string,
    bookmarkText: string,
) => {
    const titles = getBookmarksForEpisode(state, episodeUuid).map(b => b.title);

    let defaultTitle = bookmarkText;
    let count = 0;

    while (titles.includes(defaultTitle)) {
        count += 1;
        defaultTitle = `${bookmarkText} ${count}`;
    }

    return defaultTitle;
};
