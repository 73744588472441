import styled from 'styled-components';

export const VideoControlsWrapper = styled.div`
    position: relative;
    .controls-bar {
        position: absolute;
        top: 0;
        height: 76px;
        width: 100%;
        opacity: 0;
        transition: opacity 300ms ease-out;
        background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
    }
    .video-fullscreen,
    .video-expand-button {
        opacity: 0;
        transition: opacity 300ms ease-out;
    }
    .video-fullscreen {
        position: absolute;
        cursor: pointer;
        width: 54px;
        height: 50px;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        .frame {
            height: 19px;
            width: 28px;
            position: relative;
            &:hover {
                cursor: pointer;
                .topleft {
                    transform: translate(-1px, -1px);
                }
                .topright {
                    transform: rotate(90deg) translate(-1px, -1px);
                }

                .bottomleft {
                    transform: rotate(270deg) translate(-1px, -1px);
                }

                .bottomright {
                    transform: rotate(180deg) translate(-1px, -1px);
                }
            }
            .corner {
                position: absolute;
                width: 7px;
                height: 7px;
                background-image: url('data:image/svg+xml,%3Csvg%20width%3D%227%22%20height%3D%227%22%20viewBox%3D%220%200%207%207%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ctitle%3Evideo%2Ffullscreen-corner%3C%2Ftitle%3E%3Cpath%20d%3D%22M1%206V1h5%22%20stroke-width%3D%222%22%20stroke%3D%22%23FFF%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%20stroke-linecap%3D%22round%22%2F%3E%3C%2Fsvg%3E');
                background-repeat: no-repeat;
                transition: transform 150ms;
            }
            .topleft {
                display: inline-block;
                position: absolute;
                top: 0;
                left: 0;
            }
            .topright {
                display: inline-block;
                position: absolute;
                top: 0;
                right: 0;
                transform: rotate(90deg);
            }
            .bottomleft {
                display: inline-block;
                position: absolute;
                bottom: 0;
                left: 0;
                transform: rotate(270deg);
            }
            .bottomright {
                display: inline-block;
                position: absolute;
                bottom: 0;
                right: 0;
                transform: rotate(180deg);
            }
        }
    }
    &:hover {
        .video-fullscreen,
        .controls-bar {
            opacity: 1;
        }
    }
`;
