import { isMacApp, isSafari } from 'helper/Browser';
import { STATIC_URL } from '../settings';
import { isDarkTheme, THEME } from '../model/theme';

/**
 * This is a sentinel value to indicate that the current episode is actually an uploaded
 * file and not a genuine podcast episode.
 */
export const UPLOADED_FILES_PODCAST_UUID = 'da7aba5e-f11e-f11e-f11e-da7aba5ef11e';

export const isUploadedFile = (episode: { podcastUuid?: string }) =>
    episode?.podcastUuid === UPLOADED_FILES_PODCAST_UUID;

export const UPLOADED_FILE_COLORS = {
    noColor: 0,
    grey: 1,
    red: 2,
    blue: 3,
    green: 4,
    yellow: 5,
    orange: 6,
    purple: 7,
    pink: 8,
};

export const getUploadedFileIconUrl = (theme: THEME, uploadedFileColor: number) => {
    const colorWithDefault =
        uploadedFileColor === UPLOADED_FILE_COLORS.noColor
            ? UPLOADED_FILE_COLORS.grey
            : uploadedFileColor;

    // Due to a scaling issue, safari seems to have an issue with this icon specifically
    // https://github.com/shiftyjelly/pocketcasts-webplayer/issues/1241
    if (isSafari() || isMacApp()) {
        return getPngUrl(theme, colorWithDefault);
    }

    if (isDarkTheme(theme)) {
        return `${STATIC_URL}/discover/images/artwork/dark/${colorWithDefault}.svg`;
    }

    // Light theme is the default
    return `${STATIC_URL}/discover/images/artwork/light/${colorWithDefault}.svg`;
};

export const isDefaultSvgImage = (imageUrl: string) =>
    imageUrl.slice(imageUrl.length - 4) === '.svg';

export const getUploadedFileIconPngUrl = (
    theme: THEME,
    episode: { color: number; imageUrl: string },
) => {
    const { imageUrl } = episode;
    if (!isDefaultSvgImage(imageUrl)) {
        return imageUrl;
    }

    const colorWithDefault =
        episode.color === UPLOADED_FILE_COLORS.noColor ? UPLOADED_FILE_COLORS.grey : episode.color;

    return getPngUrl(theme, colorWithDefault);
};

const getPngUrl = (theme: THEME, colorWithDefault: number) => {
    if (isDarkTheme(theme)) {
        return `${STATIC_URL}/discover/images/artwork/dark/960/${colorWithDefault}.png`;
    }

    // Light theme is the default
    return `${STATIC_URL}/discover/images/artwork/light/960/${colorWithDefault}.png`;
};

// We reject files whose size is less than 10kB - this is consistent with the mobile apps
export const MINIMUM_UPLOADED_FILE_SIZE_BYTES = 10 * 1024;
