import React from 'react';
import { useHistory } from 'react-router-dom';
import Modal from '../../../components/Modal';
import { POCKET_CASTS_PLUS } from '../../../model/external-links';
import { Button } from '../../../components/Button';
import {
    DialogIcon,
    DialogTitle,
    DialogDescription,
    DialogText,
    DialogContent,
    GetPlusButton,
    ReturnToPromoButton,
} from './PromoExpiredModal.styled';
import PlusErrorIcon from './plus-expired-icon.png';

export default function PromoExpiredModal() {
    const history = useHistory();

    return (
        <Modal hideCross onClose={() => ({})} useModernStyle>
            <DialogContent>
                <DialogIcon src={PlusErrorIcon} />
                <DialogText>
                    <DialogTitle>Promotion Expired or Invalid</DialogTitle>
                    <DialogDescription>
                        Unfortunately, it looks like this promotion is invalid.
                        <br />
                        <br />
                        You’re welcome to sign up for Pocket Casts anyway, or just download our
                        mobile app and get listening!
                    </DialogDescription>
                </DialogText>
                <GetPlusButton>
                    <Button
                        onClick={() => window.location.replace(POCKET_CASTS_PLUS)}
                        width={355}
                        kind="secondary"
                    >
                        Get Pocket Casts
                    </Button>
                </GetPlusButton>
                <ReturnToPromoButton>
                    <Button onClick={() => history.goBack()} width={355} kind="primary">
                        Return to Promotion Page
                    </Button>
                </ReturnToPromoButton>
            </DialogContent>
        </Modal>
    );
}
