import { TrackOnMount } from 'components/Tracks';
import useExportPodcasts from 'hooks/useExportPodcasts';
import useFormatMessage from 'hooks/useFormatMessage';
import useImportPodcasts from 'hooks/useImportPodcasts';
import useTracks from 'hooks/useTracks';
import React, { useEffect, useRef } from 'react';
import { SettingsDescription, SettingsLink, SettingsOption } from '../SettingsPage.styled';

function SettingsHelp() {
    const exportRef = useRef<HTMLAnchorElement>(null);

    const importRef = useRef<HTMLInputElement>(null);

    const { recordEvent } = useTracks();

    const formatMessage = useFormatMessage();

    const [exportDataUrl, exportPodcasts] = useExportPodcasts();

    const importPodcasts = useImportPodcasts();

    const handleImportPodcastsClick = () => {
        importRef.current?.click();

        recordEvent('settings_import_select_file');
    };

    const handleExportPodcastsClick = () => {
        exportPodcasts();

        recordEvent('settings_import_export_tapped');
    };

    const handleImportPodcastsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];

        if (file) {
            importPodcasts(file);
        }

        if (importRef.current) {
            importRef.current.value = '';
        }
    };

    useEffect(() => {
        if (exportDataUrl && exportRef.current) {
            exportRef.current?.click();
        }
    }, [exportDataUrl]);

    return (
        <div>
            <TrackOnMount event="settings_import_shown" />
            <h1>{formatMessage('import-and-export')}</h1>
            <SettingsOption>
                <SettingsLink as="button" onClick={handleExportPodcastsClick}>
                    {formatMessage('export-podcasts')}
                </SettingsLink>
                <SettingsDescription>{formatMessage('export-podcasts-desc')}</SettingsDescription>
                {exportDataUrl && (
                    <a
                        style={{ display: 'none' }}
                        ref={exportRef}
                        href={exportDataUrl}
                        download="PocketCasts.opml"
                    >
                        {formatMessage('download-file')}
                    </a>
                )}
            </SettingsOption>
            <SettingsOption>
                <SettingsLink as="button" onClick={handleImportPodcastsClick}>
                    {formatMessage('import-podcasts')}
                </SettingsLink>
                <SettingsDescription>{formatMessage('import-podcasts-desc')}</SettingsDescription>
                <input
                    type="file"
                    style={{ display: 'none' }}
                    ref={importRef}
                    accept=".opml, application/xml, text/xml, text/x-opml"
                    onChange={handleImportPodcastsChange}
                />
            </SettingsOption>
        </div>
    );
}

export default SettingsHelp;
