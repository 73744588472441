import React from 'react';
import { TimeTextWrapper } from './TimeText.styled';
import { formatTime } from '../../../helper/TimeHelper';

export type Props = {
    className?: string;
    timeSecs: number;
};

function TimeText({ timeSecs = 0, className }: Props) {
    return <TimeTextWrapper className={className}>{formatTime(timeSecs)}</TimeTextWrapper>;
}

export default TimeText;
