import { UserStats } from 'model/types';
import { LOCAL_STORAGE_KEY_EMAIL } from '../../model/local-storage';
import * as fromSubscriptionActions from '../actions/subscription.actions';
import { ActionTypes, Actions } from '../actions/user.actions';

interface UserState {
    isFetching: boolean;
    email: string | null;
    isForgottenPasswordSent: boolean;
    stats: UserStats;
    supportOpen: boolean;
    supportSending: boolean;
    supportSent: boolean;
    supportSendFailed: boolean;
    shouldShowEmailChangeModal: boolean;
    shouldShowPasswordChangeModal: boolean;
    shouldShowAccountDeleteModal: boolean;
    justSignedUp: boolean;
    emailChangeResult: Record<string, unknown>;
    passwordChangeResult: Record<string, unknown>;
    passwordResetResult: Record<string, unknown>;
    accountDeleteResult: Record<string, unknown>;
    errorMessage: string;
}

export const INITIAL_STATE: UserState = {
    isFetching: false,
    email: localStorage.getItem(LOCAL_STORAGE_KEY_EMAIL),
    isForgottenPasswordSent: false,
    stats: { timesStartedAt: null },
    supportOpen: false,
    supportSending: false,
    supportSent: false,
    supportSendFailed: false,
    shouldShowEmailChangeModal: false,
    shouldShowPasswordChangeModal: false,
    shouldShowAccountDeleteModal: false,
    justSignedUp: false,
    emailChangeResult: {},
    passwordChangeResult: {},
    passwordResetResult: {},
    accountDeleteResult: {},
    errorMessage: '',
};

export default (
    state: UserState = INITIAL_STATE,
    action: Actions | fromSubscriptionActions.Actions,
): UserState => {
    switch (action.type) {
        case ActionTypes.SIGN_IN_WITH_ACCESS_TOKEN:
        case ActionTypes.SIGN_IN: {
            return {
                ...state,
                isFetching: true,
                email: null,
                errorMessage: '',
            };
        }
        case ActionTypes.SIGN_IN_SUCCESS: {
            return {
                ...state,
                isFetching: false,
                email: action.payload.email,
                errorMessage: '',
            };
        }
        case ActionTypes.SIGN_IN_FAILURE: {
            return {
                ...state,
                errorMessage: action.payload.message,
                isFetching: false,
                email: null,
            };
        }

        case ActionTypes.SIGN_IN_TRY_AGAIN: {
            return {
                ...state,
                isFetching: false,
                email: null,
                errorMessage: '',
            };
        }
        case ActionTypes.SIGN_UP: {
            return { ...state, isFetching: true, errorMessage: '', email: null };
        }
        case ActionTypes.SIGN_UP_SUCCESS: {
            return {
                ...state,
                isFetching: false,
                email: action.payload.email,
                errorMessage: '',
                justSignedUp: true,
            };
        }
        case ActionTypes.SIGN_UP_ERROR: {
            return {
                ...state,
                errorMessage: action.payload.message,
                isFetching: false,
                email: null,
            };
        }
        case fromSubscriptionActions.ActionTypes.REDEEM_PROMO_SUCCESS:
        case ActionTypes.PURCHASE_WEB_PLAYER_SUCCESS: {
            return {
                ...state,
                justSignedUp: true,
            };
        }
        case ActionTypes.PURCHASE_WEB_PLAYER_FAILURE: {
            return {
                ...state,
            };
        }
        case ActionTypes.FORGOTTEN_PASSWORD: {
            return { ...state, isFetching: true, isForgottenPasswordSent: false, errorMessage: '' };
        }
        case ActionTypes.FORGOTTEN_PASSWORD_SENT: {
            return { ...state, isFetching: false, isForgottenPasswordSent: true };
        }
        case ActionTypes.FORGOTTEN_PASSWORD_ERROR: {
            return { ...state, errorMessage: action.payload.message, isFetching: false };
        }
        case ActionTypes.CLEAR_USER_SERVER_ERROR: {
            return { ...state, errorMessage: '' };
        }
        case ActionTypes.UN_AUTHENTICATE: {
            localStorage.clear();
            return {
                ...state,
                isFetching: false,
                email: null,
                errorMessage: '',
            };
        }
        case ActionTypes.DOWNLOAD_STATS_SUCCESS: {
            return { ...state, stats: action.payload.stats };
        }
        case ActionTypes.OPEN_SUPPORT: {
            return {
                ...state,
                supportOpen: true,
                supportSending: false,
                supportSent: false,
                supportSendFailed: false,
            };
        }
        case ActionTypes.CLOSE_SUPPORT: {
            return {
                ...state,
                supportOpen: false,
                supportSending: false,
                supportSent: false,
                supportSendFailed: false,
            };
        }
        case ActionTypes.SUPPORT_SENDING: {
            return { ...state, supportSending: true, supportSent: false, supportSendFailed: false };
        }
        case ActionTypes.SUPPORT_SENT: {
            return { ...state, supportSending: false, supportSent: true, supportSendFailed: false };
        }
        case ActionTypes.SUPPORT_SEND_FAILED: {
            return { ...state, supportSending: false, supportSent: false, supportSendFailed: true };
        }
        case ActionTypes.SHOW_CHANGE_EMAIL_MODAL: {
            return { ...state, shouldShowEmailChangeModal: true };
        }
        case ActionTypes.DISMISS_CHANGE_EMAIL_MODAL: {
            return { ...state, shouldShowEmailChangeModal: false };
        }
        case ActionTypes.SHOW_DELETE_ACCOUNT_MODAL: {
            return { ...state, shouldShowAccountDeleteModal: true };
        }
        case ActionTypes.DISMISS_DELETE_ACCOUNT_MODAL: {
            return { ...state, shouldShowAccountDeleteModal: false };
        }
        case ActionTypes.SHOW_CHANGE_PASSWORD_MODAL: {
            return { ...state, shouldShowPasswordChangeModal: true };
        }
        case ActionTypes.DISMISS_CHANGE_PASSWORD_MODAL: {
            return { ...state, shouldShowPasswordChangeModal: false };
        }
        case ActionTypes.EMAIL_CHANGE_REQUEST: {
            return {
                ...state,
                emailChangeResult: {
                    inProgress: true,
                    success: null,
                    message: null,
                },
            };
        }
        case ActionTypes.EMAIL_CHANGE_RESPONSE: {
            const emailSpread = {} as Record<string, unknown>;

            if (action.payload.success) {
                emailSpread.email = action.payload.newEmail;
            }

            return {
                ...state,
                ...emailSpread,
                emailChangeResult: {
                    inProgress: false,
                    success: action.payload.success,
                    message: action.payload.message,
                },
            };
        }
        case ActionTypes.ACCOUNT_DELETE_REQUEST: {
            return {
                ...state,
                accountDeleteResult: {
                    inProgress: true,
                    success: null,
                    message: null,
                },
            };
        }
        case ActionTypes.ACCOUNT_DELETE_RESPONSE: {
            return {
                ...state,
                accountDeleteResult: {
                    inProgress: false,
                    success: action.payload.success,
                    message: action.payload.message,
                },
            };
        }
        case ActionTypes.EMAIL_CHANGE_CANCEL: {
            return { ...state, emailChangeResult: {} };
        }
        case ActionTypes.PASSWORD_CHANGE_REQUEST: {
            return {
                ...state,
                passwordChangeResult: {
                    inProgress: true,
                    success: null,
                    message: null,
                },
            };
        }
        case ActionTypes.PASSWORD_CHANGE_RESPONSE: {
            return {
                ...state,
                passwordChangeResult: {
                    inProgress: false,
                    success: action.payload.success,
                    message: action.payload.message,
                },
            };
        }
        case ActionTypes.PASSWORD_CHANGE_CANCEL: {
            return { ...state, passwordChangeResult: {} };
        }
        case ActionTypes.PASSWORD_RESET_REQUEST: {
            return {
                ...state,
                passwordResetResult: {
                    inProgress: true,
                    success: null,
                    message: null,
                },
            };
        }
        case ActionTypes.PASSWORD_RESET_RESPONSE: {
            return {
                ...state,
                passwordResetResult: {
                    inProgress: false,
                    success: action.payload.success,
                    message: action.payload.message,
                },
            };
        }
        default:
            return state;
    }
};
