import React from 'react';
import { Icon } from 'components/Icon';
import { StyledNavigationButton } from './NavigationButton.styled';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    direction?: 'right' | 'left';
}

const NavigationButton = ({ onClick, disabled, direction = 'right', ...props }: Props) => {
    return (
        <StyledNavigationButton
            onClick={onClick}
            disabled={disabled}
            direction={direction}
            {...props}
        >
            <Icon id="arrow-right" size={28} />
        </StyledNavigationButton>
    );
};

export default NavigationButton;
