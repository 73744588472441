import React from 'react';
import { ButtonWrapper } from './AnimatedPlayButton.styled';

interface Props {
    onClick: () => void;
}

export const PauseButton = (props: Props) => {
    return (
        <ButtonWrapper {...props}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 96 96"
                width="96"
                height="96"
                style={{ width: '100%', height: '100%' }}
                preserveAspectRatio="xMidYMid meet"
            >
                <g
                    style={{ userSelect: 'none' }}
                    transform="matrix(1,0,0,1,48.023,48.016)"
                    opacity="1"
                >
                    <g opacity="1" transform="matrix(-1,0,0,-1,11.446,0)">
                        <path
                            fill="rgb(255,255,255)"
                            fillOpacity="1"
                            d="M0 0 M17.492,-19.016 C17.492,-19.567999999999998 17.94,-20.016 18.492,-20.016 C18.492,-20.016 27.508,-20.016 27.508,-20.016 C28.06,-20.016 28.508,-19.567999999999998 28.508,-19.016 C28.508,-19.016 28.508,19.016 28.508,19.016 C28.508,19.567999999999998 28.06,20.016 27.508,20.016 C27.508,20.016 18.492,20.016 18.492,20.016 C17.94,20.016 17.492,19.567999999999998 17.492,19.016 C17.492,19.016 17.492,-19.016 17.492,-19.016zM0 0 M-5.508,-19.016 C-5.508,-19.567999999999998 -5.0600000000000005,-20.016 -4.508,-20.016 C-4.508,-20.016 4.508,-20.016 4.508,-20.016 C5.0600000000000005,-20.016 5.508,-19.567999999999998 5.508,-19.016 C5.508,-19.016 5.508,19.016 5.508,19.016 C5.508,19.567999999999998 5.0600000000000005,20.016 4.508,20.016 C4.508,20.016 -4.508,20.016 -4.508,20.016 C-5.0600000000000005,20.016 -5.508,19.567999999999998 -5.508,19.016 C-5.508,19.016 -5.508,-19.016 -5.508,-19.016z"
                        ></path>
                        <path
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            fillOpacity="0"
                            strokeMiterlimit="4"
                            stroke="rgb(255,255,255)"
                            strokeOpacity="1"
                            strokeWidth="0"
                            d="M0 0 M17.492,-19.016 C17.492,-19.567999999999998 17.94,-20.016 18.492,-20.016 C18.492,-20.016 27.508,-20.016 27.508,-20.016 C28.06,-20.016 28.508,-19.567999999999998 28.508,-19.016 C28.508,-19.016 28.508,19.016 28.508,19.016 C28.508,19.567999999999998 28.06,20.016 27.508,20.016 C27.508,20.016 18.492,20.016 18.492,20.016 C17.94,20.016 17.492,19.567999999999998 17.492,19.016 C17.492,19.016 17.492,-19.016 17.492,-19.016zM0 0 M-5.508,-19.016 C-5.508,-19.567999999999998 -5.0600000000000005,-20.016 -4.508,-20.016 C-4.508,-20.016 4.508,-20.016 4.508,-20.016 C5.0600000000000005,-20.016 5.508,-19.567999999999998 5.508,-19.016 C5.508,-19.016 5.508,19.016 5.508,19.016 C5.508,19.567999999999998 5.0600000000000005,20.016 4.508,20.016 C4.508,20.016 -4.508,20.016 -4.508,20.016 C-5.0600000000000005,20.016 -5.508,19.567999999999998 -5.508,19.016 C-5.508,19.016 -5.508,-19.016 -5.508,-19.016z"
                        ></path>
                    </g>
                    <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                        <path
                            fill="rgb(255,255,255)"
                            fillOpacity="1"
                            d="M0 0 M-146.023,310.984"
                        ></path>
                        <path
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            fillOpacity="0"
                            strokeMiterlimit="4"
                            stroke="rgb(255,255,255)"
                            strokeOpacity="1"
                            strokeWidth="0"
                            d="M0 0 M-146.023,310.984"
                        ></path>
                    </g>
                </g>
            </svg>
        </ButtonWrapper>
    );
};
