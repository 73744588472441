import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as fromUploadManagerActions from '../../../../../redux/actions/upload-manager.actions';
import UploadManagerHeader from './view';

UploadManagerHeader.propTypes = {
    collapsed: PropTypes.bool.isRequired,
    onCollapse: PropTypes.func.isRequired,
    onExpand: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
    const { filesOrder, files } = state.uploadManager;

    const { progress, total, errorCount, completeCount, hasUnfinished } = filesOrder.reduce(
        (result, current) => ({
            total: result.total + (files[current].error ? 0 : files[current].size),
            progress: result.progress + (files[current].error ? 0 : files[current].progress),
            errorCount: result.errorCount + Number(!!files[current].error),
            completeCount: result.completeCount + Number(!!files[current].complete),
            hasUnfinished:
                result.hasUnfinished || (!files[current].complete && !files[current].error),
        }),
        {
            progress: 0,
            total: 0,
            errorCount: 0,
            completeCount: 0,
            hasUnfinished: false,
        },
    );

    const totalProgress = Math.min(100, Math.max(0, (100 * progress) / total));

    return {
        numberOfUploads: filesOrder.length,
        errorCount,
        completeCount,
        totalProgress,
        allFinished: !hasUnfinished,
    };
};

const mapDispatchToProps = {
    clearFilesFromUploadManager: fromUploadManagerActions.Actions.clearFilesFromUploadManager,
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadManagerHeader);
