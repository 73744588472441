import { ExpandingIconButton } from 'components/ExpandingIconButton';
import { TRANSITION_TIME_MS } from 'components/ExpandingIconButton/ExpandingIconButton.styled';
import styled from 'styled-components';
import { Typography } from 'styles';

export const FolderExpandingIconButton = styled(ExpandingIconButton)<{ folderColor: string }>`
    ${Typography.Desktop['H70-B']}
    line-height: 1;
    background: ${props => props.theme.legacyScss['theme-background']};
    color: ${props => props.folderColor || props.theme.tokens['primary-text-02']};
    box-sizing: border-box;

    @media (prefers-reduced-motion: no-preference) {
        transition: box-shadow ${TRANSITION_TIME_MS / 2}ms ease-in-out;
    }

    max-width: 600px;
    &:hover,
    &.focus-visible {
        color: ${props => props.folderColor || props.theme.tokens['primary-text-01']};
        box-shadow: inset 2px 0 0
                ${props => props.folderColor || props.theme.tokens['primary-text-01']},
            inset -2px 0 0 ${props => props.folderColor || props.theme.tokens['primary-text-01']},
            inset 0 2px 0 ${props => props.folderColor || props.theme.tokens['primary-text-01']},
            inset 0 -2px 0 ${props => props.folderColor || props.theme.tokens['primary-text-01']};
    }
`;
export const SubscribeExpandingIconButton = styled(ExpandingIconButton)<{
    isSubscribed: boolean;
    justSubscribed: boolean;
}>`
    ${Typography.Desktop['H80-B']}
    text-transform: uppercase;
    letter-spacing: 0.075em;
    ${props =>
        props.isSubscribed
            ? `
            background: ${props.theme.legacyScss['theme-button-disabled']};
            color: ${props.theme.legacyScss['theme-button-disabled-text']};
            `
            : `
            background: ${props.theme.tokens['support-02']};
            color: ${props.theme.legacyScss['theme-background']};
            `}
    &:hover,
    &.focus-visible {
        ${props =>
            props.isSubscribed && !props.justSubscribed
                ? `
                background-color: ${props.theme.legacyScss['theme-text-link-warning']};
                color: ${props.theme.legacyScss['theme-background']};
                `
                : `
                opacity: 0.9;
                `}
    }
`;
