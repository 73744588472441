import styled from 'styled-components';

export const StyledLink = styled.a`
    color: ${props => props.theme.tokens['primary-interactive-01']};
    cursor: pointer;
    text-decoration: none;

    &:hover {
        color: ${props => props.theme.tokens['primary-interactive-01-hover']};
    }
`;
