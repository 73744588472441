import styled from 'styled-components';
import { gridSize } from '../../../styles';

export const FilesEmptyStateWrapper = styled.div`
    display: flex;
    height: 70%;
    justify-content: center;
    align-items: center;
    margin-top: ${5 * gridSize}px;
`;

export const UploadFilesLabel = styled.label`
    cursor: pointer;
    text-align: center;
`;

export const UploadFilesInput = styled.input`
    opacity: 0;
    height: 1px;
    width: 1px;
`;
