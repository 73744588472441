import { PurchaseWebPlayerFailureActionPayload, RequestPurchaseActionPayload } from 'model/types';
import * as fromUserActions from '../actions/user.actions';
import * as fromSubscriptionActions from '../actions/subscription.actions';
import * as fromPaymentsActions from '../actions/payments.actions';

type PaymentsState = {
    subscriptionPurchaseFailed: boolean;
    subscriptionPurchaseDetails?:
        | RequestPurchaseActionPayload
        | PurchaseWebPlayerFailureActionPayload
        | undefined;
    isPurchasing: boolean;
    isRetry: boolean;
};

export const INITIAL_STATE: PaymentsState = {
    subscriptionPurchaseFailed: false,
    isPurchasing: false,

    // If a subscription purchase fails, we may need these details to retry the purchase
    subscriptionPurchaseDetails: undefined,
    isRetry: false,
};

export default (
    state = INITIAL_STATE,
    action: fromPaymentsActions.Actions | fromSubscriptionActions.Actions | fromUserActions.Actions,
): PaymentsState => {
    switch (action.type) {
        case fromUserActions.ActionTypes.PURCHASE_WEB_PLAYER_FAILURE: {
            return {
                subscriptionPurchaseFailed: true,
                subscriptionPurchaseDetails: action.payload,
                isPurchasing: false,
                isRetry: false,
            };
        }
        case fromUserActions.ActionTypes.PURCHASE_WEB_PLAYER_SUCCESS: {
            return {
                subscriptionPurchaseFailed: false,
                subscriptionPurchaseDetails: undefined,
                isPurchasing: false,
                isRetry: false,
            };
        }

        case fromUserActions.ActionTypes.PURCHASE_WEB_PLAYER_REQUEST: {
            return {
                ...state,
                isPurchasing: true,
                subscriptionPurchaseFailed: false,
                subscriptionPurchaseDetails: undefined,
            };
        }
        case fromPaymentsActions.ActionTypes.CLEAR_SUBSCRIPTION_PURCHASE_FAILURE: {
            return {
                ...state,
                subscriptionPurchaseFailed: false,
                subscriptionPurchaseDetails: undefined,
            };
        }
        case fromPaymentsActions.ActionTypes.RETRY_PURCHASE: {
            return {
                ...state,
                subscriptionPurchaseFailed: false,
                isRetry: true,
            };
        }
        default:
            return state;
    }
};
