import { StyledForm } from 'components/form/form.styled';
import styled from 'styled-components';
import { gridSize } from 'styles';

const DeleteAccountFormStyled = styled(StyledForm)`
    width: 100%;
    display: flex;
    flex-direction: column;
    strong {
        color: ${props => props.theme.tokens['primary-text-01']};
    }
`;

export const VerticalSpace = styled.div`
    width: ${2 * gridSize}px;
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    width: ${58 * gridSize}px;
    margin-left: auto;

    align-items: center;
    justify-content: flex-end;
    padding-bottom: ${4 * gridSize}px;
`;

export const Separator = styled.div`
    width: 100%;
    border-bottom: 1px solid ${props => props.theme.legacyScss['theme-line']};
    margin-top: ${2 * gridSize}px;
    margin-bottom: ${3 * gridSize}px;
`;

export default DeleteAccountFormStyled;
