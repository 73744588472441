import { TrackOnMount } from 'components/Tracks';
import useFormatMessage from 'hooks/useFormatMessage';
import React from 'react';
import { useDispatch } from 'react-redux';
import {
    POCKET_CASTS_PRIVACY_POLICY,
    POCKET_CASTS_SUPPORT,
    POCKET_CASTS_TERMS_OF_USE,
} from '../../../model/external-links';
import * as fromSettingsActions from '../../../redux/actions/settings.actions';
import * as fromUserActions from '../../../redux/actions/user.actions';
import { SettingsLink, SettingsOption } from '../SettingsPage.styled';

function SettingsHelp() {
    const dispatch = useDispatch();
    const formatMessage = useFormatMessage();

    const onOpenSupportClick = () => {
        dispatch(fromUserActions.Actions.openSupport());
    };

    const onOpenKeyboardShortcuts = () => {
        dispatch(fromSettingsActions.Actions.openKeyboardShortcuts());
    };

    return (
        <div>
            <TrackOnMount event="settings_help_shown" />
            <h1>{formatMessage('help')}</h1>
            <SettingsOption>
                <SettingsLink
                    href={`${POCKET_CASTS_SUPPORT}/webplayer`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {formatMessage('frequently-asked-questions')}
                </SettingsLink>
            </SettingsOption>
            <SettingsOption>
                <SettingsLink
                    href={POCKET_CASTS_PRIVACY_POLICY}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {formatMessage('privacy-policy')}
                </SettingsLink>
            </SettingsOption>
            <SettingsOption>
                <SettingsLink
                    href={POCKET_CASTS_TERMS_OF_USE}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {formatMessage('terms-and-conditions')}
                </SettingsLink>
            </SettingsOption>
            <SettingsOption>
                <SettingsLink as="button" onClick={onOpenKeyboardShortcuts}>
                    {formatMessage('keyboard-shortcuts')}
                </SettingsLink>
            </SettingsOption>
            <SettingsOption>
                <SettingsLink as="button" onClick={onOpenSupportClick}>
                    {formatMessage('send-feedback')}
                </SettingsLink>
            </SettingsOption>
        </div>
    );
}

export default SettingsHelp;
