import { POCKET_CASTS_PRIVACY_POLICY, POCKET_CASTS_TERMS_OF_USE } from 'model/external-links';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { TermsAndConditionsWrapper } from './TermsAndConditions.styled';

const TermsAndConditions = () => {
    return (
        <TermsAndConditionsWrapper>
            <p>
                <FormattedMessage id="taxes-may-apply" />
            </p>
            <p>
                <FormattedMessage
                    id="terms-agreement-wrapper"
                    values={{
                        terms: (
                            <a
                                target="_blank"
                                rel="noreferrer noopener"
                                href={POCKET_CASTS_TERMS_OF_USE}
                            >
                                <FormattedMessage id="terms-and-conditions" />
                            </a>
                        ),
                        privacy: (
                            <a
                                target="_blank"
                                rel="noreferrer noopener"
                                href={POCKET_CASTS_PRIVACY_POLICY}
                            >
                                <FormattedMessage id="privacy-policy" />
                            </a>
                        ),
                    }}
                />
            </p>
        </TermsAndConditionsWrapper>
    );
};

export default TermsAndConditions;
