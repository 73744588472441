import { useReducer, useEffect } from 'react';

function reducer(state, action) {
    switch (action.type) {
        case 'CHECK_IF_FORM_VALID': {
            const valid = Object.keys(state.fields)
                .map(key => state.fields[key])
                .filter(field => field && field.visible)
                .every(field => field.state === 'ok');
            return {
                ...state,
                isValid: valid,
            };
        }
        case 'SET_FIELD_VALUE':
            return {
                ...state,
                lastTouched: action.fieldKey,
                fields: {
                    ...state.fields,
                    [action.fieldKey]: {
                        ...state.fields[action.fieldKey],
                        value: action.value,
                    },
                },
            };
        case 'SET_FOCUS':
            return {
                ...state,
                fields: {
                    ...state.fields,
                    [action.fieldKey]: {
                        ...state.fields[action.fieldKey],
                        focus: action.value,
                    },
                },
            };
        case 'UPDATE_FIELD':
            return {
                ...state,
                lastTouched: action.fieldKey,
                fields: {
                    ...state.fields,
                    [action.fieldKey]: {
                        ...state.fields[action.fieldKey],
                        ...action.data,
                    },
                },
            };
        case 'SET_FIELD_ERROR':
            return {
                ...state,
                lastTouched: null,
                fields: {
                    ...state.fields,
                    [action.fieldKey]: {
                        ...state.fields[action.fieldKey],
                        error: action.error,
                        state: action.state,
                    },
                },
            };
        default:
            throw new Error();
    }
}

const validateField = (form, field) => {
    if (field.value === '') {
        return '';
    }
    if (field && field.validation) {
        const { rules } = field.validation;
        for (const ruleName of Object.keys(rules)) {
            const ruleValue = rules[ruleName];
            switch (ruleName) {
                case 'value':
                    if (field.value !== ruleValue) {
                        return ruleName;
                    }
                    break;
                case 'pattern':
                    if (!ruleValue.test(field.value)) {
                        return ruleName;
                    }
                    break;
                case 'minLength':
                    if (field.value.length < ruleValue) {
                        return ruleName;
                    }
                    break;
                case 'maxLength':
                    if (field.value.length > ruleValue) {
                        return ruleName;
                    }
                    break;
                case 'customValidation': {
                    const isValid = ruleValue(form.fields);
                    if (!isValid) {
                        return ruleName;
                    }
                    break;
                }
                default:
                    // Exception or a welcomed experience
                    break;
            }
        }
    }
    return null;
};

const onValidate = (form, dispatch) => {
    try {
        Object.keys(form.fields).forEach(fieldKey => {
            const field = form.fields[fieldKey];
            const error = validateField(form, field);
            if (field.visible && error != null) {
                if (error === '') {
                    dispatch({ type: 'SET_FIELD_ERROR', fieldKey, error: null, state: 'none' });
                } else {
                    let errorMessage = null;
                    if (field.validation.messages) {
                        errorMessage = field.validation.messages[error]
                            ? field.validation.messages[error]
                            : null;
                    }
                    dispatch({
                        type: 'SET_FIELD_ERROR',
                        fieldKey,
                        error: errorMessage,
                        state: 'error',
                    });
                }
            } else {
                dispatch({ type: 'SET_FIELD_ERROR', fieldKey, error: null, state: 'ok' });
            }
        });
    } catch (e) {
        console.error(e);
    }
};

const useForm = fields => {
    const [form, dispatch] = useReducer(reducer, { fields, lastTouched: null, isValid: false });

    // Whenever the form is updated - the form will update the state the most recent field key modified
    useEffect(() => {
        if (form.lastTouched) {
            onValidate(form, dispatch);
        }
        dispatch({ type: 'CHECK_IF_FORM_VALID' });
    }, [form.lastTouched]);

    const onChange = event => {
        const field = form.fields[event.target.name];
        if (field) {
            const { value, name, checked } = event.target;
            if (field.type === 'checkbox') {
                dispatch({ type: 'SET_FIELD_VALUE', fieldKey: name, value: checked });
            } else {
                dispatch({ type: 'SET_FIELD_VALUE', fieldKey: name, value });
            }
        }
    };

    const onFocusChange = (event, value) => {
        const field = form.fields[event.target.name];
        if (field) {
            dispatch({ type: 'SET_FOCUS', fieldKey: field.name, value });
        }
    };

    const getValues = () => {
        const valuesMap = {};
        Object.keys(form.fields)
            .map(key => form.fields[key])
            .filter(field => field.visible)
            .forEach(field => {
                valuesMap[field.name] = field.value;
            });
        return valuesMap;
    };

    const setValue = (fieldKey, value) => {
        dispatch({
            type: 'UPDATE_FIELD',
            fieldKey,
            data: {
                value,
            },
        });
    };

    const setVisibility = (fieldKey, visible) => {
        dispatch({
            type: 'UPDATE_FIELD',
            fieldKey,
            data: {
                visible,
            },
        });
    };

    return {
        form: {
            ...form,
            getValues,
            setVisibility,
            setValue,
            onChange,
            onFocusChange,
        },
    };
};

export default useForm;
