import React from 'react';
import PropTypes from 'prop-types';
import { PodcastDiscover } from 'model/types';
import { PodcastImage, SubscribeButton } from 'components';
import {
    DiscoverSingleWrapper,
    DiscoverSingleText,
    DiscoverSingleTitle,
    DiscoverSingleDescription,
    ImageWrapper,
    Spons0redBadge,
    FreshPickBadge,
} from './DiscoverSingle.styled';

type Props = {
    description: string;
    podcast: PodcastDiscover;
    onClick: () => void;
    onSubscribe: () => void;
    sponsored: boolean;
};

function DiscoverSingle({ description, podcast, onClick, onSubscribe, sponsored }: Props) {
    return (
        <>
            <DiscoverSingleWrapper role="button" onClick={onClick}>
                <ImageWrapper>
                    <PodcastImage uuid={podcast.uuid} title={podcast.title} />
                    <SubscribeButton
                        circled
                        podcastUuid={podcast.uuid}
                        onSubscribe={onSubscribe}
                        eventSource="discover"
                    />
                </ImageWrapper>
                <DiscoverSingleText>
                    {sponsored ? (
                        <Spons0redBadge>SPONSORED</Spons0redBadge>
                    ) : (
                        <FreshPickBadge>FRESH PICK</FreshPickBadge>
                    )}
                    <DiscoverSingleTitle>{podcast.title || ''}</DiscoverSingleTitle>
                    <DiscoverSingleDescription>{description || ''}</DiscoverSingleDescription>
                </DiscoverSingleText>
            </DiscoverSingleWrapper>
        </>
    );
}

DiscoverSingle.propTypes = {
    description: PropTypes.string,
    podcast: PropTypes.object,
    onPodcastClick: PropTypes.func,
    sponsored: PropTypes.bool,
};

DiscoverSingle.defaultProps = {};

export default DiscoverSingle;
