import styled from 'styled-components';
import { gridSize } from 'styles';
import { Button } from 'components/Button';

export const FormSection = styled.div`
    margin-bottom: ${2 * gridSize}px;
`;

export const Footer = styled.div`
    margin: ${4 * gridSize}px 0 ${2 * gridSize}px;
    display: flex;
    flex-wrap: wrap;
    button[kind='primary'] {
        padding: 0 ${3 * gridSize}px;
        width: 100%;
    }
`;

export const ErrorMessage = styled.div`
    width: 100%;
    margin-bottom: ${2 * gridSize}px;
`;

export const DeleteButton = styled(Button)`
    display: flex;
    align-items: flex-start;
    text-align: left;
    width: 100%;
    color: ${props => props.theme.tokens['primary-text-01']};
    margin-top: ${6 * gridSize}px;
    &:hover {
        color: ${props => props.theme.tokens['support-05']};
    }
    svg {
        color: ${props => props.theme.tokens['support-05']};
        margin: -5px 6px 0 0;
    }
`;
