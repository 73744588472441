import { useEffect, useMemo, useState } from 'react';

/**
 * Hook wrapping matchMedia API to detect whether media query matches.
 * Please use as last resort, prefer to rely on native CSS media queries.
 * Credit thanks to Tumblr's webapp (Redpop).
 *
 * @param query {string} - media query string, ie. '(min-width: 500px)'
 * @returns
 */
export function useMediaQuery(query: string): boolean {
    const mediaQueryList = useMemo(() => {
        return window.matchMedia(query) ?? null;
    }, [query]);

    const [matches, setMatches] = useState<boolean>(mediaQueryList.matches);

    useEffect(() => {
        const handleChange = (event: MediaQueryListEvent) => {
            setMatches(event.matches);
        };

        mediaQueryList.addEventListener?.('change', handleChange);
        return () => {
            mediaQueryList?.removeEventListener?.('change', handleChange);
        };
    }, [mediaQueryList]);

    return matches;
}
