import { updateIntl } from 'react-intl-redux';
import TRANSLATIONS from 'translations/glotpress';

type LanguageData = {
    code: string;
    name: string;
    englishName: string;
};

export const languages: LanguageData[] = [
    { code: 'da', name: 'Dansk', englishName: 'Danish' },
    { code: 'de', name: 'Deutsch', englishName: 'German' },
    { code: 'en', name: 'English', englishName: 'English' },
    { code: 'es', name: 'Español', englishName: 'Spanish' },
    { code: 'fr', name: 'Français', englishName: 'French' },
    { code: 'it', name: 'Italiano', englishName: 'Italian' },
    { code: 'nl', name: 'Nederlands', englishName: 'Dutch' },
    { code: 'nb', name: 'Norsk', englishName: 'Norwegian' },
    { code: 'pl', name: 'Polski', englishName: 'Polish' },
    {
        code: 'pt-br',
        name: 'Portugues (Brazil)',
        englishName: 'Portuguese (Brazil)',
    },
    { code: 'ru', name: 'Pусский', englishName: 'Russian' },
    { code: 'sv', name: 'Svenska', englishName: 'Swedish' },
    { code: 'ja', name: '日本語', englishName: 'Japanese' },
    { code: 'zh-cn', name: '简体中文', englishName: 'Chinese (Simplified)' },
    { code: 'zh-tw', name: '繁體中文', englishName: 'Chinese (Traditional)' },
];

const localeLanguage = (locale: string) => {
    const parts = (locale || '').split('-');
    return parts.length > 0 ? parts[0] : 'en';
};

export const getShortLanguage = (language?: string) => {
    if (!language) {
        return 'en';
    }
    if (['pt-br', 'zh-cn', 'zh-tw'].includes(language.toLowerCase())) {
        return language.toLowerCase();
    }
    // Glotpress changes its Norwegian shortcode from 'no' to 'nb'. This snippet makes sure that users
    // who'd already chosen 'no' will use 'nb' behind the scenes, so they don't get switched to English.
    if (language.toLowerCase() === 'no') {
        return 'nb';
    }
    return localeLanguage(language);
};

export const getTranslations = (language: string) => {
    const shortLang = getShortLanguage(language);
    return { ...TRANSLATIONS.en, ...TRANSLATIONS[shortLang] };
};

export const normalisedUpdateIntlAction = (locale: string) => {
    const localeShortCode = getShortLanguage(locale);
    const translations = getTranslations(localeShortCode);
    return updateIntl({
        locale: localeShortCode,
        messages: translations,
    });
};
