import { isElectronApp } from 'helper/Browser';
import { cleanForFileName } from 'helper/StringHelper';
import { UploadedFile, UploadedFilesAccount } from 'model/types';
import { Channel, END, EventChannel, buffers, eventChannel } from 'redux-saga';
import { call, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { ActionWithPayload } from 'redux/actions/action-creators';
import uuidLib from 'uuid';
import { PlayingStatus } from '../../helper/PlayingStatus';
import {
    audioMimeTypes,
    guessMimeTypeFromFileExtension,
    mimeTypeIsSupported,
    videoMimeTypes,
} from '../../helper/UploadedFilesHelper';
import { MINIMUM_UPLOADED_FILE_SIZE_BYTES } from '../../model/uploaded-files';
import * as filesApi from '../../services/filesApi';
import * as fromTracksActions from '../actions/tracks.actions';
import * as fromUpNextActions from '../actions/up-next.actions';
import * as fromUploadManagerActions from '../actions/upload-manager.actions';
import * as fromUploadedFilesActions from '../actions/uploaded-files.actions';
import {
    getSettings,
    getTheme,
    getUploadedFile,
    getUploadedFileCurried,
    getUploadedFileLastModified,
    getUploadedFilesLastModified,
    getUploadedFilesMap,
    getUploadedFilesOrder,
    getUploadedFilesSuspended,
} from '../reducers/selectors';

/* Exported for direct use in userReturned */
export function* fetchUploadedFilesData() {
    try {
        const theme: ReturnType<typeof getTheme> = yield select(getTheme);

        const ifModifiedSince: string = yield select(getUploadedFilesLastModified);

        const { files, account, lastModified } = yield call(
            filesApi.getUploadedFiles,
            theme,
            ifModifiedSince,
        );

        yield put(fromUploadedFilesActions.Actions.filesFetchSuccess(files, account, lastModified));
    } catch (error) {
        if (error instanceof Error && error.message !== '304') {
            yield put(
                fromUploadedFilesActions.Actions.filesFetchFailure(
                    error || 'Error fetching uploaded files information, please refresh the page.',
                ),
            );
        }
    }
}

const createMetadataChannel = (url: string, element: string) =>
    eventChannel(emitter => {
        const mediaElement = document.createElement(element) as HTMLAudioElement;
        mediaElement.preload = 'metadata';

        mediaElement.onloadedmetadata = () => {
            emitter({
                duration: mediaElement.duration,
            });
            emitter(END);
        };

        mediaElement.onerror = () => {
            emitter({
                error: true,
            });
            emitter(END);
        };

        mediaElement.src = url;

        return () => ({});
    }, buffers.sliding<any>(8));

function* fillInDurationForFilesWhereNeeded() {
    const blackList: string[] = [];
    let uuid;
    do {
        const fetchedFileUuids: string[] = yield select(getUploadedFilesOrder);
        const files: Record<string, UploadedFile> = yield select(getUploadedFilesMap);

        uuid = fetchedFileUuids.find(
            (uuid: string) =>
                !Number(files[uuid].duration) &&
                !blackList.includes(uuid) &&
                mimeTypeIsSupported(files[uuid].contentType),
        );

        if (uuid) {
            const mimeType = files[uuid].contentType;
            const { url } = yield call(filesApi.getMediaFileOfUploadedFile, uuid);

            try {
                const channel: Channel<EventChannel<string>> = audioMimeTypes().includes(mimeType)
                    ? yield call(createMetadataChannel, url, 'audio')
                    : videoMimeTypes().includes(mimeType)
                      ? yield call(createMetadataChannel, url, 'video')
                      : null;

                if (channel) {
                    const channelEvent: {
                        error: string;
                        duration: number;
                    } = yield take(channel);

                    if (channelEvent.error) {
                        blackList.push(uuid);
                    } else {
                        // We have to default to 1 if something has gone wrong, otherwise we'll get
                        // stuck on the same file, which is likely to have the same issue repeatedly.
                        yield put(
                            fromUploadedFilesActions.Actions.requestUpdateFile(uuid, {
                                duration: Math.floor(channelEvent.duration) || 1,
                            }),
                        );
                    }
                } else {
                    blackList.push(uuid);
                }
            } catch {
                blackList.push(uuid);
            }
        }
    } while (uuid);
}

function* fetchUploadedFilesUsage() {
    try {
        const response: UploadedFilesAccount = yield call(filesApi.getUploadedFilesUsage);
        yield put(fromUploadedFilesActions.Actions.uploadedFilesUsageSuccess(response));
    } catch {
        yield put(fromUploadedFilesActions.Actions.uploadedFilesUsageFailure());
    }
}

function* fetchFile(
    action:
        | ReturnType<typeof fromUploadedFilesActions.Actions.fetchUploadedFile>
        | ReturnType<typeof fromUploadedFilesActions.Actions.editFile>,
) {
    const { uuid } = action.payload;
    const theme: ReturnType<typeof getTheme> = yield select(getTheme);
    const ifModifiedSince: string = yield select(getUploadedFileLastModified, uuid);

    try {
        const response: UploadedFile = yield call(
            filesApi.getUploadedFile,
            uuid,
            theme,
            ifModifiedSince,
            getUploadedFileCurried(yield select()),
        );

        if (!response) {
            return;
        }

        const {
            title,
            size,
            contentType,
            color,
            imageUrl,
            artworkImageUrl,
            playedUpTo,
            playingStatus,
            duration,
            published,
            lastModified,
        } = response;

        yield put(
            fromUploadedFilesActions.Actions.fileFetchSuccess(
                uuid,
                title,
                // FIXME: Either UploadFile type's definition is wrong (should be number) or fileFetchSuccess definition is wrong (should be string)
                size as unknown as number,
                contentType,
                color,
                imageUrl,
                artworkImageUrl,
                playedUpTo,
                playingStatus,
                // FIXME: Either UploadFile type's definition is wrong (should be number) or fileFetchSuccess definition is wrong (should be string)
                duration as unknown as number,
                published,
                lastModified,
            ),
        );
    } catch (e) {
        yield put(
            fromUploadedFilesActions.Actions.fileFetchFailure(`Error fetching file ${uuid}: ${e}`),
        );
    }
}

function* updateFiles(
    action: ReturnType<typeof fromUploadedFilesActions.Actions.requestUpdateFiles>,
) {
    const { files } = action.payload;
    const settings: ReturnType<typeof getSettings> = yield select(getSettings);

    if (files.constructor !== Array || files.length === 0) {
        return;
    }

    try {
        // Do the update on the server
        yield call(filesApi.updateFiles, action.payload);

        // If any of those set the playing status to played, remove them from Up Next
        for (const file of action.payload.files) {
            if (file.playingStatus === PlayingStatus.COMPLETED) {
                yield put(
                    fromUpNextActions.Actions.removeFromUpNext(file.uuid || '', {
                        eventSource: null,
                    }),
                );

                if (settings.filesAfterPlayingDeleteCloud) {
                    yield put(fromUploadedFilesActions.Actions.requestDeleteFile(file.uuid));
                }
            }
        }

        // Generate a map of uuid => { ...new values }
        const fileUpdateMap = action.payload.files.reduce(
            (result, current) => ({
                ...result,
                [current.uuid]: { ...current },
            }),
            {},
        );

        yield put(fromUploadedFilesActions.Actions.updateFilesSuccess(fileUpdateMap));
    } catch (e) {
        yield put(fromUploadedFilesActions.Actions.updateFilesFailure(`Update files failed: ${e}`));
    }
}

function* filesUpdateSuccess(
    action: ReturnType<typeof fromUploadedFilesActions.Actions.updateFilesSuccess>,
) {
    const map = action.payload.fileUpdates;
    const uuids = Object.keys(map);

    for (const uuid of uuids) {
        yield put(fromUploadedFilesActions.Actions.fetchUploadedFile(uuid));
    }
}

function* doFileUploadPreflight(
    action: ReturnType<typeof fromUploadedFilesActions.Actions.requestFileUploadPreflight>,
) {
    const isSuspended: ReturnType<typeof getUploadedFilesSuspended> =
        yield select(getUploadedFilesSuspended);

    const { file } = action.payload;
    const { name, size, type } = file;

    const contentType =
        type || guessMimeTypeFromFileExtension(name.substr(name.lastIndexOf('.') + 1));

    const localUuid = uuidLib.v4();

    try {
        if (isSuspended) {
            throw new Error('Files access not allowed');
        }

        if (size < MINIMUM_UPLOADED_FILE_SIZE_BYTES) {
            throw new Error('File size is less than 10kB');
        }

        if (!contentType) {
            throw new Error("Can't upload as this file type is not supported");
        }

        const {
            uuid,
            url,
            message: errorIfPresent,
        } = yield filesApi.uploadFilePreflight(name, size, contentType);

        // Hand off to Upload Manager
        yield put(
            fromUploadManagerActions.Actions.addFileToUploadManager(
                file,
                uuid || localUuid,
                url,
                errorIfPresent,
            ),
        );
    } catch (error) {
        if (!(error instanceof Error)) {
            throw error;
        }

        yield put(
            fromTracksActions.Actions.recordEvent('episode_upload_failed', {
                episode_uuid: localUuid,
                source: 'files',
                error: error.message,
            }),
        );

        if (error.message === '400') {
            yield put(
                fromUploadManagerActions.Actions.addFileToUploadManager(
                    file,
                    localUuid,
                    '',
                    "This file doesn't appear to be in a format we can play",
                ),
            );
        } else {
            yield put(
                fromUploadManagerActions.Actions.addFileToUploadManager(
                    file,
                    localUuid,
                    '',
                    error.message,
                ),
            );
        }
    }
}

function* doImageUploadPreflight(
    action: ReturnType<typeof fromUploadedFilesActions.Actions.requestImageUploadPreflight>,
) {
    const { file, uuid } = action.payload;
    const { size, type } = file;

    try {
        const { url } = yield filesApi.uploadImageForFilePreflight(uuid, size, type);

        // Hand off to Upload Manager
        if (url) {
            yield put(fromUploadManagerActions.Actions.addImageToUploadManager(file, uuid, url));
        }
    } catch {
        yield put(fromUploadManagerActions.Actions.addImageToUploadManager(null, uuid, null));
    }
}

function* deleteFile(
    action: ReturnType<typeof fromUploadedFilesActions.Actions.requestDeleteFile>,
) {
    const { uuid } = action.payload;

    yield put(
        fromTracksActions.Actions.recordEvent('episode_deleted_from_cloud', {
            episode_id: uuid,
            source: 'files',
        }),
    );

    try {
        yield filesApi.deleteFile(uuid);
        yield put(fromUploadedFilesActions.Actions.deleteFileSuccess(uuid));
        yield put(fromTracksActions.Actions.recordEvent('user_file_deleted'));
        yield put(fromUpNextActions.Actions.removeFromUpNext(uuid, { eventSource: null }));
    } catch (e) {
        if (!(e instanceof Error)) {
            throw e;
        }
        yield put(fromUploadedFilesActions.Actions.deleteFileFailure(uuid, e.message));
    }
}

function* showEditDialog(action: ReturnType<typeof fromUploadedFilesActions.Actions.editFile>) {
    const { uuid } = action.payload;

    try {
        yield fetchFile(action);
        const file: UploadedFile = yield select(getUploadedFile, uuid);
        yield put(fromUploadedFilesActions.Actions.loadFileToEdit(file));
    } catch {
        // Deliberate no-op
    }
}

function* downloadFile(action: ReturnType<typeof fromUploadedFilesActions.Actions.downloadFile>) {
    const { uuid, fileName } = action.payload;

    const { url } = yield call(filesApi.getMediaFileOfUploadedFile, uuid);

    const urlParts = new URL(url);
    const fileExtension = urlParts.pathname.split('.').pop();

    let recommendedFileName;
    if (fileExtension && fileName.endsWith(fileExtension)) {
        recommendedFileName = fileName;
    } else {
        recommendedFileName = cleanForFileName(fileName);
    }

    // Simulate a link click as the native Mac App does not support window.open(url).
    // This link is identical to the Podcast Episode download link in EpisodePopup.

    const linkId = `file-download-link-${uuid}`;
    const anchorElement = document.createElement('a') as HTMLAnchorElement;

    anchorElement.id = linkId;
    anchorElement.href = url;
    anchorElement.download = 'true';
    anchorElement.target = '_blank';
    anchorElement.rel = 'noopener noreferrer';
    anchorElement.setAttribute('data-filename', recommendedFileName);

    const rootElem = document.getElementById('root') as HTMLDivElement;
    rootElem?.appendChild(anchorElement);
    anchorElement.click();

    // TODO: This is a hack so that we can continue getting the file name attribute from the link within the electron app in the `will-download` handler.
    // In the future, we could try to refactor the logic here so we don't have to create dummy anchors. In the meantime we still need to hook in here
    // so we can get the correct URL from getMediaFileOfUploadedFile.
    if (isElectronApp()) {
        setTimeout(() => {
            anchorElement?.parentNode?.removeChild(anchorElement);
        }, 5000);
        return;
    }
    anchorElement?.parentNode?.removeChild(anchorElement);
}

//

export function* watchFetchUploadedFilesData() {
    yield takeLatest(
        fromUploadedFilesActions.ActionTypes.FILES_FETCH_REQUEST,
        fetchUploadedFilesData,
    );
}

export function* watchFetchUploadedFilesSuccess() {
    yield takeLatest(
        fromUploadedFilesActions.ActionTypes.FILES_FETCH_SUCCESS,
        fillInDurationForFilesWhereNeeded,
    );
}

export function* watchFetchUploadedFilesUsage() {
    yield takeLatest(
        fromUploadedFilesActions.ActionTypes.FILES_USAGE_REQUEST,
        fetchUploadedFilesUsage,
    );
}

export function* watchFetchFile() {
    yield takeLatest(fromUploadedFilesActions.ActionTypes.FILE_FETCH_REQUEST, fetchFile);
}

export function* watchFilesUpdate() {
    yield takeLatest(fromUploadedFilesActions.ActionTypes.FILES_UPDATE_REQUEST, updateFiles);
}

export function* watchFilesUpdateSuccess() {
    yield takeLatest(fromUploadedFilesActions.ActionTypes.FILES_UPDATE_SUCCESS, filesUpdateSuccess);
}

export function* watchFileUploadPreflight() {
    yield takeEvery(
        fromUploadedFilesActions.ActionTypes.FILE_UPLOAD_PREFLIGHT_REQUEST,
        doFileUploadPreflight,
    );
}

export function* watchImageUploadPreflight() {
    yield takeLatest(
        fromUploadedFilesActions.ActionTypes.FILE_IMAGE_UPLOAD_PREFLIGHT_REQUEST,
        doImageUploadPreflight,
    );
}

export function* watchFileDelete() {
    yield takeLatest(fromUploadedFilesActions.ActionTypes.FILE_DELETE_REQUEST, deleteFile);
}

export function* watchShowEditDialog() {
    yield takeLatest(fromUploadedFilesActions.ActionTypes.FILE_SHOW_EDIT_DIALOG, showEditDialog);
}

export function* watchFileDownload() {
    yield takeLatest(fromUploadedFilesActions.ActionTypes.FILE_DOWNLOAD, downloadFile);
}

// This function needs to be used by other Sagas to allow for correct lastModified behaviour.
export function* getUploadedFileFetchingIfNecessary(uuid: string) {
    yield fetchFile({
        type: fromUploadedFilesActions.ActionTypes.FILE_FETCH_REQUEST,
        payload: { uuid },
    } as ActionWithPayload<
        fromUploadedFilesActions.ActionTypes.FILE_FETCH_REQUEST,
        { uuid: string }
    >);
    const file: UploadedFile = yield select(getUploadedFile, uuid);
    return file;
}
