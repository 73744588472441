import styled from 'styled-components';
import { blueTextLinkMixin, gridSize, Typography } from 'styles';
import { lightenColor } from 'helper/ColorHelper';

export const Wrapper = styled.article`
    ${Typography.Desktop.H70}
    display: flex;
    cursor: pointer;
`;

export const Image = styled.div`
    margin: 3px ${gridSize * 4}px 0 0;
    width: 112px;
`;

export const Content = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    min-width: 0; // https://stackoverflow.com/questions/38223879/white-space-nowrap-breaks-flexbox-layout
`;

export const Label = styled.div`
    ${Typography.Desktop['H70-TBC']};
    margin: 6px 0;
    color: ${props => props.color || props.theme.tokens['primary-interactive-01']};
`;

export const EpisodeTitle = styled.h2`
    ${Typography.Desktop.H30};
    -webkit-line-clamp: 2;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
`;

export const PodcastLink = styled.a<{ color?: string }>`
    ${Typography.Desktop['H70-B']};
    ${blueTextLinkMixin};
    color: ${props => props.theme.tokens['primary-text-02']};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover {
        color: ${props => lightenColor(props.theme.tokens['primary-text-02'], 0.3)};
    }
`;

export const PlayButton = styled.div`
    margin-right: ${gridSize * 1.5}px;
    > button {
        text-transform: capitalize;
    }
`;

export const Actions = styled.div`
    color: ${props => props.theme.tokens['primary-text-02']};
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: auto;
    padding-top: ${gridSize * 1}px;
    .duration-text {
        &:after {
            content: '•';
            margin: 0 ${gridSize * 0.75}px;
        }
    }
`;

export const Text = styled.p`
    margin: ${gridSize}px 0;
`;
