import React from 'react';

const Beta = () => (
    <svg width="33" height="17" viewBox="0 0 33 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="33" height="17" rx="2" fill="#E0E6EA" />
        <path
            d="M4.58594 12V4.9541H7.65234C8.98047 4.9541 9.81543 5.6377 9.81543 6.72168V6.73145C9.81543 7.50293 9.22949 8.16211 8.45312 8.26465V8.29395C9.43457 8.36719 10.1426 9.06055 10.1426 9.9834V9.99316C10.1426 11.2285 9.20996 12 7.70605 12H4.58594ZM7.27148 6.04785H6.06055V7.89844H7.09082C7.9209 7.89844 8.36523 7.55176 8.36523 6.94141V6.93164C8.36523 6.37012 7.95996 6.04785 7.27148 6.04785ZM7.2666 8.89941H6.06055V10.9014H7.33008C8.1748 10.9014 8.63867 10.5547 8.63867 9.90039V9.89062C8.63867 9.24609 8.16992 8.89941 7.2666 8.89941ZM11.1824 12V4.9541H15.8504V6.16992H12.657V7.85938H15.6697V9.01172H12.657V10.7842H15.8504V12H11.1824ZM18.7994 12V6.16992H16.7633V4.9541H22.315V6.16992H20.274V12H18.7994ZM21.9242 12L24.3852 4.9541H26.1186L28.5746 12H27.0268L26.4945 10.291H24.0043L23.4721 12H21.9242ZM25.2348 6.32129L24.3461 9.1875H26.1527L25.2641 6.32129H25.2348Z"
            fill="#8F97A4"
        />
    </svg>
);

export default Beta;
