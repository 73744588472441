import { PodcastImage } from 'components';
import { sortPodcastListItems } from 'helper/PodcastHelper';
import useResize from 'hooks/useResize';
import { PodcastGridOrder } from 'pages/PodcastsPage/model';
import React, { MutableRefObject, useRef } from 'react';
import { useSelector } from 'hooks/react-redux-typed';
import { getFolderColorValues, getPodcastsByUuid } from 'redux/reducers/selectors';
import {
    Content,
    EmptyImage,
    GridCell,
    Label,
    PodcastGrid,
    PodcastImages,
    Wrapper,
} from './FolderImage.styled';

export type Props = {
    alt?: string;
    color: number;
    name: string;
    podcastUuids: Array<string>;
    showName?: boolean;
    size?: number;
    sortType: PodcastGridOrder;
};

const FolderImage = ({
    alt,
    color,
    name,
    podcastUuids,
    showName = true,
    size,
    sortType,
}: Props) => {
    const folderColors = useSelector(getFolderColorValues);

    // Gather podcast data, sort the podcasts for this folder's settings, and create the layout
    const podcasts = useSelector(state => getPodcastsByUuid(state, podcastUuids));
    const sortedPodcasts = sortPodcastListItems(podcasts, sortType)
        .concat([null, null, null, null])
        .slice(0, 4);

    const wrapperRef = useRef() as MutableRefObject<HTMLDivElement>;
    const { width } = useResize(wrapperRef);

    return (
        <Wrapper
            color={folderColors[color]}
            size={size}
            role={alt ? 'img' : 'presentation'}
            aria-label={alt}
            ref={wrapperRef}
        >
            <Content>
                <PodcastImages>
                    <PodcastGrid>
                        {sortedPodcasts.map((podcast, index) =>
                            podcast ? (
                                <GridCell key={podcast.uuid}>
                                    <PodcastImage uuid={podcast.uuid} />
                                </GridCell>
                            ) : (
                                <EmptyImage key={index} />
                            ),
                        )}
                    </PodcastGrid>
                </PodcastImages>
                {showName && (
                    <Label width={width || size || 0}>
                        <span>{name}</span>
                    </Label>
                )}
            </Content>
        </Wrapper>
    );
};

export default FolderImage;
