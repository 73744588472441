import PropTypes from 'prop-types';
import UploadedFileRowActions from './view';

UploadedFileRowActions.propTypes = {
    isInUpNext: PropTypes.bool.isRequired,
    isPlayed: PropTypes.bool.isRequired,
    onPlayNextClick: PropTypes.func.isRequired,
    onPlayLastClick: PropTypes.func.isRequired,
    onRemoveFromUpNextClick: PropTypes.func.isRequired,
    onMarkAsPlayedClick: PropTypes.func.isRequired,
    onMarkAsUnplayedClick: PropTypes.func.isRequired,
    onEditClick: PropTypes.func.isRequired,
    onDownloadFileClick: PropTypes.func.isRequired,
    onDeleteClick: PropTypes.func.isRequired,
};

export default UploadedFileRowActions;
