import { Icon, IconId } from 'components/Icon';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import { SvgImageButtonWrapper } from './SvgImageButton.styled';

type Props = {
    svg: IconId;
    title: string;
    buttonRole?: boolean;
    ariaAttributes: Record<string, unknown>;
    onClick?: () => void;
    onClose?: (e: unknown) => void;
    color?: string;
};

class SvgImageButton extends React.Component<Props> {
    public static defaultProps = {
        buttonRole: true,
    };

    buttonRef: React.RefObject<HTMLInputElement> | undefined;

    constructor(props: Props) {
        super(props);
        this.buttonRef = React.createRef();
    }

    componentDidMount() {
        ReactTooltip.rebuild();
    }

    componentWillUnmount() {
        this.buttonRef = undefined;
        ReactTooltip.hide();
    }

    render() {
        const { svg, title, buttonRole, color } = this.props;

        const ariaAttributes = this.props.ariaAttributes ? this.props.ariaAttributes : {};

        return (
            <SvgImageButtonWrapper
                {...ariaAttributes}
                ref={this.buttonRef}
                data-testid={`img-btn-${svg}`}
                role={buttonRole ? 'button' : undefined}
                tabIndex={buttonRole ? 0 : -1}
                data-tip={title}
                data-effect="solid"
                data-delay-show="100"
                data-place="bottom"
                onClick={this.onOptionClick}
                onKeyPress={buttonRole ? this.onKeyPress : undefined}
                iconColour={color}
            >
                <Icon id={svg} />
            </SvgImageButtonWrapper>
        );
    }

    onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        event.stopPropagation();
        event.preventDefault();
        if (event.key === 'Enter') {
            if (this.props.onClick) {
                this.props.onClick();
            }
            if (this.props.onClose) {
                this.props.onClose(event);
            }
        }
    };

    onOptionClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        if (this.props.onClick) {
            this.props.onClick();
        }
        if (this.props.onClose) {
            this.props.onClose(event);
        }
    };
}

export default SvgImageButton;
