import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon } from 'components/Icon';
import { DropZoneInfoWrapper, ImageWrapper, MainTextWrapper, SubTextWrapper } from './styled';

export const filesDropZoneIdPrefix = 'files-drop-zone';
export class DropZoneInformation extends Component {
    render() {
        return (
            <DropZoneInfoWrapper id={`${filesDropZoneIdPrefix}-info-wrapper`}>
                <ImageWrapper id={`${filesDropZoneIdPrefix}-image`}>
                    <Icon id="upload" size={72} />
                </ImageWrapper>
                <MainTextWrapper id={`${filesDropZoneIdPrefix}-title`}>
                    <FormattedMessage id="drop-files-to-upload" />
                </MainTextWrapper>
                <SubTextWrapper id={`${filesDropZoneIdPrefix}-subtitle`}>
                    <FormattedMessage id="must-be-under" />
                </SubTextWrapper>
            </DropZoneInfoWrapper>
        );
    }
}

export default DropZoneInformation;
