import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ListData } from 'model/types';
import { RootState } from 'redux/reducers';
import useFormatMessage from 'hooks/useFormatMessage';
import Image from 'components/Image';
import { Separator } from '../../Separator';
import ArrowButton from '../../buttons/ArrowButton/ArrowButton';
import { THEME } from '../../../model/theme';
import { getDisplayedTheme } from '../../../redux/reducers/selectors';
import {
    StyledDiscoverCollection,
    CollectionImage,
    PodcastCollectionText,
    DiscoverCollectionContent,
    CollectionSubtitle,
    CollectionTitle,
    CollectionDescription,
    CollageImageContainer,
} from './DiscoverCollection.styled';
import collageFallbackImage from './collage-fallback.png';

type Props = {
    collection: ListData;
    onSeeListClick: () => void;
    previousIsCollection: boolean;
};

function DiscoverCollection({ collection, onSeeListClick, previousIsCollection }: Props) {
    const { title, description, subtitle, colors } = collection;
    const formatMessage = useFormatMessage();

    const accentColor = useSelector((state: RootState) => {
        switch (getDisplayedTheme(state.settings.theme)) {
            case THEME.dark:
                return colors.onDarkBackground;
            default:
            case THEME.light:
                return colors.onLightBackground;
        }
    });

    const CollageImage = useMemo(() => {
        const webImage = collection?.collage_images?.find(x => x.key === 'web');
        if (webImage && webImage.image_url) {
            return <img alt="" data-testid="collage-image" src={webImage.image_url} />;
        }
        return <img alt="" data-testid="collage-image-fallback" src={collageFallbackImage} />;
    }, [collection.collage_images]);
    return (
        <StyledDiscoverCollection previousIsCollection={previousIsCollection}>
            <Separator />
            <DiscoverCollectionContent
                role="button"
                data-testid="discover-collection-content"
                onClick={onSeeListClick}
            >
                {collection.collection_image && (
                    <CollectionImage>
                        <Image
                            borderRadius={66}
                            alt={collection.title}
                            src={collection.collection_image}
                        />
                    </CollectionImage>
                )}

                <PodcastCollectionText>
                    <CollectionSubtitle data-testid="collection-subtitle" accent={accentColor}>
                        <p>{subtitle || ''}</p>
                        <ArrowButton
                            aria-hidden="true"
                            color={accentColor}
                            label={formatMessage('see-list')}
                        />
                    </CollectionSubtitle>
                    <CollectionTitle data-testid="collection-title">{title || ''}</CollectionTitle>
                    <CollectionDescription data-testid="collection-description">
                        {description || ''}
                    </CollectionDescription>
                </PodcastCollectionText>

                <CollageImageContainer>{CollageImage}</CollageImageContainer>
            </DiscoverCollectionContent>
            <Separator />
        </StyledDiscoverCollection>
    );
}

export default DiscoverCollection;
