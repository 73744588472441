import React, { InputHTMLAttributes, MouseEventHandler, useState } from 'react';
import { NumberInput, Wrapper } from './StarRatingInput.styled';

export type Props = {
    value: number;
    onChange: (value: number) => void;
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>;

const MINIMUM_RATING = 1;

const Star = ({ rating, position }: { rating: number; position: number }) => {
    const amount = rating >= position ? 'full' : 'empty';
    return (
        <svg width="48" height="48" viewBox="0 0 48 48">
            <path
                d="M25.2061 6.86553L30.548 17.4448L40.8293 18.4502C41.5318 18.4981 42.0586 19.1189 41.9948 19.8211C41.9628 20.1243 41.8351 20.4115 41.6116 20.619L33.1502 29.0023L36.2793 40.381C36.455 41.0673 36.0399 41.7695 35.3534 41.961C35.0341 42.0408 34.7148 41.9929 34.4274 41.8652L24.004 36.6945L13.5949 41.8333C12.9403 42.1525 12.174 41.8972 11.8388 41.2428C11.6951 40.9556 11.6472 40.6204 11.743 40.3172L14.8721 28.9225L6.38518 20.5264V20.5248C5.8743 20.0158 5.8743 19.2018 6.36921 18.7071C6.57675 18.4837 6.86412 18.356 7.16745 18.3241L17.4488 17.3027L22.7811 6.7219C23.1004 6.06758 23.8826 5.81223 24.5372 6.14737C24.7767 6.25908 24.9682 6.46655 25.096 6.70594L25.2061 6.86553Z"
                fill={amount === 'full' ? 'currentColor' : 'none'}
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                vectorEffect="non-scaling-stroke"
            />
        </svg>
    );
};

const StarRatingInput = ({ value, onChange }: Props) => {
    const [hoverRating, setHoverRating] = useState<number | null>(null);
    const rating = hoverRating !== null ? hoverRating : value;

    const handleMouseMove: MouseEventHandler<HTMLDivElement> = e => {
        if (!e.currentTarget) {
            return;
        }

        const { left, width } = e.currentTarget.getBoundingClientRect();
        const percent = (e.clientX - left) / width;
        const roundedRating = Math.ceil(percent * 5);

        setHoverRating(Math.max(MINIMUM_RATING, roundedRating));
    };

    return (
        <>
            <NumberInput
                type="number"
                step="0.5"
                min={MINIMUM_RATING}
                max="5"
                value={value}
                onChange={e => e.target && onChange(e.target.valueAsNumber)}
            />
            <Wrapper
                onMouseMove={handleMouseMove}
                onMouseLeave={() => setHoverRating(null)}
                onClick={() => hoverRating && onChange(hoverRating)}
            >
                <Star rating={rating} position={1} />
                <Star rating={rating} position={2} />
                <Star rating={rating} position={3} />
                <Star rating={rating} position={4} />
                <Star rating={rating} position={5} />
            </Wrapper>
        </>
    );
};

export default StarRatingInput;
