import styled from 'styled-components';
import { gridSize, userSelectNoneMixin } from '../../../styles/index';
import DropZoneIcon from '../../../styles/borders/drop-zone-border-white.svg';

export const ImageDropZoneWrapper = styled.div`
    display: flex;
    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 560px;
    height: 576px;

    background-color: rgba(3, 169, 244, 0.8); /* blue50 */
    z-index: 2147483647; /* Maximum */

    align-items: center;
    justify-content: center;

    border-radius: 6px;

    ${userSelectNoneMixin};
`;

export const ImageZoneDashedContainer = styled.div`
    display: flex;
    width: calc(100% - ${6 * gridSize}px);
    height: calc(100% - ${6 * gridSize}px);

    align-items: center;
    justify-content: center;

    border: 4px dashed rgba(255, 255, 255, 0.5);
    border-image: url(${DropZoneIcon}) 7 round;
    border-radius: ${gridSize}px;
`;
