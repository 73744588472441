import React from 'react';
import { LinkProps } from 'react-router-dom';
import { SubscribeButton } from '../../buttons/SubscribeButton';
import { WrapperLink, Rank, Text, Title, Subtitle } from './GridItem.styled';

export type Props = {
    image: React.ReactNode;
    title: React.ReactNode;
    subtitle: React.ReactNode;
    to: LinkProps['to'];
    onClick?: () => void;
    rank?: number;
    onSubscribe?: () => void;
    podcastUuid?: string;
};

const GridItem = ({
    image,
    title,
    subtitle,
    to,
    onClick,
    rank,
    onSubscribe,
    podcastUuid,
}: Props) => {
    return (
        <WrapperLink onClick={onClick} to={to}>
            {!!rank && <Rank>{rank}</Rank>}
            {image}
            <Text>
                <Title>{title || ''}</Title>
                <Subtitle>{subtitle || ''}</Subtitle>
                {podcastUuid && (
                    <SubscribeButton
                        circled
                        podcastUuid={podcastUuid}
                        onSubscribe={onSubscribe}
                        eventSource="discover"
                    />
                )}
            </Text>
        </WrapperLink>
    );
};

export default GridItem;
