import { choosePlanPath, registerPath } from 'App';
import { Button } from 'components/Button';
import { ButtonLink } from 'components/ButtonLink';
import { Logo } from 'components/PocketcastsLogo';
import useFormatMessage from 'hooks/useFormatMessage';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { USE_PATRON } from 'settings';

import { FeaturesListContainer, FeaturesListItem, Modal } from './LoginPage.styled';
import LockedImage from './locked.svg';
import UpgradeImage from './upgrade.svg';
import UploadFilesImage from './uploaded-files.svg';

type Props = {
    onClose: () => void;
} & RouteComponentProps;

export function TrialFinishedModal({ onClose }: Props) {
    const formatMessage = useFormatMessage();

    return (
        <Modal
            data-testid="trialFinishedModal"
            horizontalInsetPx={42}
            onClose={onClose}
            useModernStyle
        >
            <Logo size="small" />

            <h1>{formatMessage('modal-trial-finished')}</h1>
            <p>{formatMessage('modal-trial-downgraded')}</p>

            <FeaturesListContainer>
                <FeaturesListItem>
                    <img src={LockedImage} alt="" />
                    {formatMessage('modal-trial-webplayer-locked')}
                </FeaturesListItem>
                <FeaturesListItem>
                    <img src={UploadFilesImage} alt="" />
                    {formatMessage('modal-trial-files-removal')}
                </FeaturesListItem>
                <FeaturesListItem>
                    <img src={UpgradeImage} alt="" />
                    {formatMessage('modal-trial-upgrade-account')}
                </FeaturesListItem>
            </FeaturesListContainer>
            <ButtonLink to={USE_PATRON ? choosePlanPath : registerPath} kind="plus">
                {formatMessage('modal-upgrade-to-pocket-casts-plus')}
            </ButtonLink>
            <Button onClick={onClose} kind="secondary">
                {formatMessage('done')}
            </Button>
        </Modal>
    );
}

export default withRouter(TrialFinishedModal);
