import React from 'react';
import { PageTitle } from './PageHeader.styled';

type Props = {
    title: string;
};

// This is a super simple component, but chances are good that it will expand in the future. So for now,
// we'll keep it as a full component to avoid making big changes through the codebase.
const PageHeader = ({ title }: Props) => <PageTitle>{title}</PageTitle>;

export default PageHeader;
