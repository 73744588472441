import styled from 'styled-components';
import { Table } from 'react-virtualized';
import {
    Bonus,
    EpisodeTypeText,
    SeasonTrailer,
    Trailer,
} from 'components/format/EpisodeTypeText/EpisodeTypeText.styled';
import { Typography, gridSize } from 'styles';

export const EpisodesTableStyled = styled(Table)`
    padding-bottom: 50px;
    .no-episodes {
        text-align: center;
        padding-top: 20px;
    }
    .row {
        ${Typography.Desktop.H70}
        color: ${props => props.theme.legacyScss['theme-text-secondary']};
        border-bottom: 1px solid ${props => props.theme.legacyScss['theme-line']};
        cursor: default;
        &.clickable {
            cursor: pointer;
        }
        &.played,
        &.archived {
            &,
            .text-primary {
                color: ${props => props.theme.legacyScss['theme-text-disabled']};
            }
        }
        &.hover {
            .hide-on-hover {
                visibility: hidden;
            }
            .show-on-hover {
                visibility: visible;
            }
            .star-button {
                display: block;
            }
        }
        &:focus-within {
            .show-on-hover {
                visibility: visible;
            }
            .hide-on-hover {
                visibility: hidden;
            }
        }
    }

    .actions {
        flex-basis: auto !important;
        display: flex;
        align-items: center;
        & > * {
            margin-left: ${gridSize * 1.5}px;
        }
    }
    .archived-status {
        ${Typography.Desktop.H80}
        display:flex;
        align-items: center;
        visibility: hidden;
        padding-top: 3px;

        svg {
            margin: -0.2em 0.5em 0 0;
            opacity: 0.6;
        }

        @media only screen and (max-width: 1030px) {
            svg {
                display: none;
            }
        }

        color: ${props => props.theme.legacyScss['theme-interactive-secondary']};
    }
    .archived .archived-status {
        visibility: inherit;
    }

    .episode-table-cell {
        padding-right: 10px;
        display: flex;
        .episode-table-image {
            width: 52px;
            flex-shrink: 0;
        }
        .text {
            display: flex;
            min-width: 0;
            flex-direction: column;
            justify-content: center;
            padding-left: ${gridSize * 1.5}px;

            .secondary-line {
                padding-top: 1px;
                display: flex;
                flex-direction: row;
                align-items: center;
            }
            .text-secondary {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
        &.no-podcast {
            .text {
                padding-left: 0;
            }
        }
        .star-button {
            display: none;
            margin: -3px 0 0 5px;
            &.on {
                display: block;
            }
        }
    }

    .text-primary {
        display: flex;
        align-items: center;
        color: ${props => props.theme.legacyScss['theme-text-primary']};
        white-space: nowrap;
        overflow: hidden;
        ${EpisodeTypeText} {
            ${Typography.Desktop.H65}
            padding-right: 8px;
            color: ${props => props.theme.legacyScss['theme-text-secondary']};
        }
        ${Bonus}, ${Trailer}, ${SeasonTrailer} {
            ${Typography.Desktop['H80-C']}
            text-transform: uppercase;
            padding: 1px 8px 0 0;
            color: ${props => props.color};
        }
        .title {
            ${Typography.Desktop.H65}
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .up-next-icon {
        margin: -0.15em 8px 0 0;
        color: ${props => props.theme.tokens['primary-icon-01']};
    }
`;
