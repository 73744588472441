import {
    EpisodeYShortMessage,
    SeasonTrailerMessage,
    SeasonXEpisodeYShortMessage,
    SeasonXShortMessage,
} from 'components/messages';
import { EpisodeType } from 'model/types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Bonus, Trailer, SeasonTrailer, Season } from './EpisodeTypeText.styled';

export type Props = {
    season?: number;
    number?: number;
    type?: EpisodeType;
};

const EpisodeTypeText = ({ season, number, type }: Props) => {
    if (type === EpisodeType.BONUS) {
        return (
            <Bonus>
                <FormattedMessage id="bonus" />
            </Bonus>
        );
    }

    if (type === EpisodeType.TRAILER) {
        if (season && season > 0) {
            return (
                <SeasonTrailer>
                    <SeasonTrailerMessage season={season} />
                </SeasonTrailer>
            );
        }

        return (
            <Trailer>
                <FormattedMessage id="trailer" />
            </Trailer>
        );
    }

    if (season && number) {
        return (
            <Season>
                <SeasonXEpisodeYShortMessage season={season} episode={number} />
            </Season>
        );
    }

    if (season) {
        return (
            <Season>
                <SeasonXShortMessage season={season} />
            </Season>
        );
    }

    if (number) {
        return (
            <Season>
                <EpisodeYShortMessage episode={number} />
            </Season>
        );
    }

    return null;
};

export default EpisodeTypeText;
