import formatCurrency from '@automattic/format-currency';
import { LoaderSquare } from 'components/LoaderSquare';
import { PlanBadge } from 'components/PlanBadge';
import { getLocalizedSubscriptionPrice } from 'helper/PaddleCheckoutHelper';
import { isInfoOfferAvailableForCountry } from 'helper/PlansHelper';
import useFormatMessage from 'hooks/useFormatMessage';
import { useGeolocation } from 'hooks/useGeolocation';
import { PaddlePlan, PaddleProduct } from 'model/types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { USE_50_PERCENT_BY_DEFAULT } from 'settings';
import { PlanFeatures, PlanPrice } from './PlanSelector';
import {
    FreeTrialBadge,
    Interval,
    IntervalSelector,
    PlanSelectorItemsRow,
    PriceRowContainer,
    SplitPlanSelectorFooter,
    SplitPlanWrapper,
    SplitPlans,
    SubscribeButton,
    Wrapper,
} from './PlanSelector.styled';
import { PLAN_VARIABLES } from './plans';

const PlanPanel = ({
    plan,
    product,
    onSubscribe,
}: {
    plan: PaddlePlan;
    product: PaddleProduct;
    onSubscribe: (plan: PaddlePlan) => void;
}) => {
    const { tier, perIntervalText } = PLAN_VARIABLES[plan];
    const formatMessage = useFormatMessage();
    const { countryCode, hasFetchedLocation } = useGeolocation();

    if (!hasFetchedLocation) {
        return <LoaderSquare />;
    }

    const offerHalfOff =
        plan === 'plus-yearly' &&
        USE_50_PERCENT_BY_DEFAULT &&
        isInfoOfferAvailableForCountry(countryCode);
    const priceString = getLocalizedSubscriptionPrice(product);
    const halfOffPriceString = product
        ? formatCurrency(product.subscription.price.gross / 2, product.currency)
        : '';

    return (
        <Wrapper isPatron={tier === 'Patron'}>
            <PlanSelectorItemsRow>
                <PlanBadge tier={tier} />
                {offerHalfOff && (
                    <FreeTrialBadge>{formatMessage('switch-to-discount-text')}</FreeTrialBadge>
                )}
            </PlanSelectorItemsRow>

            {offerHalfOff ? (
                <PriceRowContainer>
                    <PlanPrice
                        price={halfOffPriceString}
                        interval={perIntervalText}
                        fullPrice={priceString}
                    />
                </PriceRowContainer>
            ) : (
                <PlanPrice price={priceString} interval={perIntervalText} />
            )}

            <PlanFeatures tier={tier} plan={plan} />
            <SplitPlanSelectorFooter>
                <SubscribeButton onClick={() => onSubscribe(plan)}>
                    {formatMessage('subscribe-to-tier', { tier })}
                </SubscribeButton>
            </SplitPlanSelectorFooter>
        </Wrapper>
    );
};

export type Props = {
    orientation: 'vertical' | 'horizontal';
    products: Partial<Record<PaddlePlan, PaddleProduct>>;
    onSubscribe: (plan: PaddlePlan) => void;
};

const SplitPlanSelector = ({ orientation = 'horizontal', products, onSubscribe }: Props) => {
    const [planInterval, setPlanInterval] = useState<'monthly' | 'yearly'>('yearly');
    const plusPlan = planInterval === 'monthly' ? 'plus-monthly' : 'plus-yearly';
    const patronPlan = planInterval === 'monthly' ? 'patron-monthly' : 'patron-yearly';

    const plusProduct = products[plusPlan];
    const patronProduct = products[patronPlan];

    return (
        <SplitPlanWrapper>
            <IntervalSelector>
                <label>
                    <input
                        type="radio"
                        name="split-plan-selector--interval"
                        value="yearly"
                        checked={planInterval === 'yearly'}
                        onChange={() => setPlanInterval('yearly')}
                    />
                    <Interval>
                        <FormattedMessage id="yearly" />
                    </Interval>
                </label>
                <label>
                    <input
                        type="radio"
                        name="split-plan-selector--interval"
                        value="monthly"
                        checked={planInterval === 'monthly'}
                        onChange={() => setPlanInterval('monthly')}
                    />
                    <Interval>
                        <FormattedMessage id="monthly" />
                    </Interval>
                </label>
            </IntervalSelector>

            {plusProduct && patronProduct && (
                <SplitPlans orientation={orientation}>
                    <PlanPanel plan={plusPlan} product={plusProduct} onSubscribe={onSubscribe} />
                    <PlanPanel
                        plan={patronPlan}
                        product={patronProduct}
                        onSubscribe={onSubscribe}
                    />
                </SplitPlans>
            )}
        </SplitPlanWrapper>
    );
};

export default SplitPlanSelector;
