import styled from 'styled-components';
import { gridSize, H40, H70, red } from '../../../../styles/index';

export const Heading = styled(H40)`
    color: ${props => props.theme.tokens['primary-text-01']};
    padding-bottom: ${2 * gridSize}px;
`;

export const CredentialWrapper = styled.div`
    padding-bottom: ${2 * gridSize}px;
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    width: ${58 * gridSize}px;

    align-items: center;
    justify-content: flex-end;
    padding-bottom: ${4 * gridSize}px;
`;

export const VerticalSpace = styled.div`
    width: ${2 * gridSize}px;
`;

export const ResponseInformationWrapper = styled(H70)`
    color: ${red};
`;

export const Separator = styled.div`
    width: 100%;
    border-bottom: 1px solid ${props => props.theme.legacyScss['theme-line']};
    margin-top: ${2 * gridSize}px;
    margin-bottom: ${3 * gridSize}px;
`;
