import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon } from 'components/Icon';
import { imageDropZoneIdPrefix } from '../model';
import { ImageDropZoneInfoWrapper, ImageWrapper, MainTextWrapper, SubTextWrapper } from './styled';

export class DropZoneInformation extends Component {
    render() {
        return (
            <ImageDropZoneInfoWrapper id={`${imageDropZoneIdPrefix}-info-wrapper`}>
                <ImageWrapper id={`${imageDropZoneIdPrefix}-image`}>
                    <Icon id="upload" size={72} />
                </ImageWrapper>
                <MainTextWrapper id={`${imageDropZoneIdPrefix}-title`}>
                    <FormattedMessage id="drop-image-to-upload" />
                </MainTextWrapper>
                <SubTextWrapper id={`${imageDropZoneIdPrefix}-subtitle`}>
                    <FormattedMessage id="replace-image" />
                </SubTextWrapper>
            </ImageDropZoneInfoWrapper>
        );
    }
}

export default DropZoneInformation;
