import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import useFormatMessage from 'hooks/useFormatMessage';
import { hasFreeSubscription, isGiftSubscription } from 'redux/reducers/selectors';
import * as fromUserActions from 'redux/actions/user.actions';
import { BackButton } from 'components/BackButton';
import { CredentialNotification } from 'components/form/CredentialNotification';
import { LoginRegisterForm } from 'components/form/LoginRegisterForm';
import { TrackOnMount, TrackOnUnmount } from 'components/Tracks';
import FreeAccountModal from './FreeAccountModal';
import TrialFinishedModal from './TrialFinishedModal';
import { Wrapper } from './LoginPage.styled';

const LoginPage = () => {
    const location = useLocation<{ loginSuccessMessage: string }>();
    const isFreeUser = useSelector(hasFreeSubscription);
    const isGift = useSelector(isGiftSubscription);

    const dispatch = useDispatch();
    const formatMessage = useFormatMessage();

    // Clean up errors on unmount
    useEffect(
        () => () => {
            dispatch(fromUserActions.Actions.clearUserError());
        },
        [dispatch],
    );

    const onSubscribeModalClose = () => dispatch(fromUserActions.Actions.signInTryAgain());

    const showResetPasswordSuccess =
        location && location.state && location.state.loginSuccessMessage;

    return (
        <Wrapper>
            <TrackOnMount event="signin_shown" />
            <TrackOnUnmount event="signin_dismissed" />
            <Helmet>
                <title>{formatMessage('log-in')}</title>
            </Helmet>

            <BackButton buttonText={formatMessage('back')} />

            {showResetPasswordSuccess && (
                <CredentialNotification variant="success">
                    {location.state.loginSuccessMessage}
                </CredentialNotification>
            )}

            <LoginRegisterForm
                title={formatMessage('log-in-to-pocket-casts')}
                submitButtonText={formatMessage('log-in')}
            />

            {isFreeUser &&
                (isGift ? (
                    <TrialFinishedModal onClose={onSubscribeModalClose} />
                ) : (
                    <FreeAccountModal onClose={onSubscribeModalClose} />
                ))}
        </Wrapper>
    );
};

export default LoginPage;
