/**
 * This component is similar to <DurationText> with two big differences:
 *
 * - <StatsTimeText> just takes one prop - the time in seconds - and doesn't try to calculate played time or remaining time
 * - <DurationText> rounds off times to fit in smaller spaces (e.g. after 1m doesn't show seconds) while <StatsTimeText> is
 *   more precise, always showing the two highest levels of precision (min+sec, hr+min, day+hr)
 */
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const StatsSecondMessage = () => <FormattedMessage id="duration-one-second" />;

export const StatsSecondsMessage = ({ seconds }: { seconds: number }) => (
    <FormattedMessage
        id="duration-n-seconds"
        values={{
            number: seconds,
        }}
    />
);

export const StatsMinuteMessage = () => <FormattedMessage id="minute" />;

export const StatsMinuteSecondMessage = () => (
    <FormattedMessage id="duration-one-minute-one-second" />
);

export const StatsMinuteSecondsMessage = ({ seconds }: { seconds: number }) => (
    <FormattedMessage
        id="duration-one-minute-n-seconds"
        values={{
            number: seconds,
        }}
    />
);

export const StatsMinutesMessage = ({ minutes }: { minutes: number }) => (
    <FormattedMessage
        id="duration-n-minutes"
        values={{
            number: minutes,
        }}
    />
);

export const StatsMinutesSecondMessage = ({ minutes }: { minutes: number }) => (
    <FormattedMessage
        id="duration-n-minutes-one-second"
        values={{
            number: minutes,
        }}
    />
);

export const StatsMinutesSecondsMessage = ({
    minutes,
    seconds,
}: {
    seconds: number;
    minutes: number;
}) => (
    <FormattedMessage
        id="duration-n-minutes-m-seconds"
        values={{
            numMinutes: minutes,
            numSeconds: seconds,
        }}
    />
);

export const StatsHourMessage = () => <FormattedMessage id="duration-one-hour" />;

export const StatsHourMinuteMessage = () => <FormattedMessage id="duration-one-hour-one-minute" />;

export const StatsHourMinutesMessage = ({ minutes }: { minutes: number }) => (
    <FormattedMessage
        id="duration-one-hour-n-minutes"
        values={{
            number: minutes,
        }}
    />
);

export const StatsHoursMessage = ({ hours }: { hours: number }) => (
    <FormattedMessage
        id="duration-n-hours"
        values={{
            number: hours,
        }}
    />
);

export const StatsHoursMinuteMessage = ({ hours }: { hours: number }) => (
    <FormattedMessage
        id="duration-n-hours-one-minute"
        values={{
            number: hours,
        }}
    />
);

export const StatsHoursMinutesMessage = ({
    hours,
    minutes,
}: {
    hours: number;
    minutes: number;
}) => (
    <FormattedMessage
        id="duration-n-hours-m-minutes"
        values={{
            numHours: hours,
            numMinutes: minutes,
        }}
    />
);

export const StatsDayMessage = () => <FormattedMessage id="duration-one-day" />;

export const StatsDayHourMessage = () => <FormattedMessage id="duration-one-day-one-hour" />;

export const StatsDayHoursMessage = ({ hours }: { hours: number }) => (
    <FormattedMessage
        id="duration-one-day-n-hours"
        values={{
            number: hours,
        }}
    />
);

export const StatsDaysMessage = ({ days }: { days: number }) => (
    <FormattedMessage
        id="duration-n-days"
        values={{
            number: days,
        }}
    />
);

export const StatsDaysHourMessage = ({ days }: { days: number }) => (
    <FormattedMessage
        id="duration-n-days-one-hour"
        values={{
            number: days,
        }}
    />
);

export const StatsDaysHoursMessage = ({ days, hours }: { days: number; hours: number }) => (
    <FormattedMessage
        id="duration-n-days-m-hours"
        values={{
            numDays: days,
            numHours: hours,
        }}
    />
);

/*
 * There are:
 * 60 seconds in a minute (120 in 2 minutes)
 * 3,600 seconds in an hour (7,200 in 2 hours)
 * 86,400 seconds in a day (172,800 in 2 days)
 */
const SECONDS_PER_MINUTE = 60;
const SECONDS_PER_HOUR = 3600;
const SECONDS_PER_DAY = 86400;

export type Props = {
    timeSecs: number;
};

const StatsTimeText = ({ timeSecs }: Props) => {
    if (timeSecs === 1) {
        return <StatsSecondMessage />;
    }

    if (timeSecs < SECONDS_PER_MINUTE) {
        return <StatsSecondsMessage seconds={timeSecs} />;
    }

    if (timeSecs === SECONDS_PER_MINUTE) {
        return <StatsMinuteMessage />;
    }

    if (timeSecs === SECONDS_PER_MINUTE + 1) {
        return <StatsMinuteSecondMessage />;
    }

    if (timeSecs < 2 * SECONDS_PER_MINUTE) {
        return <StatsMinuteSecondsMessage seconds={timeSecs - SECONDS_PER_MINUTE} />;
    }

    const secondsOverMinute = timeSecs % SECONDS_PER_MINUTE;
    const minutesWithSeconds = Math.floor(timeSecs / SECONDS_PER_MINUTE);

    if (timeSecs < SECONDS_PER_HOUR && secondsOverMinute === 0) {
        return <StatsMinutesMessage minutes={minutesWithSeconds} />;
    }

    if (timeSecs < SECONDS_PER_HOUR && secondsOverMinute === 1) {
        return <StatsMinutesSecondMessage minutes={minutesWithSeconds} />;
    }

    if (timeSecs < SECONDS_PER_HOUR && secondsOverMinute > 1) {
        return (
            <StatsMinutesSecondsMessage minutes={minutesWithSeconds} seconds={secondsOverMinute} />
        );
    }

    if (timeSecs < SECONDS_PER_HOUR + SECONDS_PER_MINUTE) {
        return <StatsHourMessage />;
    }

    if (timeSecs < SECONDS_PER_HOUR + 2 * SECONDS_PER_MINUTE) {
        return <StatsHourMinuteMessage />;
    }

    if (timeSecs < 2 * SECONDS_PER_HOUR) {
        return (
            <StatsHourMinutesMessage
                minutes={Math.floor((timeSecs - SECONDS_PER_HOUR) / SECONDS_PER_MINUTE)}
            />
        );
    }

    const minutesOverHour = Math.floor((timeSecs % SECONDS_PER_HOUR) / SECONDS_PER_MINUTE);
    const hoursWithMinutes = Math.floor(timeSecs / SECONDS_PER_HOUR);

    if (timeSecs < SECONDS_PER_DAY && minutesOverHour === 0) {
        return <StatsHoursMessage hours={hoursWithMinutes} />;
    }

    if (timeSecs < SECONDS_PER_DAY && minutesOverHour === 1) {
        return <StatsHoursMinuteMessage hours={hoursWithMinutes} />;
    }

    if (timeSecs < SECONDS_PER_DAY && minutesOverHour > 1) {
        return <StatsHoursMinutesMessage hours={hoursWithMinutes} minutes={minutesOverHour} />;
    }

    if (timeSecs < SECONDS_PER_DAY + SECONDS_PER_HOUR) {
        return <StatsDayMessage />;
    }

    if (timeSecs < SECONDS_PER_DAY + 2 * SECONDS_PER_HOUR) {
        return <StatsDayHourMessage />;
    }

    if (timeSecs < 2 * SECONDS_PER_DAY) {
        return (
            <StatsDayHoursMessage
                hours={Math.floor((timeSecs - SECONDS_PER_DAY) / SECONDS_PER_HOUR)}
            />
        );
    }

    // Two days or more

    const hoursOverDay = Math.floor((timeSecs % SECONDS_PER_DAY) / SECONDS_PER_HOUR);
    const daysWithHours = Math.floor(timeSecs / SECONDS_PER_DAY);

    if (hoursOverDay === 0) {
        return <StatsDaysMessage days={daysWithHours} />;
    }

    if (hoursOverDay === 1) {
        return <StatsDaysHourMessage days={daysWithHours} />;
    }

    if (hoursOverDay > 1) {
        return <StatsDaysHoursMessage days={daysWithHours} hours={hoursOverDay} />;
    }

    // Should be unreachable

    return <StatsSecondsMessage seconds={timeSecs} />;
};

export default StatsTimeText;
