window._tkq = window._tkq || [];

export const initialize = () => {
    const script = document.createElement('script');
    script.src = '//stats.wp.com/w.js';
    document.head.appendChild(script);
};

export const recordRawEvent = (name: string, properties = {}) => {
    window._tkq.push(['recordEvent', name, properties]);
};
