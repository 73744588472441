import styled from 'styled-components';
import { gridSize } from '../../../../../styles';

/*
 * This div must not have an explicit `position` value. Otherwise, the tooltips that need
 * to overflow its bounds (despite the overflow-x/y values given) will not overflow correctly.
 */
export const UploadManagerDetailWrapper = styled.div`
    height: ${props => (props.collapsed ? '0' : '248px')};

    transition: height 500ms ease-in-out;
    overflow-y: auto;
    overflow-x: hidden;
`;

export const DetailShadowWrapper = styled.div`
    position: sticky;
    width: 100%;
    top: 0;
    left: 0;
`;

export const DetailShadow = styled.div`
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;

    background: linear-gradient(
        ${props => props.theme.legacyScss['theme-line']}50,
        ${props => props.theme.legacyScss['theme-line']}00
    );
    height: ${gridSize}px;
`;
