import { ReactNode } from 'react';
import styled from 'styled-components';
import Shiitake from 'vendor/shiitake';
import { Typography } from '../../styles';

export const TextWithReadMoreContainer = styled.div`
    ${Typography.Desktop['H50-B']};
    color: ${props => props.theme.legacyScss['theme-text-primary']};
`;

export const TextContentWrapper = styled.div`
    overflow: hidden;
`;

export const TextContent = styled(Shiitake)<{ children: ReactNode }>`
    font-size: 14.4px;
    font-weight: 400;
    line-height: 20px;
`;

export const MoreButton = styled.button`
    &:hover {
        span {
            opacity: 0.7;
        }
    }
    span {
        color: ${({ color }) => color};

        cursor: pointer;
        font-weight: 500;
        padding-left: 5px;
    }
`;
