import { PlayPauseButton as PlayPauseButtonComponent } from 'components/PlayPauseButton';
import PodcastImageComponent from 'components/PodcastImage';
import {
    Row as RowComponent,
    RowContent as RowContentComponent,
    RowImage as RowImageComponent,
    RowTitle as RowTitleComponent,
} from 'components/Row';
import styled from 'styled-components';
import { Typography, gridSize, popoverMixin } from 'styles';

export const Wrapper = styled.div``;

export const BookmarkDetails = styled.small`
    ${Typography.Desktop['H80-B']}
    color: ${props => props.theme.tokens['primary-text-02']};
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 120%;
`;

export const BookmarkTitle = styled.div`
    ${Typography.Desktop['H65-B']}
    color: ${props => props.theme.tokens['primary-text-01']};
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 120%;
`;

export const PlayPauseButton = styled(PlayPauseButtonComponent)`
    background: ${props => props.theme.tokens['primary-text-01']};
    color: ${props => props.theme.tokens['primary-ui-01']};
    margin: 0 ${gridSize}px 0 ${2 * gridSize}px;
`;

export const PodcastImage = styled(PodcastImageComponent)`
    width: 62px;
    margin-right: 16px;
`;

export const FileImage = styled.img`
    width: 62px;
    margin-right: 16px;
`;

export const PopupForm = styled.div`
    ${popoverMixin}
    width: 400px;
    background: ${props => props.theme.tokens['primary-ui-01']};
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: ${5 * gridSize}px;
`;

export const PopupCloseButton = styled.button`
    position: absolute;
    top: 0;
    right: 0;
    padding: ${gridSize}px;
    color: ${props => props.theme.tokens['primary-icon-02']};
    &:hover {
        opacity: 0.8;
    }
`;

export const EmptyMessage = styled.section`
    flex: 1;
    margin: ${2 * gridSize}px 0 ${4 * gridSize}px;
    border-radius: 4px;
    background: ${props => props.theme.tokens['primary-icon-02-disabled']};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: ${4 * gridSize}px;
    text-align: center;
    line-height: 144%;
`;

export const RowContent = styled(RowContentComponent)`
    border-top: none;
`;

export const Row = styled(RowComponent)`
    padding-right: 0;
    padding-left: 0px;
    margin-left: 0px;
    padding: 4px;
`;

export const RowTitle = styled(RowTitleComponent)`
    margin: 0;
`;

export const RowImage = styled(RowImageComponent)`
    width: 62px;
    margin-right: 16px;
    margin-top: 16px;
`;
