import { createAction, ActionsUnion } from './action-creators';

export enum ActionTypes {
    ADD_TIME_SAVED_SKIPPING = '@stats/ADD_TIME_SAVED_SKIPPING',
    ADD_TIME_SAVED_AUTO_SKIPPING = '@stats/ADD_TIME_SAVED_AUTO_SKIPPING',
    ADD_TIME_SAVED_VARIABLE_SPEED = '@stats/ADD_TIME_SAVED_VARIABLE_SPEED',
    ADD_TOTAL_LISTENING_TIME = '@stats/ADD_TOTAL_LISTENING_TIME',
    UPDATE_STATS = '@stats/UPDATE_STATS',
    UPLOAD_STATS = '@stats/UPLOAD_STATS',
}

export const Actions = {
    addTimeSavedSkipping: (timeMs: number) =>
        createAction(ActionTypes.ADD_TIME_SAVED_SKIPPING, {
            timeMs,
        }),

    addTimeSavedAutoSkipping: (timeMs: number) =>
        createAction(ActionTypes.ADD_TIME_SAVED_AUTO_SKIPPING, {
            timeMs,
        }),

    addTimeSavedVariableSpeed: (timeMs: number) =>
        createAction(ActionTypes.ADD_TIME_SAVED_VARIABLE_SPEED, {
            timeMs,
        }),

    addTotalListeningTime: (timeMs: number) =>
        createAction(ActionTypes.ADD_TOTAL_LISTENING_TIME, {
            timeMs,
        }),

    updateStats: ({
        totalListeningMs,
        skippingStatMs,
        skippingIntroMs,
        variableSpeedMs,
    }: {
        totalListeningMs: number;
        skippingStatMs: number;
        skippingIntroMs: number;
        variableSpeedMs: number;
    }) =>
        createAction(ActionTypes.UPDATE_STATS, {
            totalListeningMs,
            skippingStatMs,
            skippingIntroMs,
            variableSpeedMs,
        }),

    uploadStats: () => createAction(ActionTypes.UPLOAD_STATS),
};

export type Actions = ActionsUnion<typeof Actions>;
