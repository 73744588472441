import { PocketCastsLocationState } from 'customHistory';
import { useLocation } from 'react-router';

const useNavigation = () => {
    const location = useLocation<PocketCastsLocationState>();
    return {
        canGoBack: location.state?.canGoBack ?? false,
        canGoForward: location.state?.canGoForward ?? false,
    };
};

export default useNavigation;
