import { TracksEventSource } from 'model/types';
import { ActionsUnion, createAction } from './action-creators';

export enum ActionTypes {
    OPEN_EPISODE_SHARE = '@share/OPEN_EPISODE_SHARE',
    OPEN_PODCAST_SHARE = '@share/OPEN_PODCAST_SHARE',

    OPEN_SHARE_SUCCESS = '@share/OPEN_SHARE_SUCCESS',
    OPEN_SHARE_FAILED = '@share/OPEN_SHARE_FAILED',
    CLOSE_SHARE = '@share/CLOSE_SHARE',
}

export const Actions = {
    openEpisodeShare: (
        episodeUuid: string,
        podcastUuid: string,
        episodeTitle: string,
        duration: number,
        audioUrl: string,
        options: { eventSource: TracksEventSource; time?: number },
    ) =>
        createAction(ActionTypes.OPEN_EPISODE_SHARE, {
            episodeUuid,
            podcastUuid,
            episodeTitle,
            duration,
            audioUrl,
            options,
        }),

    openPodcastShare: (podcastUuid: string, tracksProperties: { eventSource: TracksEventSource }) =>
        createAction(ActionTypes.OPEN_PODCAST_SHARE, { podcastUuid, tracksProperties }),

    openShareSuccess: (url: string, time?: number) =>
        createAction(ActionTypes.OPEN_SHARE_SUCCESS, { url, time }),

    openShareFailed: () => createAction(ActionTypes.OPEN_SHARE_FAILED),

    closeShare: () => createAction(ActionTypes.CLOSE_SHARE),
};

export type Actions = ActionsUnion<typeof Actions>;
