import React from 'react';
import { useTheme } from 'styled-components';
import LogoVertical from './logo-vertical.png';
import { LogoWrapper } from './PocketcastsLogo.styled';

export const SIZE_SMALL = 'small';
export const SIZE_MEDIUM = 'medium';
export const SIZE_LARGE = 'large';

type Props = {
    size?: 'small' | 'medium' | 'large';
    vertical?: boolean;
};

function Logo({ size, vertical }: Props) {
    const height = vertical ? 94 : size === SIZE_SMALL ? 27 : size === SIZE_LARGE ? 54 : 46;
    const theme = useTheme();
    return (
        <LogoWrapper height={height}>
            {vertical && <img height={height} src={LogoVertical} alt="Pocket Casts Logo" />}

            {!vertical && (
                <svg
                    role="img"
                    width="393"
                    height="54"
                    viewBox="0 0 393 54"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <title>Pocket Casts Plus Logo</title>
                    <path
                        d="M392.14 7H286.09L276 47.46H382.05L392.14 7Z"
                        fill="url(#paint0_linear)"
                    />
                    <path
                        d="M304.95 15.82H299.76V38.64H304.95V15.82Z"
                        fill={theme.tokens['contrast-01']}
                    />
                    <path
                        d="M321.54 38.6399V15.8199H327.73C333.3 15.8199 336.3 18.4599 336.3 23.7399C336.3 29.6399 332.75 32.0799 327.86 32.0799H326.59V38.6299H321.54V38.6399ZM327.67 27.9799C330.08 27.9799 331.29 26.6799 331.29 23.9099C331.29 21.0799 330.08 19.9999 327.74 19.9999H326.57V27.9899H327.67V27.9799Z"
                        fill={theme.tokens['contrast-01']}
                    />
                    <path
                        d="M338.62 38.64V15.14H343.31V38.64H338.62Z"
                        fill={theme.tokens['contrast-01']}
                    />
                    <path
                        d="M350.84 38.8599C347.97 38.8599 346.38 37.0999 346.38 33.9099V21.7499H351.11V32.7699C351.11 34.0699 351.66 34.7599 352.84 34.7599C353.59 34.7599 354.34 34.3999 354.93 33.9499V21.7499H359.66V38.6299H355.88L355.23 37.0999C354 38.0799 352.67 38.8599 350.84 38.8599Z"
                        fill={theme.tokens['contrast-01']}
                    />
                    <path
                        d="M368.18 38.93C365.9 38.93 363.78 38.34 361.96 37.37L362.91 33.56C364.7 34.54 366.49 35.12 368.12 35.12C369.33 35.12 369.98 34.6 369.98 33.88C369.98 33.16 369.59 32.84 367.31 31.92C364.15 30.65 362.58 29.44 362.58 26.61C362.58 23.48 364.73 21.46 368.51 21.46C370.43 21.46 372.19 21.92 373.79 22.7L372.84 26.51C371.37 25.76 369.87 25.27 368.51 25.27C367.5 25.27 366.95 25.69 366.95 26.38C366.95 27.03 367.31 27.36 369.56 28.27C373.11 29.67 374.35 30.98 374.35 33.65C374.34 37.17 371.86 38.93 368.18 38.93Z"
                        fill={theme.tokens['contrast-01']}
                    />
                    <path
                        d="M313.12 29.82L314.41 24.64H291.6L290.3 29.82H313.12Z"
                        fill={theme.tokens['contrast-01']}
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M79.2402 28.3032C82.3482 28.3032 83.9022 26.6232 83.9022 23.0522C83.9022 19.3972 82.3482 18.0112 79.3242 18.0112H77.8112V28.3032H79.2402ZM71.3432 42.0392V12.6342H79.3242C86.5072 12.6342 90.3722 16.0372 90.3722 22.8422C90.3722 30.4452 85.7932 33.5962 79.4922 33.5962H77.8532V42.0392H71.3432Z"
                        fill={theme.tokens['primary-text-01']}
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M99.8623 24.9844C97.9713 24.9844 96.7963 27.0014 96.7963 31.1174C96.7963 35.2764 98.0133 37.3344 99.9463 37.3344C101.836 37.3344 103.054 35.2764 103.054 31.1594C103.054 27.0014 101.794 24.9844 99.8623 24.9844ZM99.9043 42.4594C94.9473 42.4594 90.9983 38.6364 90.9983 31.2014C90.9983 23.6824 94.9893 19.8174 99.9463 19.8174C104.861 19.8174 108.851 23.6824 108.851 31.1174C108.851 38.5944 104.861 42.4594 99.9043 42.4594Z"
                        fill={theme.tokens['primary-text-01']}
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M119.56 42.3755C113.721 42.3755 110.276 38.3005 110.276 31.2015C110.276 24.2705 113.805 19.9015 119.896 19.9015C121.829 19.9015 123.298 20.2795 124.727 20.8255L123.802 26.1185C122.794 25.6565 121.619 25.2785 120.4 25.2785C117.585 25.2785 116.116 27.7145 116.116 31.1175C116.116 34.8145 117.585 36.9985 120.484 36.9985C121.577 36.9985 122.71 36.6625 123.928 36.2005L124.853 41.2835C123.382 41.9135 121.451 42.3755 119.56 42.3755Z"
                        fill={theme.tokens['primary-text-01']}
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M133.124 31.6216V42.0396H127.033V11.7516H133.124V29.6896L138.081 20.2796H144.76L138.837 30.6976L144.928 42.0396H138.165L133.124 31.6216Z"
                        fill={theme.tokens['primary-text-01']}
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M155.721 29.6895C155.595 26.4125 154.628 24.4805 152.781 24.4805C150.89 24.4805 149.924 26.4965 149.797 29.6895H155.721ZM144.126 31.2435C144.126 23.7665 147.95 19.8175 152.781 19.8175C157.905 19.8175 161.35 23.7245 161.35 31.3695V33.3445H149.797C150.008 36.3265 151.646 37.7965 154.334 37.7965C155.931 37.7965 157.821 37.2925 159.543 36.4945L160.636 40.7795C158.535 41.8295 156.057 42.3755 153.704 42.3755C148.369 42.3755 144.126 39.5615 144.126 31.2435Z"
                        fill={theme.tokens['primary-text-01']}
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M164.622 35.5283V25.2783H162.269V20.2793H164.622V14.5673H170.671V20.2793H175.292V25.2783H170.671V34.6463C170.671 36.4523 171.638 37.2083 173.275 37.2083C173.99 37.2083 174.62 37.0823 175.292 36.9143V41.8713C174.368 42.1653 173.065 42.3333 171.554 42.3333C166.933 42.3333 164.622 40.4433 164.622 35.5283Z"
                        fill={theme.tokens['primary-text-01']}
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M195.745 42.3755C188.099 42.3755 183.31 37.5025 183.31 27.3785C183.31 17.6335 188.225 12.2985 195.787 12.2985C198.308 12.2985 200.408 12.8025 202.046 13.4325L201.038 19.3975C199.736 18.8515 198.224 18.3475 196.417 18.3475C192.385 18.3475 189.99 21.5405 189.99 27.2945C189.99 33.2175 192.259 36.3265 196.543 36.3265C198.182 36.3265 199.736 35.9065 201.08 35.3185L202.13 41.1155C200.324 41.8715 198.098 42.3755 195.745 42.3755Z"
                        fill={theme.tokens['primary-text-01']}
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M214.141 37.419V32.252C213.553 32.126 212.881 32.084 212.167 32.084C210.907 32.084 210.193 32.336 209.689 32.798C209.227 33.302 208.974 34.058 208.974 35.15C208.974 37.335 209.941 38.217 211.62 38.217C212.461 38.217 213.343 37.881 214.141 37.419ZM209.1 42.334C205.698 42.334 203.303 40.149 203.303 35.444C203.303 33.344 203.892 31.58 205.026 30.487C206.244 29.227 208.05 28.597 210.781 28.597C211.957 28.597 213.133 28.765 214.141 28.975V27.757C214.141 25.909 213.301 24.9 211.033 24.9C209.269 24.9 207.378 25.405 205.698 26.119L204.564 21.498C206.748 20.616 209.311 19.944 212.125 19.944C217.628 19.944 220.107 22.296 220.107 27.295V38.175L220.569 42.04H215.276L214.52 40.149C212.923 41.536 211.369 42.334 209.1 42.334Z"
                        fill={theme.tokens['primary-text-01']}
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M230.226 42.4175C227.286 42.4175 224.555 41.6615 222.203 40.4015L223.421 35.4865C225.732 36.7465 228.041 37.5025 230.142 37.5025C231.697 37.5025 232.537 36.8305 232.537 35.9065C232.537 34.9825 232.033 34.5625 229.092 33.3865C225.017 31.7475 223.001 30.1935 223.001 26.5385C223.001 22.5055 225.774 19.9015 230.646 19.9015C233.124 19.9015 235.393 20.4895 237.452 21.4985L236.233 26.4125C234.343 25.4465 232.411 24.8165 230.646 24.8165C229.344 24.8165 228.63 25.3625 228.63 26.2445C228.63 27.0845 229.092 27.5045 231.991 28.6815C236.57 30.4875 238.165 32.1675 238.165 35.6125C238.165 40.1495 234.973 42.4175 230.226 42.4175Z"
                        fill={theme.tokens['primary-text-01']}
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M241.648 35.5283V25.2783H239.295V20.2793H241.648V14.5673H247.697V20.2793H252.318V25.2783H247.697V34.6463C247.697 36.4523 248.664 37.2083 250.301 37.2083C251.016 37.2083 251.646 37.0823 252.318 36.9143V41.8713C251.394 42.1653 250.091 42.3333 248.58 42.3333C243.959 42.3333 241.648 40.4433 241.648 35.5283Z"
                        fill={theme.tokens['primary-text-01']}
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M262.187 42.4175C259.247 42.4175 256.516 41.6615 254.164 40.4015L255.382 35.4865C257.693 36.7465 260.002 37.5025 262.103 37.5025C263.658 37.5025 264.498 36.8305 264.498 35.9065C264.498 34.9825 263.994 34.5625 261.053 33.3865C256.978 31.7475 254.962 30.1935 254.962 26.5385C254.962 22.5055 257.735 19.9015 262.607 19.9015C265.085 19.9015 267.354 20.4895 269.413 21.4985L268.194 26.4125C266.304 25.4465 264.372 24.8165 262.607 24.8165C261.305 24.8165 260.591 25.3625 260.591 26.2445C260.591 27.0845 261.053 27.5045 263.952 28.6815C268.531 30.4875 270.126 32.1675 270.126 35.6125C270.126 40.1495 266.934 42.4175 262.187 42.4175Z"
                        fill={theme.tokens['primary-text-01']}
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M54 27C54 41.912 41.912 54 27 54C12.088 54 0 41.912 0 27C0 12.088 12.088 0 27 0C41.912 0 54 12.088 54 27Z"
                        fill={theme.tokens['support-05']}
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6 27C6 15.402 15.402 6 27 6C38.598 6 48 15.402 48 27H42.75C42.75 18.3015 35.6985 11.25 27 11.25C18.3015 11.25 11.25 18.3015 11.25 27C11.25 35.6985 18.3015 42.75 27 42.75V48C15.402 48 6 38.598 6 27ZM27 39.6C20.0412 39.6 14.4 33.9588 14.4 27C14.4 20.0412 20.0412 14.4 27 14.4C33.9588 14.4 39.6 20.0412 39.6 27H35.0182C35.0182 22.5717 31.4283 18.9818 27 18.9818C22.5717 18.9818 18.9818 22.5717 18.9818 27C18.9818 31.4283 22.5717 35.0182 27 35.0182V39.6Z"
                        fill={theme.tokens['contrast-01']}
                    />
                    <defs>
                        <linearGradient
                            id="paint0_linear"
                            x1="286.371"
                            y1="7.00001"
                            x2="386.054"
                            y2="31.7563"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#FED745" />
                            <stop offset="1" stopColor="#FEB525" />
                        </linearGradient>
                    </defs>
                </svg>
            )}
        </LogoWrapper>
    );
}

export default Logo;
