import styled from 'styled-components';
import { Typography } from 'styles';

export const KeyGroup = styled.div`
    display: inline-flex;
`;

export const Key = styled.span`
    ${Typography.Desktop.H70}
    border-radius: 2px;
    background: ${props => props.theme.tokens['primary-ui-05']};
    color: ${props => props.theme.tokens['primary-ui-05-selected']};
    height: 20px;
    min-width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    & + & {
        margin-left: 3px;
    }
`;

export const TextKey = styled(Key)`
    padding: 0.15em 5px 0;
    text-transform: uppercase;
`;
