import styled from 'styled-components';
import { gridSize, H60 } from '../../styles';

export const ProfilePageWrapper = styled.div`
    height: 100%;
`;

export const ThreeColumns = styled.div`
    display: flex;
    width: 100%;
    padding-top: ${gridSize * 2}px;
`;

export const ProfileInformationColumnWrapper = styled.div`
    display: flex;
    min-width: 360px;
    flex-direction: column;
    align-items: flex-start;
`;

export const Space = styled.div`
    width: 100%;
    height: ${3 * gridSize}px;
`;

export const ErrorWrapper = styled(H60)`
    padding-top: ${2 * gridSize}px;
`;
