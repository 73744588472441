import styled from 'styled-components';
import { Typography } from '../../styles/typography';

export const PodcastListPageWrapper = styled.div`
    height: 100%;
    padding: 20px 40px;
    position: relative;
`;

export const ListTitle = styled.h1`
    ${Typography.Desktop.H10};
    padding-top: 32px;
    padding-bottom: 32px;
`;
