import memoize from 'lodash/memoize';
import { UploadedFile } from 'model/types';
import {
    LOCAL_STORAGE_KEY_FILES,
    LOCAL_STORAGE_KEY_FILES_SORT_COLUMN,
    LOCAL_STORAGE_KEY_FILES_SORT_ORDER,
} from '../model/local-storage';

const k = 1000;
const M = 1000 * 1000;
const G = 1000 * 1000 * 1000;
export const getSizeStringFromBytes = (bytes: number) => {
    if (Math.floor(bytes / k) === 0) {
        return `${bytes} B`;
    }

    if (Math.floor(bytes / M) === 0) {
        return `${Math.floor(bytes / k)} kB`;
    }

    if (Math.floor(bytes / G) === 0) {
        return `${(bytes / M).toFixed(1)} MB`;
    }

    return `${(bytes / G).toFixed(1)} GB`;
};

export const supportedMimeTypes = memoize(() => [
    'audio/mpeg',
    'audio/aac',
    'audio/wav',
    'audio/mp3',
    'audio/mp4',
    'audio/x-mpeg',
    'audio/aiff',
    'audio/x-aiff',
    'audio/amr',
    'audio/mpeg3',
    'audio/x-mp3',
    'audio/x-mpeg3',
    'audio/3gpp',
    'audio/3gpp2',
    'audio/x-mp4',
    'audio/x-wav',
    'audio/x-m4a',
    'audio/x-m4b',
    'audio/x-m4p',
    'video/3gpp',
    'video/3gpp2',
    'video/mp4',
    'video/x-mp4',
    'video/quicktime',
    'video/m4v',
    'video/mpeg',
    'video/mpeg2',
]);

export const mimeTypeIsSupported = (mimeType: string) => supportedMimeTypes().includes(mimeType);
export const videoMimeTypes = () => supportedMimeTypes().filter(type => type.startsWith('video/'));
export const audioMimeTypes = () => supportedMimeTypes().filter(type => type.startsWith('audio/'));

export const guessMimeTypeFromFileExtension = (ext: string) => {
    switch (ext) {
        case 'm4v':
            return 'video/x-m4v';
        case 'mov':
            return 'video/mpeg2';
        case '3gp':
            return 'audio/3gpp';
        case '3g2':
            return 'audio/3gpp2';
        case 'aiff':
            return 'audio/aiff';
        case 'amr':
            return 'audio/amr';
        case 'mp4':
            return 'audio/x-mp4';
        case 'mp3':
            return 'audio/mpeg';
        case 'wav':
            return 'audio/wav';
        case 'm4a':
            return 'audio/x-m4a';
        case 'm4b':
            return 'audio/x-m4b';
        case 'm4p':
            return 'audio/x-m4p';
        default:
            return '';
    }
};

export const sortFilesAlgorithm = (
    filesOrder: string[],
    files: Record<string, UploadedFile>,
    sortColumnIfNeeded?: string,
    sortOrderIfNeeded?: string,
) => {
    const sortColumn =
        sortColumnIfNeeded ||
        localStorage.getItem(LOCAL_STORAGE_KEY_FILES_SORT_COLUMN) ||
        LOCAL_STORAGE_KEY_FILES.COLUMN_NAME;

    const sortOrder =
        sortOrderIfNeeded ||
        localStorage.getItem(LOCAL_STORAGE_KEY_FILES_SORT_ORDER) ||
        LOCAL_STORAGE_KEY_FILES.ORDER_DESCENDING;

    const newFilesOrder = JSON.parse(JSON.stringify(filesOrder));

    const descendingSortFunction = (
        a: UploadedFile,
        b: UploadedFile,
        sortInfo: {
            sortingName: boolean;
            sortingSize: boolean;
            sortingDuration: boolean;
            sortingDate: boolean;
        },
    ) => {
        if (sortInfo.sortingName) {
            return a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1;
        }

        if (sortInfo.sortingSize) {
            return Number(a.size) < Number(b.size) ? 1 : -1;
        }

        if (sortInfo.sortingDuration) {
            return Number(a.duration) < Number(b.duration) ? 1 : -1;
        }

        if (sortInfo.sortingDate) {
            return a.published < b.published ? 1 : -1;
        }

        // Should be unreachable
        return 1;
    };

    const sortFunction = (a: number, b: number) => {
        const sortInfo = {
            sortingName: sortColumn === LOCAL_STORAGE_KEY_FILES.COLUMN_NAME,
            sortingSize: sortColumn === LOCAL_STORAGE_KEY_FILES.COLUMN_SIZE,
            sortingDuration: sortColumn === LOCAL_STORAGE_KEY_FILES.COLUMN_DURATION,
            sortingDate: sortColumn === LOCAL_STORAGE_KEY_FILES.COLUMN_DATE,
            ascending: sortOrder === LOCAL_STORAGE_KEY_FILES.ORDER_ASCENDING,
            descending: sortOrder === LOCAL_STORAGE_KEY_FILES.ORDER_DESCENDING,
        };

        const result = descendingSortFunction(files[a], files[b], sortInfo);

        if (sortInfo.ascending) {
            const invert = (oneToMinusOne: number) => {
                if (oneToMinusOne === 1) return -1;
                if (oneToMinusOne === -1) return 1;
                return oneToMinusOne;
            };

            return invert(result);
        }

        return result;
    };

    newFilesOrder.sort(sortFunction);

    return newFilesOrder;
};
