import { Icon } from 'components/Icon';
import React, { FocusEventHandler, MouseEvent, MouseEventHandler } from 'react';
import { Content, ButtonWrapper, LoadingIcon, TextButton } from './Button.styled';

export type Props = {
    kind?: 'plus' | 'primary' | 'primary-red' | 'secondary' | 'secondary-muted' | 'text';
    type?: 'button' | 'submit' | 'reset';
    width?: number;
    small?: boolean;
    disabled?: boolean;
    loading?: boolean;
    className?: string;
    children: React.ReactNode;
    onBlur?: FocusEventHandler;
    onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
    onFocus?: FocusEventHandler;
    onMouseDown?: MouseEventHandler;
    'data-testid'?: string;
    value?: string | number;
};

function Button({
    type,
    kind = 'primary',
    width,
    small = false,
    onBlur,
    onClick = () => ({}),
    onFocus,
    onMouseDown,
    disabled,
    loading = false,
    children,
    className,
    value,
    ...restProps
}: Props) {
    const isDisabled = !!(loading || disabled);

    if (kind === 'text') {
        return (
            <TextButton
                data-testid={restProps['data-testid'] || null}
                type={type}
                disabled={isDisabled}
                onBlur={onBlur}
                onClick={onClick}
                onFocus={onFocus}
                onMouseDown={onMouseDown}
                value={value}
                className={className}
            >
                {children}
            </TextButton>
        );
    }

    const height = small ? 37 : 48;

    return (
        <ButtonWrapper
            kind={kind}
            type={type}
            width={width}
            height={height}
            disabled={isDisabled}
            isLoading={loading}
            onBlur={onBlur}
            onClick={onClick}
            onFocus={onFocus}
            onMouseDown={onMouseDown}
            className={className}
            data-testid={restProps['data-testid'] || null}
            value={value}
        >
            <LoadingIcon>
                <Icon id="in-progress" aria-label="Working" />
            </LoadingIcon>

            <Content>{children}</Content>
        </ButtonWrapper>
    );
}

export default Button;
