export enum SubscriptionPlatform {
    none = 0,
    iOS = 1,
    Android = 2,
    Paddle = 3,
    gift = 4,
}

export enum PaddleStatus {
    none = 0,
    active = 1,
    trialing = 2,
    pastDue = 3,
    paused = 4,
    deleted = 5,
}

export enum PaymentFrequency {
    none = 0,
    monthly = 1,
    annual = 2,
}

export enum SubscriptionType {
    plus = 1,
}

export type SubscriptionTier = 'Patron' | 'Plus';

export interface Subscription {
    platform: SubscriptionPlatform;
    type: SubscriptionType;
    paid: PlusPaidStatus;
    expiryDate: string;
    frequency: PaymentFrequency;
    autoRenewing: boolean;
    giftDays: number;
    cancelUrl: string;
    updateUrl: string;
    webStatus: PaddleStatus;
    plan: string;
    // Patron tier is not shipped to production yet, so may not be available in all environments
    tier?: SubscriptionTier;
    // The API only sends nextPayment in staging — keep this optional until it's in production too
    nextPayment?: {
        amount: number;
        currency: string;
        paymentDate: string;
    };
}

export enum PlusPaidStatus {
    free = 0,
    paid = 1,
}

export interface SubscriptionData {
    paid: PlusPaidStatus;
    platform: SubscriptionPlatform;
    frequency: PaymentFrequency;
    expiryDate: string;
    autoRenewing: boolean;
    giftDays: number;
    webCancelUrl?: string;
    webUpdateUrl?: string;
    cancelUrl: string;
    updateUrl: string;
    webStatus: number;
    web: {
        monthly: number;
        yearly: number;
        trialDays: number;
        // Stub in the Patron SKUs here — these are not yet finalized in the API
        patronMonthly?: number;
        patronYearly?: number;
    };
    index: number;
    type: number;
    // Patron tier is not shipped to production yet, so may not be available in all environments
    tier?: SubscriptionTier;
    subscriptions: Subscription[];
}

export interface RedeemPromoSuccessResponse {
    code: string;
    description: string;
    startsAt: string;
    endsAt: string;
}

export interface RedeemPromoErrorResponse {
    errorMessage: string;
}
