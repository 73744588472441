import styled from 'styled-components';
import { gridSize, visuallyHidden } from 'styles';

export const Fieldset = styled.fieldset`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: ${gridSize * 1.25}px;
    max-width: 340px;
    legend {
        ${visuallyHidden}
    }
    label {
        cursor: pointer;
        display: inline-block; // Fix for legacy Edge which wasn't rendering the containing flexbox as a row

        @supports (-ms-ime-align: auto) {
            /* Hack to explicitly force Legacy Edge (Windows App) to give color buttons width and height */
            width: 45px;
            height: 45px;
            margin: 0 0 10px 10px;
        }
    }
    input {
        ${visuallyHidden}
    }
`;

export const ColorOption = styled.div`
    background: ${props => props.color};
    width: 100%;
    height: 100%;
    padding-bottom: 100%;
    border-radius: 50%;
    transform-origin: center center;
    transition: transform 0.1s ease-in-out;

    position: relative;

    &:after {
        content: '';
        background: #fff;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.075s ease-out;
    }

    label:hover &,
    input:focus + & {
        transform: scale(1.2);
    }
    input:checked + &:after {
        transform: translate(-50%, -50%) scale(1);
    }
`;
