import styled from 'styled-components';
import { gridSize } from 'styles';
import { Separator as SeparatorComponent } from 'components';
import { Logo as SVGLogo } from 'components/Logo';
import Modal from 'components/Modal';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Logo = styled(SVGLogo)`
    width: 48px;
    height: 48px;
    @media (min-width: 500px) {
        width: 52px;
        height: 52px;
    }
    @media (min-width: 1100px) {
        width: 56px;
        height: 56px;
    }
`;

export const Heading = styled.h1`
    font-size: 40px;
    line-height: 1.2;
    padding-bottom: ${0.5 * gridSize}px;
    margin: ${3 * gridSize}px 0;
    letter-spacing: -0.02em;
    -webkit-line-clamp: 3;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    @media (min-width: 500px) {
        font-size: 48px;
        line-height: 1;
        margin: ${3 * gridSize}px 0 ${5 * gridSize}px;
    }
    @media (min-width: 620px) {
        font-size: 56px;
    }
    @media (min-width: 800px) {
        font-size: 48px;
    }
    @media (min-width: 1100px) {
        font-size: 56px;
    }
`;

export const FinePrint = styled.small`
    display: block;
    margin-top: ${2 * gridSize}px;

    font-size: 14px;
    line-height: 1.7;
    a,
    button {
        color: inherit;
        font-weight: 700;
        white-space: nowrap;
    }
    @media (min-width: 800px) {
        margin-top: ${3 * gridSize}px;
        order: 1; // Move to the bottom of the flex container
    }
`;

export const Separator = styled(SeparatorComponent)`
    margin: ${2 * gridSize}px 0;
`;

export const DownloadModal = styled(Modal)`
    max-width: 440px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.2;
    text-align: center;
    img {
        display: block;
        width: 100%;
        max-width: 172px;
        margin: 0 auto ${3 * gridSize}px;
    }
`;
