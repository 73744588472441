import { PlanBadge } from 'components/PlanBadge';
import useFormatMessage from 'hooks/useFormatMessage';
import { PaddleDiscountedPlan, PaddlePlan, PaddleProduct } from 'model/types';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { USE_PATRON } from 'settings';
import { TranslatableStringKey } from 'translations/glotpress';
import { PlanFeatures, PlanPrice } from './PlanSelector';

import {
    FlexLineBreak,
    FreeTrialBadge,
    PlanSelectorItemsRow,
    TextButton,
    Wrapper,
} from './PlanSelector.styled';
import { PLAN_VARIABLES } from './plans';

export type Props = {
    plan: PaddlePlan | PaddleDiscountedPlan;
    product?: PaddleProduct;
    couponCode?: string;
    discountInterval: TranslatableStringKey;
    discountPrice: ReactNode;
    fullInterval: TranslatableStringKey;
    fullPrice: ReactNode;
    variant?: 'mini' | 'regular';
    onChange: (newPlan: PaddlePlan) => void;
};

// TODO: Rename to DiscountPlanSelector??? May be more general-purpose then
const DiscountedPlanSelector = ({
    plan,
    couponCode,
    product,
    discountInterval,
    discountPrice,
    fullPrice,
    fullInterval,
    variant = 'regular',
    onChange,
}: Props) => {
    const { tier } = PLAN_VARIABLES[plan];
    const formatMessage = useFormatMessage();
    const { otherIntervalPlan, switchIntervalText, otherTierPlan } = PLAN_VARIABLES[plan];

    const generateCouponBadgeText = () => {
        if (!product || !couponCode) {
            return undefined;
        }

        const fullPriceNumber = product.subscription.price.gross;
        const discountPriceNumber = product.price.gross;
        const percentDiscount = Math.round((1 - discountPriceNumber / fullPriceNumber) * 100);

        const badgeText =
            discountInterval === 'first-month'
                ? formatMessage('discount-text-first-month', { percentDiscount })
                : formatMessage('discount-text-first-year', { percentDiscount });

        return badgeText;
    };

    const couponBadgeText = generateCouponBadgeText();
    const badgeText = couponBadgeText ?? formatMessage('switch-to-discount-text');
    const isPatron = ['patron-monthly', 'patron-yearly'].includes(plan);

    return (
        <Wrapper variant={variant} isPatron={isPatron}>
            <PlanSelectorItemsRow>
                <div>
                    <PlanBadge tier={tier} />
                </div>
                <div>{badgeText && <FreeTrialBadge>{badgeText}</FreeTrialBadge>}</div>
                <div>
                    {USE_PATRON && otherTierPlan && (
                        <TextButton kind="text" onClick={() => onChange(otherTierPlan)}>
                            <FormattedMessage id="change-plan" />
                        </TextButton>
                    )}
                </div>
            </PlanSelectorItemsRow>

            <FlexLineBreak />

            <PlanSelectorItemsRow>
                <PlanPrice
                    price={discountPrice}
                    interval={discountInterval === 'first-month' ? 'month' : 'year'}
                    variant={variant}
                    fullPrice={fullPrice}
                    fullInterval={fullInterval}
                />

                {otherIntervalPlan && switchIntervalText && (
                    <TextButton
                        kind="text"
                        onClick={() => onChange(otherIntervalPlan)}
                        data-testid="plan-selector-switch-plan-button"
                    >
                        <FormattedMessage id={switchIntervalText} />
                    </TextButton>
                )}
            </PlanSelectorItemsRow>

            <FlexLineBreak />

            {variant !== 'mini' && <PlanFeatures tier={tier} plan={plan} />}
        </Wrapper>
    );
};

export default DiscountedPlanSelector;
