import styled from 'styled-components';

export const LoaderSquareWrapper = styled.div`
    height: 26px;
    width: 26px;
    margin: 20px auto;
    .spinners {
        width: 26px;
        height: 26px;
        position: relative;
        div {
            display: block;
            width: 10px;
            height: 10px;
            background-color: ${props => props.theme.legacyScss['theme-line']};
            opacity: 1;
            border-radius: 2px;
        }
        .spinner1,
        .spinner2,
        .spinner3,
        .spinner4 {
            position: absolute;
            animation: spin infinite 1s ease-in-out;
        }
        .spinner1 {
            top: 0;
            left: 0;
            animation-delay: 0ms;
        }
        .spinner2 {
            top: 0;
            left: 13px;
            animation-delay: 250ms;
        }
        .spinner3 {
            left: 13px;
            top: 13px;
            animation-delay: 500ms;
        }
        .spinner4 {
            left: 0;
            top: 13px;
            animation-delay: 750ms;
        }

        @keyframes spin {
            0% {
                transform: scale(1);
                opacity: 0.6;
            }
            60% {
                transform: scale(1.1);
                opacity: 1;
            }
            100% {
                transform: scale(1);
                opacity: 0.6;
            }
        }
    }
`;
