import { FilterEpisode, FilterId } from 'model/types';
import { createAction, ActionsUnion } from './action-creators';

export enum ActionTypes {
    CLEAR_FILTER = '@filter/CLEAR_FILTER',
    DOWNLOAD_FILTER = '@filter/DOWNLOAD_FILTER',
    DOWNLOAD_FILTER_SUCCESS = '@filter/DOWNLOAD_FILTER_SUCCESS',
    DOWNLOAD_FILTER_FAILED = '@filter/DOWNLOAD_FILTER_FAILED',
}

export const Actions = {
    downloadFilter: (id: FilterId) => createAction(ActionTypes.DOWNLOAD_FILTER, { id }),

    downloadFilterSuccess: (id: FilterId, episodes: FilterEpisode[]) =>
        createAction(ActionTypes.DOWNLOAD_FILTER_SUCCESS, { id, episodes }),

    downloadFilterFailed: () => createAction(ActionTypes.DOWNLOAD_FILTER_FAILED),

    clearFilter: () => createAction(ActionTypes.CLEAR_FILTER),
};

export type Actions = ActionsUnion<typeof Actions>;
