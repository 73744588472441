import styled from 'styled-components';
import { Typography } from 'styles';

export const Wrapper = styled.div<{ size?: number }>`
    background: ${props => props.color};
    color: #fff;
    width: ${props => (props.size ? `${props.size}px` : '100%')};
    border-radius: 4px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    position: relative;
    &:before {
        content: '';
        display: block;
        width: 100%;
        padding-bottom: 100%;
        background: linear-gradient(180deg, transparent, transparent 40%, ${props => props.color});
        filter: brightness(70%) saturate(150%);
    }
`;

export const Content = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const PodcastImages = styled.div`
    position: relative;
    flex: 1;

    overflow: hidden;
`;
export const PodcastGrid = styled.div`
    position: absolute;
    width: 80%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: auto;
`;

export const GridCell = styled.div`
    padding: ${(1 / 24) * 100}%;
`;

export const Label = styled.div<{ width: number }>`
    ${({ width }) =>
        width >= 220
            ? Typography.Desktop['H50-B']
            : width >= 190
            ? Typography.Desktop['H60-B']
            : width >= 160
            ? Typography.Desktop['H65-B']
            : width >= 120
            ? Typography.Desktop['H70-B']
            : width >= 100
            ? Typography.Desktop['H80-B']
            : `${Typography.Desktop['H80-B']} font-size: 11px;`}
    height: 14%;
    line-height: 120%;

    padding: 0em 0.3em;
    position: relative;
    text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    span {
        overflow: hidden;
        white-space: nowrap;

        text-overflow: ellipsis;
        padding-bottom: 4%;
    }
`;

export const EmptyImage = styled.div`
    padding: ${(1 / 24) * 100}%;
    &:after {
        content: '';
        display: block;
        width: 100%;
        padding-top: 100%; // Trick: Padding is % of width, so this forces it to be square
        background: rgba(0, 0, 0, 0.1);
        border-radius: 4px;
    }
`;
